import { PDFFont } from 'pdf-lib';
import { CITIES_CHALLENGE_REPORT } from 'src/app/constants/constants';
import { FirebaseConstants } from '../../firebase-constants.enum';
import { Report } from '../report/report.model';

export interface ICitiesChallengeReport {
  company: {
    name: string;
    id: string;
  };
  challenge: {
    name: string;
    reportDate: string;
    challengePeriod: string;
    dayProgress: string;
    participantsNumber: string;
    routeProgress: string;
    targetValue: string;
    currentStage: string;
    stageProgress: string;
  };
}

/** Class representing a Cities Challenge Report. */
export class CitiesChallengeReport extends Report {
  public templatePath: string = CITIES_CHALLENGE_REPORT.TEMPLATE_PATH;

  public fonts: { [fontName: string]: PDFFont | null } = {
    [FirebaseConstants.OpenSansExtraBoldItalic]: null,
    [FirebaseConstants.OpenSansExtraBold]: null,
    [FirebaseConstants.OpenSansItalic]: null,
  };

  public textFields: {
    [textFieldName: string]: { value: string | null; font: string };
  } = {
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.COMPANY_NAME]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBoldItalic,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.COMPANY_ID]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBoldItalic,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.CHALLENGE_NAME]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBoldItalic,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.REPORT_DATE]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBoldItalic,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.CHALLENGE_PERIOD]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBold,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.DAY_PROGRESS]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBold,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.PARTICIPANT_NUMBER]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBold,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.ROUTE_PROGRESS]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBold,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.TARGET_VALUE]: {
      value: null,
      font: FirebaseConstants.OpenSansItalic,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.CURRENT_STAGE]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBold,
    },
    [CITIES_CHALLENGE_REPORT.TEXT_FIELDS.STAGE_PROGRESS]: {
      value: null,
      font: FirebaseConstants.OpenSansExtraBold,
    },
  };

  constructor(citiesChallengeReportData?: ICitiesChallengeReport) {
    super();
    if (citiesChallengeReportData) {
      this.fillTextFieldsValues(citiesChallengeReportData);
    }
  }

  /**
   * Prepare textFieldsArray for Report
   * @param {{ challenge: DocumentData }} valuesToFill - The data to prepare the report
   */
  public fillTextFieldsValues(valuesToFill: ICitiesChallengeReport): void {
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.COMPANY_NAME].value =
      valuesToFill.company.name;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.COMPANY_ID].value =
      valuesToFill.company.id;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.CHALLENGE_NAME].value =
      valuesToFill.challenge.name;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.REPORT_DATE].value =
      valuesToFill.challenge.reportDate;
    this.textFields[
      CITIES_CHALLENGE_REPORT.TEXT_FIELDS.CHALLENGE_PERIOD
    ].value = valuesToFill.challenge.challengePeriod;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.DAY_PROGRESS].value =
      valuesToFill.challenge.dayProgress;
    this.textFields[
      CITIES_CHALLENGE_REPORT.TEXT_FIELDS.PARTICIPANT_NUMBER
    ].value = valuesToFill.challenge.participantsNumber;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.ROUTE_PROGRESS].value =
      valuesToFill.challenge.routeProgress;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.TARGET_VALUE].value =
      valuesToFill.challenge.targetValue;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.CURRENT_STAGE].value =
      valuesToFill.challenge.currentStage;
    this.textFields[CITIES_CHALLENGE_REPORT.TEXT_FIELDS.STAGE_PROGRESS].value =
      valuesToFill.challenge.stageProgress;
  }
}
