import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SERVER_NOTIFICATION_URL } from 'src/app/constants/constants';
import { NotificationInterface } from 'src/app/interfaces/notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  public checkForNotification(): Promise<NotificationInterface> {
    return this.http
      .get<NotificationInterface>(SERVER_NOTIFICATION_URL)
      .toPromise();
  }
}
