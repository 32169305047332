<div class="confirmation-dialog-container">
  <h1 mat-dialog-title class="title">{{ data.title | translate }}</h1>
  <div mat-dialog-content class="message">
    <p>{{ data.message | translate }}</p>
  </div>
  <div mat-dialog-actions class="actions">
    <div class="button-container">
      <button mat-button class="cancel-button" (click)="onNoClick()" [disabled]="showYesSpinner" cdkFocusInitial>{{ (data.cancelLabel ? data.cancelLabel : 'NO') | translate }}</button>
    </div>
    <div class="button-container">
      <button mat-button class="confirm-button" (click)="onYesClick()" [disabled]="showYesSpinner">
        {{ (data.confirmLabel ? data.confirmLabel : 'YES') | translate }}
        <mat-spinner [diameter]="20" *ngIf="data.showSpinner && showYesSpinner"></mat-spinner>
      </button>
    </div>
  </div>
</div>
