export enum AdminTabs {
  Companies,
  Users,
  Challenges,
  Cities,
  Routes,
  Teams,
  PointsSources,
  WhiteLabels,
  VerificationEmail,
  GenerateReport,
}
