export enum FirebaseConstants {
  CompaniesCollection = 'companies',
  AvailableChallengesCollection = 'availableChallenges',
  PointsSourcesCollection = 'pointsSources',
  LigasCollection = 'ligas',
  TeamChallengesCollection = 'teamChallenges',
  TeamsCollection = 'teams',
  WeeklyHistoriesCollection = 'weeklyHistories',
  CitiesChallengesCollection = 'citiesChallenges',
  LocationsCollection = 'locations',
  DailyPerformanceCollection = 'dailyPerformance',
  ReportsCollection = 'reports',
  WeeklyRankingCollection = 'weeklyRanking',
  UsersCollection = 'users',
  LevelsCollection = 'levels',
  TrainingsCollection = 'newTrainings',
  CitiesCollection = 'cities',
  RoutesCollection = 'routes',
  CityInfosCollection = 'cityInfos',
  WhiteLabelsCollection = 'whiteLabels',
  DuelsCollection = 'duels',
  PerUserCollection = 'perUser',
  PersonalChallengesCollection = 'personalChallenges',
  DefaultTeamChallengesCollection = 'teamChallenges',
  EventsCollection = 'events',
  StatisticsCollection = 'statistics',
  PushNotificationsCollection = 'pushNotifications',
  DuelChallengesCollection = 'duelChallenges',
  GroupRunChallenges = 'groupRunChallenges',
  ChallengesCollection = 'challenges',

  FinalStandingsDocument = 'finalStandings',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  StatisticsDocument = 'statistics',
  StatisticsDocumentPerDay = 'daily',
  StatisticsDocumentPerMonth = 'monthly',
  StatisticsDocumentPerWeek = 'weekly',
  StatisticsDocumentPerYear = 'yearly',
  TrainingsDocument = 'trainings',

  CompanyIdProp = 'companyId',
  StartDateProp = 'startDate',
  WeekdaysProp = 'weekdays',
  IdProp = 'id',
  LocationIdsProp = 'locationIds',
  ChallengeIdProp = 'challengeId',
  TypeProp = 'type',
  LocationProp = 'location',
  CurrentTeamChallengeIdProp = 'currentTeamChallengeId',
  ManagerCompanyIdsProp = 'managerCompanyIds',
  CityInfoTypeProp = 'cityInfoType',
  EmailProp = 'email',
  HierarchyLevelProp = 'hierarchyLevel',
  ChallengeTypeProp = 'challengeType',

  EqualOperation = '==',
  NotEqualOperation = '!=',
  GreaterOrEqualOperation = '>=',
  GreaterOperation = '>',
  ArrayContainsOperation = 'array-contains',
  ArrayContainsAnyOperation = 'array-contains-any',
  InOperation = 'in',

  InOperationLimit = '10',

  EuropeRegion = 'europe-west3',

  SendSupportEmailFunction = 'sendSupportEmail',
  SendVerificationEmail = 'sendVerificationEmail',
  SendProjectPlanFunction = 'reports-sendProjectPlanTrigger',

  WebSource = 'web',

  SendAdminNotification = 'sendAdminNotification',
  SendTeamLeaderNotification = 'sendTeamLeaderNotification',
  CleanProjectClientData = 'cleanProjectClientData',
  ChangeTeamMember = 'changeTeamMember',

  DuelStatisticsProp = 'duelStatistics',
  WonDuelsProp = 'wonDuels',
  DuelSDateProp = 'sDate',
  DuelStatusProp = 'status',
  IsUserParticipatingInDuelsProp = 'isParticipatingInDuels',
  DescOrder = 'desc',
  AscOrder = 'asc',

  /* **********Request Endpoints*********** */
  SendEmailWithAttachments = 'https://europe-west3-move-me-test.cloudfunctions.net/sendEmailWithAttachments',

  /* **********Storage*********** */
  CompaniesFolder = 'companies/',
  TemplatesFolder = 'templates/',
  ReportsFolder = 'reports/',
  AroundTheWorldFolder = 'aroundTheWorld/',
  TeamChallengesFolder = 'teamChallenges/',
  DistanceChallengesFolder = 'distanceChallenges/',
  CitiesChallengesFolder = 'citiesChallenges/',
  FontsFolder = 'fonts/',
  WhiteLabelsFolder = 'whiteLabels/',
  ReportTemplatesFolder = 'reportTemplates/',
  TeamsFolder = 'teams/',
  PostingReports = 'postingReports/',
  RoutesFolder = 'routes/',
  CitiesFolder = 'cities/',
  ImagesFolder = 'images/',
  AvatarsFolder = 'avatars/',

  /* **********Report Templates*********** */
  CitiesChallengeTemplateName = 'Template_Report_Cities Challenge_DEU.pdf',
  TeamChallengeTemplateName = 'Template_Report_Team Challenge_DEU.pdf',
  OnePagerTemplateName = 'Template_Onepager_World Trip Challenge_DEU.pdf',
  OnePagerTeamTeamplateName = 'Template_Onepager_TeamChallenge_DEU.pdf',
  OnePagerCaptainTeamplateName = 'Template_Onepager_TeamCaptains_DEU.pdf',

  /* **********Report Files ************** */
  DistanceChallengeMapName = 'map.png',
  AvatarImage = 'avatar.png',

  /* **********Fonts Files ************** */
  OpenSansBold = 'OpenSans-Bold.ttf',
  OpenSansExtraBold = 'OpenSans-ExtraBold.ttf',
  OpenSansExtraBoldItalic = 'OpenSans-ExtraBoldItalic.ttf',
  OpenSansItalic = 'OpenSans-Italic.ttf',
  OpenSansRegular = 'OpenSans-Regular.ttf',
  OpenSansBoldItalic = 'OpenSans-BoldItalic.ttf',
}
