import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-cities-challenge-overview',
  templateUrl: './cities-challenge-overview.component.html',
  styleUrls: ['./cities-challenge-overview.component.scss'],
})
export class CitiesChallengeOverviewComponent {
  @Input() citiesChallengeDataForm: UntypedFormGroup;
  @Input() citiesChallengeType: string;
  @Input() showSpinner = false;
  @Output() createClicked = new EventEmitter<void>();

  constructor() {}

  get regions(): FormArray<FormGroup> {
    return this.citiesChallengeDataForm.controls
      .regions as FormArray<FormGroup>;
  }

  public getRouteFromRegionIndex(index: number): UntypedFormGroup {
    return this.regions.at(index).controls.route as UntypedFormGroup;
  }

  public createClick(): void {
    this.createClicked.emit();
  }
}
