<div class="container-card-login">
  <app-notification-messages *ngIf="!isProdEnvironment" [defaultNotification]="{ showMessage: true, message: { en: 'Testing Environment', de: 'Testing Umgebung' }}"></app-notification-messages>
  <div class="language-button-container">
    <button class="language-button-position" mat-button [matMenuTriggerFor]="langMenu">
      <span class="menu-label">{{ 'languages.' + settings.lang | translate }}
      </span>
      <mat-icon> language </mat-icon>
    </button>
    <mat-menu #langMenu="matMenu">
      <button mat-menu-item (click)="changeLanguage('en')">
        {{ 'languages.en' | translate }}
      </button>
      <button mat-menu-item (click)="changeLanguage('de')">
        {{ 'languages.de' | translate }}
      </button>
    </mat-menu>
  </div>
  <mat-grid-list [cols]="colSize">
    <mat-grid-tile class="logo-col" colspan="1" rowspan="1">
      <div class="img-container">
        <img src="../../../assets/logo_login.png" />
      </div>
      <div *ngIf="colSize === 1" (click)="scroll(scrollTarget)" class="expand-icon-container">
        <mat-icon>expand_more</mat-icon>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="login-col" colspan="1" [rowspan]="colSize === 1 ? 2 : 1">
      <mat-card class="login-card">
        <div #scrollTarget class="login-container">
          <mat-card-title class="title">
            Move Me
          </mat-card-title>
          <mat-card-subtitle class="welcome-subtitle">
            {{ 'LOGIN_WELCOME' | translate }}
          </mat-card-subtitle>
          <mat-card *ngIf="!isProdEnvironment">
            This is a test environment, using a new test database, in case you do not have access to the new testing environment with your account, please create a new one.
          </mat-card>
          <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
            <mat-form-field>
              <mat-label>{{ 'forms.email.LABEL' | translate }}</mat-label>
              <input matInput type="email" class="form-control" formControlName="email" id="email" />
              <mat-error *ngIf="email.hasError('required')">
                {{ 'forms.email.REQUIRED_ERROR' | translate }}
              </mat-error>
              <mat-error *ngIf="email.hasError('pattern')">
                {{ 'forms.email.PATTERN_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'forms.password.LABEL' | translate }}</mat-label>
              <input matInput type="password" class="form-control" formControlName="password" id="password" />
              <mat-error *ngIf="password.hasError('required')">
                {{ 'forms.password.REQUIRED_ERROR' | translate }}
              </mat-error>
              <mat-error *ngIf="password.hasError('minlength')">
                {{ 'forms.password.MINLENGTH_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="error-container">
              <div *ngIf="emailNotVerified">
                <p>{{ 'login.errors.EMAIL_NOT_VERIFIED_TEXT' | translate }}</p>
                <p class="action" (click)="sendVerificationEmail()">{{ 'login.errors.EMAIL_NOT_VERIFIED_ACTION' | translate }}</p>
              </div>
            </div>
            <div>
              <mat-checkbox mat-flat-button formControlName="persistence" id="persistence" color="primary"
                class="form-control" fxLayoutAlign="center">
                <span>{{ 'login.KEEP_ME_LOGGED_IN' | translate }}</span>
              </mat-checkbox>
            </div>
            <div class="links-container password-forgotten">
              <span class="ion-text-center" routerLink="/reset-password" routerDirection="forward">
                {{ 'LOGIN_PASSWORD_FORGOTTEN' | translate }}
              </span>
            </div>
            <div class="button-container">
              <button class="login-button" [disabled]="!loginForm.valid || loggingIn" mat-flat-button color="primary" type="submit">
                {{ 'LOGIN_BUTTON' | translate }}
                <mat-spinner diameter="20" *ngIf="loggingIn"></mat-spinner>
              </button>
              <button [disabled]="loggingIn" (click)="goToSignup()" mat-stroked-button color="secondary" type="button">
                {{ 'SIGNUP_BUTTON' | translate }}
              </button>
            </div>
          </form>
          <div class="error-card-container">
            <mat-card class="error-card" *ngIf="errorMessage && errorMessage !== ''">
              <div class="error-card-icon">
                <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
              </div>
              <div class="error-card-text">
                {{ errorMessage }}
              </div>
            </mat-card>
          </div>
        </div>
        <mat-card-footer>
          <div
            class="terms-of-use"
            fxLayout="row center"
          >
            <p>
              <a class="link-label" target="_blank" rel="noopener noreferrer"
                href="https://www.move-me-service.com/agb/">{{ 'LOGIN_TERMS_OF_USE' | translate }}</a>
            </p>
            <p>
              <a class="link-label" target="_blank" rel="noopener noreferrer"
                href="https://www.move-me-service.com/datenschutz/">
                {{ 'PRIVACY_POLICY' | translate }}</a>
            </p>
            <p>
              <a class="link-label" target="_blank" rel="noopener noreferrer"
                href="https://www.move-me-service.com/impressum/">{{ 'ABOUT_US' | translate }}</a>
            </p>
          </div>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
