import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-challenge-countdown',
  templateUrl: './challenge-countdown.component.html',
  styleUrls: ['./challenge-countdown.component.scss'],
})
export class ChallengeCountdownComponent implements OnInit {
  // startDate as ISO string.
  @Input() startDate: string;
  public remainingTime: string;

  constructor() {}

  ngOnInit(): void {
    this.calculateTimeRemaining();
    setInterval(() => {
      this.calculateTimeRemaining();
    }, 1000);
  }

  private calculateTimeRemaining(): void {
    if (this.startDate) {
      const startTime = new Date(this.startDate).getTime();
      const currentTime = new Date().getTime();
      let timeDifference = startTime - currentTime;

      if (timeDifference <= 0) {
        this.remainingTime = '00:00:00';
      } else {
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        timeDifference -= hours * (1000 * 60 * 60);
        const minutes = Math.floor(timeDifference / (1000 * 60));
        timeDifference -= minutes * (1000 * 60);
        const seconds = Math.floor(timeDifference / 1000);

        this.remainingTime = new Intl.DateTimeFormat('de-DE', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }).format(new Date(0, 0, 0, hours, minutes, seconds));
      }
    }
  }
}
