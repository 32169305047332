import { ChallengeStatus } from 'src/app/models/challenge/challenge-status.enum';
import { MS_IN_ONE_DAY } from 'src/app/constants/constants';
import { Helpers } from 'src/app/helpers/helpers';

export interface IChallengeInitializer {
  id?: string;
  name?: string;
  startDate?: string;
  endDate?: string;
  features?: Array<string>;
  projectPlanSent?: boolean;
}

export abstract class Challenge {
  public id: string = null;
  public name: string = null;
  public startDate: string = null; // Use ISO datetime to avoid unexpected behaviors.
  public endDate: string = null; // Use ISO datetime to avoid unexpected behaviors.
  public features: Set<string> = new Set();
  public projectPlanSent: boolean = false;

  constructor(iChallengeInitializer: IChallengeInitializer = {}) {
    Object.assign(this, iChallengeInitializer);
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      const auxDate = this.startDate;
      this.startDate = this.endDate;
      this.endDate = auxDate;
    }

    if (iChallengeInitializer.features) {
      this.features = new Set(iChallengeInitializer.features);
    }
  }

  public toObject(): IChallengeInitializer {
    const { features, ...otherProperties } = this;
    return {
      ...otherProperties,
      features: Array.from(features),
    };
  }
  public getStatus(): ChallengeStatus {
    const today = Helpers.createISODateTime();
    if (today < this.startDate) {
      return ChallengeStatus.Pending;
    } else if (today > this.endDate) {
      return ChallengeStatus.Completed;
    } else {
      return ChallengeStatus.InProgress;
    }
  }

  public getDaysDuration(): number {
    if (this.startDate && this.endDate) {
      const endTime = new Date(this.endDate).getTime();
      const startTime = new Date(this.startDate).getTime();
      return Math.floor((endTime - startTime) / MS_IN_ONE_DAY) + 1;
    } else {
      return -1;
    }
  }

  public isPending(): boolean {
    return this.getStatus() === ChallengeStatus.Pending;
  }
  public isCompleted(): boolean {
    return this.getStatus() === ChallengeStatus.Completed;
  }
  public isInProgress(): boolean {
    return this.getStatus() === ChallengeStatus.InProgress;
  }
  public hasFeature(feature: string): boolean {
    return this.features.has(feature);
  }
}
