<mat-card class="card-container">
  <mat-card-actions *ngIf="!viewOnly" align="end" class="action-buttons">
    <button mat-icon-button color="none" aria-label="Button with a delete icon" (click)="removeCityInfo()">
      <mat-icon class="trash-icon" [svgIcon]="trashIcon"></mat-icon>
    </button>
    <button mat-icon-button color="none" aria-label="Button with a edit icon" (click)="editCityInfo()">
      <mat-icon class="pencil-icon" [svgIcon]="pencilIcon"></mat-icon>
    </button>
  </mat-card-actions>
  <p class="color-primary">{{ cityInfo.cityInfoType }}</p>
  <mat-card-header class="header">
    <mat-card-title class="color-primary">{{ cityInfo.name }}</mat-card-title>
    <mat-card-subtitle>{{ cityInfo.subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <p *ngIf="cityInfo.link" class="info-link">
    <a [href]="cityInfo.link" target="_blank">{{ 'CITY_INFO_CARD.LINK' | translate }}
    </a>
    <mat-icon>link</mat-icon>
  </p>
  <div class="image-container">
    <div [@animSlider]="{ value: currentIndex, params: { direction: animationDirection > 0 ? -100 : 100 } }">
      <img mat-card-image [src]="cityInfo.details[currentIndex].image" alt="City info image">
      <p class="image-rights">{{ cityInfo.details[currentIndex].imageRights }}</p>
      <mat-card-content class="image-info">
        <p>{{ cityInfo.details[currentIndex].description }}</p>
      </mat-card-content>
    </div>
  </div>
  <mat-card-actions class="navigation-buttons">
    <button mat-mini-fab color="primary" [disabled]="cityInfo.details.length <= 1" aria-label="Button to move to previous image" (click)="changeImage(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-mini-fab color="primary" [disabled]="cityInfo.details.length <= 1" aria-label="Button to move to next image" (click)="changeImage(1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
