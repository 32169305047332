import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmButton', { static: false }) confirmButton: MatButton;
  public sanitizedUrl: SafeUrl;
  private cropper!: Cropper;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public image: string
  ) {}

  ngOnInit(): void {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.image);
  }

  ngAfterViewInit(): void {
    this.initCropper();
    this.confirmButton.focus();
  }

  private initCropper(): void {
    const image = document.getElementById('image') as HTMLImageElement;
    this.cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
      guides: false,
    });
  }

  // make the crop box rounded
  private getRoundedCanvas(sourceCanvas: HTMLCanvasElement): HTMLCanvasElement {
    if (sourceCanvas) {
      const canvas = document.createElement('canvas');
      const context: CanvasRenderingContext2D = canvas.getContext('2d');
      const width = sourceCanvas.width;
      const height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true
      );
      context.fill();
      return canvas;
    } else {
      return null;
    }
  }

  public crop(): void {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    const roundedCanvas = this.getRoundedCanvas(croppedCanvas);

    if (roundedCanvas) {
      this.dialogRef.close(roundedCanvas.toDataURL('image/png', 0.8));
    } else {
      this.dialogRef.close(null);
    }
  }

  public cancel(): void {
    this.cropper.clear();
    this.dialogRef.close(null);
  }
}
