<div class="container" fxLayout.xs="column space-between" fxLayout.sm="column space-between">
  <h1 class="title">Admin</h1>
  <mat-tab-group [selectedIndex]="tabIndex">
    <mat-tab [label]="ADMIN_TAB_COMPANIES">
      <div class="buttons">
        <button mat-raised-button (click)="goToAddCompany()">
          {{ 'ADMIN_NEW_COMPANY_BUTTON' | translate }} <mat-icon>add</mat-icon>
        </button>
        <mat-checkbox [disabled]="showSpinnerCompany" color="primary" [(ngModel)]="showTestCompanies" (change)="toggleTestCompanies()">Show test companies</mat-checkbox>
      </div>
      <div class="spinner-container" *ngIf="showSpinnerCompany">
        <mat-spinner></mat-spinner>
      </div>
      <mat-tab-group *ngIf="!showSpinnerCompany" class="company-tables">
        <mat-tab [label]="'Active'">
          <app-companies-table [companies]="activeCompanies"></app-companies-table>
        </mat-tab>
        <mat-tab [label]="'In Preparation'">
          <app-companies-table [companies]="inPreparationCompanies"></app-companies-table>
        </mat-tab>
        <mat-tab [label]="'Inactive'">
          <app-companies-table [companies]="inactiveCompanies"></app-companies-table>
        </mat-tab>
        <mat-tab label="{{ 'CREATE_COMPANY.PROJECT_MANAGER_FORM.PROJECT_MANAGER' | translate}}">
          <app-companies-table [companies]="pmCompanies"></app-companies-table>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <mat-tab [label]="'Users'">
      <app-users-tab>

      </app-users-tab>
      <!-- TODO: extract tabs in components -->
    </mat-tab>

    <mat-tab [label]="'Challenges'">
      <div class="challenges-buttons">
        <button mat-raised-button [ngClass]="selectedChallenge === CHALLENGES.PERSONAL ? 'activated' : ''" (click)="setSelectedChallenge(CHALLENGES.PERSONAL)">
          <mat-icon>directions_run</mat-icon>
          <p>Personal Challenges</p>
        </button>
        <button mat-raised-button [ngClass]="selectedChallenge === CHALLENGES.TEAM ? 'activated' : ''" (click)="setSelectedChallenge(CHALLENGES.TEAM)">
          <mat-icon>groups</mat-icon>
          <p>Team Challenges</p>
        </button>
        <button mat-raised-button [ngClass]="selectedChallenge === CHALLENGES.GROUP_RUN ? 'activated' : ''" (click)="setSelectedChallenge(CHALLENGES.GROUP_RUN)">
          <mat-icon>domain</mat-icon>
          <p>Company Group Run</p>
        </button>
      </div>
      <div *ngIf="selectedChallenge === CHALLENGES.PERSONAL">
        <button mat-raised-button (click)="openNewChallengeDialog(null)">
          {{ 'New Personal Challenge' | translate }} <mat-icon>add</mat-icon>
        </button>
        <table mat-table [dataSource]="personalChallengesDataSource" matSort>
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.name }}
            </td>
          </ng-container>

          <!-- Fixed Date Column -->
          <ng-container matColumnDef="fixedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Fixed Date?
            </th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.hasFixedDate }}
            </td>
          </ng-container>

          <!-- Start Date Column -->
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Points to Hold Level
            </th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.pointsToHoldLevel }}
            </td>
          </ng-container>

          <!-- End Date Column -->
          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Points to Level Up
            </th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.pointsToLevelUp }}
            </td>
          </ng-container>

          <!-- Initial Level Column -->
          <ng-container matColumnDef="initialLevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Initial Level
            </th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.initialLevel }}
            </td>
          </ng-container>

          <!-- Minimal Level Column -->
          <ng-container matColumnDef="minLevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Min Level
            </th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.minLevel }}
            </td>
          </ng-container>

          <!-- Maximal Level Column -->
          <ng-container matColumnDef="maxLevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Max Level
            </th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.maxLevel }}
            </td>
          </ng-container>

          <!-- Level Duration Column -->
          <ng-container matColumnDef="levelDuration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Level Duration
            </th>
            <td mat-cell *matCellDef="let personalChallenge">
              {{ personalChallenge.levelDuration + ' days' }}
            </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let challenge"
              (click)="openNewChallengeDialog(challenge)"
            >
              <mat-icon>edit_outline</mat-icon>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let challenge"
              (click)="removeChallenge(challenge)"
            >
              <mat-icon>delete_outline</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="personalChallengeColumns"></tr>
          <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: personalChallengeColumns"></tr>
        </table>
      </div>
      <div *ngIf="selectedChallenge === CHALLENGES.TEAM">
        <button mat-raised-button (click)="openNewChallengeDialog(null)">
          {{ 'New Team Challenge' | translate }} <mat-icon>add</mat-icon>
        </button>
        <table mat-table [dataSource]="teamChallengesDataSource" matSort>
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let teamChallenge">
              {{ teamChallenge.name }}
            </td>
          </ng-container>

          <!-- Teams Amount Column -->
          <ng-container matColumnDef="teamsAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Number of Teams
            </th>
            <td mat-cell *matCellDef="let teamChallenge">
              {{ teamChallenge.teamIds.size }}
            </td>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let challenge"
              (click)="openNewChallengeDialog(challenge)"
            >
            <mat-icon>edit_outline</mat-icon>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let challenge"
              (click)="removeChallenge(challenge)"
            >
              <mat-icon>delete_outline</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="teamChallengeColumns"></tr>
          <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: teamChallengeColumns"></tr>
        </table>
      </div>
      <div *ngIf="selectedChallenge === CHALLENGES.GROUP_RUN">
        <button mat-raised-button (click)="openNewChallengeDialog(null)">
          {{ 'New Challenge' | translate }} <mat-icon>add</mat-icon>
        </button>

        <section>
          <div class="filter-container">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'GROUP_RUN_CHALLENGE_DIALOG.TABLE.FILTER.LABEL' | translate }}</mat-label>
              <input matInput (keyup)="applyFilterToGroupRunChallenges($event)" [placeholder]="'GROUP_RUN_CHALLENGE_DIALOG.TABLE.FILTER.PLACEHOLDER' | translate " #input>
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <table
            mat-table
            [dataSource]="groupRunChallengesDataSource"
            matSort
          >

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GROUP_RUN_CHALLENGE_DIALOG.TABLE.NAME' | translate}}</th>
              <td mat-cell *matCellDef="let company">
                {{ company.name }}
              </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let groupRunChallenge" (click)="openNewChallengeDialog(groupRunChallenge)">
                <mat-icon>edit_outline</mat-icon>
              </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let groupRunChallenge" (click)="removeChallenge(groupRunChallenge)">
                <mat-icon>delete_outline</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="groupRunChallengeColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: groupRunChallengeColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="3">{{ 'GROUP_RUN_CHALLENGE_DIALOG.TABLE.FILTER.MESSAGE' | translate}} "{{input.value}}"</td>
            </tr>
          </table>

          <mat-paginator pageSize="10" aria-label="Group run challenges table"></mat-paginator>
        </section>
      </div>
    </mat-tab>

    <mat-tab [label]="'Cities'">
      <div class="buttons">
        <button mat-raised-button (click)="goToAddCity()">
          New City
          <mat-icon>add</mat-icon>
        </button>
        <button mat-raised-button (click)="goToImportCities()">
          Import Cities
          <mat-icon>publish</mat-icon>
        </button>
        <!-- <button mat-raised-button (click)="updateCities()">
          Update Cities
        </button> -->
      </div>
      <table mat-table [dataSource]="cityDataSource" matSort *ngIf="!showSpinnerCity">
        <!-- Index Column -->
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let city; let i = index">{{ i }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let city">{{ city.name }}</td>
        </ng-container>

        <!-- Latitude Column -->
        <ng-container matColumnDef="lat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Latitude</th>
          <td mat-cell *matCellDef="let city">{{ city.lat }}</td>
        </ng-container>

        <!-- Longitude Column -->
        <ng-container matColumnDef="lng">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Longitude</th>
          <td mat-cell *matCellDef="let city">{{ city.lng }}</td>
        </ng-container>

        <!-- Flag Column -->
        <ng-container matColumnDef="flag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Flag</th>
          <td mat-cell *matCellDef="let city"><img height="24px" width="32px"
              [src]="getFlagPath(city.countryAlphaCode)"
              alt="{{ city.name }} Flag" /></td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let city" (click)="goToCity(city.id)">
            <mat-icon>edit_outline</mat-icon>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let city" (click)="removeCity(city)">
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <!-- Info Column -->
        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let city" (click)="goToCityInfo(city.id)">
            <mat-icon>info_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="cityColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: cityColumns"></tr>
      </table>
      <div class="spinner-container" *ngIf="showSpinnerCity">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>

    <mat-tab [label]="'Routes'">
      <div class="buttons">
        <button mat-raised-button (click)="goToAddRoute()">
          {{ 'EDIT_ROUTE.TITLE.NEW_ROUTE' | translate }}
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <app-routes-table [routes]="routes" *ngIf="!showSpinnerRoutes" [selectable]="false"></app-routes-table>
      <div class="spinner-container" *ngIf="showSpinnerRoutes">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>

    <mat-tab [label]="'Teams'">
      <div class="buttons">
        <button mat-raised-button (click)="goToAddTeam()">
          {{ 'ADMIN_NEW_TEAM_BUTTON' | translate }}
          <mat-icon>add</mat-icon>
        </button>
        <!-- TODO -->
        <!-- <button mat-raised-button (click)="goToImportCities()">
          Import Cities
        </button> -->
      </div>
      <table mat-table [dataSource]="teamDataSource" matSort *ngIf="!showSpinnerTeams">
        <!-- Avatar Column -->
        <ng-container matColumnDef="avatar">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let team" class="avatar">
            <img *ngIf="team.avatar !== 'assets/img/move-me-new.png'" src="{{ team.avatar }}" alt="avatar" />
            <div *ngIf="team.avatar === 'assets/img/move-me-new.png'">
              <mat-icon>do_not_disturb</mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let team">{{ team.name }}</td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let team">{{ team.id }}</td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Color</th>
          <td mat-cell *matCellDef="let team" [ngStyle]="{ 'color': team.color }">{{ team.color }}</td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let team" (click)="goToTeam(team.id)">
            <mat-icon>edit_outline</mat-icon>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let team" (click)="removeTeam(team)">
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="teamsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: teamsColumns"></tr>
      </table>
      <div class="spinner-container" *ngIf="showSpinnerTeams">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>

    <mat-tab [label]="'Points Sources'">
      <div class="buttons">
        <button mat-raised-button (click)="goToAddPointsSource()">
          {{ 'New Points Source' | translate }}
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <table mat-table [dataSource]="pointsSourcesDataSource" matSort *ngIf="!showSpinnerPointsSources">
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let pointsSource">{{ pointsSource.id }}</td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let pointsSource">{{ pointsSource.description }}</td>
        </ng-container>

        <!-- Expected Performance Column -->
        <ng-container matColumnDef="expectedPerformance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Expected Performance</th>
          <td mat-cell *matCellDef="let pointsSource">{{ pointsSource.expectedPerformance | number: '1.0-0':'de' }}</td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let pointsSource">{{ pointsSourceTypesNames[pointsSource.type] }}</td>
        </ng-container>

        <!-- Basis Column -->
        <ng-container matColumnDef="basis">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Basis</th>
          <td mat-cell *matCellDef="let pointsSource">{{ pointsSourceBasisNames[pointsSource.basis] }}</td>
        </ng-container>

        <!-- Points Awarded Column -->
        <ng-container matColumnDef="pointsAwarded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Points Awarded</th>
          <td mat-cell *matCellDef="let pointsSource">{{ pointsSource.pointsAwarded | number: '1.0-0':'de' }}</td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let pointsSource" (click)="goToPointsSource(pointsSource.id)">
            <mat-icon>edit_outline</mat-icon>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let pointsSource" (click)="removePointsSource(pointsSource)">
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="pointsSourcesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pointsSourcesColumns"></tr>
      </table>
      <div class="spinner-container" *ngIf="showSpinnerPointsSources">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>

    <mat-tab [label]="'White Labels'">
      <div class="buttons">
        <button mat-raised-button (click)="goToAddWhiteLabel()">
          {{ 'New White Label' | translate }} <mat-icon>add</mat-icon>
        </button>
      </div>
      <table mat-table [dataSource]="whiteLabelDataSource" matSort *ngIf="!showSpinnerWhiteLabels">
        <!-- Logo Column -->
        <ng-container matColumnDef="logo">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let whiteLabel" class="avatar">
            <img src="{{ whiteLabel.logo }}" alt="logo" />
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let whiteLabel">{{ whiteLabel.name }}</td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Color</th>
          <td mat-cell *matCellDef="let whiteLabel" [ngStyle]="{ 'color': whiteLabel.color }">{{ whiteLabel.color }}</td>
        </ng-container>

        <!-- Button Column -->
        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Button?</th>
          <td mat-cell *matCellDef="let whiteLabel">{{ whiteLabel.button ? whiteLabel.button.text : 'No' }}</td>
        </ng-container>

        <!-- Edit Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let whiteLabel" (click)="goToWhiteLabel(whiteLabel)">
            <mat-icon>edit_outline</mat-icon>
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let whiteLabel" (click)="removeWhiteLabel(whiteLabel)">
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="whiteLabelsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: whiteLabelsColumns"></tr>
      </table>
      <div class="spinner-container" *ngIf="showSpinnerWhiteLabels">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>

    <mat-tab [label]="('forms.notification.PUSH_NOTIFICATION' | translate)">
      <app-send-notification [adminPage]="true"></app-send-notification>
    </mat-tab>

    <mat-tab [label]="'Generate Reports'">
      <div class="report-container">
        <ngx-qrcode-styling *ngIf="qrConfig" [config]="qrConfig" id="qrcode" [ngStyle]="{'display': 'none'}"></ngx-qrcode-styling>
        <form [formGroup]="reportsForm" class="report-form">
          <mat-form-field>
            <mat-select formControlName="company" [placeholder]="'ADMIN_PAGE.GENERATE_REPORTS_TAB.SELECT_A_COMPANY' | translate"
              (selectionChange)="selectCompanyToReport()">
              <mat-option *ngFor="let company of companies" [value]="company">
                {{ company.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="company-image-container" *ngIf="companyToReport">
            <img #companyImage class="company-image"
              [src]="reportsForm.value.company.avatar">
          </div>
          <mat-form-field *ngIf="companyToReport">
            <mat-select formControlName="report" [placeholder]="'ADMIN_PAGE.GENERATE_REPORTS_TAB.SELECT_A_REPORT' | translate"
              (selectionChange)="selectReportToGenerate()">
              <mat-option *ngFor="let postingReport of postingReports" [value]="postingReport.type">
                {{ postingReport.translation | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="companyChallenges">
            <mat-select formControlName="challenge" [placeholder]="'ADMIN_PAGE.GENERATE_REPORTS_TAB.SELECT_A_CHALLENGE' | translate"
              (selectionChange)="selectChallengeToReport()">
              <mat-option *ngFor="let challenge of companyChallenges" [value]="challenge">
                {{ challenge.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="availableTeamChallengeWeeks">
            <mat-select formControlName="week" [placeholder]="'ADMIN_PAGE.GENERATE_REPORTS_TAB.SELECT_A_WEEK' | translate"
              (selectionChange)="selectWeekToReport()">
              <mat-option *ngFor="let week of availableTeamChallengeWeeks" [value]="week">
                {{ week.id + ' ('+ week.weekdays[0] + ' - ' + week.weekdays[week.weekdays.length - 1] + ') '}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="buttons">
            <button mat-raised-button *ngIf="blobFiles.length === 0"
              [disabled]="!reportsForm.valid || isGeneratingReport ||
                ((reportToGenerate === REPORTTYPE_TEAMCHALLENGE ) &&
                !qrReady)"
              (click)="generateReport()">
              {{ 'GENERATE_REPORT' | translate }}
              <mat-spinner [diameter]="16" *ngIf="isGeneratingReport"></mat-spinner>
            </button>
            <input #inputUploadFile hidden="true" type="file" onclick="this.value=null" (change)="previewFile($event)"
              accept=".pdf" />
            <button mat-raised-button *ngIf="blobFiles.length === 0"
              [disabled]="!(companyToReport && reportToGenerate) || isGeneratingReport"
              (click)="inputUploadFile.click()">
              {{ 'SELECT_PDF_FILE' | translate }}
            </button>
            <button mat-raised-button *ngIf="blobFiles.length > 0"
              [disabled]="!pendingReports"
              (click)="uploadPendingReports()">
              {{ 'UPLOAD_ALL' | translate }}
            </button>
            <button mat-raised-button *ngIf="blobFiles.length > 0"
              [disabled]="!pendingReports"
              (click)="discardPendingReports()">
              {{ 'DISCARD_ALL' | translate }}
            </button>
          </div>
        </form>
      </div>
      <div>
        <mat-card class="file-card" *ngFor="let blobFile of blobFiles">
          <div class="title">
            {{blobFile.title}}
          </div>
          <div class="buttons-container">
            <button mat-icon-button aria-label="Open in browser" (click)="openBlob(blobFile.blob)">
              <mat-icon>file_open</mat-icon>
            </button>
            <a #downloadFile [href]="blobFile.fileURL" [download]="blobFile.title"></a>
            <button (click)="downloadFile.click()" mat-icon-button aria-label="Download from browser" *ngIf="blobFile.fileURL">
              <mat-icon>cloud_download</mat-icon>
            </button>
            <button mat-icon-button aria-label="Save file" (click)="uploadPdfFile(blobFile)" *ngIf="blobFile.status === REPORT_PENDING">
              <mat-icon>cloud_upload</mat-icon>
            </button>
            <button mat-icon-button aria-label="Uploading file" *ngIf="blobFile.status === REPORT_UPLOADING">
              <mat-spinner [diameter]="16"></mat-spinner>
            </button>
            <button mat-icon-button aria-label="File saved" (click)="showMessage(successMessage)" *ngIf="blobFile.status === REPORT_SAVED">
              <mat-icon>cloud_done</mat-icon>
            </button>
            <button mat-icon-button aria-label="File error" (click)="showMessage(errorMessage)" *ngIf="blobFile.status === REPORT_ERROR">
              <mat-icon>error</mat-icon>
            </button>
            <button mat-icon-button aria-label="Discard file" (click)="discardFile(blobFile)" [disabled]="blobFile.status !== REPORT_PENDING">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
  </mat-tab>
  </mat-tab-group>
</div>
