<table mat-table [dataSource]="pushNotificationsDataSource" multiTemplateDataRows matSort class="mat-elevation-z8">
  <!-- Title Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SENT_PUSH_NOTIFICATIONS_TABLE.TITLE' | translate }}</th>
    <td mat-cell *matCellDef="let notification">{{ notification.title.get(currentLang) }}</td>
  </ng-container>

  <!-- Date Sent Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SENT_PUSH_NOTIFICATIONS_TABLE.DATE_SENT' | translate }}</th>
    <td mat-cell *matCellDef="let notification">{{ notification.id | date: 'dd.MM.yyyy' }}</td>
  </ng-container>

  <!-- Recipient Companies Column -->
  <ng-container matColumnDef="recipientCompanies">
    <th mat-header-cell *matHeaderCellDef>{{ 'SENT_PUSH_NOTIFICATIONS_TABLE.RECIPIENT_COMPANIES' | translate }}</th>
    <td mat-cell *matCellDef="let notification">
      {{ showRecipientCompanies(notification.recipientCompanyIds) }}
    </td>
  </ng-container>

  <!-- Expanded Row -->
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let notification">
      <button mat-icon-button aria-label="expand row" (click)="(expandedNotification = expandedNotification === notification ? null : notification); $event.stopPropagation()">
        <mat-icon *ngIf="expandedNotification !== notification">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedNotification === notification">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let notification" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="notification-detail" *ngIf="notification == expandedNotification" [@detailExpand]="notification == expandedNotification ? 'expanded' : 'collapsed'">
        <div class="notification-description">
          <div class="recipient-companies-container">
            <p class="label">{{ 'SENT_PUSH_NOTIFICATIONS_TABLE.DETAILS.SENT_TO' | translate }}</p>
            <p>{{ showAllRecipientCompanies(notification.recipientCompanyIds) }}</p>
          </div>
          <div class="title-container">
            <p class="label">{{ 'SENT_PUSH_NOTIFICATIONS_TABLE.DETAILS.TITLE' | translate }}</p>
            <p>{{ notification.title.get(currentLang) }}</p>
          </div>
          <div class="body-container">
            <p class="label">{{ 'SENT_PUSH_NOTIFICATIONS_TABLE.DETAILS.BODY' | translate }}</p>
            <p>{{ notification.message.get(currentLang) }}</p>
          </div>
          <div class="link-container" *ngIf="notification.link">
            <p class="label">{{ 'SENT_PUSH_NOTIFICATIONS_TABLE.DETAILS.LINK' | translate }}</p>
            <p><a [href]="notification.link">{{ notification.link }}</a></p>
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let notification; columns: columnsToDisplayWithExpand;"
      class="notification-row"
      [class.example-expanded-row]="expandedNotification === notification"
      (click)="expandedNotification = expandedNotification === notification ? null : notification">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="notification-detail-row"></tr>
</table>
