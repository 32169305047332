import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { Settings } from 'src/app/models/settings.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public settings: Settings;

  constructor(
    private translateService: TranslateService,
    private localstorageService: LocalstorageService
  ) {
    const settings = this.localstorageService.getSettings();

    if (settings !== undefined && settings !== null) {
      this.settings = settings;
      this.translateService.use(this.settings.lang);
    } else {
      this.initializeSettings();
    }
  }

  public changeLanguage(lang: string): void {
    this.translateService.use(lang);
    this.settings.lang = lang;
  }

  private initializeSettings(): void {
    this.settings = new Settings();
    this.settings.lang = this.translateService.currentLang;
  }
}
