<div class="container">
  <div>
    <h1 class="title">{{ 'ADMIN_TEAMS_TEAMS' | translate }}</h1>
  </div>
  <div *ngIf="!showSpinner">
    <div>
      <mat-card class="cards">
        <p class="card-title">Teams amount</p>
        <p class="card-number">{{ teams.length }}</p>
      </mat-card>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <!-- Avatar Column -->
      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let team" class="avatar">
          <img src="{{ team.avatar }}" alt="avatar" />
        </td>
      </ng-container>

      <!-- Team name Column -->
      <ng-container matColumnDef="teamName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
        <td mat-cell *matCellDef="let team">{{ team.name }}</td>
      </ng-container>

      <!-- Total Steps Column -->
      <ng-container matColumnDef="totalSteps">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total steps</th>
        <td mat-cell *matCellDef="let team">{{ team.totalSteps | number: '1.0-0':'de' }}</td>
      </ng-container>

      <!-- Total Points Column -->
      <ng-container matColumnDef="totalPoints">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Points</th>
        <td mat-cell *matCellDef="let team">{{ team.totalPoints | number: '1.0-0':'de' }}</td>
      </ng-container>

      <!-- Current challenge Id Column -->
      <ng-container matColumnDef="currentChallenge">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Current Challenge
        </th>
        <td mat-cell *matCellDef="let team">{{ team.currentTeamChallengeId }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="teams-tabs spinner-container" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
