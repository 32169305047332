// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Testing Database
export const environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyCDvEXplPdl_1Awuss8x0oTnlVO-MdaV2E',
    authDomain: 'move-me-test.firebaseapp.com',
    projectId: 'move-me-test',
    storageBucket: 'move-me-test.appspot.com',
    messagingSenderId: '1015475393142',
    appId: '1:1015475393142:web:6b0679b4c73d9ba4b08557',
    dynamicLinkDomain: 'https://movemereportstest.page.link',
  },
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  salesEmails: ['gdeamicis@move-me-service.com'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
