import { Deserializable } from './deserializable.model';

export interface IWhiteLabelButtonInitializer {
  color?: string;
  link?: string;
  text?: string;
}

export class WhiteLabelButton implements Deserializable {
  public color: string;
  public link: string;
  public text: string;

  constructor(initializer: IWhiteLabelButtonInitializer) {
    this.color = initializer.color ? initializer.color : null;
    this.link = initializer.link ? initializer.link : null;
    this.text = initializer.text ? initializer.text : null;
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  public toObject(): any {
    return Object.assign({}, this);
  }
}
