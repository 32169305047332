import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import * as XLSX from 'xlsx';
import { AdminTabs } from '../admin-tabs.enum';
import { City } from 'src/app/models/cities-challenge/city/city.model';

@Component({
  selector: 'app-import-cities',
  templateUrl: './import-cities.component.html',
  styleUrls: ['./import-cities.component.scss'],
})
export class ImportCitiesComponent {
  public fileDataForm: UntypedFormGroup;

  public showSpinner = false;
  public showTable = false;
  public showImportProgress = false;

  public importProgress = 0;
  public importingCityName = '';
  public importingIndex = 0;

  public citiesFileData = null;

  public citiesDataSource = new MatTableDataSource();
  public citiesColumns: Array<string> = [
    'flag',
    'name',
    'latitude',
    'longitude',
    'countryAlphaCode',
    'translationEn',
    'translationDe',
  ];

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService
  ) {
    this.fileDataForm = this.formBuilder.group({
      file: ['', Validators.required],
      fileSource: ['', Validators.required],
    });
  }

  public returnPage(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Cities } });
  }

  public onFileChange(event: Event): void {
    this.showTable = false;
    const input = event.target as HTMLInputElement;
    if (input.files.length > 0) {
      const file = input.files[0];
      const filereader = new FileReader();
      filereader.onload = (eventReader): void => {
        const data = eventReader.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        workbook.SheetNames.forEach((sheet) => {
          this.citiesFileData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheet]
          );
        });
        this.citiesDataSource = new MatTableDataSource(this.citiesFileData);
        this.showTable = true;
      };
      filereader.readAsBinaryString(file);
      this.fileDataForm.patchValue({
        fileSource: file,
      });
    }
  }

  public async import(): Promise<void> {
    this.showImportProgress = true;
    for (const [index, element] of this.citiesFileData.entries()) {
      this.importingIndex = index;
      this.importingCityName = element.name;
      const city = new City({
        id:
          element.countryAlphaCode.replace(/\s/g, '').toLowerCase() +
          '-' +
          element.name,
        name: element.name,
        lat: Number(element.latitude),
        lng: Number(element.longitude),
        countryAlphaCode: element.countryAlphaCode
          .replace(/\s/g, '')
          .toLowerCase(),
        translations: new Map<string, string>([
          ['de', element.de],
          ['en', element.en],
        ]),
      });

      await this.firebaseService.createUpdateCity(city);

      this.importProgress = ((index + 1) / this.citiesFileData.length) * 100;
    }
  }
}
