import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Option {
  value: any;
  label: string;
}

interface UpdatePropertyDialogData {
  currentSelection: any;
  options: Array<Option>;
  propertyToUpdate: string;
  object: any;
}

@Component({
  selector: 'app-select-property-dialog',
  templateUrl: './select-property-dialog.component.html',
  styleUrls: ['./select-property-dialog.component.scss'],
})
export class SelectPropertyDialogComponent implements OnInit {
  public selectedOption: string;

  constructor(
    public dialogRef: MatDialogRef<SelectPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdatePropertyDialogData
  ) {}

  ngOnInit(): void {
    this.selectedOption = String(this.data.currentSelection);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async selectedValue(): Promise<void> {
    this.data.object[this.data.propertyToUpdate] = this.selectedOption;
  }
}
