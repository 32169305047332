import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';

// Models
import { Company } from 'src/app/models/company.model';
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { PointsSource } from 'src/app/models/points-source.model';
import { PointsSourceType } from 'src/app/models/points-source-type.enum';
import { PointsSourceBasis } from 'src/app/models/points-source-basis.enum';
import { LicenceTypes } from 'src/app/models/licence-types.enum';
import { Team } from 'src/app/models/team.model';
import { Training } from 'src/app/models/training.model';
import { CompanyStatus } from 'src/app/models/company-status.enum';
import { TeamSchema } from 'src/app/models/team-schema.model';
import { Location } from 'src/app/models/location.model';
import { Features } from 'src/app/models/features.enum';
import { User } from 'src/app/models/user.model';
import { MessageData } from 'src/app/models/message-data';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge.model';

// Services
import { CompanyService } from 'src/app/services/company/company.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ExcelService } from 'src/app/services/excel/excel.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';

// Constants
import { EventStatus } from 'src/app/models/event/event-status.enum';

// Components
import { CompanyStatusDialogComponent } from 'src/app/shared/components/company-status-dialog/company-status-dialog.component';
import { AddRandomTeamsDialogComponent } from 'src/app/shared/components/add-random-teams-dialog/add-random-teams-dialog.component';
import { CompanyIsTestDialogComponent } from 'src/app/shared/components/company-is-test-dialog/company-is-test-dialog.component';
// eslint-disable-next-line max-len
import { CompanyUpdateAvailableLicencesComponent } from 'src/app/shared/components/company-update-available-licences/company-update-available-licences.component';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { WhiteLabel } from 'src/app/models/white-label.model';
import { WhiteLabelDetails } from 'src/app/models/white-label-details.model';
import { AdminTabs } from '../admin-tabs.enum';
import { Locations } from 'src/app/models/locations.model';
import { LinkLocationsDialogComponent } from 'src/app/shared/components/link-locations-dialog/link-locations-dialog.component';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { EventsService } from 'src/app/services/events/events.service';
import { Event as EventModel } from 'src/app/models/event/event';
import { MatSort } from '@angular/material/sort';
import { _isNumberValue } from '@angular/cdk/coercion';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { TranslateService } from '@ngx-translate/core';
import {
  IChallengeData,
  ProjectManagerCompanyService,
} from 'src/app/services/project-manager-company/project-manager-company.service';
import { CompanyTrainingsStatistics } from 'src/app/models/company-trainings-statistics.model';
import { Notification } from 'src/app/models/notification.model';
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { MatButton } from '@angular/material/button';
import { TeamChallengeService } from 'src/app/services/team-challenge/team-challenge.service';

interface IDataFormDictionary {
  1: UntypedFormGroup;
  2: UntypedFormGroup;
  3: UntypedFormGroup;
}

interface ILevelLocationsDictionary {
  1: Array<Location>;
  2: Array<Location>;
  3: Array<Location>;
}

interface IDataSourcesDictionary {
  1: MatTableDataSource<Location>;
  2: MatTableDataSource<Location>;
  3: MatTableDataSource<Location>;
}

interface IConfirmationAlertText {
  title: string;
  message: string;
}

interface IUnitConverterOption {
  feature: Features;
  name: string;
}

export const enum TabIndex {
  CompanyData = 0,
  PersonalChallenges = 1,
  PointSources = 2,
  Teams = 3,
  TeamChallenges = 4,
  TeamChallengesV2 = 5,
  CitiesChallenges = 6,
  Events = 7,
  CurrentDuelChallenge = 8,
  CurrentCitiesChallenge = 9,
  CurrentTeamChallenge = 10,
  UsersLocationList = 11,
}

const MAX_SAFE_INTEGER = 9007199254740991;
@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent implements OnInit, AfterViewInit {
  @ViewChild('chipList') chipList;
  @ViewChild('tableEvents', { read: MatSort }) eventsSort: MatSort;
  @ViewChildren('tableLocationLevel', { read: MatSort })
  sortLocationLevels: QueryList<MatSort>;

  public EVENT_NOT_STARTED = EventStatus.NotStarted;
  public EVENT_IN_PROGRESS = EventStatus.InProgress;
  public EVENT_COMPLETED = EventStatus.Completed;

  public tabIndex = 0;

  public teamsColumns: Array<string> = [
    'avatar',
    'name',
    'members',
    'totalPoints',
    'totalSteps',
  ];
  public personalChallengeColumns: Array<string> = [
    'name',
    'fixedDate',
    'startDate',
    'endDate',
    'initialLevel',
    'minLevel',
    'maxLevel',
    'levelDuration',
  ];
  public teamChallengeColumns: Array<string> = [
    'name',
    'startDate',
    'endDate',
    'currentWeek',
    'duration',
    'teamsAmount',
    'projectPlan',
    'edit',
  ];
  public pointsSourceColumns: Array<string> = [
    'id',
    'description',
    'basis',
    'expectedPerformance',
    'type',
    'pointsAwarded',
  ];

  public eventsColumns: Array<string> = [
    'name',
    'startDate',
    'endDate',
    'distance',
    'progress',
    'status',
  ];

  public FEATURES_WHITELABEL = Features.WhiteLabel;
  public FEATURES_LOCATIONS = Features.Locations;
  public FEATURES_PROJECT_MANAGER = Features.ProjectManager;
  public FEATURES_SET_AUTOMATIC_TEAM = Features.SetAutomaticTeam;
  public FEATURES_NOT_SET_AUTOMATIC_TEAM = Features.NotSetAutomaticTeam;
  public FEATURES_NOT_SHOW_WHITE_LABEL_LOGO_ON_REPORT =
    Features.NotShowWhiteLabelLogoOnReport;
  public FEATURES_ORGANISATION_BOARD = Features.OrganisationBoard;
  public FEATURES_EVENTS = Features.Events;
  public FEATURES_UNIT_CONVERTER = Features.UnitConverter;
  public companyDataForm: UntypedFormGroup;
  public adminEmails: Array<string> = [];

  // Chip list variables
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  public whiteLabelSelected: WhiteLabel = null;
  public showUpdateCompanySpinner = false;

  private CLEAN_COMPANY_PROJECT_CONFIRMATION: IConfirmationAlertText = {
    title: '',
    message: '',
  };

  public randomTeamsColumns: Array<string> = ['avatar', 'name', 'id', 'color'];

  public teamsDataSource = new MatTableDataSource();
  public personalChallengesDataSource = new MatTableDataSource();
  public teamChallengesDataSource = new MatTableDataSource();
  public pointsSourceDataSource = new MatTableDataSource();
  public locationDataSource = new MatTableDataSource();
  public eventsDataSource = new MatTableDataSource();

  public randomTeamsDataSource = new MatTableDataSource();

  public id = '';
  public company: Company;
  public locations: Array<Location> = [];

  public personalChallenges: Array<ChallengeSchema> = null;
  public teamChallenges: Array<TeamChallenge> = null;
  public teamChallengesV2: Array<TeamChallengeV2> = null;
  public pointsSources: Array<PointsSource> = null;
  public teams: Array<Team> = null;
  public events: Array<EventModel> = null;
  public citiesChallenges: Array<CitiesChallenge> = null;

  public showCompanySpinner = true;
  public showPersonalChallengesSpinner = true;
  public showTeamChallengesSpinner = true;
  public showTeamChallengesV2Spinner = true;
  public showPointsSourcesSpinner = true;
  public showTeamSpinner = true;
  public showEventsSpinner = true;
  public showCitiesChallengesSpinner = true;
  public showManagedCompaniesSpinner = true;
  public showPushNotificationsSpinner = true;

  public showRandomTeamsTable = false;
  public teamsToAdd: Array<TeamSchema> = [];
  public whiteLabelImgPreview = '';

  public trainings: Array<Training> = [];
  public accumulatedDistance: Array<number> = [];
  public messageData: MessageData = {
    showMessage: false,
    type: '',
    title: '',
    body: '',
  };

  public currentTeamChallengeWeeks: Array<any> = [];
  public currentTeamChallengeData: any = {};

  public currentCitiesChallengeTabLoaded = false;
  public currentDuelChallengeTabLoaded = false;

  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public licencesNames: Array<string> = Object.keys(LicenceTypes)
    .map((key) => LicenceTypes[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public usersLocations = [];
  public usersLocationsListShowSpinner = false;

  public disableButton = false;
  public usersTeamReportSpinnerFlagDisableButton = false;
  public spinnerFlag = false;
  public usersTeamReportSpinnerFlag = false;
  public reportDateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  public locationDataForm: UntypedFormGroup;
  public isEditActive = false;
  public editLevel = -1;
  public editIndex = -1;
  public levelsDataForms: IDataFormDictionary = {
    1: null,
    2: null,
    3: null,
  };

  public levelsDataSources: IDataSourcesDictionary = {
    1: null,
    2: null,
    3: null,
  };

  public levelsLocations: ILevelLocationsDictionary = {
    1: [],
    2: [],
    3: [],
  };

  public locationColumns = ['id', 'name', 'edit', 'links', 'delete'];

  public managedCompanies: Array<Company>;
  public companiesStatistics: Map<string, CompanyTrainingsStatistics>;
  public pushNotifications: Array<Notification>;
  public managedCompanyChallengesData: Map<
    string,
    Array<IChallengeData>
  > = new Map();

  public unitConverterList: Array<IUnitConverterOption> = [
    {
      feature: Features.BicycleConverter,
      name: 'UNIT_CONVERTER.BICYCLE_FEATURE',
    },
    {
      feature: Features.TrainingConverter,
      name: 'UNIT_CONVERTER.TRAINING_FEATURE',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private eventsService: EventsService,
    private teamsService: TeamsService,
    private dialog: MatDialog,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private firebaseService: FirebaseService, // TODO: replace with middleware
    private excelService: ExcelService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private projectManagerService: ProjectManagerCompanyService,
    private teamChallengeService: TeamChallengeService
  ) {
    this.getTranslations();
    this.companyDataForm = this.formBuilder.group({
      adminEmail: ['', [Validators.email]],
      availableLicenses: ['', Validators.required],
      avatar: ['assets/img/move-me-new.png'],
      avatarImg: [undefined],
      color: ['#f97a1c', Validators.required],
      companyId: ['', Validators.required],
      name: ['', Validators.required],
      managerCompanyIds: [[]],

      // New features must be added in alphabetical order.
      featureEvents: [false],
      featureJoinTeamCard: [false],
      featureLimitedLicenses: [false],
      featureLocations: [false],
      featureMarketplace: [false],
      featureNotSetAutomaticTeam: [false],
      featureNotShowWhiteLabelLogoOnReport: [false],
      featureOrganisationBoard: [false],
      featureProjectManager: [false],
      featureReferralProgramDisabled: [false],
      featureSetAutomaticTeam: [false],
      featureShareEmail: [false],
      featureTeamChallenge: [false],
      featureUnitConverter: [],
      featureWhiteLabel: [false],

      whiteLabelLogo: [''],
      whiteLabelButtonColor: [''],
      whiteLabelButtonText: [''],
      whiteLabelButtonLink: [''],
    });

    for (const level of [1, 2, 3]) {
      this.levelsDataForms[level] = this.formBuilder.group({
        titleEN: [null, Validators.required],
        titleDE: [null, Validators.required],
        selectorEN: [null, Validators.required],
        selectorDE: [null, Validators.required],
        name: [null, Validators.required],
        id: [{ value: null, disabled: true }, Validators.required],
      });
    }

    this.locationDataForm = this.formBuilder.group({
      levels: [0, Validators.compose([Validators.min(1), Validators.max(3)])],
    });
  }

  get statusEnumKeys(): Array<number> {
    return Object.keys(CompanyStatus)
      .map((key) => CompanyStatus[key])
      .filter((value) => typeof value === 'number') as Array<number>;
  }

  get statusEnumNames(): Array<string> {
    return Object.keys(CompanyStatus)
      .map((key) => CompanyStatus[key])
      .filter((value) => typeof value === 'string') as Array<string>;
  }

  get adminTeamSchemas(): Array<TeamSchema> {
    return this.adminService.teams;
  }

  get adminEmail(): AbstractControl {
    return this.companyDataForm.get('adminEmail');
  }

  get whiteLabels(): Array<WhiteLabel> {
    return this.adminService.whiteLabels;
  }

  get managerCompanies(): Array<Company> {
    return this.adminService.managerCompanies;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    if (window.history?.state?.companyInfoTab) {
      this.tabChange({ index: window.history.state.companyInfoTab });
    }

    this.getPersonalChallenges();
    this.companyService
      .getCompany(this.id)
      .then((company) => {
        this.company = company;
        this.companyDataForm.patchValue({
          companyId: this.company.id,
          adminEmail: [],
          availableLicenses: this.company.availableLicenses,
          avatar: this.company.avatar,
          avatarImg: null,
          color: this.company.color,
          name: this.company.name,
          managerCompanyIds: Array.from(this.company.managerCompanyIds),
          featureEvents: this.company.features.has(Features.Events),
          featureJoinTeamCard: this.company.features.has(Features.JoinTeamCard),
          featureLimitedLicenses: this.company.features.has(
            Features.LimitedLicenses
          ),
          featureLocations: this.company.features.has(Features.Locations),
          featureMarketplace: this.company.features.has(Features.Marketplace),
          featureNotSetAutomaticTeam: this.company.features.has(
            Features.NotSetAutomaticTeam
          ),
          featureNotShowWhiteLabelLogoOnReport: this.company.features.has(
            Features.NotShowWhiteLabelLogoOnReport
          ),
          featureOrganisationBoard: this.company.features.has(
            Features.OrganisationBoard
          ),
          featureProjectManager: this.company.features.has(
            Features.ProjectManager
          ),
          featureReferralProgramDisabled: this.company.features.has(
            Features.ReferralProgramDisabled
          ),
          featureSetAutomaticTeam: this.company.features.has(
            Features.SetAutomaticTeam
          ),
          featureShareEmail: this.company.features.has(Features.ShareEmail),
          featureTeamChallenge: this.company.features.has(
            Features.TeamChallenge
          ),
          featureUnitConverter: this.unitConverterList.filter(
            (unitConverterOption) =>
              this.company.features.has(unitConverterOption.feature)
          ),
          featureWhiteLabel: this.company.features.has(Features.WhiteLabel),
          whiteLabelLogo: this.company.whiteLabel
            ? this.company.whiteLabel.logo
            : null,
          whiteLabelButtonColor: this.company.whiteLabel
            ? this.company.whiteLabel.buttonColor
            : null,
          whiteLabelButtonText: this.company.whiteLabel
            ? this.company.whiteLabel.buttonText
            : null,
          whiteLabelButtonLink: this.company.whiteLabel
            ? this.company.whiteLabel.buttonLink
            : null,
        });
        this.loadWhiteLabelLogo();

        this.companyDataForm.controls.whiteLabelLogo.valueChanges.subscribe(
          () => {
            this.loadWhiteLabelLogo();
          }
        );
        if (this.companyDataForm.controls.featureProjectManager.value) {
          this.companyDataForm.get('featureTeamChallenge').disable();
          this.companyDataForm.get('featureMarketplace').disable();
          this.companyDataForm.get('featureWhiteLabel').disable();
          this.companyDataForm.get('featureLocations').disable();
          this.companyDataForm.get('featureSetAutomaticTeam').disable();
          this.companyDataForm.get('featureNotSetAutomaticTeam').disable();
          this.companyDataForm
            .get('featureNotShowWhiteLabelLogoOnReport')
            .disable();
          this.companyDataForm.get('featureOrganisationBoard').disable();
          this.companyDataForm.get('featureUnitConverter').disable();
          this.companyDataForm.get('featureEvents').disable();
          this.companyDataForm.get('managerCompanyIds').disable();
          this.companyDataForm.get('featureReferralProgramDisabled').disable();
        } else {
          this.companyDataForm.get('featureTeamChallenge').enable();
          this.companyDataForm.get('featureMarketplace').enable();
          this.companyDataForm.get('featureWhiteLabel').enable();
          this.companyDataForm.get('featureLocations').enable();
          this.companyDataForm.get('featureSetAutomaticTeam').enable();
          this.companyDataForm.get('featureNotSetAutomaticTeam').enable();
          this.companyDataForm
            .get('featureNotShowWhiteLabelLogoOnReport')
            .enable();
          this.companyDataForm.get('featureOrganisationBoard').enable();
          this.companyDataForm.get('featureUnitConverter').enable();
          this.companyDataForm.get('featureEvents').enable();
          this.companyDataForm.get('managerCompanyIds').enable();
          this.companyDataForm.get('featureReferralProgramDisabled').enable();
        }
        if (this.company.whiteLabel && this.company.whiteLabel.id) {
          this.whiteLabelSelected = this.whiteLabels.find(
            (whiteLabel) => whiteLabel.id === this.company.whiteLabel.id
          );
        }
        this.companyDataForm.get('featureProjectManager').disable();
        if (this.company.features.has(Features.ProjectManager)) {
          this.showManagedCompaniesSpinner = true;
          this.projectManagerService
            .getManagedCompanies(this.company.id)
            .then((managedCompanies) => {
              this.managedCompanies = managedCompanies;
              this.projectManagerService
                .getManagedCompanyStatistics(this.managedCompanies)
                .then((companiesStatistics) => {
                  this.companiesStatistics = companiesStatistics;
                })
                .catch((error) => {
                  console.error(
                    'Error - cannot get managed statistics:',
                    error
                  );
                });
            })
            .catch((error) => {
              console.error('Error - cannot get managed companies:', error);
            })
            .finally(() => {
              this.showManagedCompaniesSpinner = false;
            });
          this.showPushNotificationsSpinner = true;
          this.projectManagerService
            .getPushNotifications(this.company.id)
            .then((notifications) => (this.pushNotifications = notifications))
            .catch((error) => {
              console.error('Error - cannot get push notifications:', error);
            })
            .finally(() => {
              this.showPushNotificationsSpinner = false;
            });
        }
        if (this.company.features.has(Features.Locations)) {
          this.companyService
            .getCompanyLocations(this.company.id)
            .then((locations) => {
              this.locations = locations;
              const locationLevels = {};
              locationLevels[1] = {
                titleEN: this.company.locationsInfo.title.get('en')
                  ? this.company.locationsInfo.title.get('en')
                  : null,
                titleDE: this.company.locationsInfo.title.get('de')
                  ? this.company.locationsInfo.title.get('de')
                  : null,
                selectorEN: this.company.locationsInfo.selector.get('en')
                  ? this.company.locationsInfo.selector.get('en')
                  : null,
                selectorDE: this.company.locationsInfo.selector.get('de')
                  ? this.company.locationsInfo.selector.get('de')
                  : null,
              };

              this.levelsLocations[1] = this.locations.filter(
                (location) => location.hierarchyLevel === 1
              );

              if (this.company.locationLevels > 1) {
                const locationLevel1 = this.locations.find(
                  (location) =>
                    location.hierarchyLevel === 1 &&
                    location.childLocations.optionNames.length > 0
                );

                if (!locationLevel1) {
                  locationLevels[2] = {
                    titleEN: '',
                    titleDE: '',
                    selectorEN: '',
                    selectorDE: '',
                  };
                } else {
                  locationLevels[2] = {
                    titleEN: locationLevel1.childLocations.title.get('en')
                      ? locationLevel1.childLocations.title.get('en')
                      : null,
                    titleDE: locationLevel1.childLocations.title.get('de')
                      ? locationLevel1.childLocations.title.get('de')
                      : null,
                    selectorEN: locationLevel1.childLocations.selector.get('en')
                      ? locationLevel1.childLocations.selector.get('en')
                      : null,
                    selectorDE: locationLevel1.childLocations.selector.get('de')
                      ? locationLevel1.childLocations.selector.get('de')
                      : null,
                  };
                }

                this.levelsLocations[2] = this.locations.filter(
                  (location) => location.hierarchyLevel === 2
                );

                if (this.company.locationLevels > 2) {
                  const locationLevel2 = this.locations.find(
                    (location) =>
                      location.hierarchyLevel === 2 &&
                      location.childLocations.optionNames.length > 0
                  );

                  if (!locationLevel2) {
                    locationLevels[3] = {
                      titleEN: '',
                      titleDE: '',
                      selectorEN: '',
                      selectorDE: '',
                    };
                  } else {
                    locationLevels[3] = {
                      titleEN: locationLevel2.childLocations.title.get('en')
                        ? locationLevel2.childLocations.title.get('en')
                        : null,
                      titleDE: locationLevel2.childLocations.title.get('de')
                        ? locationLevel2.childLocations.title.get('de')
                        : null,
                      selectorEN: locationLevel2.childLocations.selector.get(
                        'en'
                      )
                        ? locationLevel2.childLocations.selector.get('en')
                        : null,
                      selectorDE: locationLevel2.childLocations.selector.get(
                        'de'
                      )
                        ? locationLevel2.childLocations.selector.get('de')
                        : null,
                    };
                  }

                  this.levelsLocations[3] = this.locations.filter(
                    (location) => location.hierarchyLevel === 3
                  );
                }
              }

              for (let i = 1; i <= this.company.locationLevels; i++) {
                this.levelsDataForms[i].patchValue({
                  titleEN: locationLevels[i].titleEN,
                  titleDE: locationLevels[i].titleDE,
                  selectorEN: locationLevels[i].selectorEN,
                  selectorDE: locationLevels[i].selectorDE,
                });

                this.levelsDataSources[i] = new MatTableDataSource(
                  this.levelsLocations[i]
                );
              }
            });
          this.locationDataForm.patchValue({
            levels: this.company.locationLevels
              ? this.company.locationLevels
              : 0,
          });
        }
        for (const level of [1, 2, 3]) {
          this.levelsDataForms[level].controls.name.valueChanges.subscribe(
            (newValue) => {
              if (newValue && !this.isEditActive) {
                this.levelsDataForms[level].controls.id.setValue(
                  newValue.toUpperCase().replace(/\//g, '')
                );
              }
            }
          );
        }
        this.adminEmails = this.company.adminEmail;
        this.showCompanySpinner = false;
      })
      .catch((error) => {
        console.log('companyServce.getCompany - error: ', error);
      });
  }

  public loadWhiteLabelLogo(): void {
    const newValue = this.companyDataForm.controls.whiteLabelLogo.value;
    if (newValue) {
      if (newValue.files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(newValue.files[0]);

        fileReader.addEventListener('load', (event) => {
          this.whiteLabelImgPreview = String(event.target?.result);
        });
      } else {
        this.whiteLabelImgPreview = newValue;
      }
    } else {
      this.whiteLabelImgPreview = null;
    }
  }

  ngAfterViewInit(): void {
    this.sortLocationLevels.changes.subscribe(
      (locationLevelsObserver: QueryList<MatSort>) => {
        locationLevelsObserver.forEach(
          (item, index) => (this.levelsDataSources[index + 1].sort = item)
        );
      }
    );
  }

  private getTranslations(): void {
    this.translateService
      .stream(['COMPANY_INFO_PAGE.ALERTS'])
      .subscribe((values) => {
        this.CLEAN_COMPANY_PROJECT_CONFIRMATION.title =
          values[
            'COMPANY_INFO_PAGE.ALERTS'
          ].CLEAN_COMPANY_PROJECT_CONFIRMATION.TITLE;
        this.CLEAN_COMPANY_PROJECT_CONFIRMATION.message =
          values[
            'COMPANY_INFO_PAGE.ALERTS'
          ].CLEAN_COMPANY_PROJECT_CONFIRMATION.MESSAGE;
      });
  }

  public updateCompanyStatus(): void {
    this.dialog.open(CompanyStatusDialogComponent, {
      width: '250px',
      data: this.company,
    });
  }

  public updateCompanyIsTestValue(): void {
    this.dialog.open(CompanyIsTestDialogComponent, {
      width: '250px',
      data: this.company,
    });
  }

  public updateCompanyAvailableLicences(): void {
    this.dialog.open(CompanyUpdateAvailableLicencesComponent, {
      width: '250px',
      data: this.company,
    });
  }

  public tabChange(event): void {
    if (this.tabIndex !== event.index) {
      this.tabIndex = event.index;
    }
    if (event.index === TabIndex.PointSources && this.pointsSources === null) {
      this.getPointSources();
      return;
    }

    if (event.index === TabIndex.Teams && this.teams === null) {
      this.getTeams();
      return;
    }

    if (
      event.index === TabIndex.TeamChallenges &&
      this.teamChallenges === null
    ) {
      this.getTeamChallenges();
      return;
    }

    if (
      event.index === TabIndex.TeamChallengesV2 &&
      this.teamChallengesV2 === null
    ) {
      this.getTeamChallengesV2();
      return;
    }

    if (
      event.index === TabIndex.CitiesChallenges &&
      this.citiesChallenges === null
    ) {
      this.getCitiesChallenges();
      return;
    }

    if (event.index === TabIndex.Events) {
      this.getEvents();
      return;
    }

    if (event.index === TabIndex.CurrentDuelChallenge) {
      this.currentDuelChallengeTabLoaded = true;
      return;
    }

    if (event.index === TabIndex.CurrentCitiesChallenge) {
      this.currentCitiesChallengeTabLoaded = true;
      return;
    }

    if (event.index === TabIndex.CurrentTeamChallenge) {
      this.calculateCurrentTeamChallenge();
      return;
    }

    if (event.index === TabIndex.UsersLocationList) {
      this.usersLocationsListShowSpinner = true;
      this.getCompanyLocations();
      return;
    }
  }

  public add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const input = event.input;
    const isValid = this.adminEmail.valid;

    // Add admin email
    if (value && isValid) {
      const email: string = event.value;
      this.adminEmails.push(email.toLowerCase().trim());
      this.adminEmails.slice(1);
      if (input) {
        input.value = '';
      }
    }

    if (this.adminEmail.invalid && this.adminEmail.dirty) {
      this.chipList.errorState = true;
    } else {
      this.chipList.errorState = false;
    }
  }

  public remove(email: string): void {
    const index = this.adminEmails.indexOf(email);

    if (index >= 0) {
      this.adminEmails.splice(index, 1);
    }

    if (index === 0) {
      this.adminEmail.setValue('');
    }
  }

  public updateWhiteLabelValues(): void {
    this.companyDataForm.controls.whiteLabelLogo.setValue(
      this.whiteLabelSelected.logo
    );
    this.companyDataForm.controls.color.setValue(this.whiteLabelSelected.color);

    if (this.whiteLabelSelected.button) {
      this.companyDataForm.controls.whiteLabelButtonColor.setValue(
        this.whiteLabelSelected.button.color
      );
      this.companyDataForm.controls.whiteLabelButtonText.setValue(
        this.whiteLabelSelected.button.text
      );
      this.companyDataForm.controls.whiteLabelButtonLink.setValue(
        this.whiteLabelSelected.button.link
      );
    } else {
      this.companyDataForm.controls.whiteLabelButtonColor.setValue(null);
      this.companyDataForm.controls.whiteLabelButtonText.setValue(null);
      this.companyDataForm.controls.whiteLabelButtonLink.setValue(null);
    }
  }

  public onChangeCompanyColor(color: string): void {
    this.companyDataForm.patchValue({ color });
  }

  public onChangeWhiteLabelButtonColor(whiteLabelButtonColor: string): void {
    this.companyDataForm.patchValue({ whiteLabelButtonColor });
  }

  public addLocationToLevel(level: number): void {
    const dataForm = this.levelsDataForms[level];

    const location = new Location({
      id: dataForm.controls.id.value,
      name: dataForm.controls.name.value,
      hierarchyLevel: level,
    });

    const allLocations = [].concat(...Object.values(this.levelsLocations));
    const sameNameIndex = allLocations.findIndex(
      (element) => element.name === location.name || element.id === location.id
    );

    if (sameNameIndex === -1) {
      if (!this.isEditActive) {
        this.levelsLocations[level].push(location);
      } else {
        const oldLocation = this.levelsLocations[level].splice(
          this.editIndex,
          1,
          location
        )[0];
        this.firebaseService.editLocation(
          this.company.id,
          oldLocation,
          location
        );
        this.isEditActive = false;
      }
      dataForm.controls.id.setValue(null);
      dataForm.controls.id.markAsUntouched();
      dataForm.controls.name.setValue(null);
      dataForm.controls.name.markAsUntouched();
      this.levelsDataSources[level] = new MatTableDataSource(
        this.levelsLocations[level]
      );
    } else {
      this.snackBar.open(
        'Location already present, please select another name for the location',
        'Ok',
        {
          duration: 4000,
          panelClass: 'snack-bar-color',
        }
      );
    }
  }

  public updateLocation(level: number, event: Event): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: 'Editing Location',
        message: 'Are you sure you want to edit this Location?',
        action: () => this.updateLocationName(level),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.levelsDataSources[level] = new MatTableDataSource(
            this.levelsLocations[level]
          );
          this.snackBar.open('Location updated successfully.', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open('Error while udpating location', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          console.log('error: ', result.error);
          break;
      }
    });
  }

  private async updateLocationName(level: number): Promise<void> {
    const dataForm = this.levelsDataForms[level];

    const location = new Location({
      id: dataForm.controls.id.value,
      name: dataForm.controls.name.value,
      hierarchyLevel: level,
    });

    const oldLocation = this.levelsLocations[level][this.editIndex];
    await this.firebaseService.editLocation(
      this.company.id,
      oldLocation,
      location
    );
    this.levelsLocations[level][this.editIndex].name = location.name;
    this.isEditActive = false;
    this.editLevel = -1;
    dataForm.controls.id.setValue(null);
    dataForm.controls.id.markAsUntouched();
    dataForm.controls.name.setValue(null);
    dataForm.controls.name.markAsUntouched();
  }

  public editLocation(locationId: string, level: number): void {
    this.isEditActive = true;
    this.editLevel = level;
    const editElement = this.levelsLocations[level].find(
      (element) => element.id === locationId
    );
    const editIndex = this.levelsLocations[level].findIndex(
      (element) => element.id === locationId
    );
    this.levelsDataForms[level].controls.id.setValue(editElement.id);
    this.levelsDataForms[level].controls.name.setValue(editElement.name);
    this.editIndex = editIndex;
  }

  public deleteLocation(location: Location, level: number, event: Event): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: 'Removing Location',
        message: 'Are you sure you want to remove this Location?',
        action: () => this.removeLocation(location, level),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.levelsDataSources[level] = new MatTableDataSource(
            this.levelsLocations[level]
          );
          this.snackBar.open(
            'Location removed successfully. Press Update Company to persist the changes',
            'Ok',
            {
              duration: 4000,
              panelClass: 'snack-bar-color',
            }
          );
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open('Error while removing location', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          console.log('error: ', result.error);
          break;
      }
    });
  }

  public removeLocation(location: Location, level: number): Promise<void> {
    const removeIndex = this.levelsLocations[level].findIndex(
      (element) => element.id === location.id
    );
    this.levelsLocations[level].splice(removeIndex, 1);
    return this.firebaseService.deleteLocation(this.company.id, location);
  }

  public cancelEdit(level: number): void {
    this.isEditActive = false;
    this.levelsDataForms[level].controls.id.setValue(null);
    this.levelsDataForms[level].controls.id.markAsUntouched();
    this.levelsDataForms[level].controls.name.setValue(null);
    this.levelsDataForms[level].controls.name.markAsUntouched();
  }

  public linkLocation(location: Location, level: number): void {
    const locationList = this.levelsLocations[level + 1];

    if (level !== this.locationDataForm.value.levels) {
      this.dialog.open(LinkLocationsDialogComponent, {
        width: '400px',
        data: {
          location,
          locationList,
        },
      });
    }
  }

  private mapFormToLocations(
    locationDataForm: UntypedFormGroup,
    locationsOptions: Array<Location>
  ): Locations {
    const title = new Map<string, string>();
    title.set('en', locationDataForm.value.titleEN);
    title.set('de', locationDataForm.value.titleDE);

    const selector = new Map<string, string>();
    selector.set('en', locationDataForm.value.selectorEN);
    selector.set('de', locationDataForm.value.selectorDE);

    const optionNames = locationsOptions.map((location) => location.name);

    return new Locations({
      title,
      selector,
      optionNames,
    });
  }

  public async updateCompany(event: Event): Promise<void> {
    event.preventDefault();
    const locationsInfoArray = [];
    const locationsArray = [];
    this.showUpdateCompanySpinner = true;
    if (this.companyDataForm.valid) {
      const companyId = this.companyDataForm.controls.companyId.value;

      if (this.companyDataForm.controls.avatarImg.value) {
        const avatarFile = this.companyDataForm.controls.avatarImg.value
          .files[0];

        await this.firebaseService
          .uploadImage(avatarFile, 'companies/' + companyId + '/avatar')
          .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            this.companyDataForm.controls.avatar.setValue(url);
          });
      }

      const features = new Set<Features>();
      let whiteLabel: WhiteLabelDetails = null;

      if (this.companyDataForm.controls.featureWhiteLabel.value) {
        if (this.companyDataForm.controls.whiteLabelLogo.value) {
          if (this.companyDataForm.controls.whiteLabelLogo.value.files) {
            const whiteLabelLogoFile = this.companyDataForm.controls
              .whiteLabelLogo.value.files[0];
            await this.firebaseService
              .uploadImage(
                whiteLabelLogoFile,
                'companies/' + companyId + '/whiteLabelLogo'
              )
              .then(async (snapshot) => {
                const url = await snapshot.ref.getDownloadURL();
                this.companyDataForm.controls.whiteLabelLogo.setValue(url);
              });
          }
        }
        features.add(Features.WhiteLabel);
        whiteLabel = new WhiteLabelDetails({
          id: this.whiteLabelSelected.id,
          logo: this.companyDataForm.controls.whiteLabelLogo.value,
          buttonColor: this.companyDataForm.controls.whiteLabelButtonColor
            .value,
          buttonText: this.companyDataForm.controls.whiteLabelButtonText.value,
          buttonLink: this.companyDataForm.controls.whiteLabelButtonLink.value,
          color: this.companyDataForm.controls.color.value,
        });
      }

      if (this.companyDataForm.controls.featureProjectManager.value) {
        features.add(Features.ProjectManager);
      }

      if (this.companyDataForm.controls.featureJoinTeamCard.value) {
        features.add(Features.JoinTeamCard);
      }

      if (this.companyDataForm.controls.featureLimitedLicenses.value) {
        features.add(Features.LimitedLicenses);
      }

      if (this.companyDataForm.controls.featureShareEmail.value) {
        features.add(Features.ShareEmail);
      }

      if (this.companyDataForm.controls.featureTeamChallenge.value) {
        features.add(Features.TeamChallenge);
      }

      if (this.companyDataForm.controls.featureMarketplace.value) {
        features.add(Features.Marketplace);
      }

      if (this.companyDataForm.controls.featureSetAutomaticTeam.value) {
        features.add(Features.SetAutomaticTeam);
      }

      if (this.companyDataForm.controls.featureNotSetAutomaticTeam.value) {
        features.add(Features.NotSetAutomaticTeam);
      }

      if (
        this.companyDataForm.controls.featureNotShowWhiteLabelLogoOnReport.value
      ) {
        features.add(Features.NotShowWhiteLabelLogoOnReport);
      }

      if (this.companyDataForm.controls.featureOrganisationBoard.value) {
        features.add(Features.OrganisationBoard);
      }

      if (this.companyDataForm.controls.featureEvents.value) {
        features.add(Features.Events);
      }

      if (this.companyDataForm.controls.featureReferralProgramDisabled.value) {
        features.add(Features.ReferralProgramDisabled);
      }

      if (
        this.companyDataForm.controls.featureUnitConverter.value &&
        this.companyDataForm.controls.featureUnitConverter.value.length > 0
      ) {
        features.add(Features.UnitConverter);
        for (const converterOption of this.companyDataForm.controls
          .featureUnitConverter.value) {
          features.add(converterOption.feature);
        }
      }

      if (this.companyDataForm.controls.featureLocations.value) {
        features.add(Features.Locations);

        for (
          let levelIndex = 1;
          levelIndex <= this.locationDataForm.value.levels;
          levelIndex++
        ) {
          const locationsInfo = this.mapFormToLocations(
            this.levelsDataForms[levelIndex],
            this.levelsLocations[levelIndex]
          );
          locationsInfoArray.push(locationsInfo);
        }

        for (
          let levelIndex = 1;
          levelIndex <= this.locationDataForm.value.levels;
          levelIndex++
        ) {
          for (const location of this.levelsLocations[levelIndex]) {
            if (
              location.childLocations &&
              location.childLocations.optionNames.length > 0 &&
              locationsInfoArray[levelIndex]
            ) {
              location.childLocations.title =
                locationsInfoArray[levelIndex].title;
              location.childLocations.selector =
                locationsInfoArray[levelIndex].selector;
            } else {
              location.childLocations = null;
            }
            locationsArray.push(location);
          }
        }
      }

      const company = {
        adminEmail: this.adminEmails,
        availableLicenses: this.companyDataForm.controls.availableLicenses
          .value,
        avatar: this.companyDataForm.controls.avatar.value,
        color: this.companyDataForm.controls.color.value,
        name: this.companyDataForm.controls.name.value,
        managerCompanyIds: this.companyDataForm.controls.managerCompanyIds
          .value,
        licence: LicenceTypes.Complete,
        features: Array.from(features),
        whiteLabel: whiteLabel ? whiteLabel.toObject() : whiteLabel,
        locationsInfo:
          locationsInfoArray.length > 0
            ? locationsInfoArray[0].toObject()
            : null,
        locationLevels: this.locationDataForm.value.levels,
      };

      if (features.has(Features.Locations)) {
        this.firebaseService.createLocations(this.id, locationsArray);
      }

      this.firebaseService.updateCompany(
        this.companyDataForm.controls.companyId.value,
        company
      );

      this.company.adminEmail = company.adminEmail;
      this.company.availableLicenses = company.availableLicenses;
      this.company.avatar = company.avatar;
      this.company.color = company.color;
      this.company.name = company.name;
      this.company.licence = company.licence;
      this.company.features = features;
      this.company.whiteLabel = whiteLabel;
      this.company.locationsInfo =
        locationsInfoArray.length > 0 ? locationsInfoArray[0] : null;
      this.company.locationLevels = company.locationLevels;

      this.showUpdateCompanySpinner = false;
      this.snackBar.open('Company updated correctly', 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
    }
  }

  private async getCompanyLocations(): Promise<void> {
    if (this.trainings.length === 0) {
      this.trainings = await this.companyService.getCompanyTrainings(this.id);
    }
    if (this.locations.length === 0) {
      await this.companyService
        .getCompanyLocations(this.company.id)
        .then((locations) => {
          this.locations = locations;
          this.getStepsForLocationsOnly();
          this.usersLocationsListShowSpinner = false;
        });
    }
  }

  private getStepsForLocationsOnly(): void {
    this.locations.forEach((location) => {
      location.totalSteps = -1;
      this.trainings.forEach((trainingsDay) => {
        location.totalSteps += trainingsDay.trainings.reduce(
          (accumulator, trainingData) =>
            (accumulator += trainingData.locationIds.includes(location.name)
              ? trainingData.steps
              : 0),
          0
        );
      });
      location.totalSteps += 1;
    });
  }

  private getPersonalChallenges(): void {
    this.companyService
      .getAvailableChallenges(this.id)
      .then((availableChallenges) => {
        this.personalChallenges = availableChallenges;
        this.personalChallengesDataSource = new MatTableDataSource(
          this.personalChallenges
        );
        this.showPersonalChallengesSpinner = false;
      })
      .catch((error) => {
        console.log('companyService.getAvailableChallenges - error: ', error);
      });
  }

  private getPointSources(): void {
    this.companyService
      .getPointsSources(this.id)
      .then((pointsSources) => {
        this.pointsSources = pointsSources;
        this.pointsSourceDataSource = new MatTableDataSource(
          this.pointsSources
        );
        this.showPointsSourcesSpinner = false;
      })
      .catch((error) => {
        console.log('companyService.getPointSources - error: ', error);
      });
  }

  private getTeams(): void {
    this.teamsService
      .getTeams(this.id)
      .then((teams) => {
        this.teams = teams;
        this.teamsDataSource = new MatTableDataSource(this.teams);
        this.showTeamSpinner = false;
      })
      .catch((error) => {
        console.log('teamsService.getTeams - error: ', error);
      });
  }

  private getEvents(): void {
    this.eventsService
      .getCompanyArrayEvents(this.id)
      .then((events) => {
        this.events = events;
        this.eventsDataSource = new MatTableDataSource(this.events);
        this.eventsDataSource.sort = this.eventsSort;
        this.eventsDataSource.sortingDataAccessor = this.eventsSortingDataAccessor;
        this.showEventsSpinner = false;
      })
      .catch((error) => {
        console.log('eventService.getCompanyArrayEvents - error: ', error);
      });
  }

  private getTeamChallenges(): void {
    this.companyService
      .getTeamChallenges(this.id)
      .then((teamChallenges) => {
        this.teamChallenges = teamChallenges;
        this.teamChallengesDataSource = new MatTableDataSource(
          this.teamChallenges
        );
        this.showTeamChallengesSpinner = false;
      })
      .catch((error) => {
        console.log('companyService.getTeamChallenges - error: ', error);
      });
  }

  private getTeamChallengesV2(): void {
    this.teamChallengeService
      .getTeamChallengesV2(this.id)
      .then((teamChallengesV2) => {
        this.teamChallengesV2 = teamChallengesV2;
        this.showTeamChallengesV2Spinner = false;
      })
      .catch((error) => {
        console.log('companyService.getTeamChallenges - error: ', error);
      });
  }

  private getCitiesChallenges(): void {
    this.companyService
      .getDistanceChallenges(this.id)
      .then((distanceChallenges) => {
        this.citiesChallenges = distanceChallenges;
        this.showCitiesChallengesSpinner = false;
      })
      .catch((error) => {
        console.log('companyService.getCitiesChallenges - error: ', error);
      });
  }

  private async calculateCurrentTeamChallenge(): Promise<void> {
    if (this.company.currentTeamChallenges.length > 0) {
      const currentTeamChallengeId = this.company.currentTeamChallenges[0];
      this.currentTeamChallengeWeeks = await this.teamsService.getTeamChallengeWeeks(
        this.company.id,
        currentTeamChallengeId
      );
      this.currentTeamChallengeData = await this.teamsService.getTeamWeeklyRanking(
        this.company.id,
        currentTeamChallengeId,
        Helpers.formatDate(new Date())
      );
    }
  }

  public returnPage(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Companies } });
  }

  public goToTeam(team: Team): void {
    this.router.navigate(['edit-team/' + team.id], {
      relativeTo: this.route,
    });
  }

  public goToNewTeamChallengePage(): void {
    this.router.navigate(['new-team-challenge'], {
      relativeTo: this.route,
    });
  }

  public goToPersonalChallenge(challengeSchema: ChallengeSchema): void {
    this.router.navigate(['edit-personal-challenge/' + challengeSchema.id], {
      relativeTo: this.route,
    });
  }

  public goToTeamChallenge(teamChallenge: TeamChallenge): void {
    this.router.navigate(['edit-team-challenge/' + teamChallenge.id], {
      relativeTo: this.route,
    });
  }

  public goToPointsSource(pointsSource: PointsSource): void {
    this.router.navigate(['edit-points-source/' + pointsSource.id], {
      relativeTo: this.route,
    });
  }

  public goToAddTeam(): void {
    this.router.navigate(['add-team'], { relativeTo: this.route });
  }

  public async addRandomTeams(): Promise<void> {
    for (const newTeam of this.teamsToAdd) {
      const team = new Team({}).deserialize({
        avatar: newTeam.avatar,
        color: newTeam.color,
        name: newTeam.name,
        id: newTeam.id,
        totalSteps: 0,
        totalPoints: 0,
        teamCaptain: '',
        records: [],
        members: [],
      });

      await this.firebaseService.createCompanyTeam(
        this.company.id,
        newTeam.id,
        team
      );
    }

    this.teamsToAdd = [];
    this.randomTeamsDataSource = new MatTableDataSource(this.teamsToAdd);
    this.showRandomTeamsTable = false;

    this.getTeams();

    this.snackBar.open('Random Teams added successfully', 'Ok', {
      duration: 4000,
      panelClass: 'snack-bar-color',
    });
  }

  public openRandomTeamsDialog(): void {
    const availableTeams = this.adminTeamSchemas.filter(
      (teamSchema) =>
        this.teams.findIndex((team) => team.id === teamSchema.id) === -1
    );

    const dialogRef = this.dialog.open(AddRandomTeamsDialogComponent, {
      width: '250px',
      data: availableTeams,
    });

    dialogRef.afterClosed().subscribe((result) => {
      availableTeams.sort(() => 0.5 - Math.random());
      this.teamsToAdd = availableTeams.slice(0, result);
      this.randomTeamsDataSource = new MatTableDataSource(this.teamsToAdd);
      this.showRandomTeamsTable = true;
    });
  }

  public goToAddPersonalChallenge(): void {
    this.router.navigate(['add-personal-challenge'], {
      relativeTo: this.route,
    });
  }

  public goToAddTeamChallenge(): void {
    this.router.navigate(['add-team-challenge'], {
      relativeTo: this.route,
    });
  }

  public goToAddPointsSource(): void {
    this.router.navigate(['add-point-source'], {
      relativeTo: this.route,
    });
  }

  public goToNewCitiesChallenge(): void {
    this.router.navigate(['add-cities-challenge'], {
      relativeTo: this.route,
    });
  }

  public goToAddEvent(): void {
    this.router.navigate(['app-add-event-component'], {
      relativeTo: this.route,
    });
  }

  public async export(): Promise<void> {
    const headers = new Set<string>();
    headers.add('id');
    this.spinnerFlag = true;
    this.disableButton = true;
    const userDataArray = new Array<any>();
    await this.firebaseService
      .getUserExportData(this.id)
      .then(async (querySnapshot) => {
        querySnapshot.forEach(async (documentSnapshot) => {
          const userDocument: User = new User(documentSnapshot.data());
          const userLocations: object = userDocument.locationIds.reduce(
            (accumulator: object, currentLocation: string, index: number) => {
              accumulator['location' + index] = currentLocation;
              return accumulator;
            },
            {}
          );
          Object.keys(userLocations).forEach(headers.add, headers);
          const userData: any = Object.assign(userLocations, {
            id: documentSnapshot.id,
          });
          userDataArray.push(userData);
        });
      })
      .catch((error) => {
        console.log('error - export: ', error);
      });

    const startDate = new Date(this.reportDateRange.value.start);
    const endDate = Helpers.formatDateYYYYMMDD(
      new Date(this.reportDateRange.value.end)
    );

    let queryDate: Date = new Date(startDate);

    while (Helpers.formatDateYYYYMMDD(queryDate) <= endDate) {
      const formattedQueryDate = formatDate(queryDate, 'dd.MM.yyyy', 'en-US');
      // TODO: update to correspond with newTrainings
      await this.firebaseService
        .getTrainingExportData(this.id, formattedQueryDate)
        .then((trainingExportDataSnapshot) => {
          const trainingExportData = trainingExportDataSnapshot.data();
          userDataArray.forEach((userElement) => {
            userElement['Steps ' + formattedQueryDate] =
              trainingExportData && trainingExportData[userElement.id]
                ? trainingExportData[userElement.id].steps
                : trainingExportData[userElement.id];
          });
          headers.add('Steps ' + formattedQueryDate);
        })
        .catch((error) => {
          console.log('error - getTrainingExportData: ', error);
        });
      queryDate = Helpers.addDays(queryDate, 1);
    }
    const levelHeaders = [];

    for (const userData of userDataArray) {
      await this.firebaseService
        .getLevelExportData(userData.id, startDate.getTime())
        .then(async (levelExportDataQuery) => {
          levelExportDataQuery.forEach(async (levelExportDataSnapshot) => {
            const levelExportData = levelExportDataSnapshot.data();
            const levelExportId = levelExportDataSnapshot.id;
            if (
              startDate <= levelExportData.startDate &&
              levelExportData.startDate <= endDate
            ) {
              const header =
                'Level ' +
                (Helpers.hasDateFormat(levelExportId)
                  ? levelExportId
                  : Helpers.formatDate(new Date(Number(levelExportId))));
              userData[header] = levelExportData.level;
              if (levelHeaders.indexOf(header) === -1) {
                levelHeaders.push(header);
              }
            }
          });
        })
        .catch((error) => {
          console.log('error - getLevelExportData: ', error);
        });
    }

    levelHeaders.sort((headerA, headerB) => {
      const splitHeaderA = headerA.split(' ');
      const splitHeaderB = headerB.split(' ');

      const stringDateA = splitHeaderA[1];
      const stringDateB = splitHeaderB[1];

      return (
        Helpers.getTimeFromFormattedString(stringDateA) -
        Helpers.getTimeFromFormattedString(stringDateB)
      );
    });
    levelHeaders.forEach(headers.add, headers);

    this.excelService.exportExcel(userDataArray, 'Move-Me-Report', [
      ...headers,
    ]);
    this.spinnerFlag = false;
    this.disableButton = false;
  }

  public async usersTeamReport(): Promise<void> {
    this.usersTeamReportSpinnerFlag = true;
    this.usersTeamReportSpinnerFlagDisableButton = true;

    const teams = await this.teamsService.getTeams(this.id);

    const headers = [];
    const userData = [];

    for (const team of teams) {
      headers.push(team.name);
      const teamUsersQuery = await this.firebaseService.getTeamUsersByCompany(
        this.id,
        team.id
      );
      const teamUsers = teamUsersQuery.docs.map((userSnapshot) =>
        new User().deserialize(userSnapshot.data())
      );

      userData.push(
        ...teamUsers.map((user) =>
          Object.assign({}, { [team.name]: user.displayName })
        )
      );
    }

    this.excelService.exportExcel(userData, 'Move-Me-Report', headers);

    this.usersTeamReportSpinnerFlag = false;
    this.usersTeamReportSpinnerFlagDisableButton = false;
  }

  public goToEditEvent(eventModel: EventModel): void {
    console.log('Event: ', eventModel);
    this.router.navigate(['app-add-event-component/' + eventModel.id], {
      relativeTo: this.route,
    });
  }

  public cleanProjectData(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: new ConfirmationDialogData({
        title: this.CLEAN_COMPANY_PROJECT_CONFIRMATION.title,
        message: this.CLEAN_COMPANY_PROJECT_CONFIRMATION.message,
        action: () => this.firebaseService.cleanProjectData(this.id),
        showSpinner: true,
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.snackBar.open('Client Data cleaned successfully.', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open('Error while cleaning client data', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          console.log('error: ', result.error);
          break;
      }
    });
  }

  public sendProjectPlan(
    challenge: TeamChallenge | CitiesChallenge,
    button: MatButton
  ): void {
    button.disabled = true;
    this.firebaseService
      .sendProjectPlan(challenge, this.company)
      .finally(() => {
        button.disabled = false;
      });
  }

  /**
   * Data accessor function that is used for accessing data properties for sorting through
   * the default sortData function.
   * This default function assumes that the sort header IDs (which defaults to the column name)
   * matches the data's properties (e.g. column Xyz represents data['Xyz']).
   * May be set to a custom function for different behavior.
   * @param data Data object that is being accessed.
   * @param sortHeaderId The name of the column that represents the data.
   */
  public eventsSortingDataAccessor: (
    data: any,
    sortHeaderId: string
  ) => string | number = (data: any, sortHeaderId: string): string | number => {
    let value: any;
    if (sortHeaderId === 'startDate' || sortHeaderId === 'endDate') {
      value =
        sortHeaderId === 'startDate'
          ? (data as { [key: string]: any }).days[0]
          : (data as { [key: string]: any }).days.slice(-1)[0];
    } else {
      value = (data as { [key: string]: any })[sortHeaderId];
    }
    if (_isNumberValue(value)) {
      const numberValue = Number(value);

      // Numbers beyond `MAX_SAFE_INTEGER` can't be compared reliably so we
      // leave them as strings. For more info: https://goo.gl/y5vbSg
      return numberValue < MAX_SAFE_INTEGER ? numberValue : value;
    }
    return value;
  };
}
