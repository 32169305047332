import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { Location } from 'src/app/models/location.model';
import { TranslateService } from '@ngx-translate/core';
import { Locations } from 'src/app/models/locations.model';

export interface IHierarchyLevelName {
  name: string;
  hierarchyLevel: number;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService
  ) {}

  public getTopHierarchyLocationsNames(
    companyId: string,
    companyLocations: Locations
  ): Promise<Array<IHierarchyLevelName>> {
    const hierarchyLevelNames = new Array<IHierarchyLevelName>();
    hierarchyLevelNames.push({
      name: companyLocations.title.get(this.translateService.currentLang),
      hierarchyLevel: 1,
    });
    return this.firebaseService
      .getCompanyLocations(companyId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const locations = querySnapshot.docs.map(
            (docSnapshot) => new Location(docSnapshot.data())
          );
          locations.forEach((item) => {
            if (
              item.childLocations.title.get(this.translateService.currentLang)
            ) {
              const hierarchyLevel = item.hierarchyLevel + 1;
              const hierarchyLevelIndex = hierarchyLevelNames.findIndex(
                (element) => element.hierarchyLevel === hierarchyLevel
              );

              if (hierarchyLevelIndex === -1) {
                hierarchyLevelNames.push({
                  name: item.childLocations.title.get(
                    this.translateService.currentLang
                  ),
                  hierarchyLevel: item.hierarchyLevel + 1,
                });
              }
            }
          });
          hierarchyLevelNames.sort(
            (elementA, elementB) =>
              elementA.hierarchyLevel - elementB.hierarchyLevel
          );
          return hierarchyLevelNames;
        } else {
          return hierarchyLevelNames;
        }
      })
      .catch((error) => {
        console.log('Error getting company locations: ', error);
        return [];
      });
  }
  public getLocationByHierarchyLevel(
    companyId: string,
    hierarchyLevel: number
  ): Promise<Array<Location>> {
    return this.firebaseService
      .getLocationByHierarchyLevel(companyId, hierarchyLevel)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs.map(
            (docSnapshot) => new Location(docSnapshot.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('Error getting location by hierarchy: ', error);
        return [];
      });
  }
}
