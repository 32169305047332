import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Helpers } from 'src/app/helpers/helpers';
import { Company } from 'src/app/models/company.model';
import { Roles } from 'src/app/models/roles.enum';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { FirebaseService } from '../firebase/firebase.service';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { LoginService } from '../login/login.service';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { CityInfo } from 'src/app/models/cities-challenge/city-info/city-info.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  // USERS DATA
  public userUnsubscribe: () => void;
  private user$: BehaviorSubject<User> = new BehaviorSubject(null);
  public get user(): Observable<User> {
    return this.user$.asObservable();
  }

  // COMPANY DATA
  public companyUnsubscribe: () => void;
  private company$: BehaviorSubject<Company> = new BehaviorSubject(null);
  public get company(): Observable<Company> {
    return this.company$.asObservable();
  }

  // TEAM DATA
  public teamUnsubscribe: () => void;
  private team$: BehaviorSubject<Team> = new BehaviorSubject(null);
  public get team(): Observable<Team> {
    return this.team$.asObservable();
  }

  public teamsUnsubscribe: () => void;
  private teams$: BehaviorSubject<Array<Team>> = new BehaviorSubject(null);
  public get teams(): Observable<Array<Team>> {
    return this.teams$.asObservable();
  }

  public dailyPerformaceArray = [];
  public dailyPerformanceStats: Array<string> = [];
  public teamTotalSteps = 0;
  public teamTotalKilometers = 0;
  public teamDailyDataUnsubscribe: () => void;

  public currentTeamChallengeWeeks = [];
  private teamChallengeWeeksUnsubscribe: () => void;

  // CITIES CHALLENGE DATA
  public citiesUnsubscribe: () => void;
  private cities$: BehaviorSubject<Array<City>> = new BehaviorSubject(null);
  public get cities(): Observable<Array<City>> {
    return this.cities$.asObservable();
  }

  // CITY INFO
  public cityInfosUnsubscribe: () => void;
  private cityInfos$: BehaviorSubject<Array<CityInfo>> = new BehaviorSubject(
    []
  );
  public get cityInfos(): Observable<Array<CityInfo>> {
    return this.cityInfos$.asObservable();
  }

  // COMPANIES DATA
  public companiesUnsubscribe: () => void;
  private companies$: BehaviorSubject<Array<Company>> = new BehaviorSubject(
    null
  );
  public get companies(): Observable<Array<Company>> {
    return this.companies$.asObservable();
  }

  constructor(
    private firebaseService: FirebaseService,
    private localstorageService: LocalstorageService,
    private loginService: LoginService
  ) {
    const userId = this.localstorageService.getUserId();
    if (userId !== undefined && userId !== null) {
      this.subscribeToUser(userId);
    }

    const companyId = this.localstorageService.getCompanyId();
    if (companyId !== undefined && companyId !== null) {
      this.subscribeToCompany(companyId);
    }
  }

  // USER SUBSCRIPTIONS

  public subscribeToUser(userId: string): void {
    this.unsubscribeFromUser();
    this.userUnsubscribe = this.subscriptionToUser(userId);
  }

  public unsubscribeFromUser(): void {
    if (this.userUnsubscribe !== undefined) {
      this.userUnsubscribe();
    }
  }

  private subscriptionToUser(userId: string): () => void {
    return this.firebaseService.subscribeToUser(userId, {
      next: (
        userSnapshot: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
      ) => {
        if (userSnapshot.exists) {
          const user = new User(userSnapshot.data());
          // TODO: access the dashboard in a feature basis
          if (
            user.role <= Roles.TeamLeader ||
            user.role === Roles.ProjectManager
          ) {
            this.setUser(user);
          } else {
            this.setUser(null);
            this.loginService.logout();
          }
        } else {
          this.setUser(null);
          this.loginService.logout();
        }
      },
      error: (error) => {
        console.log('subscription.service.ts - subscriptionToUser: ', error);
      },
    });
  }

  public setUser(user: User): void {
    if (user) {
      this.localstorageService.setUserId(user.id);

      if (user.teamId) {
        const today = new Date();
        this.subscribeToTeam(user.companyId, user.teamId);
        this.subscribeToTeamDailyData(
          user.companyId,
          user.teamId,
          Helpers.formatDate(today)
        );
      }
    } else {
      this.localstorageService.setUserId(null);
    }
    this.user$.next(user);
  }

  // COMPANY SUBSCRIPTIONS

  public subscribeToCompany(companyId: string): void {
    this.unsubscribeFromCompany();
    this.companyUnsubscribe = this.subscriptionToCompany(companyId);
  }

  public unsubscribeFromCompany(): void {
    if (this.companyUnsubscribe !== undefined) {
      this.companyUnsubscribe();
    }
  }

  private subscriptionToCompany(companyId: string): () => void {
    return this.firebaseService.subscribeToCompany(companyId, {
      next: (
        companySnapshot: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
      ) => {
        if (companySnapshot.exists) {
          const company = new Company(companySnapshot.data());
          this.setCompany(company);
        } else {
          this.setCompany(null);
          this.loginService.logout();
        }
      },
      error: (error) => {
        console.log('subscription.service.ts - subscriptionToCompany: ', error);
      },
    });
  }

  public setCompany(company: Company): void {
    if (company) {
      this.localstorageService.setCompanyId(company.id);

      if (company.currentTeamChallenges.length !== 0) {
        this.subscribeToTeamChallengeWeeks(
          company.id,
          company.currentTeamChallenges[0]
        );
      }
    } else {
      this.localstorageService.setCompanyId(null);
    }
    return this.company$.next(company);
  }

  // TEAMS SUBSCRIPTIONS

  public subscribeToTeam(companyId: string, teamId: string): void {
    this.unsubscribeFromTeam();
    this.teamUnsubscribe = this.subscriptionToTeam(companyId, teamId);
  }

  public unsubscribeFromTeam(): void {
    if (this.teamUnsubscribe !== undefined) {
      this.teamUnsubscribe();
    }
  }

  private subscriptionToTeam(companyId: string, teamId: string): () => void {
    return this.firebaseService.subscribeToTeam(companyId, teamId, {
      next: (
        teamSnapshot: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
      ) => {
        if (teamSnapshot.exists) {
          this.setTeam(new Team(teamSnapshot.data()));
        } else {
          this.setTeam(null);
        }
      },
      error: (error) => {
        console.log('subscription.service.ts - subscriptionToTeam: ', error);
      },
    });
  }

  private setTeam(team: Team): void {
    return this.team$.next(team);
  }

  public subscribeToTeams(companyId: string): void {
    this.unsubscribeFromTeams();
    this.teamsUnsubscribe = this.subscriptionToTeams(companyId);
  }

  public unsubscribeFromTeams(): void {
    if (this.teamsUnsubscribe !== undefined) {
      this.teamsUnsubscribe();
    }
  }

  private subscriptionToTeams(companyId: string): () => void {
    return this.firebaseService.subscribeToTeams(companyId, {
      next: (
        teamsQuerySnapshot: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
      ) => {
        if (!teamsQuerySnapshot.empty) {
          this.setTeams(
            teamsQuerySnapshot.docs.map(
              (teamSnapshot) => new Team(teamSnapshot.data())
            )
          );
        } else {
          this.setTeams([]);
        }
      },
      error: (error) => {
        console.log('subscription.service.ts - subscriptionToTeams: ', error);
      },
    });
  }

  private setTeams(teams: Array<Team>): void {
    this.teams$.next(teams);
  }

  public subscribeToTeamDailyData(
    companyId: string,
    teamId: string,
    date: string
  ): void {
    this.unsubscribeFromTeamDailyData();
    this.teamDailyDataUnsubscribe = this.subscriptionToTeamDailyData(
      companyId,
      teamId,
      date
    );
  }

  public unsubscribeFromTeamDailyData(): void {
    if (this.teamDailyDataUnsubscribe !== undefined) {
      this.teamDailyDataUnsubscribe();
    }
  }

  private subscriptionToTeamDailyData(
    companyId: string,
    teamId: string,
    date: string
  ): () => void {
    return this.firebaseService.subscribeToTeamChallengeDailyPerformance(
      companyId,
      teamId,
      date,
      {
        next: (
          dailyPerformanceSnapshot: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
        ) => {
          const stepsArray = [];
          if (dailyPerformanceSnapshot.exists) {
            const dailyPerformanceDoc = dailyPerformanceSnapshot.data();
            // Get userId from keys
            Object.keys(dailyPerformanceDoc).forEach((key) => {
              dailyPerformanceDoc[key].userId = key;
            });
            this.dailyPerformaceArray = Object.values(dailyPerformanceDoc);
            this.dailyPerformaceArray.sort(
              (a, b) => b.stats.Steps - a.stats.Steps
            );

            this.dailyPerformanceStats = Object.keys(
              this.dailyPerformaceArray[0].stats
            );

            for (const dailyPerformaceArrayItem of this.dailyPerformaceArray) {
              stepsArray.push(dailyPerformaceArrayItem.stats.Steps);
            }

            const stepsSum = stepsArray.reduce((a, b) => a + b, 0);
            this.teamTotalSteps = stepsSum;
            this.teamTotalKilometers = Math.round(stepsSum / 1312); // 1312 steps are 1 kilometer

            this.dailyPerformaceArray.forEach((element, index) => {
              element.position = index + 1;
            });
          } else {
            this.dailyPerformaceArray = [];
            this.dailyPerformanceStats = [];
            this.teamTotalSteps = 0;
            this.teamTotalKilometers = 0;
          }
        },
        error: (error) => {
          console.log(
            'subscription.service.ts - subscriptionToTeamDailyData: ',
            error
          );
        },
      }
    );
  }

  public subscribeToTeamChallengeWeeks(
    companyId: string,
    currentTeamChallengeId: string
  ): void {
    this.unsubscribeFromTeamChallengeWeeks();
    this.teamChallengeWeeksUnsubscribe = this.subscriptionToTeamChallengeWeeks(
      companyId,
      currentTeamChallengeId
    );
  }

  public unsubscribeFromTeamChallengeWeeks(): void {
    if (this.teamChallengeWeeksUnsubscribe !== undefined) {
      this.teamChallengeWeeksUnsubscribe();
    }
  }

  private subscriptionToTeamChallengeWeeks(
    companyId: string,
    currentTeamChallengeId: string
  ): () => void {
    return this.firebaseService.subscribeToTeamChallengeWeeks(
      companyId,
      currentTeamChallengeId,
      {
        next: (
          teamChallengeWeeksQuery: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
        ) => {
          if (!teamChallengeWeeksQuery.empty) {
            this.currentTeamChallengeWeeks = teamChallengeWeeksQuery.docs.map(
              (teamChallengeWeeksSnapshot) =>
                Object.assign(teamChallengeWeeksSnapshot.data(), {
                  id: teamChallengeWeeksSnapshot.id,
                })
            );
          } else {
            this.currentTeamChallengeWeeks = [];
          }
        },
        error: (error) => {
          console.log(
            'subscription.service.ts - subscriptionToTeamChallengeWeeks: ',
            error
          );
        },
      }
    );
  }

  // CITIES CHALLENGE SUBSCRIPTIONS

  public subscribeToCities(): void {
    this.unsubscribeFromCities();
    this.citiesUnsubscribe = this.subscriptionToCities();
  }

  public unsubscribeFromCities(): void {
    if (this.citiesUnsubscribe !== undefined) {
      this.citiesUnsubscribe();
    }
  }

  private subscriptionToCities(): () => void {
    return this.firebaseService.subscribeToCities({
      next: (
        citiesQuery: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
      ) => {
        if (!citiesQuery.empty) {
          this.setCities(
            citiesQuery.docs.map(
              (citySnapshot) => new City(citySnapshot.data())
            )
          );
        } else {
          this.setCities([]);
        }
      },
      error: (error) => {
        console.log('subscription.service.ts - subscriptionToCities: ', error);
      },
    });
  }

  private setCities(cities: Array<City>): void {
    this.cities$.next(cities);
  }

  // CITY INFO SUBSCRIPTIONS
  public subscribeToCityInfos(cityId: string): void {
    this.unsubscribeFromCityInfos();
    this.cityInfosUnsubscribe = this.subscriptionToCityInfos(cityId);
  }

  public unsubscribeFromCityInfos(): void {
    if (this.cityInfosUnsubscribe !== undefined) {
      this.cityInfos$.next([]);
      this.cityInfosUnsubscribe();
    }
  }

  private subscriptionToCityInfos(cityId: string): () => void {
    return this.firebaseService.subscribeToCityInfos(cityId, {
      next: (
        cityInfosSnapshot: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
      ) => {
        if (!cityInfosSnapshot.empty) {
          const cityInfos = cityInfosSnapshot.docs.map(
            (cityInfoDocumentSnapshot) =>
              new CityInfo(cityInfoDocumentSnapshot.data())
          );
          this.cityInfos$.next(cityInfos);
        } else {
          this.cityInfos$.next([]);
        }
      },
      error: (error) => {
        console.log('subscription.service.ts - subscriptionToCompany: ', error);
      },
    });
  }

  // GROUP RUN CHALLENGE SUBSCRIPTIONS
  public subscribeToCompanies(): void {
    this.unsubscribeFromCompanies();
    this.companiesUnsubscribe = this.subscriptionToCompanies();
  }

  public unsubscribeFromCompanies(): void {
    if (this.companiesUnsubscribe !== undefined) {
      this.companiesUnsubscribe();
    }
  }

  private subscriptionToCompanies(): () => void {
    return this.firebaseService.subscribeToCompanies({
      next: (
        companiesQuery: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
      ) => {
        if (!companiesQuery.empty) {
          this.setCompanies(
            companiesQuery.docs.map(
              (companySnapshot) => new Company(companySnapshot.data())
            )
          );
        } else {
          this.setCompanies([]);
        }
      },
      error: (error) => {
        console.log(
          'subscription.service.ts - subscriptionToCompanies: ',
          error
        );
      },
    });
  }

  private setCompanies(companies: Array<Company>): void {
    this.companies$.next(companies);
  }
}
