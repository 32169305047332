import { AbstractControl } from '@angular/forms';
export class SettingsValidators {
  static MatchEmail(control: AbstractControl): void {
    const email = control.get('newEmail').value;
    const checkEmail = control.get('confirmEmail').value;
    if (email !== checkEmail) {
      control.get('confirmEmail').setErrors({ ConfirmEmail: true });
    }
  }

  static MatchPassword(control: AbstractControl): void {
    const password = control.get('newPassword').value;
    const checkPassword = control.get('confirmPassword').value;
    if (password !== checkPassword) {
      control.get('confirmPassword').setErrors({ ConfirmPassword: true });
    }
  }
}
