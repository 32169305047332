/* eslint-disable max-len */
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';

export const RADIANS: number = 180 / 3.14159265;
export const EARTH_RADIUS_KM = 6371;
export const STEPS_IN_ONE_KILOMETER = 1312;
export const MSECS_IN_A_DAY: number = 24 * 60 * 60 * 1000;
export const MAX_SAFE_INTEGER = 9007199254740991;
export const MS_IN_ONE_DAY = 86400000;
export const SELECT_ALL = 'selectAll';
export const PRIMARY_COLOR = '#F97A1C';

export const ERROR_CODES = {
  AUTH: {
    WRONG_PASSWORD: 'auth/wrong-password',
    PERMISSION_DENIED: 'permission-denied',
    USER_NOT_FOUND: 'auth/user-not-found',
  },
};

export const StatisticsType = {
  Trainings: 'trainings',
};

export const StatisticsBasis = {
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Challenges: 'challenges',
};

export const availableLanguages: Array<string> = ['en', 'de'];
export const LANGUAGES_MAP = {
  en: 'gbr',
  de: 'deu',
};

export const SERVER_NOTIFICATION_URL =
  'https://app.move-me-service.com/dashboard-message.json';

export const COMPANY_ID = 'COMPANY_ID';
export const DISTANCE_CHALLENGE_ID = 'DISTANCE_CHALLENGE_ID';
export const CITIES_CHALLENGE_ID = 'CITIES_CHALLENGE_ID';
export const WHITE_LABEL_ID = 'WHITE_LABEL_ID';
export const TEAM_ID = 'TEAM_ID';
export const ROUTE_ID = 'ROUTE_ID';

export const COUNTRY_CODE_TOKEN = 'COUNTRY_CODE';
export const FLAGS_PATH = `assets/countries/flags/1x1/${COUNTRY_CODE_TOKEN}.svg`;
export const FLAGS_PATH_4X3 = `assets/countries/flags/4x3/${COUNTRY_CODE_TOKEN}.svg`;

export const REGEX_NUMERIC: RegExp = /^[0-9]*$/;
export const REGEX_NAME: RegExp = /^[A-Za-zÀ-úäöüÄÖÜß ]*$/;
export const REGEX_ALPHANUMERIC_WITHOUT_GERMAN_CHARS: RegExp = /^[A-Za-z0-9_]*$/;
export const REGEX_ALPHANUMERIC: RegExp = /^[A-Za-z0-9äöüÄÖÜß._\- ]*$/;
export const REGEX_ALPHANUMERIC_EXTENDED: RegExp = /^[A-Za-z0-9äöüÄÖÜß.,_+&!?„“,’"'\- ]*$/;
// eslint-disable-next-line no-useless-escape
export const REGEX_EMAIL: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
export const REGEX_LINK: RegExp = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const REGEX_ISO_DATETIME: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
export const REGEX_ISO_DATE: RegExp = /^\d{4}-\d{2}-\d{2}/;

export const MAT_CARD_CONTENT_TAG = 'MAT-CARD-CONTENT';
export const DEFAULT_IMAGE_PATH = 'assets/img/default-placeholder.png';

export const DISTANCE_CHALLENGE_MAP_PATH =
  FirebaseConstants.DistanceChallengesFolder +
  DISTANCE_CHALLENGE_ID +
  '/' +
  FirebaseConstants.DistanceChallengeMapName;

export const CITIES_CHALLENGE_ROUTE_MAP_PATH =
  FirebaseConstants.CompaniesFolder +
  COMPANY_ID +
  '/' +
  FirebaseConstants.CitiesChallengesFolder +
  CITIES_CHALLENGE_ID +
  '/' +
  FirebaseConstants.RoutesFolder +
  ROUTE_ID +
  '/' +
  FirebaseConstants.DistanceChallengeMapName;

export const ROUTE_MAP_PATH =
  FirebaseConstants.RoutesFolder +
  ROUTE_ID +
  '/' +
  FirebaseConstants.DistanceChallengeMapName;

export const COMPANY_DISTANCE_CHALLENGE_MAP_PATH =
  FirebaseConstants.CompaniesFolder +
  COMPANY_ID +
  '/' +
  FirebaseConstants.DistanceChallengesFolder +
  DISTANCE_CHALLENGE_ID +
  '/' +
  FirebaseConstants.DistanceChallengeMapName;

export const WHITE_LABEL_DISTANCE_CHALLENGE_REPORT_TEMPLATE_PATH =
  FirebaseConstants.WhiteLabelsFolder +
  WHITE_LABEL_ID +
  '/' +
  FirebaseConstants.ReportTemplatesFolder +
  FirebaseConstants.CitiesChallengeTemplateName;

export const WHITE_LABEL_TEAM_CHALLENGE_REPORT_TEMPLATE_PATH =
  FirebaseConstants.WhiteLabelsFolder +
  WHITE_LABEL_ID +
  '/' +
  FirebaseConstants.ReportTemplatesFolder +
  FirebaseConstants.TeamChallengeTemplateName;

export const TEAM_CHALLENGE_REPORT_TEMPLATE_PATH =
  FirebaseConstants.TemplatesFolder +
  FirebaseConstants.ReportsFolder +
  FirebaseConstants.TeamChallengeTemplateName;

export const COMPANY_TEAM_AVATAR_IMAGE_PATH =
  FirebaseConstants.CompaniesFolder +
  COMPANY_ID +
  '/' +
  FirebaseConstants.TeamsFolder +
  '/' +
  TEAM_ID +
  '/' +
  FirebaseConstants.AvatarImage;

export const AVATAR_IMAGE_PATH =
  FirebaseConstants.TeamsFolder + TEAM_ID + '/' + FirebaseConstants.AvatarImage;

export const ONE_PAGER = {
  TEMPLATE_PATH:
    FirebaseConstants.TemplatesFolder +
    FirebaseConstants.ReportsFolder +
    FirebaseConstants.OnePagerTemplateName,
  TEAM_TEMPLATE_PATH:
    FirebaseConstants.TemplatesFolder +
    FirebaseConstants.ReportsFolder +
    FirebaseConstants.OnePagerTeamTeamplateName,
  CAPTAIN_TEMPLATE_PATH:
    FirebaseConstants.TemplatesFolder +
    FirebaseConstants.ReportsFolder +
    FirebaseConstants.OnePagerCaptainTeamplateName,
  IMAGE_FIELDS: {
    COMPANY_LOGO: 'companyLogo_af_image',
    WHITE_LABEL_LOGO: 'whiteLabelLogo_af_image',
  },
  TEXT_FIELDS: {
    CHALLENGE_NAME: 'challengeName',
    CHALLENGE_START_DATE: 'challengeStartDate',
    CHALLENGE_NAME_TEXT: 'challengeNameText',
    CODE_TYPE: 'codeType',
    COMPANY_ID: 'companyId',
    TEAM_NAME: 'teamName',
    CODE_TYPE_TEAM: 'codeTypeTeam',
    TEAM_ID: 'teamId',
  },
};

export const CITIES_CHALLENGE_REPORT = {
  TEMPLATE_PATH:
    FirebaseConstants.TemplatesFolder +
    FirebaseConstants.ReportsFolder +
    FirebaseConstants.CitiesChallengeTemplateName,
  IMAGE_FIELDS: {
    COMPANY_LOGO: 'companyLogo_af_image',
    WHITE_LABEL_LOGO: 'whiteLabelLogo_af_image',
    QR_CODE: 'qrCode_af_image',
  },
  TEXT_FIELDS: {
    COMPANY_NAME: 'companyName',
    COMPANY_ID: 'companyId',
    CHALLENGE_NAME: 'challengeName',
    REPORT_DATE: 'reportDate',
    CHALLENGE_PERIOD: 'challengePeriod',
    DAY_PROGRESS: 'dayProgress',
    PARTICIPANT_NUMBER: 'participantsNumber',
    ROUTE_PROGRESS: 'routeProgress',
    TARGET_VALUE: 'targetValue',
    CURRENT_STAGE: 'currentStage',
    STAGE_PROGRESS: 'stageProgress',
    QR_CODE_LINK: 'qrCodeLink',
  },
};

export enum CHALLENGE_TYPE_NAMES {
  TeamChallenge = 'TeamChallenge',
  CitiesChallenge = 'CitiesChallenge',
  ExpressChallenge = 'ExpressChallenge',
  DuelChallenge = 'DuelChallenge',
  GroupRunChallenge = 'GroupRunChallenge',
  TeamChallengeV2 = 'TeamChallengeV2',
}

export const TEAM_CHALLENGE_REPORT = {
  TEMPLATE_PATH:
    FirebaseConstants.TemplatesFolder +
    FirebaseConstants.ReportsFolder +
    FirebaseConstants.TeamChallengeTemplateName,
  IMAGE_FIELDS: {
    COMPANY_LOGO: 'companyLogo_af_image',
    WHITE_LABEL_LOGO: 'whiteLabelLogo_af_image',
    QR_CODE: 'qrCode_af_image',
  },
  TEXT_FIELDS: {
    COMPANY_NAME: 'companyName',
    COMPANY_ID: 'companyId',
    CHALLENGE_NAME: 'challengeName',
    REPORT_DATE: 'reportDate',
    CHALLENGE_PERIOD: 'challengePeriod',
    DAY_PROGRESS: 'dayProgress',
    PARTICIPANT_NUMBER: 'participantsNumber',
    QR_CODE_LINK: 'qrCodeLink',
    TEAM_CHALLENGE_NAME: 'teamChallengeName',
    COMPANY_AVERAGE_STEP_COUNT: 'companyAverageStepCount',
  },
};

export const RANDOM_NICKNAMES = {
  en: [
    'Adventurous Apple',
    'Powerful Apricot',
    'Amusing Avocado',
    'Loud Banana',
    'Lively Pear',
    'Soft Blueberry',
    'Fun-loving Blackberry',
    'Delicious Clementine',
    'Ready to learn Cranberry',
    'Noble Date',
    'Loyal Dragon fruit',
    'Funny Strawberry',
    'Massive Fig',
    'Honest Galia melon',
    'Striking Granadilla',
    'Kind Grapefruit',
    'Brave Cherry',
    'Optimistic Coconut',
    'Curious Lime',
    'Impressive Tangerine',
    'Productive Mango',
    'Epic Mirabelle',
    'Respectful Orange',
    'Huge Papaya',
    'Carefree Peach',
    'Social Pomelo',
    'Unbeatable Watermelon',
    'Gigantic Pitahaya',
    'Unbeatable Passion fruit',
    'Harmonious Blackthorn',
    'Inspiring Cranberry',
    'Adventurous Rock Pear',
    'Large Pistachio',
    'Friendly Sugar Melon',
    'Elegant Watermelon',
    'Brilliant Grape',
    'Impressive Zitorne',
    'Epic Nashi',
    'Breathtaking Pitahaya',
    'Impeccable Giant Papaya',
    'Fabulous Physalis',
    'Happy Sweet Church',
    'Harmonious Sauerkirche',
    'Inspiring Plum',
    'Interesting Church plum',
    'Eager Scratchberry',
    'Lively Cornelian Cherry',
    'Royal Cranberry',
    'Graceful Banana',
    'Dynamic Macadamia',
    'Fine Coconut',
    'Exquisite Brazil Nut',
    'Sensational Pecan',
    'Well-known Bitter Orange',
    'Sympathetic Walnut',
    'Dreamlike Sugar melon',
    'Delicious Net melon',
    'Active Java Apple',
    'Brand new Malaya Apple',
    'Agile Lotus fruit',
  ],
  de: [
    'Abenteuerlicher Apfel',
    'Amüsante Aprikose',
    'Zielstrebige Avocado',
    'Leichte Banane',
    'Begeisternde Birne',
    'Lebenlustige Blaubeere',
    'Köstliche Brombeere ',
    'Loyale Clementine',
    'Lernbereite Brombeere ',
    'Große Clementine',
    'Köstliche Brombeere',
    'Bodenständige Dattel',
    'Massive Dattel',
    'Markante Drachenfrucht',
    'Milde Erdbeere ',
    'Einzigartige Erdbeere',
    'Elegante Feige',
    'Mutige Feige',
    'Nette Galiamelone',
    'Brilliante Galiamelone',
    'Eindrucksvolle Granadilla',
    'Neugiriege Granadilla',
    'Optimistischer Granatapfel',
    'Produktive Grapefruit ',
    'Epische Grapefruit ',
    'Zarter Granatapfel ',
    'Atemberaubende Guave',
    'Respektvolle Guave',
    'Exklusive Heidelbeere',
    'Faszinierende Himbeere',
    'Soziale Johannisbeere',
    'Frische Kaktusfeige',
    'Spontane Kaktusfeige',
    'Unschlagbare Kirsche',
    'Harmonische Limette',
    'Versierte Limette',
    'Abenteuerliche Mandarine',
    'Interessante Mango',
    'Intensive Mandarine',
    'Lebhafte Mirabelle ',
    'Zielstrebige Mirabelle',
    'Sportliche Orange',
    'Königliche Papaya',
    'Köstliche Pfirsich',
    'Edele Pomelo',
    'Legendäre Pomelo',
    'Freundliche Quitte',
    'Bodenständige Quitte',
    'Elegante Wassermelone',
    'Engagierte Wassermelone',
    'Erfahrene Zitorne',
    'Eindrucksvolle Zitorne',
    'Epische Nashi',
    'Exklusive Maracuja',
    'Brandneuer Malayaapfel',
    'Agile Lotusfrucht',
    'Großartige Artischocke',
    'Umwerfende Artischocke',
    'Gepflegter Brokkoli',
    'Achtsame Erbse',
    'Ansprechender Spinat',
    'Kräftiger Kürbis',
  ],
};
