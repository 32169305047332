import { Deserializable } from './deserializable.model';

export interface ITeamChallengeHistoryInitializer {
  endDate?: number;
  teamChallengeId?: string;
  teamChallengeName?: string;
  startDate?: number;
  teamFinalPosition?: number;
  teamName?: string;
  teamId?: string;
}

export class TeamChallengeHistory implements Deserializable {
  public endDate: number = null;
  public teamChallengeId: string = null;
  public teamChallengeName: string = null;
  public startDate: number = null;
  public teamFinalPosition: number = null;
  public teamName: string = null;
  public teamId: string = null;

  constructor(initializer?: ITeamChallengeHistoryInitializer) {
    Object.assign(this, initializer);
  }

  public deserialize(input: any): this {
    return Object.assign(this, input);
  }

  public toObject(): any {
    return Object.assign({}, this);
  }
}
