import { Deserializable } from './deserializable.model';

export interface ILocationsInitializer {
  title?: Map<string, string>;
  selector?: Map<string, string>;
  optionNames?: Array<string>;
}

export class Locations implements Deserializable {
  public title: Map<string, string> = new Map();
  public selector: Map<string, string> = new Map();
  public optionNames: Array<string> = [];

  constructor(initializer?: ILocationsInitializer) {
    Object.assign(this, initializer);
    if (initializer && initializer.title) {
      this.title =
        initializer.title instanceof Map
          ? initializer.title
          : new Map(Object.entries(initializer.title));
    }
    if (initializer && initializer.selector) {
      this.selector =
        initializer.selector instanceof Map
          ? initializer.selector
          : new Map(Object.entries(initializer.selector));
    }
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    if (input && input.title) {
      this.title = new Map(Object.entries(input.title));
    }
    if (input && input.selector) {
      this.selector = new Map(Object.entries(input.selector));
    }
    return this;
  }

  public toObject(): any {
    const title = Object.fromEntries(this.title);
    const selector = Object.fromEntries(this.selector);
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { title, selector });
  }
}
