<div class="container-card-signup">
  <div class="language-button-container">
    <button class="language-button-position" mat-button [matMenuTriggerFor]="langMenu">
      <span class="menu-label">{{ 'languages.' + settings.lang | translate }}
      </span>
      <mat-icon> language </mat-icon>
    </button>
    <mat-menu #langMenu="matMenu">
      <button mat-menu-item (click)="changeLanguage('en')">
        {{ 'languages.en' | translate }}
      </button>
      <button mat-menu-item (click)="changeLanguage('de')">
        {{ 'languages.de' | translate }}
      </button>
    </mat-menu>
  </div>
  <mat-grid-list [cols]="colSize">
    <mat-grid-tile class="logo-col" colspan="1" rowspan="1">
      <div class="img-container">
        <img src="../../../assets/logo_login.png" />
      </div>
      <div *ngIf="colSize === 1" (click)="scroll(scrollTarget)" class="expand-icon-container">
        <mat-icon>expand_more</mat-icon>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="signup-col" colspan="1" [rowspan]="colSize === 1 ? 2 : 1">
      <mat-card class="signup-card">
        <div #scrollTarget class="signup-container">
          <mat-card-title class="title">
            Move Me
          </mat-card-title>
          <mat-card-subtitle class="signup-subtitle">
            {{ 'SIGNUP_TEXT' | translate }}
          </mat-card-subtitle>
          <mat-horizontal-stepper #stepper linear="true" labelPosition="bottom">
            <mat-step [stepControl]="termsAndConditionsForm">
              <form [formGroup]="termsAndConditionsForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 0 ? 'color-primary' : 'color-gray'">task</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.TERMS_AND_CONDITIONS.TITLE' | translate }}</p>
                </div>
                <div class="scrollable-section">
                  <p> {{ 'forms.TERMS_AND_CONDITIONS.PRIVACY_POLICY_1' | translate }} </p>
                  <ul class="terms-links">
                    <li>
                      <a href="https://www.move-me-service.com/datenschutz/" target="_blank" rel="noreferrer noopener">{{ 'forms.TERMS_AND_CONDITIONS.PRIVACY_POLICY_2' | translate }}</a>
                    </li>
                    <li>
                      <a href="https://www.move-me-service.com/agb/" target="_blank" rel="noreferrer noopener">{{ 'forms.TERMS_AND_CONDITIONS.PRIVACY_POLICY_3' | translate }}</a>
                    </li>
                  </ul>
                  <p> {{ 'forms.TERMS_AND_CONDITIONS.PRIVACY_POLICY_4' | translate }} </p>
                  <div class="checkbox-container">
                    <mat-checkbox
                      color="primary"
                      formControlName="termsOfService"
                      id="termsOfService"
                    >{{ 'forms.TERMS_AND_CONDITIONS.CHECKBOX_1_TEXT' | translate }}</mat-checkbox>
                  </div>
                  <div class="checkbox-container">
                    <mat-checkbox
                      color="primary"
                      formControlName="shareData"
                      id="shareData"
                    >{{ 'forms.TERMS_AND_CONDITIONS.CHECKBOX_2_TEXT' | translate }}</mat-checkbox>
                  </div>
                </div>
                <div class="button-container">
                  <button [disabled]="!termsAndConditionsForm.valid" class="round-button validate-button" color="primary" mat-raised-button matStepperNext>
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="emailForm">
              <form [formGroup]="emailForm">

                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 1 ? 'color-primary' : 'color-gray'">mail_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.email.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.email.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-label>{{ 'forms.email.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    type="email"
                    class="form-control"
                    formControlName="email"
                    id="email"
                  />
                  <mat-error *ngIf="email.hasError('required')">
                    {{ 'forms.email.REQUIRED_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="email.hasError('pattern')">
                    {{ 'forms.email.PATTERN_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{ 'forms.confirmEmail.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    type="email"
                    class="form-control"
                    formControlName="checkEmail"
                    id="checkEmail"
                  />
                  <mat-error *ngIf="checkEmail.hasError('required')">
                    {{ 'forms.confirmEmail.REQUIRED_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="checkEmail.hasError('pattern')">
                    {{ 'forms.confirmEmail.PATTERN_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="checkEmail.hasError('ConfirmEmail')">
                    {{ 'forms.confirmEmail.EMAILS_DONT_MATCH_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <div class="used-email-error" *ngIf="showUsedEmailError">
                  <p>
                    {{ 'SIGNUP_ACCOUNT_ALREADY_EXISTS' | translate }}
                  </p>
                  <p routerLink="/login" routerDirection="forward" class="link">
                    {{ 'SIGNUP_CLICK_HERE_TO_LOGIN' | translate }}
                  </p>
                </div>
                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button *ngIf="!showUserSpinner" [disabled]="!emailForm.valid" class="round-button validate-button" color="primary" mat-raised-button (click)="checkForUsedEmail(stepper)">
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                  <button *ngIf="showUserSpinner" [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
                    <mat-spinner diameter="24"></mat-spinner>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="passwordForm">
              <form [formGroup]="passwordForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 2 ? 'color-primary' : 'color-gray'">key_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.password.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.password.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-label>{{ 'forms.password.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    [type]="isPasswordVisible ? 'text' : 'password'"
                    class="form-control"
                    formControlName="password"
                    id="password"
                  />
                  <mat-icon class="visibility-icon" matSuffix (click)="togglePasswordVisibility('password')">{{ isPasswordVisible ? 'visibility_off_outlined' : 'visibility_outlined'}}</mat-icon>
                  <mat-error *ngIf="password.hasError('required')">
                    {{ 'forms.password.REQUIRED_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="password.hasError('minlength')">
                    {{ 'forms.password.MINLENGTH_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{ 'forms.confirmPassword.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    [type]="isPasswordCheckVisible ? 'text' : 'password'"
                    class="form-control"
                    formControlName="checkPassword"
                    id="checkPassword"
                  />
                  <mat-icon class="visibility-icon" matSuffix (click)="togglePasswordVisibility('checkPassword')">{{ isPasswordCheckVisible ? 'visibility_off_outlined' : 'visibility_outlined'}}</mat-icon>
                  <mat-error *ngIf="confirmPassword.hasError('required')">
                    {{ 'forms.confirmPassword.REQUIRED_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="confirmPassword.hasError('minlength')">
                    {{ 'forms.confirmPassword.MINLENGTH_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="confirmPassword.hasError('ConfirmPassword')">
                    {{ 'forms.confirmPassword.PASSWORDS_DONT_MATCH_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button [disabled]="!passwordForm.valid" class="round-button" color="primary" mat-raised-button matStepperNext>
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="nameForm">
              <form [formGroup]="nameForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 3 ? 'color-primary' : 'color-gray'">badge_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.displayName.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.displayName.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-label>{{ 'forms.displayName.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    class="form-control"
                    formControlName="displayName"
                    id="displayName"
                  />
                  <mat-error *ngIf="displayName.hasError('required')">
                    {{ 'forms.displayName.REQUIRED_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="displayName.hasError('minlength')">
                    {{ 'forms.displayName.MINLENGTH_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="displayName.hasError('pattern')">
                    {{ 'forms.displayName.PATTERN_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button [disabled]="!nameForm.valid" class="round-button" color="primary" mat-raised-button matStepperNext>
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="nicknameForm">
              <form [formGroup]="nicknameForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 4 ? 'color-primary' : 'color-gray'">face_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.nickname.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.nickname.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-label>{{ 'forms.nickname.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    class="form-control"
                    formControlName="nickname"
                    id="nickname"
                  />
                  <mat-icon (click)="chooseRandomNickname()" class="casino-icon">casino</mat-icon>
                  <mat-error *ngIf="nickname.hasError('required')">
                    {{ 'forms.nickname.REQUIRED_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="nickname.hasError('minlength')">
                    {{ 'forms.nickname.MINLENGTH_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button [disabled]="!nicknameForm.valid" class="round-button" color="primary" mat-raised-button matStepperNext>
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="birthdateForm">
              <form [formGroup]="birthdateForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 5 ? 'color-primary' : 'color-gray'">cake_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.birthdate.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.birthdate.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-label>{{ 'forms.birthdate.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    [min]="minBirthdate"
                    [max]="maxBirthdate"
                    formControlName="birthdate"
                    id="birthdate"
                    (click)="picker.open()"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button [disabled]="!birthdateForm.valid" class="round-button" color="primary" mat-raised-button matStepperNext>
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="optionalForm">
              <form [formGroup]="optionalForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 6 ? 'color-primary' : 'color-gray'">accessibility_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.optional.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.optional.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-label>{{ 'forms.height.LABEL' | translate }} ({{ 'forms.CM' | translate }}) ({{ 'forms.OPTIONAL' | translate }})</mat-label>
                  <input
                    matInput
                    type="text"
                    class="form-control"
                    formControlName="height"
                    id="height"
                  />
                  <mat-error *ngIf="height.hasError('min') || height.hasError('max') || height.hasError('pattern')">
                    {{ 'forms.height.INVALID_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{ 'forms.weight.LABEL' | translate }} ({{ 'forms.KG' | translate }}) ({{ 'forms.OPTIONAL' | translate }})</mat-label>
                  <input
                    matInput
                    type="text"
                    class="form-control"
                    formControlName="weight"
                    id="weight"
                  />
                  <mat-error *ngIf="weight.hasError('min') || weight.hasError('max') || weight.hasError('pattern')">
                    {{ 'forms.weight.INVALID_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-select formControlName="gender" placeholder="{{ 'forms.gender.LABEL' | translate }} ({{ 'forms.OPTIONAL' | translate }})">
                    <mat-option translate *ngFor="let gender of genderEnumKeys" value="{{ gender }}">
                      {{ gender | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button [disabled]="!optionalForm.valid" class="round-button" color="primary" mat-raised-button matStepperNext>
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="healthProviderForm">
              <form [formGroup]="healthProviderForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 7 ? 'color-primary' : 'color-gray'">favorite_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.healthProvider.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.healthProvider.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-select formControlName="healthProvider" placeholder="{{ 'forms.healthProvider.LABEL' | translate }} ({{ 'forms.OPTIONAL' | translate }})">
                    <mat-option *ngFor="let healthProvider of healthProviderEnumKeys" value="{{ healthProviderEnum[healthProvider] }}">
                      {{ healthProviderEnum[healthProvider] | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-card class="info-card">
                  <p [innerHtml]="('forms.healthProvider.INFO' | translate)"></p>
                </mat-card>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button [disabled]="!optionalForm.valid" class="round-button" color="primary" mat-raised-button matStepperNext>
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="companyCodeForm">
              <form [formGroup]="companyCodeForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 8 ? 'color-primary' : 'color-gray'">business_outline</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.codeType.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.codeType.STEPPER_SUBTITLE' | translate }}</p>
                </div>

                <mat-form-field>
                  <mat-select formControlName="codeType" placeholder="{{ 'forms.codeType.LABEL' | translate }}">
                    <mat-option value="company">
                      {{ 'forms.codeType.COMPANY' | translate }}
                    </mat-option>
                    <mat-option value="team">
                      {{ 'forms.codeType.TEAM' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{ 'forms.companyCode.LABEL' | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    class="form-control"
                    formControlName="companyCode"
                    id="companyCode"
                  />
                  <mat-error *ngIf="companyCode.hasError('required')">
                    {{ 'forms.companyCode.REQUIRED_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="companyCode.hasError('minlength')">
                    {{ 'forms.companyCode.MINLENGTH_ERROR' | translate }}
                  </mat-error>
                  <mat-error *ngIf="companyCode.hasError('pattern')">
                    {{ 'forms.companyCode.PATTERN_ERROR' | translate }}
                  </mat-error>
                </mat-form-field>

                <div class="error-card-container">
                  <mat-card class="error-card" *ngIf="errorMessage && errorMessage !== ''">
                    <div class="error-card-icon">
                      <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
                    </div>
                    <div class="error-card-text">
                      {{ errorMessage | translate }}
                    </div>
                  </mat-card>
                </div>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button *ngIf="!showCompanySpinner" [disabled]="!companyCodeForm.valid" class="round-button validate-button" color="primary" mat-raised-button (click)="getCompany(stepper)">
                    <span class="material-icons next-icon">
                      chevron_right
                    </span>
                  </button>
                  <button *ngIf="showCompanySpinner" [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
                    <mat-spinner diameter="24"></mat-spinner>
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step #step>
              <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 9 ? 'color-primary' : 'color-gray'">check_circle_outline</mat-icon></ng-template>
              <div class="step-label">
                <p class="step-label-title">{{ 'forms.welcome.STEPPER_TITLE' | translate }}</p>
                <p class="step-label-subtitle">{{ 'forms.welcome.STEPPER_SUBTITLE' | translate }}</p>
              </div>
              <div class="company-info">
                <div class="company-image-container">
                  <img *ngIf="company && company.avatar !== 'assets/img/move-me-new.png'" src="{{ company?.avatar }}" alt="avatar" />
                </div>
                <div class="company-info-container">
                  <span>{{ company?.name }}</span>
                </div>
              </div>
              <div class="button-container">
                <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                  <span class="material-icons next-icon">
                    chevron_left
                  </span>
                </button>
                <button *ngIf="!showUserCreationSpinner && company?.features.has(FEATURES_LOCATIONS)" class="round-button" color="primary" mat-raised-button matStepperNext>
                  <span class="material-icons next-icon">
                    chevron_right
                  </span>
                </button>
                <button *ngIf="!showUserCreationSpinner && !company?.features.has(FEATURES_LOCATIONS)" class="round-button register-button" color="primary" mat-raised-button matStepperNext (click)= "signupUser()">
                  <span class="material-icons next-icon">
                    done
                  </span>
                </button>
                <button *ngIf="showUserCreationSpinner" [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
                  <mat-spinner diameter="24"></mat-spinner>
                </button>
              </div>
            </mat-step>

            <mat-step *ngIf="company?.features.has(FEATURES_LOCATIONS)">
              <form [formGroup]="locationsForm">
                <ng-template matStepLabel><mat-icon [ngClass]="stepper.selectedIndex > 10 ? 'color-primary' : 'color-gray'">place</mat-icon></ng-template>
                <div class="step-label">
                  <p class="step-label-title">{{ 'forms.locations.STEPPER_TITLE' | translate }}</p>
                  <p class="step-label-subtitle">{{ 'forms.locations.STEPPER_SUBTITLE' | translate }}</p>
                </div>
                <mat-form-field>
                  <mat-select formControlName="location1" placeholder="{{ company.locationsInfo.selector.get(translateService.currentLang) }}">
                    <mat-option translate *ngFor="let locationName of company.locationsInfo.optionNames" value="{{ locationName }}">
                      {{ locationName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="selectedLocations[1]?.childLocations && selectedLocations[1]?.childLocations.optionNames.length > 0">
                  <mat-select formControlName="location2" placeholder="{{ selectedLocations[1].childLocations.selector[translateService.currentLang] }}">
                    <mat-option translate *ngFor="let locationName of selectedLocations[1].childLocations.optionNames" value="{{ locationName }}">
                      {{ locationName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="selectedLocations[2]?.childLocations && selectedLocations[2]?.childLocations.optionNames.length > 0">
                  <mat-select formControlName="location3" placeholder="{{ selectedLocations[2].childLocations.selector[translateService.currentLang] }}">
                    <mat-option translate *ngFor="let locationName of selectedLocations[2].childLocations.optionNames" value="{{ locationName }}">
                      {{ locationName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="button-container">
                  <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
                    <span class="material-icons next-icon">
                      chevron_left
                    </span>
                  </button>
                  <button *ngIf="!showUserCreationSpinner" [disabled]="!locationsForm.valid" class="round-button register-button" color="primary" mat-raised-button matStepperNext (click)="signupUser()">
                    <span class="material-icons next-icon">
                      done
                    </span>
                  </button>
                  <button *ngIf="showUserCreationSpinner" [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
                    <mat-spinner diameter="24"></mat-spinner>
                  </button>
                </div>
              </form>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
        <mat-card-footer>
          <div
            class="back-link"
            fxLayout="row center"
          >
            <p
              class="ion-text-center"
              routerLink="/login"
              routerDirection="forward"
              >
              {{ 'SIGNUP_BACK_BUTTON' | translate }}
            </p>
          </div>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
