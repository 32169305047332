import {
  CityDetails,
  ICityDetailsInitializer,
} from '../city-details/city-details.model';

export interface ICityInfoInitializer {
  id?: string;
  name?: string;
  subtitle?: string;
  link?: string;
  cityInfoType?: string;
  details?: Array<ICityDetailsInitializer>;
}

export class CityInfo {
  public id: string = null;
  public name: string = null;
  public subtitle: string = null;
  public link: string = null;
  public cityInfoType: string = null;
  public details: Array<CityDetails> = [];

  constructor(iCityInfoInitializer: ICityInfoInitializer = {}) {
    Object.assign(this, iCityInfoInitializer);

    if (iCityInfoInitializer.details?.length > 0) {
      this.details = iCityInfoInitializer.details.map(
        (detail) => new CityDetails(detail)
      );
    }
  }

  public toObject(): ICityInfoInitializer {
    const { details, ...otherProperties } = this;
    return {
      ...otherProperties,
      details: details.map((cityDetails) => cityDetails.toObject()),
    };
  }

  public updateDetails(index: number, cityDetails: CityDetails): void {
    if (index < this.details.length && index >= 0) {
      if (cityDetails) {
        this.details[index] = cityDetails;
      } else {
        throw new Error('city details does not exist');
      }
    } else {
      throw new Error('incorrect index');
    }
  }

  public removeDetails(index: number): void {
    if (index < this.details.length && index >= 0) {
      this.details.splice(index, 1);
    } else {
      throw new Error('incorrect index');
    }
  }

  public addDetails(cityDetails: CityDetails): void {
    if (cityDetails) {
      this.details.push(cityDetails);
    } else {
      throw new Error('city details does not exist');
    }
  }
}
