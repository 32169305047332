<div *ngIf="teamChallenge" class="team-challenge-info-card">
  <div class="image-container">
    <img [src]="GROUP_OF_PEOPLE_AND_LOGO">
  </div>
  <div class="challenge-info-container">
    <p class="challenge-name">{{ teamChallenge.name }}</p>
    <p class="challenge-dates"> {{ 'TEAM_CHALLENGE_INFO.DATES' | translate : {
      startDate: (teamChallenge.startDate | date: 'dd.MM.YYYY'),
      endDate: (teamChallenge.endDate | date: 'dd.MM.YYYY')
    } }}</p>
    <div class="challenge-extra-info-container">
      <div class="extra-info-element">
        <p>{{ teamChallenge.getDaysDuration() }}</p>
        <p>{{ 'TEAM_CHALLENGE_INFO.DURATION_DAYS' | translate }}</p>
      </div>
      <div class="extra-info-element">
        <p>{{ teamChallenge.maxParticipantsPerTeam === 0 ? ('TEAM_CHALLENGE_INFO.NO_LIMIT' | translate) : teamChallenge.maxParticipantsPerTeam }}</p>
        <p>{{ 'TEAM_CHALLENGE_INFO.MAX_PARTICIPANTS_PER_TEAM' | translate }}</p>
      </div>
    </div>
  </div>
</div>
