<h1 mat-dialog-title>{{ company.name }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Status</mat-label>
    <mat-select required [(value)]="company.status">
      <mat-option
        *ngFor="let status of statusEnumNames; let i = index"
        [value]="statusEnumKeys[i]"
      >
        {{ status }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button [mat-dialog-close]="company" (click)="updateCompanyStatus()" cdkFocusInitial>Update Status</button>
</div>
