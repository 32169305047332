import { Component, OnInit } from '@angular/core';

// Models
import { Company } from 'src/app/models/company.model';
import { Location } from 'src/app/models/location.model';

// Services
import { CompanyService } from 'src/app/services/company/company.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  public company: Company;
  public showSpinner = true;

  constructor(
    private subscriptionService: SubscriptionService,
    private companyService: CompanyService
  ) {}

  get locations(): Array<Location> {
    return this.companyService.locations;
  }

  ngOnInit(): void {
    this.subscriptionService.company.subscribe((company) => {
      if (company) {
        this.company = company;
        this.showSpinner = false;
      }
    });
  }
}
