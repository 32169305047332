import { TeamRecord } from './team-record.model';
import { TeamSchema } from './team-schema.model';

export interface ITeamInitializer {
  id?: string;
  avatar?: string;
  color?: string;
  name?: string;
  teamCaptain?: string;
  members?: Array<string>;
  records?: Array<object>;
  currentTeamChallengeId?: string;
  totalSteps?: number;
  totalPoints?: number;
  location?: string;
}

export class Team extends TeamSchema {
  public id = '';
  public avatar = '';
  public teamCaptain = '';
  public color = '';
  public name = '';
  public members: Set<string> = new Set<string>();
  public records: Array<TeamRecord> = new Array<TeamRecord>();
  public currentTeamChallengeId = '';
  public location = '';
  public totalSteps = 0; // historical
  public totalPoints = 0; // historical

  constructor(initializer?: ITeamInitializer) {
    super(initializer);
    Object.assign(this, initializer);

    if (initializer && initializer.members) {
      this.members = new Set<string>(initializer.members);
    }
    if (initializer && initializer.records) {
      this.records = initializer.records.map((record: any) =>
        new TeamRecord().deserialize(record)
      );
    }
  }

  public toObject(): any {
    const records = this.records.map((record: TeamRecord) => record.toObject());
    const members = Array.from(this.members);
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { members, records });
  }

  public deserialize(input: any): this {
    Object.assign(this, input);

    this.records = input.records.map((record: any) =>
      new TeamRecord().deserialize(record)
    );
    this.members = new Set(input.members);

    return this;
  }
}
