import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from 'src/app/helpers/helpers';
import { City } from 'src/app/models/cities-challenge/city/city.model';

export interface IStageProgressCard {
  title: string;
  totalProgress: number;
  cityFrom: City;
  cityTo: City;
  progressTitle?: string;
  progressSubtitle?: string;
}

@Component({
  selector: 'app-stage-progress',
  templateUrl: './stage-progress.component.html',
  styleUrls: ['./stage-progress.component.scss'],
})
export class StageProgressComponent implements AfterViewInit, OnChanges {
  @Input() stageProgressCard: IStageProgressCard;
  @ViewChild('circleProgressCardContent', { static: true })
  contentElement: ElementRef;

  public totalProgress: number = 0;

  constructor(private translateService: TranslateService) {}

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stageProgressCard) {
      this.totalProgress =
        this.stageProgressCard.totalProgress > 100
          ? 100
          : this.stageProgressCard.totalProgress;
    }
  }

  ngAfterViewInit(): void {
    Helpers.calculateCardContentHeight(this.contentElement, 32);
  }

  public getFlagPath(city: City): string {
    return Helpers.getFlagPath(city.countryAlphaCode, true);
  }
}
