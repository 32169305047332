import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CompanyStatusDialogComponent } from '../company-status-dialog/company-status-dialog.component';

interface Option {
  value: any;
  label: string;
}

interface UpdatePropertyDialogData {
  currentSelection: any;
  options: Array<Option>;
  propertyToUpdate: string;
  object: any;
  objectId: string;
  objectRoute: Array<FirebaseConstants | string>;
}

@Component({
  selector: 'app-update-property-dialog',
  templateUrl: './update-property-dialog.component.html',
  styleUrls: ['./update-property-dialog.component.scss'],
})
export class UpdatePropertyDialogComponent implements OnInit {
  public selectedOption: any;

  constructor(
    public dialogRef: MatDialogRef<CompanyStatusDialogComponent>,
    private firebaseService: FirebaseService,
    @Inject(MAT_DIALOG_DATA) public data: UpdatePropertyDialogData
  ) {}

  ngOnInit(): void {
    this.selectedOption = String(this.data.currentSelection);
    console.log('data: ', this.data);
    console.log('selectedOption: ', this.selectedOption);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async updateOption(): Promise<void> {
    const path = this.data.objectRoute.reduce(
      (accumulator, routePoint) => accumulator + routePoint,
      ''
    );
    console.log('path: ', path);

    if (!Array.isArray(this.data.object[this.data.propertyToUpdate])) {
      const update = {
        [this.data.propertyToUpdate]:
          typeof this.data.object[this.data.propertyToUpdate] === 'number'
            ? Number(this.selectedOption)
            : this.selectedOption,
      };

      this.firebaseService
        .updateObject(path, this.data.objectId, update)
        .then(() => {
          this.data.object[this.data.propertyToUpdate] =
            typeof this.data.object[this.data.propertyToUpdate] === 'number'
              ? Number(this.selectedOption)
              : this.selectedOption;
        })
        .catch((error) => {
          console.log('firebaseService.updateObject - error: ', error);
        });
    } else {
      const arrayToUpdate: Array<any> = this.data.object[
        this.data.propertyToUpdate
      ];
      const indexToUpdate = arrayToUpdate.findIndex(
        (arrayElement) => arrayElement === String(this.data.currentSelection)
      );
      if (indexToUpdate >= 0) {
        arrayToUpdate[indexToUpdate] = this.selectedOption;
        this.data.object[this.data.propertyToUpdate] = arrayToUpdate;
      } else {
        this.data.object[this.data.propertyToUpdate].push(this.selectedOption);
      }
    }
  }
}
