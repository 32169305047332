<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
  *ngIf="user && settingsForm"
>
  <h1 class="title">{{ 'SETTINGS.TITLE' | translate }}</h1>
  <form [formGroup]="settingsForm" (ngSubmit)="saveSettings()">
    <mat-form-field class="name-field">
      <mat-label>{{ 'forms.companyId.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="text"
        class="form-control"
        formControlName="companyId"
        id="companyId"
      />
      <!-- <mat-error *ngIf="companyId.hasError('pattern')">
                Special characters are not accepted in name fields
            </mat-error> -->
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'forms.email.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="email"
        class="form-control"
        formControlName="email"
        id="email"
      />
    </mat-form-field>
    <mat-form-field class="name-field">
      <mat-label>{{ 'forms.companyCode.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="text"
        class="form-control"
        formControlName="companyName"
        id="companyName"
      />
      <mat-error *ngIf="companyName?.hasError('pattern')">
        {{ 'forms.companyName.PATTERN_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="name-field">
      <mat-label>{{ 'forms.displayName.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="text"
        class="form-control"
        formControlName="displayName"
        id="displayName"
      />
      <mat-error *ngIf="displayName?.hasError('pattern')">
        {{ 'forms.displayName.PATTERN_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <br />
    <button
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="
        !settingsForm?.valid ||
        savingSettings ||
        (!(displayName?.dirty && displayName?.value !== user.displayName) &&
          !(companyName?.dirty && companyName?.value !== company.name))
      "
    >
      {{ savingSettings ? '' : SAVE_SETTINGS_BUTTON_LABEL }}
      <mat-spinner [diameter]="16" *ngIf="savingSettings"></mat-spinner>
    </button>
    <br />
  </form>

  <form [formGroup]="changeEmailForm" (ngSubmit)="changeEmail()">
    <h3 class="subtitle">{{ 'SETTINGS.SUBTITLE_CHANGE_EMAIL' | translate }}</h3>
    <mat-form-field>
      <mat-label>{{ 'forms.newEmail.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="email"
        class="form-control"
        formControlName="newEmail"
        id="newEmail"
      />
      <mat-error *ngIf="newEmail.hasError('required')">
        {{ 'forms.newEmail.REQUIRED_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="newEmail.hasError('pattern')">
        {{ 'forms.newEmail.PATTERN_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'forms.confirmNewEmail.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="email"
        class="form-control"
        formControlName="confirmEmail"
        id="confirmEmail"
      />
      <mat-error *ngIf="confirmEmail.hasError('required')">
        {{ 'forms.confirmNewEmail.REQUIRED_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="confirmEmail.hasError('pattern')">
        {{ 'forms.confirmNewEmail.PATTERN_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="confirmEmail.hasError('ConfirmEmail')">
        {{ 'forms.confirmNewEmail.EMAILS_DONT_MATCH_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'forms.password.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="password"
        class="form-control"
        formControlName="newEmailPassword"
        id="newEmailPassword"
      />
      <mat-error *ngIf="newEmailPassword.hasError('required')">
        {{ 'forms.password.REQUIRED_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="newEmailPassword.hasError('minlength')">
        {{ 'forms.password.MINLENGTH_ERROR' | translate }}
      </mat-error>
    </mat-form-field>

    <br />

    <button
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="!changeEmailForm.valid || changingEmail"
    >
      {{ changingEmail ? '' : SAVE_EMAIL_BUTTON_LABEL }}
      <mat-spinner [diameter]="16" *ngIf="changingEmail"></mat-spinner>
    </button>

    <br />
  </form>

  <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
    <h3 class="subtitle">
      {{ 'SETTINGS.SUBTITLE_CHANGE_PASSWORD' | translate }}
    </h3>
    <mat-form-field>
      <mat-label>{{ 'forms.newPassword.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="password"
        class="form-control"
        formControlName="newPassword"
        id="newPassword"
      />
      <mat-error *ngIf="newPassword.hasError('required')">
        {{ 'forms.newPassword.REQUIRED_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="newPassword.hasError('minlength')">
        {{ 'forms.newPassword.MINLENGTH_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'forms.confirmPassword.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="password"
        class="form-control"
        formControlName="confirmPassword"
        id="confirmPassword"
      />
      <mat-error *ngIf="confirmPassword.hasError('required')">
        {{ 'forms.confirmPassword.REQUIRED_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="confirmPassword.hasError('minlength')">
        {{ 'forms.confirmPassword.MINLENGTH_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="confirmPassword.hasError('ConfirmPassword')">
        {{ 'forms.confirmPassword.PASSWORDS_DONT_MATCH_ERROR' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'forms.oldPassword.LABEL' | translate }}</mat-label>
      <input
        matInput
        type="password"
        class="form-control"
        formControlName="oldPassword"
        id="oldPassword"
      />
      <mat-error *ngIf="oldPassword.hasError('required')">
        {{ 'forms.password.REQUIRED_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="oldPassword.hasError('minlength')">
        {{ 'forms.password.MINLENGTH_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
    <br />
    <button
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="!changePasswordForm.valid || changingPassword"
    >
      {{ changingPassword ? '' : SAVE_PASSWORD_BUTTON_LABEL }}
      <mat-spinner [diameter]="16" *ngIf="changingPassword"></mat-spinner>
    </button>
    <br />
  </form>

  <h3 fxHide.lg fxHide.md class="subtitle">
    {{ 'SETTINGS.SUBTITLE_CHANGE_LANGUAGE' | translate }}
  </h3>

  <button
    fxHide.lg
    fxHide.gt-md
    mat-button
    [matMenuTriggerFor]="langMenu"
    fxLayoutAlign="center space-between"
  >
    <span class="menu-label"
      >{{ 'languages.' + settings.lang | translate }}
    </span>
    <mat-icon> language </mat-icon>
  </button>
  <mat-menu #langMenu="matMenu">
    <button mat-menu-item (click)="changeLanguage('en')">
      {{ 'languages.en' | translate }}
    </button>
    <button mat-menu-item (click)="changeLanguage('de')">
      {{ 'languages.de' | translate }}
    </button>
  </mat-menu>
</div>
<div class="spinner-container" *ngIf="!user || !settingsForm">
  <mat-spinner></mat-spinner>
</div>
