<h1 mat-dialog-title>{{ data.translations.get(currentLang) }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Link</mat-label>
    <input matInput [(ngModel)]="data.link">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Save</button>
  <button mat-button (click)="goToLink(data.link)" cdkFocusInitial>Follow Link</button>
</div>
