import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Helpers } from 'src/app/helpers/helpers';
import { Company } from 'src/app/models/company.model';
import { Features as TeamChallengeFeatures } from 'src/app/models/team-challenge/features';
import { TeamChallengeService } from 'src/app/services/team-challenge/team-challenge.service';
import {
  IHierarchyLevelName,
  LocationService,
} from 'src/app/services/location/location.service';
import { ITeamTableElement } from '../../team-challenge-v2/teams-table/teams-table.component';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';

@Component({
  selector: 'app-team-challenge-form',
  templateUrl: './team-challenge-form.component.html',
  styleUrls: ['./team-challenge-form.component.scss'],
})
export class TeamChallengeFormComponent implements OnInit {
  @Input() teamChallengeDataForm: UntypedFormGroup;
  @Input() availableLocationLevels: Array<IHierarchyLevelName> = [];
  @Input() company: Company;
  @Input() showSaveButton: boolean = false;

  public minDate: Date = Helpers.addDays(new Date(), 1);
  public addingTeams = false;

  public TEAM_CHALLENGE_FEATURES = TeamChallengeFeatures;
  public teamTableElement: ITeamTableElement;
  public showSavingSpinner = false;

  constructor(
    private teamChallengeService: TeamChallengeService,
    private locationService: LocationService,
    private changeDetectorRef: ChangeDetectorRef,
    private userMessageService: UserMessageService
  ) {}

  get features(): Array<TeamChallengeFeatures> {
    return this.teamChallengeDataForm.controls.features.value;
  }
  get teamChallengeStartDate(): UntypedFormControl {
    return this.teamChallengeDataForm.controls.startDate as UntypedFormControl;
  }
  get teamChallengeEndDate(): UntypedFormControl {
    return this.teamChallengeDataForm.controls.endDate as UntypedFormControl;
  }
  get teamForms(): UntypedFormArray {
    return this.teamChallengeDataForm.controls.teamForms as UntypedFormArray;
  }

  ngOnInit(): void {
    const todayIso = Helpers.createISODateTime();
    const startDateValue = this.teamChallengeDataForm.controls.startDate.value;
    this.teamTableElement = {
      companyId: this.company.id,
      teamChallengeId: this.teamChallengeDataForm.controls.id.value,
      showDelete:
        startDateValue && Helpers.createISODateTime(startDateValue) <= todayIso
          ? false
          : true,
    };

    if (this.features.includes(this.TEAM_CHALLENGE_FEATURES.LOCATIONS)) {
      this.locationService
        .getLocationByHierarchyLevel(
          this.company.id,
          Number(this.teamChallengeDataForm.controls.locationLevel.value)
        )
        .then(
          (locationArray) =>
            (this.teamTableElement = {
              ...this.teamTableElement,
              locations: locationArray,
            })
        )
        .catch((error) => {
          console.log('getLocationByHierarchyLevel - error: ', error);
          this.userMessageService.snackBarMessage(
            'USER_MESSAGE_SERVICE.GENERIC_ERROR'
          );
        });
    }

    this.teamChallengeService.setFeaturesToForm(
      this.teamChallengeDataForm,
      this.features
    );

    this.teamChallengeDataForm.controls.durationInWeeks.valueChanges.subscribe(
      () => {
        if (this.teamChallengeDataForm.controls.durationInWeeks.valid) {
          this.teamChallengeStartDate.enable();
        } else {
          this.teamChallengeStartDate.disable();
        }
      }
    );

    this.teamChallengeStartDate.valueChanges.subscribe(
      (dateStartValue: Date) => {
        const challengeDuration = this.teamChallengeDataForm.controls
          .durationInWeeks.value;
        if (dateStartValue !== null && challengeDuration > 0) {
          const endDate = new Date(dateStartValue.getTime());
          endDate.setDate(endDate.getDate() + 7 * challengeDuration - 1);
          this.teamChallengeEndDate.setValue(endDate);
        }
      }
    );

    this.teamChallengeDataForm.controls.locationLevel.valueChanges.subscribe(
      (locationLevel: string) => {
        if (
          this.features.includes(this.TEAM_CHALLENGE_FEATURES.TEAM_SELECTION)
        ) {
          // Remove old locations if the level has changed
          this.teamForms.controls.forEach((teamForm: UntypedFormGroup) =>
            teamForm.controls.location.setValue(null)
          );
          this.locationService
            .getLocationByHierarchyLevel(this.company.id, Number(locationLevel))
            .then(
              (locationArray) =>
                (this.teamTableElement = {
                  ...this.teamTableElement,
                  locations: locationArray,
                })
            )
            .catch((error) => {
              console.log('getLocationByHierarchyLevel - error: ', error);
              this.userMessageService.snackBarMessage(
                'USER_MESSAGE_SERVICE.GENERIC_ERROR'
              );
            });
        }
      }
    );

    this.teamChallengeDataForm.controls.features.valueChanges.subscribe(
      (features: Array<string>) => {
        if (!features.includes(this.TEAM_CHALLENGE_FEATURES.TEAM_SELECTION)) {
          this.teamForms.clear();
        }
        this.teamChallengeService.setFeaturesToForm(
          this.teamChallengeDataForm,
          features
        );
      }
    );
  }

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }

  public async addRandomTeams(): Promise<void> {
    // On some situations this function when locations is active generate an NG0100 error.
    this.addingTeams = true;
    const numberOfTeams = this.teamChallengeDataForm.controls.teamsAmount.value;
    this.teamChallengeDataForm.controls.teamsAmount.setValue(0);
    const newForms = [
      ...(
        await this.teamChallengeService.getRandomTeams(
          numberOfTeams,
          this.teamForms.length
        )
      ).map((team) => {
        const teamForm = this.teamChallengeService.getStandardTeamDataForm();
        teamForm.patchValue(team.toObject());
        return teamForm;
      }),
    ];

    newForms.forEach((form) => this.teamForms.insert(0, form));
    this.teamChallengeService.setFeaturesToForm(
      this.teamChallengeDataForm,
      this.features
    );
    this.teamTableElement = { ...this.teamTableElement };
    this.changeDetectorRef.detectChanges();
    this.addingTeams = false;
  }

  public async saveTeamChallenge(): Promise<void> {
    this.showSavingSpinner = true;

    // Save avatars if it is necessary
    this.teamChallengeService
      .saveTeamChallengeFromForm(this.company.id, this.teamChallengeDataForm)
      .then(() => {
        this.showSavingSpinner = false;
        this.userMessageService.snackBarMessage(
          'TEAM_CHALLENGE_FORM.SAVE_TEAM_CHALLENGE_BUTTON.SUCCESS_SNACKBAR.MESSAGE'
        );
      })
      .catch((error) => {
        console.log('error: ', error);
        this.showSavingSpinner = false;
        this.userMessageService.snackBarMessage(
          'TEAM_CHALLENGE_FORM.SAVE_TEAM_CHALLENGE_BUTTON.ERROR_SNACKBAR.MESSAGE'
        );
      });
  }
}
