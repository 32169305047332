<table mat-table [dataSource]="companyDataSource" matSort>
  <!-- Avatar Column -->
  <ng-container matColumnDef="avatar">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let company" class="avatar">
        <img [src]="company.avatar" alt="avatar" onerror="this.onerror=null;this.src='assets/img/default-placeholder.png';"/>
    </td>
  </ng-container>
  <!-- ID Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "COMPANIES_TABLE.ID" | translate }}
    </th>
    <td mat-cell *matCellDef="let company">{{ company.id }}</td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "COMPANIES_TABLE.NAME" | translate }}
    </th>
    <td mat-cell *matCellDef="let company">{{ company.name }}</td>
  </ng-container>

  <!-- Users Column -->
  <ng-container matColumnDef="users">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "COMPANIES_TABLE.USERS" | translate }}
    </th>
    <td mat-cell *matCellDef="let company">{{ company.users.length }}</td>
  </ng-container>

  <!-- Available Licenses Column -->
  <ng-container matColumnDef="availableLicenses">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "COMPANIES_TABLE.AVAILABLE_LICENSES" | translate }}
    </th>
    <td mat-cell *matCellDef="let company">
      {{ company.availableLicenses }}
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="companyColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: companyColumns" (click)="goToCompany(row.id)"></tr>
</table>
