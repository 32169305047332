import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WhiteLabelButton } from 'src/app/models/white-label-button.model';
import { WhiteLabel } from 'src/app/models/white-label.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { AdminTabs } from 'src/app/pages/admin/admin-tabs.enum';
import {
  REGEX_LINK,
  REGEX_ALPHANUMERIC_EXTENDED,
  WHITE_LABEL_DISTANCE_CHALLENGE_REPORT_TEMPLATE_PATH,
  WHITE_LABEL_ID,
  WHITE_LABEL_TEAM_CHALLENGE_REPORT_TEMPLATE_PATH,
} from 'src/app/constants/constants';
import { ReportTemplates } from 'src/app/models/reports/report-templates.model';

@Component({
  selector: 'app-add-white-label',
  templateUrl: './add-white-label.component.html',
  styleUrls: ['./add-white-label.component.scss'],
})
export class AddWhiteLabelComponent implements OnInit {
  public whiteLabelDataForm: UntypedFormGroup;
  public whiteLabelButtonDataForm: UntypedFormGroup;

  public showSpinner = false;
  public whiteLabelLogo = null;
  public showWhiteLabelLogo = false;

  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    this.whiteLabelDataForm = this.formBuilder.group({
      logo: ['assets/img/move-me-new.png'],
      logoImg: [undefined, Validators.required],
      color: ['#f97a1c', Validators.required],
      id: [{ value: '', disabled: true }, Validators.required],
      name: [
        '',
        Validators.compose([
          Validators.maxLength(20),
          Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED),
          Validators.required,
        ]),
      ],
      button: [false],
      distanceReportFile: [''],
      distanceReportFileSource: [''],
      teamReportFile: [''],
      teamReportFileSource: [''],
    });

    this.whiteLabelButtonDataForm = this.formBuilder.group({
      color: ['#f97a1c', Validators.required],
      text: [
        '',
        Validators.compose([
          Validators.maxLength(50),
          Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED),
          Validators.required,
        ]),
      ],
      link: [
        '',
        Validators.compose([
          Validators.pattern(REGEX_LINK),
          Validators.required,
        ]),
      ],
    });

    this.whiteLabelDataForm.controls.name.valueChanges.subscribe((newValue) => {
      if (newValue) {
        this.whiteLabelDataForm.controls.id.setValue(newValue.toUpperCase());
      }
    });

    this.whiteLabelDataForm.controls.logoImg.valueChanges.subscribe(
      (newImgValue) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(newImgValue.files[0]);

        fileReader.addEventListener('load', (event) => {
          this.whiteLabelLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
            String(event.target?.result)
          );
          this.showWhiteLabelLogo = true;
        });
      }
    );

    this.whiteLabelDataForm.controls.distanceReportFile.valueChanges.subscribe(
      (newValue) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(newValue.files[0]);

        fileReader.addEventListener('load', (event) => {
          this.whiteLabelDataForm.patchValue({
            distanceReportFileSource: event.target?.result,
          });
        });
      }
    );

    this.whiteLabelDataForm.controls.teamReportFile.valueChanges.subscribe(
      (newValue) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(newValue.files[0]);

        fileReader.addEventListener('load', (event) => {
          this.whiteLabelDataForm.patchValue({
            teamReportFileSource: event.target?.result,
          });
        });
      }
    );
  }

  ngOnInit(): void {}

  public async addWhiteLabel(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (this.whiteLabelDataForm.valid) {
      const whiteLabelId = this.whiteLabelDataForm.controls.id.value;

      if (this.whiteLabelDataForm.controls.logoImg.value) {
        const logoFile = this.whiteLabelDataForm.controls.logoImg.value
          .files[0];

        await this.firebaseService
          .uploadImage(logoFile, 'whiteLabels/' + whiteLabelId + '/logo')
          .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            this.whiteLabelDataForm.controls.logo.setValue(url);
          });
      }

      const reportTemplates = new ReportTemplates();

      if (this.whiteLabelDataForm.value.distanceReportFileSource) {
        const distanceChallengePath = WHITE_LABEL_DISTANCE_CHALLENGE_REPORT_TEMPLATE_PATH.replace(
          WHITE_LABEL_ID,
          whiteLabelId
        );
        await this.firebaseService
          .uploadFile(
            this.whiteLabelDataForm.value.distanceReportFileSource,
            distanceChallengePath
          )
          .then(async (uploadTaskSnapshot) => {
            reportTemplates.distanceChallenge = await uploadTaskSnapshot.ref.getDownloadURL();
          })
          .catch((error) => {
            console.log(
              'error while uploading distance challenge report template: ',
              error
            );
          });
      }

      if (this.whiteLabelDataForm.value.teamReportFileSource) {
        const teamChallengePath = WHITE_LABEL_TEAM_CHALLENGE_REPORT_TEMPLATE_PATH.replace(
          WHITE_LABEL_ID,
          whiteLabelId
        );
        await this.firebaseService
          .uploadFile(
            this.whiteLabelDataForm.value.teamReportFileSource,
            teamChallengePath
          )
          .then(async (uploadTaskSnapshot) => {
            reportTemplates.teamChallenge = await uploadTaskSnapshot.ref.getDownloadURL();
          })
          .catch((error) => {
            console.log(
              'error while uploading team challenge report template: ',
              error
            );
          });
      }

      let whiteLabelButton = null;

      if (this.whiteLabelDataForm.controls.button.value) {
        whiteLabelButton = new WhiteLabelButton({
          color: this.whiteLabelButtonDataForm.value.color,
          text: this.whiteLabelButtonDataForm.value.text,
          link: this.whiteLabelButtonDataForm.value.link,
        });
      }

      const whiteLabel = new WhiteLabel({
        id: this.whiteLabelDataForm.controls.id.value,
        name: this.whiteLabelDataForm.value.name,
        logo: this.whiteLabelDataForm.value.logo,
        color: this.whiteLabelDataForm.value.color,
        button: whiteLabelButton,
        reportTemplates,
      });

      this.firebaseService.createWhiteLabel(whiteLabel);

      this.showSpinner = false;
      this.snackBar.open('White Label correctly created', 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
      this.router.navigate(['admin'], {
        state: { tab: AdminTabs.WhiteLabels },
      });
    }
  }

  public onChangeWhiteLabelColor(color: string): void {
    this.whiteLabelDataForm.patchValue({ color });
  }

  public onChangeWhiteLabelButtonColor(color: string): void {
    this.whiteLabelButtonDataForm.patchValue({ color });
  }

  public returnPage(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.WhiteLabels } });
  }
}
