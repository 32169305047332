import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'challengeDuration',
})
export class ChallengeDurationPipe implements PipeTransform {
  public DAYS = '';
  public DAY = '';
  public WEEKS = '';
  public WEEK = '';
  public YEARS = '';
  public YEAR = '';

  // challengeDuration in days
  transform(challengeDuration: number, lang: string): string {
    let duration = '';

    this.DAY = lang === 'en' ? ' day challenge' : ' Tag-Challenge';
    this.DAYS = lang === 'en' ? '  days challenge' : ' Tage-Challenge';
    this.WEEK = lang === 'en' ? ' week challenge' : ' Woche-Challenge';
    this.WEEKS = lang === 'en' ? ' weeks challenge' : ' Wochen-Challenge';
    this.YEARS = lang === 'en' ? ' year challenge' : ' Jahr-Challenge';
    this.YEAR = lang === 'en' ? ' years challenge' : ' Jahre-Challenge';

    const units = [
      {
        duration: 365,
        singular: this.YEAR,
        plural: this.YEARS,
      },
      {
        duration: 7,
        singular: this.WEEK,
        plural: this.WEEKS,
      },
      {
        duration: 1,
        singular: this.DAY,
        plural: this.DAYS,
      },
    ];

    for (const unit of units) {
      if (challengeDuration >= unit.duration) {
        const numUnits = Math.round(challengeDuration / unit.duration);
        duration = `${numUnits} ${
          numUnits === 1 ? unit.singular : unit.plural
        }`;
        break;
      }
    }
    return duration;
  }
}
