<mat-card *ngIf="nextCitiesChallenge">
  <div class="title">
    <h1>{{ nextCitiesChallenge.name }}</h1>
  </div>
  <mat-card-content>
    <div class="countries">
      <h2>{{ 'CITIES_CHALLENGE.STARTS_SOON_CARD.FROM' | translate }}</h2>
      <img [src]="getFlagPath(cityFrom)" alt="City from flag">
      <h2> {{ cityFrom.translations.get(currentLang) ? cityFrom.translations.get(currentLang) : cityFrom.name }}</h2>
    </div>
    <div class="challenge-info">
      <h2 *ngIf="startTomorrow">{{ 'CITIES_CHALLENGE.STARTS_SOON_CARD.STARTS_SOON' | translate }}</h2>
      <app-challenge-countdown *ngIf="startTomorrow" [startDate]="nextCitiesChallenge.startDate"></app-challenge-countdown>
      <h2>{{ 'CITIES_CHALLENGE.STARTS_SOON_CARD.TOTAL_KILOMETERS' | translate }}</h2>
      <p>{{ nextCitiesChallenge.totalDistance | number: '1.0-0':'de' }}</p>
    </div>
    <div class="countries">
      <h2>{{ 'CITIES_CHALLENGE.STARTS_SOON_CARD.TO' | translate }}</h2>
      <img [src]="getFlagPath(cityTo)" alt="City to flag">
      <h2> {{ cityTo.translations.get(currentLang) ? cityTo.translations.get(currentLang) : cityTo.name }}</h2>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <div class="dates">
      <h3>{{ nextCitiesChallenge.startDate | date: 'dd.MM.yyyy' }}</h3>
      <p>{{ 'CITIES_CHALLENGE.STARTS_SOON_CARD.STARTS' | translate }}</p>
    </div>
    <div class="clock-icon">
      <mat-icon [svgIcon]="CLOCK_OUTLINED_ICON"></mat-icon>
    </div>
    <div class="dates">
      <h3>{{ nextCitiesChallenge.endDate | date: 'dd.MM.yyyy' }}</h3>
      <p>{{ 'CITIES_CHALLENGE.STARTS_SOON_CARD.ENDS' | translate }}</p>
    </div>
  </mat-card-footer>
</mat-card>
