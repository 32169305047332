<mat-form-field>
  <mat-label>{{ 'COMPANY_INFO.TEAMS_CHALLENGE.TABLE.FILTER_LABEL' | translate }}</mat-label>
  <input matInput (keyup)="applyFilter($event)" [placeholder]="'COMPANY_INFO.TEAMS_CHALLENGE.TABLE.FILTER_PLACEHOLDER' | translate " #input>
  <mat-hint>{{ 'COMPANY_INFO.TEAMS_CHALLENGE.TABLE.FILTER_HINT' | translate }}</mat-hint>
</mat-form-field>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="teamChallengesDataSource" matSort>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMPANY_INFO.TEAMS_CHALLENGE.TABLE.NAME' | translate }}</th>
      <td mat-cell *matCellDef="let teamChallenge">{{ teamChallenge.name }}</td>
    </ng-container>
  
    <!-- Start Date Column -->
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMPANY_INFO.TEAMS_CHALLENGE.TABLE.START_DATE' | translate }}</th>
      <td mat-cell *matCellDef="let teamChallenge">{{ teamChallenge.startDate | date: 'dd.MM.yyyy' }}</td>
    </ng-container>
  
    <!-- End Date Column -->
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMPANY_INFO.TEAMS_CHALLENGE.TABLE.END_DATE' | translate }}</th>
      <td mat-cell *matCellDef="let teamChallenge">{{ teamChallenge.endDate | date: 'dd.MM.yyyy' }}</td>
    </ng-container>
  
    <!-- Project Plan Column -->
    <ng-container matColumnDef="projectPlan">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Project Plan
      </th>
      <td mat-cell *matCellDef="let teamChallenge">
        <button mat-raised-button #projectPlanButton (click)="sendProjectPlan(teamChallenge, projectPlanButton)">
          <mat-spinner diameter="20" *ngIf="projectPlanButton.disabled"></mat-spinner>
          <mat-icon *ngIf="!projectPlanButton.disabled">outgoing_mail</mat-icon>
        </button>
      </td>
    </ng-container>
  
    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let teamChallenge">
        <button mat-raised-button (click)="goToTeamChallenge(teamChallenge.id)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="teamChallengeColumns"></tr>
    <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: teamChallengeColumns"></tr>

     <!-- Row shown when there is no matching data. -->
     <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{ 'COMPANY_INFO.TEAMS_CHALLENGE.TABLE.FILTER_MESSAGE' | translate}} "{{input.value}}"</td>
    </tr>
  </table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of team challenges"></mat-paginator>
</div>

