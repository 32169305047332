import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class GenericValidators {
  static limitInput = (maxLength: number): ValidatorFn => (
    control: AbstractControl
  ): ValidationErrors => {
    const inputValue: string = control.value || '';

    // Limit input value
    const limitedValue = inputValue.slice(0, maxLength);
    if (inputValue !== limitedValue) {
      control.setValue(limitedValue, { emitEvent: false });
    }

    return null;
  };
}
