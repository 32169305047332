<div class="container" *ngIf="user && team">
  <h1 class="title" id="competition-title">
    {{ 'MY_TEAM_TITLE' | translate }}
  </h1>
  <div class="title-row" fxLayout="column">
    <img src="{{ team.avatar }}" alt="" />
    <h2 class="subtitle" [ngStyle]="{ 'color': team.color }"><b>{{ team.name }}</b></h2>
  </div>
  <div class="datepicker">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'ADMIN_TEAMS_CHOOSE_DATE' | translate}}</mat-label>
      <input matInput [matDatepicker]="myTeamDatePicker" [min]="minDate" [max]="maxDate" [formControl]="date" (dateChange)="updateTeamCount($event)">
      <mat-datepicker-toggle matSuffix [for]="myTeamDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #myTeamDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
  >
      <mat-card class="cards"
      fxFlex="0 1 calc(33.3% - 30px)"
      fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%"
      >
        <p class="card-title">{{ 'MY_TEAM_MOST_STEPS' | translate }} ({{ date.value | date: 'dd.MM.yyyy' }})</p>
        <p class="card-number">
          {{
            dailyPerformaceArray.length > 0 ? (dailyPerformaceArray[0].stats.Steps | number: '1.0-0':'de') : 'NA'
          }}
        </p>
      </mat-card>
      <mat-card class="cards" fxFlex="0 1 calc(33.3% - 30px)" fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%">
        <p class="card-title">{{ 'MY_TEAM_TOTAL_STEPS' | translate }} ({{ date.value | date: 'dd.MM.yyyy' }})</p>
        <p class="card-number">
          {{
            teamTotalSteps > 0 ? (teamTotalSteps | number: '1.0-0':'de') : 'NA'
          }}
          <!-- {{ (team.totalSteps | number: '1.0-0' : 'de') }} -->
        </p>
      </mat-card>
      <mat-card class="cards" fxFlex="0 1 calc(33.3% - 30px)" fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%">
        <p class="card-title">{{ 'MY_TEAM_TOTAL_KILOMETERS' | translate }} ({{ date.value | date: 'dd.MM.yyyy' }})</p>
        <p class="card-number">
          {{
            teamTotalKilometers > 0
              ? (teamTotalKilometers | number: '1.0-0':'de') + ' Km.'
              : 'NA'
          }}
        </p>
      </mat-card>
      <mat-card class="cards" fxFlex="0 1 calc(33.3% - 30px)" fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%">
        <p class="card-title">{{ 'MY_TEAM_TOTAL_MEMBERS' | translate }}</p>
        <p class="card-number">
          {{ team.members.size }}
        </p>
      </mat-card>
      <mat-card class="cards" fxFlex="0 1 calc(33.3% - 30px)" fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%">
        <p class="card-title">{{ 'MY_TEAM_TOTAL_STEPS_ALL_TIME' | translate }}</p>
        <p class="card-number">
          {{ (team.totalSteps | number: '1.0-0' : 'de') }}
        </p>
      </mat-card>

      <mat-card class="cards" fxFlex="0 1 calc(33.3% - 30px)" fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%">
        <p class="card-title">{{ 'MY_TEAM_TOTAL_KILOMETERS_ALL_TIME' | translate }}</p>
        <p class="card-number">
          {{ (team.totalSteps / 1312 | number: '1.0-0' : 'de') + ' Km.' }}
          <!-- {{
            teamTotalKilometers > 0
              ? (teamTotalKilometers | number: '1.0-0':'de') + ' Km.'
              : 'NA'
          }} -->
        </p>
      </mat-card>

      <!-- <mat-card class="cards">
        <p class="card-title">{{ 'MY_TEAM_TABLE_POSITION' | translate }}</p>
        <p class="card-number">
          {{ teamPosition > 0 ? (teamPosition | number: '1.0-0':'de') : 'NA' }}
        </p>
      </mat-card> -->
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    *ngIf="dataSource.data.length > 0"
  >
    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-75">#</th>
      <td mat-cell *matCellDef="let teamMember">
        {{ teamMember.position | number: '1.0-0':'de' }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="nickname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'MY_TEAM_TABLE_NICKNAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let teamMember">{{ teamMember.nickname }}</td>
    </ng-container>

    <!-- Steps Column -->
    <ng-container *ngFor="let stat of dailyPerformanceStats; let i = index">
      <ng-container [matColumnDef]="'stat-' + i">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'MY_TEAM_TABLE_CUMULATED_STEPS' | translate }} {{ 'MY_TEAM_TABLE_TODAY' | translate}}
        </th>
        <td mat-cell *matCellDef="let teamMember">
          {{ teamMember.stats.Steps | number: '1.0-0':'de' }}
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ fewSteps: row.stats.Steps < 1500 }"
    ></tr>
  </table>
  <mat-card *ngIf="dataSource.data.length === 0">
    <p>No data available</p>
  </mat-card>
</div>
<div class="spinner-container" *ngIf="!user || !team">
  <mat-spinner></mat-spinner>
</div>
