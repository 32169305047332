import { Deserializable } from './deserializable.model';

export interface ITrainingDataInitializer {
  steps: number;
  nickname?: string;
  country?: string;
  avatar?: string;
  location?: string;
  locationIds?: Array<string>;
}

export class TrainingData implements Deserializable {
  public steps: number;
  public nickname: string;
  public country: string;
  public avatar: string;
  public location: string;
  public locationIds: Array<string>;

  constructor(initializer: ITrainingDataInitializer) {
    Object.assign(this, initializer);
    this.locationIds = initializer.locationIds
      ? initializer.locationIds
      : this.location
      ? [this.location]
      : [];
  }

  toObject(): TrainingData {
    return Object.assign({}, this);
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
