import { Helpers } from 'src/app/helpers/helpers';
import {
  Challenge,
  IChallengeInitializer,
} from '../../challenge/challenge.abstract';
import { DistanceChallenge } from '../../distance-challenge.model';
import { IRegionInitializer, Region } from '../region/region.model';
import {
  CitiesChallengePerformance,
  ICitiesChallengePerformance,
} from '../cities-challenge-performance/cities-challenge-performance.model';
import { CHALLENGE_TYPE_NAMES } from 'src/app/constants/constants';

export enum CitiesChallengeFeatures {
  DailySync = 'DAILY_SYNC',
}

export const CITIES_CHALLENGE_TYPES = [
  'CC_STANDARD',
  'CC_CUSTOMIZABLE',
  'CC_PREMIUM',
];

export interface ICitiesChallengeInitializer extends IChallengeInitializer {
  totalDistance: number;
  regions: Array<IRegionInitializer>;
  expectedDailySteps: number;
  totalPerformance: ICitiesChallengePerformance;
}

export class CitiesChallenge extends Challenge {
  public challengeType: string = CHALLENGE_TYPE_NAMES.CitiesChallenge;
  public totalDistance: number = null;
  public expectedDailySteps: number = null;
  public regions: Array<Region> = [];
  public totalPerformance: CitiesChallengePerformance = new CitiesChallengePerformance();

  constructor(
    iCitiesChallengeInitializer?:
      | ICitiesChallengeInitializer
      | DistanceChallenge
  ) {
    let initializer: ICitiesChallengeInitializer;
    if (
      iCitiesChallengeInitializer &&
      Helpers.isDistanceChallenge(iCitiesChallengeInitializer)
    ) {
      initializer = Helpers.transformDistanceChallengeInCitiesChallengeInitializer(
        iCitiesChallengeInitializer
      );
    } else {
      initializer = iCitiesChallengeInitializer as ICitiesChallengeInitializer;
    }

    super(initializer ? initializer : {});
    if (initializer) {
      const {
        totalDistance,
        expectedDailySteps,
        totalPerformance,
      } = initializer;

      if (initializer.regions?.length > 0) {
        this.regions = initializer.regions.map(
          (region: IRegionInitializer) => new Region(region)
        );
        this.calculateTotalDistance();
      }

      if (totalDistance !== undefined) {
        this.totalDistance = totalDistance;
      }

      if (expectedDailySteps !== undefined) {
        this.expectedDailySteps = expectedDailySteps;
      }

      if (totalPerformance) {
        this.totalPerformance = new CitiesChallengePerformance(
          totalPerformance
        );
      }
    }
  }

  public toObject(): ICitiesChallengeInitializer {
    const { totalDistance, expectedDailySteps, regions } = this;

    return {
      ...super.toObject(),
      totalDistance,
      expectedDailySteps,
      regions: regions.map((region) => region.toObject()),
      totalPerformance: this.totalPerformance
        ? this.totalPerformance.toObject()
        : null,
    };
  }

  public calculateTotalDistance(): void {
    this.totalDistance = this.regions.reduce(
      (accumulatedDistance, region) =>
        (accumulatedDistance += region.route?.totalDistance
          ? region.route?.totalDistance
          : 0),
      0
    );
  }

  public updateRegion(index: number, region: Region): void {
    if (index < this.regions.length && index >= 0) {
      if (region) {
        this.regions[index] = region;

        this.calculateTotalDistance();
      } else {
        throw new Error('region does not exist');
      }
    } else {
      throw new Error('incorrect index');
    }
  }

  public removeRegion(index: number): void {
    if (index < this.regions.length && index >= 0) {
      this.regions.splice(index, 1);
      this.calculateTotalDistance();
    } else {
      throw new Error('incorrect index');
    }
  }

  public addRegion(region: Region): void {
    if (region) {
      this.regions.push(region);
      this.calculateTotalDistance();
    } else {
      throw new Error('region does not exist');
    }
  }
}
