import { Deserializable } from '../deserializable.model'; // TODO: change for absolute path
import { ReportTypes } from './report-types.enum';

export interface IReportInitializer {
  name?: string;
  src?: string;
  shortLink?: string;
  dateGenerated?: number;
  type?: ReportTypes;
}

export class Report implements Deserializable {
  public name = '';
  public src = '';
  public shortLink = '';
  public dateGenerated = 0;
  public type = ReportTypes.Default;

  constructor(initializer?: IReportInitializer) {
    if (initializer) {
      Object.assign(this, initializer);
    }
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  toObject(): Report {
    return Object.assign({}, this);
  }
}
