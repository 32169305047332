import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { StatisticsType } from 'src/app/constants/constants';
import { CompanyTrainingData } from 'src/app/models/company-training-data/company-training-data.model';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private firebaseService: FirebaseService) {}

  public getTrainingsStatistics(
    companyId: string,
    basis: string,
    period: string
  ): Promise<Array<CompanyTrainingData>> {
    return this.firebaseService
      .getStatistics(companyId, StatisticsType.Trainings, basis, period)
      .then((docSnapshot) => {
        let trainingStatistics: Array<CompanyTrainingData> = [];
        if (docSnapshot.exists) {
          trainingStatistics = Object.entries(docSnapshot.data())
            .map((training) =>
              Object.assign(new CompanyTrainingData(training[1]), {
                id: training[0],
              })
            )
            .sort(
              (trainingA, trainingB) =>
                (trainingB as CompanyTrainingData).steps -
                (trainingA as CompanyTrainingData).steps
            );
        }
        return trainingStatistics;
      })
      .catch((error) => {
        console.log('getDailyTrainings - error: ', error);
        return [];
      });
  }
}
