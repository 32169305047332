import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Helpers } from 'src/app/helpers/helpers';
import { ITeamRanking } from 'src/app/models/team-challenge/interfaces/team-ranking.interface';
import { WeeklyHistory } from 'src/app/models/team-challenge/weekly-history/weekly-history.model';

export enum TeamRankingBy {
  ACCUMULATED_POINTS = 'points',
  AVERAGE_PERFORMANCE = 'average',
}

export const DISPLAY_COLUMNS = {
  BY_POINTS_WITH_LOCATION: [
    'rank',
    'teamAvatar',
    'teamName',
    'teamSize',
    'performance',
    'syncs',
    'teamLocation',
    'points',
  ],
  BY_POINTS_WITHOUT_LOCATION: [
    'rank',
    'teamAvatar',
    'teamName',
    'teamSize',
    'performance',
    'syncs',
    'points',
  ],
  BY_AVERAGE_WITH_LOCATION: [
    'rank',
    'teamAvatar',
    'teamName',
    'teamSize',
    'performance',
    'syncs',
    'teamLocation',
    'average',
  ],
  BY_AVERAGE_WITHOUT_LOCATION: [
    'rank',
    'teamAvatar',
    'teamName',
    'teamSize',
    'performance',
    'syncs',
    'average',
  ],
};

@Component({
  selector: 'app-team-ranking-table',
  templateUrl: './team-ranking-table.component.html',
  styleUrls: ['./team-ranking-table.component.scss'],
})
export class TeamRankingTableComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() teamRankings: Array<ITeamRanking> = [];
  @Input() rankingBy: TeamRankingBy = TeamRankingBy.ACCUMULATED_POINTS;
  @Input() weeklyHistories: Array<WeeklyHistory> = [];
  @Output() selectedWeek = new EventEmitter<WeeklyHistory>();

  public panelOpenState = false;
  public displayedColumns: Array<string>;
  public dataSource: MatTableDataSource<ITeamRanking> = new MatTableDataSource();
  public weekFormControl = new FormControl<WeeklyHistory>(new WeeklyHistory());

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.weekFormControl.setValue(this.weeklyHistories[0]);
  }

  ngOnChanges(): void {
    this.initTable();
  }

  ngAfterViewInit(): void {
    this.initSortAndPaginator();
  }

  public initTable(): void {
    this.setColumnsToDisplay();
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.teamRankings);

    // Redefine the filter method
    this.dataSource.filterPredicate = (
      data: ITeamRanking,
      filter: string
    ): boolean => {
      if (this.displayedColumns.includes('teamLocation')) {
        return (
          data.teamName.trim().toLowerCase().indexOf(filter) !== -1 ||
          data.teamLocation.trim().toLowerCase().indexOf(filter) !== -1
        );
      } else {
        return data.teamName.trim().toLowerCase().indexOf(filter) !== -1;
      }
    };

    this.initSortAndPaginator();
  }

  private initSortAndPaginator(): void {
    // Initialize sort and paginator
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public setDefaultImagePath(userAvatar: HTMLImageElement): void {
    Helpers.setDefaultImagePath(userAvatar);
  }

  private setColumnsToDisplay(): void {
    const hasLocation = this.teamRankings.some((teamRanking) =>
      Boolean(teamRanking.teamLocation)
    );
    if (this.rankingBy === TeamRankingBy.ACCUMULATED_POINTS) {
      if (hasLocation) {
        this.displayedColumns = DISPLAY_COLUMNS.BY_POINTS_WITH_LOCATION;
      } else {
        this.displayedColumns = DISPLAY_COLUMNS.BY_POINTS_WITHOUT_LOCATION;
      }
    } else {
      if (hasLocation) {
        this.displayedColumns = DISPLAY_COLUMNS.BY_AVERAGE_WITH_LOCATION;
      } else {
        this.displayedColumns = DISPLAY_COLUMNS.BY_AVERAGE_WITHOUT_LOCATION;
      }
    }
  }

  public selectWeeklyHistory(): void {
    this.selectedWeek.emit(this.weekFormControl.value);
  }
}
