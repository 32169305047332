import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

// Models
import { User } from 'src/app/models/user.model';
import { Roles } from 'src/app/models/roles.enum';
import { Company } from 'src/app/models/company.model';
import { Settings } from 'src/app/models/settings.model';
import { Gender } from 'src/app/models/gender.enum';
import { Features } from 'src/app/models/features.enum';
import { HealthProvider } from 'src/app/models/health-provider.enum';
import { Location } from 'src/app/models/location.model';

// Services
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SignupService } from 'src/app/services/signup/signup.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';
import {
  RANDOM_NICKNAMES,
  REGEX_ALPHANUMERIC,
  REGEX_EMAIL,
  REGEX_NAME,
} from 'src/app/constants/constants';

// Validators
import { SignupValidators } from 'src/app/validators/signup/signup.validator';

interface LevelLocationsDictionary {
  1: Location;
  2: Location;
  3: Location;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class SignupComponent implements OnInit, OnDestroy {
  public emailForm: UntypedFormGroup;
  public passwordForm: UntypedFormGroup;
  public nameForm: UntypedFormGroup;
  public nicknameForm: UntypedFormGroup;
  public birthdateForm: UntypedFormGroup;
  public optionalForm: UntypedFormGroup;
  public healthProviderForm: UntypedFormGroup;
  public companyCodeForm: UntypedFormGroup;
  public termsAndConditionsForm: UntypedFormGroup;
  public locationsForm: UntypedFormGroup;

  public isSmallScreen = false;
  public colSize = 2;
  public company: Company;
  public users: Array<User> = [];
  public account: User;

  public isPasswordVisible = false;
  public isPasswordCheckVisible = false;

  public SIGNUP_NOT_ADMIN = '';
  public SIGNUP_USER_CREATED = '';
  private SIGNUP_CHECK_EMAIL_ERROR = '';

  public minBirthdate: Date;
  public maxBirthdate: Date;

  public showUsedEmailError = false;
  public showUserSpinner = false;
  public showCompanySpinner = false;
  public errorMessage = '';

  public FEATURES_LOCATIONS = Features.Locations;
  public showUserCreationSpinner = false;
  public companyLocations: Array<Location> = [];
  public selectedLocations: LevelLocationsDictionary = {
    1: null,
    2: null,
    3: null,
  };

  private observer: ResizeObserver;
  private observedElement: Element;

  constructor(
    private settingsService: SettingsService,
    private formBuilder: UntypedFormBuilder,
    private signupService: SignupService,
    private snackBar: MatSnackBar,
    public translateService: TranslateService,
    private router: Router,
    private dateAdapter: DateAdapter<any>,
    private zone: NgZone
  ) {
    this.dateAdapter.setLocale('de');
    this.getTranslations();

    const regexNumeric: RegExp = /^\d+$/;

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 90);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 16);

    this.minBirthdate = minDate;
    this.maxBirthdate = maxDate;

    this.emailForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
        checkEmail: [
          '',
          [Validators.required, Validators.pattern(REGEX_EMAIL)],
        ],
      },
      {
        validators: [SignupValidators.MatchEmail],
      }
    );
    this.passwordForm = this.formBuilder.group(
      {
        password: [
          '',
          Validators.compose([Validators.minLength(6), Validators.required]),
        ],
        checkPassword: [
          '',
          Validators.compose([Validators.minLength(6), Validators.required]),
        ],
      },
      {
        validators: [SignupValidators.MatchPassword],
      }
    );
    this.nameForm = this.formBuilder.group({
      displayName: [
        '',
        Validators.compose([
          Validators.minLength(1),
          Validators.pattern(REGEX_NAME),
          Validators.required,
        ]),
      ],
    });
    this.nicknameForm = this.formBuilder.group({
      nickname: [
        '',
        Validators.compose([Validators.minLength(1), Validators.required]),
      ],
    });
    this.birthdateForm = this.formBuilder.group({
      birthdate: ['', [Validators.required]],
    });
    this.optionalForm = this.formBuilder.group({
      height: [
        '',
        Validators.compose([
          Validators.min(50),
          Validators.max(250),
          Validators.pattern(regexNumeric),
        ]),
      ],
      weight: [
        '',
        Validators.compose([
          Validators.min(30),
          Validators.max(250),
          Validators.pattern(regexNumeric),
        ]),
      ],
      gender: [''],
    });

    this.healthProviderForm = this.formBuilder.group({
      healthProvider: [this.settings ? this.settings.healthProvider : null],
    });

    this.companyCodeForm = this.formBuilder.group({
      codeType: ['company', Validators.compose([Validators.required])],
      companyCode: [
        '',
        Validators.compose([
          Validators.minLength(3),
          Validators.required,
          Validators.pattern(REGEX_ALPHANUMERIC),
        ]),
      ],
    });

    this.termsAndConditionsForm = this.formBuilder.group({
      shareData: [false, [Validators.requiredTrue]],
      termsOfService: [false, [Validators.requiredTrue]],
    });

    this.locationsForm = this.formBuilder.group({
      location1: [null, [Validators.required]],
      location2: [null],
      location3: [null],
    });

    this.locationsForm.controls.location1.valueChanges.subscribe((newValue) => {
      this.selectedLocations[1] = this.companyLocations.find(
        (location) => location.name === newValue
      );
      this.selectedLocations[2] = null;
      this.selectedLocations[3] = null;
    });

    this.locationsForm.controls.location2.valueChanges.subscribe((newValue) => {
      this.selectedLocations[2] = this.companyLocations.find(
        (location) => location.name === newValue
      );
      this.selectedLocations[3] = null;
    });

    this.locationsForm.controls.location3.valueChanges.subscribe((newValue) => {
      this.selectedLocations[3] = this.companyLocations.find(
        (location) => location.name === newValue
      );
    });
    this.chooseRandomNickname();
  }

  get companyCode(): AbstractControl {
    return this.companyCodeForm.get('companyCode');
  }
  get displayName(): AbstractControl {
    return this.nameForm.get('displayName');
  }
  get email(): AbstractControl {
    return this.emailForm.get('email');
  }
  get checkEmail(): AbstractControl {
    return this.emailForm.get('checkEmail');
  }
  get password(): AbstractControl {
    return this.passwordForm.get('password');
  }
  get confirmPassword(): AbstractControl {
    return this.passwordForm.get('checkPassword');
  }
  get nickname(): AbstractControl {
    return this.nicknameForm.get('nickname');
  }
  get height(): AbstractControl {
    return this.optionalForm.get('height');
  }
  get weight(): AbstractControl {
    return this.optionalForm.get('weight');
  }
  get genderEnum(): typeof Gender {
    return Gender;
  }
  get genderEnumKeys(): Array<string> {
    return Object.keys(Gender);
  }
  get healthProviderEnum(): typeof HealthProvider {
    return HealthProvider;
  }
  get healthProviderEnumKeys(): Array<string> {
    return Object.keys(HealthProvider);
  }
  get settings(): Settings {
    return this.settingsService.settings;
  }

  ngOnInit(): void {
    const width = window.outerWidth;
    if (width < 1400) {
      this.colSize = 1;
    } else {
      this.colSize = 2;
    }

    this.observedElement = document.querySelector('.container-card-signup');

    this.observer = new ResizeObserver((entries) => {
      let observerWidth = entries[0].contentRect.width;
      if (observerWidth === 0) {
        observerWidth = window.outerWidth;
      }
      if (observerWidth < 1400) {
        this.zone.run(() => {
          this.colSize = 1;
        });
      } else {
        this.zone.run(() => {
          this.colSize = 2;
        });
      }
    });

    this.observer.observe(this.observedElement);
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.observedElement);
  }

  public scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  public changeLanguage(lang: string): void {
    this.settingsService.changeLanguage(lang);
  }

  public getTranslations(): void {
    this.translateService
      .stream([
        'SIGNUP_NOT_ADMIN',
        'SIGNUP_USER_CREATED',
        'SIGNUP_CHECK_EMAIL_ERROR',
      ])
      .subscribe((values) => {
        this.SIGNUP_NOT_ADMIN = values.SIGNUP_NOT_ADMIN;
        this.SIGNUP_USER_CREATED = values.SIGNUP_USER_CREATED;
        this.SIGNUP_CHECK_EMAIL_ERROR = values.SIGNUP_CHECK_EMAIL_ERROR;
      });
  }

  public chooseRandomNickname(): void {
    const randomNickname =
      RANDOM_NICKNAMES[this.translateService.currentLang][
        Math.floor(
          Math.random() *
            RANDOM_NICKNAMES[this.translateService.currentLang].length
        )
      ];
    this.nicknameForm.controls.nickname.setValue(randomNickname);
  }

  public togglePasswordVisibility(input: string): void {
    if (input === 'password') {
      this.isPasswordVisible = !this.isPasswordVisible;
    }

    if (input === 'checkPassword') {
      this.isPasswordCheckVisible = !this.isPasswordCheckVisible;
    }
  }

  public async checkForUsedEmail(stepper: MatStepper): Promise<void> {
    this.showUsedEmailError = false;
    this.showUserSpinner = true;
    try {
      const emailUsed = await this.signupService.checkForUsedEmail(
        this.email.value
      );

      if (emailUsed) {
        this.showUsedEmailError = true;
      } else {
        stepper.next();
      }
    } catch (error) {
      this.snackBar.open(this.SIGNUP_CHECK_EMAIL_ERROR + error, 'Ok', {
        duration: 50000,
        panelClass: 'snack-bar-color',
      });
    } finally {
      this.showUserSpinner = false;
    }
  }

  public async getCompany(stepper: MatStepper): Promise<void> {
    this.showCompanySpinner = true;
    this.errorMessage = '';
    try {
      this.company = await this.signupService.validateCompanyCode(
        this.companyCode.value
      );
      if (this.company.features.has(Features.Locations)) {
        this.companyLocations = await this.signupService.getCompanyLocations(
          this.company.id
        );
      }
      stepper.next();
    } catch (error) {
      this.errorMessage = error;
    } finally {
      this.showCompanySpinner = false;
    }
  }

  public async signupUser(): Promise<void> {
    this.showUserCreationSpinner = true;

    const locationIds = Object.values(this.selectedLocations)
      .filter((element) => element !== null)
      .map((location) => location.name);

    try {
      const email: string = this.emailForm.value.email;
      if (this.company.adminEmail.includes(email.toLowerCase().trim())) {
        const settings = new Settings();
        const checkIsoTime = new Date().toISOString();
        settings.lang = this.translateService.currentLang;
        settings.notificationSettings.isNotSyncedNotificationActive = true;
        settings.notificationSettings.notSyncedNotificationTime = '19:00';
        settings.healthProvider = this.healthProviderForm.value.healthProvider;

        this.signupService.newAccount({
          email: email.toLowerCase().trim(),
          companyId: this.company.id,
          displayName: this.nameForm.value.displayName,
          role: Roles.Admin,
          nickname: this.nicknameForm.value.nickname,
          birthdate: Helpers.formatDateYYYYMMDD(
            this.birthdateForm.value.birthdate
          ),
          height: this.optionalForm.value.height,
          weight: this.optionalForm.value.weight,
          gender: this.optionalForm.value.gender,
          physicalCondition: this.termsAndConditionsForm.value.termsOfService,
          termsAndConditionsAcceptance: this.termsAndConditionsForm.value
            .termsOfService
            ? checkIsoTime
            : '',
          shareDataAcceptance: this.termsAndConditionsForm.value.shareData
            ? checkIsoTime
            : '',
          locationIds,
          settings,
          timezoneOffset: new Date().getTimezoneOffset(),
        });

        this.signupService.updatePassword(this.passwordForm.value.password);

        if (this.companyCodeForm.value.codeType !== 'company') {
          await this.signupService.setTeamOnUser(
            this.company.id,
            this.companyCodeForm.value.companyCode
          );
        }

        await this.signupService.createAccount();
        this.snackBar.open(this.SIGNUP_USER_CREATED, 'Ok', {
          duration: 10000,
          panelClass: 'snack-bar-color',
        });
        this.router.navigate(['login']);
      } else {
        this.snackBar.open(this.SIGNUP_NOT_ADMIN, 'Ok', {
          duration: 5000,
          panelClass: 'snack-bar-color',
        });
      }
    } catch (error) {
      console.log('error: ', error);
      this.snackBar.open(error.message, 'Ok', {
        duration: 5000,
        panelClass: 'snack-bar-color',
      });
    } finally {
      this.showUserCreationSpinner = false;
    }
  }
}
