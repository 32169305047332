import { Deserializable } from './deserializable.model';

export interface ICityMarkerPointInitializer {
  id?: string;
  name?: string;
  lat?: number;
  lng?: number;
  countryAlphaCode?: string;
  translations?: Map<string, string>;
  countryName?: string;
  link?: string;
}

export class CityMarkerPoint implements Deserializable {
  public id: string = null;
  public name: string = null;
  public lat: number = null;
  public lng: number = null;
  public countryAlphaCode: string = null;
  public translations: Map<string, string> = null;
  public countryName: string = null;
  public link: string = null;

  constructor(initializer?: ICityMarkerPointInitializer) {
    Object.assign(this, initializer);
    this.translations =
      initializer && initializer.translations
        ? initializer.translations instanceof Map
          ? initializer.translations
          : new Map(Object.entries(initializer.translations))
        : null;
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    if (!input.translations) {
      this.translations = new Map<string, string>();
    } else {
      if (input.translations instanceof Map) {
        this.translations = input.translations;
      } else {
        this.translations = new Map(Object.entries(input.translations));
      }
    }
    return this;
  }

  public toObject(): any {
    const translations = Object.fromEntries(this.translations);
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { translations });
  }
}
