import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';

export interface IForecastGraph {
  title: string;
  chartOptions: Highcharts.Options;
}
@Component({
  selector: 'app-forecast-graph',
  templateUrl: './forecast-graph.component.html',
  styleUrls: ['./forecast-graph.component.scss'],
})
export class ForecastGraphComponent implements OnChanges, OnDestroy {
  @Input() forecastGraph: IForecastGraph;

  public Highcharts = Highcharts;
  public chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'bottom',
      x: 0,
      y: 0,
      floating: false,
      borderWidth: 0,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    },
    xAxis: {
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.3,
      },
    },
  };

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forecastGraph) {
      if (!this.chartOptions.series) {
        this.prepareChartOptions();
      } else {
        this.updateChart();
      }
    }
  }

  ngOnDestroy(): void {
    Highcharts.charts.splice(0, Highcharts.charts.length);
  }

  private updateChart(): void {
    for (let i = 0; i < Highcharts.charts[0].series.length; i++) {
      Highcharts.charts[0].series[i].update(
        {
          ...this.forecastGraph.chartOptions.series[i],
        },
        false
      );
    }

    Highcharts.charts[0].redraw();
  }

  private prepareChartOptions(): void {
    Object.assign(this.chartOptions, this.forecastGraph.chartOptions);

    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
}
