import { Component, Input } from '@angular/core';
import { AssetsConstants } from 'src/app/models/assets-constants';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge.model';

@Component({
  selector: 'app-team-challenge-info',
  templateUrl: './team-challenge-info.component.html',
  styleUrls: ['./team-challenge-info.component.scss'],
})
export class TeamChallengeInfoComponent {
  @Input() teamChallenge: TeamChallengeV2;

  public GROUP_OF_PEOPLE_AND_LOGO = AssetsConstants.GroupOfPeopleAndLogo;

  constructor() {}
}
