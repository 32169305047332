import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from 'src/app/models/notification.model';

@Component({
  selector: 'app-sent-push-notifications-table',
  templateUrl: './sent-push-notifications-table.component.html',
  styleUrls: ['./sent-push-notifications-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class SentPushNotificationsTableComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() pushNotifications: Array<Notification>;
  @ViewChild(MatSort) sort: MatSort;

  public pushNotificationsDataSource: MatTableDataSource<Notification> = new MatTableDataSource();
  public columnsToDisplay: Array<string> = [
    'title',
    'id',
    'recipientCompanies',
  ];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public expandedNotification: Notification | null = null;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.pushNotificationsDataSource.sort = this.sort;
    this.pushNotificationsDataSource.sortingDataAccessor = this.notificationsDataAccessor;
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pushNotifications) {
      this.pushNotificationsDataSource = new MatTableDataSource(
        this.pushNotifications
      );
    }
  }

  public showRecipientCompanies(companies: Set<string>): string {
    let recipientCompanies = '';
    let extraCompanies = 0;
    if (companies) {
      companies.forEach((company) => {
        if (recipientCompanies.length === 0) {
          recipientCompanies += company;
        } else {
          extraCompanies++;
        }
      });
      if (extraCompanies > 0) {
        recipientCompanies += this.translateService.instant(
          'SENT_PUSH_NOTIFICATIONS_TABLE.EXTRA_COMPANIES',
          {
            extraCompanies,
          }
        );
      }
    }
    return recipientCompanies;
  }

  public showAllRecipientCompanies(companies: Set<string>): string {
    let recipientCompanies = '';
    if (companies) {
      companies.forEach((company) => {
        if (recipientCompanies.length === 0) {
          recipientCompanies += `${company}`;
        } else {
          recipientCompanies += ` - ${company}`;
        }
      });
    }
    return recipientCompanies;
  }

  /**
   * Data accessor function that is used for accessing data properties for sorting through
   * the default sortData function.
   * This default function assumes that the sort header IDs (which defaults to the column name)
   * matches the data's properties (e.g. column Xyz represents data['Xyz']).
   * May be set to a custom function for different behavior.
   * @param data Data object that is being accessed.
   * @param sortHeaderId The name of the column that represents the data.
   */
  public notificationsDataAccessor: (
    data: Notification,
    sortHeaderId: string
  ) => string | number = (
    data: Notification,
    sortHeaderId: string
  ): string | number => {
    switch (sortHeaderId) {
      case 'title':
        return data.title.get(this.currentLang);
      case 'id':
        return data.id;
      case 'recipientCompanies':
        return data.recipientCompanyIds.size;
      default:
        return data[sortHeaderId] || '';
    }
  };
}
