import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { GroupChallengesTypes } from 'src/app/models/group-challenges-types.enum';
import { TeamChallengeSchema } from 'src/app/models/team-challenge-schema/team-challenge-schema';
import { TeamChallengeTypes } from 'src/app/models/team-challenge-types.enum';
import { TeamSchema } from 'src/app/models/team-schema.model';
import { AdminService } from 'src/app/services/admin/admin.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-new-team-challenge-dialog',
  templateUrl: './new-team-challenge-dialog.component.html',
  styleUrls: ['./new-team-challenge-dialog.component.scss'],
})
export class NewTeamChallengeDialogComponent implements OnInit {
  public GROUP_CHALLENGE_TYPES_TEAM_CHALLENGE =
    GroupChallengesTypes.TeamChallenge;

  public teamChallengeForm: UntypedFormGroup;
  public teamsDataForm: UntypedFormGroup;
  public teamChallengePointsDataForm: UntypedFormGroup;

  public teamChallengeTypesNames: Array<string> = Object.keys(
    TeamChallengeTypes
  )
    .map((key) => TeamChallengeTypes[key])
    .filter((value) => typeof value === 'string') as string[];

  public allTeams: Array<TeamSchema> = [];
  public selectableTeams: Array<TeamSchema> = [];
  public challengeTeams: Array<TeamSchema> = [];
  public userIds: Array<string> = [];
  public teamsDataSource = new MatTableDataSource(this.challengeTeams);
  public teamsColumns: string[] = ['avatar', 'name', 'delete'];

  public pointsAwarded = [];
  public pointsAwardedDataSource = new MatTableDataSource();
  public pointsAwardedColumns: Array<string> = ['position', 'points', 'delete'];

  public POSITION_ALREADY_PRESENT = '';

  public showSpinner = false;

  public positiveIntegerRegex: RegExp = /^\d{1,2}$/;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewTeamChallengeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TeamChallengeSchema
  ) {
    if (data) {
      this.teamChallengeForm = this.formBuilder.group({
        challengeId: [data.id, Validators.required],
        name: [data.name, Validators.required],
        participantsPerTeam: [data.participantsPerTeam, Validators.required],
        type: [data.type, Validators.required],
      });
      this.challengeTeams = this.adminService.teams.filter((team) =>
        data.teamIds.has(team.id)
      );
      this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
      this.pointsAwarded = Array.from(
        data.pointsAwarded,
        ([position, points]) => ({ position, points })
      );
      this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
    } else {
      this.teamChallengeForm = this.formBuilder.group({
        challengeId: ['', Validators.required],
        name: ['', Validators.required],
        participantsPerTeam: [10, Validators.required],
        type: ['0', Validators.required],
      });
    }

    this.teamsDataForm = this.formBuilder.group({
      teamId: ['', Validators.required],
    });

    this.teamChallengePointsDataForm = this.formBuilder.group({
      position: [
        1,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern(this.positiveIntegerRegex),
        ],
      ],
      points: [3, [Validators.required, Validators.min(1)]],
    });

    this.teamChallengePointsDataForm.controls.position.valueChanges.subscribe(
      (value) => {
        if (
          this.pointsAwarded.findIndex(
            (pointsAward) => pointsAward.position === value
          ) > -1
        ) {
          this.teamChallengePointsDataForm.controls.position.setErrors({
            notUnique: true,
          });
        } else {
          if (
            this.teamChallengePointsDataForm.controls.position.hasError(
              'notUnique'
            )
          ) {
            delete this.teamChallengePointsDataForm.controls.position.errors
              .notUnique;
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.allTeams = [...this.adminService.teams];
    this.selectableTeams = [...this.adminService.teams].filter(
      (team) =>
        this.challengeTeams.findIndex(
          (challengeTeam) => challengeTeam.id === team.id
        ) === -1
    );
  }

  public addTeam(): void {
    this.challengeTeams.push(
      ...this.allTeams.filter(
        (team) => team.id === this.teamsDataForm.controls.teamId.value
      )
    );

    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);

    this.selectableTeams.splice(
      this.selectableTeams.findIndex(
        (team) => team.id === this.teamsDataForm.controls.teamId.value
      ),
      1
    );
    this.teamsDataForm.controls.teamId.setValue('');
  }

  public removeTeam(teamId: string): void {
    this.challengeTeams.splice(
      this.challengeTeams.findIndex((team) => team.id === teamId),
      1
    );
    this.selectableTeams.push(this.allTeams.find((team) => team.id === teamId));
    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
  }

  public addPointsAwarded(): void {
    // TODO: prevent adding more points for one position
    this.pointsAwarded.push({
      position: this.teamChallengePointsDataForm.controls.position.value,
      points: this.teamChallengePointsDataForm.controls.points.value,
    });
    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
    this.teamChallengePointsDataForm.controls.position.setValue(
      this.teamChallengePointsDataForm.controls.position.value + 1
    );
    this.teamChallengePointsDataForm.controls.points.setValue(
      this.teamChallengePointsDataForm.controls.points.value - 1
    );
  }

  public removePointsAward(pointsAward): void {
    this.pointsAwarded.splice(
      this.pointsAwarded.findIndex(
        (element) => element.position === pointsAward.position
      ),
      1
    );
    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async confirm(): Promise<void> {
    this.showSpinner = true;
    if (this.teamChallengeForm.valid) {
      const challengeId = this.teamChallengeForm.controls.challengeId.value;

      const pointsAwarded = {};
      this.pointsAwarded.forEach((pointsAward) => {
        pointsAwarded[pointsAward.position] = pointsAward.points;
      });

      const teamIds = this.challengeTeams.map((team) => team.id);
      const userIds = this.userIds;

      const teamChallenge = new TeamChallengeSchema({
        id: challengeId,
        name: this.teamChallengeForm.controls.name.value,
        type: this.teamChallengeForm.controls.type.value,
        participantsPerTeam: this.teamChallengeForm.controls.participantsPerTeam
          .value,
        pointsAwarded,
        teamIds,
        userIds,
      });

      this.firebaseService
        .createDefaultTeamChallenge(teamChallenge)
        .then(() => {
          this.snackBar.open('Team Challenge Created', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          this.dialogRef.close();
        })
        .catch((error) => {
          // TODO: show error toast
          console.log('error: ', error);
          this.snackBar.open(
            'error on addTeamChallenge, while creating the challenge: ' + error,
            'Ok',
            {
              duration: 4000,
              panelClass: 'snack-bar-color',
            }
          );
        })
        .finally(() => {
          this.showSpinner = false;
        });
    }
  }
}
