import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { MatAccordion } from '@angular/material/expansion';

import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { Report } from 'src/app/models/reports/report.model';

// Constants
import { isReportChallenge } from 'src/app/models/reports/report-challenge.model';

interface IReportsByChallenge {
  [challengeId: string]: Array<Report>;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  public reportsByChallenge: IReportsByChallenge = {};
  public showSpinnerReports = true;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    private firebaseService: FirebaseService,
    private companyService: CompanyService,
    private userService: UserService
  ) {}

  get company(): Company {
    return this.companyService.company;
  }

  get user(): User {
    return this.userService.user;
  }

  ngOnInit(): void {
    this.companyService.updateCompanyData();
    this.updateReportLists().then(() => (this.showSpinnerReports = false));
  }

  public downloadReport(url: string): void {
    window.open(url);
  }

  private async updateReportLists(): Promise<void> {
    this.firebaseService
      .getReports(this.company.id)
      .then((reportsQuery) => {
        if (!reportsQuery.empty) {
          reportsQuery.docs.map((reportSnapshot) => {
            const report = new Report(reportSnapshot.data());
            if (isReportChallenge(report)) {
              this.reportsByChallenge[report.challengeId] = this
                .reportsByChallenge[report.challengeId]
                ? [...this.reportsByChallenge[report.challengeId], report]
                : [report];
            }
            // TODO: implement else when reports that do not depend on challenges exist
            Object.values(this.reportsByChallenge).map((reports) =>
              reports.sort(
                (reportA, reportB) =>
                  reportB.dateGenerated - reportA.dateGenerated
              )
            );
          });
        }
      })
      .catch((error) => {
        console.log('getReports error: ', error);
      });
  }
}
