import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  Form,
} from '@angular/forms';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatChipInputEvent } from '@angular/material/chips';

import { MatTableDataSource } from '@angular/material/table';

// Models
import { Company } from 'src/app/models/company.model';
import { LicenceTypes } from 'src/app/models/licence-types.enum';
import { Features } from 'src/app/models/features.enum';
import { Location } from 'src/app/models/location.model';
import { Locations } from 'src/app/models/locations.model';
import { WhiteLabelDetails } from 'src/app/models/white-label-details.model';
import { WhiteLabel } from 'src/app/models/white-label.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AdminTabs } from 'src/app/pages/admin/admin-tabs.enum';
import { MatDialog } from '@angular/material/dialog';
import { LinkLocationsDialogComponent } from 'src/app/shared/components/link-locations-dialog/link-locations-dialog.component';

interface DataFormDictionary {
  1: UntypedFormGroup;
  2: UntypedFormGroup;
  3: UntypedFormGroup;
}

interface LevelLocationsDictionary {
  1: Array<Location>;
  2: Array<Location>;
  3: Array<Location>;
}

interface DataSourcesDictionary {
  1: MatTableDataSource<Location>;
  2: MatTableDataSource<Location>;
  3: MatTableDataSource<Location>;
}

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
})
export class AddCompanyComponent implements OnInit {
  @ViewChild('chipList') chipList;

  public companyDataForm: UntypedFormGroup;
  public locationDataForm: UntypedFormGroup;
  public whiteLabelDataForm: UntypedFormGroup;

  public showSpinner = false;
  public licences: Array<string> = [];
  public adminEmails: Array<string> = [];

  public personalChallengeFormCompleted = false;
  public teamChallengeFormCompleted = false;
  public distanceChallengeFormCompleted = false;

  public levelsDataForms: DataFormDictionary = {
    1: null,
    2: null,
    3: null,
  };

  public levelsDataSources: DataSourcesDictionary = {
    1: null,
    2: null,
    3: null,
  };

  public levelsLocations: LevelLocationsDictionary = {
    1: [],
    2: [],
    3: [],
  };

  public locationColumns = ['id', 'name', 'edit', 'links', 'delete'];

  public isEditActive = false;
  public editIndex = -1;

  public ADD_COMPANY_CREATED_COMPANY = '';
  public ADD_COMPANY_WHITE_LABEL_BUTTON_COLOR = '';
  public ADD_COMPANY_AVATAR_WHITE_LABEL = '';

  // Chip list variables
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  public whiteLabelSelected: WhiteLabel = null;

  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private adminService: AdminService,
    public dialog: MatDialog
  ) {
    this.getTranslations();
    this.companyDataForm = this.formBuilder.group({
      adminEmail: ['', [Validators.email]],
      availableLicenses: ['', Validators.required],
      avatar: ['assets/img/move-me-new.png'],
      avatarImg: [undefined, Validators.required],
      companyId: ['', Validators.required],
      name: ['', Validators.required],
      featureTeamChallenge: [false],
      featureDistanceChallenge: [false],
      // featureMarketplace: [false],
      featureWhiteLabel: [false],
      featureLocations: [false],
    });

    this.whiteLabelDataForm = this.formBuilder.group({
      color: ['#f97a1c', Validators.required],
      whiteLabelLogo: [''],
      whiteLabelButtonColor: [''],
      whiteLabelButtonText: [''],
      whiteLabelButtonLink: [''],
    });

    this.locationDataForm = this.formBuilder.group({
      levels: [0, Validators.compose([Validators.min(1), Validators.max(3)])],
    });

    for (const level of [1, 2, 3]) {
      this.levelsDataForms[level] = this.formBuilder.group({
        titleEN: [null, Validators.required],
        titleDE: [null, Validators.required],
        selectorEN: [null, Validators.required],
        selectorDE: [null, Validators.required],
        name: [null, Validators.required],
        id: [{ value: null, disabled: true }, Validators.required],
      });
    }

    this.locationDataForm = this.formBuilder.group({
      levels: [0, Validators.compose([Validators.min(1), Validators.max(3)])],
    });
  }

  get adminEmail(): AbstractControl {
    return this.companyDataForm.get('adminEmail');
  }

  get whiteLabels(): Array<WhiteLabel> {
    return this.adminService.whiteLabels;
  }

  ngOnInit(): void {
    for (const level of [1, 2, 3]) {
      this.levelsDataForms[level].controls.name.valueChanges.subscribe(
        (newValue) => {
          if (newValue) {
            this.levelsDataForms[level].controls.id.setValue(
              newValue.toUpperCase().replace(/\//, '')
            );
          }
        }
      );
    }
  }

  public setCompleted(event: boolean, form: string): void {
    switch (form) {
      case 'PERSONAL':
        this.personalChallengeFormCompleted = event;
        break;
      case 'TEAM_CHALLENGE':
        this.teamChallengeFormCompleted = event;
        break;
      case 'DISTANCE_CHALLENGE':
        this.distanceChallengeFormCompleted = event;
    }
    console.log('event', event);
  }

  public addLocationToLevel(level: number): void {
    const dataForm = this.levelsDataForms[level];

    const location = new Location({
      id: dataForm.controls.id.value,
      name: dataForm.controls.name.value,
      hierarchyLevel: level,
    });

    const allLocations = [].concat(...Object.values(this.levelsLocations));
    const sameNameIndex = allLocations.findIndex(
      (element) => element.name === location.name
    );

    if (sameNameIndex === -1) {
      if (!this.isEditActive) {
        this.levelsLocations[level].push(location);
      } else {
        this.levelsLocations[level].splice(this.editIndex, 1, location);
        this.isEditActive = false;
      }
      dataForm.controls.id.setValue(null);
      dataForm.controls.id.markAsUntouched();
      dataForm.controls.name.setValue(null);
      dataForm.controls.name.markAsUntouched();
      this.levelsDataSources[level] = new MatTableDataSource(
        this.levelsLocations[level]
      );
    } else {
      this.snackBar.open(
        'Location already present, please select another name for the location',
        'Ok',
        {
          duration: 4000,
          panelClass: 'snack-bar-color',
        }
      );
    }
  }

  public editLocation(locationId: string, level: number): void {
    this.isEditActive = true;
    const editElement = this.levelsLocations[level].find(
      (element) => element.id === locationId
    );
    const editIndex = this.levelsLocations[level].findIndex(
      (element) => element.id === locationId
    );
    this.levelsDataForms[level].controls.id.setValue(editElement.id);
    this.levelsDataForms[level].controls.name.setValue(editElement.name);
    this.editIndex = editIndex;
  }

  public removeLocation(locationId: string, level: number): void {
    const removeIndex = this.levelsLocations[level].findIndex(
      (element) => element.id === locationId
    );
    this.levelsLocations[level].splice(removeIndex, 1);
    this.levelsDataSources[level] = new MatTableDataSource(
      this.levelsLocations[level]
    );
  }

  public cancelEdit(level: number): void {
    this.isEditActive = false;
    this.levelsDataForms[level].controls.id.setValue(null);
    this.levelsDataForms[level].controls.id.markAsUntouched();
    this.levelsDataForms[level].controls.name.setValue(null);
    this.levelsDataForms[level].controls.name.markAsUntouched();
  }

  public async addCompany(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (this.companyDataForm.valid) {
      const companyId = this.companyDataForm.controls.companyId.value;

      if (this.companyDataForm.controls.avatarImg.value) {
        const avatarFile = this.companyDataForm.controls.avatarImg.value
          .files[0];

        await this.firebaseService
          .uploadImage(avatarFile, 'companies/' + companyId + '/avatar')
          .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            this.companyDataForm.controls.avatar.setValue(url);
          });
      }

      const features = new Set<Features>();
      const locationsInfoArray = [];
      const locationsArray = [];
      let whiteLabel = null;

      if (this.companyDataForm.controls.featureWhiteLabel.value) {
        features.add(Features.WhiteLabel);
        whiteLabel = new WhiteLabelDetails({
          id: this.whiteLabelSelected.id,
          logo: this.companyDataForm.controls.whiteLabelLogo.value,
          buttonColor: this.companyDataForm.controls.whiteLabelButtonColor
            .value,
          buttonText: this.companyDataForm.controls.whiteLabelButtonText.value,
          buttonLink: this.companyDataForm.controls.whiteLabelButtonLink.value,
          color: this.companyDataForm.controls.color.value,
        });
      }

      if (this.companyDataForm.controls.featureTeamChallenge.value) {
        features.add(Features.TeamChallenge);
      }

      // if (this.companyDataForm.controls.featureMarketplace.value) {
      //   features.add(Features.Marketplace);
      // }

      if (this.companyDataForm.controls.featureLocations.value) {
        features.add(Features.Locations);

        for (
          let levelIndex = 1;
          levelIndex <= this.locationDataForm.value.levels;
          levelIndex++
        ) {
          const locationsInfo = this.mapFormToLocations(
            this.levelsDataForms[levelIndex],
            this.levelsLocations[levelIndex]
          );
          locationsInfoArray.push(locationsInfo);
        }

        for (
          let levelIndex = 1;
          levelIndex <= this.locationDataForm.value.levels;
          levelIndex++
        ) {
          for (const location of this.levelsLocations[levelIndex]) {
            if (
              location.childLocations.optionNames.length > 0 &&
              locationsInfoArray[levelIndex]
            ) {
              location.childLocations.title =
                locationsInfoArray[levelIndex].title;
              location.childLocations.selector =
                locationsInfoArray[levelIndex].selector;
            } else {
              location.childLocations = null;
            }
            locationsArray.push(location);
          }
        }
      }

      const company = new Company({
        avatar: this.companyDataForm.controls.avatar.value,
        id: this.companyDataForm.controls.companyId.value,
        name: this.companyDataForm.controls.name.value,
        color: this.companyDataForm.controls.color.value,
        users: [],
        completedChallenges: [],
        availableLicenses: this.companyDataForm.controls.availableLicenses
          .value,
        licence: LicenceTypes.Complete,
        teamIds: [],
        currentTeamChallenges: [],
        features: Array.from(features),
        adminEmail: this.adminEmails,
        whiteLabel,
        locationsInfo:
          locationsInfoArray.length > 0
            ? locationsInfoArray[0].toObject()
            : null,
        locationLevels: this.locationDataForm.value.levels,
      });

      this.firebaseService.createCompany(
        this.companyDataForm.controls.companyId.value,
        company
      );

      if (company.features.has(Features.Locations)) {
        this.firebaseService.createLocations(company.id, locationsArray);
      }

      this.showSpinner = false;
      this.snackBar.open(this.ADD_COMPANY_CREATED_COMPANY, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
      this.router.navigate(['admin'], { state: { tab: AdminTabs.Companies } });
    }
  }

  public returnPage(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Companies } });
  }

  public getTranslations(): void {
    this.translateService
      .stream([
        'ADD_COMPANY_CREATED_COMPANY',
        'ADD_COMPANY_WHITE_LABEL_BUTTON_COLOR',
        'ADD_COMPANY_AVATAR_WHITE_LABEL',
      ])
      .subscribe((values) => {
        this.ADD_COMPANY_CREATED_COMPANY = values.ADD_COMPANY_CREATED_COMPANY;
        this.ADD_COMPANY_WHITE_LABEL_BUTTON_COLOR =
          values.ADD_COMPANY_WHITE_LABEL_BUTTON_COLOR;
        this.ADD_COMPANY_AVATAR_WHITE_LABEL =
          values.ADD_COMPANY_AVATAR_WHITE_LABEL;
      });
  }

  public add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const input = event.input;
    const isValid = this.adminEmail.valid;

    // Add admin email
    if (value && isValid) {
      const email: string = event.value;
      this.adminEmails.push(email.toLowerCase().trim());
      this.adminEmails.slice(1);
      if (input) {
        input.value = '';
      }
    }

    if (this.adminEmail.invalid && this.adminEmail.dirty) {
      this.chipList.errorState = true;
    } else {
      this.chipList.errorState = false;
    }
  }

  public remove(email: string): void {
    const index = this.adminEmails.indexOf(email);

    if (index >= 0) {
      this.adminEmails.splice(index, 1);
    }

    if (index === 0) {
      this.adminEmail.setValue('');
    }
  }

  public updateWhiteLabelValues(): void {
    this.companyDataForm.controls.whiteLabelLogo.setValue(
      this.whiteLabelSelected.logo
    );
    this.companyDataForm.controls.color.setValue(this.whiteLabelSelected.color);

    if (this.whiteLabelSelected.button) {
      this.companyDataForm.controls.whiteLabelButtonColor.setValue(
        this.whiteLabelSelected.button.color
      );
      this.companyDataForm.controls.whiteLabelButtonText.setValue(
        this.whiteLabelSelected.button.text
      );
      this.companyDataForm.controls.whiteLabelButtonLink.setValue(
        this.whiteLabelSelected.button.link
      );
    } else {
      this.companyDataForm.controls.whiteLabelButtonColor.setValue(null);
      this.companyDataForm.controls.whiteLabelButtonText.setValue(null);
      this.companyDataForm.controls.whiteLabelButtonLink.setValue(null);
    }
  }

  public onChangeCompanyColor(color: string): void {
    this.companyDataForm.patchValue({ color });
  }

  public onChangeWhiteLabelButtonColor(whiteLabelButtonColor: string): void {
    this.companyDataForm.patchValue({ whiteLabelButtonColor });
  }

  public linkLocation(location: Location, level: number): void {
    const locationList = this.levelsLocations[level + 1];

    if (level !== this.locationDataForm.value.levels) {
      this.dialog.open(LinkLocationsDialogComponent, {
        width: '400px',
        data: {
          location,
          locationList,
        },
      });
    }
  }

  private mapFormToLocations(
    locationDataForm: UntypedFormGroup,
    locationsOptions: Array<Location>
  ): Locations {
    const title = new Map<string, string>();
    title.set('en', locationDataForm.value.titleEN);
    title.set('de', locationDataForm.value.titleDE);

    const selector = new Map<string, string>();
    selector.set('en', locationDataForm.value.selectorEN);
    selector.set('de', locationDataForm.value.selectorDE);

    const optionNames = locationsOptions.map((location) => location.name);

    return new Locations({
      title,
      selector,
      optionNames,
    });
  }
}
