<div class="container" fxLayout.xs="column space-between" fxLayout.sm="column space-between">
  <div *ngIf="!showLoadingRouteSpinner">
    <div class="top">
      <mat-icon (click)="returnToAdminRoutes()">keyboard_backspace</mat-icon>
      <h1 class="title">{{ (routeId ? 'EDIT_ROUTE.TITLE.EDIT_ROUTE' : 'EDIT_ROUTE.TITLE.NEW_ROUTE') | translate }}</h1>
    </div>
    <app-route-form [routeDataForm]="routeDataForm" [editable]="true"></app-route-form>
    <div class="buttons">
      <button mat-raised-button [disabled]="!routeDataForm.valid && cityMarkerPoints.length > 0 || showUpdatingRouteSpinner"
        (click)="updateRoute()">
        {{ (routeId ? 'EDIT_ROUTE.BUTTON.UPDATE_ROUTE' : 'EDIT_ROUTE.BUTTON.CREATE_ROUTE') | translate }}
      </button>
    </div>
    <div class="updating-route-spinner-container" *ngIf="showUpdatingRouteSpinner">
      <p>{{ (routeId ? 'EDIT_ROUTE.SPINNER.UPDATING_ROUTE' : 'EDIT_ROUTE.SPINNER.CREATING_ROUTE') | translate }}</p>
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="spinner-container" *ngIf="showLoadingRouteSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
