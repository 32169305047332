<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'ADD_PERSONAL_CHALLENGE_TITLE' | translate }}</h1>
  </div>

  <form [formGroup]="personalChallengeForm">
    <mat-form-field>
      <mat-label>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.schema.LABEL' | translate }}</mat-label>
      <mat-select formControlName="schema">
        <mat-option *ngFor="let personalChallengeSchema of personalChallengeSchemas" [value]="personalChallengeSchema">
          {{ personalChallengeSchema.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.name.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.name.REQUIRED_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="personalChallengeForm.controls.hasFixedDate.value">
      <mat-label>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.personalChallengeDateRange.LABEL' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="personalChallengeDateRangePicker" [formGroup]="personalChallengeDateRange" [min]="minDate" disabled (click)="personalChallengeDateRangePicker.open()">
        <input
          matStartDate
          placeholder="{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.personalChallengeDateRange.START_LABEL' | translate }}"
          formControlName="start"
        />
        <input
          matEndDate
          placeholder="{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.personalChallengeDateRange.END_LABEL' | translate }}"
          formControlName="end"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="personalChallengeDateRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #personalChallengeDateRangePicker [disabled]="!personalChallengeForm.controls.hasFixedDate.value"></mat-date-range-picker>
    </mat-form-field>
  </form>
  <div class="points-sources" *ngIf="personalChallengeForm.controls.schema.value">
    <h2 class="subtitles">{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TITLE' | translate }}</h2>
    <table
      mat-table
      [dataSource]="pointsSourceDataSource"
      matSort
      *ngIf="pointsSourceIds.length !== 0"
    >

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.ID' | translate }}</th>
        <td mat-cell *matCellDef="let pointsSource">
          {{ pointsSource.id }}
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.DESCRIPTION' | translate }}</th>
        <td mat-cell *matCellDef="let pointsSource">
          {{ pointsSource.description }}
        </td>
      </ng-container>

      <!-- Basis Column -->
      <ng-container matColumnDef="basis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.BASIS' | translate }}</th>
        <td mat-cell *matCellDef="let pointsSource">
          {{ pointsSourceBasisNames[pointsSource.basis] }}
        </td>
      </ng-container>

      <!-- Expected Performance Column -->
      <ng-container matColumnDef="expectedPerformance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.EXPECTED_PERFORMANCE' | translate }}
        </th>
        <td mat-cell *matCellDef="let pointsSource">
          {{ pointsSource.expectedPerformance }}
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.TYPE' | translate }}</th>
        <td mat-cell *matCellDef="let pointsSource">
          {{ pointsSourceTypesNames[pointsSource.type] }}
        </td>
      </ng-container>

      <!-- Points Awarded Column -->
      <ng-container matColumnDef="pointsAwarded">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.POINTS_AWARDED' | translate }}
        </th>
        <td mat-cell *matCellDef="let pointsSource">
          {{ pointsSource.pointsAwarded }}
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let pointsSource"
          (click)="removePointsSource(pointsSource.id)"
        >
          <mat-icon>delete_outline</mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="pointsSourceColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: pointsSourceColumns"></tr>
    </table>
    <div *ngIf="pointsSourceIds.length === 0">
      <span>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.EMPTY_TABLE' | translate }}</span>
    </div>
  </div>

  <div class="buttons">
    <button
      mat-raised-button
      [disabled]="!personalChallengeForm.valid || pointsSourceIds.length === 0 || (personalChallengeForm.controls.hasFixedDate.value && !personalChallengeDateRange.controls.end.value)"
      (click)="addPersonalChallenge()"
    >
      {{ 'ADD_PERSONAL_CHALLENGE_TITLE' | translate }}
    </button>
  </div>
  <div class="spinner-container" *ngIf="showSpinner">
    <p>{{ 'ADD_PERSONAL_CHALLENGE_SPINNER' | translate }}</p>
    <mat-spinner></mat-spinner>
  </div>
</div>
