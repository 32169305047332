<mat-card class="badge-card-container">
  <mat-card-header>
    <mat-card-title>
      {{ infoBadgeCard.title | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content #badgeCardContent>
    <div class="badge-item">
      <mat-icon class="badge-icon" [svgIcon]="infoBadgeCard.iconName"></mat-icon>
      <div class="user-info">
        <p> {{ infoBadgeCard.userNickname }} </p>
        <p class="no-winner"> {{ infoBadgeCard.message | translate }} </p>
        <p class="performance"> {{ infoBadgeCard.userPerformance | number: '1.0-0':'de'}} </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>