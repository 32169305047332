import { TeamChallengeTypes } from './team-challenge-types.enum';

export interface ITeamChallengeInitializer {
  id?: string;
  name?: string;
  startDate?: number;
  endDate?: number;
  duration?: number;
  currentWeek?: number;
  participantsPerTeam?: number;
  type?: TeamChallengeTypes;
  pointsAwarded?: any;
  teamIds?: Set<string>;
  userIds?: Set<string>;
  teamCaptainWithEmailSent?: Set<string>;
  projectPlanSent?: boolean;
}
export class TeamChallenge {
  id: string;
  name: string;
  startDate: number;
  endDate: number;
  duration: number; // in weeks
  currentWeek: number;
  participantsPerTeam: number;
  type: TeamChallengeTypes;
  pointsAwarded: Map<string, number> = new Map<string, number>();
  teamIds: Set<string> = new Set<string>();
  userIds: Set<string> = new Set<string>();
  teamCaptainWithEmailSent: Set<string> = new Set<string>();
  public projectPlanSent: boolean = false;

  constructor(initializer?: ITeamChallengeInitializer) {
    Object.assign(this, initializer);
    if (initializer && initializer.pointsAwarded) {
      this.pointsAwarded = new Map(Object.entries(initializer.pointsAwarded));
    }
    if (initializer && initializer.teamIds) {
      this.teamIds = new Set(initializer.teamIds);
    }
    if (initializer && initializer.userIds) {
      this.userIds = new Set(initializer.userIds);
    }
    if (initializer && initializer.teamCaptainWithEmailSent) {
      this.teamCaptainWithEmailSent = new Set(
        initializer.teamCaptainWithEmailSent
      );
    }
  }

  public toObject(): object {
    const pointsAwarded = Object.fromEntries(this.pointsAwarded);
    const teamIds = Array.from(this.teamIds);
    const userIds = Array.from(this.userIds);
    const teamCaptainWithEmailSent = Array.from(this.teamCaptainWithEmailSent);
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, {
      pointsAwarded,
      teamIds,
      userIds,
      teamCaptainWithEmailSent,
    });
  }
}
