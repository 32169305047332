import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, map, startWith } from 'rxjs';

// Models
import { LicenceTypes } from 'src/app/models/licence-types.enum';
import { Team } from 'src/app/models/team.model';
import { Company } from 'src/app/models/company.model';
import { TeamSchema } from 'src/app/models/team-schema.model';
import { AdminTabs } from '../admin-tabs.enum';
import { Features } from 'src/app/models/features.enum';
import { User } from 'src/app/models/user.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import {
  REGEX_ALPHANUMERIC_WITHOUT_GERMAN_CHARS,
  REGEX_EMAIL,
} from 'src/app/constants/constants';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss'],
})
export class EditTeamComponent implements OnInit {
  public teamDataForm: UntypedFormGroup;
  public licences: LicenceTypes;
  public showSpinner = false;
  private isAdmin: boolean;

  public companyId: string;
  public company: Company;
  private teamId: string;
  private team: Team;
  private teamSchema: TeamSchema;

  public UPDATE_TEAM_CREATED = '';
  public FEATURES_LOCATIONS = Features.Locations;

  public teamCaptainOptions: Array<string> = [];
  public filteredTeamCaptainOptions: Observable<Array<string>>;
  private REGEX_FOR_ID = REGEX_ALPHANUMERIC_WITHOUT_GERMAN_CHARS;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private teamsService: TeamsService,
    private adminService: AdminService
  ) {
    this.teamDataForm = this.formBuilder.group({
      avatar: ['assets/img/move-me-new.png'],
      avatarImg: [undefined],
      color: ['', Validators.required],
      id: [
        { value: '' },
        [Validators.required, Validators.pattern(this.REGEX_FOR_ID)],
      ],
      name: ['', Validators.required],
      companyId: [{ value: '', disabled: true }, Validators.required],
      location: [''],
      teamCaptain: [null, Validators.pattern(REGEX_EMAIL)],
    });

    this.filteredTeamCaptainOptions = this.teamDataForm.controls.teamCaptain.valueChanges.pipe(
      startWith(''),
      map((email) =>
        email
          ? this.filterTeamCaptainOptions(email)
          : this.teamCaptainOptions.slice()
      )
    );
  }

  get companies(): Array<Company> {
    return this.adminService.companies;
  }

  public async ngOnInit(): Promise<void> {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.teamId = this.route.snapshot.paramMap.get('teamId');
    this.teamDataForm.controls.companyId.setValue(this.companyId);
    this.isAdmin = this.companyId === null;

    this.company = this.companies.find(
      (company) => company.id === this.companyId
    );

    try {
      const usersQuerySnapshot = await this.firebaseService.getUsersFromCompany(
        this.companyId
      );
      if (!usersQuerySnapshot.empty) {
        this.teamCaptainOptions = usersQuerySnapshot.docs
          .map((userSnapshot) => new User(userSnapshot.data()))
          .filter((user) => user.teamId === this.teamId)
          .map((user) => user.email)
          .sort();
      }
    } catch (error) {
      console.log('error getting users from company - error: ', error);
    }

    if (!this.isAdmin) {
      this.teamsService
        .getTeam(this.companyId, this.teamId)
        .then((team: Team) => {
          this.team = team;
          Object.entries(this.teamDataForm.controls).forEach((control) => {
            if (control[0] !== 'companyId' && control[0] !== 'avatarImg') {
              this.teamDataForm.get(control[0]).setValue(this.team[control[0]]);
            }
          });

          if (this.team.teamCaptain) {
            const teamCaptain = this.teamCaptainOptions.find(
              (userEmail) => userEmail === this.team.teamCaptain
            );
            if (teamCaptain) {
              this.teamDataForm.patchValue({
                teamCaptain,
              });
            }
          }
        })
        .catch((error) => {
          console.log('firebaseService.getTeam - error: ', error);
        });
    } else {
      this.firebaseService
        .getTeamSchema(this.teamId)
        .then((teamSnapshot) => {
          this.teamSchema = new TeamSchema().deserialize(teamSnapshot.data());
          Object.entries(this.teamDataForm.controls).forEach((control) => {
            if (control[0] !== 'companyId' && control[0] !== 'avatarImg') {
              this.teamDataForm
                .get(control[0])
                .setValue(this.teamSchema[control[0]]);
            }
          });
        })
        .catch((error) => {
          console.log('firebaseService.getTeam - error: ', error);
        });
    }
  }

  public returnPage(): void {
    this.router.navigate(['../..'], {
      relativeTo: this.route,
      state: { tab: AdminTabs.Teams },
    });
  }

  public async editTeam(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (!this.isAdmin) {
      if (this.teamDataForm.valid) {
        const companyId = this.teamDataForm.controls.companyId.value;
        const teamId = this.teamDataForm.controls.id.value;

        if (this.teamDataForm.controls.avatarImg.value) {
          const avatarFile = this.teamDataForm.controls.avatarImg.value
            .files[0];

          await this.firebaseService
            .uploadImage(
              avatarFile,
              'companies/' + companyId + '/' + teamId + '/avatar'
            )
            .then(async (snapshot) => {
              const url = await snapshot.ref.getDownloadURL();
              this.teamDataForm.controls.avatar.setValue(url);
            });
        }

        const team = new Team({
          avatar: this.teamDataForm.controls.avatar.value,
          color: this.teamDataForm.controls.color.value,
          name: this.teamDataForm.controls.name.value,
          id: teamId,
          location: this.teamDataForm.controls.location.value,
          totalSteps: this.team.totalSteps,
          totalPoints: this.team.totalPoints,
          teamCaptain: this.teamDataForm.controls.teamCaptain.value
            .trim()
            .toLowerCase(),
          currentTeamChallengeId: this.team.currentTeamChallengeId,
          records: this.team.records,
          members: [...this.team.members],
        });

        if (teamId !== this.teamId) {
          await this.firebaseService.createCompanyTeam(companyId, teamId, team);
          await this.firebaseService.deleteTeam(companyId, this.teamId);
        } else {
          await this.firebaseService.updateTeam(companyId, teamId, team);
        }

        this.teamUpdateFinished();
      }
    } else {
      if (this.teamDataForm.valid) {
        const teamId = this.teamDataForm.controls.id.value;

        if (this.teamDataForm.controls.avatarImg.value) {
          const avatarFile = this.teamDataForm.controls.avatarImg.value
            .files[0];

          await this.firebaseService
            .uploadImage(avatarFile, 'teams/' + teamId + '/avatar')
            .then(async (snapshot) => {
              const url = await snapshot.ref.getDownloadURL();
              this.teamDataForm.controls.avatar.setValue(url);
            });
        }
        const team = new TeamSchema().deserialize({
          avatar: this.teamDataForm.controls.avatar.value,
          color: this.teamDataForm.controls.color.value,
          name: this.teamDataForm.controls.name.value,
          id: this.teamDataForm.controls.id.value,
        });

        await this.firebaseService.updateTeamSchema(teamId, team);

        this.teamUpdateFinished();
      }
    }
  }

  private teamUpdateFinished(): void {
    this.showSpinner = false;
    const updateTeamCreatedText = this.translateService.instant('TEAM_UPDATED');
    this.snackBar.open(updateTeamCreatedText, 'Ok', {
      duration: 4000,
      panelClass: 'snack-bar-color',
    });
    this.router.navigate(['../..'], {
      relativeTo: this.route,
      state: { tab: AdminTabs.Teams },
    });
  }

  private filterTeamCaptainOptions(value: string): Array<string> {
    const filterValue = value.toLowerCase();
    return this.teamCaptainOptions.filter((userEmail) =>
      userEmail.toLowerCase().includes(filterValue)
    );
  }
}
