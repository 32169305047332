import { Deserializable } from './deserializable.model';

export interface INotificationSettings {
  // Not Synced Notification
  isNotSyncedNotificationActive?: boolean;
  notSyncedNotificationTime?: string;

  // Walk reminder Notification
  isWalkReminderNotificationActive?: boolean;
  walkReminderNotificationTimes?: Array<string>;
}

export class NotificationSettings implements Deserializable {
  // Not Synced Notification
  public isNotSyncedNotificationActive = false;
  public notSyncedNotificationTime = '';

  // Walk reminder Notification
  public isWalkReminderNotificationActive = false;
  public walkReminderNotificationTimes: Array<string> = [];

  constructor(initializer?: INotificationSettings) {
    Object.assign(this, initializer);
  }

  public deserialize(input: any): this {
    return Object.assign(this, input);
  }

  public toObject(): any {
    return Object.assign({}, this);
  }
}
