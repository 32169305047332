import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FirebaseService } from '../firebase/firebase.service';
import { GroupRunChallenge } from 'src/app/models/group-run/group-run-challenge/group-run-challenge';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { UserMessageService } from '../user-message/user-message.service';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GroupRunChallengeService {
  public groupRunChallenges: Array<GroupRunChallenge> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService
  ) {}

  public getStandardGroupRunChallengeDataForm(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      dateRange: new UntypedFormGroup({
        start: new UntypedFormControl(null, Validators.required),
        end: new UntypedFormControl(null, Validators.required),
      }),
      projectPlanSent: [false],
      features: [[]],
      firstLevelTarget: [100, [Validators.required, Validators.min(100)]],
      levelIncrementalFactor: [
        1.1,
        [Validators.required, Validators.min(1.1), Validators.max(2)],
      ],
      companies: [[]],
    });
  }

  public updateGroupRunChallenge(
    groupRunChallenge: GroupRunChallenge
  ): Promise<void> {
    if (!groupRunChallenge.id) {
      groupRunChallenge.id = this.firebaseService.getAutomaticIdInRootCollection(
        FirebaseConstants.GroupRunChallenges
      );
    }
    return this.firebaseService
      .updateGroupRunChallenge(groupRunChallenge)
      .then(() => {
        this.userMessageService.snackBarMessage(
          this.translateService.instant(
            'UPDATE_GROUP_RUN_RESPONSES.GROUP_RUN_CREATED_CORRECTLY',
            { name: groupRunChallenge.name }
          )
        );
      })
      .catch((error) => {
        this.userMessageService.snackBarMessage(
          this.translateService.instant(
            'UPDATE_GROUP_RUN_RESPONSES.ERROR_CREATING_GROUP_RUN',
            { name: groupRunChallenge.name }
          )
        );
        console.log(`Create group run challenge error: ${error}`);
      });
  }

  public getGroupRunChallenges(): Promise<Array<GroupRunChallenge>> {
    return this.firebaseService
      .getGroupRunChallenges()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.groupRunChallenges = querySnapshot.docs.map(
            (docSnapshot) => new GroupRunChallenge(docSnapshot.data())
          );
          return [...this.groupRunChallenges];
        } else {
          this.groupRunChallenges = [];
          return [];
        }
      })
      .catch((error) => {
        console.log(`Get group run challenges error: ${error}`);
        this.userMessageService.snackBarMessage(
          this.translateService.instant(
            'GET_GROUP_RUNS_RESPONSES.ERROR_GETTING_GROUP_RUNS'
          )
        );
        this.groupRunChallenges = [];
        return [];
      });
  }

  private deleteGroupRunChallenge(groupRunChallengeId: string): Promise<void> {
    return this.firebaseService
      .deleteGroupRunChallenge(groupRunChallengeId)
      .then(() => {
        const indexToDelete = this.groupRunChallenges.findIndex(
          (groupRunChallenge) => groupRunChallenge.id === groupRunChallengeId
        );
        this.groupRunChallenges.splice(indexToDelete, 1);
      })
      .catch((error) => {
        console.log(`Remove group run challenge error: ${error}`);
      });
  }

  public deleteGroupRunChallengeWithConfirmation(
    groupRunChallenge: GroupRunChallenge
  ): MatDialogRef<ConfirmationDialogComponent> {
    const dialogData: ConfirmationDialogData = new ConfirmationDialogData({
      title: this.translateService.instant('DELETE_GROUP_RUN_ALERT.TITLE', {
        name: groupRunChallenge.name,
      }),
      message: this.translateService.instant('DELETE_GROUP_RUN_ALERT.MESSAGE', {
        name: groupRunChallenge.name,
      }),
      action: () => this.deleteGroupRunChallenge(groupRunChallenge.id),
    });

    const dialogRef = this.userMessageService.openConfirmationDialog(
      dialogData
    );

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              'DELETE_GROUP_RUN_ALERT.ACTION_PERFORMED_CORRECTLY',
              { name: groupRunChallenge.name }
            )
          );
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.userMessageService.snackBarMessage(
            this.translateService.instant(
              'DELETE_GROUP_RUN_ALERT.ACTION_PERFORMED_WITH_ERRORS',
              { name: groupRunChallenge.name }
            )
          );
          console.log('error: ', result.error);
          break;
      }
    });

    return dialogRef;
  }
}
