import { Helpers } from 'src/app/helpers/helpers';
import { IRouteInitializer, Route } from '../route/route.model';
import { RouteStatus } from '../route/route-status.enum';

export interface IRegionInitializer {
  route: IRouteInitializer;
  startDate: string;
  endDate: string;
  duration: number;
}

export class Region {
  public route: Route = null;
  public startDate: string = null; // Use ISO datetime to avoid unexpected behaviors.
  public endDate: string = null; // Use ISO datetime to avoid unexpected behaviors.
  public duration: number = null;

  constructor(iRegionInitializer?: IRegionInitializer) {
    if (iRegionInitializer) {
      Object.assign(this, iRegionInitializer);

      if (iRegionInitializer.route) {
        this.route = new Route(iRegionInitializer.route);
      }
    }
  }

  public toObject(): IRegionInitializer {
    const { route, ...otherProperties } = this;

    return {
      ...otherProperties,
      route: route ? route.toObject() : null,
    };
  }

  public getStatus(): RouteStatus {
    const today = Helpers.createISODateTime();

    if (today > this.endDate) {
      return RouteStatus.Completed;
    } else {
      if (today < this.startDate) {
        return RouteStatus.Pending;
      } else {
        return RouteStatus.InProgress;
      }
    }
  }
}
