import { Deserializable } from './deserializable.model';

export class FinalStandings implements Deserializable {
  public teamAvatar: string;
  public teamName: string;
  public finalPoints: number;
  public finalPosition: number;
  public totalAverageSteps: number;
  public totalSteps: number;
  public id: string;

  constructor({
    id = '',
    teamAvatar = '',
    teamName = '',
    finalPoints = 0,
    finalPosition = 0,
    totalAverageSteps = 0,
    totalSteps = 0,
  }) {
    this.teamAvatar = teamAvatar ? teamAvatar : '';
    this.teamName = teamName ? teamName : '';
    this.finalPoints = finalPoints ? finalPoints : 0;
    this.finalPosition = finalPosition ? finalPosition : 0;
    this.totalAverageSteps = totalAverageSteps ? totalAverageSteps : 0;
    this.totalSteps = totalSteps ? totalSteps : 0;
    this.id = id ? id : '';
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  toObject(): any {
    return Object.assign({}, this);
  }
}
