<div class="container-card">
  <mat-grid-list [cols]="colSize" rowHeight="1:1">
    <mat-grid-tile class="logo-col" colspan="1" rowspan="1">
      <div class="img-container">
        <img src="../../../assets/logo_login.png" />
      </div>
      <div *ngIf="colSize === 1" (click)="scroll(scrollTarget)" class="expand-icon-container">
        <mat-icon>expand_more</mat-icon>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="recover-col" rowspan="1" colspan="1">
      <div #scrollTarget>
        <div class="welcome-box">
          <p class="move-me-title">Move Me</p>
          <p class="enter-email-label">
            {{ 'RESET_PASSWORD_TEXT' | translate }}
          </p>
        </div>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
          <mat-form-field>
            <mat-label>{{ 'forms.email.LABEL' | translate }}</mat-label>
            <input
              matInput
              type="email"
              class="form-control"
              formControlName="email"
              id="email"
            />
            <mat-error *ngIf="email.hasError('required')">
              {{ 'forms.email.REQUIRED_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="email.hasError('pattern')">
              {{ 'forms.email.PATTERN_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <div class="button-container">
            <button mat-flat-button color="primary" type="submit" [disabled]="!resetPasswordForm.valid">
              {{ 'RESET_PASSWORD_BUTTON' | translate }}
            </button>
          </div>
          <div class="links-container">
            <p
              class="ion-text-center"
              routerLink="/login"
              routerDirection="forward"
            >
              {{ 'RESET_PASSWORD_BACK' | translate }}
            </p>
          </div>
        </form>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
