import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CityMarkerPoint } from 'src/app/models/city-marker-point.model';

interface ImportRouteConfirmationDialogData {
  route: MatTableDataSource<CityMarkerPoint>;
}

@Component({
  selector: 'app-import-route-confirmation-dialog',
  templateUrl: './import-route-confirmation-dialog.component.html',
  styleUrls: ['./import-route-confirmation-dialog.component.scss'],
})
export class ImportRouteConfirmationDialogComponent implements OnInit {
  public routesColumns: Array<string> = [
    'startingCity',
    'endingCity',
    'distance',
  ];

  constructor(
    public dialogRef: MatDialogRef<ImportRouteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImportRouteConfirmationDialogData
  ) {}

  ngOnInit(): void {}

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public async onYesClick(): Promise<void> {
    this.dialogRef.close(true);
  }
}
