<app-notification-messages *ngIf="!isProdEnvironment" [defaultNotification]="{ showMessage: true, message: { en: 'Testing Environment', de: 'Testing Umgebung' }}"></app-notification-messages>
<mat-toolbar class="sidenav-content-toolbar" *ngIf="user">
  <mat-toolbar-row>
    <div
      class="move-me-button"
      [ngClass]="sidebarOpened ? 'big-button' : 'small-button'"
      (click)="toggleSidebar()"
    >
      <button mat-icon-button>
        <img class="icon" src="../../../../assets/img/logo-without-name.svg" />
      </button>
      <span>
        <mat-icon class="side-chevron">
          {{ sidebarOpened ? 'chevron_left' : 'chevron_right' }}
        </mat-icon>
      </span>
    </div>

    <div
      class="company-name-label"
      fxLayoutAlign="flex-start center"
      fxHide.xs
      fxHide.sm
      fxHide.md
    >
      <span>{{ company?.name }}</span>
    </div>

    <div fxFlex fxLayout="row center" fxLayoutAlign="flex-end">
      <ul
        fxLayout="row"
        fxLayoutGap="20px"
        class="ul-header-position"
        fxHide.xs
        fxHide.lt-lg
      >
        <li>
          <button mat-button (click)="updateChart()">
            <mat-icon
              [ngClass]="isUpdating ? 'animate' : ''"
              class="sync-button"
              >sync</mat-icon
            >
          </button>
        </li>
        <li>
          <button mat-button [matMenuTriggerFor]="langMenu">
            <span class="menu-label"
              >{{ 'languages.' + settings.lang | translate }}
            </span>
            <!-- <span>username</span> -->
            <mat-icon> language </mat-icon>
          </button>
          <mat-menu #langMenu="matMenu">
            <button mat-menu-item (click)="changeLanguage('de')">
              {{ 'languages.de' | translate }}
            </button>
            <button mat-menu-item (click)="changeLanguage('en')">
              {{ 'languages.en' | translate }}
            </button>
          </mat-menu>
        </li>
        <li>
          <button mat-button [matMenuTriggerFor]="menu">
            <span class="menu-label">{{ user.displayName }}</span>
            <!-- <span>username</span> -->
            <mat-icon> person_outline </mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logout()">
              <!-- <mat-icon>exit_to_app</mat-icon> -->
              {{ 'HEADER_LOGOUT' | translate }}
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
  <!-- <button mat-icon-button class="menu-button" type="button" aria-label="Toggle sidenav">
        <i class="material-icons">menu</i>
    </button>
    <span class="filler">
        You can place a search bar here or anything you like
    </span> -->
</mat-toolbar>
