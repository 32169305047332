import { Deserializable } from './deserializable.model';

export class TeamRecord implements Deserializable {
  public date: number;
  public name: string;
  public finalPosition: number;
  public ligaId: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  toObject(): object {
    return Object.assign({}, this);
  }
}
