import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumbersFormat',
})
export class BigNumbersFormatPipe implements PipeTransform {
  transform(value: number, lang: string): string {
    if (Math.abs(value) >= Math.abs(1000000)) {
      const millionValue = value / 1000000;
      return `${millionValue.toFixed(1)}M`;
    } else if (Math.abs(value) >= Math.abs(1000)) {
      const thousandValue = value / 1000;
      return `${thousandValue.toFixed(1)}${lang === 'en' ? 'K' : 'T'}`;
    } else {
      return value.toString();
    }
  }
}
