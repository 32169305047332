<h1 mat-dialog-title>{{ company.name }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Test Company</mat-label>
    <mat-select required [(ngModel)]="company.isTestCompany">
      <mat-option
        *ngFor="let value of [true, false]; let i = index"
        [value]="value"
      >
        {{ value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button [mat-dialog-close]="company" (click)="updateCompanyIsTestValue()" cdkFocusInitial>Update Value</button>
</div>
