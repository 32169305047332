import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Team } from 'src/app/models/team.model';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-admin-team',
  templateUrl: './admin-teams.component.html',
  styleUrls: ['./admin-teams.component.scss'],
})
export class AdminTeamsComponent {
  @ViewChild(MatSort) sort: MatSort;
  public teams: Array<Team> = [];

  public showSpinner = true;

  public displayedColumns: string[] = [
    'avatar',
    'teamName',
    'totalSteps',
    'totalPoints',
    'currentChallenge',
  ];
  public dataSource: MatTableDataSource<Team>;

  constructor(private subscriptionService: SubscriptionService) {
    this.subscriptionService.teams.subscribe((teams) => {
      if (teams) {
        this.teams = teams;
        this.dataSource = new MatTableDataSource(this.teams);
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      }
    });
  }
}
