import { Deserializable } from './deserializable.model';
import { ChallengeTypes } from './challenge-types.enum';
import { Helpers } from 'src/app/helpers/helpers';

export class ChallengeSchema implements Deserializable {
  public initialPoints: number;
  public name: string;
  public type: ChallengeTypes;
  public maxLevel: number;
  public minLevel: number;
  public pointsToLevelUp: number;
  public pointsToHoldLevel: number;
  public initialLevel: number;
  public pointsSourceIds: Set<string>;
  public rewards: Map<string, number>;
  public levelDuration: number;
  public canAscend: boolean;
  public canDescend: boolean;
  public hasFixedDate: boolean;
  public startDate: number;
  public endDate: number;
  public id: string;
  public sStartDate: string;
  public sEndDate: string;
  public creationDate: number;

  constructor(
    id?: string,
    initialPoints?: number,
    name?: string,
    type?: ChallengeTypes,
    maxLevel?: number,
    minLevel?: number,
    pointsToLevelUp?: number,
    pointsToHoldLevel?: number,
    initialLevel?: number,
    pointsSourceIds?: Set<string>,
    rewards?: Map<string, number>,
    levelDuration?: number,
    canAscend?: boolean,
    canDescend?: boolean,
    hasFixedDate?: boolean,
    startDate?: number,
    endDate?: number
  ) {
    this.id = id || '';
    this.initialPoints = initialPoints || 0;
    this.name = name || '';
    this.type = type || ChallengeTypes.Walk;
    this.maxLevel = maxLevel || 0;
    this.minLevel = minLevel || 0;
    this.pointsToLevelUp = pointsToLevelUp || 0;
    this.pointsToHoldLevel = pointsToHoldLevel || 0;
    this.initialLevel = initialLevel || 0;
    this.pointsSourceIds = pointsSourceIds || new Set<string>();
    this.rewards = rewards || new Map<string, number>();
    this.levelDuration = levelDuration || 7;
    this.canAscend = canAscend !== null ? canAscend : true;
    this.canDescend = canDescend !== null ? canDescend : true;
    this.hasFixedDate = hasFixedDate !== null ? hasFixedDate : true;
    this.startDate = startDate || null;
    this.endDate = endDate || null;
    this.sStartDate = startDate ? Helpers.formatDateTimestamp(startDate) : null;
    this.sEndDate = endDate ? Helpers.formatDateTimestamp(endDate) : null;
    this.creationDate = new Date().getTime();
  }

  public toObject(): any {
    const pointsSourceIds = Array.from(this.pointsSourceIds);
    const returnObject = Object.assign({}, this);
    const rewards = Object.fromEntries(this.rewards);
    return Object.assign(returnObject, { pointsSourceIds, rewards });
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    this.rewards = input.rewards
      ? new Map(Object.entries(input.rewards))
      : new Map<string, number>();
    this.pointsSourceIds = input.pointsSourceIds
      ? new Set(input.pointsSourceIds)
      : new Set<string>();
    if (input.startDate && !this.sStartDate) {
      this.sStartDate = Helpers.formatDateTimestamp(input.startDate);
    }
    if (input.endDate && !this.sEndDate) {
      this.sEndDate = Helpers.formatDateTimestamp(input.endDate);
    }
    return this;
  }
}
