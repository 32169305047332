import {
  CitiesChallengePerformance,
  ICitiesChallengePerformance,
} from '../cities-challenge-performance/cities-challenge-performance.model';

export interface ICitiesChallengeDayPerformance
  extends ICitiesChallengePerformance {
  date: string;
}

export class CitiesChallengeDayPerformance extends CitiesChallengePerformance {
  public date: string = null;

  constructor(iCitiesChallengeDayPerformance?: ICitiesChallengeDayPerformance) {
    super(iCitiesChallengeDayPerformance);
    if (iCitiesChallengeDayPerformance) {
      this.date = iCitiesChallengeDayPerformance.date;
    }
  }

  public toObject(): ICitiesChallengeDayPerformance {
    return {
      ...super.toObject(),
      date: this.date,
    };
  }
}
