<div mat-dialog-title>
  <div class="top">
    <h1 class="title">{{ 'ADD_PERSONAL_CHALLENGE_TITLE' | translate }}</h1>
  </div>
</div>
<div mat-dialog-content
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <form [formGroup]="challengeDataForm">
    <mat-form-field>
      <mat-label>{{ 'ADD_PERSONAL_CHALLENGE_ID' | translate }}</mat-label>
      <input matInput formControlName="challengeId" />
      <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_PERSONAL_CHALLENGE_NAME' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <div class="challenge-details">
      <mat-form-field>
        <mat-label>{{ 'Initial Points' | translate }}</mat-label>
        <input type="number" matInput formControlName="initialPoints" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Initial Level' | translate }}</mat-label>
        <input type="number" matInput formControlName="initialLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Minimal Level' | translate }}</mat-label>
        <input type="number" matInput formControlName="minLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Maximal Level' | translate }}</mat-label>
        <input type="number" matInput formControlName="maxLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Level Duration' | translate }}</mat-label>
        <input type="number" matInput formControlName="levelDuration" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PointsToLevelUp' | translate }}</mat-label>
        <input type="number" matInput formControlName="pointsToLevelUp" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'pointsToHoldLevel' | translate }}</mat-label>
        <input type="number" matInput formControlName="pointsToHoldLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="checkboxes">
      <mat-form-field>
        <mat-label>{{ 'Type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let challengeType of challengeTypesNames; let i = index"
            value="{{ challengeTypesKeys[i] }}"
          >
            {{ challengeType }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_POINTS_SOURCE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="canAscend">{{
        'Can Ascend' | translate
      }}</mat-checkbox>
      <mat-checkbox color="primary" formControlName="canDescend">{{
        'Can Descend' | translate
      }}</mat-checkbox>
      <mat-checkbox color="primary" formControlName="hasFixedDate">{{
        'Has Fixed Date' | translate
      }}</mat-checkbox>

    </div>
  </form>
  <form [formGroup]="pointsSourcesDataForm">
    <div class="points-sources">
      <h3>Points Sources</h3>
      <mat-form-field>
        <mat-label>{{ 'Points Sources' | translate }}</mat-label>
        <mat-select formControlName="pointsSourceId">
          <mat-option
            *ngFor="let pointsSource of selectablePointsSources"
            value="{{ pointsSource.id }}"
          >
            {{ pointsSource.description }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        [disabled]="!pointsSourcesDataForm.valid"
        (click)="addPointsSource()"
      >
        {{ 'Add Points Source' | translate }}
      </button>

      <table
        mat-table
        [dataSource]="pointsSourceDataSource"
        matSort
        *ngIf="pointsSourceIds.length !== 0"
      >
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.id }}
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.description }}
          </td>
        </ng-container>

        <!-- Basis Column -->
        <ng-container matColumnDef="basis">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Basis</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSourceBasisNames[pointsSource.basis] }}
          </td>
        </ng-container>

        <!-- Expected Performance Column -->
        <ng-container matColumnDef="expectedPerformance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Expected Performance
          </th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.expectedPerformance }}
          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSourceTypesNames[pointsSource.type] }}
          </td>
        </ng-container>

        <!-- Points Awarded Column -->
        <ng-container matColumnDef="pointsAwarded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Points Awarded
          </th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.pointsAwarded }}
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let pointsSource"
            (click)="removePointsSource(pointsSource.id)"
          >
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="pointsSourceColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pointsSourceColumns"></tr>
      </table>
      <div *ngIf="pointsSourceIds.length === 0">
        <span>Add a Data Source for this challenge</span>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'CANCEL' | translate }}</button>
  <button mat-button [disabled]="!challengeDataForm.valid || pointsSourceIds.length === 0" (click)="confirm()">
    {{ 'CONFIRM' | translate }}
    <mat-spinner [diameter]="16" *ngIf="showSpinner"></mat-spinner>
  </button>
</div>
