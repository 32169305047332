import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-challenge-type-card',
  templateUrl: './challenge-type-card.component.html',
  styleUrls: ['./challenge-type-card.component.scss'],
})
export class ChallengeTypeCardComponent {
  @Input() type: string;
  @Output() selectedType = new EventEmitter<string>();

  constructor() {}

  public select(challengeType: string): void {
    this.selectedType.emit(challengeType);
  }
}
