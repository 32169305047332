import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  DoCheck,
  Input,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { _isNumberValue } from '@angular/cdk/coercion';
import { DateAdapter } from '@angular/material/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

// Models
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { TeamFinalStandings } from 'src/app/models/team-final-standings.model';
import { FinalStandings } from 'src/app/models/final-standings.model';
import { Company } from 'src/app/models/company.model';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { TeamsService } from 'src/app/services/teams/teams.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';
import { MAX_SAFE_INTEGER, MSECS_IN_A_DAY } from 'src/app/constants/constants';

@Component({
  selector: 'app-team-challenge-component',
  templateUrl: './team-challenge.component.html',
  styleUrls: ['./team-challenge.component.scss'],
})
export class TeamChallengesComponent implements OnInit, AfterViewInit, DoCheck {
  @Input()
  company: Company;

  @Input()
  currentTeamChallengeWeeks: Array<any>;

  @Input()
  dailyRankingArray: Array<any>;

  @Input()
  currentTeamChallengeData: any = {
    weeklyRanking: [],
    weeklyRankingPointsSorted: [],
    weeklyRankingStats: [],
  };

  public teamChallengeRankingForm: UntypedFormGroup;

  public displayedColumnsLiga: string[] = [
    'positionSorted',
    'avatar',
    'name',
    'teamSize',
  ];
  public displayedColumnsWeekly: string[] = [
    'position',
    'avatar',
    'name',
    'totalSteps',
    'teamSize',
    'totalSyncs',
    'averageSteps',
  ];
  public dataSourceLiga = new MatTableDataSource(
    this.weeklyRankingPointsSorted
  );
  public dataSourceWeekly = new MatTableDataSource(this.weeklyRanking);
  public TEAM_CHALLENGE_TAB_TEAM_CHALLENGE_RANKING = '';
  public TEAM_CHALLENGE_TAB_WEEKLY_RANKING = '';
  public today = new Date();
  public date =
    this.today.getDate().toString().padStart(2, '0') +
    '.' +
    (this.today.getMonth() + 1).toString().padStart(2, '0') +
    '.' +
    this.today.getFullYear();

  public pastTeamChallenges: Array<TeamChallenge> = [];
  public pastFinalStandings: Array<TeamFinalStandings> = [];
  public finalStandingsReady = false;
  public dataSourceFinalStandings: Array<
    MatTableDataSource<FinalStandings>
  > = [];

  public displayedColumnsFinalStandings: string[] = [
    'positionSorted',
    'avatar',
    'name',
    'points',
  ];

  public dateRange = new UntypedFormGroup({
    teamChallenge: new UntypedFormControl(),
    startingWeek: new UntypedFormControl(),
    endingWeek: new UntypedFormControl(),
  });

  public selectedTeamChallengeWeeks: Array<any> = [];
  public selectedTeamChallengePossibleEndingWeeks: Array<any> = [];

  public rankingByDateDataSource = new MatTableDataSource();

  @ViewChild('ligaSort') ligaSort: MatSort;
  @ViewChild('weeklySort') weeklySort: MatSort;
  @ViewChild('rankingByDateSort') rankingByDateSort: MatSort;

  constructor(
    private companyService: CompanyService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private dateAdapter: DateAdapter<any>,
    private teamsService: TeamsService
  ) {
    this.getTranslations();
    this.teamChallengeRankingForm = this.formBuilder.group({
      week: [null, Validators.required],
    });
    this.dateAdapter.setLocale('de');

    this.dateRange.controls.startingWeek.disable();
    this.dateRange.controls.endingWeek.disable();
  }

  get weeklyRanking(): any {
    return this.currentTeamChallengeData.weeklyRanking;
  }

  get weeklyRankingPointsSorted(): any {
    return this.currentTeamChallengeData.weeklyRankingPointsSorted;
  }

  public ngAfterViewInit(): void {
    this.dataSourceLiga.sort = this.ligaSort;
    this.dataSourceLiga.sortingDataAccessor = this.sortingDataAccessor;

    this.dataSourceWeekly.sort = this.weeklySort;
    this.dataSourceWeekly.sortingDataAccessor = this.sortingDataAccessor;

    this.rankingByDateDataSource.sort = this.rankingByDateSort;
  }

  public ngOnInit(): void {
    this.companyService
      .getTeamChallenges(this.company.id)
      .then((teamChallenges) => {
        this.pastTeamChallenges = teamChallenges.filter(
          (teamChallenge) => teamChallenge.endDate < Date.now() - MSECS_IN_A_DAY
        );
        // today minus a day
        Promise.all(
          this.pastTeamChallenges.map(async (teamChallenge) =>
            this.companyService.getTeamChallengeFinalStandings(
              teamChallenge.id,
              this.company.id
            )
          )
        )
          .then((resolutions) => {
            this.pastFinalStandings = resolutions;
            this.dataSourceFinalStandings = this.pastFinalStandings
              .filter((finalStanding) => finalStanding)
              .map(
                (finalStanding) => new MatTableDataSource(finalStanding.teams)
              );
            this.finalStandingsReady = true;
          })
          .catch((error) => {
            console.log('final standings promises error: ', error);
          });
      })
      .catch((error) => {
        console.log('getTeamChallenges - error: ', error);
      });

    this.displayedColumnsLiga.push('points');

    this.teamChallengeRankingForm
      .get('week')
      .valueChanges.subscribe((selectedWeek) => {
        const week = Object.assign({}, selectedWeek);
        delete week.weekdays;
        delete week.id;
        this.dataSourceLiga = new MatTableDataSource(
          Object.entries(week)
            .map((weekEntry) => weekEntry[1])
            .sort((a: any, b: any) => b.points - a.points)
            .map((weekEntry: any, index) =>
              Object.assign(weekEntry, { positionSorted: index + 1 })
            )
        );
        this.dataSourceLiga.sort = this.ligaSort;
        this.dataSourceLiga.sortingDataAccessor = this.sortingDataAccessor;

        this.dataSourceWeekly = new MatTableDataSource(
          Object.entries(week)
            .map((weekEntry) => weekEntry[1])
            .sort((a: any, b: any) => {
              if (!b.totalSyncs || b.totalSyncs === 0) {
                return -1;
              }
              if (!a.totalSyncs || a.totalSyncs === 0) {
                return 1;
              }
              const meanStepsDifference =
                b.totalSteps / b.totalSyncs - a.totalSteps / a.totalSyncs;
              if (meanStepsDifference !== 0) {
                return meanStepsDifference;
              }
              // Sort by name with equal points
              if (a.name < b.name) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((weekEntry: any, index) =>
              Object.assign(weekEntry, {
                position: index + 1,
                averageSteps: weekEntry.totalSyncs
                  ? weekEntry.totalSteps / weekEntry.totalSyncs
                  : 0,
              })
            )
        );

        this.dataSourceWeekly.sort = this.weeklySort;
        this.dataSourceWeekly.sortingDataAccessor = this.sortingDataAccessor;
      });
  }

  public ngDoCheck(): void {
    if (
      this.teamChallengeRankingForm.get('week').value === null &&
      this.currentTeamChallengeWeeks.length !== 0
    ) {
      this.teamChallengeRankingForm
        .get('week')
        .setValue(
          this.currentTeamChallengeWeeks[
            this.currentTeamChallengeWeeks.length - 1
          ]
        );
    }
  }

  public getTranslations(): void {
    this.translateService
      .stream([
        'TEAM_CHALLENGE.TAB_TEAM_CHALLENGE_RANKING',
        'TEAM_CHALLENGE.TAB_WEEKLY_RANKING',
      ])
      .subscribe((values) => {
        this.TEAM_CHALLENGE_TAB_TEAM_CHALLENGE_RANKING =
          values['TEAM_CHALLENGE.TAB_TEAM_CHALLENGE_RANKING'];
        this.TEAM_CHALLENGE_TAB_WEEKLY_RANKING =
          values['TEAM_CHALLENGE.TAB_WEEKLY_RANKING'];
      });
  }

  /**
   * Data accessor function that is used for accessing data properties for sorting through
   * the default sortData function.
   * This default function assumes that the sort header IDs (which defaults to the column name)
   * matches the data's properties (e.g. column Xyz represents data['Xyz']).
   * May be set to a custom function for different behavior.
   * @param data Data object that is being accessed.
   * @param sortHeaderId The name of the column that represents the data.
   */
  public sortingDataAccessor: (
    data: any,
    sortHeaderId: string
  ) => string | number = (data: any, sortHeaderId: string): string | number => {
    let value: any;
    if (sortHeaderId.includes('position')) {
      value = (data as { [key: string]: any }).i;
    } else {
      if (sortHeaderId.includes('stat-')) {
        const statIndex = sortHeaderId.split('-')[1]; // get stat index after an string split
        value = (data as { [key: string]: any }).dailyPerformance[this.date]
          .Steps;
      } else {
        value = (data as { [key: string]: any })[sortHeaderId];
      }
    }

    if (_isNumberValue(value)) {
      const numberValue = Number(value);

      // Numbers beyond `MAX_SAFE_INTEGER` can't be compared reliably so we
      // leave them as strings. For more info: https://goo.gl/y5vbSg
      return numberValue < MAX_SAFE_INTEGER ? numberValue : value;
    }
    return value;
  };

  public getTeamStepsOnRange(): void {
    this.companyService
      .getTeamChallengesWeeksOnRange(
        this.company.id,
        this.dateRange.value.teamChallenge,
        this.dateRange.value.startingWeek,
        this.dateRange.value.endingWeek
      )
      .then((standingsArray) => {
        this.rankingByDateDataSource = new MatTableDataSource(standingsArray);
        this.rankingByDateDataSource.sort = this.rankingByDateSort;
      })
      .catch((error) => {
        console.log('companyService.getTeamChallengesWeeksOnRange: ', error);
      });
  }

  public async onTeamChallengeRankingSelected(): Promise<void> {
    this.dateRange.controls.endingWeek.disable();
    this.dateRange.controls.startingWeek.reset();
    this.dateRange.controls.endingWeek.reset();
    this.rankingByDateDataSource = new MatTableDataSource([]);
    this.selectedTeamChallengeWeeks = (
      await this.teamsService.getTeamChallengeWeeks(
        this.company.id,
        this.dateRange.value.teamChallenge
      )
    )
      .map((week) => {
        week.weekdays.sort(
          (weekdayA: string, weekdayB: string) =>
            Helpers.unformatDate(weekdayA).getTime() -
            Helpers.unformatDate(weekdayB).getTime()
        );
        return week;
      })
      .sort((weekA, weekB) => {
        return Number(weekA.id.split(' ')[1]) - Number(weekB.id.split(' ')[1]);
      });

    this.dateRange.controls.startingWeek.enable();
  }

  public onStartingWeekSelected(): void {
    const startingWeek = Number(
      this.dateRange.value.startingWeek.id.split(' ')[1]
    );

    this.selectedTeamChallengePossibleEndingWeeks = this.selectedTeamChallengeWeeks.filter(
      (week) => Number(week.id.split(' ')[1]) >= startingWeek
    );

    this.dateRange.controls.endingWeek.enable();
  }
}
