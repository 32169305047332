import { Deserializable } from './deserializable.model';

export interface IWhiteLabelDetailsInitializer {
  id?: string;
  logo?: string;
  color?: string;
  buttonColor?: string;
  buttonText?: string;
  buttonLink?: string;
}

export class WhiteLabelDetails implements Deserializable {
  public id: string = null;
  public logo: string = null;
  public color: string = null;
  public buttonColor: string = null;
  public buttonText: string = null;
  public buttonLink: string = null;

  constructor(initializer?: IWhiteLabelDetailsInitializer) {
    Object.assign(this, initializer);
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  public toObject(): any {
    return Object.assign({}, this);
  }
}
