import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, merge } from 'rxjs';
import { Helpers } from 'src/app/helpers/helpers';
import { Route } from 'src/app/models/cities-challenge/route/route.model';
import { RoutesService } from 'src/app/services/routes/routes.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { CitiesChallengeFeatures } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';

@Component({
  selector: 'app-cities-challenge-form',
  templateUrl: './cities-challenge-form.component.html',
  styleUrls: ['./cities-challenge-form.component.scss'],
})
export class CitiesChallengeFormComponent
  implements AfterContentInit, OnDestroy {
  @Input() citiesChallengeDataForm: UntypedFormGroup;
  @Input() editableChallenge = false;
  @Input() editableRoute = false;
  @Input() createRegions = false;

  private regionUpdateSubscription: Subscription;

  public today = new Date();
  public featureList = Object.values(CitiesChallengeFeatures);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private routesService: RoutesService,
    private adminService: AdminService
  ) {}

  get regions(): FormArray<FormGroup> {
    return this.citiesChallengeDataForm.controls.regions as UntypedFormArray;
  }

  get defaultRoutes(): Array<Route> {
    return this.adminService.routes;
  }

  ngAfterContentInit(): void {
    this.subscribeToRegionUpdates();
  }

  ngOnDestroy(): void {
    this.regionUpdateSubscription.unsubscribe();
  }

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }

  private subscribeToRegionUpdates(): void {
    if (this.regionUpdateSubscription) {
      this.regionUpdateSubscription.unsubscribe();
    }

    this.regionUpdateSubscription = merge(
      ...this.regions.controls.map(
        (regionFormGroup: UntypedFormGroup) => regionFormGroup.valueChanges
      )
    ).subscribe(() => {
      this.calculateTotalDurationAndDistance();
    });
  }

  private calculateTotalDurationAndDistance(): void {
    const totalDurationAndDistance = this.regions.controls.reduce(
      (
        accumulatedDurationAndDistance: {
          totalDuration: number;
          totalDistance: number;
        },
        regionFormGroup: UntypedFormGroup
      ) => ({
        totalDuration: (accumulatedDurationAndDistance.totalDuration +=
          regionFormGroup.controls.duration.value),
        totalDistance: (accumulatedDurationAndDistance.totalDistance += Number(
          (regionFormGroup.controls.route as UntypedFormGroup).controls
            .totalDistance.value
        )),
      }),
      {
        totalDuration: 0,
        totalDistance: 0,
      }
    );

    this.citiesChallengeDataForm.patchValue({
      totalDuration: totalDurationAndDistance.totalDuration,
      totalDistance: Number(totalDurationAndDistance.totalDistance.toFixed(2)),
    });
  }

  public setSelectedRouteInRegion(
    selectedRoute: Route,
    regionIndex: number
  ): void {
    if (!selectedRoute) {
      selectedRoute = new Route({
        name: `Region ${regionIndex + 1}`,
      });
    }

    const regionRouteDataForm = this.routesService.getStandardRouteDataForm();
    this.routesService.setRouteDataFormValues(
      regionRouteDataForm,
      selectedRoute,
      true
    );

    this.regions.setControl(
      regionIndex,
      this.formBuilder.group({
        route: regionRouteDataForm,
        dateRange: new UntypedFormGroup({
          start: new UntypedFormControl(),
          end: new UntypedFormControl(),
        }),
        duration: [0, Validators.min(1)],
      })
    );

    this.subscribeToRegionUpdates();
  }

  public addRegion(): void {
    this.citiesChallengeDataForm.patchValue({
      regionsNumber:
        this.citiesChallengeDataForm.controls.regionsNumber.value + 1,
    });

    const selectedRoute = new Route();
    const regionRouteDataForm = this.routesService.getStandardRouteDataForm();
    this.routesService.setRouteDataFormValues(
      regionRouteDataForm,
      selectedRoute,
      true
    );

    this.regions.push(
      this.formBuilder.group({
        route: regionRouteDataForm,
        dateRange: new UntypedFormGroup({
          start: new UntypedFormControl(),
          end: new UntypedFormControl(),
        }),
        duration: [0, Validators.min(0)],
      }),
      { emitEvent: false }
    );
    this.subscribeToRegionUpdates();
  }

  public removeRegion(): void {
    this.citiesChallengeDataForm.patchValue({
      regionsNumber:
        this.citiesChallengeDataForm.controls.regionsNumber.value - 1,
    });
    this.regions.removeAt(this.regions.length - 1, { emitEvent: false });
    this.subscribeToRegionUpdates();
  }

  public getRegionIndexMinDate(index: number): Date {
    if (this.editableChallenge) {
      if (index > 0) {
        const previousRegionEndDate = this.regions
          .at(index - 1)
          .controls.dateRange.get('end').value;
        if (previousRegionEndDate) {
          return Helpers.addDays(previousRegionEndDate, 1);
        } else {
          return Helpers.addDays(new Date(), 1);
        }
      } else {
        return Helpers.addDays(new Date(), 1);
      }
    } else {
      return this.regions.at(index).controls.dateRange.get('start').value;
    }
  }
}
