import { PDFDocument, PDFForm } from 'pdf-lib';

export abstract class Document {
  public abstract templatePath: string;
  public pdfDoc: PDFDocument | null = null;
  public pdfForm: PDFForm | null = null;
  private companyLogoFieldName: string = 'companyLogo_af_image';
  private whiteLabelLogoFieldName: string = 'whiteLabelLogo_af_image';

  constructor() {}

  /**
   * Get the value of company logo field name
   */
  public getCompanyLogoFieldName(): string {
    return this.companyLogoFieldName;
  }

  /**
   * Get the value of white label logo field name
   */
  public getWhiteLabelLogoFieldName(): string {
    return this.whiteLabelLogoFieldName;
  }

  /**
   * Set Title and get Report File in Uint8Array format
   * @param {string} title - The title to set to the report
   * @return {Promise<Uint8Array>} - The promise with the report document in Uint8Array format
   */
  public async setTitleAndGetReportFile(title: string): Promise<Uint8Array> {
    // Set fields to read only
    this.pdfForm.getFields().map((field) => field.enableReadOnly());
    // Set document title
    this.pdfDoc.setTitle(title);

    const pdfToSave = await this.pdfDoc.copy();
    // save and return
    const pdfBytes = await pdfToSave.save();

    return pdfBytes;
  }

  /**
   * Set Title and get Report File in Uint8Array format
   * @param {string} title - The title to set to the report
   * @return {Promise<Blob>} - The promise with the report document in Blob format
   */
  public async generateBlobFile(title: string): Promise<Blob> {
    const pdfBytes = await this.setTitleAndGetReportFile(title);
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }
}
