<div class="container" fxLayout.xs="column space-between" fxLayout.sm="column space-between">
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">Import Cities</h1>
  </div>
  <form [formGroup]="fileDataForm">
    <input formControlName="file" type="file" accept=".xls,.xlsx" (change)="onFileChange($event)">
  </form>
  <div *ngIf="showTable">
    <table mat-table [dataSource]="citiesDataSource" matSort>
      <!-- Flag Column -->
      <ng-container matColumnDef="flag">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Flag</th>
        <td mat-cell *matCellDef="let city"><img height="24px" width="32px"
            src="assets/countries/flags/1x1/{{ city.countryAlphaCode.toLowerCase() }}.svg" alt="{{ city.name }} Flag" /></td>
      </ng-container>
      <!-- Language Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let city">
          {{ city.name }}
        </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="latitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Latitude</th>
        <td mat-cell *matCellDef="let city">
          {{ city.latitude }}
        </td>
      </ng-container>
      <!-- Longitude Column -->
      <ng-container matColumnDef="longitude">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Longitude</th>
        <td mat-cell *matCellDef="let city">
          {{ city.longitude }}
        </td>
      </ng-container>

      <!-- Country Alpha Code Column -->
      <ng-container matColumnDef="countryAlphaCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country Code</th>
        <td mat-cell *matCellDef="let city">
          {{ city.countryAlphaCode }}
        </td>
      </ng-container>

      <!-- Translation En Column -->
      <ng-container matColumnDef="translationEn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>English</th>
        <td mat-cell *matCellDef="let city">
          {{ city.en }}
        </td>
      </ng-container>

      <!-- Translation De Column -->
      <ng-container matColumnDef="translationDe">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>German</th>
        <td mat-cell *matCellDef="let city">
          {{ city.de }}
        </td>
      </ng-container>

      <!-- Delete Column -->
      <!-- <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td
          mat-cell
          *matCellDef="let translation"
          (click)="removeTranslation(translation.language)"
        >
          <mat-icon>delete_outline</mat-icon>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="citiesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: citiesColumns"></tr>
    </table>
    <button mat-raised-button (click)="import()">
      Import
    </button>
  </div>
  <div *ngIf="showImportProgress">
    <span>Importing city: {{ importingCityName }} ({{ importingIndex + 1 }} / {{ citiesFileData.length }})</span>
    <mat-progress-bar mode="determinate" [value]="importProgress"></mat-progress-bar>
  </div>
  <div class="spinner-container" *ngIf="showSpinner">
    <p>{{ 'Creating city' | translate }}</p>
    <mat-spinner></mat-spinner>
  </div>
</div>
