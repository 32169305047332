<div mat-dialog-title>
  <div class="top">
    <h1 class="title">{{ 'ADD_TEAM_CHALLENGE_TITLE' | translate }}</h1>
  </div>
</div>
<div mat-dialog-content
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <form [formGroup]="teamChallengeForm">
    <mat-form-field>
      <mat-label>{{ 'ADD_TEAM_CHALLENGE_ID' | translate }}</mat-label>
      <input matInput formControlName="challengeId" />
      <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_PERSONAL_CHALLENGE_NAME' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>

    <div class="challenge-details">
      <mat-form-field>
        <mat-label>{{ 'ADD_TEAM_CHALLENGE_PARTICIPANTS_PER_TEAM' | translate }}</mat-label>
        <input type="number" matInput formControlName="participantsPerTeam" />
        <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let challengeType of teamChallengeTypesNames; let i = index"
            value="{{ challengeType }}"
          >
            {{ challengeType }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <form [formGroup]="teamsDataForm">
    <div class="points-sources">
      <h3>Teams</h3>
      <mat-form-field>
        <mat-label>{{ 'ADMIN_TEAMS_TEAMS' | translate }}</mat-label>
        <mat-select formControlName="teamId">
          <mat-option *ngFor="let team of selectableTeams" [value]="team.id" [disabled]="challengeTeams.includes(team)">
            {{ team.name }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        [disabled]="!teamsDataForm.valid"
        (click)="addTeam()"
      >
        {{ 'ADD_TEAM_CHALLENGE_ADD_TEAM_BUTTON' | translate }}
      </button>

      <table
        mat-table
        [dataSource]="teamsDataSource"
        matSort
        *ngIf="challengeTeams.length !== 0"
      >
        <!-- Id Column -->
        <ng-container matColumnDef="avatar">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let team">
            <img
              *ngIf="team.avatar !== 'assets/img/move-me-new.png'"
              src="{{ team.avatar }}"
              alt="avatar"
            />
            <div *ngIf="team.avatar === 'assets/img/move-me-new.png'">
              <mat-icon>do_not_disturb</mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let team">
            {{ team.name }}
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let team" (click)="removeTeam(team.id)">
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="teamsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: teamsColumns"></tr>
      </table>
      <div *ngIf="challengeTeams.length === 0">
        <span>{{ 'ADD_TEAM_CHALLENGE_ADD_TEAM_FOR_THIS_CHALLENGE' | translate }}</span>
      </div>
    </div>
  </form>

  <form [formGroup]="teamChallengePointsDataForm">
    <div class="rewards">
      <h3>{{ 'ADD_TEAM_CHALLENGE_POINTS_AWARDED' | translate }}</h3>
      <mat-form-field>
        <mat-label>{{ 'ADD_TEAM_CHALLENGE_POSITION' | translate }}</mat-label>
        <input type="number" matInput formControlName="position" />
        <mat-error *ngIf="teamChallengePointsDataForm.controls.position.errors?.required">{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
        <mat-error *ngIf="teamChallengePointsDataForm.controls.position.errors?.pattern">{{ 'Enter a valid position' | translate }}</mat-error>
        <mat-error *ngIf="teamChallengePointsDataForm.controls.position.errors?.notUnique">{{ 'This position already has a reward' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ADD_TEAM_CHALLENGE_POINTS' | translate }}</mat-label>
        <input type="number" matInput formControlName="points" />
        <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        [disabled]="!teamChallengePointsDataForm.valid"
        (click)="addPointsAwarded()"
      >
        {{ 'ADD_TEAM_CHALLENGE_ADD_REWARD_BUTTON' | translate }}
      </button>

      <table
        mat-table
        [dataSource]="pointsAwardedDataSource"
        matSort
        *ngIf="pointsAwarded.length !== 0"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
          <td mat-cell *matCellDef="let pointsAward">
            {{ pointsAward.position }}
          </td>
        </ng-container>

        <!-- Points Column -->
        <ng-container matColumnDef="points">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Points</th>
          <td mat-cell *matCellDef="let pointsAward">
            {{ pointsAward.points }}
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let pointsAward"
            (click)="removePointsAward(pointsAward)"
          >
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="pointsAwardedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pointsAwardedColumns"></tr>
      </table>
      <div *ngIf="pointsAwarded.length === 0">
        <span>{{ 'ADD_TEAM_CHALLENGE_ADD_POINT_AWARD' | translate }}</span>
      </div>
    </div>
  </form>
</div>
  <div mat-dialog-actions>
    <button mat-button (click)="close()">{{ 'CANCEL' | translate }}</button>
    <button mat-button [disabled]="!teamChallengeForm.valid" (click)="confirm()">
      {{ 'CONFIRM' | translate }}
      <mat-spinner [diameter]="16" *ngIf="showSpinner"></mat-spinner>
    </button>
  </div>
