<mat-card class="forecast-graph-container">
  <mat-card-header>
    <mat-card-title>
      {{ forecastGraph.title | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content #citiesListCardContent>
    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
      style="width: 100%; height: 400px; display: block"></highcharts-chart>
  </mat-card-content>
</mat-card>