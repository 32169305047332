import { NotificationInterface } from 'src/app/interfaces/notification.interface';

export class Notification {
  public id: string = null;
  public title: Map<string, string>;
  public message: Map<string, string>;
  public read = false;
  public showMessage = false;
  public link: string = null;
  public sender: string;
  public recipientCompanyIds: Set<string>;

  constructor(iNotification?: NotificationInterface) {
    Object.assign(this, iNotification);
    if (iNotification) {
      this.title = iNotification.title
        ? new Map<string, string>(Object.entries(iNotification.title))
        : new Map<string, string>();
      this.message = iNotification.message
        ? new Map<string, string>(Object.entries(iNotification.message))
        : new Map<string, string>();
      this.recipientCompanyIds = iNotification.recipientCompanyIds
        ? new Set<string>(iNotification.recipientCompanyIds)
        : new Set<string>();
    }
  }

  public toObject(): NotificationInterface {
    const title = Object.fromEntries(this.title);
    const message = Object.fromEntries(this.message);
    const recipientCompanyIds = Array.from(this.recipientCompanyIds);
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { title, message, recipientCompanyIds });
  }
}
