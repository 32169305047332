import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Helpers } from 'src/app/helpers/helpers';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { Location } from 'src/app/models/location.model';
import { Team as TeamV2 } from 'src/app/models/team-challenge/team/team.model';
import { TeamChallengeService } from 'src/app/services/team-challenge/team-challenge.service';

export interface ITeamTableElement {
  companyId?: string;
  teamChallengeId?: string;
  locations?: Array<Location>;
  showDelete?: boolean;
  showSave?: boolean;
}

@Component({
  selector: 'app-teams-table',
  templateUrl: './teams-table.component.html',
  styleUrls: ['./teams-table.component.scss'],
})
export class TeamsTableComponent implements OnChanges, AfterViewInit {
  @Input() teamForms: UntypedFormArray;
  @Input() teamsTableElement: ITeamTableElement;
  public panelOpenState = false;
  public displayedColumns: Array<string>;
  public dataSource: MatTableDataSource<UntypedFormGroup> = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private teamChallengeService: TeamChallengeService) {}

  ngOnChanges(): void {
    this.initTable();
  }

  ngAfterViewInit(): void {
    this.initSortAndPaginator();
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public initTable(): void {
    // Add all the columns in the right order
    this.displayedColumns = [
      'avatar',
      'name',
      'color',
      'location',
      'delete',
      'save',
    ];

    // Remove location column if there are not location names or teams with locations
    if (
      !this.teamsTableElement.locations ||
      this.teamsTableElement.locations.length === 0
    ) {
      this.displayedColumns.splice(
        this.displayedColumns.findIndex((column) => column === 'location'),
        1
      );
    }

    // Remove delete button
    if (!this.teamsTableElement.showDelete) {
      this.displayedColumns.splice(
        this.displayedColumns.findIndex((column) => column === 'delete'),
        1
      );
    }

    // Remove save button
    if (!this.teamsTableElement.showSave) {
      this.displayedColumns.splice(
        this.displayedColumns.findIndex((column) => column === 'save'),
        1
      );
    }

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(
      this.teamForms.controls as Array<UntypedFormGroup>
    );

    // Redefine the filter method
    this.dataSource.filterPredicate = (
      data: UntypedFormGroup,
      filter: string
    ): boolean => {
      if (this.displayedColumns.includes('location')) {
        return (
          data.controls.name.value.trim().toLowerCase().indexOf(filter) !==
            -1 ||
          (data.controls.location.value &&
            data.controls.location.value
              .trim()
              .toLowerCase()
              .indexOf(filter) !== -1)
        );
      } else {
        return (
          data.controls.name.value.trim().toLowerCase().indexOf(filter) !== -1
        );
      }
    };

    // Redefine the sort accessor method
    this.dataSource.sortingDataAccessor = (
      data: UntypedFormGroup,
      sortHeaderId: string
    ): string | number => data.controls[sortHeaderId].value;

    this.initSortAndPaginator();
  }

  private initSortAndPaginator(): void {
    // Initialize sort and paginator
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public onChangeColor(event: string, formControl: UntypedFormGroup): void {
    formControl.get('color').setValue(event);
  }

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }

  public removeTeam(teamForm: UntypedFormGroup): void {
    const formIndex = this.teamForms.controls.findIndex(
      (form: UntypedFormGroup) =>
        form.controls.name.value === teamForm.controls.name.value
    );

    if (teamForm.controls.id.value) {
      this.teamChallengeService
        .removeTeamWithConfirmation(
          this.teamsTableElement.companyId,
          this.teamsTableElement.teamChallengeId,
          teamForm.controls.id.value
        )
        .afterClosed()
        .subscribe((result: ConfirmationDialogResponse) => {
          if (
            result.code ===
            ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly
          ) {
            this.teamForms.removeAt(formIndex);
            this.initSortAndPaginator();
          }
        });
    } else {
      this.teamForms.removeAt(formIndex);
      this.initSortAndPaginator();
    }
  }

  public saveTeam(teamForm: UntypedFormGroup): void {
    this.teamChallengeService
      .updateTeamV2(
        this.teamsTableElement.companyId,
        this.teamsTableElement.teamChallengeId,
        new TeamV2(teamForm.value)
      )
      .then(() => {
        teamForm.markAsPristine();
      });
  }
}
