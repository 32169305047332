import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';

@Component({
  selector: 'app-companies-table',
  templateUrl: './companies-table.component.html',
  styleUrls: ['./companies-table.component.scss'],
})
export class CompaniesTableComponent implements OnChanges, AfterViewInit {
  @Input() companies: Array<Company>;
  @ViewChild(MatSort) sort: MatSort;

  public companyDataSource: MatTableDataSource<Company> = new MatTableDataSource();
  public companyColumns: Array<string> = [
    'avatar',
    'id',
    'name',
    'users',
    'availableLicenses',
  ];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companies) {
      this.refreshDataSource();
    }
  }

  ngAfterViewInit(): void {
    this.companyDataSource.sort = this.sort;
  }

  private refreshDataSource(): void {
    this.companyDataSource = new MatTableDataSource(this.companies);
    this.companyDataSource.sortingDataAccessor = (
      data,
      sortHeaderId
    ): string | number =>
      sortHeaderId === 'users' ? data.users.length : data[sortHeaderId];
  }

  public goToCompany(companyId: string): void {
    this.router.navigate(['company-info', companyId], {
      relativeTo: this.route,
    });
  }
}
