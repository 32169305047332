import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-challenge-header',
  templateUrl: './challenge-header.component.html',
  styleUrls: ['./challenge-header.component.scss'],
})
export class ChallengeHeaderComponent {
  @Input() challengeName: string = '';
  // Challenge duration in days
  @Input() challengeDuration: number = 0;
  @Input() challengeIcon: string = '';

  constructor(private translateService: TranslateService) {}

  get lang(): string {
    return this.translateService.currentLang;
  }
}
