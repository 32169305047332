import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamSchema } from 'src/app/models/team-schema.model';

@Component({
  selector: 'app-add-random-teams-dialog',
  templateUrl: './add-random-teams-dialog.component.html',
  styleUrls: ['./add-random-teams-dialog.component.scss'],
})
export class AddRandomTeamsDialogComponent implements OnInit {
  public teamsAmount = 0;

  constructor(
    public dialogRef: MatDialogRef<AddRandomTeamsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public availableTeams: Array<TeamSchema>
  ) {}

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }
}
