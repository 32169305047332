<div mat-dialog-title class="dialog-title">
  <h1>{{ 'EMAILS.NEW_EMAIL' | translate }}</h1>
  <button mat-icon-button color="secondary" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="dialog-content">
  <form [formGroup]="emailFormGroup">
    <mat-form-field>
      <mat-label>{{ 'EMAILS.subject.LABEL' | translate }}</mat-label>
      <input type="text" matInput formControlName="subject" />
      <mat-error>{{ 'EMAILS.subject.REQUIRED_ERROR' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'EMAILS.body.LABEL' | translate }}</mat-label>
      <textarea
        matInput
        formControlName="body"
        cdkTextareaAutosize
        cdkAutosizeMinRows="8"
        cdkAutosizeMaxRows="12"
        >
      </textarea>
      <mat-error>{{ 'EMAILS.body.REQUIRED_ERROR' | translate }}</mat-error>
    </mat-form-field>
  </form>

  <div class="attachments">
    <mat-card *ngFor="let attachment of data.attachments; let i = index">
      <div class="title">
        <p>{{ attachment.title }}</p>
      </div>
      <div class="buttons-container">
        <button mat-icon-button aria-label="Open in browser" (click)="openBlob(attachment.blob)">
          <mat-icon>file_open</mat-icon>
        </button>
        <a #downloadFile [href]="attachment.fileURL" [download]="attachment.title"></a>
        <button (click)="downloadFile.click()" mat-icon-button aria-label="Download from browser" *ngIf="attachment.fileURL">
          <mat-icon>cloud_download</mat-icon>
        </button>
        <button mat-icon-button aria-label="Discard file" (click)="discardFile(i)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button color="primary" mat-raised-button (click)="sendEmail()" [disabled]="showSpinner">
    {{ 'EMAILS.SEND' | translate }}
    <mat-spinner diameter="20" *ngIf="showSpinner"></mat-spinner>
  </button>
</div>
