import { ChallengeTypes } from 'src/app/models/challenge-types.enum';
import { Deserializable } from 'src/app/models/deserializable.model';

export interface IPersonalChallengeSchemaInitializer {
  id?: string;
  initialPoints?: number;
  name?: string;
  type?: ChallengeTypes;
  maxLevel?: number;
  minLevel?: number;
  pointsToLevelUp?: number;
  pointsToHoldLevel?: number;
  initialLevel?: number;
  pointsSourceIds?: Array<string>;
  rewards?: object;
  levelDuration?: number;
  canAscend?: boolean;
  canDescend?: boolean;
  hasFixedDate?: boolean;
}

export class PersonalChallengeSchema implements Deserializable {
  public id = '';
  public initialPoints = 0;
  public name = '';
  public type: ChallengeTypes = ChallengeTypes.Walk;
  public maxLevel = 0;
  public minLevel = 0;
  public pointsToLevelUp = 0;
  public pointsToHoldLevel = 0;
  public initialLevel = 0;
  public pointsSourceIds: Set<string> = new Set<string>();
  public rewards: Map<string, number> = new Map<string, number>();
  public levelDuration = 7;
  public canAscend = true;
  public canDescend = true;
  public hasFixedDate = false;

  constructor(initializer?: IPersonalChallengeSchemaInitializer) {
    Object.assign(this, initializer);
    if (initializer && initializer.pointsSourceIds) {
      this.pointsSourceIds = new Set(initializer.pointsSourceIds);
    }
    if (initializer && initializer.rewards) {
      this.rewards = new Map(Object.entries(initializer.rewards));
    }
  }

  public toObject(): object {
    const pointsSourceIds = Array.from(this.pointsSourceIds);
    const returnObject = Object.assign({}, this);
    const rewards = Object.fromEntries(this.rewards);
    return Object.assign(returnObject, { pointsSourceIds, rewards });
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    this.rewards = input.rewards
      ? new Map(Object.entries(input.rewards))
      : new Map<string, number>();
    this.pointsSourceIds = input.pointsSourceIds
      ? new Set(input.pointsSourceIds)
      : new Set<string>();
    return this;
  }
}
