import { ConfirmationDialogDataReturnCodes } from './confirmation-dialog-data-return-codes.enum';

export class ConfirmationDialogResponse {
  public code: ConfirmationDialogDataReturnCodes;
  public error: any;

  constructor({ code = null, error = null }) {
    this.code = code;
    this.error = error;
  }
}
