export interface ICompanyTrainingDataInitializer {
  steps: number;
  country: string;
  nickname: string;
  avatar: string;
  location: string;
  locationIds: Array<string>;
  id: string;
}

export class CompanyTrainingData {
  public steps: number = null;
  public country: string = null;
  public nickname: string = null;
  public avatar: string = null;
  public location: string = null;
  public locationIds: Array<string> = [];
  public id: string = null;

  constructor(initializer?: ICompanyTrainingDataInitializer) {
    if (initializer) {
      Object.assign(this, initializer);
      this.locationIds = initializer.locationIds
        ? initializer.locationIds
        : this.location
        ? [this.location]
        : [];
    }
  }

  public toObject(): ICompanyTrainingDataInitializer {
    return Object.assign({}, this);
  }
}
