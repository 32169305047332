<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'Add White Label' | translate }}</h1>
  </div>
  <form [formGroup]="whiteLabelDataForm" (ngSubmit)="addWhiteLabel($event)">
    <mat-form-field>
      <mat-label>{{ 'Name' | translate }}</mat-label>
      <input matInput required formControlName="name" />
      <mat-error *ngIf="whiteLabelDataForm.controls.name.errors?.maxlength">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.name.MAX_LENGTH_ERROR' | translate }}</mat-error>
      <mat-error *ngIf="whiteLabelDataForm.controls.name.errors?.pattern">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.name.PATTERN_ERROR' | translate }}</mat-error>
      <mat-error *ngIf="whiteLabelDataForm.controls.name.errors?.required">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.name.REQUIRED_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'Id' | translate }}</mat-label>
      <input matInput required formControlName="id" />
    </mat-form-field>
    <mat-form-field>
      <ngx-mat-file-input
        accept="image/jpeg, image/jpg, image/png, image/svg+xml"
        formControlName="logoImg"
        [placeholder]="'Add White Label Logo'"
      ></ngx-mat-file-input>
      <mat-icon matSuffix>attach_file</mat-icon>
      <mat-error>{{ 'Error' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="color-input">
      <mat-label>{{ 'Color' | translate }}</mat-label>
      <input matInput formControlName="color" (colorPickerChange)="onChangeWhiteLabelColor($event)" [(colorPicker)]="this.whiteLabelDataForm.value.color" [style.background]="this.whiteLabelDataForm.value.color"/>
    </mat-form-field>
    <div class="white-label-logo-container" fxLayout.xs="center" *ngIf="showWhiteLabelLogo">
      <img [src]="whiteLabelLogo" alt="Select a white label to see its logo" />
    </div>

    <mat-checkbox color="primary" formControlName="button">{{ 'Add Button to Home' | translate }}</mat-checkbox>

    <div class="white-label-button-container" *ngIf="this.whiteLabelDataForm.value.button">
      <form [formGroup]="whiteLabelButtonDataForm">
        <mat-form-field>
          <mat-label>{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonColor.LABEL' | translate }}</mat-label>
          <input matInput formControlName="color" (colorPickerChange)="onChangeWhiteLabelButtonColor($event)" [(colorPicker)]="this.whiteLabelButtonDataForm.value.color" [style.background]="this.whiteLabelButtonDataForm.value.color"/>
          <mat-error>{{ 'Error' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.LABEL' | translate }}</mat-label>
          <input matInput formControlName="text" />
          <mat-error *ngIf="whiteLabelButtonDataForm.controls.text.errors?.maxlength">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.MAXLENGTH_ERROR' | translate }}</mat-error>
          <mat-error *ngIf="whiteLabelButtonDataForm.controls.text.errors?.pattern">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.PATTERN_ERROR' | translate }}</mat-error>
          <mat-error *ngIf="whiteLabelButtonDataForm.controls.text.errors?.required">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.REQUIRED_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonLink.LABEL' | translate }}</mat-label>
          <input matInput formControlName="link" />
          <mat-error *ngIf="whiteLabelButtonDataForm.controls.link.errors?.pattern">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonLink.PATTERN_ERROR' | translate }}</mat-error>
          <mat-error *ngIf="whiteLabelButtonDataForm.controls.link.errors?.required">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonLink.REQUIRED_ERROR' | translate }}</mat-error>
        </mat-form-field>
      </form>
    </div>
    <h3 class="report-template-title">Distance Challenge Report Template</h3>
    <div class="report-template-container">
      <div class="report-template-input">
        <mat-form-field>
          <ngx-mat-file-input
            accept="application/pdf"
            formControlName="distanceReportFile"
            [placeholder]="'Add a distance challenge report template'"

          ></ngx-mat-file-input>
          <mat-icon matSuffix>attach_file</mat-icon>
          <mat-error>{{ 'Error' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="report-template-pdf-viewer" [ngStyle]="{ 'height': this.whiteLabelDataForm.controls.distanceReportFileSource.value ? '500px' : 'inherit' }">
        <pdf-viewer *ngIf="this.whiteLabelDataForm.controls.distanceReportFileSource.value" [src]="this.whiteLabelDataForm.controls.distanceReportFileSource.value" [autoresize]="true" [render-text]="true" [original-size]="false" [zoom-scale]="'page-width'" style="max-width: 50%;">
        </pdf-viewer>
      </div>
    </div>
    <h3 class="report-template-title">Team Challenge Report Template</h3>
    <div class="report-template-container">
      <div class="report-template-input">
        <mat-form-field>
          <ngx-mat-file-input
            accept="application/pdf"
            formControlName="teamReportFile"
            [placeholder]="'Add a team challenge report template'"

          ></ngx-mat-file-input>
          <mat-icon matSuffix>attach_file</mat-icon>
          <mat-error>{{ 'Error' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="report-template-pdf-viewer" [ngStyle]="{ 'height': this.whiteLabelDataForm.controls.teamReportFileSource.value ? '500px' : 'inherit' }">
        <pdf-viewer *ngIf="this.whiteLabelDataForm.controls.teamReportFileSource.value" [src]="this.whiteLabelDataForm.controls.teamReportFileSource.value" [autoresize]="true" [render-text]="true" [original-size]="false" [zoom-scale]="'page-width'" style="max-width: 50%;">
        </pdf-viewer>
      </div>
    </div>
    <div class="buttons">
      <button mat-raised-button [disabled]="!whiteLabelDataForm.valid || (this.whiteLabelDataForm.value.button && !whiteLabelButtonDataForm.valid)">
        {{ 'Create White Label' | translate }}
      </button>
    </div>
  </form>
  <div class="creating-white-label-spinner-container" *ngIf="showSpinner">
    <p>{{ 'Creating White Label' | translate }}</p>
    <mat-spinner></mat-spinner>
  </div>
</div>
