import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import {
  CITIES_CHALLENGE_TYPES,
  CitiesChallenge,
  ICitiesChallengeInitializer,
} from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
// eslint-disable-next-line max-len
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Region } from 'src/app/models/cities-challenge/region/region.model';
import { RoutesService } from '../routes/routes.service';
import { Helpers } from 'src/app/helpers/helpers';
import { CITIES_CHALLENGE_ROUTE_MAP_PATH } from 'src/app/constants/constants';
import { Route } from 'src/app/models/cities-challenge/route/route.model';
import { CitiesChallengePerformance } from 'src/app/models/cities-challenge/cities-challenge-performance/cities-challenge-performance.model';
import {
  CitiesChallengeDayPerformance,
  ICitiesChallengeDayPerformance,
} from 'src/app/models/cities-challenge/cities-day-challenge-performance/cities-challenge-day-performance.model';

@Injectable({
  providedIn: 'root',
})
export class CitiesChallengeService {
  constructor(
    private firebaseService: FirebaseService,
    private routesService: RoutesService,
    private formBuilder: UntypedFormBuilder
  ) {}

  public getStandardCitiesChallengeFromFirebase(
    challengeId: string
  ): Promise<CitiesChallenge> {
    return this.firebaseService
      .getStandardCitiesChallenge(challengeId)
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          return new CitiesChallenge(
            documentSnapshot.data() as ICitiesChallengeInitializer
          );
        } else {
          return Promise.reject(`NO CITY CHALLENGE WITH ID: ${challengeId}`);
        }
      })
      .catch((error) => {
        console.log('getStandardCitiesChallengeFromFirebase - error: ', error);
        return Promise.reject(error);
      });
  }

  public getCompanyCityChallengeFromFirebase(
    companyId: string,
    challengeId: string
  ): Promise<CitiesChallenge> {
    return this.firebaseService
      .getCompanyCitiesChallenge(companyId, challengeId)
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          return new CitiesChallenge(
            documentSnapshot.data() as ICitiesChallengeInitializer
          );
        } else {
          return Promise.reject(
            `NO CITY CHALLENGE WITH ID: ${challengeId} IN COMPANY ${companyId}`
          );
        }
      })
      .catch((error) => {
        console.log('getCompanyCityChallengeFromFirebase - error: ', error);
        return Promise.reject(error);
      });
  }

  public getNextCitiesChallenge(companyId: string): Promise<CitiesChallenge> {
    return this.firebaseService
      .getNextCitiesChallenge(companyId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return new CitiesChallenge(
            querySnapshot.docs[0].data() as ICitiesChallengeInitializer
          );
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log('getNextCitiesChallenge - error: ', error);
        return Promise.reject(error);
      });
  }

  public getCitiesChallenge(
    companyId: string,
    challengeId: string
  ): Promise<CitiesChallenge> {
    return this.firebaseService
      .getCitiesChallenge(companyId, challengeId)
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          return new CitiesChallenge(
            docSnapshot.data() as ICitiesChallengeInitializer
          );
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log('getNextCitiesChallenge - error: ', error);
        return Promise.reject(error);
      });
  }

  public createCompanyCityChallengeId(companyId: string): string {
    return this.firebaseService.getAutomaticIdInCompanySubCollection(
      companyId,
      FirebaseConstants.CitiesChallengesCollection
    );
  }

  public createCompanyCityChallenge(
    companyId: string,
    challenge: CitiesChallenge
  ): Promise<void> {
    if (!challenge.id) {
      challenge.id = this.firebaseService.getAutomaticIdInCompanySubCollection(
        companyId,
        FirebaseConstants.CitiesChallengesCollection
      );
    }
    return this.firebaseService.createCompanyCitiesChallenge(
      companyId,
      challenge
    );
  }

  public getStandardCitiesChallengeDataForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      expectedDailySteps: [
        8000,
        [Validators.required, Validators.min(4000), Validators.max(12000)],
      ],
      features: [],
      totalDistance: [{ value: 0, disabled: true }, Validators.required],
      totalDuration: [{ value: 0, disabled: true }, Validators.required],
      regions: this.formBuilder.array([]),
      regionsNumber: [1],
    });
  }

  public setCitiesChallengeDataFormValues(
    citiesChallengeDataForm: UntypedFormGroup,
    citiesChallenge: CitiesChallenge
  ): void {
    citiesChallengeDataForm.patchValue({
      id: citiesChallenge.id,
      name: citiesChallenge.name,
      expectedDailySteps: citiesChallenge.expectedDailySteps,
      features: Array.from(citiesChallenge.features),
      totalDistance: citiesChallenge.totalDistance,
      totalDuration: citiesChallenge.getDaysDuration(),
      regionsNumber: citiesChallenge.regions.length,
    });

    citiesChallenge.regions.forEach((region: Region) => {
      const standardRouteDataForm = this.routesService.getStandardRouteDataForm();

      this.routesService.setRouteDataFormValues(
        standardRouteDataForm,
        region.route,
        true
      );

      (citiesChallengeDataForm.controls.regions as UntypedFormArray).push(
        this.formBuilder.group({
          route: standardRouteDataForm,
          dateRange: new UntypedFormGroup({
            start: new UntypedFormControl(new Date(region.startDate)),
            end: new UntypedFormControl(new Date(region.endDate)),
          }),
          duration: [region.duration, Validators.min(0)],
        })
      );
    });
  }

  public disableForm(challengeDataForm: UntypedFormGroup): void {
    challengeDataForm.controls.name.disable({ emitEvent: false });
    challengeDataForm.controls.expectedDailySteps.disable({ emitEvent: false });

    for (let i = 0; i < challengeDataForm.controls.regionsNumber.value; i++) {
      (challengeDataForm.controls.regions as UntypedFormArray)
        .at(i)
        .disable({ emitEvent: false });
    }
  }

  public enableFormBasedOnType(
    challengeDataForm: UntypedFormGroup,
    challengeType: string
  ): void {
    challengeDataForm.controls.name.enable({ emitEvent: false });
    challengeDataForm.controls.expectedDailySteps.enable({ emitEvent: false });

    for (let i = 0; i < challengeDataForm.controls.regionsNumber.value; i++) {
      (challengeDataForm.controls.regions as FormArray<FormGroup>)
        .at(i)
        .controls.dateRange.enable({ emitEvent: false });
      if (challengeType !== CITIES_CHALLENGE_TYPES[0]) {
        (challengeDataForm.controls.regions as FormArray<FormGroup>)
          .at(i)
          .enable({ emitEvent: false });
        ((challengeDataForm.controls.regions as FormArray<FormGroup>).at(i)
          .controls.route as UntypedFormGroup).controls.totalDistance.disable({
          emitEvent: false,
        });
      }
    }
  }

  public async setCompanyCitiesChallenge(
    companyId: string,
    citiesChallengeId: string,
    citiesChallengeDataForm: UntypedFormGroup,
    citiesChallengeType: string
  ): Promise<void> {
    const citiesChallenge = await this.createCitiesChallengeObject(
      citiesChallengeId,
      citiesChallengeDataForm,
      citiesChallengeType,
      companyId
    );

    return this.createCompanyCityChallenge(companyId, citiesChallenge);
  }

  private async createCitiesChallengeObject(
    citiesChallengeId: string,
    citiesChallengeDataForm: UntypedFormGroup,
    citiesChallengeType: string,
    companyId: string = null
  ): Promise<CitiesChallenge> {
    const regions = await this.createRegions(
      citiesChallengeDataForm.controls.regions as UntypedFormArray,
      citiesChallengeId,
      companyId
    );

    const challengeFeatures = citiesChallengeDataForm.controls.features.value
      ? [...citiesChallengeDataForm.controls.features.value]
      : [];

    const citiesChallenge = new CitiesChallenge({
      id: citiesChallengeId,
      name: citiesChallengeDataForm.controls.name.value,
      startDate: regions[0].startDate,
      endDate: regions[regions.length - 1].endDate,
      features: [citiesChallengeType, ...challengeFeatures],
      totalDistance: citiesChallengeDataForm.controls.totalDistance.value,
      expectedDailySteps:
        citiesChallengeDataForm.controls.expectedDailySteps.value,
      regions: regions.map((region) => region.toObject()),
      totalPerformance: new CitiesChallengePerformance().toObject(),
    });

    return Promise.resolve(citiesChallenge);
  }

  private async createRegions(
    regionsDataForm: FormArray<UntypedFormGroup>,
    citiesChallengeId: string,
    companyId: string = null
  ): Promise<Array<Region>> {
    const regions = [];
    for (
      let regionIndex = 0;
      regionIndex < regionsDataForm.length;
      regionIndex++
    ) {
      regions.push(
        new Region({
          route: (
            await this.createRouteOnCitiesChallenge(
              regionsDataForm.at(regionIndex).controls
                .route as UntypedFormGroup,
              regionIndex,
              citiesChallengeId,
              companyId
            )
          ).toObject(),
          duration: regionsDataForm.at(regionIndex).controls.duration.value,
          startDate: Helpers.createISOStartDateTime(
            regionsDataForm.at(regionIndex).controls.dateRange.value.start
          ),
          endDate: Helpers.createISOEndDateTime(
            regionsDataForm.at(regionIndex).controls.dateRange.value.end
          ),
        })
      );
    }
    return Promise.resolve(regions);
  }

  private async createRouteOnCitiesChallenge(
    routeDataForm: UntypedFormGroup,
    index = 0,
    citiesChallengeId: string,
    companyId: string = null
  ): Promise<Route> {
    const route = this.routesService.createRouteObject(
      null,
      routeDataForm,
      routeDataForm.controls.cityMarkerPoints as UntypedFormArray
    );

    // UPLOAD MAP
    const imageFile = routeDataForm.controls.mapFile.value
      ? routeDataForm.controls.mapFile.value.files[0]
      : null;
    if (imageFile && companyId) {
      const imagePath = CITIES_CHALLENGE_ROUTE_MAP_PATH.replace(
        'COMPANY_ID',
        companyId
      )
        .replace('CITIES_CHALLENGE_ID', citiesChallengeId)
        .replace('ROUTE_ID', String(index));
      const mapUrl = await this.routesService.uploadRouteImage(
        imageFile,
        imagePath
      );
      route.map = mapUrl;
    }
    return Promise.resolve(route);
  }

  public getCitiesChallengeDailyPerformances(
    companyId: string,
    citiesChallengeId: string
  ): Promise<Array<CitiesChallengeDayPerformance>> {
    return this.firebaseService
      .getCitiesChallengeDailyPerformances(companyId, citiesChallengeId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs.map(
            (docSnapshot) =>
              new CitiesChallengeDayPerformance(
                docSnapshot.data() as ICitiesChallengeDayPerformance
              )
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('Error - getAllDailyPerformance: ', error);
        return [];
      });
  }

  public getCompanyCitiesChallenges(
    companyId: string
  ): Promise<Array<CitiesChallenge>> {
    return this.firebaseService
      .getCompanyCitiesChallenges(companyId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs
            .map(
              (docSnapshot) =>
                new CitiesChallenge(
                  docSnapshot.data() as ICitiesChallengeInitializer
                )
            )
            .sort((challengeA, challengeB) =>
              challengeA.startDate > challengeB.startDate ? -1 : 1
            );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('getCompanyCitiesChallenges - error: ', error);
        return [];
      });
  }
}
