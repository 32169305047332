<div class="container">
  <div class="top">
    <mat-icon (click)="goBack()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'NEW_TEAM_CHALLENGE_PAGE.TITLE' | translate }}</h1>
  </div>

  <div class="spinner-container" *ngIf="companyLoading || teamChallengeLoading">
    <mat-spinner ></mat-spinner>
  </div>
  <div *ngIf="!companyLoading && company && !teamChallengeLoading">
    <mat-horizontal-stepper labelPosition="bottom" #newTeamChallengeStepper linear="true" class="show-overflow">
      <mat-step>
        <ng-template matStepContent>
          <p class="step-title"> {{  'NEW_TEAM_CHALLENGE_PAGE.FEATURES_SELECTION.TITLE' | translate }}</p>
          <div class="feature-selection-card-container">
            <app-feature-selection-card *ngFor="let feature of features" [featureSelectionCard]="feature" (featureToggled)="toggleFeature($event)"></app-feature-selection-card>
          </div>
        </ng-template>
      </mat-step>
      <mat-step>
        <ng-template matStepContent>
          <p class="step-title"> {{  'NEW_TEAM_CHALLENGE_PAGE.CONFIGURATION.TITLE' | translate }}</p>
          <app-team-challenge-form
            [teamChallengeDataForm]="challengeDataForm"
            [availableLocationLevels]="availableLocationLevels"
            [company]="company"
          ></app-team-challenge-form>
        </ng-template>
      </mat-step>
      <mat-step>
        <ng-template matStepContent>
          <p class="step-title" [innerHtml]="('NEW_TEAM_CHALLENGE_PAGE.SUMMARY.TITLE' | translate)"></p>
          <app-team-challenge-confirmation [teamChallengeForm]="challengeDataForm" [locationsArray]="locationsArray" [locationSelectedName]="locationSelectedName"></app-team-challenge-confirmation>
        </ng-template>
      </mat-step>
    </mat-horizontal-stepper>
    <div class="button-container">
      <button mat-fab color="primary" aria-label="Button to next step" [disabled]="checkFormValidation && !challengeDataForm.valid || showSpinner" (click)="goForth()">
        <mat-icon *ngIf="!showSpinner"> {{ isLastStep ? 'check' : 'chevron_right' }}</mat-icon>
        <mat-spinner *ngIf="showSpinner" diameter="20"></mat-spinner>
      </button>
    </div>
  </div>
</div>
