import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminTabs } from '../admin-tabs.enum';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { TranslateService } from '@ngx-translate/core';
import { CitiesService } from 'src/app/services/cities/cities.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-city-info',
  templateUrl: './city-info.component.html',
  styleUrls: ['./city-info.component.scss'],
})
export class CityInfoComponent implements OnInit, OnDestroy {
  public showLoadingCitySpinner = true;
  public city: City;

  // Mock data only for tests, remove it when creating the CRUD for new city infos
  public cityInfos = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private citiesService: CitiesService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService
  ) {}

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  ngOnInit(): void {
    const cityId = this.route.snapshot.paramMap.get('cityId');

    this.citiesService
      .getCityFromFirebase(cityId)
      .then((city) => {
        this.city = city;
        this.subscriptionService.subscribeToCityInfos(city.id);
        this.subscriptionService.cityInfos.subscribe(
          (cityInfos) => (this.cityInfos = cityInfos)
        );
      })
      .catch((error) => {
        console.log('companyService.getCity - error: ', error);
      })
      .finally(() => {
        this.showLoadingCitySpinner = false;
      });
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeFromCityInfos();
  }

  public returnPage(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Cities } });
  }

  public addCityInfo(): void {
    // Function to add a new city info
    this.citiesService.openEditCityInfoDialog(this.city);
  }
}
