<div class="container" fxLayout.xs="column space-between" fxLayout.sm="column space-between">
  <div *ngIf="!showLoadingCitySpinner">
    <div class="top">
      <mat-icon (click)="returnToAdminCities()">keyboard_backspace</mat-icon>
      <h1 class="title">{{ (cityId ? 'EDIT_CITY.TITLE.EDIT_CITY' : 'EDIT_CITY.TITLE.NEW_CITY') | translate }}</h1>
    </div>
    <form [formGroup]="cityDataForm">
      <mat-form-field>
        <mat-label>{{ 'EDIT_CITY.FORM.name.LABEL' | translate }}</mat-label>
        <input matInput formControlName="name" />
        <mat-error>{{ getFormErrors(cityDataForm.controls.name, 'EDIT_CITY.FORM', 'name') | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'EDIT_CITY.FORM.country.LABEL' | translate }}</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" formControlName="country" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="showCountryName">
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            <img height="24px" width="32px" [src]="getFlagPath(country['alpha-3'])" />
            {{ country.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>{{ getFormErrors(cityDataForm.controls.country, 'EDIT_CITY.FORM', 'country') | translate }} </mat-error>
      </mat-form-field>

      <img height="24px" width="32px"
        src="{{ cityDataForm.controls.country.value && cityDataForm.controls.country.value['alpha-3'] ? getFlagPath(cityDataForm.controls.country?.value['alpha-3']) : getFlagPath('xx')}}" />

      <mat-form-field>
        <mat-label>{{ 'EDIT_CITY.FORM.lat.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="lat" />
        <mat-error>{{ getFormErrors(cityDataForm.controls.lat, 'EDIT_CITY.FORM', 'lat') | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'EDIT_CITY.FORM.lng.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="lng" />
        <mat-error>{{ getFormErrors(cityDataForm.controls.lng, 'EDIT_CITY.FORM', 'lng') | translate }} </mat-error>
      </mat-form-field>

      <form [formGroup]="translationDataForm">
        <div class="translations">
          <h3>{{ 'EDIT_CITY.TRANSLATIONS.TITLE' | translate }}</h3>
          <mat-form-field>
            <mat-label>{{ 'EDIT_CITY.TRANSLATION_FORM.language.LABEL' | translate }}</mat-label>
            <mat-select formControlName="translationLanguage">
              <mat-option *ngFor="let language of availableLanguages" value="{{ language }}">
                {{ 'languages.' + language | translate }}
              </mat-option>
            </mat-select>
            <mat-error>{{ getFormErrors(translationDataForm.controls.translationLanguage, 'EDIT_CITY.TRANSLATION_FORM', 'language') | translate }} </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'EDIT_CITY.TRANSLATION_FORM.name.LABEL' | translate }}</mat-label>
            <input matInput formControlName="translationName" />
            <mat-error>{{ getFormErrors(translationDataForm.controls.translationName, 'EDIT_CITY.TRANSLATION_FORM', 'name') | translate }} </mat-error>
          </mat-form-field>

          <button mat-raised-button [disabled]="!translationDataForm.valid" (click)="addTranslation()">
            {{ 'EDIT_CITY.TRANSLATION_FORM.ADD_TRANSLATION' | translate }}
          </button>

          <div class="translation-cards">
            <app-edit-image-and-name-card
              *ngFor="let translationData of translationsArray.controls"
              [formGroup]="$any(translationData)"
              [image]="getFlagPath(LANGUAGES_MAP[translationData.value.language])"
              [remove]="removeTranslation.bind(this)"
            >
            </app-edit-image-and-name-card>
          </div>
          <div *ngIf="translationsArray.controls.length === 0">
            <span>{{ 'EDIT_CITY.TRANSLATION_FORM.TRANSLATIONS_ARRAY_EMPTY' | translate }}</span>
          </div>
        </div>
      </form>

      <div class="buttons">
        <button mat-raised-button [disabled]="!cityDataForm.valid || showCreatingCitySpinner" (click)="editCity()">
          {{ (cityId ? 'EDIT_CITY.BUTTON.UPDATE_CITY' : 'EDIT_CITY.BUTTON.CREATE_CITY') | translate }}
        </button>
      </div>
    </form>
    <div class="creating-city-spinner-container" *ngIf="showCreatingCitySpinner">
      <p>{{ (cityId ? 'EDIT_CITY.SPINNER.UPDATING_CITY' : 'EDIT_CITY.SPINNER.CREATING_CITY') | translate }}</p>
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="spinner-container" *ngIf="showLoadingCitySpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
