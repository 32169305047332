import { Component, Inject, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-company-is-test-dialog',
  templateUrl: './company-is-test-dialog.component.html',
  styleUrls: ['./company-is-test-dialog.component.scss'],
})
export class CompanyIsTestDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CompanyIsTestDialogComponent>,
    private firebaseService: FirebaseService,
    @Inject(MAT_DIALOG_DATA) public company: Company
  ) {}

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }

  public async updateCompanyIsTestValue(): Promise<void> {
    return this.firebaseService.updateCompany(this.company.id, {
      isTestCompany: this.company.isTestCompany,
    });
  }
}
