<div class="container">
  <div class="left-aligned-buttons">
    <button id="export-user-step-average-per-company-button" mat-raised-button (click)="exportUserStepAveragePerCompany()" [disabled]="exportUserStepAveragePerCompanySpinner">
      {{ 'ADMIN_EXPORT_USERS_STEP_AVERAGE_PER_COMPANY_REPORT' | translate }}
      <span class="material-icons" *ngIf="!exportUserStepAveragePerCompanySpinner">
        file_download
      </span>
      <mat-spinner diameter="20" *ngIf="exportUserStepAveragePerCompanySpinner"></mat-spinner>
    </button>
  </div>
  <div class="users-section">
    <h2 class="title">{{ 'ADMIN_TAB_USERS' | translate }}</h2>
    <span>{{ 'ADMIN_SEARCH_FOR_USERS' | translate }}</span>
    <button mat-button id="user-query-button" (click)="getUserBySearchMethod(userSearchOptionSelected.value)">
      <mat-icon
        class="sync-button"
        >sync</mat-icon>
    </button>
    <div class="user-search-export">
      <form class="user-search-form">
        <mat-form-field>
          <mat-select [placeholder]="'ADMIN_SEARCH_FOR_USERS_BY' | translate" [formControl]="userSearchOptionSelected" (selectionChange)="getUserBySearchMethod()">
            <mat-option *ngFor="let userSearchOption of userSearchOptions" value="{{ userSearchOption }}">
              {{ userSearchOption }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="userSearchOptionSelected.value === USERS_SEARCH_OPTIONS.AllCompanies || userSearchOptionSelected.value === USERS_SEARCH_OPTIONS.ActiveCompanies">
          <mat-select [placeholder]=" 'ADMIN_SELECT_A_COMPANY_ID' | translate" multiple [formControl]="selectedCompanies" panelClass="company-select-panel">
            <mat-option [value]="SELECT_ALL_VALUE" (click)="toggleAllSelection()">{{ 'SELECT_ALL' | translate }}</mat-option>
            <mat-option (click)="updateSelectAllValue()" *ngFor="let companyOption of companiesToSelect" value="{{ companyOption.id }}">
              {{ companyOption.id }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="userSearchOptionSelected.value === USERS_SEARCH_OPTIONS.AllCompanies || userSearchOptionSelected.value === USERS_SEARCH_OPTIONS.ActiveCompanies">
          <input
            matInput
            [placeholder]=" 'ADMIN_FILTER' | translate"
            type="text"
            class="form-control"
            [formControl]="filterValue"
            id="filterValue"
          />
        </mat-form-field>
        <mat-form-field *ngIf="userSearchOptionSelected.value === USERS_SEARCH_OPTIONS.Email">
          <input
            matInput
            placeholder="Email"
            type="text"
            class="form-control"
            [formControl]="userEmailToSearch"
            id="userEmailToSearch"
          />
        </mat-form-field>
        <div class="search-button-container">
          <button mat-raised-button id="search-users-button" (click)="getUserBySearchMethod(userSearchOptionSelected.value)">
            {{ 'ADMIN_SEARCH' | translate }}
          </button>
        </div>
      </form>
      <div class="export-button-container">
        <button mat-raised-button color="primary" id="export-users-button" [disabled]="usersDataSource.filteredData.length === 0" (click)="exportUserTableToExcel()">
          {{ 'EXPORT_USERS' | translate }}
        </button>
      </div>
    </div>
    <div class="filter-and-table-container">
      <div class="filter-list">
        <h2 class="subtitle">{{ 'ADMIN_FILTERS_FOR_USERS_BY' | translate }}</h2>
        <div class="filter-container">
          <div *ngFor="let filter of usersFilters; let i = index" [formGroup]="filtersForm">
            <mat-checkbox color="primary" [formControlName]="usersFilters[i]">
              {{ USER_PROP_NAMES[filter] }}
            </mat-checkbox>
          </div>
        </div>
      </div>

      <mat-table [dataSource]="usersDataSource" matSort (matSortChange)="updateSort($event)" [ngStyle]="{'display': usersToShow.length > 0 && !showSpinnerUsers ? 'block' : 'none'}">
        <ng-container *ngFor="let filter of activeFilters" [matColumnDef]="filter">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ USER_PROP_NAMES[filter] }}</mat-header-cell>
          <mat-cell *matCellDef="let user">{{
            filter === 'lastSync' ? (user[filter] | date: 'dd.MM.yyyy HH:mm:ss') :
            filter === 'settings.healthProvider' ? user.settings.healthProvider :
            filter === 'settings.lang' ? user.settings.lang :
            user[filter]
          }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="activeFilters"></mat-header-row>
        <mat-row *matRowDef="let row; columns: activeFilters" (click)="goToUser(row.id)"></mat-row>
      </mat-table>
      <div class="spinner-container" *ngIf="showSpinnerUsers">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
