import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CityInfo } from 'src/app/models/cities-challenge/city-info/city-info.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';

@Component({
  selector: 'app-city-info-card-dialog',
  templateUrl: './city-info-card-dialog.component.html',
  styleUrls: ['./city-info-card-dialog.component.scss'],
})
export class CityInfoCardDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CityInfoCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      city: City;
      cityInfo: CityInfo;
      viewOnly: boolean;
    }
  ) {}
}
