import { Component, Input, OnInit } from '@angular/core';

// Models
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';

// Constants
import { IconNames } from 'src/app/models/assets-constants';
import { MS_IN_ONE_DAY } from 'src/app/constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from 'src/app/helpers/helpers';

@Component({
  selector: 'app-cities-challenge-starts-soon-card',
  templateUrl: './cities-challenge-starts-soon-card.component.html',
  styleUrls: ['./cities-challenge-starts-soon-card.component.scss'],
})
export class CitiesChallengeStartsSoonCardComponent implements OnInit {
  @Input() nextCitiesChallenge: CitiesChallenge;
  public cityFrom: City;
  public cityTo: City;
  public CLOCK_OUTLINED_ICON = IconNames.ClockOutlined;
  public companyId: string = null;
  public startTomorrow: boolean = false;

  constructor(public translateService: TranslateService) {}

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.cityFrom = this.nextCitiesChallenge.regions[0].route.cityMarkerPoints[0].city;
    const cityMarkerPoints = this.nextCitiesChallenge.regions[
      this.nextCitiesChallenge.regions.length - 1
    ].route.cityMarkerPoints;
    this.cityTo = cityMarkerPoints[cityMarkerPoints.length - 1].city;
    this.startTomorrow =
      new Date(this.nextCitiesChallenge.startDate).getTime() - Date.now() <
      MS_IN_ONE_DAY;
  }

  public getFlagPath(city: City): string {
    return Helpers.getFlagPath(city.countryAlphaCode, true);
  }
}
