import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-widget-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  image: string;

  @Input()
  number: number;

  @Input()
  unit: string;

  @Input()
  cityName: string;

  @Input()
  text: string;

  @Input()
  average = false;

  @Input()
  showHeader: boolean;

  @Input()
  showImage: boolean;

  @Input()
  updatingValue: boolean;

  constructor() {}

  ngOnInit(): void {}
}
