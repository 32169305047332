<mat-card *ngIf="translationsReady">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'OVERVIEW.DATE_SELECTION' | translate }}</mat-label>
    <mat-date-range-input [rangePicker]="picker" [formGroup]="stepsDateRange" (click)="picker.open()">
      <input readonly='true' matStartDate
        placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_START' | translate }}" formControlName="start" />
      <input readonly='true' matEndDate
        placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_END' | translate }}" formControlName="end" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <highcharts-chart *ngIf="isChartReady" [Highcharts]="Highcharts" [options]="chartOptions"
    style="width: 100%; height: 400px; display: block"></highcharts-chart>
</mat-card>
