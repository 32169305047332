export interface ICityDetailsInitializer {
  image?: string;
  description?: string;
  imageRights?: string;
}

export class CityDetails {
  public image: string = null;
  public description: string = null;
  public imageRights: string = null;

  constructor(iCityDetailsInitializer: ICityDetailsInitializer = {}) {
    Object.assign(this, iCityDetailsInitializer);
  }

  public toObject(): ICityDetailsInitializer {
    return Object.assign({}, this);
  }
}
