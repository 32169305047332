<div class="container">
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title" id="team-chalenge-title">{{ user.email }}</h1>
  </div>
  <div *ngIf="!showSpinner">
    <mat-card class="cards">
      <div class="avatar" fxLayout.xs="center">
        <img src="{{ user.avatar ? user.avatar : 'assets/logo_login.png' }}" alt="" />
        <p class="display-name">{{ user.displayName }}</p>
      </div>
      <section fxLayout="row">
        <div class="info-section">
          <p class="card-title">{{ 'Name' | translate }}: </p>
          <p class="card-info">{{ user.firstName }} {{ user.lastName }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Email' | translate }}: </p>
          <p class="card-info">{{ user.email }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Nickname' | translate }}: </p>
          <p class="card-info">{{ user.nickname }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Company ID' | translate }}:</p>
          <p class="card-info">{{ user.companyId }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Health Provider' | translate }}:</p>
          <p class="card-info">{{ user.settings.healthProvider }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Current Level' | translate }}:</p>
          <p class="card-info">{{ user.currentLevel }}</p>
        </div>
      </section>
      <div class="second-section" fxLayout="row">
        <div class="info-section">
          <p class="card-title">{{ 'Current Challenge ID' | translate }}: </p>
          <p class="card-info">{{ user.currentChallengeId }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Current Level ID' | translate }}: </p>
          <p class="card-info">{{ user.currentLevelId }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Current Training ID' | translate }}: </p>
          <p class="card-info">{{ user.currentTrainingId }}</p>
        </div>
        <div class="info-section">
          <p class="card-title">{{ 'Last Sync' | translate }}: </p>
          <p class="card-info">{{ user.lastSync| date: 'dd.MM.yyyy HH:mm:ss'}}</p>
        </div>
      </div>
    </mat-card>
    <mat-card class="cards" *ngIf="(userCompany.currentTeamChallenges || userCompany.nextTeamChallenges) && user.currentChallengeId">
      <h2 class="title title-space">Team Challenge</h2>
      <div fxLayout="row">
        <div class="info-section">
          <mat-icon *ngIf="!joiningTeamChallenge" class="modify-icon" (click)="joinTeamChallenge()">create</mat-icon>
          <mat-spinner *ngIf="joiningTeamChallenge" diameter="20" class="modify-spinner"></mat-spinner>
          <p class="card-title"> {{ 'Current Team Challenge ID' | translate }}: </p>
          <p class="card-info">{{ user.currentTeamChallengeId }}</p>
        </div>
        <div class="info-section">
          <mat-icon *ngIf="!changingTeam" class="modify-icon" (click)="changeTeam()">create</mat-icon>
          <mat-spinner *ngIf="changingTeam" diameter="20" class="modify-spinner"></mat-spinner>
          <p class="card-title"> {{ 'Team ID' | translate }}: </p>
          <p class="card-info">{{ user.teamId }}</p>
        </div>
        <div class="info-section" *ngIf="team">
          <p class="card-title"> {{ 'Team Location' | translate }}: </p>
          <p class="card-info">{{ team.location }}</p>
        </div>
      </div>
    </mat-card>
    <mat-card class="cards">
      <h2 class="title title-space">Editable Info</h2>
      <div fxLayout="row">
        <div class="info-section" *ngIf="hierarchyOne">
          <mat-icon class="modify-icon" (click)="updateLocation(1)">create</mat-icon>
          <p class="card-title">{{ 'Location 1' | translate }}</p>
          <p class="card-info">{{ locationOne ? locationOne.name : '' }}</p>
        </div>
        <div class="info-section" *ngIf="hierarchyTwo">
          <mat-icon class="modify-icon" (click)="updateLocation(2)">create</mat-icon>
          <p class="card-title">{{ 'Location 2' | translate }}</p>
          <p class="card-info">{{ locationTwo ? locationTwo.name : ''}}</p>
        </div>
        <div class="info-section" *ngIf="hierarchyThree">
          <mat-icon class="modify-icon" (click)="updateLocation(3)">create</mat-icon>
          <p class="card-title">{{ 'Location 3' | translate }}</p>
          <p class="card-info">{{ locationThree ? locationThree.name : '' }}</p>
        </div>
        <div class="info-section">
          <mat-icon class="modify-icon" (click)="updateRole()">create</mat-icon>
          <p class="card-title">{{ 'Role' | translate }}</p>
          <p class="card-info">{{ roleEnumNames[user.role] }}</p>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="spinner-container" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
