<div>
  <img id="image" height="400" [src]="sanitizedUrl">
  <div class="crop-actions">
    <button style="margin-right: 1rem;" mat-flat-button (click)="cancel()">
      {{ 'CANCEL' | translate }}
    </button>
    <button color="primary" mat-flat-button (click)="crop()" #confirmButton>
      {{ 'CONFIRM' | translate }}
    </button>
  </div>
</div>
