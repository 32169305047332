<mat-card class="card-container">
  <mat-card-header class="header">
    <mat-card-title>{{ ('CHALLENGE_TYPES.' + type + '.TITLE') | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="description">
      {{ ('CHALLENGE_TYPES.' + type + '.DESCRIPTION') | translate }}
    </div>
  </mat-card-content>
  <mat-card-footer class="navigation-buttons">
    <button mat-flat-button color="primary" aria-label="Challenge type card button" (click)="select(type)">
      {{ 'CHALLENGE_TYPES.BUTTON.LABEL' | translate }}
    </button>
  </mat-card-footer>
</mat-card>
