import { Component, Input, forwardRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AvatarComponent),
    },
  ],
})
export class AvatarComponent implements ControlValueAccessor {
  @Input() index = 0;
  public file: string = '';

  constructor(private dialog: MatDialog) {}

  public onFileChange(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    const files = eventTarget.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      this.resetInput();
      this.openAvatarEditor(_file).subscribe((imageResult: string) => {
        if (imageResult) {
          this.file = imageResult;
          this.onChange(this.file);
        }
      });
    }
  }

  private resetInput(): void {
    const input = document.getElementById(
      'avatar-input-file'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  private openAvatarEditor(image: string): Observable<string> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: image,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  /** Functions and properties needed for ControlValueAccessor
   * ControlValueAccessor: Defines an interface that acts as a bridge between the Angular forms API and a native element in the DOM.
   */
  public onChange: (fileUrl: string) => void;
  public onTouched: () => void;
  public disabled: boolean = false;

  /**
   * Called by the Forms module to write a value into a form control
   * @param {string} fileUrl - The file url of the avatar
   */
  public writeValue(fileUrl: string): void {
    this.file = fileUrl;
  }

  /**
   * When a form value changes due to user input, we need to report the value back to the parent form.
   * This is done by calling a callback, that was initially registered with the control using the registerOnChange method
   */
  public registerOnChange(fn: (fileUrl: string) => void): void {
    this.onChange = fn;
  }

  /**
   * When the user first interacts with the form control, the control is considered to have the status touched, which is useful for styling.
   * In order to report to the parent form that the control was touched, we need to use a callback registered using
   * the registerOnTouched method
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Form controls can be enabled and disabled using the Forms API.
   * This state can be transmitted to the form control via the setDisabledState method
   * @param {boolean} isDisabled - Value to indicate if the form is disable
   */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
