import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { ChallengeTypes } from 'src/app/models/challenge-types.enum';
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { PointsSourceType } from 'src/app/models/points-source-type.enum';
import { PointsSourceBasis } from 'src/app/models/points-source-basis.enum';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/services/admin/admin.service';
import { Company } from 'src/app/models/company.model';

@Component({
  selector: 'app-edit-personal-challenge',
  templateUrl: './edit-personal-challenge.component.html',
  styleUrls: ['./edit-personal-challenge.component.scss'],
})
export class EditPersonalChallengeComponent implements OnInit {
  public challengeDataForm: UntypedFormGroup;
  public pointsSourcesDataForm: UntypedFormGroup;
  public rewardsDataForm: UntypedFormGroup;
  public dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  public showSpinner = false;

  public companyId: string;
  public personalChallengeId: string;
  public personalChallenge: ChallengeSchema;

  public ADD_PERSONAL_CHALLENGE_CREATED = '';

  public companyPointsSources = [];
  public selectablePointsSources = [];
  public pointsSourceDataSource = new MatTableDataSource();
  public pointsSourceColumns: string[] = [
    'id',
    'description',
    'basis',
    'expectedPerformance',
    'type',
    'pointsAwarded',
    'delete',
  ];

  public rewardsDataSource = new MatTableDataSource();
  public rewardsColumns: string[] = ['level', 'reward', 'delete'];

  public pointsSourceIds = [];
  public rewards = []; // [{level, reward}] => convert to map

  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as string[];

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as string[];

  public challengeTypesKeys: Array<number> = Object.keys(ChallengeTypes)
    .map((key) => ChallengeTypes[key])
    .filter((value) => typeof value === 'number') as number[];

  public challengeTypesNames: Array<string> = Object.keys(ChallengeTypes)
    .map((key) => ChallengeTypes[key])
    .filter((value) => typeof value === 'string') as string[];

  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) {
    this.getTranslations();
    this.challengeDataForm = this.formBuilder.group({
      id: [{ value: '', disabled: true }, Validators.required],
      initialPoints: [0, Validators.required],
      name: ['', Validators.required],
      companyId: [{ value: '', disabled: true }, Validators.required],
      type: ['0', Validators.required],
      minLevel: [1, Validators.required],
      maxLevel: [26, Validators.required],
      pointsToLevelUp: [14, Validators.required],
      pointsToHoldLevel: [10, Validators.required],
      initialLevel: [1, Validators.required],
      levelDuration: [7, Validators.required],
      canAscend: [{ value: true, disabled: true }, Validators.required],
      canDescend: [{ value: true, disabled: true }, Validators.required],
      hasFixedDate: [{ value: false, disabled: true }, Validators.required],
    });
    this.pointsSourcesDataForm = this.formBuilder.group({
      pointsSourceId: ['', Validators.required],
    });
    this.rewardsDataForm = this.formBuilder.group({
      level: [
        { value: 26, disabled: this.rewards.length === 0 },
        Validators.required,
      ],
      reward: [0, Validators.required],
    });

    this.challengeDataForm
      .get('maxLevel')
      .valueChanges.subscribe((maxLevelValue) => {
        if (this.rewards.length === 0) {
          this.rewardsDataForm.get('level').setValue(maxLevelValue);
        }
      });

    this.challengeDataForm.get('type').valueChanges.subscribe((type) => {
      switch (Number(type)) {
        case ChallengeTypes.Walk:
          this.challengeDataForm.get('canAscend').setValue(true);
          this.challengeDataForm.get('canDescend').setValue(true);
          this.challengeDataForm.get('hasFixedDate').setValue(false);
          break;

        case ChallengeTypes.WalkFixedDate:
          this.challengeDataForm.get('canAscend').setValue(true);
          this.challengeDataForm.get('canDescend').setValue(true);
          this.challengeDataForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyDownwards:
          this.challengeDataForm.get('canAscend').setValue(false);
          this.challengeDataForm.get('canDescend').setValue(true);
          this.challengeDataForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyUpwards:
          this.challengeDataForm.get('canAscend').setValue(true);
          this.challengeDataForm.get('canDescend').setValue(false);
          this.challengeDataForm.get('hasFixedDate').setValue(true);
          break;
      }
    });

    this.dateRange.disable();
    this.challengeDataForm.controls.hasFixedDate.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.dateRange.enable();
          this.dateRange.controls.start.setValidators(Validators.required);
          this.dateRange.controls.end.setValidators(Validators.required);
          this.dateRange.controls.start.updateValueAndValidity();
          this.dateRange.controls.end.updateValueAndValidity();
        } else {
          this.dateRange.disable();
          this.dateRange.controls.start.clearValidators();
          this.dateRange.controls.end.clearValidators();
          this.dateRange.controls.start.setValue(null);
          this.dateRange.controls.end.setValue(null);
          this.dateRange.controls.start.updateValueAndValidity();
          this.dateRange.controls.end.updateValueAndValidity();
        }
      }
    );
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.challengeDataForm.controls.companyId.setValue(this.companyId);
    this.personalChallengeId = this.route.snapshot.paramMap.get(
      'personalChallengeId'
    );

    Promise.all([
      this.firebaseService.getAvailableChallenge(
        this.companyId,
        this.personalChallengeId
      ),
      this.companyService.getPointsSources(this.companyId),
    ])
      .then((resolutions) => {
        const personalChallengeSnapshot = resolutions[0];
        const pointSources = resolutions[1];

        this.companyPointsSources = [...pointSources];
        this.selectablePointsSources = [...pointSources];

        this.personalChallenge = new ChallengeSchema().deserialize(
          personalChallengeSnapshot.data()
        );
        this.initializePointsSources();
        this.initializeRewards();
        Object.entries(this.challengeDataForm.controls).forEach((control) => {
          if (control[0] !== 'companyId') {
            this.challengeDataForm
              .get(control[0])
              .setValue(
                control[0] !== 'type'
                  ? this.personalChallenge[control[0]]
                  : this.personalChallenge[control[0]].toString()
              );
          }
        });
        if (this.personalChallenge.hasFixedDate) {
          this.dateRange
            .get('start')
            .setValue(new Date(this.personalChallenge.startDate));
          this.dateRange
            .get('end')
            .setValue(new Date(this.personalChallenge.endDate));
        }
      })
      .catch((error) => {
        console.log('ngOnInit Promises - error: ', error);
      });
  }

  public getTranslations(): void {
    this.translateService
      .stream(['ADD_PERSONAL_CHALLENGE_CREATED'])
      .subscribe((values) => {
        this.ADD_PERSONAL_CHALLENGE_CREATED =
          values.ADD_PERSONAL_CHALLENGE_CREATED;
      });
  }

  get companies(): Array<Company> {
    return this.adminService.companies;
  }

  public returnPage(): void {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  private initializeRewards(): void {
    this.personalChallenge.rewards.forEach((value, key) => {
      this.rewards.push({ level: Number(key), reward: value });
    });
    this.rewardsDataSource = new MatTableDataSource(this.rewards);
    if (this.rewards.length === 0) {
      this.rewardsDataForm.controls.level.disable();
      this.rewardsDataForm.controls.level.setValue(
        this.challengeDataForm.get('maxLevel').value
      );
    }
  }

  public addReward(): void {
    // TODO: prevent adding more rewards for one level
    this.rewards.push({
      level: this.rewardsDataForm.controls.level.value,
      reward: this.rewardsDataForm.controls.reward.value,
    });
    this.rewardsDataSource = new MatTableDataSource(this.rewards);
    this.rewardsDataForm.controls.level.enable();
  }

  public removeReward(reward): void {
    this.rewards.splice(
      this.rewards.findIndex((element) => element.level === reward.level),
      1
    );
    this.rewardsDataSource = new MatTableDataSource(this.rewards);
    if (this.rewards.length === 0) {
      this.rewardsDataForm.controls.level.disable();
      this.rewardsDataForm.controls.level.setValue(
        this.challengeDataForm.get('maxLevel').value
      );
    }
  }

  private initializePointsSources(): void {
    this.pointsSourceIds = Array.from(this.personalChallenge.pointsSourceIds);
    this.pointsSourceDataSource = new MatTableDataSource(
      this.companyPointsSources.filter((companyPointSource) =>
        this.pointsSourceIds.includes(companyPointSource.id)
      )
    );
    this.selectablePointsSources = this.selectablePointsSources.filter(
      (pointsSource) => !this.pointsSourceIds.includes(pointsSource.id)
    );
  }

  public addPointsSource(): void {
    this.pointsSourceIds.push(
      this.pointsSourcesDataForm.controls.pointsSourceId.value
    );
    this.pointsSourceDataSource = new MatTableDataSource(
      this.companyPointsSources.filter((companyPointSource) =>
        this.pointsSourceIds.includes(companyPointSource.id)
      )
    );
    this.selectablePointsSources.splice(
      this.selectablePointsSources.findIndex(
        (pointsSources) =>
          pointsSources.id ===
          this.pointsSourcesDataForm.controls.pointsSourceId.value
      ),
      1
    );
    this.pointsSourcesDataForm.controls.pointsSourceId.setValue('');
  }

  public removePointsSource(pointsSourceId: string): void {
    this.pointsSourceIds.splice(
      this.pointsSourceIds.findIndex(
        (pointsSources) => pointsSources === pointsSourceId
      ),
      1
    );
    this.selectablePointsSources.push(
      this.companyPointsSources.find(
        (pointsSource) => pointsSource.id === pointsSourceId
      )
    );
    this.pointsSourceDataSource = new MatTableDataSource(
      this.companyPointsSources.filter((companyPointSource) =>
        this.pointsSourceIds.includes(companyPointSource.id)
      )
    );
  }

  public async updatePersonalChallenge(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (this.challengeDataForm.valid) {
      const challengeId = this.challengeDataForm.controls.id.value;
      const companyId = this.challengeDataForm.controls.companyId.value;

      const rewards = {};
      this.rewards.forEach((reward) => {
        rewards[reward.level] = reward.reward;
      });

      const pointsSourceIds = new Set(this.pointsSourceIds);

      const challengeSchema = new ChallengeSchema().deserialize({
        id: challengeId,
        initialPoints: this.challengeDataForm.controls.initialPoints.value,
        name: this.challengeDataForm.controls.name.value,
        type: Number(this.challengeDataForm.controls.type.value),
        maxLevel: this.challengeDataForm.controls.maxLevel.value,
        minLevel: this.challengeDataForm.controls.minLevel.value,
        pointsToLevelUp: this.challengeDataForm.controls.pointsToLevelUp.value,
        pointsToHoldLevel: this.challengeDataForm.controls.pointsToHoldLevel
          .value,
        initialLevel: this.challengeDataForm.controls.initialLevel.value,
        pointsSourceIds,
        rewards,
        levelDuration: this.challengeDataForm.controls.levelDuration.value,
        canAscend: this.challengeDataForm.controls.canAscend.value,
        canDescend: this.challengeDataForm.controls.canDescend.value,
        hasFixedDate: this.challengeDataForm.controls.hasFixedDate.value,
        startDate: this.challengeDataForm.controls.hasFixedDate.value
          ? new Date(this.dateRange.value.start).getTime()
          : null,
        endDate: this.challengeDataForm.controls.hasFixedDate.value
          ? new Date(this.dateRange.value.end).getTime()
          : null,
      });

      await this.firebaseService.updatePersonalChallenge(
        companyId,
        challengeSchema,
        challengeId
      );

      this.showSpinner = false;
      this.snackBar.open(this.ADD_PERSONAL_CHALLENGE_CREATED, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
      this.router.navigate(['../..'], { relativeTo: this.route });
    }
  }
}
