import { Deserializable } from './deserializable.model';
import { FinalStandings } from './final-standings.model';

export class TeamFinalStandings implements Deserializable {
  public teams: Array<FinalStandings>;

  constructor(teams?: Array<FinalStandings>) {
    this.teams = teams ? teams : new Array<FinalStandings>();
  }

  deserialize(input: any): this {
    this.teams = Object.entries(input)
      .sort(
        (teamA: [string, FinalStandings], teamB: [string, FinalStandings]) =>
          teamA[1].finalPosition - teamB[1].finalPosition
      )
      .map((element) =>
        new FinalStandings({ id: element[0] }).deserialize(element[1])
      );
    return this;
  }

  toObject() {
    const teams = this.teams.map((teamStanding) => teamStanding.toObject());
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { teams });
  }
}
