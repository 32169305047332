import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CitiesService } from 'src/app/services/cities/cities.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { LANGUAGES_MAP, availableLanguages } from 'src/app/constants/constants';
import { AdminTabs } from '../admin-tabs.enum';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from 'src/app/helpers/helpers';
import { COUNTRIES } from 'src/assets/countries/countries';

class TranslationObject {
  public language: string;
  public name: string;

  constructor(language: string, name: string) {
    this.language = language;
    this.name = name;
  }
}

export interface ICountry {
  name: string;
  'alpha-2': string;
  'alpha-3': string;
  'country-code': string;
  'iso_3166-2': string;
  region: string;
  'sub-region': string;
  'intermediate-region': string;
  'region-code': string;
  'sub-region-code': string;
  'intermediate-region-code': string;
}

@Component({
  selector: 'app-edit-city',
  templateUrl: './edit-city.component.html',
  styleUrls: ['./edit-city.component.scss'],
})
export class EditCityComponent implements OnInit {
  public cityDataForm: UntypedFormGroup;
  public translationDataForm: UntypedFormGroup;
  public translationsArray: UntypedFormArray = this.formBuilder.array([]);

  public cityId: string;
  public city: City;

  public showCreatingCitySpinner = false;
  public showLoadingCitySpinner = true;

  public filteredCountries: Observable<Array<ICountry>>;

  public availableLanguages: Array<string> = new Array(...availableLanguages);

  public LANGUAGES_MAP = LANGUAGES_MAP;

  public countries: Array<ICountry> = COUNTRIES;

  private SNACKBAR_TYPES = {
    GET_CITY_FAILURE: 'GET_CITY_FAILURE',
    CREATE_FAILURE: 'EDIT_CITY.FAILURE_SNACKBAR.CREATE',
    UPDATE_FAILURE: 'EDIT_CITY.FAILURE_SNACKBAR.UPDATE',
    CREATE_SUCCESS: 'EDIT_CITY.SUCCESS_SNACKBAR.CREATE',
    UPDATE_SUCCESS: 'EDIT_CITY.SUCCESS_SNACKBAR.UPDATE',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private citiesService: CitiesService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.cityDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      lat: [
        null,
        [Validators.required, Validators.min(-90), Validators.max(90)],
      ],
      lng: [
        null,
        [Validators.required, Validators.min(-180), Validators.max(179.999)],
      ],
      country: [null, Validators.required],
    });

    this.translationDataForm = this.formBuilder.group({
      translationLanguage: ['', Validators.required],
      translationName: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.filteredCountries = this.cityDataForm.controls.country.valueChanges.pipe(
      startWith<string>(''),
      map((value: string | City) =>
        typeof value === 'string' ? value : value.name
      ),
      map((name) => (name ? this.filter(name) : this.countries.slice()))
    );

    this.cityId = this.route.snapshot.paramMap.get('cityId');

    if (this.cityId) {
      this.getCityFromFirebase();
    } else {
      this.showLoadingCitySpinner = false;
    }
  }

  private getCityFromFirebase(): void {
    this.citiesService
      .getCityFromFirebase(this.cityId)
      .then((city) => {
        this.city = city;
        this.setCityDataFormValues();
        this.setTranslationsArray();
        this.setAvailableLanguages();

        this.showLoadingCitySpinner = false;
      })
      .catch((error) => {
        this.showSnackBar(this.SNACKBAR_TYPES.GET_CITY_FAILURE, error);
      });
  }

  private setCityDataFormValues(): void {
    this.cityDataForm.controls.name.setValue(this.city.name);
    this.cityDataForm.controls.lat.setValue(this.city.lat);
    this.cityDataForm.controls.lng.setValue(this.city.lng);
    this.cityDataForm.controls.country.setValue(
      this.countries.find(
        (country: ICountry) =>
          country['alpha-3'].toLowerCase() === this.city.countryAlphaCode
      )
    );
  }

  private setTranslationsArray(): void {
    this.translationsArray = this.formBuilder.array(
      this.translationsMapToArray(this.city.translations).map((translation) =>
        this.formBuilder.group({
          language: [translation.language],
          name: [translation.name, Validators.required],
        })
      )
    );
  }

  private setAvailableLanguages(): void {
    this.availableLanguages = availableLanguages.filter(
      (lang) => !Array.from(this.city.translations.keys()).includes(lang)
    );
  }

  private filter(val: string): Array<ICountry> {
    return this.countries.filter(
      (option: ICountry) =>
        option.name.toLowerCase().indexOf(val.toLowerCase()) === 0
    );
  }

  public showCountryName(country?: ICountry): string | undefined {
    return country ? country.name : undefined;
  }

  public returnToAdminCities(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Cities } });
  }

  public async editCity(): Promise<void> {
    this.showCreatingCitySpinner = true;

    if (this.cityDataForm.valid) {
      const city = this.createCityObject();

      try {
        await this.citiesService.createUpdateCity(city);
      } catch (error) {
        this.showSnackBar(
          this.cityId
            ? this.SNACKBAR_TYPES.UPDATE_FAILURE
            : this.SNACKBAR_TYPES.CREATE_FAILURE,
          error
        );
        return Promise.reject(error);
      } finally {
        this.showCreatingCitySpinner = false;
      }

      this.showSnackBar(
        this.cityId
          ? this.SNACKBAR_TYPES.UPDATE_SUCCESS
          : this.SNACKBAR_TYPES.CREATE_SUCCESS
      );
      this.returnToAdminCities();
    }
  }

  private createCityObject(): City {
    return new City({
      id: this.cityId
        ? this.cityId
        : this.cityDataForm.controls.country.value['alpha-3'].toLowerCase() +
          '-' +
          this.cityDataForm.controls.name.value,
      name: this.cityDataForm.controls.name.value,
      lat: this.cityDataForm.controls.lat.value,
      lng: this.cityDataForm.controls.lng.value,
      countryAlphaCode: this.cityDataForm.controls.country.value[
        'alpha-3'
      ].toLowerCase(),
      translations: this.convertTranslationsFormIntoObject(
        this.translationsArray
      ),
    });
  }

  private showSnackBar(snackBarType: string, error?: string): void {
    this.snackBar.open(
      this.translateService.instant(snackBarType, error ? { error } : null),
      this.translateService.instant('Ok'),
      {
        duration: 4000,
        panelClass: 'snack-bar-color',
      }
    );
  }

  public addTranslation(): void {
    this.translationsArray.push(
      this.formBuilder.group({
        language: [this.translationDataForm.controls.translationLanguage.value],
        name: [
          this.translationDataForm.controls.translationName.value,
          Validators.required,
        ],
      })
    );

    this.availableLanguages.splice(
      this.availableLanguages.findIndex(
        (lang) =>
          lang === this.translationDataForm.get('translationLanguage').value
      ),
      1
    );

    this.translationDataForm.reset({
      translationLanguage: '',
      translationName: '',
    });
  }

  public removeTranslation(langFormGroup: FormGroup): void {
    const language = langFormGroup.controls.language.value;

    this.availableLanguages.push(language);
    const langIndex = this.translationsArray.controls.findIndex(
      (translationControl) =>
        translationControl.value.language === langFormGroup.value.language
    );
    this.translationsArray.removeAt(langIndex);
  }

  private translationsMapToArray(
    translations: Map<string, string>
  ): Array<TranslationObject> {
    return Array.from(
      translations,
      ([key, value]) => new TranslationObject(key, value)
    );
  }

  private convertTranslationsFormIntoObject(
    formArray: UntypedFormArray
  ): object {
    return formArray.controls.reduce(
      (accumulator, currentValue) =>
        Object.assign(accumulator, {
          [currentValue.value.language]: currentValue.value.name,
        }),
      {}
    );
  }

  public getFlagPath(countryCode: string): string {
    return Helpers.getFlagPath(countryCode);
  }

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }
}
