<div class="container">
  <div class="top">
    <mat-icon [mat-dialog-close]="null">keyboard_backspace</mat-icon>
    <h1 mat-dialog-title>{{ (params.cityInfo ? 'CITY_INFO.EDIT_CITY_INFO' : 'CITY_INFO.NEW') | translate }}</h1>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="cityInfoDataForm">
      <mat-form-field>
        <mat-label>{{ 'CITY_INFO.name.LABEL' | translate }}</mat-label>
        <input matInput formControlName="name" />
        <mat-icon matSuffix [svgIcon]="PENCIL_ICON"></mat-icon>
        <mat-error>{{ getFormErrors(cityInfoDataForm.controls.name, 'CITY_INFO', 'name') | translate }} </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CITY_INFO.subtitle.LABEL' | translate }}</mat-label>
        <input matInput formControlName="subtitle" />
        <mat-icon matSuffix [svgIcon]="PENCIL_ICON"></mat-icon>
        <mat-error>{{ getFormErrors(cityInfoDataForm.controls.subtitle, 'CITY_INFO', 'subtitle') | translate }} </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CITY_INFO.link.LABEL' | translate }}</mat-label>
        <mat-icon matSuffix [svgIcon]="PENCIL_ICON"></mat-icon>
        <input matInput formControlName="link" />
        <mat-error>{{ getFormErrors(cityInfoDataForm.controls.link, 'CITY_INFO', 'link') | translate }} </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CITY_INFO.type.LABEL' | translate }}</mat-label>
        <input class="upper-case" matInput formControlName="cityInfoType"/>
        <mat-icon matSuffix [svgIcon]="PENCIL_ICON"></mat-icon>
        <mat-error>{{ getFormErrors(cityInfoDataForm.controls.cityInfoType, 'CITY_INFO', 'type') | translate }} </mat-error>
      </mat-form-field>
      <div formArrayName="details">
        <div *ngFor="let detailsControl of detailsControlsArray; let i = index" [@animSlider]="{ value: detailsIndex, params: { direction: animationDirection > 0 ? -100 : 100 } }">
          <div *ngIf="i === detailsIndex" class="images" [formGroupName]="detailsIndex">
              <!-- image -->
              <div class="delete-button-container">
                <button *ngIf="detailsControlsArray.length > 1" mat-icon-button color="none" aria-label="Button with a delete icon" (click)="removeImage(detailsIndex)">
                  <mat-icon class="trash-icon" [svgIcon]="TRASH_ICON"></mat-icon>
                </button>
              </div>
              <input #inputUploadFile hidden="true" type="file" onclick="this.value=null" (change)="onFileChange($event, detailsIndex)"
                accept="image/jpeg, image/jpg, image/png, image/svg+xml" />
              <div *ngIf="!detailsControl.get('image').value" class="upload-image" (click)="inputUploadFile.click()">
                <mat-icon matPrefix>upload</mat-icon>
                {{ 'CITY_INFO.image.LABEL' | translate }}
              </div>
              <div *ngIf="detailsControl.get('image').value" class="image-preview" (click)="inputUploadFile.click()">
                <img [src]="detailsControl.get('image').value" alt="City info image">
              </div>
              <!-- description -->
              <mat-form-field>
                <mat-label>{{ 'CITY_INFO.rights.LABEL' | translate }}</mat-label>
                <textarea matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="3"
                formControlName="imageRights"></textarea>
              </mat-form-field>
              <!-- description -->
              <mat-form-field>
                <mat-label>{{ 'CITY_INFO.description.LABEL' | translate }}</mat-label>
                <textarea matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="4"
                formControlName="description"></textarea>
                <mat-error>{{ getFormErrors(detailsControl.get('description'), 'CITY_INFO', 'description') | translate }} </mat-error>
              </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <p class="detail-number">{{ detailsIndex + 1 }} / {{ detailsControlsArray.length }}</p>
    <div class="navigation-buttons">
      <button *ngIf="detailsIndex > 0" mat-mini-fab color="primary" aria-label="Button to move to previous image" (click)="changeImage(-1)">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button [disabled]="detailsControlsArray[detailsIndex].invalid" mat-mini-fab color="primary" aria-label="Button to move to next image" (click)="changeImage(1)">
        <mat-icon>{{ detailsControlsArray.length - 1 === detailsIndex ? 'add' : 'chevron_right' }}</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="submit-button" mat-raised-button color="primary" type="button" [disabled]="cityInfoDataForm.invalid || cityInfoDataSavingSpinner" (click)="saveCityInfo()">
      {{ (params.cityInfo ? 'CITY_INFO.UPDATE_CITY' : 'CITY_INFO.SAVE') | translate }}
      <span *ngIf="cityInfoDataSavingSpinner">
        <mat-spinner [diameter]="16"></mat-spinner>
      </span>
    </button>
  </div>
</div>
