<h1 mat-dialog-title>{{ data.location.name }}</h1>
<div mat-dialog-content>
  <div *ngFor="let location of data.locationList">
    <mat-checkbox color="primary" [checked]="data.location.childLocations.optionNames.includes(location.name)" (change)="updateChildLocations(location)">
      {{ location.name }}
    </mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="locationsSelected()">{{ 'READY' | translate }}</button>
</div>
