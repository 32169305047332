import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge.model';
import { Location } from 'src/app/models/location.model';
import { ITeamTableElement } from '../teams-table/teams-table.component';
import { Features as TeamChallengeFeatures } from 'src/app/models/team-challenge/features';

@Component({
  selector: 'app-team-challenge-confirmation',
  templateUrl: './team-challenge-confirmation.component.html',
  styleUrls: ['./team-challenge-confirmation.component.scss'],
})
export class TeamChallengeConfirmationComponent implements OnChanges {
  @Input() teamChallengeForm: UntypedFormGroup;
  @Input() locationsArray: Array<Location>;
  @Input() locationSelectedName: string;

  public teamChallenge: TeamChallengeV2;
  public teamTableElement: ITeamTableElement;
  public teamChallengeFeatures = TeamChallengeFeatures;

  constructor() {}

  get teamForms(): UntypedFormArray {
    return this.teamChallengeForm.controls.teamForms as UntypedFormArray;
  }

  ngOnChanges(): void {
    this.teamChallenge = new TeamChallengeV2(
      this.teamChallengeForm.getRawValue()
    );
    this.teamTableElement = {
      showDelete: false,
      locations: this.locationsArray,
    };
  }
}
