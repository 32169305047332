import { IDuelist } from 'src/app/interfaces/duelist.interface';
import {
  Challenge,
  IChallengeInitializer,
} from '../challenge/challenge.abstract';

export interface IDuelChallenge extends IChallengeInitializer {
  participantIds?: Array<string>;
  ranking?: { [key: string]: IDuelist };
}
export class DuelChallenge extends Challenge {
  public participantIds: Set<string> = new Set();
  public ranking: Map<string, IDuelist> = new Map();

  constructor(iDuelChallenge: IDuelChallenge = {}) {
    const iChallenge: IChallengeInitializer = iDuelChallenge;
    super(iChallenge);
    if (iDuelChallenge.participantIds) {
      this.participantIds = new Set(iDuelChallenge.participantIds);
    }
    if (iDuelChallenge.ranking) {
      this.ranking = new Map(Object.entries(iDuelChallenge.ranking));
    }
  }

  public toObject(): IDuelChallenge {
    return {
      ...super.toObject(),
      participantIds: Array.from(this.participantIds),
      ranking: Object.fromEntries(this.ranking),
    };
  }

  public hasParticipant(userId: string): boolean {
    return this.participantIds.has(userId);
  }

  public sortRanking(): Array<IDuelist> {
    if (this.ranking) {
      const duelists = [...this.ranking.values()];
      return duelists.sort((duelistA, duelistB) => {
        if (duelistA.duelsWon !== duelistB.duelsWon) {
          return duelistB.duelsWon - duelistA.duelsWon;
        } else if (duelistB.totalPerformance !== duelistA.totalPerformance) {
          return duelistB.totalPerformance - duelistA.totalPerformance;
        } else {
          return duelistA.nickname > duelistB.nickname ? 1 : -1;
        }
      });
    } else {
      return [];
    }
  }
}
