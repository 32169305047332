import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AddCompanyComponent } from 'src/app/pages/admin/add-company/add-company.component';
import { CreateCompanyComponent } from 'src/app/pages/admin/create-company/create-company.component';
import { AddPersonalChallengeComponent } from 'src/app/pages/admin/add-personal-challenge/add-personal-challenge.component';
import { AddPointsSourceComponent } from 'src/app/pages/admin/add-points-source/add-points-source.component';
import { AddTeamComponent } from 'src/app/pages/admin/add-team/add-team.component';
import { AddTeamChallengeComponent } from 'src/app/pages/admin/add-team-challenge/add-team-challenge.component';
import { AddEventComponentComponent } from 'src/app/pages/admin/add-event-component/add-event-component.component';
import { AdminComponent } from 'src/app/pages/admin/admin.component';
import { AdminTeamsComponent } from 'src/app/pages/admin-teams/admin-teams.component';
import { CitiesChallengeContainerComponent } from 'src/app/pages/cities-challenge-container/cities-challenge-container.component';
import { CompanyInfoComponent } from 'src/app/pages/admin/company-info/company-info.component';
import { UserInfoComponent } from 'src/app/pages/admin/user-info/user-info.component';
import { DefaultComponent } from './default.component';
import { HelpComponent } from 'src/app/pages/help/help.component';
import { EditCitiesChallengeComponent } from 'src/app/pages/admin/edit-cities-challenge/edit-cities-challenge.component';
import { EditCityComponent } from 'src/app/pages/admin/edit-city/edit-city.component';
import { EditPersonalChallengeComponent } from 'src/app/pages/admin/edit-personal-challenge/edit-personal-challenge.component';
import { EditPointsSourceComponent } from 'src/app/pages/admin/edit-points-source/edit-points-source.component';
import { EditTeamChallengeComponent } from 'src/app/pages/admin/edit-team-challenge/edit-team-challenge.component';
import { EditTeamComponent } from 'src/app/pages/admin/edit-team/edit-team.component';
import { EditRouteComponent } from 'src/app/pages/admin/edit-route/edit-route.component';
import { InputStepsComponent } from 'src/app/pages/input-steps/input-steps.component';
import { MyTeamComponent } from 'src/app/pages/my-team/my-team.component';
import { OverviewComponent } from 'src/app/pages/overview/overview.component';
import { SettingsComponent } from 'src/app/pages/settings/settings.component';
import { TeamChallengeComponent } from 'src/app/pages/team-challenge/team-challenge.component';
import { MatListModule } from '@angular/material/list';
import { UsersComponent } from 'src/app/pages/users/users.component';
import { ReportsComponent } from 'src/app/pages/reports/reports.component';
import { ImportCitiesComponent } from 'src/app/pages/admin/import-cities/import-cities.component';
import { DuelsComponent } from 'src/app/pages/duels/duels.component';
import { EventsComponent } from 'src/app/pages/events/events.component';
import { SendNotificationComponent } from 'src/app/pages/send-notification/send-notification.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { AgmCoreModule } from '@agm/core';
// import { AgmDirectionModule } from 'agm-direction';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatStepperModule } from '@angular/material/stepper';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AddWhiteLabelComponent } from 'src/app/pages/admin/add-white-label/add-white-label.component';
import { EditWhiteLabelComponent } from 'src/app/pages/admin/edit-white-label/edit-white-label.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { UsersTabComponent } from 'src/app/pages/admin/users-tab/users-tab.component';
import { NewTeamChallengeComponent } from 'src/app/pages/admin/team-challenge/new-team-challenge/new-team-challenge.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    DefaultComponent,
    OverviewComponent,
    HelpComponent,
    SettingsComponent,
    MyTeamComponent,
    TeamChallengeComponent,
    AdminComponent,
    AddCompanyComponent,
    CreateCompanyComponent,
    AddTeamComponent,
    CompanyInfoComponent,
    UserInfoComponent,
    AddPersonalChallengeComponent,
    AdminTeamsComponent,
    CitiesChallengeContainerComponent,
    InputStepsComponent,
    AdminTeamsComponent,
    InputStepsComponent,
    AddPointsSourceComponent,
    EditPersonalChallengeComponent,
    AddTeamChallengeComponent,
    EditPointsSourceComponent,
    EditTeamComponent,
    EditTeamChallengeComponent,
    EditCityComponent,
    EditCitiesChallengeComponent,
    UsersComponent,
    ReportsComponent,
    ImportCitiesComponent,
    EditRouteComponent,
    AddWhiteLabelComponent,
    EditWhiteLabelComponent,
    DuelsComponent,
    EventsComponent,
    SendNotificationComponent,
    AddEventComponentComponent,
    UsersTabComponent,
    NewTeamChallengeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatSidenavModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MaterialFileInputModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatListModule,
    MatStepperModule,
    MatAutocompleteModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDxaMGwcg22fUFDIsR-4d0kMzfby8K6kms',
    // }),
    // AgmDirectionModule,
    MatChipsModule,
    MatProgressBarModule,
    PdfViewerModule,
    MatDialogModule,
    MatExpansionModule,
    MatRadioModule,
    MatGridListModule,
    ColorPickerModule,
    MatRadioModule,
    NgxQrcodeStylingModule,
    MatPaginatorModule,
  ],
})
export class DefaultModule {}
