import { Deserializable } from './deserializable.model';
import { HealthProvider } from './health-provider.enum';
import { NotificationSettings } from './notification-settings.model';

export interface ISettingsInitializer {
  lang?: string;
  healthProvider?: HealthProvider;
  notificationSettings?: NotificationSettings;
}

export class Settings implements Deserializable {
  public lang: string = null;
  public healthProvider: HealthProvider = null;
  public notificationSettings: NotificationSettings = new NotificationSettings();

  constructor(initializer?: ISettingsInitializer) {
    Object.assign(this, initializer);
    this.notificationSettings = new NotificationSettings(
      initializer?.notificationSettings
    );
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    this.notificationSettings = new NotificationSettings().deserialize(
      input.notificationSettings
    );
    return this;
  }

  public toObject(): any {
    const notificationSettings = this.notificationSettings.toObject();
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { notificationSettings });
  }
}
