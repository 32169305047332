import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CityMarkerPoint } from 'src/app/models/cities-challenge/city-marker-point/city-marker-point.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { CityMarkerPoint as OldCityMarkerPoint } from 'src/app/models/city-marker-point.model';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-add-city-dialog',
  templateUrl: './add-city-dialog.component.html',
  styleUrls: ['./add-city-dialog.component.scss'],
})
export class AddCityDialogComponent implements OnInit {
  public cityDataForm: UntypedFormGroup;
  public routePoint: CityMarkerPoint = new CityMarkerPoint({
    city: null,
    distanceToNextCity: 0,
  });

  public filteredCities: Observable<Array<City>>;

  constructor(
    public dialogRef: MatDialogRef<AddCityDialogComponent>,
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService
  ) {
    this.cityDataForm = this.formBuilder.group({
      city: [this.routePoint.city, Validators.required],
      distance: [this.routePoint.distanceToNextCity, Validators.required],
    });

    this.cityDataForm.valueChanges.subscribe((valueChanges) => {
      if (valueChanges.city instanceof OldCityMarkerPoint) {
        this.routePoint.city = valueChanges.city;
      }
      this.routePoint.distanceToNextCity = valueChanges.distance;
    });
  }

  get cities(): Array<City> {
    return this.adminService.cities;
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.filteredCities = this.cityDataForm.get('city').valueChanges.pipe(
      startWith<string, any>(''),
      map((value: string | City) =>
        typeof value === 'string' ? value : value.name
      ),
      map((name) => (name ? this.filterCities(name) : this.cities.slice()))
    );
  }

  public close(): void {
    this.dialogRef.close();
  }

  public displayFn(city?: City): string | undefined {
    return city
      ? city.translations.get(this.currentLang)
        ? city.translations.get(this.currentLang)
        : city.name
      : undefined;
  }

  private filterCities(val: string): Array<City> {
    return this.cities.filter(
      (option) =>
        option.name.toLowerCase().indexOf(val.toLowerCase()) === 0 ||
        Array.from(option.translations.values()).includes(val)
    );
  }
}
