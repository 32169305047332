<ng-container *ngIf="citiesChallengeDataForm">
  <form class="cities-challenge-form" [formGroup]="citiesChallengeDataForm">
    <mat-form-field>
      <mat-label>{{ 'EDIT_CITIES_CHALLENGE.FORM.name.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ getFormErrors(citiesChallengeDataForm.controls.name, 'EDIT_CITIES_CHALLENGE.FORM', 'name') | translate }} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'EDIT_CITIES_CHALLENGE.FORM.expectedDailySteps.LABEL' | translate }}</mat-label>
      <input type="number" matInput formControlName="expectedDailySteps" />
      <mat-error>{{ getFormErrors(citiesChallengeDataForm.controls.expectedDailySteps, 'EDIT_CITIES_CHALLENGE.FORM', 'expectedDailySteps') | translate }} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'EDIT_CITIES_CHALLENGE.FORM.features.LABEL' | translate }}</mat-label>
      <mat-select formControlName="features" multiple>
        <mat-option *ngFor="let feature of featureList" [value]="feature"> {{ 'EDIT_CITIES_CHALLENGE.FORM.features.'+feature | translate}} </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'EDIT_CITIES_CHALLENGE.FORM.totalDistance.LABEL' | translate }}</mat-label>
      <input type="number" matInput formControlName="totalDistance" />
      <mat-error>{{ getFormErrors(citiesChallengeDataForm.controls.totalDistance, 'EDIT_CITIES_CHALLENGE.FORM', 'totalDistance') | translate }} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'EDIT_CITIES_CHALLENGE.FORM.totalDuration.LABEL' | translate }}</mat-label>
      <input type="number" matInput formControlName="totalDuration" />
      <mat-error>{{ getFormErrors(citiesChallengeDataForm.controls.totalDuration, 'EDIT_CITIES_CHALLENGE.FORM', 'totalDuration') | translate }} </mat-error>
    </mat-form-field>

    <ng-container *ngIf="createRegions">
      <div class="regions-number-container">
        <span>{{ 'EDIT_CITIES_CHALLENGE.FORM.regionsNumber.LABEL' | translate }}</span>
        <button class="remove-region-button" [disabled]="citiesChallengeDataForm.controls.regionsNumber.value === 1 || !editableChallenge" mat-icon-button type="button" color="warn" (click)="removeRegion()">
          <mat-icon>do_not_disturb_on_outline</mat-icon>
        </button>
        <span>{{ citiesChallengeDataForm.controls.regionsNumber.value }}</span>
        <button class="add-region-button" [disabled]="citiesChallengeDataForm.controls.regionsNumber.value === 10 || !editableChallenge" mat-icon-button type="button" color="primary" (click)="addRegion()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </ng-container>

    <mat-vertical-stepper linear>
      <mat-step [stepControl]="region" *ngFor="let region of regions.controls; let i = index">
        <ng-template matStepLabel>{{ regions.at(i).controls.route.get('name').value }}</ng-template>
        <app-routes-table *ngIf="!regions.at(i).controls.route.get('name').value" (newRouteSelected)="setSelectedRouteInRegion($event, i)" [routes]="defaultRoutes" [selectable]="true" [showCreateRouteButton]="true"></app-routes-table>
        <app-region-form *ngIf="regions.at(i).controls.route.get('name').value" [regionDataForm]="region" [minDate]="getRegionIndexMinDate(i)" [editableRoute]="editableRoute"></app-region-form>
        <div *ngIf="i !== regions.controls.length - 1">
          <button class="next-region-button" [disabled]="region.invalid" mat-flat-button color="primary" matStepperNext>{{ 'EDIT_CITIES_CHALLENGE.BUTTON.NEXT_REGION' | translate }}</button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </form>
</ng-container>
