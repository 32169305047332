<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'UPDATE_TEAM_CHALLENGE_TITLE' | translate }}</h1>
  </div>

  <form [formGroup]="challengeDataForm">
    <mat-form-field>
      <mat-label>{{ 'ADD_PERSONAL_CHALLENGE_COMPANY' | translate }}</mat-label>
      <mat-select formControlName="companyId">
        <mat-option *ngFor="let company of companies" value="{{ company.id }}">
          {{ company.name }}
        </mat-option>
      </mat-select>
      <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.id.LABEL' | translate }}</mat-label>
      <input matInput formControlName="id" />
      <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.id.REQUIRED_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.name.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.name.REQUIRED_ERROR' | translate }}</mat-error>
    </mat-form-field>

    <div class="challenge-details">
      <mat-form-field>
        <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.duration.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="duration" />
        <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.duration.REQUIRED_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.participantsPerTeam.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="participantsPerTeam" />
        <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.participantsPerTeam.REQUIRED_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.type.LABEL' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let challengeType of challengeTypesNames; let i = index"
            value="{{ challengeType }}"
          >
            {{ challengeType }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.type.REQUIRED_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamChallengeDateRange.LABEL' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange" (click)="picker.open()">
          <input
            matStartDate
            placeholder="{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamChallengeDateRange.START_LABEL' | translate }}"
            formControlName="start"
          />
          <input
            matEndDate
            placeholder="{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamChallengeDateRange.END_LABEL' | translate }}"
            formControlName="end"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </form>

  <form [formGroup]="teamsDataForm">
    <div class="add-team-form">
      <h3>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TITLE' | translate }}</h3>
      <div class="buttons-row">
        <div class="team-selector">
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TITLE' | translate }}</mat-label>
            <mat-select formControlName="teamId">
              <mat-optgroup [label]="('CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TITLE' | translate)">
                <mat-option *ngFor="let team of companyTeams" [value]="team.id" [disabled]="challengeTeams.includes(team)">
                  {{ team.name }}
                </mat-option>
              </mat-optgroup>
              <mat-optgroup [label]="('CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.ADD_BUTTON' | translate)">
                <mat-option *ngFor="let team of selectableTeams" [value]="team.id">
                  {{ team.name }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.REQUIRED_ERROR' | translate }}</mat-error>
          </mat-form-field>

          <button
            mat-raised-button
            [disabled]="!teamsDataForm.valid"
            (click)="addTeam()"
          >
            {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamsAmount.BUTTON_LABEL' | translate }}
          </button>
        </div>
        <div class="send-email-button-container">
          <button mat-raised-button color="primary" [disabled]="!getSendEmailButtonDisable() || showSendEmailSpinner" (click)="sendEmailToTeamCaptains()">
            {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.SEND_EMAIL_BUTTON' | translate }}
            <mat-spinner diameter="20" *ngIf="showSendEmailSpinner"></mat-spinner>
          </button>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="teamCaptainsCheckboxesForm">
    <table
      mat-table
      [dataSource]="teamsDataSource"
      matSort
      *ngIf="challengeTeams.length !== 0"
    >
      <!-- Id Column -->
      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let team">
          <img
            *ngIf="team.avatar !== 'assets/img/move-me-new.png'"
            src="{{ team.avatar }}"
            alt="avatar"
          />
          <div *ngIf="team.avatar === 'assets/img/move-me-new.png'">
            <mat-icon>do_not_disturb</mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TABLE.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let team">
          {{ team.name }}
        </td>
      </ng-container>

      <!-- Total Steps Column -->
      <ng-container matColumnDef="totalSteps">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TABLE.TOTAL_STEPS' | translate }}</th>
        <td mat-cell *matCellDef="let team">
          {{ team.totalSteps }}
        </td>
      </ng-container>

      <!-- Total Points Column -->
      <ng-container matColumnDef="totalPoints">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TABLE.TOTAL_POINTS' | translate }}
        </th>
        <td mat-cell *matCellDef="let team">
          {{ team.totalPoints }}
        </td>
      </ng-container>

      <!-- Team Captain Column -->
      <ng-container matColumnDef="teamCaptain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TABLE.TEAM_CAPTAIN' | translate }}
        </th>
        <td mat-cell *matCellDef="let team">
          {{ team.teamCaptain }}
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let team">
          <mat-icon (click)="removeTeam(team.id)">delete_outline</mat-icon>
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [id]="'checkbox-all'" color="primary" [formControlName]="'all'" (change)="toggleAllCheckboxes()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let team">
          <mat-checkbox *ngIf="teamChallenge && !teamChallenge.teamCaptainWithEmailSent.has(team.teamCaptain)" [id]="'checkbox-' + team.id" color="primary" [formControlName]="team.id"></mat-checkbox>
          <mat-icon *ngIf="teamChallenge && teamChallenge.teamCaptainWithEmailSent.has(team.teamCaptain)" [svgIcon]="sentEmailOkIcon"></mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="teamsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: teamsColumns"></tr>
    </table>
    <div *ngIf="challengeTeams.length === 0">
      <span>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TABLE.EMPTY_TABLE' | translate }}</span>
    </div>
  </form>

  <form [formGroup]="pointsAwardedDataForm">
    <div class="rewards">
      <h3>
        {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.TITLE' | translate }}
        <button mat-raised-button (click)="calculatePointsAwarded()">
          {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.CALCULATE_POINTS_TABLE_BUTTON' | translate }}
        </button>
      </h3>
      <mat-form-field>
        <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.position.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="position" />
        <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.position.MIN_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.points.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="points" />
        <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.points.MIN_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        [disabled]="!pointsAwardedDataForm.valid"
        (click)="addPointsAwarded()"
      >
        {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.ADD_BUTTON' | translate }}
      </button>

      <table
        mat-table
        [dataSource]="pointsAwardedDataSource"
        matSort
        *ngIf="pointsAwarded.length !== 0"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.TABLE.POSITION' | translate }}</th>
          <td mat-cell *matCellDef="let pointsAward">
            {{ pointsAward.position }}
          </td>
        </ng-container>

        <!-- Points Column -->
        <ng-container matColumnDef="points">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.TABLE.POINTS' | translate }}</th>
          <td mat-cell *matCellDef="let pointsAward">
            {{ pointsAward.points }}
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let pointsAward"
            (click)="removePointsAward(pointsAward)"
          >
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="pointsAwardedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pointsAwardedColumns"></tr>
      </table>
      <div *ngIf="pointsAwarded.length === 0">
        <span>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.TABLE.EMPTY_TABLE' | translate }}</span>
      </div>
    </div>
  </form>

  <div class="buttons">
    <button
      mat-raised-button
      [disabled]="!challengeDataForm.valid || dateRange.invalid"
      (click)="updateTeamChallenge($event)"
    >
      {{ 'UPDATE_TEAM_CHALLENGE_TITLE' | translate }}
    </button>
  </div>
  <div class="creating-team" *ngIf="showSpinner">
    <p>{{ 'ADD_PERSONAL_CHALLENGE_SPINNER' | translate }}</p>
    <mat-spinner></mat-spinner>
  </div>
</div>
