<div class="location-name">
  <p>{{ locationSelectedName }}</p>
  <p>{{ 'TEAM_CHALLENGE_INFO.LOCATION_LEVEL' | translate }}</p>
</div>
<div>
  <mat-chip-list>
    <mat-basic-chip *ngFor="let location of locationsArray">
      {{ location.name }}
    </mat-basic-chip>
  </mat-chip-list>
</div>
