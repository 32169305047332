import { Deserializable } from './deserializable.model';
import { Locations } from './locations.model';

export interface ILocationInitializer {
  id?: string;
  name?: string;
  userAmount?: number;
  totalSteps?: number;
  childLocations?: Locations;
  hierarchyLevel?: number;
}
export class Location implements Deserializable {
  public id: string = null;
  public name: string = null;
  public userAmount = 0;
  public totalSteps = 0;
  public childLocations: Locations = null;
  public hierarchyLevel = 1;

  constructor(initializer?: ILocationInitializer) {
    Object.assign(this, initializer);
    this.childLocations = new Locations(
      initializer && initializer.childLocations
        ? initializer.childLocations
        : null
    );
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    this.childLocations = new Locations().deserialize(input.childLocations);
    // this.totalSteps = -1;
    return this;
  }

  public toObject(): any {
    const childLocations = this.childLocations
      ? this.childLocations.toObject()
      : null;
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { childLocations });
  }
}
