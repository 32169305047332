import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Helpers } from 'src/app/helpers/helpers';

export interface IBadgeCardInfo {
  title: string;
  iconName: string;
  userNickname: string;
  message?: string;
  userPerformance: number;
}
@Component({
  selector: 'app-badge-card',
  templateUrl: './badge-card.component.html',
  styleUrls: ['./badge-card.component.scss'],
})
export class BadgeCardComponent implements AfterViewInit {
  @Input() infoBadgeCard: IBadgeCardInfo;
  @ViewChild('badgeCardContent', { static: true }) contentElement: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    Helpers.calculateCardContentHeight(this.contentElement, 32);
  }
}
