<mat-card *ngIf="nextTeamChallenge">
  <h1>{{ nextTeamChallenge.name }}</h1>
  <img [src]="startsSoonImage" alt="guy with phone waiting" onerror="this.onerror=null;this.src='assets/img/default-placeholder.png';"/>
  <h2>{{ 'TEAM_CHALLENGE_V2.STARTS_SOON_CARD.CHALLENGE_STARTS_SOON' | translate }}</h2>
  <mat-progress-bar mode="determinate" [value]="daysDifference < MAXIMAL_DAYS_DIFFERENCE_TO_SHOW ? (MAXIMAL_DAYS_DIFFERENCE_TO_SHOW - daysDifference) / MAXIMAL_DAYS_DIFFERENCE_TO_SHOW * 100 : PROGRESS_WHEN_DIFFERENCE_ABOVE_MAXIMAL"></mat-progress-bar>
  <h3>{{ (daysDifference < MAXIMAL_DAYS_DIFFERENCE_TO_SHOW ? 'TEAM_CHALLENGE_V2.STARTS_SOON_CARD.DAYS_TO_START' : 'TEAM_CHALLENGE_V2.STARTS_SOON_CARD.MORE_THAN_2_WEEKS_TO_START') | translate: { daysDifference } }}</h3>
  <button mat-raised-button color="primary" (click)="goToEdit()">
    {{ 'TEAM_CHALLENGE_V2.STARTS_SOON_CARD.EDIT_BUTTON' | translate }}
    <mat-icon>chevron_right</mat-icon>
  </button>
</mat-card>
