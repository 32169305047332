import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// Models
import { AdminTabs } from '../admin-tabs.enum';
import { Route } from 'src/app/models/cities-challenge/route/route.model';

// Services
import { RoutesService } from 'src/app/services/routes/routes.service';

@Component({
  selector: 'app-edit-route',
  templateUrl: './edit-route.component.html',
  styleUrls: ['./edit-route.component.scss'],
})
export class EditRouteComponent implements OnInit {
  public routeDataForm: UntypedFormGroup;

  public route: Route;
  public routeId: string;

  public showLoadingRouteSpinner = true;
  public showUpdatingRouteSpinner = false;

  private SNACKBAR_TYPES = {
    GET_ROUTE_FAILURE: 'GET_ROUTE_FAILURE',
    CREATE_FAILURE: 'EDIT_ROUTE.FAILURE_SNACKBAR.CREATE',
    UPDATE_FAILURE: 'EDIT_ROUTE.FAILURE_SNACKBAR.UPDATE',
    CREATE_SUCCESS: 'EDIT_ROUTE.SUCCESS_SNACKBAR.CREATE',
    UPDATE_SUCCESS: 'EDIT_ROUTE.SUCCESS_SNACKBAR.UPDATE',
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private routesService: RoutesService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.routeDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      totalDistance: [
        { value: 0, disabled: true },
        [Validators.required, Validators.min(0)],
      ],
      mapFile: [null],
      mapUrl: [null],
      cityInfoType: [null],
      cityMarkerPoints: this.formBuilder.array([]),
    });
  }

  get cityMarkerPoints(): FormArray<FormGroup> {
    return this.routeDataForm.controls.cityMarkerPoints as UntypedFormArray;
  }

  ngOnInit(): void {
    this.routeId = this.activatedRoute.snapshot.paramMap.get('routeId');

    if (this.routeId) {
      this.getRouteFromFirebase();
    } else {
      [...Array(3)].forEach((_, index) =>
        this.routesService.addControlToCityMarkerPoints(this.cityMarkerPoints, {
          isDistanceRequired: index !== 2,
        })
      );
      this.showLoadingRouteSpinner = false;
    }
  }

  private getRouteFromFirebase(): void {
    this.routesService
      .getRouteFromFirebase(this.routeId)
      .then((route) => {
        this.route = route;
        this.routesService.setRouteDataFormValues(
          this.routeDataForm,
          this.route
        );

        this.showLoadingRouteSpinner = false;
      })
      .catch((error) => {
        this.showSnackBar(this.SNACKBAR_TYPES.GET_ROUTE_FAILURE, error);
        console.log('companyServce.getCompany - error: ', error);
      });
  }

  public returnToAdminRoutes(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Routes } });
  }

  private showSnackBar(snackBarType: string, error?: string): void {
    this.snackBar.open(
      this.translateService.instant(snackBarType, error ? { error } : null),
      this.translateService.instant('Ok'),
      {
        duration: 4000,
        panelClass: 'snack-bar-color',
      }
    );
  }

  public async updateRoute(): Promise<void> {
    this.showUpdatingRouteSpinner = true;

    if (this.routeDataForm.valid) {
      const imageFile = this.routeDataForm.controls.mapFile.value
        ? this.routeDataForm.controls.mapFile.value.files[0]
        : null;
      const route = this.routesService.createRouteObject(
        this.routeId,
        this.routeDataForm,
        this.cityMarkerPoints
      );

      try {
        await this.routesService.createRoute(route, imageFile);
      } catch (error) {
        this.showSnackBar(
          this.routeId
            ? this.SNACKBAR_TYPES.UPDATE_FAILURE
            : this.SNACKBAR_TYPES.CREATE_FAILURE,
          error
        );
        return Promise.reject(error);
      } finally {
        this.showUpdatingRouteSpinner = false;
      }

      this.showSnackBar(
        this.routeId
          ? this.SNACKBAR_TYPES.UPDATE_SUCCESS
          : this.SNACKBAR_TYPES.CREATE_SUCCESS
      );
      this.returnToAdminRoutes();
    }
  }
}
