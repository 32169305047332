<mat-card>
  <mat-card-content>
    <mat-grid-list cols="4" rowHeight="150px">
      <mat-grid-tile [colspan]="4" [rowspan]="2">
        <app-kpi-list *ngIf="challengeKpiList" [kpiList]="challengeKpiList"></app-kpi-list>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="2">
        <app-user-ranking *ngIf="pointsRanking" [userRanking]="pointsRanking"></app-user-ranking>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="2">
        <app-user-ranking *ngIf="performanceRanking" [userRanking]="performanceRanking"></app-user-ranking>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="7">
        <section class="ranking-table">
          <mat-card>
            <mat-card-header>
              <mat-card-title> {{ "TEAM_CHALLENGE_V2.TOTAL_RANKING" | translate }} </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedTab">
                <mat-tab [label]="'TEAM_CHALLENGE_V2.POINTS' | translate">
                  <app-team-ranking-table *ngIf="pointsTableRanking" [rankingBy]="teamRankingBy.ACCUMULATED_POINTS" [teamRankings]="pointsTableRanking"></app-team-ranking-table>
                </mat-tab>
                <mat-tab [label]="'TEAM_CHALLENGE_V2.AVERAGE_PERFORMANCE' | translate">
                  <app-team-ranking-table *ngIf="averageTableRanking" [rankingBy]="teamRankingBy.AVERAGE_PERFORMANCE" [teamRankings]="averageTableRanking" [weeklyHistories]="teamChallengeOverview.weeklyHistories" (selectedWeek)="setAverageTableRanking($event)"></app-team-ranking-table>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>

            <div class="download-container">
              <button mat-raised-button (click)="downloadReport()" aria-label="Download from browser" [disabled]="downloading">
                <span>{{ 'TEAM_CHALLENGE_V2.DOWNLOAD_BUTTON' | translate }}</span>
                <mat-icon *ngIf="!downloading">cloud_download</mat-icon>
                <mat-spinner *ngIf="downloading" [diameter]="20"></mat-spinner>
              </button>
            </div>
          </mat-card>
        </section>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
