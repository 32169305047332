<div class="container">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab [label]="'TEAM_CHALLENGE_V2.CURRENT_CHALLENGE' | translate">
      <app-team-challenge-dashboard *ngIf="!currentChallengeLoading && (currentTeamChallengeDashboard || nextTeamChallenge)" [teamChallengeDashboard]="currentTeamChallengeDashboard"></app-team-challenge-dashboard>
      <app-no-current-challenge *ngIf="!currentChallengeLoading && !(currentTeamChallengeDashboard || nextTeamChallenge)" [noCurrentChallenge]="noTeamChallenge"></app-no-current-challenge>
      <div class="spinner-container" *ngIf="currentChallengeLoading">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>
    <mat-tab [label]="'TEAM_CHALLENGE_V2.COMPLETED_CHALLENGES' | translate">
      <div class="form-field-container">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'CITIES_CHALLENGE.SELECT_CHALLENGE' | translate }}</mat-label>
          <mat-select [formControl]="selectedChallengeFormControl" (selectionChange)="setTeamChallengeDashboardData()">
            <mat-option *ngFor="let teamChallenge of completedTeamChallenges" [value]="teamChallenge">
              {{ teamChallenge.name }}
            </mat-option>
          </mat-select>
          <mat-hint>{{ 'CITIES_CHALLENGE.HINT' | translate }}</mat-hint>
        </mat-form-field>
      </div>
      <app-team-challenge-dashboard *ngIf="!completedChallengeLoading && completedTeamChallengeDashboard" [teamChallengeDashboard]="completedTeamChallengeDashboard"></app-team-challenge-dashboard>
      <div class="spinner-container" *ngIf="completedChallengeLoading">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
