import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CityMarkerPoint } from 'src/app/models/city-marker-point.model';

@Component({
  selector: 'app-city-link-dialog',
  templateUrl: './city-link-dialog.component.html',
  styleUrls: ['./city-link-dialog.component.scss'],
})
export class CityLinkDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CityLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CityMarkerPoint,
    private translateService: TranslateService
  ) {}

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  ngOnInit(): void {
    console.log('data: ', this.data);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public goToLink(link: string): void {
    window.open(link, '_blank');
  }
}
