import { Injectable } from '@angular/core';
import { WhiteLabelButton } from 'src/app/models/white-label-button.model';
import { WhiteLabel } from 'src/app/models/white-label.model';
import { FirebaseService } from '../firebase/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  public whiteLabelToEdit: WhiteLabel;
  public whiteLabelButtonToEdit: WhiteLabelButton;

  constructor(private firebaseService: FirebaseService) {}

  public setWhiteLabelToEdit(
    whiteLabel: WhiteLabel,
    whiteLabelButton: WhiteLabelButton
  ): void {
    this.whiteLabelToEdit = whiteLabel;
    this.whiteLabelButtonToEdit = whiteLabelButton ? whiteLabelButton : null;
  }

  public updateWhiteLabelToEdit(whiteLabel: WhiteLabel): Promise<void> {
    if (whiteLabel) {
      this.whiteLabelToEdit = whiteLabel;
      return this.firebaseService.updateWhiteLabel(this.whiteLabelToEdit);
    }
    return Promise.reject('No White Label to Update');
  }
}
