<button mat-raised-button color="primary" (click)="selectRoute(null)" *ngIf="showCreateRouteButton">
  {{ 'EDIT_CITIES_CHALLENGE.BUTTON.CREATE_YOUR_OWN' | translate }}
</button>

<table mat-table [dataSource]="routesDataSource" matSort matSortActive="name" matSortDirection="asc" multiTemplateDataRows>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROUTES_TABLE.HEADER.NAME' | translate }}</th>
    <td mat-cell *matCellDef="let route">{{ route.name }}</td>
  </ng-container>

  <!-- Starting City Column -->
  <ng-container matColumnDef="startingCity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROUTES_TABLE.HEADER.STARTING_CITY' | translate }}</th>
    <td mat-cell *matCellDef="let route">{{ route.cityMarkerPoints[0].city.name }}</td>
  </ng-container>

  <!-- Ending City Column -->
  <ng-container matColumnDef="endingCity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROUTES_TABLE.HEADER.ENDING_CITY' | translate }}</th>
    <td mat-cell *matCellDef="let route">{{ route.cityMarkerPoints[route.cityMarkerPoints.length - 1].city.name }}</td>
  </ng-container>

  <!-- Ending City Column -->
  <ng-container matColumnDef="cities">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROUTES_TABLE.HEADER.CITIES' | translate }}</th>
    <td mat-cell *matCellDef="let route">{{ route.cityMarkerPoints.length }}</td>
  </ng-container>

  <!-- Total Distance Column -->
  <ng-container matColumnDef="totalDistance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROUTES_TABLE.HEADER.TOTAL_DISTANCE' | translate }}</th>
    <td mat-cell *matCellDef="let route">{{ route.totalDistance | number: '1.2-2':'de' }} {{ 'km' | translate }}</td>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let route" (click)="goToRoute(route.id)">
      <mat-icon>edit_outline</mat-icon>
    </td>
  </ng-container>

  <!-- Delete Column -->
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let route" (click)="removeRoute(route)">
      <mat-icon>delete_outline</mat-icon>
    </td>
  </ng-container>

  <!-- Expand Column -->
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
    <td mat-cell *matCellDef="let route">
      <button mat-icon-button aria-label="expand row" (click)="expandRoute($event, route)">
        <mat-icon *ngIf="expandedRoute !== route">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedRoute === route">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Select Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef aria-label="select row"></th>
    <td mat-cell *matCellDef="let route">
      <button mat-icon-button aria-label="expand row" (click)="selectRoute(route)">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let route" [attr.colspan]="columnsToDisplay.length">
      <app-route-form *ngIf="route === expandedRoute" [editable]="false" [routeDataForm]="expandedRouteForm"></app-route-form>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay"
    class="route-row"
    [ngClass]="selectedRoute === row ? 'selected' : ''"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expanded-detail-row"></tr>
</table>
