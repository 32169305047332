import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  DoCheck,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { _isNumberValue } from '@angular/cdk/coercion';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

// Models
import { User } from 'src/app/models/user.model';
import { Team } from 'src/app/models/team.model';
import { Roles } from 'src/app/models/roles.enum';

// Services
import { CompanyService } from 'src/app/services/company/company.service';
import { TeamsService } from 'src/app/services/teams/teams.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

const MAX_SAFE_INTEGER = 9007199254740991;
@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.scss'],
})
export class MyTeamComponent implements AfterViewInit, OnInit, DoCheck {
  @ViewChild(MatSort) sort: MatSort;

  public user: User;
  public maxDate = new Date();
  public minDate: Date = null;
  public date = new UntypedFormControl({
    value: new Date(),
    disabled: true,
  });

  public displayedColumns: string[] = ['position'];
  public dataSource = new MatTableDataSource(this.dailyPerformaceArray);
  public dataSourceReady = false;

  constructor(
    private companyService: CompanyService,
    private teamsService: TeamsService,
    private dateAdapter: DateAdapter<any>,
    private subscriptionService: SubscriptionService
  ) {
    this.dateAdapter.setLocale('de');

    this.subscriptionService.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.date = new UntypedFormControl({
          value: new Date(),
          disabled: this.user.role > Roles.TeamLeader,
        });
      }
    });
  }

  get team(): Team {
    return this.teamsService.team;
  }
  get dailyPerformaceArray(): Array<any> {
    return this.teamsService.dailyPerformaceArray;
  }
  get teamTotalSteps(): number {
    return this.teamsService.teamTotalSteps;
  }
  get teamTotalKilometers(): number {
    return this.teamsService.teamTotalKilometers;
  }
  get dailyPerformanceStats(): Array<string> {
    return this.teamsService.dailyPerformanceStats;
  }
  get teamChallengeWeeks(): Array<any> {
    return this.teamsService.currentTeamChallengeWeeks;
  }
  get companyUsers(): Array<string> {
    return this.companyService.company.users;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  ngDoCheck(): void {
    if (this.minDate === null && this.teamChallengeWeeks.length !== 0) {
      this.minDate = Helpers.unformatDate(
        this.teamChallengeWeeks[0].weekdays[0]
      );
    }

    this.dataSource.data = this.dailyPerformaceArray;
    if (!this.dataSourceReady && this.dailyPerformaceArray.length !== 0) {
      if (this.user.companyId && !this.displayedColumns.includes('nickname')) {
        this.displayedColumns.push('nickname');
      }
      this.dataSourceReady = true;
    }
  }

  public updateTeamCount(date: MatDatepickerInputEvent<Date>): void {
    if (this.user.role <= Roles.TeamLeader) {
      this.subscriptionService.subscribeToTeamDailyData(
        this.user.companyId,
        this.user.teamId,
        Helpers.formatDate(date.value)
      );
    }
  }

  /**
   * Data accessor function that is used for accessing data properties for sorting through
   * the default sortData function.
   * This default function assumes that the sort header IDs (which defaults to the column name)
   * matches the data's properties (e.g. column Xyz represents data['Xyz']).
   * May be set to a custom function for different behavior.
   * @param data Data object that is being accessed.
   * @param sortHeaderId The name of the column that represents the data.
   */
  public sortingDataAccessor: (
    data: any,
    sortHeaderId: string
  ) => string | number = (data: any, sortHeaderId: string): string | number => {
    let value: any;
    if (sortHeaderId.includes('stat-')) {
      const statIndex = sortHeaderId.split('-')[1]; // get stat index after an string split
      value = (data as { [key: string]: any }).stats.Steps;
    } else {
      value = (data as { [key: string]: any })[sortHeaderId];
    }
    if (_isNumberValue(value)) {
      const numberValue = Number(value);

      // Numbers beyond `MAX_SAFE_INTEGER` can't be compared reliably so we
      // leave them as strings. For more info: https://goo.gl/y5vbSg
      return numberValue < MAX_SAFE_INTEGER ? numberValue : value;
    }
    return value;
  };
}
