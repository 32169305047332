import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { Settings } from 'src/app/models/settings.model';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { UserService } from 'src/app/services/user/user.service';
import { LoginService } from 'src/app/services/login/login.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/company.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  sidebarOpened: boolean;
  public isUpdating = false;
  public isProdEnvironment = environment.production;

  constructor(
    private layoutService: LayoutService,
    private userService: UserService,
    private loginService: LoginService,
    private settingsService: SettingsService,
    private router: Router,
    private companyService: CompanyService
  ) {}

  get user(): User {
    return this.userService.user;
  }
  get settings(): Settings {
    return this.settingsService.settings;
  }
  get company(): Company {
    return this.companyService.company;
  }

  ngOnInit(): void {}

  public toggleSidebar(): void {
    this.layoutService.toggleSidebar();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  public logout(): void {
    this.loginService.logout();
    this.userService.removeUser();
    this.companyService.removeCompany();
    this.router.navigate(['login']);
  }

  public changeLanguage(lang: string): void {
    this.userService.updateLang(lang);
  }

  public updateChart(): void {
    this.isUpdating = true;
    this.companyService
      .updateCompanyData()
      .then(() => {
        this.isUpdating = false;
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }
}
