import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { Event as EventModel } from 'src/app/models/event/event';
import { Helpers } from 'src/app/helpers/helpers';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  public companyEvents: Array<EventModel> = [];

  constructor(private firebaseService: FirebaseService) {}

  public getCompanyEvents(companyId: string): Promise<void> {
    return this.firebaseService
      .getCompanyEvents(companyId)
      .then((eventsQuerySnapshot) => {
        if (!eventsQuerySnapshot.empty) {
          this.companyEvents = eventsQuerySnapshot.docs
            .map((docSnapshot) => new EventModel(docSnapshot.data()))
            .sort((eventA: EventModel, eventB: EventModel) => {
              const lastDayA = eventA.days[eventA.days.length - 1];
              const lastDayB = eventB.days[eventB.days.length - 1];
              return (
                Helpers.unformatDateYYYYMMDD(lastDayB).getTime() -
                Helpers.unformatDateYYYYMMDD(lastDayA).getTime()
              );
            });
        } else {
          this.companyEvents = [];
        }
      })
      .catch((error) => {
        console.log('getCompanyEvents - error: ', error);
      });
  }

  public getCompanyArrayEvents(companyId: string): Promise<Array<EventModel>> {
    return this.firebaseService
      .getCompanyEvents(companyId)
      .then((eventQuery) => {
        if (!eventQuery.empty) {
          return eventQuery.docs.map(
            (eventSnapshot) => new EventModel(eventSnapshot.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('getCompanyEvents - error: ', error);
        return Promise.reject(error);
      });
  }

  public async getCompanyEvent(
    companyId: string,
    eventId: string
  ): Promise<EventModel> {
    const eventSnapshot = await this.firebaseService.getCompanyEvent(
      companyId,
      eventId
    );
    const event: EventModel = eventSnapshot.exists
      ? new EventModel(eventSnapshot.data())
      : null;

    return event;
  }

  public createEvents(companyId: string, event: EventModel): Promise<void> {
    return this.firebaseService.createCompanyEvent(companyId, event.id, event);
  }
}
