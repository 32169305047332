<ng-container *ngIf="regionDataForm">
  <form class="region" [formGroup]="regionDataForm">
    <div class="region-duration">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'EDIT_REGION.FORM.dateRange.LABEL' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange" (click)="picker.open()" [min]="minDate">
          <input
            matStartDate
            placeholder="{{ 'EDIT_REGION.FORM.dateRange.startDate.LABEL' | translate }}"
            formControlName="start"
          />
          <input
            matEndDate
            placeholder="{{ 'EDIT_REGION.FORM.dateRange.endDate.LABEL' | translate }}"
            formControlName="end"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <p>
        {{ 'EDIT_REGION.DURATION' | translate: { duration: regionDataForm.controls.duration.value } }}
      </p>
    </div>

    <app-route-form [editable]="editableRoute" [routeDataForm]="route"></app-route-form>
  </form>
</ng-container>
