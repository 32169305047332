<ng-container *ngIf="routeDataForm">
  <form class="route" [formGroup]="routeDataForm">
    <mat-form-field>
      <mat-label>{{ 'EDIT_ROUTE.FORM.name.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ getFormErrors(routeDataForm.controls.name, 'EDIT_ROUTE.FORM', 'name') | translate }} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'EDIT_ROUTE.FORM.totalDistance.LABEL' | translate }}</mat-label>
      <input type="number" matInput formControlName="totalDistance" />
      <mat-error>{{ getFormErrors(routeDataForm.controls.totalDistance, 'EDIT_ROUTE.FORM', 'totalDistance') | translate }} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select formControlName="cityInfoType" [placeholder]="'EDIT_ROUTE.FORM.cityInfo.LABEL' | translate">
        <mat-option value="CLEAR">{{ 'EDIT_ROUTE.FORM.cityInfo.NONE' | translate }}</mat-option>
        <mat-option *ngFor="let cityInfoType of cityInfoTypesAvailable" [value]="cityInfoType">
          {{ cityInfoType }}
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="cityInfoTypesAvailable && cityInfoTypesAvailable.size === 0">{{ 'EDIT_ROUTE.FORM.cityInfo.HINT' | translate }}</mat-hint>
    </mat-form-field>

    <div class="map-container">
      <mat-form-field class="map-input-button">
        <button *ngIf="!routeDataForm.controls.mapUrl.value && editable" type="button" color="primary" class="add-map-image-button" (click)="mapInput">
          {{ 'EDIT_ROUTE.FORM.mapFile.LABEL' | translate }}
        </button>
        <img *ngIf="routeDataForm.controls.mapUrl.value" [src]="routeDataForm.controls.mapUrl.value" [alt]="'EDIT_ROUTE.FORM.mapFile.IMG_ALT' | translate" />
        <ngx-mat-file-input style="visibility: hidden;"
        accept=".png, .jpg, .jpeg"
        formControlName="mapFile" #mapInput
        ></ngx-mat-file-input>
      </mat-form-field>
    </div>

    <h2>{{ 'EDIT_ROUTE.CITIES_SUBTITLE' | translate }}</h2>

    <ng-container formArrayName="cityMarkerPoints">
      <ng-container *ngFor="let cityMarkerPointForm of cityMarkerPoints.controls; let i = index">
        <div [formGroup]="cityMarkerPointForm" class="city-marker-point-form">
          <div class="flag-and-position">
            {{ (i + 1).toLocaleString('de-De', { minimumIntegerDigits: 2 }) }}
            <img [src]="getFlagPath(cityMarkerPointForm.value.city)" height="24px" width="32px" />
          </div>
          <mat-form-field>
            <mat-label>{{ 'EDIT_ROUTE.CITY_FORM.city.LABEL' | translate }}</mat-label>
            <input type="text" matInput [matAutocomplete]="startingAutocomplete" formControlName="city" />
            <mat-autocomplete #startingAutocomplete="matAutocomplete" [displayWith]="showCityName">
              <mat-option *ngFor="let city of filteredCities | async" [value]="city.toObject()">
                <img height="24px" width="32px" [src]="getFlagPath(city)" />
                {{ city.translations.get(currentLang) }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{ getFormErrors(cityMarkerPoints.at(i).controls.city, 'EDIT_ROUTE.CITY_FORM', 'city') | translate }} </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="i !== cityMarkerPoints.controls.length - 1">
            <mat-label>{{ 'EDIT_ROUTE.CITY_FORM.distanceToNextCity.LABEL' | translate }}</mat-label>
            <input type="number" matInput formControlName="distanceToNextCity" />
            <mat-error>{{ getFormErrors(cityMarkerPoints.at(i).controls.distanceToNextCity, 'EDIT_ROUTE.CITY_FORM', 'distanceToNextCity') | translate }} </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="cityMarkerPointForm.value.city">
            <mat-select formControlName="cityInfoType" [placeholder]="'EDIT_ROUTE.FORM.cityInfo.LABEL' | translate">
              <mat-option [value]="null">{{ 'EDIT_ROUTE.FORM.cityInfo.NONE' | translate }}</mat-option>
              <mat-option *ngFor="let cityInfoType of cityMarkerPointForm.value.city.cityInfoTypes" [value]="cityInfoType">
                {{ cityInfoType }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-icon-button type="button" color="primary" class="city-info-button" *ngIf="cityMarkerPoints.at(i).controls.city.value && editable && cityInfos.length > 0" (click)="editCityInfo(cityMarkerPoints.at(i).controls.city.value, cityInfos[i], i)">
            <mat-icon *ngIf="cityInfos[i]">info</mat-icon>
            <mat-icon *ngIf="!cityInfos[i]">info_outline</mat-icon>
          </button>

          <button class="remove-city-button" *ngIf="cityMarkerPoints.length > 3 && editable" mat-icon-button type="button" (click)="removeCity(i)">
            <mat-icon [svgIcon]="trashIcon"></mat-icon>
          </button>

        </div>

      </ng-container>

      <button *ngIf="editable" class="add-city-button" [disabled]="cityMarkerPoints.length > 0 && !cityMarkerPoints.at(cityMarkerPoints.controls.length - 1).valid" mat-icon-button type="button" (click)="addCity()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

    </ng-container>
  </form>
</ng-container>
