import { Injectable } from '@angular/core';

// Models
import { Team } from 'src/app/models/team.model';
import { Company } from 'src/app/models/company.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

// Constants
import { STEPS_IN_ONE_KILOMETER } from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  public team: Team;
  public teams: Array<Team> = [];

  public company: Company;

  public weeklyRanking: Array<any> = [];
  public weeklyRankingPointsSorted: Array<any> = [];
  public weeklyRankingStats: Array<string> = [];

  private NOT_SYNCED_YET = '';

  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
    private subscriptionService: SubscriptionService
  ) {
    this.getTranslations();

    this.subscriptionService.company.subscribe((company) => {
      if (company) {
        this.company = company;
      }
    });

    this.subscriptionService.teams.subscribe((teams) => {
      if (teams) {
        this.teams = teams;
      }
    });

    this.subscriptionService.team.subscribe((team) => {
      if (team) {
        this.team = team;
      }
    });
  }

  // TODO: update model to not use any
  get dailyPerformaceArray(): Array<any> {
    return this.subscriptionService.dailyPerformaceArray;
  }
  get teamTotalSteps(): number {
    return this.subscriptionService.teamTotalSteps;
  }
  get teamTotalKilometers(): number {
    return this.subscriptionService.teamTotalKilometers;
  }
  get dailyPerformanceStats(): Array<string> {
    return this.subscriptionService.dailyPerformanceStats;
  }
  // TODO: update model to not use any
  get currentTeamChallengeWeeks(): Array<any> {
    return this.subscriptionService.currentTeamChallengeWeeks;
  }

  private getTranslations(): void {
    this.translateService
      .stream(['TEAMS_NOT_SYNCED_YET'])
      .subscribe((values) => {
        this.NOT_SYNCED_YET = values.TEAMS_NOT_SYNCED_YET;
      });
  }

  public async getTeam(companyId: string, teamId: string): Promise<Team> {
    return this.firebaseService
      .getTeam(companyId, teamId)
      .then((teamSnapshot) => {
        return new Team(teamSnapshot.data());
      })
      .catch((error) => {
        console.log('error while getting team: ', error);
        return Promise.reject(error);
      });
  }

  public async getTeams(companyId: string): Promise<Array<Team>> {
    return this.firebaseService
      .getTeams(companyId)
      .then((teamsQuerySnapshot) => {
        if (!teamsQuerySnapshot.empty) {
          return teamsQuerySnapshot.docs.map(
            (teamSnapshot) => new Team(teamSnapshot.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('error while getting teams: ', error);
        return Promise.reject(error);
      });
  }

  public getTeamChallengeWeeks(
    companyId: string,
    teamChallengeId: string
  ): Promise<Array<any>> {
    return this.firebaseService
      .getTeamChallengeWeeks(companyId, teamChallengeId)
      .then((teamChallengeWeeksQuery) => {
        if (!teamChallengeWeeksQuery.empty) {
          return teamChallengeWeeksQuery.docs.map((teamChallengeWeekSnapshot) =>
            Object.assign(teamChallengeWeekSnapshot.data(), {
              id: teamChallengeWeekSnapshot.id,
            })
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('getTeamChallengeWeeks - error: ', error);
        return Promise.reject(error);
      });
  }

  public getTeamWeeklyRanking(
    companyId: string,
    teamChallengeId: string,
    day: string
  ): Promise<any> {
    return this.firebaseService
      .getTeamChallengeWeeklyRanking(companyId, teamChallengeId, day)
      .then((teamChallengeWeeklyRankingQuery) => {
        const returnObject = {
          weeklyRanking: [],
          weeklyRankingPointsSorted: [],
          weeklyRankingStats: [],
          largestTeam: [],
          teamChallengeStepsArray: [],
          teamChallengeTotalSteps: 0,
          teamChallegeTotalKilometers: 0,
        };
        if (!teamChallengeWeeklyRankingQuery.empty) {
          const teamWeeklyRankingDoc = teamChallengeWeeklyRankingQuery.docs[0];
          if (teamWeeklyRankingDoc.exists) {
            const teamWeeklyRankingData = teamWeeklyRankingDoc.data();
            delete teamWeeklyRankingData.weekdays;
            returnObject.weeklyRanking = Object.values(
              teamWeeklyRankingData
            ).sort((a, b) => {
              const stepDifference =
                b.dailyPerformance[day].Steps - a.dailyPerformance[day].Steps;
              if (stepDifference !== 0) {
                return stepDifference;
              }
              if (a.name < b.name) {
                return -1;
              } else {
                return 1;
              }
            });
            returnObject.weeklyRanking.forEach((element, index) => {
              element.position = index + 1;
            });
            returnObject.weeklyRankingPointsSorted = returnObject.weeklyRanking.slice();
            returnObject.weeklyRankingPointsSorted.sort(
              (a, b) => b.points - a.points
            );
            returnObject.weeklyRankingPointsSorted.forEach((element, index) => {
              element.positionSorted = index + 1;
            });
            // TODO: stats should be defined on team challenge type, day should be selected programatically
            returnObject.weeklyRankingStats = Object.keys(
              returnObject.weeklyRanking[0].dailyPerformance[day]
            );
            returnObject.largestTeam = returnObject.weeklyRanking
              .slice()
              .sort((a, b) => b.teamSize - a.teamSize);
            for (const weeklyRankingDailyPerformance of returnObject.weeklyRanking) {
              returnObject.teamChallengeStepsArray.push(
                weeklyRankingDailyPerformance.dailyPerformance[day].Steps
              );
            }
            const teamChallengeStepsSum = returnObject.teamChallengeStepsArray.reduce(
              (a, b) => a + b,
              0
            );
            returnObject.teamChallengeTotalSteps = teamChallengeStepsSum;
            returnObject.teamChallegeTotalKilometers = Math.round(
              teamChallengeStepsSum / STEPS_IN_ONE_KILOMETER
            );
          }
        }
        return returnObject;
      })
      .catch((error) => {
        console.log('getTeamChallengeWeeklyRanking - error: ', error);
        return Promise.reject(error);
      });
  }

  public getAllTeamsDailyPerformance(
    companyId: string,
    date: string
  ): Promise<any> {
    const dailyPerformancePromises = this.teams.map((team) =>
      this.firebaseService.getTeamChallengeDailyPerformance(
        companyId,
        team.id,
        date
      )
    );

    return Promise.all(Object.values(dailyPerformancePromises))
      .then(
        (
          resolutions: Array<
            firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
          >
        ) => {
          const allTeamsDailyPerformance = {};
          resolutions.forEach((resolution) => {
            if (resolution.exists) {
              const teamId = resolution.ref.path.split('/')[3];
              allTeamsDailyPerformance[teamId] = Object.values(
                resolution.data()
              ).sort((a, b) => b.stats.Steps - a.stats.Steps);
              const membersThatDidntSynced =
                this.teams.find((team) => team.id === teamId).members.size -
                allTeamsDailyPerformance[teamId].length;
              for (let i = 0; i < membersThatDidntSynced; i++) {
                allTeamsDailyPerformance[teamId].push({
                  name: '**** ' + this.NOT_SYNCED_YET,
                  stats: {
                    Steps: 0,
                  },
                });
              }
            }
          });
          return allTeamsDailyPerformance;
        }
      )
      .catch((error) => {
        console.log('error: ', error);
        return Promise.reject(error);
      });
  }

  public getAllTeamsUsers(): Promise<any> {
    const allTeamsUsersPromises = this.teams.map((team) =>
      this.firebaseService.getTeamUsers(team.id)
    );

    return Promise.all(Object.values(allTeamsUsersPromises))
      .then(
        (
          resolutions: Array<
            firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
          >
        ) => {
          const allTeamsUsers = {};
          resolutions.forEach((resolution) => {
            if (!resolution.empty) {
              resolution.forEach((userSnapshot) => {
                const user = userSnapshot.data();
                allTeamsUsers[user.teamId].push(user.displayName);
              });
            }
          });
          return allTeamsUsers;
        }
      )
      .catch((error) => {
        console.log('error: ', error);
        return Promise.reject(error);
      });
  }

  public async removeUserFromTeam(
    companyId: string,
    teamId: string,
    userId: string
  ): Promise<void> {
    return this.firebaseService.removeUserFromTeam(companyId, teamId, userId);
  }

  /**
   * batched creation of a series of teams into a company, it calls the promise for creation of teams for every team in the teams param.
   * @param companyId company where to create the new teams
   * @param teams teams schema array to add to the company
   * @returns promise with the resolutions for the creation of each of the teams. If one of them fails, it will return a promise reject.
   */
  public createCompanyTeams(
    companyId: string,
    teams: Array<Team>
  ): Promise<Array<void>> {
    return Promise.all(
      teams.map((team) =>
        this.firebaseService.createCompanyTeam(companyId, team.id, team)
      )
    );
  }
}
