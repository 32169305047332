import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';

// Models
import { ChallengeTypes } from 'src/app/models/challenge-types.enum';
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { PointsSourceType } from 'src/app/models/points-source-type.enum';
import { PointsSourceBasis } from 'src/app/models/points-source-basis.enum';
import { PersonalChallengeSchema } from 'src/app/models/personal-challenge-schema/personal-challenge-schema';
import { PointsSource } from 'src/app/models/points-source.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-add-personal-challenge',
  templateUrl: './add-personal-challenge.component.html',
  styleUrls: ['./add-personal-challenge.component.scss'],
})
export class AddPersonalChallengeComponent implements OnInit {
  public personalChallengeForm: UntypedFormGroup;
  public personalChallengeDateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  public minDate = new Date();

  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as string[];

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as string[];

  public pointsSourceIds: Array<string> = [];
  public selectablePointsSources: Array<PointsSource> = [];
  public pointsSourceDataSource = new MatTableDataSource();
  public pointsSourceColumns: string[] = [
    'id',
    'description',
    'basis',
    'expectedPerformance',
    'type',
    'pointsAwarded',
    'delete',
  ];

  public companyId: string;
  public ADD_PERSONAL_CHALLENGE_CREATED = '';
  public showSpinner = false;

  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) {
    this.getTranslations();
    this.personalChallengeForm = this.formBuilder.group({
      schema: [null, Validators.required],
      challengeId: ['', Validators.required],
      name: ['', Validators.required],
      initialPoints: [0, Validators.required],
      type: ['0', Validators.required],
      minLevel: [1, Validators.required],
      maxLevel: [26, Validators.required],
      pointsToLevelUp: [14, Validators.required],
      pointsToHoldLevel: [10, Validators.required],
      initialLevel: [1, Validators.required],
      levelDuration: [7, Validators.required],
      canAscend: [{ value: true, disabled: true }, Validators.required],
      canDescend: [{ value: true, disabled: true }, Validators.required],
      hasFixedDate: [{ value: false, disabled: true }, Validators.required],
    });

    this.personalChallengeForm.controls.schema.valueChanges.subscribe(
      (selectedSchema: PersonalChallengeSchema) => {
        this.personalChallengeForm.patchValue({
          challengeId: selectedSchema.id,
          name: selectedSchema.name,
          initialPoints: selectedSchema.initialPoints,
          type: selectedSchema.type,
          minLevel: selectedSchema.minLevel,
          maxLevel: selectedSchema.maxLevel,
          pointsToLevelUp: selectedSchema.pointsToLevelUp,
          pointsToHoldLevel: selectedSchema.pointsToHoldLevel,
          initialLevel: selectedSchema.initialLevel,
          levelDuration: selectedSchema.levelDuration,
          canAscend: selectedSchema.canAscend,
          canDescend: selectedSchema.canDescend,
          hasFixedDate: selectedSchema.hasFixedDate,
        });

        this.pointsSourceIds = Array.from(selectedSchema.pointsSourceIds);
        this.pointsSourceDataSource = new MatTableDataSource(
          this.adminService.pointsSources.filter((pointSource) =>
            this.pointsSourceIds.includes(pointSource.id)
          )
        );
      }
    );

    this.personalChallengeForm.get('type').valueChanges.subscribe((type) => {
      switch (Number(type)) {
        case ChallengeTypes.Walk:
          this.personalChallengeForm.get('canAscend').setValue(true);
          this.personalChallengeForm.get('canDescend').setValue(true);
          this.personalChallengeForm.get('hasFixedDate').setValue(false);
          break;

        case ChallengeTypes.WalkFixedDate:
          this.personalChallengeForm.get('canAscend').setValue(true);
          this.personalChallengeForm.get('canDescend').setValue(true);
          this.personalChallengeForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyDownwards:
          this.personalChallengeForm.get('canAscend').setValue(false);
          this.personalChallengeForm.get('canDescend').setValue(true);
          this.personalChallengeForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyUpwards:
          this.personalChallengeForm.get('canAscend').setValue(true);
          this.personalChallengeForm.get('canDescend').setValue(false);
          this.personalChallengeForm.get('hasFixedDate').setValue(true);
          break;
      }
    });
  }

  get personalChallengeSchemas(): Array<PersonalChallengeSchema> {
    return this.adminService.personalChallenges;
  }

  public ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.adminService
      .getPointsSources()
      .then((pointSources) => {
        this.selectablePointsSources = [...pointSources];
      })
      .catch((error) => {
        console.log('adminService.getPointsSources - error: ', error);
      });
  }

  public getTranslations(): void {
    this.translateService
      .stream(['ADD_PERSONAL_CHALLENGE_CREATED'])
      .subscribe((values) => {
        this.ADD_PERSONAL_CHALLENGE_CREATED =
          values.ADD_PERSONAL_CHALLENGE_CREATED;
      });
  }

  public returnPage(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public removePointsSource(pointsSourceId: string): void {
    this.pointsSourceIds.splice(
      this.pointsSourceIds.findIndex(
        (pointsSources) => pointsSources === pointsSourceId
      ),
      1
    );
  }

  public async addPersonalChallenge(): Promise<void> {
    this.showSpinner = true;
    if (this.personalChallengeForm.valid) {
      const challengeId = this.personalChallengeForm.controls.challengeId.value;
      const companyId = this.companyId;

      const pointsSourceIds = new Set(this.pointsSourceIds);
      const pointSources = this.selectablePointsSources.filter((pointSource) =>
        this.pointsSourceIds.includes(pointSource.id)
      );

      // TODO: add try catch structures when connecting to firebase
      await this.adminService.createCompanyPointSources(
        companyId,
        pointSources
      );

      const endDate = this.personalChallengeForm.controls.hasFixedDate.value
        ? new Date(this.personalChallengeDateRange.value.end)
        : null;

      if (endDate !== null) {
        endDate.setHours(23, 59, 59);
      }

      const challengeSchema = new ChallengeSchema().deserialize({
        id: challengeId,
        initialPoints: this.personalChallengeForm.controls.initialPoints.value,
        name: this.personalChallengeForm.controls.name.value,
        type: Number(this.personalChallengeForm.controls.type.value),
        maxLevel: this.personalChallengeForm.controls.maxLevel.value,
        minLevel: this.personalChallengeForm.controls.minLevel.value,
        pointsToLevelUp: this.personalChallengeForm.controls.pointsToLevelUp
          .value,
        pointsToHoldLevel: this.personalChallengeForm.controls.pointsToHoldLevel
          .value,
        initialLevel: this.personalChallengeForm.controls.initialLevel.value,
        pointsSourceIds,
        levelDuration: this.personalChallengeForm.controls.levelDuration.value,
        canAscend: this.personalChallengeForm.controls.canAscend.value,
        canDescend: this.personalChallengeForm.controls.canDescend.value,
        hasFixedDate: this.personalChallengeForm.controls.hasFixedDate.value,
        startDate: this.personalChallengeForm.controls.hasFixedDate.value
          ? new Date(this.personalChallengeDateRange.value.start).getTime()
          : null,
        endDate: endDate !== null ? endDate.getTime() : null,
      });

      await this.firebaseService.createPersonalChallenge(
        companyId,
        challengeSchema,
        challengeId
      );

      this.showSpinner = false;
      this.snackBar.open(this.ADD_PERSONAL_CHALLENGE_CREATED, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }
}
