import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StatisticsBasis } from 'src/app/constants/constants';
import { Helpers } from 'src/app/helpers/helpers';
import { AssetsConstants } from 'src/app/models/assets-constants';

// Models
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
// eslint-disable-next-line max-len
import { CitiesChallengeDayPerformance } from 'src/app/models/cities-challenge/cities-day-challenge-performance/cities-challenge-day-performance.model';

// Services
import { CitiesChallengeService } from 'src/app/services/cities-challenge/cities-challenge.service';
import { StatisticsService } from 'src/app/services/statistics/statistics.service';
import { ICitiesChallengeDashboard } from 'src/app/shared/components/cities-challenge-dashboard/cities-challenge-dashboard.component';
import { INoCurrentChallenge } from 'src/app/shared/widgets/no-current-challenge/no-current-challenge/no-current-challenge.component';

@Component({
  selector: 'app-cities-challenge-container',
  templateUrl: './cities-challenge-container.component.html',
  styleUrls: ['./cities-challenge-container.component.scss'],
})
export class CitiesChallengeContainerComponent implements OnInit {
  @Input() companyId: string;
  @Input() challengeId: string;

  public loading = true;
  public citiesChallenges: Array<CitiesChallenge>;
  public currentCitiesChallengeDashboard: ICitiesChallengeDashboard;
  public completedCitiesChallengeDashboard: ICitiesChallengeDashboard;
  public nextCitiesChallenge: CitiesChallenge;
  public completedCitiesChallenges: Array<CitiesChallenge> = [];
  public noCitiesChallenge: INoCurrentChallenge = {
    image: AssetsConstants.GroupOfPeopleAndLogo,
    title: 'CITIES_CHALLENGE.NO_CURRENT_CHALLENGE.TITLE',
  };

  public selectedChallengeFormControl: FormControl = new FormControl();
  public selectedChallenge: CitiesChallenge;

  constructor(
    private route: ActivatedRoute,
    private citiesChallengeService: CitiesChallengeService,
    private statisticsService: StatisticsService
  ) {}

  ngOnInit(): void {
    this.selectedChallengeFormControl.valueChanges.subscribe((challenge) =>
      this.setCompletedCitiesChallengeDashboardData(challenge.id)
    );

    if (!this.companyId) {
      this.companyId = this.route.snapshot.paramMap.get('companyId');
      this.challengeId = this.route.snapshot.paramMap.get('challengeId');
    }

    if (this.companyId) {
      this.citiesChallengeService
        .getCompanyCitiesChallenges(this.companyId)
        .then((citiesChallenges) => {
          const today = Helpers.createISODate();
          this.citiesChallenges = citiesChallenges;
          this.completedCitiesChallenges = citiesChallenges.filter(
            (challenge) => challenge.endDate < today
          );
          if (citiesChallenges.length > 0) {
            if (this.challengeId) {
              this.getCitiesChallengeDashboardData(
                this.challengeId,
                this.companyId
              ).then((currentCitiesChallengeDashboard) => {
                this.currentCitiesChallengeDashboard = currentCitiesChallengeDashboard;
                this.loading = false;
              });
            } else {
              this.nextCitiesChallenge = this.citiesChallenges
                .filter((challenge) => challenge.startDate > today)
                .reverse()[0];
              this.loading = false;
            }
          }

          if (this.completedCitiesChallenges.length === 0) {
            this.selectedChallengeFormControl.disable({ emitEvent: false });
            this.loading = false;
          }
        });
    }
  }

  private setCompletedCitiesChallengeDashboardData(challengeId: string): void {
    this.completedCitiesChallengeDashboard = null;
    this.loading = true;
    this.getCitiesChallengeDashboardData(challengeId, this.companyId)
      .then(
        (citiesChallengeDashboard) =>
          (this.completedCitiesChallengeDashboard = citiesChallengeDashboard)
      )
      .finally(() => (this.loading = false));
  }

  public getCitiesChallengeDashboardData(
    challengeId: string,
    companyId: string
  ): Promise<ICitiesChallengeDashboard> {
    const citiesChallenge = this.citiesChallenges.find(
      (challenge) => challenge.id === challengeId
    );
    const statisticsPromise = this.statisticsService.getTrainingsStatistics(
      companyId,
      StatisticsBasis.Challenges,
      challengeId
    );
    const citiesChallengeDailyPerformancePromise = this.citiesChallengeService.getCitiesChallengeDailyPerformances(
      companyId,
      challengeId
    );

    return Promise.all([
      statisticsPromise,
      citiesChallengeDailyPerformancePromise,
    ])
      .then(([companyTrainingData, citiesChallengeDayPerformances]) => {
        citiesChallengeDayPerformances.push(
          new CitiesChallengeDayPerformance({
            date: Helpers.formatDateYYYYMMDD(new Date()),
            ...citiesChallenge.totalPerformance.toObject(),
          })
        );
        return {
          citiesChallenge,
          companyTrainingData,
          citiesChallengeDays: citiesChallengeDayPerformances,
          companyId,
        };
      })
      .catch((error) => {
        console.log(
          'Error getting cities challenge and training data: ',
          error
        );
        return {
          citiesChallenge: null,
          companyTrainingData: [],
          citiesChallengeDays: [],
          companyId: null,
        };
      });
  }
}
