<div class="container">
  <div class="spinner-container" *ngIf="showInputDataForm">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!showInputDataForm">
    <form [formGroup]="inputDataForm" (ngSubmit)="updateSteps()">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'INPUT_STEPS_DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>{{ 'INPUT_STEPS_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'INPUT_STEPS_AMOUNT_STEPS' | translate }}</mat-label>
        <input matInput formControlName="steps" type="number" />
        <mat-error>{{ 'INPUT_STEPS_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'INPUT_STEPS_INSERT_EMAIL' | translate }}</mat-label>
        <input matInput formControlName="email" type="email" />
        <mat-error *ngIf="inputDataForm.controls['email'].errors?.required">{{
          'INPUT_STEPS_ERROR' | translate
        }}</mat-error>
        <mat-error *ngIf="inputDataForm.controls['email'].errors?.pattern">{{
          'INPUT_STEPS_ERROR_EMAIL' | translate
        }}</mat-error>
        <mat-hint
          *ngIf="
            !inputDataForm.controls['email'].errors?.required &&
            !inputDataForm.controls['email'].errors?.pattern &&
            notCompanyMember
          "
          >{{ 'INPUT_STEPS_ERROR_NOT_COMPANY_MEMBER' | translate }}</mat-hint
        >
      </mat-form-field>
      <div class="buttons">
        <button
          mat-raised-button
          [disabled]="!inputDataForm.valid || notCompanyMember"
        >
          {{ 'INPUT_STEPS_ADD_STEPS' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
