import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { REGEX_EMAIL } from 'src/app/constants/constants';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { Settings } from 'src/app/models/settings.model';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { UserService } from 'src/app/services/user/user.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-data-deletion-request',
  templateUrl: './data-deletion-request.component.html',
  styleUrls: ['./data-deletion-request.component.scss'],
})
export class DataDeletionRequestComponent implements OnInit {
  public dataDeletionRequest: UntypedFormGroup;

  public isProdEnvironment = environment.production;
  public isMobile = false;
  public colSize = 2;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private settingsService: SettingsService
  ) {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
        if (this.isMobile) {
          this.colSize = 1;
        } else {
          this.colSize = 2;
        }
      });
  }

  ngOnInit(): void {
    this.dataDeletionRequest = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
      password: ['', [Validators.required]],
    });
  }

  get email(): AbstractControl {
    return this.dataDeletionRequest.controls.email;
  }
  get password(): AbstractControl {
    return this.dataDeletionRequest.controls.password;
  }
  get settings(): Settings {
    return this.settingsService.settings;
  }

  public changeLanguage(lang: string): void {
    this.settingsService.changeLanguage(lang);
  }

  public scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  public requestDataDelete(): void {
    const requestDataDialog = this.translateService.instant(
      'ACCOUNT_DELETION_PAGE.DIALOG'
    );
    console.log('requestDataDialog: ', requestDataDialog);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: requestDataDialog.TITLE,
        message: requestDataDialog.MESSAGE,
        showSpinner: true,
        action: () =>
          this.userService.deleteUser(this.email.value, this.password.value),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.snackBar.open(requestDataDialog.SUCCESS, 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors: {
          const requestDataDialogError = this.translateService.instant(
            'ACCOUNT_DELETION_PAGE.DIALOG.ERROR',
            { error: this.userService.errorMessages[result.error] }
          );
          this.snackBar.open(requestDataDialogError, 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          break;
        }
      }
    });
  }
}
