import { EventStatus } from './event-status.enum';
import { EventTypes } from './event-types.enum';

export interface IEventFinalProgress {
  id: string;
  avatar: string;
  country: string;
  location: string;
  locationIds: Array<string>;
  nickname: string;
  progress: number;
  steps: number;
}

export interface IEventInitializer {
  id?: string;
  name?: string;
  days?: Array<string>;
  distance?: number;
  type?: EventTypes;
  status?: EventStatus;
  finalProgressPerUser?: object; // final progress after the end of the challenge
  progress?: number;
}

export class Event {
  public id = '';
  public name = '';
  public days: Array<string> = [];
  public distance = 0;
  public type = EventTypes.Running;
  public status = EventStatus.NotStarted;
  public finalProgressPerUser: Array<IEventFinalProgress> = null;
  public progress = 0;

  constructor(initializer?: IEventInitializer) {
    Object.assign(this, initializer);

    if (initializer && initializer.finalProgressPerUser) {
      this.finalProgressPerUser = Object.entries(
        initializer.finalProgressPerUser
      )
        .map((element) => Object.assign(element[1], { id: element[0] }))
        .sort(
          (trainingA, trainingB) => trainingB.progress - trainingA.progress
        );
    }
  }

  public deserialize(input: any): this {
    return Object.assign(this, input);
  }

  public toObject(): any {
    return Object.assign({}, this);
  }
}
