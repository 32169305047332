import { Component, OnDestroy } from '@angular/core';

// Models
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';

// Services
import { CompanyService } from 'src/app/services/company/company.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnDestroy {
  constructor(
    private layoutService: LayoutService,
    private userService: UserService,
    private companyService: CompanyService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnDestroy(): void {
    if (this.user && this.user.teamId !== null) {
      this.subscriptionService.unsubscribeFromTeamChallengeWeeks();
      this.subscriptionService.unsubscribeFromTeam();
    }
  }

  get user(): User {
    return this.userService.user;
  }

  get company(): Company {
    return this.companyService.company;
  }

  get sidebarOpened(): boolean {
    return this.layoutService.sidebarOpened;
  }
}
