import { Deserializable } from './deserializable.model';

export class CompletedChallenge implements Deserializable {
  userId: string;
  displayName: string;
  date: number;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  toObject(): CompletedChallenge {
    return Object.assign({}, this);
  }
}
