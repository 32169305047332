<div class="container">
  <h1 class="title">
    {{ 'forms.notification.PUSH_NOTIFICATION' | translate }}
  </h1>
  <mat-tab-group *ngIf="user && company">
    <mat-tab label="{{ 'forms.notification.NEW' | translate}}">
      <app-push-notifications-form *ngIf="sendNotificationTypeByRole[user.role]"
      [sendNotificationType]="sendNotificationTypeByRole[user.role]"
      [company]="company"
      [possibleRecipients]="sendNotificationTypeByRole[user.role] === SEND_NOTIFICATION_TYPE.ProjectManager ? managedCompanies :
      sendNotificationTypeByRole[user.role] === SEND_NOTIFICATION_TYPE.MoveMeAdmin ? adminCompanies : possibleRecipients"
      ></app-push-notifications-form>
      <div *ngIf="user && user.role === ROLES_USER" class="error">
        <p>
          {{ 'forms.notification.NOTIFICATION_PAGE_ERROR' | translate }}
        </p>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'forms.notification.NOTIFICATIONS_SENT' | translate}}" *ngIf="company.features.has(FEATURES.ProjectManager)">
      <app-sent-push-notifications-table [pushNotifications]="pushNotificationsSent">
      </app-sent-push-notifications-table>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="!user || !company" class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</div>
