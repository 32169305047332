import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/compat/app';

// Models
import { Company } from 'src/app/models/company.model';
import { SendNotificationType } from 'src/app/models/send-notification-type';
import { Team } from 'src/app/models/team.model';

// Services
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { REGEX_LINK } from 'src/app/constants/constants';
import { Notification } from 'src/app/models/notification.model';
import { GenericValidators } from 'src/app/validators/generic-validators/generic-validators';

interface IFunctionsMap {
  [prop: string]: () => Promise<firebase.functions.HttpsCallableResult>;
}

@Component({
  selector: 'app-push-notifications-form',
  templateUrl: './push-notifications-form.component.html',
  styleUrls: ['./push-notifications-form.component.scss'],
})
export class PushNotificationsFormComponent implements OnChanges {
  @Input() sendNotificationType: SendNotificationType;
  @Input() company: Company;
  @Input() possibleRecipients: Array<Company | Team> = [];

  public TITLE_CHARACTERS_LIMIT = 75;
  public BODY_CHARACTERS_LIMIT = 500;

  public notificationForm: UntypedFormGroup = this.formBuilder.group({
    recipientGroup: [[]],
    titleMessageDe: [
      '',
      [
        Validators.required,
        GenericValidators.limitInput(this.TITLE_CHARACTERS_LIMIT),
      ],
    ],
    titleMessageEn: [
      '',
      [
        Validators.required,
        GenericValidators.limitInput(this.TITLE_CHARACTERS_LIMIT),
      ],
    ],
    notificationMessageDe: [
      '',
      Validators.compose([
        Validators.required,
        GenericValidators.limitInput(this.BODY_CHARACTERS_LIMIT),
      ]),
    ],
    notificationMessageEn: [
      '',
      Validators.compose([
        Validators.required,
        GenericValidators.limitInput(this.BODY_CHARACTERS_LIMIT),
      ]),
    ],
    link: ['', Validators.compose([Validators.pattern(REGEX_LINK)])],
    deLanguage: [true],
    enLanguage: [true],
  });

  public isSendingNotification = false;

  public SEND_NOTIFICATION_TYPE = SendNotificationType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.possibleRecipients) {
      if (
        (changes.possibleRecipients.currentValue as Array<Company | Team>)
          .length > 0
      ) {
        Object.keys(this.notificationForm.controls).forEach((key) => {
          this.notificationForm.controls[key].enable();
        });
        if (
          this.sendNotificationType !== SendNotificationType.ProjectManager &&
          this.sendNotificationType !== SendNotificationType.MoveMeAdmin
        ) {
          this.notificationForm.controls.recipientGroup.setValue([
            this.possibleRecipients[0].id,
          ]);
          this.notificationForm.controls.recipientGroup.disable();
        }
      } else {
        Object.keys(this.notificationForm.controls).forEach((key) => {
          this.notificationForm.controls[key].disable();
        });
      }
    }
  }

  public sendNotification(): void {
    const notificationResponse = this.translateService.instant(
      'forms.notification'
    );
    this.isSendingNotification = true;

    const titleDe = this.notificationForm.value.titleMessageDe
      ? this.notificationForm.value.titleMessageDe
      : this.notificationForm.value.titleMessageEn;
    const titleEn = this.notificationForm.value.titleMessageEn
      ? this.notificationForm.value.titleMessageEn
      : this.notificationForm.value.titleMessageDe;
    const messageDe = this.notificationForm.value.notificationMessageDe
      ? this.notificationForm.value.notificationMessageDe
      : this.notificationForm.value.notificationMessageEn;
    const messageEn = this.notificationForm.value.notificationMessageEn
      ? this.notificationForm.value.notificationMessageEn
      : this.notificationForm.value.notificationMessageDe;

    const notification = new Notification({
      id: '',
      read: false,
      title: {
        de: titleDe,
        en: titleEn,
      },
      message: {
        de: messageDe,
        en: messageEn,
      },
      link: this.notificationForm.value.link
        ? this.notificationForm.value.link
        : null,
      recipientCompanyIds: this.notificationForm.controls.recipientGroup.value,
    });

    const sendNotifications: IFunctionsMap = {
      [SendNotificationType.Team]: this.firebaseService.sendTeamLeaderNotification.bind(
        this,
        this.company.id,
        this.notificationForm.controls.recipientGroup.value,
        notification
      ),
      [SendNotificationType.Company]: this.firebaseService.sendAdminNotification.bind(
        this,
        notification,
        this.company.id
      ),
      [SendNotificationType.ProjectManager]: this.firebaseService.sendAdminNotification.bind(
        this,
        notification,
        this.company.id
      ),
      [SendNotificationType.MoveMeAdmin]: this.firebaseService.sendAdminNotification.bind(
        this,
        notification,
        this.company.id
      ),
    };

    sendNotifications[this.sendNotificationType]()
      .then(() => {
        this.snackBar.open(
          notificationResponse.NOTIFICATION_SENT_SUCCESFULLY,
          'Ok',
          {
            duration: 5000,
            panelClass: 'snack-bar-color',
          }
        );
      })
      .catch(() => {
        this.snackBar.open(notificationResponse.NOTIFICATION_ERROR, 'Ok', {
          duration: 5000,
          panelClass: 'snack-bar-color',
        });
      })
      .finally(() => {
        this.notificationForm.patchValue({
          titleMessageDe: '',
          titleMessageEn: '',
          notificationMessageDe: '',
          notificationMessageEn: '',
          link: '',
        });
        this.isSendingNotification = false;
      });
  }

  public switchValidator(language: string): void {
    if (language === 'de') {
      this.notificationForm.controls.titleMessageDe.setValidators(
        this.notificationForm.get('deLanguage').value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.TITLE_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.titleMessageDe.setValue('');
      this.notificationForm.controls.titleMessageDe.updateValueAndValidity();
      this.notificationForm.controls.notificationMessageDe.setValidators(
        this.notificationForm.get('deLanguage').value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.BODY_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.notificationMessageDe.setValue('');
      this.notificationForm.controls.notificationMessageDe.updateValueAndValidity();
    }
    if (language === 'en') {
      this.notificationForm.controls.titleMessageEn.setValidators(
        this.notificationForm.get('enLanguage').value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.TITLE_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.titleMessageEn.setValue('');
      this.notificationForm.controls.titleMessageEn.updateValueAndValidity();
      this.notificationForm.controls.notificationMessageEn.setValidators(
        this.notificationForm.get('enLanguage').value
          ? [
              Validators.required,
              GenericValidators.limitInput(this.BODY_CHARACTERS_LIMIT),
            ]
          : null
      );
      this.notificationForm.controls.notificationMessageEn.setValue('');
      this.notificationForm.controls.notificationMessageEn.updateValueAndValidity();
    }
  }
}
