import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';

// Models
import {
  CITIES_CHALLENGE_TYPES,
  CitiesChallenge,
} from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { Route } from 'src/app/models/cities-challenge/route/route.model';

// Services
import { AdminService } from 'src/app/services/admin/admin.service';
import { RoutesService } from 'src/app/services/routes/routes.service';
import { CitiesChallengeService } from 'src/app/services/cities-challenge/cities-challenge.service';
import { TabIndex } from '../company-info/company-info.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-add-cities-challenge',
  templateUrl: './add-cities-challenge.component.html',
  styleUrls: ['./add-cities-challenge.component.scss'],
})
export class AddCitiesChallengeComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;

  public challengeDataForm: UntypedFormGroup;

  public citiesChallengeType: string = null;
  public citiesChallenge: CitiesChallenge;
  public companyId: string;

  public challengeTypes = CITIES_CHALLENGE_TYPES;

  public showCreatingCitiesChallengeSpinner = false;

  private SNACKBAR_TYPES = {
    CREATE_FAILURE: 'EDIT_CITIES_CHALLENGE.FAILURE_SNACKBAR.CREATE',
    CREATE_SUCCESS: 'EDIT_CITIES_CHALLENGE.SUCCESS_SNACKBAR.CREATE',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private routesService: RoutesService,
    private citiesChallengeService: CitiesChallengeService,
    private translateService: TranslateService
  ) {
    this.challengeDataForm = this.citiesChallengeService.getStandardCitiesChallengeDataForm();
  }

  get defaultRoutes(): Array<Route> {
    return this.adminService.routes;
  }

  get challengeRegions(): FormArray<FormGroup> {
    return this.challengeDataForm.controls.regions as UntypedFormArray;
  }

  public ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
  }

  public goToCompanyInfo(): void {
    this.router.navigate(['..'], {
      relativeTo: this.route,
      state: { companyInfoTab: TabIndex.CitiesChallenges },
    });
  }

  public goBack(): void {
    if (this.stepper.selectedIndex === 0) {
      this.goToCompanyInfo();
    } else {
      this.stepper.previous();
    }
  }

  public goForth(): void {
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  public setSelectedType(type: string): void {
    this.citiesChallengeType = type;
    this.goForth();
  }

  private showSnackBar(snackBarType: string, error?: string): void {
    this.snackBar.open(
      this.translateService.instant(snackBarType, error ? { error } : null),
      this.translateService.instant('Ok'),
      {
        duration: 4000,
        panelClass: 'snack-bar-color',
      }
    );
  }

  public setSelectedRoute(selectedRoute: Route): void {
    if (!selectedRoute) {
      selectedRoute = new Route();
    }

    this.challengeRegions.clear();

    const regionRouteDataForm = this.routesService.getStandardRouteDataForm();
    this.routesService.setRouteDataFormValues(
      regionRouteDataForm,
      selectedRoute,
      this.citiesChallengeType !== CITIES_CHALLENGE_TYPES[0]
    );

    this.challengeRegions.push(
      this.formBuilder.group({
        route: regionRouteDataForm,
        dateRange: new UntypedFormGroup({
          start: new UntypedFormControl(),
          end: new UntypedFormControl(),
        }),
        duration: [0, Validators.min(1)],
      })
    );

    this.challengeDataForm.patchValue({
      name: regionRouteDataForm.controls.name.value,
      totalDistance: regionRouteDataForm.controls.totalDistance.value,
      regionsNumber: 1,
    });

    this.goForth();
  }

  public disableDataFormForOverview(): void {
    this.citiesChallengeService.disableForm(this.challengeDataForm);

    this.goForth();
  }

  public stepperChanged(stepperSelectionEvent: StepperSelectionEvent): void {
    if (
      stepperSelectionEvent.previouslySelectedIndex >
      stepperSelectionEvent.selectedIndex
    ) {
      stepperSelectionEvent.selectedStep.completed = false;
      stepperSelectionEvent.previouslySelectedStep.reset();
    }

    if (stepperSelectionEvent.previouslySelectedIndex === 3) {
      this.enableDataForm();
    }
  }

  public enableDataForm(): void {
    this.citiesChallengeService.enableFormBasedOnType(
      this.challengeDataForm,
      this.citiesChallengeType
    );
  }

  public async createCompanyCityChallenge(): Promise<void> {
    this.showCreatingCitiesChallengeSpinner = true;

    try {
      await this.citiesChallengeService.setCompanyCitiesChallenge(
        this.companyId,
        null,
        this.challengeDataForm,
        this.citiesChallengeType
      );
    } catch (error) {
      this.showSnackBar(this.SNACKBAR_TYPES.CREATE_FAILURE, error);
      return Promise.reject(error);
    } finally {
      this.showCreatingCitiesChallengeSpinner = false;
    }

    this.showSnackBar(this.SNACKBAR_TYPES.CREATE_SUCCESS);
    this.goToCompanyInfo();
  }
}
