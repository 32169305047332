import { Injectable } from '@angular/core';

// Models

import { Company } from 'src/app/models/company.model';
import { PointsSource } from 'src/app/models/points-source.model';
import { TeamSchema } from 'src/app/models/team-schema.model';
import { WhiteLabel } from 'src/app/models/white-label.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

// Helpers
import { PersonalChallengeSchema } from 'src/app/models/personal-challenge-schema/personal-challenge-schema';
import { TeamChallengeSchema } from 'src/app/models/team-challenge-schema/team-challenge-schema';
import { Features } from 'src/app/models/features.enum';
import { Route } from 'src/app/models/cities-challenge/route/route.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { GroupRunChallenge } from 'src/app/models/group-run/group-run-challenge/group-run-challenge';
import { GroupRunChallengeService } from '../group-run-challenge/group-run-challenge.service';
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { Challenge } from 'src/app/models/challenge/challenge.abstract';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';

export const CHALLENGES = {
  PERSONAL: 'PERSONAL',
  TEAM: 'TEAM',
  GROUP_RUN: 'GROUP_RUN',
};

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public companies: Array<Company> = [];
  public cities: Array<City> = [];
  public teams: Array<TeamSchema> = [];
  public pointsSources: Array<PointsSource> = [];
  public routes: Array<Route> = [];
  public whiteLabels: Array<WhiteLabel> = [];
  public personalChallenges: Array<PersonalChallengeSchema> = [];
  public teamChallenges: Array<TeamChallengeSchema> = [];
  public managerCompanies: Array<Company> = [];
  public groupRunChallenges: Array<GroupRunChallenge> = [];

  constructor(
    private firebaseService: FirebaseService,
    private groupRunChallengeService: GroupRunChallengeService
  ) {}

  public async getFirebaseData(): Promise<void> {
    await this.getCompanies();
    await this.getCities();
    await this.getTeamSchemas();
    await this.getPointsSources();
    await this.getRoutes();
    await this.getWhiteLabels();
    await this.getPersonalChallenges();
    await this.getTeamChallenges();
    await this.getGroupRunChallenges();
  }

  public getCompanies(): Promise<void> {
    return this.firebaseService
      .getCompanies()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.companies = querySnapshot.docs
            .map((doc) => new Company(doc.data()))
            .sort((companyA, companyB) => {
              const nameA = companyA.name.toLowerCase();
              const nameB = companyB.name.toLowerCase();

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          this.managerCompanies = this.companies.filter((company) =>
            company.features.has(Features.ProjectManager)
          );
        }
      })
      .catch((error) => {
        console.log('error getting company data: ', error);
      });
  }

  public getPersonalChallenges(): Promise<Array<PersonalChallengeSchema>> {
    return this.firebaseService
      .getDefaultPersonalChallenges()
      .then((defaultChallengesQuery) => {
        if (!defaultChallengesQuery.empty) {
          this.personalChallenges = defaultChallengesQuery.docs.map(
            (defaultChallengeDoc) =>
              new PersonalChallengeSchema(defaultChallengeDoc.data())
          );
          return this.personalChallenges;
        }
        return [];
      })
      .catch((error) => {
        console.log('error: ', error);
        return [];
      });
  }

  public createPersonalChallenge(
    challengeSchema: PersonalChallengeSchema
  ): Promise<void> {
    return this.firebaseService.createDefaultPersonalChallenge(challengeSchema);
  }

  public getTeamChallenges(): Promise<Array<TeamChallengeSchema>> {
    return this.firebaseService
      .getDefaultTeamChallenges()
      .then((defaultChallengesQuery) => {
        if (!defaultChallengesQuery.empty) {
          this.teamChallenges = defaultChallengesQuery.docs.map(
            (defaultChallengeDoc) =>
              new TeamChallengeSchema(defaultChallengeDoc.data())
          );
          return this.teamChallenges;
        }
        return [];
      })
      .catch((error) => {
        console.log('error: ', error);
        return [];
      });
  }

  public createTeamChallenge(challenge: TeamChallengeSchema): Promise<void> {
    return this.firebaseService.createDefaultTeamChallenge(challenge);
  }

  public async deleteChallenge(
    challenge: ChallengeSchema | TeamChallenge | Challenge,
    challengeType: string
  ): Promise<void | MatDialogRef<ConfirmationDialogComponent>> {
    let indexToDelete: number;
    try {
      switch (challengeType) {
        case CHALLENGES.PERSONAL: {
          await this.firebaseService.deleteDefaultPersonalChallenge(
            challenge.id
          );
          indexToDelete = this.personalChallenges.findIndex(
            (personalChallenge) => personalChallenge.id === challenge.id
          );
          this.personalChallenges.splice(indexToDelete, 1);
          break;
        }
        case CHALLENGES.TEAM: {
          this.firebaseService.deleteDefaultTeamChallenge(challenge.id);
          indexToDelete = this.teamChallenges.findIndex(
            (teamChallenge) => teamChallenge.id === challenge.id
          );
          this.teamChallenges.splice(indexToDelete, 1);
          break;
        }
        case CHALLENGES.GROUP_RUN: {
          const deleteDialog = this.groupRunChallengeService.deleteGroupRunChallengeWithConfirmation(
            challenge as GroupRunChallenge
          );
          deleteDialog
            .afterClosed()
            .subscribe((result: ConfirmationDialogResponse) => {
              if (
                result.code ===
                ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly
              ) {
                indexToDelete = this.groupRunChallenges.findIndex(
                  (groupRunChallenge) => groupRunChallenge.id === challenge.id
                );
                this.groupRunChallenges.splice(indexToDelete, 1);
              }
            });
          return deleteDialog;
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private getCities(): Promise<void> {
    return this.firebaseService
      .getCities()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.cities = querySnapshot.docs
            .map((doc) => new City(doc.data()))
            .sort((cityA, cityB) => (cityB.name > cityA.name ? -1 : 1));
        }
      })
      .catch((error) => {
        console.log('error getting cities data: ', error);
      });
  }

  public getTeamSchemas(): Promise<Array<TeamSchema>> {
    return this.firebaseService
      .getTeamSchemas()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.teams = querySnapshot.docs
            .map((doc) => new TeamSchema().deserialize(doc.data()))
            .sort((teamA, teamB) => (teamB.name > teamA.name ? -1 : 1));
        }
        return this.teams;
      })
      .catch((error) => {
        console.log('error getting teams data: ', error);
        return [];
      });
  }

  public getPointsSources(): Promise<Array<PointsSource>> {
    return this.firebaseService
      .getPointsSources()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.pointsSources = querySnapshot.docs.map((doc) =>
            new PointsSource().deserialize(doc.data())
          );
        }
        return this.pointsSources;
      })
      .catch((error) => {
        console.log('error getting points sources data: ', error);
        return [];
      });
  }

  public createCompanyPointSources(
    companyId: string,
    pointSources: Array<PointsSource>
  ): Promise<Array<void>> {
    return Promise.all(
      pointSources.map((pointSource) =>
        this.firebaseService.createPointsSourceInCompany(companyId, pointSource)
      )
    );
  }

  private getRoutes(): Promise<void> {
    return this.firebaseService
      .getRoutes()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.routes = querySnapshot.docs.map((doc) => new Route(doc.data()));
        } else {
          this.routes = [];
        }
      })
      .catch((error) => {
        console.log('error getting routes data: ', error);
      });
  }

  private getWhiteLabels(): Promise<void> {
    return this.firebaseService
      .getWhiteLabels()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.whiteLabels = querySnapshot.docs.map(
            (docSnapshot) => new WhiteLabel(docSnapshot.data())
          );
        } else {
          this.whiteLabels = [];
        }
      })
      .catch((error) => {
        console.log('error getting white labels data: ', error);
      });
  }

  public async deleteCity(cityId: string): Promise<void> {
    try {
      await this.firebaseService.deleteCity(cityId);
    } catch (error) {
      return Promise.reject(error);
    }
    const indexToDelete = this.cities.findIndex((city) => city.id === cityId);
    this.cities.splice(indexToDelete, 1);
  }

  public async deleteRoute(routeId: string): Promise<void> {
    try {
      await this.firebaseService.deleteRoute(routeId);
    } catch (error) {
      return Promise.reject(error);
    }
    const indexToDelete = this.routes.findIndex(
      (route) => route.id === routeId
    );
    this.routes.splice(indexToDelete, 1);
  }

  public async deleteTeam(teamId: string): Promise<void> {
    try {
      await this.firebaseService.deleteTeamSchema(teamId);
    } catch (error) {
      return Promise.reject(error);
    }
    const indexToDelete = this.teams.findIndex((team) => team.id === teamId);
    this.teams.splice(indexToDelete, 1);
  }

  public async deleteWhiteLabel(whiteLabelId: string): Promise<void> {
    try {
      await this.firebaseService.deleteWhiteLabel(whiteLabelId);
    } catch (error) {
      return Promise.reject(error);
    }
    const indexToDelete = this.whiteLabels.findIndex(
      (whiteLabel) => whiteLabel.id === whiteLabelId
    );
    this.whiteLabels.splice(indexToDelete, 1);
  }

  public async deletePointsSource(pointsSourceId: string): Promise<void> {
    try {
      await this.firebaseService.deletePointsSource(pointsSourceId);
    } catch (error) {
      return Promise.reject(error);
    }
    const indexToDelete = this.pointsSources.findIndex(
      (pointsSource) => pointsSource.id === pointsSourceId
    );
    this.pointsSources.splice(indexToDelete, 1);
  }

  public getGroupRunChallenges(): Promise<Array<GroupRunChallenge>> {
    return this.groupRunChallengeService
      .getGroupRunChallenges()
      .then((groupRunChallenges) => {
        this.groupRunChallenges = groupRunChallenges;
        return this.groupRunChallenges;
      })
      .catch((error) => {
        console.log('error: ', error);
        return [];
      });
  }
}
