import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public sidebarOpened = window.innerWidth > 900;

  constructor() {}

  public toggleSidebar(): void {
    this.sidebarOpened = !this.sidebarOpened;
  }
}
