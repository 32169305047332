<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'Edit Personal Challenge' | translate }}</h1>
  </div>

  <form [formGroup]="challengeDataForm">
    <mat-form-field>
      <mat-label>{{ 'ADD_PERSONAL_CHALLENGE_COMPANY' | translate }}</mat-label>
      <mat-select formControlName="companyId">
        <mat-option *ngFor="let company of companies" value="{{ company.id }}">
          {{ company.name }}
        </mat-option>
      </mat-select>
      <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_PERSONAL_CHALLENGE_ID' | translate }}</mat-label>
      <input matInput formControlName="id" />
      <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_PERSONAL_CHALLENGE_NAME' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <div class="challenge-details">
      <mat-form-field>
        <mat-label>{{ 'Initial Points' | translate }}</mat-label>
        <input type="number" matInput formControlName="initialPoints" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Initial Level' | translate }}</mat-label>
        <input type="number" matInput formControlName="initialLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Minimal Level' | translate }}</mat-label>
        <input type="number" matInput formControlName="minLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Maximal Level' | translate }}</mat-label>
        <input type="number" matInput formControlName="maxLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Level Duration' | translate }}</mat-label>
        <input type="number" matInput formControlName="levelDuration" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PointsToLevelUp' | translate }}</mat-label>
        <input type="number" matInput formControlName="pointsToLevelUp" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'pointsToHoldLevel' | translate }}</mat-label>
        <input type="number" matInput formControlName="pointsToHoldLevel" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="checkboxes">
      <mat-form-field>
        <mat-label>{{ 'Type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let challengeType of challengeTypesNames; let i = index"
            value="{{ challengeTypesKeys[i] }}"
          >
            {{ challengeType }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_POINTS_SOURCE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-checkbox color="primary" formControlName="canAscend">{{
        'Can Ascend' | translate
      }}</mat-checkbox>
      <mat-checkbox color="primary" formControlName="canDescend">{{
        'Can Descend' | translate
      }}</mat-checkbox>
      <mat-checkbox color="primary" formControlName="hasFixedDate">{{
        'Has Fixed Date' | translate
      }}</mat-checkbox>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'OVERVIEW.DATE_SELECTION' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange" (click)="picker.open()">
          <input
            matStartDate
            placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_START' | translate }}"
            formControlName="start"
          />
          <input
            matEndDate
            placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_END' | translate }}"
            formControlName="end"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </form>
  <form [formGroup]="pointsSourcesDataForm">
    <div class="points-sources">
      <h3>Points Sources</h3>
      <mat-form-field>
        <mat-label>{{ 'Points Sources' | translate }}</mat-label>
        <mat-select formControlName="pointsSourceId">
          <mat-option
            *ngFor="let pointsSource of selectablePointsSources"
            value="{{ pointsSource.id }}"
          >
            {{ pointsSource.description }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        [disabled]="!pointsSourcesDataForm.valid"
        (click)="addPointsSource()"
      >
        {{ 'Add Points Source' | translate }}
      </button>

      <table
        mat-table
        [dataSource]="pointsSourceDataSource"
        matSort
        *ngIf="pointsSourceIds.length !== 0"
      >
        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.id }}
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.description }}
          </td>
        </ng-container>

        <!-- Basis Column -->
        <ng-container matColumnDef="basis">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Basis</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSourceBasisNames[pointsSource.basis] }}
          </td>
        </ng-container>

        <!-- Expected Performance Column -->
        <ng-container matColumnDef="expectedPerformance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Expected Performance
          </th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.expectedPerformance }}
          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSourceTypesNames[pointsSource.type] }}
          </td>
        </ng-container>

        <!-- Points Awarded Column -->
        <ng-container matColumnDef="pointsAwarded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Points Awarded
          </th>
          <td mat-cell *matCellDef="let pointsSource">
            {{ pointsSource.pointsAwarded }}
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let pointsSource"
            (click)="removePointsSource(pointsSource.id)"
          >
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="pointsSourceColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pointsSourceColumns"></tr>
      </table>
      <div *ngIf="pointsSourceIds.length === 0">
        <span>Add a Data Source for this challenge</span>
      </div>
    </div>
  </form>

  <form [formGroup]="rewardsDataForm">
    <div class="rewards">
      <h3>Rewards</h3>
      <mat-form-field>
        <mat-label>{{ 'Level' | translate }}</mat-label>
        <input type="number" matInput formControlName="level" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Reward' | translate }}</mat-label>
        <input type="number" matInput formControlName="reward" />
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        [disabled]="!rewardsDataForm.valid"
        (click)="addReward()"
      >
        {{ 'Add Reward' | translate }}
      </button>

      <table
        mat-table
        [dataSource]="rewardsDataSource"
        matSort
        *ngIf="rewards.length !== 0"
      >
        <!-- Level Column -->
        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Level</th>
          <td mat-cell *matCellDef="let reward">
            {{ reward.level }}
          </td>
        </ng-container>

        <!-- Reward Column -->
        <ng-container matColumnDef="reward">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Reward</th>
          <td mat-cell *matCellDef="let reward">
            {{ reward.reward }}
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let reward" (click)="removeReward(reward)">
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="rewardsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: rewardsColumns"></tr>
      </table>
      <div *ngIf="rewards.length === 0">
        <span>Add a Reward for this challenge</span>
      </div>
    </div>
  </form>

  <div class="buttons">
    <button
      mat-raised-button
      [disabled]="!challengeDataForm.valid || dateRange.invalid"
      (click)="updatePersonalChallenge($event)"
    >
      {{ 'Update Personal Challenge' | translate }}
    </button>
  </div>
  <div class="creating-team" *ngIf="showSpinner">
    <p>{{ 'ADD_PERSONAL_CHALLENGE_SPINNER' | translate }}</p>
    <mat-spinner></mat-spinner>
  </div>
</div>
