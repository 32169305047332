import { PDFFont } from 'pdf-lib';
import { Document } from 'src/app/models/challenge-reports/document/document.model';

/** Abstract Class representing a Generic Report. */
export abstract class Report extends Document {
  public abstract fonts: { [fontName: string]: PDFFont | null };
  public abstract textFields: {
    [textFieldName: string]: { value: string | null; font: string };
  };

  constructor() {
    super();
  }

  /**
   * Set Text fields in PDF Form for Report
   */
  public setTextFieldsInForm(): void {
    Object.entries(this.textFields).forEach(
      ([textFieldName, textFieldProps]) => {
        const textField = this.pdfForm.getTextField(textFieldName);
        textField.setText(textFieldProps.value);
        textField.updateAppearances(this.fonts[textFieldProps.font]);
      }
    );
  }

  // TODO: update for challenge types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public abstract fillTextFieldsValues(valuesToFill?: any): void;
}
