import { Deserializable } from 'src/app/models/deserializable.model';
import { TeamChallengeTypes } from 'src/app/models/team-challenge-types.enum';

export interface ITeamChallengeSchemaInitializer {
  id?: string;
  name?: string;
  participantsPerTeam?: number;
  type?: TeamChallengeTypes;
  pointsAwarded?: object;
  teamIds?: Array<string>;
  userIds?: Array<string>;
}

export class TeamChallengeSchema implements Deserializable {
  public id = '';
  public name = '';
  public participantsPerTeam = 10;
  public type: TeamChallengeTypes = TeamChallengeTypes.Steps;
  public pointsAwarded: Map<string, number> = new Map<string, number>();
  public teamIds: Set<string> = new Set<string>();
  public userIds: Set<string> = new Set<string>();

  constructor(initializer: ITeamChallengeSchemaInitializer) {
    Object.assign(this, initializer);
    if (initializer && initializer.teamIds) {
      this.teamIds = new Set(initializer.teamIds);
    }
    if (initializer && initializer.userIds) {
      this.userIds = new Set(initializer.userIds);
    }
    if (initializer && initializer.pointsAwarded) {
      this.pointsAwarded = new Map(Object.entries(initializer.pointsAwarded));
    }
  }

  public toObject(): object {
    const pointsAwarded = Object.fromEntries(this.pointsAwarded);
    const teamIds = Array.from(this.teamIds);
    const userIds = Array.from(this.userIds);
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, { pointsAwarded, teamIds, userIds });
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    this.teamIds = new Set(input.teamIds);
    this.pointsAwarded = new Map(Object.entries(input.pointsAwarded));
    this.userIds = new Set(input.userIds);
    return this;
  }
}
