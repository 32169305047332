<mat-card class="user-ranking-container">
  <mat-card-header>
    <mat-card-title>
      {{ userRanking.title | translate }}
    </mat-card-title>
    <mat-card-subtitle *ngIf="userRanking.subtitle">
      {{ userRanking.subtitle | translate }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content #rankingCardContent>
    <mat-divider></mat-divider>
    <div *ngFor="let userItem of userRanking.ranking; index as i">
      <div class="user-item">
        <p class="position"> {{ i + 1 }} </p>
        <img mat-card-avatar #userAvatar [src]="userItem.avatar" alt="Duelist avatar" (error)="setDefaultImagePath(userAvatar)">
        <p class="nickname">{{ userItem.nickname }}</p>
        <mat-icon *ngIf="userRanking.badgeIcons[i]" class="badge-icon" [svgIcon]="userRanking.badgeIcons[i]"></mat-icon>
        <p class="performance"> {{ userItem.performance | bigNumbersFormat: 'de'}} </p>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="empty-ranking" *ngIf="userRanking.ranking.length === 0">
      <p>{{ userRanking.emptyRankingMessage | translate }}</p>
    </div>
  </mat-card-content>
</mat-card>