import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-challenge-type-selection',
  templateUrl: './challenge-type-selection.component.html',
  styleUrls: ['./challenge-type-selection.component.scss'],
})
export class ChallengeTypeSelectionComponent {
  @Input() challengeTypes: Array<string>;
  @Output() selectedType = new EventEmitter<string>();

  constructor() {}

  public setSelectedType(selectedType: string): void {
    this.selectedType.emit(selectedType);
  }
}
