<div mat-dialog-title class="dialog-title">
  <div class="top">
    <h1 class="title">{{ 'GROUP_RUN_CHALLENGE_DIALOG.TITLE' | translate }}</h1>
  </div>
</div>
<div mat-dialog-content
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
  class="dialog-content"
>
  <form [formGroup]="challengeForm">
    <mat-form-field>
      <mat-label>{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.name.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ getFormErrors(challengeForm.controls.name, 'GROUP_RUN_CHALLENGE_DIALOG.FORM', 'name') | translate }} </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.dateRange.LABEL' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange" (click)="picker.open()" [min]="minDate">
        <input
          matStartDate
          placeholder="{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.dateRange.startDate.LABEL' | translate }}"
          formControlName="start"
        />
        <input
          matEndDate
          placeholder="{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.dateRange.endDate.LABEL' | translate }}"
          formControlName="end"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error>{{ getFormErrors(challengeForm.controls.dateRange, 'GROUP_RUN_CHALLENGE_DIALOG.FORM', 'dateRange') | translate }} </mat-error>
    </mat-form-field>

    <section>
      <h2>{{ 'GROUP_RUN_CHALLENGE_DIALOG.LEVELS_SUBTITLE' | translate }}</h2>
      <mat-form-field>
        <mat-label>{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.firstLevelTarget.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="firstLevelTarget" />
        <mat-hint align="start">{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.firstLevelTarget.HINT' | translate}}</mat-hint>
        <mat-error>{{ getFormErrors(challengeForm.controls.firstLevelTarget, 'GROUP_RUN_CHALLENGE_DIALOG.FORM', 'firstLevelTarget') | translate }} </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.levelIncrementalFactor.LABEL' | translate }}</mat-label>
        <input type="number" matInput formControlName="levelIncrementalFactor" />
        <mat-hint align="start">{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.levelIncrementalFactor.HINT' | translate}}</mat-hint>
        <mat-error>{{ getFormErrors(challengeForm.controls.levelIncrementalFactor, 'GROUP_RUN_CHALLENGE_DIALOG.FORM', 'levelIncrementalFactor') | translate }} </mat-error>
      </mat-form-field>
    </section>
    <section>
      <h2>{{ 'GROUP_RUN_CHALLENGE_DIALOG.COMPANIES_SUBTITLE' | translate }}</h2>
      <mat-form-field>
        <mat-label>{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.companySelector.LABEL' | translate }}</mat-label>
        <input type="text" matInput [matAutocomplete]="companiesAutocomplete" [formControl]="selectedCompany" />
        <mat-autocomplete #companiesAutocomplete="matAutocomplete" [displayWith]="showCompanyName">
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
            <img class="option-logo" height="24px" width="32px" [src]="company.avatar" />
            {{ company.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-hint align="start">{{ 'GROUP_RUN_CHALLENGE_DIALOG.FORM.companySelector.HINT' | translate}}</mat-hint>
      </mat-form-field>
    </section>

    <section *ngIf="challengeCompanies.length !== 0">
      <div class="filter-container">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'GROUP_RUN_CHALLENGE_DIALOG.TABLE.FILTER.LABEL' | translate }}</mat-label>
          <input matInput (keyup)="applyFilter($event)" [placeholder]="'GROUP_RUN_CHALLENGE_DIALOG.TABLE.FILTER.PLACEHOLDER' | translate " #input>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
      >
        <!-- Id Column -->
        <ng-container matColumnDef="avatar">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let company" class="avatar">
            <img
              *ngIf="company.avatar !== 'assets/img/move-me-new.png'"
              src="{{ company.avatar }}"
              alt="avatar"
            />
            <div *ngIf="company.avatar === 'assets/img/move-me-new.png'">
              <mat-icon>do_not_disturb</mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GROUP_RUN_CHALLENGE_DIALOG.TABLE.NAME' | translate}}</th>
          <td mat-cell *matCellDef="let company">
            {{ company.name }}
          </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let company" (click)="removeCompany(company.id)">
            <mat-icon>delete_outline</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="companiesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: companiesColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">{{ 'GROUP_RUN_CHALLENGE_DIALOG.TABLE.FILTER.MESSAGE' | translate}} "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator pageSize="5" aria-label="Select companies page"></mat-paginator>
    </section>
  </form>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="close()">{{ 'CANCEL' | translate }}</button>
  <button mat-button [disabled]="!challengeForm.valid || showSpinner" (click)="confirm()">
    {{ 'CONFIRM' | translate }}
    <mat-spinner [diameter]="16" *ngIf="showSpinner"></mat-spinner>
  </button>
</div>
