import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AssetsConstants, IconNames } from 'src/app/models/assets-constants';

export interface IIcon {
  name: string;
  path: string;
}
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private registeredIcons: Set<string> = new Set<string>();
  private iconsToRegister: Array<IIcon> = [
    { name: IconNames.PencilOutline, path: AssetsConstants.PencilOutline },
    { name: IconNames.SentEmailOk, path: AssetsConstants.SentEmailOk },
    { name: IconNames.TrashOutline, path: AssetsConstants.TrashOutline },
    { name: IconNames.ClockOutlined, path: AssetsConstants.ClockOutlined },
    { name: IconNames.Earth, path: AssetsConstants.Earth },
    { name: IconNames.GroupPeople, path: AssetsConstants.GroupPeople },
    {
      name: IconNames.SwordCrossedHistory,
      path: AssetsConstants.SwordCrossedHistory,
    },

    // Duel challenge
    {
      name: IconNames.MostStepsOfTheMonthDuel,
      path: AssetsConstants.MostStepsOfTheMonthDuel,
    },
    { name: IconNames.FirstPlaceDuel, path: AssetsConstants.FirstPlaceDuel },
    { name: IconNames.SecondPlaceDuel, path: AssetsConstants.SecondPlaceDuel },
    { name: IconNames.ThirdPlaceDuel, path: AssetsConstants.ThirdPlaceDuel },
    {
      name: IconNames.DeactivatedPlacePodiumDuel,
      path: AssetsConstants.DeactivatedPlacePodiumDuel,
    },
    {
      name: IconNames.MostStepsOfTheMonthDeactivatedDuel,
      path: AssetsConstants.MostStepsOfTheMonthDeactivatedDuel,
    },

    // Team Challenge
    {
      name: IconNames.FirstPlaceTeamChallenge,
      path: AssetsConstants.FirstPlaceTeamChallenge,
    },
    {
      name: IconNames.SecondPlaceTeamChallenge,
      path: AssetsConstants.SecondPlaceTeamChallenge,
    },
    {
      name: IconNames.ThirdPlaceTeamChallenge,
      path: AssetsConstants.ThirdPlaceTeamChallenge,
    },

    // Cities Challenge
    {
      name: IconNames.FirstPlaceCitiesChallenge,
      path: AssetsConstants.FirstPlaceCitiesChallenge,
    },
    {
      name: IconNames.SecondPlaceCitiesChallenge,
      path: AssetsConstants.SecondPlaceCitiesChallenge,
    },
    {
      name: IconNames.ThirdPlaceCitiesChallenge,
      path: AssetsConstants.ThirdPlaceCitiesChallenge,
    },
    {
      name: IconNames.DeactivatedPlaceCitiesChallenge,
      path: AssetsConstants.DeactivatedPlaceCitiesChallenge,
    },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  // Function to register all the iconsToRegister array. It is called at the app initialization by app.module
  public registerStandardIcons(): void {
    this.iconsToRegister.forEach((icon) => this.registerSvgIcon(icon));
  }
  // Register an SVG icon
  public registerSvgIcon(icon: IIcon): void {
    if (!this.iconExists(icon.name)) {
      if (icon.name && icon.path) {
        this.matIconRegistry.addSvgIcon(
          icon.name,
          this.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
        );
        // Keep track of registered icons
        this.registeredIcons.add(icon.name);
      }
    }
  }

  // Retrieve an SVG icon
  public getSvgIcon(name: string): string {
    if (this.iconExists(name)) {
      return name;
    } else {
      console.warn(`Icon "${name}" does not exist.`);
      // You can return a default icon or throw an error if needed
      return 'default-icon';
    }
  }

  // Check if an icon exists
  public iconExists(name: string): boolean {
    return this.registeredIcons.has(name);
  }
}
