import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { Company } from 'src/app/models/company.model';
import { Event as EventModel } from 'src/app/models/event/event';
import { EventsService } from 'src/app/services/events/events.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helpers } from 'src/app/helpers/helpers';

@Component({
  selector: 'app-add-event-component',
  templateUrl: './add-event-component.component.html',
  styleUrls: ['./add-event-component.component.scss'],
})
export class AddEventComponentComponent implements OnInit {
  public eventDataForm: UntypedFormGroup;
  public dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  public showSpinner = false;
  public companyId: string;
  public company: Company;
  public eventIdToEdit: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder
  ) {
    this.eventDataForm = this.formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      companyId: [{ value: '', disabled: true }, Validators.required],
      distance: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.eventDataForm.controls.companyId.setValue(this.companyId);
    this.eventIdToEdit = this.route.snapshot.paramMap.get('eventId');
    if (this.eventIdToEdit) {
      this.eventsService
        .getCompanyEvent(this.companyId, this.eventIdToEdit)
        .then((eventToEdit) => {
          if (eventToEdit) {
            this.eventDataForm.controls.id.setValue(eventToEdit.id);
            this.eventDataForm.controls.id.disable();
            this.eventDataForm.controls.name.setValue(eventToEdit.name);
            this.eventDataForm.controls.distance.setValue(eventToEdit.distance);

            const startDate = Helpers.unformatDateYYYYMMDD(eventToEdit.days[0]);
            const endDate = Helpers.unformatDateYYYYMMDD(
              eventToEdit.days[eventToEdit.days.length - 1]
            );
            this.dateRange.controls.start.setValue(startDate);
            this.dateRange.controls.end.setValue(endDate);
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    } else {
      this.showSpinner = false;
    }
  }

  public async addEvent(): Promise<void> {
    this.showSpinner = true;
    if (this.eventDataForm.valid) {
      const companyId = this.companyId;
      const eventId = this.eventDataForm.controls.id.value;
      const eventName = this.eventDataForm.controls.name.value;
      const eventDistance = this.eventDataForm.controls.distance.value;
      const startDate = new Date(this.dateRange.value.start);
      const endDate = new Date(this.dateRange.value.end);

      const eventDays = [];

      for (
        let day = startDate;
        day <= endDate;
        day.setDate(day.getDate() + 1)
      ) {
        const date = Helpers.formatDateYYYYMMDD(day);
        eventDays.push(date);
      }

      const weekendEvent = new EventModel({
        id: eventId,
        name: eventName,
        distance: eventDistance,
        days: eventDays,
      });

      this.eventsService
        .createEvents(companyId, weekendEvent)
        .then(() => {
          this.snackBar.open('The event has been created successfully', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
        })
        .catch((error) => {
          console.log('error on addEvent(), while creating event: ', error);
          this.snackBar.open('error on addEvent', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
        })
        .finally(() => {
          this.showSpinner = false;
        });

      if (this.eventIdToEdit) {
        this.router.navigate(['../..'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..'], { relativeTo: this.route });
      }
    }
  }

  public returnPage(): void {
    if (this.eventIdToEdit) {
      this.router.navigate(['../..'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }
}
