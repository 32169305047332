<mat-card class="circle-card-container">
  <mat-card-header>
    <mat-card-title>
      {{ circleProgressCard.title | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content #circleProgressCardContent>
    <div class="circle-progress-container">
      <svg viewBox="-2 -2 40 40" class="circular-chart" [ngClass]="{ 'no-progress': totalProgress === 0 }">
        <circle class="circle-bg" cx="18" cy="18" r="16"/>
        <path *ngIf="totalProgress > 0" class="circle"
          [attr.stroke-dasharray]="totalProgress + ' 100'"
          d="M18 2
            a 16 16 0 1 1 0 32
            a 16 16 0 1 1 0 -32"
        />
        <text x="47%" y="50%" class="percentage" [ngClass]="{ 'no-progress': totalProgress === 0 }">
          {{ totalProgress | number: '1.0-2':'de' }}%
        </text>
      </svg>
    </div>
    <div class="info-container">
      <h1 *ngIf="circleProgressCard.progressTitle" class="progress-title">{{ circleProgressCard.progressTitle | translate }}</h1>
      <p *ngIf="circleProgressCard.progressSubtitle" class="progress-subtitle" [ngClass]="{ 'no-progress': totalProgress === 0 }">{{ circleProgressCard.progressSubtitle | translate }}</p>
    </div>
  </mat-card-content>
</mat-card>
