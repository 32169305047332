import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Notification } from 'src/app/models/notification.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

interface DefaultNotification {
  showMessage: boolean;
  message: object;
}

const DEFAULT_LANGUAGE = 'de';
@Component({
  selector: 'app-notification-messages',
  templateUrl: './notification-messages.component.html',
  styleUrls: ['./notification-messages.component.scss'],
})
export class NotificationMessagesComponent implements OnInit {
  @Input() defaultNotification: DefaultNotification;

  public notification: Notification;
  public DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;

  constructor(
    private notificationsService: NotificationsService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (!this.defaultNotification) {
      this.notificationsService
        .checkForNotification()
        .then(
          (notification) => (this.notification = new Notification(notification))
        )
        .catch((err) => console.log(err));
    } else {
      this.notification = new Notification(this.defaultNotification);
    }
  }
}
