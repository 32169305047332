import { Deserializable } from '../deserializable.model';

export interface IReportTemplates {
  distanceChallenge?: string;
  teamChallenge?: string;
}

export class ReportTemplates implements Deserializable {
  public distanceChallenge: string = null;
  public teamChallenge: string = null;

  constructor(initializer?: IReportTemplates) {
    Object.assign(this, initializer);
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  toObject(): any {
    return Object.assign({}, this);
  }
}
