<div class="container" fxLayout.xs="column space-between" fxLayout.sm="column space-between">
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ challengeDataForm.controls.name.value }}</h1>
  </div>

  <div *ngIf="!showLoadingCitiesChallengeSpinner">
    <app-cities-challenge-form
      [citiesChallengeDataForm]="challengeDataForm"
      [editableChallenge]="editableChallenge"
      [editableRoute]="editableChallenge && (citiesChallenge.hasFeature(CITIES_CHALLENGE_TYPES[1]) || citiesChallenge.hasFeature(CITIES_CHALLENGE_TYPES[2]))"
      [createRegions]="citiesChallenge.hasFeature(CITIES_CHALLENGE_TYPES[2])"
    ></app-cities-challenge-form>

    <div class="buttons">
      <button mat-raised-button [disabled]="!challengeDataForm.valid || challengeDataForm.controls.totalDuration.value < 1 || showUpdatingCitiesChallengeSpinner"
        (click)="updateCitiesChallenge()">
        {{ 'EDIT_CITIES_CHALLENGE.BUTTON.UPDATE_CITIES_CHALLENGE' | translate }}
        <mat-spinner *ngIf="showUpdatingCitiesChallengeSpinner" diameter="16"></mat-spinner>
      </button>
    </div>
  </div>

  <div *ngIf="showLoadingCitiesChallengeSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
