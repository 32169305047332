import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Route } from 'src/app/models/cities-challenge/route/route.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin/admin.service';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CityMarkerPoint } from 'src/app/models/cities-challenge/city-marker-point/city-marker-point.model';
import { TranslateService } from '@ngx-translate/core';
import { RoutesService } from 'src/app/services/routes/routes.service';

@Component({
  selector: 'app-routes-table',
  templateUrl: './routes-table.component.html',
  styleUrls: ['./routes-table.component.scss'],
})
export class RoutesTableComponent implements OnChanges, AfterViewInit {
  @Input() showCreateRouteButton: boolean = false;
  @Input() routes: Array<Route>;
  @Input() selectable: boolean;
  @Output() newRouteSelected = new EventEmitter<Route>();

  @ViewChild(MatSort) sort: MatSort;

  public routesDataSource: MatTableDataSource<Route> = new MatTableDataSource();
  public routesColumns: Array<string> = [
    'name',
    'startingCity',
    'endingCity',
    'cities',
    'totalDistance',
    'expand',
  ];

  public columnsToDisplay = [...this.routesColumns];

  public expandedRoute: Route | null = null;
  public expandedRouteForm: UntypedFormGroup;

  public selectedRoute: Route;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private routesService: RoutesService
  ) {
    this.expandedRouteForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, Validators.required],
      totalDistance: [
        { value: 0, disabled: true },
        [Validators.required, Validators.min(0)],
      ],
      mapFile: [null],
      mapUrl: [null],
      cityInfoType: [{ value: null, disabled: true }],
      cityMarkerPoints: this.formBuilder.array([]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.routes) {
      this.refreshDataSource();
    }
    this.columnsToDisplay = [
      ...this.routesColumns,
      ...(this.selectable ? ['select'] : ['edit', 'delete']),
    ];
  }

  ngAfterViewInit(): void {
    this.routesDataSource.sort = this.sort;
  }

  private refreshDataSource(): void {
    this.routesDataSource = new MatTableDataSource(this.routes);
  }

  public goToRoute(routeId: string): void {
    this.router.navigate(['edit-route', routeId], {
      relativeTo: this.route,
    });
  }

  public removeRoute(route: Route): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: new ConfirmationDialogData({
        title: this.translateService.instant(
          'ROUTES_TABLE.REMOVE_DIALOG.TITLE',
          { name: route.name }
        ),
        message: this.translateService.instant(
          'ROUTES_TABLE.REMOVE_DIALOG.MESSAGE'
        ),
        action: () => this.adminService.deleteRoute(route.id),
        showSpinner: true,
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.routesDataSource = new MatTableDataSource(this.routes);
          this.snackBar.open(
            this.translateService.instant(
              'ROUTES_TABLE.REMOVE_DIALOG.SUCCESS_SNACKBAR'
            ),
            'Ok',
            {
              duration: 4000,
              panelClass: 'snack-bar-color',
            }
          );
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.snackBar.open(
            this.translateService.instant(
              'ROUTES_TABLE.REMOVE_DIALOG.FAILURE_SNACKBAR',
              { error: result.error }
            ),
            'Ok',
            {
              duration: 4000,
              panelClass: 'snack-bar-color',
            }
          );
          console.log('error: ', result.error);
          break;
      }
    });
  }

  // TODO: can we do this better? this code is copied from routes form
  public expandRoute(event: Event, route: Route): void {
    event.stopPropagation();
    this.expandedRoute = this.expandedRoute === route ? null : route;

    if (this.expandedRoute) {
      this.expandedRouteForm.patchValue({
        name: this.expandedRoute.name,
        totalDistance: this.expandedRoute.totalDistance,
        mapUrl: this.expandedRoute.map,
      });
      this.expandedRouteForm.controls.cityMarkerPoints = this.formBuilder.array(
        []
      );
      // TODO: can we do this more efficiently?
      this.expandedRoute.cityMarkerPoints.forEach(
        (cityMarkerPoint: CityMarkerPoint, index: number) => {
          this.routesService.addControlToCityMarkerPoints(
            this.expandedRouteForm.controls.cityMarkerPoints as FormArray,
            {
              city: cityMarkerPoint.city.toObject(),
              distanceToNextCity: cityMarkerPoint.distanceToNextCity,
              cityInfoType: cityMarkerPoint.cityInfoType,
              isDistanceRequired:
                index !== this.expandedRoute.cityMarkerPoints.length - 1,
            },
            false
          );
        }
      );
    }
  }

  public selectRoute(route: Route): void {
    if (this.selectable) {
      this.selectedRoute = route;
      this.newRouteSelected.emit(route);
    }
  }
}
