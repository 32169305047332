import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

// Models
import { User } from 'src/app/models/user.model';
import { Roles } from 'src/app/models/roles.enum';
import { MatDialog } from '@angular/material/dialog';
import { UpdatePropertyDialogComponent } from 'src/app/shared/components/update-property-dialog/update-property-dialog.component';
import { SelectPropertyDialogComponent } from 'src/app/shared/components/select-property-dialog/select-property-dialog.component';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { CompanyService } from 'src/app/services/company/company.service';
import { AdminTabs } from '../admin-tabs.enum';
import { Location } from 'src/app/models/location.model';
import { Team } from 'src/app/models/team.model';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { Company } from 'src/app/models/company.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { Features } from 'src/app/models/features.enum';
import { MatSnackBar } from '@angular/material/snack-bar';

// Services

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  private id: string;
  public user: User = new User();
  public locations: Array<Location> = [];
  public showSpinner = true;
  public locationOne: Location;
  public locationTwo: Location;
  public locationThree: Location;
  public team: Team;
  public hierarchyOne = false;
  public hierarchyTwo = false;
  public hierarchyThree = false;
  public userCompany: Company;
  public userTeamChallenge: TeamChallenge;
  public changingTeam = false;
  public joiningTeamChallenge = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private teamsService: TeamsService,
    private snackBar: MatSnackBar
  ) {}

  get roleEnumKeys(): Array<number> {
    return Object.keys(Roles)
      .map((key) => Roles[key])
      .filter((value) => typeof value === 'number') as number[];
  }

  get roleEnumNames(): Array<string> {
    return Object.keys(Roles)
      .map((key) => Roles[key])
      .filter((value) => typeof value === 'string') as string[];
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.userService
      .getUserById(this.id)
      .then(async (user) => {
        this.user = user;
        this.userCompany = await this.companyService.getCompany(
          this.user.companyId
        );
        this.userTeamChallenge = this.user.currentChallengeId
          ? await this.companyService.getCurrentTeamChallenge(
              this.user.companyId,
              this.user.currentTeamChallengeId
            )
          : null;
        this.locations = await this.companyService.getCompanyLocations(
          this.user.companyId
        );
        if (this.locations.length > 0) {
          this.setLocations();
          this.hierarchyOne = this.locations.some(
            (location) => location.hierarchyLevel === 1
          );
          this.hierarchyTwo = this.locations.some(
            (location) => location.hierarchyLevel === 2
          );
          this.hierarchyThree = this.locations.some(
            (location) => location.hierarchyLevel === 3
          );
        }
        if (this.user.teamId) {
          this.team = await this.teamsService.getTeam(
            this.user.companyId,
            this.user.teamId
          );
        }
      })
      .catch((error) => {
        console.log('userService.getUserById - error: ', error);
      })
      .finally(() => {
        this.showSpinner = false;
      });
  }

  public returnPage(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Users } });
  }

  public updateRole(): void {
    this.dialog.open(UpdatePropertyDialogComponent, {
      width: '300px',
      panelClass: 'center-dialog',
      data: {
        currentSelection: this.user.role,
        propertyToUpdate: 'role',
        options: Object.values(Roles)
          .filter((value) => typeof value === 'string')
          .map((key, index) => ({ value: index, label: key })),
        object: this.user,
        objectId: this.user.id,
        objectRoute: [FirebaseConstants.UsersCollection],
      },
    });
  }

  public async updateLocation(hierarchyLevel: number): Promise<void> {
    let locationSelected: Location;
    let locationOptions: Array<Location> = [];

    switch (hierarchyLevel) {
      case 1:
        locationSelected = this.locationOne;
        locationOptions = this.locations.filter(
          (location) => location.hierarchyLevel === hierarchyLevel
        );
        break;
      case 2:
        locationSelected = this.locationTwo;
        locationOptions = this.locations.filter((location) => {
          return (
            location.hierarchyLevel === hierarchyLevel &&
            this.locationOne.childLocations.optionNames.includes(location.name)
          );
        });
        break;
      case 3:
        locationSelected = this.locationThree;
        locationOptions = this.locations.filter((location) => {
          return (
            location.hierarchyLevel === hierarchyLevel &&
            this.locationTwo &&
            this.locationTwo.childLocations.optionNames.includes(location.name)
          );
        });
        break;
      default:
        console.log(`Sorry, we are out of ${hierarchyLevel}.`);
    }

    this.dialog
      .open(UpdatePropertyDialogComponent, {
        width: '300px',
        panelClass: 'center-dialog',
        data: {
          currentSelection: locationSelected ? locationSelected.name : '',
          propertyToUpdate: 'locationIds',
          options: locationOptions.map((location) => ({
            value: location.name,
            label: location.name,
          })),
          object: this.user,
          objectId: this.user.id,
          objectRoute: [FirebaseConstants.UsersCollection],
        },
      })
      .afterClosed()
      .subscribe(() => {
        console.log('Se cerro', this.user.locationIds);
        let updateDataBase = false;
        if (!this.user.locationIds.includes(this.locationOne.name)) {
          updateDataBase = true;
          if (this.locationTwo) {
            this.user.locationIds = this.user.locationIds.filter(
              (locationId) => locationId !== this.locationTwo.name
            );
          }
          if (this.locationThree) {
            this.user.locationIds = this.user.locationIds.filter(
              (locationId) => locationId !== this.locationThree.name
            );
          }
        } else {
          if (this.locationTwo) {
            if (!this.user.locationIds.includes(this.locationTwo.name)) {
              updateDataBase = true;
              if (this.locationThree) {
                this.user.locationIds = this.user.locationIds.filter(
                  (locationId) => locationId !== this.locationThree.name
                );
              }
            } else {
              if (this.locationThree) {
                if (!this.user.locationIds.includes(this.locationThree.name)) {
                  updateDataBase = true;
                }
              } else {
                updateDataBase = this.user.locationIds.length > 2;
              }
            }
          } else {
            updateDataBase = this.user.locationIds.length > 1;
          }
        }

        if (updateDataBase) {
          this.userService
            .updateUser(
              {
                locationIds: this.user.locationIds,
              },
              this.user.id
            )
            .then(() => {
              this.setLocations();
            })
            .catch((error) => console.log('Error updating locations: ', error));
        }
      });
  }

  private setLocations(): void {
    this.locationOne = this.locations.find((location) => {
      return (
        location.hierarchyLevel === 1 &&
        this.user.locationIds.includes(location.name)
      );
    });
    this.locationTwo = this.locations.find((location) => {
      return (
        location.hierarchyLevel === 2 &&
        this.user.locationIds.includes(location.name)
      );
    });
    this.locationThree = this.locations.find((location) => {
      return (
        location.hierarchyLevel === 3 &&
        this.user.locationIds.includes(location.name)
      );
    });
  }

  public async changeTeam(): Promise<void> {
    this.changingTeam = true;
    let teamsOptions: Array<Team> = [];
    let teamsOptionsIds: Array<string> = [];
    const userOldTeamId = this.user.teamId;
    if (this.userTeamChallenge) {
      if (
        this.user.locationIds.length > 0 &&
        this.userCompany.features.has(Features.Locations)
      ) {
        teamsOptions = await this.companyService.getTeamsByLocation(
          this.user.companyId,
          this.user.locationIds[0],
          this.userTeamChallenge
        );
      } else {
        teamsOptions = await this.companyService.getTeamsByTeamChallenge(
          this.user.companyId,
          this.user.currentTeamChallengeId
        );
      }
      teamsOptionsIds = teamsOptions.map((team) => team.id);
    }
    this.dialog
      .open(SelectPropertyDialogComponent, {
        width: '300px',
        panelClass: 'center-dialog',
        data: {
          currentSelection: this.user.teamId,
          propertyToUpdate: 'teamId',
          options: teamsOptionsIds.map((key) => ({ value: key, label: key })),
          object: this.user,
        },
      })
      .afterClosed()
      .subscribe(async () => {
        if (userOldTeamId !== this.user.teamId) {
          this.companyService
            .changeTeamMember(this.user.email, this.user.teamId)
            .then(() => {
              this.snackBar.open('User saved successfully', 'Ok', {
                duration: 4000,
                panelClass: 'snack-bar-color',
              });
              this.team = teamsOptions.find(
                (team) => team.id === this.user.teamId
              );
            })
            .catch((error) => {
              console.log('error: ', error);
              this.snackBar.open('ERROR: User not saved', 'Ok', {
                duration: 4000,
                panelClass: 'snack-bar-color',
              });
            })
            .finally(() => {
              this.changingTeam = false;
            });
        } else {
          this.changingTeam = false;
        }
      });
  }

  public joinTeamChallenge(): void {
    this.joiningTeamChallenge = true;
    const oldTeamChallengeId = this.user.currentTeamChallengeId;
    this.dialog
      .open(SelectPropertyDialogComponent, {
        width: '300px',
        panelClass: 'center-dialog',
        data: {
          currentSelection: this.user.currentTeamChallengeId,
          propertyToUpdate: 'currentTeamChallengeId',
          options: [
            ...this.userCompany.nextTeamChallenges,
            ...this.userCompany.currentTeamChallenges,
          ]
            .sort()
            .map((key) => ({ value: key, label: key })),
          object: this.user,
        },
      })
      .afterClosed()
      .subscribe(async () => {
        if (oldTeamChallengeId !== this.user.currentTeamChallengeId) {
          try {
            this.userTeamChallenge = await this.companyService.getCurrentTeamChallenge(
              this.user.companyId,
              this.user.currentTeamChallengeId
            );
            if (
              this.user.teamId &&
              !this.userTeamChallenge.teamIds.has(this.user.teamId)
            ) {
              await this.teamsService.removeUserFromTeam(
                this.userCompany.id,
                this.user.teamId,
                this.user.id
              );
              this.user.teamId = '';
            }
            if (this.user.teamId === '') {
              this.userTeamChallenge.userIds.add(this.user.id);
              await this.companyService.updateTeamChallenge(
                this.user.companyId,
                this.userTeamChallenge,
                this.userTeamChallenge.id
              );
            }
            if (oldTeamChallengeId) {
              await this.companyService.removeUserFromTeamChallenge(
                this.user.companyId,
                oldTeamChallengeId,
                this.user.id
              );
            }
            await this.userService.updateUser(
              {
                currentTeamChallengeId: this.user.currentTeamChallengeId,
                teamId: this.user.teamId,
              },
              this.user.id
            );
            this.snackBar.open('User saved successfully', 'Ok', {
              duration: 4000,
              panelClass: 'snack-bar-color',
            });
          } catch (error) {
            console.log('error: ', error);
            this.snackBar.open('ERROR: User not saved', 'Ok', {
              duration: 4000,
              panelClass: 'snack-bar-color',
            });
          } finally {
            this.joiningTeamChallenge = false;
          }
        } else {
          this.joiningTeamChallenge = false;
        }
      });
  }
}
