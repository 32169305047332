<div class="avatar-container">
  <!-- added an extra ';' on url because ngStyle removes the first ';' breaking the file string -->
  <div class="image-container" *ngIf="file; else placeholder"
  [ngClass]="{'box-shadow-avatar': !!file}"
  [ngStyle]="{backgroundImage: 'url(;' + file + ')'}">
  </div>
  
  <ng-template #placeholder>
    <div class="image-container avatar-placeholder"></div>
  </ng-template>

  <label [for]="'avatar-input-file'+index" *ngIf="!disabled">
    <div class="icon-wrapper">
      <input type="file" [id]="'avatar-input-file'+index" accept="image/*" (change)="onFileChange($event)"/>
      <mat-icon class="icon" fontIcon="edit"></mat-icon>
    </div>
  </label>
</div>