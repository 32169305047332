import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  REGEX_LINK,
  REGEX_ALPHANUMERIC_EXTENDED,
  WHITE_LABEL_DISTANCE_CHALLENGE_REPORT_TEMPLATE_PATH,
  WHITE_LABEL_TEAM_CHALLENGE_REPORT_TEMPLATE_PATH,
} from 'src/app/constants/constants';
import { WhiteLabelButton } from 'src/app/models/white-label-button.model';
import { WhiteLabel } from 'src/app/models/white-label.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { WhiteLabelService } from 'src/app/services/white-label/white-label.service';
import { AdminTabs } from '../admin-tabs.enum';

@Component({
  selector: 'app-edit-white-label',
  templateUrl: './edit-white-label.component.html',
  styleUrls: ['./edit-white-label.component.scss'],
})
export class EditWhiteLabelComponent implements OnInit {
  public whiteLabelDataForm: UntypedFormGroup;
  public whiteLabelButtonDataForm: UntypedFormGroup;

  public showSpinner = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar,
    private whiteLabelService: WhiteLabelService,
    private sanitizer: DomSanitizer
  ) {
    this.whiteLabelDataForm = this.formBuilder.group({
      logo: [''],
      logoImg: [undefined],
      color: ['#f97a1c', Validators.required],
      id: [{ value: '', disabled: true }, Validators.required],
      name: [
        '',
        Validators.compose([
          Validators.maxLength(20),
          Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED),
          Validators.required,
        ]),
      ],
      button: [false],
      distanceReportFile: [''],
      distanceReportFileSource: [''],
      teamReportFile: [''],
      teamReportFileSource: [''],
    });

    this.whiteLabelButtonDataForm = this.formBuilder.group({
      color: ['#f97a1c', Validators.required],
      text: [
        '',
        Validators.compose([
          Validators.maxLength(50),
          Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED),
          Validators.required,
        ]),
      ],
      link: [
        '',
        Validators.compose([
          Validators.pattern(REGEX_LINK),
          Validators.required,
        ]),
      ],
    });

    this.whiteLabelDataForm.controls.logoImg.valueChanges.subscribe(
      (newImgValue) => {
        const fileReader = new FileReader();
        if (newImgValue !== null) {
          fileReader.readAsDataURL(newImgValue.files[0]);

          fileReader.addEventListener('load', (event) => {
            this.whiteLabelDataForm.controls.logo.setValue(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                String(event.target?.result)
              )
            );
          });
        }
      }
    );

    this.whiteLabelDataForm.controls.distanceReportFile.valueChanges.subscribe(
      (newValue) => {
        if (newValue) {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(newValue.files[0]);

          fileReader.addEventListener('load', (event) => {
            this.whiteLabelDataForm.patchValue({
              distanceReportFileSource: event.target?.result,
            });
          });
        }
      }
    );

    this.whiteLabelDataForm.controls.teamReportFile.valueChanges.subscribe(
      (newValue) => {
        if (newValue) {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(newValue.files[0]);

          fileReader.addEventListener('load', (event) => {
            this.whiteLabelDataForm.patchValue({
              teamReportFileSource: event.target?.result,
            });
          });
        }
      }
    );
  }

  get whiteLabel(): WhiteLabel {
    return this.whiteLabelService.whiteLabelToEdit;
  }

  get whiteLabelButton(): WhiteLabelButton {
    return this.whiteLabelService.whiteLabelButtonToEdit;
  }

  ngOnInit(): void {
    this.whiteLabelDataForm.setValue({
      logo: this.whiteLabelService.whiteLabelToEdit.logo
        ? this.whiteLabelService.whiteLabelToEdit.logo
        : '',
      color: this.whiteLabelService.whiteLabelToEdit.color
        ? this.whiteLabelService.whiteLabelToEdit.color
        : '',
      id: this.whiteLabelService.whiteLabelToEdit.id
        ? this.whiteLabelService.whiteLabelToEdit.id
        : '',
      name: this.whiteLabelService.whiteLabelToEdit.name
        ? this.whiteLabelService.whiteLabelToEdit.name
        : '',
      button: this.whiteLabelService.whiteLabelButtonToEdit ? true : false,
      logoImg: null,
      distanceReportFile: '',
      distanceReportFileSource: '',
      teamReportFile: '',
      teamReportFileSource: '',
    });

    if (this.whiteLabelService.whiteLabelButtonToEdit) {
      this.whiteLabelButtonDataForm.setValue({
        color: this.whiteLabelService.whiteLabelButtonToEdit.color
          ? this.whiteLabelService.whiteLabelButtonToEdit.color
          : '',
        text: this.whiteLabelService.whiteLabelButtonToEdit.text
          ? this.whiteLabelService.whiteLabelButtonToEdit.text
          : '',
        link: this.whiteLabelService.whiteLabelButtonToEdit.link
          ? this.whiteLabelService.whiteLabelButtonToEdit.link
          : '',
      });
    }

    const distanceChallengePath = WHITE_LABEL_DISTANCE_CHALLENGE_REPORT_TEMPLATE_PATH.replace(
      'WHITE_LABEL_ID',
      this.whiteLabel.id
    );
    const teamChallengePath = WHITE_LABEL_TEAM_CHALLENGE_REPORT_TEMPLATE_PATH.replace(
      'WHITE_LABEL_ID',
      this.whiteLabel.id
    );

    this.firebaseService
      .fileExist(distanceChallengePath)
      .then((distanceChallengeExists) => {
        if (distanceChallengeExists) {
          this.firebaseService
            .getDownloadURL(distanceChallengePath)
            .then((downloadUrl) => {
              this.whiteLabelDataForm.patchValue({
                distanceReportFileSource: downloadUrl,
              });
            })
            .catch((error) => {
              console.log('error - getFile: ', error);
            });
        }
      })
      .catch((error) => {
        console.log('error - fileExist: ', error);
      });

    this.firebaseService
      .fileExist(teamChallengePath)
      .then((teamChallengeExists) => {
        if (teamChallengeExists) {
          this.firebaseService
            .getDownloadURL(teamChallengePath)
            .then((downloadUrl) => {
              this.whiteLabelDataForm.patchValue({
                teamReportFileSource: downloadUrl,
              });
            })
            .catch((error) => {
              console.log('error - getFile: ', error);
            });
        }
      })
      .catch((error) => {
        console.log('error - fileExist: ', error);
      });
  }

  public async updateWhiteLabel(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (this.whiteLabelDataForm.valid) {
      const whiteLabelId = this.whiteLabelDataForm.controls.id.value;

      if (this.whiteLabelDataForm.controls.logoImg.dirty) {
        const logoFile = this.whiteLabelDataForm.controls.logoImg.value
          .files[0];

        await this.firebaseService
          .uploadImage(logoFile, 'whiteLabels/' + whiteLabelId + '/logo')
          .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            this.whiteLabelDataForm.controls.logo.setValue(url);
          });
      }

      let whiteLabelButton = null;

      if (this.whiteLabelDataForm.controls.button.value) {
        whiteLabelButton = new WhiteLabelButton({
          color: this.whiteLabelButtonDataForm.value.color,
          text: this.whiteLabelButtonDataForm.value.text,
          link: this.whiteLabelButtonDataForm.value.link,
        });
      }

      const whiteLabel = new WhiteLabel({
        id: this.whiteLabel.id,
        name: this.whiteLabelDataForm.value.name,
        logo: this.whiteLabelDataForm.value.logo,
        color: this.whiteLabelDataForm.value.color,
        button: whiteLabelButton,
      });

      if (
        this.whiteLabelDataForm.controls.distanceReportFile.dirty &&
        this.whiteLabelDataForm.value.distanceReportFileSource
      ) {
        const distanceChallengePath = WHITE_LABEL_DISTANCE_CHALLENGE_REPORT_TEMPLATE_PATH.replace(
          'WHITE_LABEL_ID',
          this.whiteLabel.id
        );
        await this.firebaseService.uploadFile(
          this.whiteLabelDataForm.value.distanceReportFileSource,
          distanceChallengePath
        );
      }

      if (
        this.whiteLabelDataForm.controls.teamReportFile.dirty &&
        this.whiteLabelDataForm.value.teamReportFileSource
      ) {
        const teamChallengePath = WHITE_LABEL_TEAM_CHALLENGE_REPORT_TEMPLATE_PATH.replace(
          'WHITE_LABEL_ID',
          this.whiteLabel.id
        );
        await this.firebaseService.uploadFile(
          this.whiteLabelDataForm.value.teamReportFileSource,
          teamChallengePath
        );
      }

      this.whiteLabelService
        .updateWhiteLabelToEdit(whiteLabel)
        .then(() => {
          this.snackBar.open('White Label correctly updated', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          this.showSpinner = false;
          this.router.navigate(['admin'], {
            state: { tab: AdminTabs.WhiteLabels },
          });
        })
        .catch((error) => {
          this.snackBar.open('Error during White Label update ' + error, 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
          this.showSpinner = false;
        });
    }
  }

  public onChangeWhiteLabelColor(color: string): void {
    this.whiteLabelDataForm.patchValue({ color });
  }

  public onChangeWhiteLabelButtonColor(color: string): void {
    this.whiteLabelButtonDataForm.patchValue({ color });
  }

  public returnPage(): void {
    this.router.navigate(['../..'], {
      relativeTo: this.route,
      state: { tab: AdminTabs.WhiteLabels },
    });
  }
}
