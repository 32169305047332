import { Injectable } from '@angular/core';
import { DuelChallenge } from 'src/app/models/duel-challenge/duel-challenge.model';
import { FirebaseService } from '../firebase/firebase.service';
import { IDuelist } from 'src/app/interfaces/duelist.interface';

@Injectable({
  providedIn: 'root',
})
export class DuelChallengeService {
  constructor(private firebaseService: FirebaseService) {}

  public getDuelChallenge(
    companyId: string,
    duelId: string
  ): Promise<DuelChallenge> {
    return this.firebaseService
      .getDuelChallenge(companyId, duelId)
      .then((duelDocSnapshot) => {
        if (duelDocSnapshot.exists) {
          return new DuelChallenge(duelDocSnapshot.data());
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log(
          `Error duel challenge service getting a duel challenge: ${error}`
        );
        return null;
      });
  }

  public getUserWithMostStepsInADuel(duelists: Array<IDuelist>): IDuelist {
    return duelists.reduce((duelistMostSteps, duelist) => {
      if (
        !duelistMostSteps ||
        !duelistMostSteps.steps ||
        duelistMostSteps.steps < duelist.steps
      ) {
        return duelist;
      } else {
        return duelistMostSteps;
      }
    });
  }
}
