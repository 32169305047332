export interface ICompanyTrainingsStatistics {
  totalSteps?: number;
}

export class CompanyTrainingsStatistics {
  public totalSteps: number = 0;

  constructor(initializer?: ICompanyTrainingsStatistics) {
    Object.assign(this, initializer);
  }

  public toObject() {
    return Object.assign({}, this);
  }
}
