import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';

import { Training } from 'src/app/models/training.model';
import { Helpers } from 'src/app/helpers/helpers';
import { CompanyService } from 'src/app/services/company/company.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-widget-steps-chart',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit, OnDestroy {
  public chartOptions: any;
  public isChartReady = false;

  public Highcharts = Highcharts;
  private today = new Date().getTime();

  public translationsReady = false;
  public GRAPH_TITLE = '';
  public COMPANY_AVERAGE = '';
  public INDIVIDUAL_MAX_STEPS = '';
  public TARGET_STEPS = '';

  public stepsDateRange = new UntypedFormGroup({
    start: new UntypedFormControl(
      new Date(this.today - 7 * 24 * 60 * 60 * 1000)
    ),
    end: new UntypedFormControl(new Date(this.today)),
  });

  constructor(
    private translateService: TranslateService,
    private companyService: CompanyService,
    private subscriptionService: SubscriptionService
  ) {
    this.getTranslations();

    this.stepsDateRange.controls.end.valueChanges.subscribe((value) => {
      if (value) {
        this.calculateChart(this.stepsDateRange.controls.start.value, value);
      }
    });

    this.subscriptionService.company.subscribe((company) => {
      if (company && !this.isChartReady) {
        this.calculateChart(
          this.stepsDateRange.controls.start.value,
          this.stepsDateRange.controls.end.value
        );
      }
    });
  }

  private getTranslations(): void {
    this.translateService
      .stream([
        'graphs.steps.TITLE',
        'graphs.steps.COMPANY_AVERAGE',
        'graphs.steps.INDIVIDUAL_MAX_STEPS',
        'graphs.steps.TARGET_STEPS',
      ])
      .subscribe((values) => {
        this.GRAPH_TITLE = values['graphs.steps.TITLE'];
        this.COMPANY_AVERAGE = values['graphs.steps.COMPANY_AVERAGE'];
        this.TARGET_STEPS = values['graphs.steps.TARGET_STEPS'];
        this.INDIVIDUAL_MAX_STEPS = values['graphs.steps.INDIVIDUAL_MAX_STEPS'];
        this.translationsReady = true;
        if (this.isChartReady) {
          Highcharts.charts[0].series[0].update({
            name: this.COMPANY_AVERAGE,
            type: 'areaspline',
          });
          Highcharts.charts[0].series[1].update({
            name: this.INDIVIDUAL_MAX_STEPS,
            type: 'areaspline',
          });
          Highcharts.charts[0].series[2].update({
            name: this.TARGET_STEPS,
            type: 'areaspline',
          });
          Highcharts.charts[0].setTitle({
            text: this.GRAPH_TITLE,
          });
          Highcharts.charts[0].redraw();
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    Highcharts.charts.splice(0, Highcharts.charts.length);
  }

  private async calculateChart(startDate: Date, endDate: Date): Promise<void> {
    const categories: Array<string> = [];
    const companyAverages: Array<number> = [];
    const companyMaxSteps: Array<number> = [];
    const companyTargetSteps: Array<number> = [];

    const startDateYYYYMMDD = Helpers.formatDateYYYYMMDD(startDate);
    const endDateYYYYMMDD = Helpers.formatDateYYYYMMDD(endDate);
    this.companyService
      .getStepsByDateRange(startDateYYYYMMDD, endDateYYYYMMDD)
      .then(async (trainings: Array<Training>) => {
        trainings.forEach((training) => {
          categories.push(training.date);

          const trainingAverage = training.trainings.reduce(
            (accumulator, currentValue, index) => {
              return (
                accumulator + (currentValue.steps - accumulator) / (index + 1)
              );
            },
            0
          );
          companyAverages.push(Math.floor(trainingAverage));

          const maxSteps = Math.max(
            ...training.trainings.map((mappedTraining) => mappedTraining.steps)
          );
          companyMaxSteps.push(maxSteps);

          companyTargetSteps.push(8000);
        });
        if (!this.isChartReady) {
          this.prepareChartOptions(
            categories,
            companyAverages,
            companyMaxSteps,
            companyTargetSteps
          );
        } else {
          this.updateChart(
            categories,
            companyAverages,
            companyMaxSteps,
            companyTargetSteps
          );
        }
      })
      .catch((error) => {
        console.log('calculateChart - error, ', error);
      });
  }

  private updateChart(
    categories: Array<string>,
    companyAverages: Array<number>,
    companyMaxSteps: Array<number>,
    companyTargetSteps: Array<number>
  ): void {
    Highcharts.charts[0].xAxis[0].setCategories(categories);
    Highcharts.charts[0].series[0].setData(companyAverages);
    Highcharts.charts[0].series[1].setData(companyMaxSteps);
    Highcharts.charts[0].series[2].setData(companyTargetSteps);
    Highcharts.charts[0].redraw();
  }

  private prepareChartOptions(
    categories: Array<string>,
    companyAverages: Array<number>,
    companyMaxSteps: Array<number>,
    companyTargetSteps: Array<number>
  ): void {
    this.chartOptions = {
      chart: {
        type: 'areaspline',
      },
      title: {
        text: this.GRAPH_TITLE,
      },
      colors: ['#F97A1C', '#BDC6CE', '#5E5F67'],
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      },
      xAxis: {
        categories,
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      tooltip: {
        shared: true,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.3,
        },
      },
      series: [
        {
          name: this.COMPANY_AVERAGE,
          data: companyAverages,
        },
        {
          name: this.INDIVIDUAL_MAX_STEPS,
          data: companyMaxSteps,
        },
        {
          name: this.TARGET_STEPS,
          data: companyTargetSteps,
          dashStyle: 'Dash',
          marker: {
            enabled: false,
          },
        },
      ],
    };

    HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);

    this.isChartReady = true;
  }
}
