import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Helpers } from 'src/app/helpers/helpers';

// Enums
import { IconNames } from 'src/app/models/assets-constants';

@Component({
  selector: 'app-edit-image-and-name-card',
  templateUrl: './edit-image-and-name-card.component.html',
  styleUrls: ['./edit-image-and-name-card.component.scss'],
})
export class EditImageAndNameCardComponent {
  @Input() formGroup: FormGroup;
  @Input() image: string;
  @Input() remove: (formGroup: FormGroup) => void;

  public trashIcon = IconNames.TrashOutline;

  constructor() {}

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }
}
