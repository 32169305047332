<div class="container">
  <app-challenge-header [challengeName]="citiesChallengeDashboard.citiesChallenge.name" [challengeDuration]="duration" [challengeIcon]="CITIES_CHALLENGE_ICON"></app-challenge-header>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ 'CITIES_CHALLENGE.TITLE'| translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="!showSpinner">
        <div class="date-picker-container">
          <mat-form-field appearance="fill">
            <mat-label> {{ "CITIES_CHALLENGE.DATE_PICKER_LABEL" | translate }} </mat-label>
            <input matInput [matDatepicker]="citiesChallengeDatePicker" [min]="citiesChallengeDashboard.citiesChallenge.startDate" [max]="maxDate" [formControl]="dateFormControl" (dateChange)="getChallengeDayPerformance($event)">
            <mat-datepicker-toggle matSuffix [for]="citiesChallengeDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #citiesChallengeDatePicker disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-grid-list cols="3" rowHeight="150px">
          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <app-stage-progress class="cards" [stageProgressCard]="stageProgressCard"></app-stage-progress>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <app-circle-progress-chart class="cards"  [circleProgressCard]="circleProgressCard"></app-circle-progress-chart>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="4">
            <app-cities-list class="cards" [citiesList]="citiesList"></app-cities-list>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <app-kpi-list class="cards" [kpiList]="performanceKpiList"></app-kpi-list>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <app-kpi-list class="cards" [kpiList]="challengeKpiList"></app-kpi-list>
          </mat-grid-tile>
        </mat-grid-list>
        <p class="total-results-title">{{ 'CITIES_CHALLENGE.TOTAL_RESULTS_TITLE'| translate }}</p>
        <mat-grid-list cols="3" rowHeight="150px">
          <mat-grid-tile [colspan]="1" [rowspan]="3">
            <app-user-ranking class="cards" [userRanking]="userRanking"></app-user-ranking>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2" [rowspan]="3">
            <app-forecast-graph class="cards" [forecastGraph]="forecastGraphCard"></app-forecast-graph>
          </mat-grid-tile>
        </mat-grid-list>
        <div class="download-container">
          <button mat-raised-button (click)="downloadReport()" aria-label="Download from browser" [disabled]="downloading">
            <span>{{ 'CITIES_CHALLENGE.DOWNLOAD_BUTTON' | translate }}</span>
            <mat-icon *ngIf="!downloading">cloud_download</mat-icon>
            <mat-spinner *ngIf="downloading" [diameter]="20"></mat-spinner>
          </button>
        </div>
      </div>
      <div class="spinner-container"  *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>
</div>

