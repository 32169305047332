<div>
  <div>
    <h1 class="title">{{ 'USERS.TITLE' | translate }}</h1>
  </div>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start"
  >
    <mat-card class="cards">
      <p class="card-title">{{ 'USERS.TITLE' | translate }}</p>
      <p class="card-number">{{ companyUsers }}</p>
    </mat-card>
    <mat-card class="cards">
      <p class="card-title">{{ 'USERS.LOCATIONS' | translate }}</p>
      <p class="card-number">{{ locations.length }}</p>
    </mat-card>
  </div>

  <h2 class="subtitle">{{ 'USERS.USERS_PER_LOCATION' | translate }}</h2>

  <table mat-table [dataSource]="locationDataSource" matSort #usersSort="matSort" class="mat-elevation-z8">
    <!-- Flag Column -->
    <!-- <ng-container matColumnDef="flag">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let location" class="flag">
        <img src="{{ location.flag }}" alt="flag" />
      </td>
    </ng-container> -->

    <!-- Location name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LOCATION' | translate }}</th>
      <td mat-cell *matCellDef="let location">{{ location.name }}</td>
    </ng-container>

    <!-- User amount Column -->
    <ng-container matColumnDef="userAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.TITLE' | translate }}</th>
      <td mat-cell *matCellDef="let location">{{ location.userAmount }}</td>
    </ng-container>

    <!-- Total steps Column -->
    <ng-container matColumnDef="totalSteps">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Total steps' | translate }}</th>
      <td mat-cell *matCellDef="let location">{{ location.totalSteps }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
