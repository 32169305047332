import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from 'src/app/helpers/helpers';
import { CitiesChallengePerformance } from 'src/app/models/cities-challenge/cities-challenge-performance/cities-challenge-performance.model';
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { CityMarkerPoint } from 'src/app/models/cities-challenge/city-marker-point/city-marker-point.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { CitiesService } from 'src/app/services/cities/cities.service';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';

export interface ICitiesList {
  title: string;
  citiesChallenge: CitiesChallenge;
  cityChallengePerformance: CitiesChallengePerformance;
}
@Component({
  selector: 'app-cities-list',
  templateUrl: './cities-list.component.html',
  styleUrls: ['./cities-list.component.scss'],
})
export class CitiesListComponent implements AfterViewInit {
  @Input() citiesList: ICitiesList;
  @ViewChild('citiesListCardContent', { static: true })
  contentElement: ElementRef;

  public gettingCityInfoIndex = -1;

  constructor(
    private citiesService: CitiesService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit(): void {
    Helpers.calculateCardContentHeight(this.contentElement, 16);
  }

  get lang(): string {
    return this.translateService.currentLang;
  }

  public getFlagPath(city: City): string {
    return Helpers.getFlagPath(city.countryAlphaCode);
  }

  public openCityInfo(cityMarkerPoint: CityMarkerPoint, index: number): void {
    this.gettingCityInfoIndex = index;

    this.citiesService
      .getCityInfoFromFirebase(
        cityMarkerPoint.city.id,
        cityMarkerPoint.cityInfoType
      )
      .then((cityInfo) =>
        this.citiesService.openCityInfoCardDialog(
          cityMarkerPoint.city,
          cityInfo,
          true
        )
      )
      .catch(() =>
        this.userMessageService.snackBarMessage(
          'CITIES_CHALLENGE.CITIES_LIST.CITY_INFO_ERROR'
        )
      )
      .finally(() => (this.gettingCityInfoIndex = -1));
  }
}
