<div class="container">
  <app-challenge-header challengeName="DUEL_CHALLENGE.NAME" [challengeDuration]="duelChallengeDuration" [challengeIcon]="DUEL_CHALLENGE_ICON"></app-challenge-header>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ 'DUEL_CHALLENGE.TITLE'| translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="!showSpinner">
        <div class="date-picker-container">
          <mat-form-field appearance="fill" (click)="openDatePicker()">
            <mat-label> {{ "DUEL_CHALLENGE.DATE_PICKER_LABEL" | translate }} </mat-label>
            <input matInput [matDatepicker]="duelDatePicker" [max]="today" [formControl]="dateFormControl">
            <mat-datepicker-toggle matSuffix [for]="duelDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #duelDatePicker
                            startView="year"
                            (monthSelected)="setMonthAndYear($event, duelDatePicker)"
                            panelClass="month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <mat-grid-list cols="3" rowHeight="150px">
          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <app-kpi-list class="cards" [kpiList]="kpiList"></app-kpi-list>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <app-circle-progress-chart class="cards" [circleProgressCard]="circleProgressCard"></app-circle-progress-chart>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="3">
            <app-user-ranking class="cards" [userRanking]="userRanking"></app-user-ranking>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <app-badge-card class="cards" [infoBadgeCard]="winnerBadge"></app-badge-card>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="1">
            <app-badge-card class="cards" [infoBadgeCard]="mostStepsBadge"></app-badge-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div class="spinner-container"  *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
      </div>
    </mat-card-content>
  </mat-card>
</div>
