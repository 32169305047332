<div class="spinner-container" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>
<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
  *ngIf="!showSpinner"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1  class="title">{{ eventIdToEdit ? ['COMPANY_INFO.EVENT.UPDATE_EVENT'| translate ] : 'COMPANY_INFO.EVENT.ADD_EVENT' | translate }}</h1>
  </div>
  <form [formGroup]="eventDataForm">
      <!-- id -->
      <mat-form-field>
       <mat-label>{{ 'COMPANY_INFO.EVENT.WEEKEND_EVENT_ID' | translate }}</mat-label>
       <input matInput formControlName="id" />
       <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <!-- name -->
      <mat-form-field>
       <mat-label>{{ 'COMPANY_INFO.EVENT.EVENT_NAME' | translate }}</mat-label>
       <input matInput formControlName="name" />
       <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <!-- date range -->
      <mat-form-field appearance="fill">
        <mat-label>{{ 'OVERVIEW.DATE_SELECTION' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange" (click)="picker.open()">
          <input
            matStartDate
            placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_START' | translate }}"
            formControlName="start"
          />
          <input
            matEndDate
            placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_END' | translate }}"
            formControlName="end"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    <!-- distance -->
     <mat-form-field>
      <mat-label>{{ 'COMPANY_INFO.EVENT.DISTANCE_IN_KM' | translate }}</mat-label>
      <input matInput formControlName="distance" />
      <mat-error>{{ 'ADD_TEAM_CHALLENGE_ERROR' | translate }}</mat-error>
    </mat-form-field>
  </form>
  <div class="buttons">
    <button
      mat-raised-button
      [disabled]="!eventDataForm.valid || dateRange.invalid"
      (click)="addEvent()">
      {{ eventIdToEdit ? ['COMPANY_INFO.EVENT.UPDATE_EVENT'| translate ] : 'COMPANY_INFO.EVENT.ADD_EVENT' | translate }}
    </button>
  </div>
</div>
