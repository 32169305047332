import { Deserializable } from './deserializable.model';

export interface ITeamSchemaInitializer {
  id?: string;
  avatar?: string;
  color?: string;
  name?: string;
}

export class TeamSchema implements Deserializable {
  public id = '';
  public avatar = '';
  public color = '';
  public name = '';

  constructor(initializer?: ITeamSchemaInitializer) {
    Object.assign(this, initializer);
  }

  public toObject(): any {
    return Object.assign({}, this);
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
