import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

// Models
import { CityInfo } from 'src/app/models/cities-challenge/city-info/city-info.model';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';

// Enums
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
import { IconNames } from 'src/app/models/assets-constants';

// Dialog
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

// Services
import { CitiesService } from 'src/app/services/cities/cities.service';
import { City } from 'src/app/models/cities-challenge/city/city.model';

@Component({
  selector: 'app-city-info-card',
  templateUrl: './city-info-card.component.html',
  styleUrls: ['./city-info-card.component.scss'],
  animations: [
    trigger('animSlider', [
      transition(
        ':increment, :decrement',
        [
          style({ transform: 'translateX({{ direction }}%)' }),
          animate('.5s ease-out', style({ transform: 'translateX(0%)' })),
        ],
        { params: { direction: 0 } }
      ),
    ]),
  ],
})
export class CityInfoCardComponent {
  @Input() cityInfo: CityInfo;
  @Input() city: City;
  @Input() viewOnly = false;

  public currentIndex = 0;
  public animationDirection = 0;
  public trashIcon = IconNames.TrashOutline;
  public pencilIcon = IconNames.PencilOutline;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private citiesService: CitiesService
  ) {}

  public changeImage(direction: number): void {
    this.currentIndex += direction;
    this.animationDirection = direction;
    if (this.currentIndex < 0) {
      this.currentIndex = this.cityInfo.details.length - 1;
    } else if (this.currentIndex >= this.cityInfo.details.length) {
      this.currentIndex = 0;
    }
  }

  public editCityInfo(): void {
    this.citiesService.openEditCityInfoDialog(this.city, this.cityInfo);
  }

  public removeCityInfo(): void {
    const dialogTranslations = this.translateService.instant(
      'CITY_INFO_DELETE.DIALOG'
    );

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: new ConfirmationDialogData({
        title: dialogTranslations.TITLE.replace(
          '{{ name }}',
          this.cityInfo.name
        ),
        message: dialogTranslations.MESSAGE,
        confirmLabel: dialogTranslations.CONFIRM,
        cancelLabel: dialogTranslations.CANCEL,
        showSpinner: true,
        action: (): Promise<void> =>
          this.citiesService.removeCityInfo(this.city.id, this.cityInfo),
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.showSnackBar('CITY_INFO_DELETE.SNACK_BAR.SUCCESSFUL');
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.showSnackBar('CITY_INFO_DELETE.SNACK_BAR.ERROR', result.error);
          break;
      }
    });
  }

  private showSnackBar(snackBarType: string, error?: string): void {
    this.snackBar.open(
      this.translateService.instant(snackBarType, error ? { error } : null),
      this.translateService.instant('Ok'),
      {
        duration: 4000,
        panelClass: 'snack-bar-color',
      }
    );
  }
}
