<h1 mat-dialog-title>Edit City</h1>
<div mat-dialog-content>
  <form [formGroup]="cityDataForm">
    <mat-form-field>
      <mat-label>{{ 'forms.distanceChallenge.cities.startingCity.LABEL' | translate }}</mat-label>
      <input type="text" matInput [matAutocomplete]="startingAutocomplete" formControlName="city" />
      <mat-autocomplete #startingAutocomplete="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let city of filteredCities | async"
          [value]="city"
        >
          <img height="24px" width="32px" src="assets/countries/flags/1x1/{{ city?.countryAlphaCode.toLowerCase() }}.svg" />
          {{ city.translations.get(currentLang) }}
        </mat-option>
      </mat-autocomplete>
      <mat-error>{{ 'Error' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'forms.distanceChallenge.cities.distance.LABEL' | translate }}</mat-label>
      <input type="number" matInput formControlName="distance" />
      <mat-error>{{ 'forms.distanceChallenge.cities.distance.ERROR' | translate }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button [disabled]="!cityDataForm.valid" [mat-dialog-close]="data" cdkFocusInitial>Save</button>
</div>
