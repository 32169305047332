import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company.model';
import { CompanyService } from 'src/app/services/company/company.service';
import { EventsService } from 'src/app/services/events/events.service';
import { Event } from 'src/app/models/event/event';
import { EventStatus } from 'src/app/models/event/event-status.enum';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  public showSpinner = false;

  public EVENT_STATUS_NOT_STARTED = EventStatus.NotStarted;
  public EVENT_STATUS_IN_PROGRESS = EventStatus.InProgress;
  public EVENT_STATUS_COMPLETED = EventStatus.Completed;

  constructor(
    private eventsService: EventsService,
    private companyService: CompanyService
  ) {}

  get company(): Company {
    return this.companyService.company;
  }

  get companyEvents(): Array<Event> {
    return this.eventsService.companyEvents;
  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.eventsService
      .getCompanyEvents(this.company.id)
      .then(() => {
        this.showSpinner = false;
      })
      .catch((error) => {
        console.log('error on ngOnInit EventsComponent: ', error);
      });
  }
}
