import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

// Models
import { Company } from 'src/app/models/company.model';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { Team } from 'src/app/models/team.model';
import { CompanyTrainingsStatistics } from 'src/app/models/company-trainings-statistics.model';
import { Roles } from 'src/app/models/roles.enum';

// Services
import { UserService } from 'src/app/services/user/user.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import {
  IChallengeData,
  ProjectManagerCompanyService,
} from 'src/app/services/project-manager-company/project-manager-company.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';

// Constants
import { STEPS_IN_ONE_KILOMETER } from 'src/app/constants/constants';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  public updatingValue = false;

  public userDefinedUsers = 0;
  public userDefinedSteps = 0;

  public disableButton = false;
  public spinnerFlag = false;
  public reportDateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  private today = new Date().getTime();

  public trainingDateRange = new UntypedFormGroup({
    start: new UntypedFormControl(
      new Date(this.today - 7 * 24 * 60 * 60 * 1000)
    ),
    end: new UntypedFormControl(new Date(this.today)),
  });

  public DETAILS_LABEL = '';
  public DAILY_STEPS_SUBTITLE = '';
  public CURRENT_STEPS = '';
  public TODAY_AVERAGE = '';
  public CURRENT_KILOMETERS = '';
  public HISTORIC_STEPS_SUBTITLE = '';
  public TOTAL_STEPS = '';
  public TOTAL_KILOMETERS = '';
  public ACTIVE_USERS = '';
  public LICENCES = '';
  public FULLFILLED_CHALLENGES_IN_TOTAL = '';
  public EMPLOYEES_WHO_AT_LEAST_FULFILLED_ONE_CHALLENGE = '';
  public AMOUNT_OF_FULFILLED_CHALLENGES_MONTH = '';
  public CURRENT_AVERAGE_STEPS_TOTAL = '';
  public YOU_BELONG_TO_THE_TEAM = '';
  public TEAM_LEADER = '';
  public MEMBERS_AMOUNT = '';
  public JOIN_A_TEAM = '';

  public STEPS_IN_ONE_KILOMETER: number = STEPS_IN_ONE_KILOMETER;
  public ROLES = Roles;

  constructor(
    private companyService: CompanyService,
    private translateService: TranslateService,
    private userService: UserService,
    private dateAdapter: DateAdapter<any>,
    private teamsService: TeamsService,
    private subscriptionService: SubscriptionService,
    private projectManagerCompanyService: ProjectManagerCompanyService
  ) {
    this.getTranslations();
    this.dateAdapter.setLocale('de');
    this.trainingDateRange.controls.end.valueChanges.subscribe((value) => {
      if (value) {
        this.getUserTrainingData(
          this.trainingDateRange.controls.start.value,
          value
        );
      }
    });

    this.subscriptionService.company.subscribe((company) => {
      if (company && this.userDefinedUsers === 0) {
        this.getUserTrainingData(
          new Date(this.today - 7 * 24 * 60 * 60 * 1000),
          new Date(this.today)
        );
      }
    });
  }

  ngOnInit(): void {}

  get totalSteps(): number {
    return this.companyService.totalSteps;
  }
  get averageTotalSteps(): number {
    return this.companyService.averageTotalSteps;
  }
  get todayAverage(): number {
    return this.companyService.todayAverage;
  }
  get todaySteps(): number {
    return this.companyService.todaySteps;
  }
  get company(): Company {
    return this.companyService.company;
  }
  get user(): User {
    return this.userService.user;
  }
  get team(): Team {
    return this.teamsService.team;
  }

  get usersWhoCompletedAChallenge(): number {
    return this.companyService.usersWhoCompletedAChallenge;
  }

  get currentMonthChallenges(): number {
    return this.companyService.currentMonthChallenges;
  }

  get activeManagedCompanies(): Array<Company> {
    return this.projectManagerCompanyService.activeManagedCompanies;
  }

  get inactiveManagedCompanies(): Array<Company> {
    return this.projectManagerCompanyService.inactiveManagedCompanies;
  }

  get managedCompaniesStatistics(): Map<string, CompanyTrainingsStatistics> {
    return this.projectManagerCompanyService.managedCompaniesStatistics;
  }

  get managedCompaniesChallengesData(): Map<string, Array<IChallengeData>> {
    return this.projectManagerCompanyService.managedCompaniesChallengesData;
  }

  private getTranslations(): void {
    this.translateService
      .stream([
        'OVERVIEW.CURRENT_AVERAGE_STEPS',
        'OVERVIEW.DAILY_STEPS_SUBTITLE',
        'OVERVIEW.TODAY_AVERAGE',
        'OVERVIEW.CURRENT_STEPS',
        'OVERVIEW.CURRENT_KILOMETERS',
        'OVERVIEW.HISTORIC_STEPS_SUBTITLE',
        'OVERVIEW.TOTAL_STEPS',
        'OVERVIEW.TOTAL_KILOMETERS',
        'OVERVIEW.ACTIVE_USERS',
        'OVERVIEW.LICENCES',
        'OVERVIEW.FULLFILLED_CHALLENGES_IN_TOTAL',
        'OVERVIEW.EMPLOYEES_WHO_AT_LEAST_FULFILLED_ONE_CHALLENGE',
        'OVERVIEW.AMOUNT_OF_FULFILLED_CHALLENGES_MONTH',
        'OVERVIEW.DETAILS_LABEL',
        'OVERVIEW.CURRENT_AVERAGE_STEPS_TOTAL',
        'OVERVIEW.YOU_BELONG_TO_THE_TEAM',
        'OVERVIEW.TEAM_LEADER',
        'OVERVIEW.MEMBERS_AMOUNT',
        'OVERVIEW.JOIN_A_TEAM',
      ])
      .subscribe((values) => {
        this.DAILY_STEPS_SUBTITLE = values['OVERVIEW.DAILY_STEPS_SUBTITLE'];
        this.CURRENT_STEPS = values['OVERVIEW.CURRENT_STEPS'];
        this.TODAY_AVERAGE = values['OVERVIEW.TODAY_AVERAGE'];
        this.CURRENT_KILOMETERS = values['OVERVIEW.CURRENT_KILOMETERS'];
        this.HISTORIC_STEPS_SUBTITLE =
          values['OVERVIEW.HISTORIC_STEPS_SUBTITLE'];
        this.TOTAL_STEPS = values['OVERVIEW.TOTAL_STEPS'];
        this.TOTAL_KILOMETERS = values['OVERVIEW.TOTAL_KILOMETERS'];
        this.ACTIVE_USERS = values['OVERVIEW.ACTIVE_USERS'];
        this.LICENCES = values['OVERVIEW.LICENCES'];
        this.FULLFILLED_CHALLENGES_IN_TOTAL =
          values['OVERVIEW.FULLFILLED_CHALLENGES_IN_TOTAL'];
        this.EMPLOYEES_WHO_AT_LEAST_FULFILLED_ONE_CHALLENGE =
          values['OVERVIEW.EMPLOYEES_WHO_AT_LEAST_FULFILLED_ONE_CHALLENGE'];
        this.AMOUNT_OF_FULFILLED_CHALLENGES_MONTH =
          values['OVERVIEW.AMOUNT_OF_FULFILLED_CHALLENGES_MONTH'];
        this.DETAILS_LABEL = values['OVERVIEW.DETAILS_LABEL'];
        this.CURRENT_AVERAGE_STEPS_TOTAL =
          values['OVERVIEW.CURRENT_AVERAGE_STEPS_TOTAL'];
        this.YOU_BELONG_TO_THE_TEAM = values['OVERVIEW.YOU_BELONG_TO_THE_TEAM'];
        this.TEAM_LEADER = values['OVERVIEW.TEAM_LEADER'];
        this.MEMBERS_AMOUNT = values['OVERVIEW.MEMBERS_AMOUNT'];
        this.JOIN_A_TEAM = values['OVERVIEW.JOIN_A_TEAM'];
      });
  }

  public getUserTrainingData(startDate: Date, endDate: Date): void {
    this.updatingValue = true;

    const startDateYYYYMMDD = Helpers.formatDateYYYYMMDD(startDate);
    const endDateYYYYMMDD = Helpers.formatDateYYYYMMDD(endDate);

    this.companyService
      .getStepsByDateRange(startDateYYYYMMDD, endDateYYYYMMDD)
      .then(async (trainings: Array<Training>) => {
        this.userDefinedSteps = 0;
        this.userDefinedUsers = 0;

        this.userDefinedSteps = trainings.reduce((totalSteps, training) => {
          return (totalSteps += training.trainings.reduce(
            (trainingTotalSteps, trainingData) => {
              return (trainingTotalSteps += trainingData.steps);
            },
            0
          ));
        }, 0);

        this.userDefinedUsers = trainings.reduce((totalUsers, training) => {
          return (totalUsers += training.trainings.length);
        }, 0);
      })
      .catch((error) => {
        console.log('getStepsByDateRange - error, ', error);
      })
      .finally(() => {
        this.updatingValue = false;
      });
  }
}
