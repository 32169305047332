import { Injectable } from '@angular/core';
import { Settings } from 'src/app/models/settings.model';
import { Team } from 'src/app/models/team.model';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  constructor() {}

  public setUserId(userId: string): void {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }
  }

  public getUserId(): string {
    return localStorage.getItem('userId');
  }

  // public setUser(user: User): void {
  //   localStorage.setItem('user', JSON.stringify(user));
  // }

  // public getUser(): User {
  //   return JSON.parse(localStorage.getItem('user'));
  // }

  public setCompanyId(companyId: string): void {
    localStorage.setItem('companyId', companyId);
  }

  public getCompanyId(): string {
    return localStorage.getItem('companyId');
  }

  // public setCompany(company: Company): void {
  //   localStorage.setItem(
  //     'company',
  //     JSON.stringify(company !== null ? company.toObject() : null)
  //   );
  // }

  // public getCompany(): Company {
  //   return JSON.parse(localStorage.getItem('company'));
  // }

  // public getCompanies(): Array<Company> {
  //   return JSON.parse(localStorage.getItem('companies'));
  // }

  // public setCompanies(companies: any): void {
  //   localStorage.setItem('companies', JSON.stringify(companies));
  // }

  public setCompanyLastUpdated(lastUpdated: number): void {
    localStorage.setItem('companyLastUpdated', lastUpdated.toString());
  }

  public getCompanyLastUpdated(): number {
    return Number(localStorage.getItem('companyLastUpdated'));
  }

  public setTeam(team: Team): void {
    if (team !== null) {
      return localStorage.setItem('team', JSON.stringify(team.toObject()));
    } else {
      return localStorage.setItem('team', JSON.stringify(team));
    }
  }

  public getTeam(): Team {
    return JSON.parse(localStorage.getItem('team'));
  }

  public setTeamId(teamId: string): void {
    return localStorage.setItem('teamId', teamId);
  }

  public getTeamId(): string {
    return localStorage.getItem('teamId');
  }

  public setTeams(teams: Array<Team>): void {
    const teamsToSave = teams.map((team) => team.toObject());
    return localStorage.setItem('teams', JSON.stringify(teamsToSave));
  }

  public getTeams(): Array<Team> {
    return JSON.parse(localStorage.getItem('teams'))?.map((element) =>
      new Team({}).deserialize(element)
    );
  }

  public setSettings(settings: Settings): void {
    localStorage.setItem('settings', JSON.stringify(settings));
  }

  public getSettings(): Settings {
    return JSON.parse(localStorage.getItem('settings'));
  }
}
