<mat-card>
  <div *ngIf="showHeader" class="card-title">
    {{ title }}
  </div>
  <div class="image-number-container">
    <div class="card-number" [ngClass]="{ 'updating-color': updatingValue }">
      {{ average ? '&#8709;' : '' }}
      {{ ( average ? (number | number: '1.0-0':'de') : (number | number: '1.0-2':'de')) }}
      {{ unit }}
      {{ cityName }}
      {{text}}
    </div>
    <img *ngIf="showImage" [src]="image" />
  </div>
</mat-card>
