import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions';

// Models
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { Company } from 'src/app/models/company.model';
import { PointsSource } from 'src/app/models/points-source.model';
import { Team } from 'src/app/models/team.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge.model';
import { Team as TeamV2 } from 'src/app/models/team-challenge/team/team.model';
import { User } from 'src/app/models/user.model';
import { Report } from 'src/app/models/reports/report.model';
import { TeamSchema } from 'src/app/models/team-schema.model';
import { Location } from 'src/app/models/location.model';
import { RouteSchema } from 'src/app/models/route-schema.model';
import { CityInfo } from 'src/app/models/cities-challenge/city-info/city-info.model';
import { WhiteLabel } from 'src/app/models/white-label.model';
import { PersonalChallengeSchema } from 'src/app/models/personal-challenge-schema/personal-challenge-schema';
import { TeamChallengeSchema } from 'src/app/models/team-challenge-schema/team-challenge-schema';
import { Event } from 'src/app/models/event/event';
import { ReportTypes } from 'src/app/models/reports/report-types.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Notification } from 'src/app/models/notification.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { Route } from 'src/app/models/cities-challenge/route/route.model';
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { ERROR_CODES } from 'src/app/constants/constants';
import { Helpers } from 'src/app/helpers/helpers';
import { GroupRunChallenge } from 'src/app/models/group-run/group-run-challenge/group-run-challenge';

interface IObserverDocument {
  next?: (
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => void;
  error?: (error: firebase.firestore.FirestoreError) => void;
  complete?: () => void;
}

interface IObserverQuery {
  next?: (
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) => void;
  error?: (error: firebase.firestore.FirestoreError) => void;
  complete?: () => void;
}

interface IObserverDocument {
  next?: (
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => void;
  error?: (error: firebase.firestore.FirestoreError) => void;
  complete?: () => void;
}

interface IObserverQuery {
  next?: (
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) => void;
  error?: (error: firebase.firestore.FirestoreError) => void;
  complete?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private db: firebase.firestore.Firestore;

  constructor(private http: HttpClient) {
    this.db = firebase.firestore();
  }

  public getAutomaticIdInRootCollection(collectionId: string): string {
    return this.db.collection(collectionId).doc().id;
  }

  public getAutomaticIdInCompanySubCollection(
    companyId: string,
    collectionId: string
  ): string {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(collectionId)
      .doc().id;
  }

  public signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        console.log('error while logging in: ', error);
        return Promise.reject(error);
      });
  }

  public persistenceSignInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
      .catch((error) => {
        console.log('error while logging in: ', error);
        return Promise.reject(error);
      });
  }

  public createUserAccount(userAccount: User, password: string): Promise<void> {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(userAccount.email, password)
      .then((createdUser) => {
        this.sendVerificationEmail(
          userAccount.email,
          userAccount.displayName,
          userAccount.settings.lang
        );
        userAccount.id = createdUser.user.uid;
        this.signupUserOnCompany(userAccount.id, userAccount.companyId);
        return this.setUser(userAccount.id, userAccount);
      })
      .catch((error) => Promise.reject(error));
  }

  public async deleteUser(email: string, password: string): Promise<void> {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    await firebase
      .auth()
      .signInWithCredential(credential)
      .then(async () => {
        const batch = firebase.firestore().batch();

        const userQuery = await this.db
          .collection(FirebaseConstants.UsersCollection)
          .where(
            FirebaseConstants.EmailProp,
            FirebaseConstants.EqualOperation,
            email
          )
          .get();

        if (userQuery.size > 0) {
          const userDoc = userQuery.docs[0];
          batch.delete(userDoc.ref);

          return batch.commit().then(() => {
            const user = firebase.auth().currentUser;
            user.delete();
          });
        } else {
          Promise.reject(ERROR_CODES.AUTH.USER_NOT_FOUND);
        }
      })
      .catch((error) => Promise.reject(error.code));
  }

  public signupUserOnCompany(userId: string, companyId: string): Promise<void> {
    return this.db
      .collection('companies')
      .doc(companyId)
      .update({
        users: firebase.firestore.FieldValue.arrayUnion(userId),
      });
  }

  public setUser(userId: string, userAccount: User): Promise<void> {
    return this.db.collection('users').doc(userId).set(userAccount.toObject());
  }
  // USER FUNCTIONS

  public getUser(
    userId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .doc(userId)
      .get();
  }

  public subscribeToUser(
    userId: string,
    observer: IObserverDocument
  ): () => void {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .doc(userId)
      .onSnapshot(observer);
  }

  public getUserByEmail(
    userEmail: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .where('email', '==', userEmail)
      .limit(1)
      .get();
  }

  public getUsersFromCompany(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .where(
        FirebaseConstants.CompanyIdProp,
        FirebaseConstants.EqualOperation,
        companyId
      )
      .get();
  }

  public getUsersFromCompanies(
    companyIds: Array<string>
  ): Array<
    Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>
  > {
    const promisesArray = [];
    for (let i = 0; i < companyIds.length; i += 10) {
      promisesArray.push(
        this.db
          .collection(FirebaseConstants.UsersCollection)
          .where(
            FirebaseConstants.CompanyIdProp,
            FirebaseConstants.InOperation,
            companyIds.slice(i, i + 10)
          )
          .get()
      );
    }
    return promisesArray;
  }

  public getUsers(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.UsersCollection).get();
  }

  public checkIfUserIsAdmin(
    userId: string,
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .where(companyId, FirebaseConstants.ArrayContainsOperation, userId)
      .get();
  }

  public checkForUsedEmail(email: string): Promise<Array<string>> {
    return firebase.auth().fetchSignInMethodsForEmail(email);
  }

  public updateUser(userId: string, update: Partial<User>): Promise<void> {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .doc(userId)
      .set(update, { merge: true });
  }

  public updateTraining(userId: string, date: string, update): Promise<void> {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .doc(userId)
      .collection(FirebaseConstants.TrainingsCollection)
      .doc(date)
      .set(update, { merge: true });
  }

  // COMPANY FUNCTIONS

  public getCompany(
    companyId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .get();
  }

  public subscribeToCompanies(observer: IObserverQuery): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .onSnapshot(observer);
  }

  public subscribeToCompany(
    companyId: string,
    observer: IObserverDocument
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .onSnapshot(observer);
  }

  public subscribeToCompanyDailyRanking(
    companyId: string,
    day: string,
    observer: IObserverDocument
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TrainingsCollection)
      .doc(day)
      .onSnapshot(observer);
  }

  public getCompanies(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.CompaniesCollection).get();
  }

  public getManagedCompanies(
    managerCompanyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .where(
        FirebaseConstants.ManagerCompanyIdsProp,
        FirebaseConstants.ArrayContainsOperation,
        managerCompanyId
      )
      .get();
  }

  public getCompaniesStatistics(
    companyIds: Array<string>
  ): Promise<
    Array<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>
  > {
    const queries = companyIds.map((companyId) =>
      this.db
        .collection(FirebaseConstants.CompaniesCollection)
        .doc(companyId)
        .collection(FirebaseConstants.StatisticsCollection)
        .doc(FirebaseConstants.TrainingsDocument)
        .get()
    );
    return Promise.all(queries);
  }

  public getPushNotifications(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.PushNotificationsCollection)
      .orderBy(FirebaseConstants.IdProp, FirebaseConstants.DescOrder)
      .get();
  }

  public getPointsSources(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.PointsSourcesCollection).get();
  }

  public getRoutes(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.RoutesCollection).get();
  }

  public createRoute(route: Route): Promise<void> {
    return this.db
      .collection(FirebaseConstants.RoutesCollection)
      .doc(route.id)
      .set(route.toObject(), {
        merge: true,
      });
  }

  public updateRoute(route: RouteSchema): Promise<void> {
    return this.db
      .collection(FirebaseConstants.RoutesCollection)
      .doc(route.id)
      .update(route.toObject());
  }

  public deleteRoute(routeId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.RoutesCollection)
      .doc(routeId)
      .delete();
  }

  public getRoute(
    routeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.RoutesCollection)
      .doc(routeId)
      .get();
  }

  public getWhiteLabels(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.WhiteLabelsCollection).get();
  }

  // Create without an id
  public addWhiteLabel(whiteLabel: WhiteLabel): Promise<string> {
    return this.db
      .collection(FirebaseConstants.WhiteLabelsCollection)
      .add(whiteLabel.toObject())
      .then((docRef) => docRef.id);
  }

  public createWhiteLabel(whiteLabel: WhiteLabel): Promise<void> {
    return this.db
      .collection(FirebaseConstants.WhiteLabelsCollection)
      .doc(whiteLabel.id)
      .set(whiteLabel.toObject());
  }

  public updateWhiteLabel(whiteLabel: WhiteLabel): Promise<void> {
    return this.db
      .collection(FirebaseConstants.WhiteLabelsCollection)
      .doc(whiteLabel.id)
      .set(whiteLabel.toObject(), { merge: true });
  }

  public deleteWhiteLabel(whiteLabelId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.WhiteLabelsCollection)
      .doc(whiteLabelId)
      .delete();
  }

  public createPointsSource(pointsSource: PointsSource): Promise<void> {
    return this.db
      .collection(FirebaseConstants.PointsSourcesCollection)
      .doc(pointsSource.id)
      .set(pointsSource.toObject());
  }

  public getPointsSource(
    pointsSourceId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.PointsSourcesCollection)
      .doc(pointsSourceId)
      .get();
  }

  public updatePointsSource(pointsSource: PointsSource): Promise<void> {
    return this.db
      .collection(FirebaseConstants.PointsSourcesCollection)
      .doc(pointsSource.id)
      .update(pointsSource.toObject());
  }

  public deletePointsSource(pointsSourceId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.PointsSourcesCollection)
      .doc(pointsSourceId)
      .delete();
  }

  public getTeamSchemas(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.TeamsCollection).get();
  }

  public async createTeamSchema(
    teamId: string,
    teamSchema: TeamSchema
  ): Promise<void> {
    const teamRef = this.db
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId);
    const teamDoc = await teamRef.get();
    if (!teamDoc.exists) {
      return this.db
        .collection(FirebaseConstants.TeamsCollection)
        .doc(teamId)
        .set(teamSchema.toObject());
    } else {
      return Promise.reject('ID already used');
    }
  }

  public getTeamSchema(
    teamId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .get();
  }

  public updateTeamSchema(
    teamId: string,
    teamSchema: TeamSchema
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .update(teamSchema.toObject());
  }

  public deleteTeamSchema(teamId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .delete();
  }

  public getCompanyAvailableChallenges(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.AvailableChallengesCollection)
      .get();
  }

  public getAvailableChallenge(
    companyId: string,
    challengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.AvailableChallengesCollection)
      .doc(challengeId)
      .get();
  }

  public getCompanyPointsSources(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.PointsSourcesCollection)
      .get();
  }

  public getCompanyPointsSource(
    companyId: string,
    pointsSourceId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.PointsSourcesCollection)
      .doc(pointsSourceId)
      .get();
  }

  public getCompanyTeamChallenges(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .get();
  }

  public getCompanyCitiesChallenges(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.CitiesChallengesCollection)
      .get();
  }

  public createCompanyCitiesChallenge(
    companyId: string,
    challenge: CitiesChallenge
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.CitiesChallengesCollection)
      .doc(challenge.id)
      .set(challenge.toObject(), {
        merge: true,
      });
  }

  public getCompanyCitiesChallenge(
    companyId: string,
    challengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.CitiesChallengesCollection)
      .doc(challengeId)
      .get();
  }

  public getStandardCitiesChallenge(
    challengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CitiesChallengesCollection)
      .doc(challengeId)
      .get();
  }

  public getReportInfoTeamChallenges(
    companyId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.ReportsCollection)
      .doc('teamChallenges')
      .get();
  }

  public getReportInfoAroundTheWorld(
    companyId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.ReportsCollection)
      .doc('aroundTheWorld')
      .get();
  }

  public getReports(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.ReportsCollection)
      .get();
  }

  public getOnePagerReport(
    companyId: string,
    challengeId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.ReportsCollection)
      .where(
        FirebaseConstants.ChallengeIdProp,
        FirebaseConstants.EqualOperation,
        challengeId
      )
      .where(
        FirebaseConstants.TypeProp,
        FirebaseConstants.EqualOperation,
        ReportTypes.CompanyOnePager
      )
      .limit(1)
      .get();
  }

  public getStatisticsArray(
    companyId: string,
    statisticsType: string,
    statisticsBasis: string,
    periodArray: Array<string>
  ): Promise<
    Array<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>
  > {
    const promisesArray = periodArray.map((period) =>
      this.getStatistics(companyId, statisticsType, statisticsBasis, period)
    );
    return Promise.all(promisesArray);
  }

  public getStatistics(
    companyId: string,
    statisticsType: string,
    statisticsBasis: string,
    statisticsPeriod: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.StatisticsCollection)
      .doc(statisticsType)
      .collection(statisticsBasis)
      .doc(statisticsPeriod)
      .get();
  }

  public subscribeToStatistics(
    companyId: string,
    statisticsType: string,
    statisticsBasis: string,
    statisticsPeriod: string,
    observer: IObserverDocument
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.StatisticsCollection)
      .doc(statisticsType)
      .collection(statisticsBasis)
      .doc(statisticsPeriod)
      .onSnapshot(observer);
  }

  public subscribeToStatisticsCollection(
    companyId: string,
    statisticsType: string,
    statisticsBasis: string,
    observer: IObserverQuery
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.StatisticsCollection)
      .doc(statisticsType)
      .collection(statisticsBasis)
      .onSnapshot(observer);
  }

  public getTrainings(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TrainingsCollection)
      .get();
  }

  public getOldTrainings(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection('trainings')
      .get();
  }

  public updateCompany(companyId: string, update: any): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .set(update, { merge: true });
  }

  public createCompany(companyId: string, company: Company): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .set(company.toObject());
  }

  public createLocations(
    companyId: string,
    locations: Array<Location>
  ): Promise<void> {
    const locationsRef = this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LocationsCollection);
    const batch = this.db.batch();
    locations.forEach((location) => {
      batch.set(locationsRef.doc(location.id), location.toObject());
    });
    return batch.commit();
  }

  public async editLocation(
    companyId: string,
    oldLocation: Location,
    newLocation: Location
  ): Promise<void> {
    const usersQuery = await this.db
      .collection(FirebaseConstants.UsersCollection)
      .where(
        FirebaseConstants.CompanyIdProp,
        FirebaseConstants.EqualOperation,
        companyId
      )
      .where(
        FirebaseConstants.LocationIdsProp,
        FirebaseConstants.ArrayContainsOperation,
        oldLocation.name
      )
      .get();

    for (const userDoc of usersQuery.docs) {
      await userDoc.ref.update({
        locationIds: firebase.firestore.FieldValue.arrayUnion(newLocation.name),
      });
      await userDoc.ref.update({
        locationIds: firebase.firestore.FieldValue.arrayRemove(
          oldLocation.name
        ),
      });
    }
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LocationsCollection)
      .doc(oldLocation.id)
      .update({
        name: newLocation.name,
      });
  }

  public async deleteLocation(
    companyId: string,
    location: Location
  ): Promise<void> {
    const usersQuery = await this.db
      .collection(FirebaseConstants.UsersCollection)
      .where(
        FirebaseConstants.CompanyIdProp,
        FirebaseConstants.EqualOperation,
        companyId
      )
      .where(
        FirebaseConstants.LocationIdsProp,
        FirebaseConstants.ArrayContainsOperation,
        location.name
      )
      .get();

    for (const userDoc of usersQuery.docs) {
      await userDoc.ref.update({
        locationIds: firebase.firestore.FieldValue.arrayRemove(location.name),
      });
    }
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LocationsCollection)
      .doc(location.id)
      .delete();
  }

  public getDefaultPersonalChallenges(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.PersonalChallengesCollection)
      .get();
  }

  public createDefaultPersonalChallenge(
    challengeSchema: PersonalChallengeSchema
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.PersonalChallengesCollection)
      .doc(challengeSchema.id)
      .set(challengeSchema.toObject());
  }

  public deleteDefaultPersonalChallenge(challengeId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.PersonalChallengesCollection)
      .doc(challengeId)
      .delete();
  }
  public getDefaultTeamChallenges(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.DefaultTeamChallengesCollection)
      .get();
  }

  public createDefaultTeamChallenge(
    challenge: TeamChallengeSchema
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.DefaultTeamChallengesCollection)
      .doc(challenge.id)
      .set(challenge.toObject());
  }

  public deleteDefaultTeamChallenge(challengeId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.DefaultTeamChallengesCollection)
      .doc(challengeId)
      .delete();
  }

  public createPersonalChallenge(
    companyId: string,
    challengeSchema: ChallengeSchema,
    challengeId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.AvailableChallengesCollection)
      .doc(challengeId)
      .set(challengeSchema.toObject());
  }

  public updatePersonalChallenge(
    companyId: string,
    challengeSchema: ChallengeSchema,
    challengeId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.AvailableChallengesCollection)
      .doc(challengeId)
      .update(challengeSchema.toObject());
  }

  public createPointsSourceInCompany(
    companyId: string,
    pointsSource: PointsSource
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.PointsSourcesCollection)
      .doc(pointsSource.id)
      .set(pointsSource.toObject());
  }

  public updateCompanyPointsSource(
    companyId: string,
    pointsSource: PointsSource,
    pointsSourceId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.PointsSourcesCollection)
      .doc(pointsSourceId)
      .update(pointsSource.toObject());
  }

  public createTeamChallenge(
    companyId: string,
    teamChallenge: TeamChallenge,
    challengeId: string
  ): Promise<void> {
    const batch = this.db.batch();

    const companyRef = this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId);

    for (const teamId of teamChallenge.teamIds) {
      const teamRef = companyRef
        .collection(FirebaseConstants.TeamsCollection)
        .doc(teamId);
      batch.update(teamRef, {
        currentTeamChallengeId: teamChallenge.id,
      });
    }

    batch.update(companyRef, {
      nextTeamChallenges: firebase.firestore.FieldValue.arrayUnion(challengeId),
    });

    batch.set(
      companyRef.collection(FirebaseConstants.LigasCollection).doc(challengeId),
      teamChallenge.toObject()
    );

    return batch.commit();
  }

  public createCompanyEvent(
    companyId: string,
    eventId: string,
    weekendEvent: Event
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.EventsCollection)
      .doc(eventId)
      .set(weekendEvent.toObject(), { merge: true });
  }

  public updateTeamChallenge(
    companyId: string,
    teamChallenge: TeamChallenge,
    challengeId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(challengeId)
      .update(teamChallenge.toObject());
  }

  // FIREBASE CLOUD FUNCTIONS TRIGGERS

  public sendSupportEmail(
    email: string,
    name: string,
    message: string,
    phone: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    const source = FirebaseConstants.WebSource;

    const sendSupportEmail = firebase
      .app()
      .functions(FirebaseConstants.EuropeRegion)
      .httpsCallable(FirebaseConstants.SendSupportEmailFunction);

    return sendSupportEmail({
      email,
      name,
      message,
      phone,
      source,
    });
  }

  public sendEmail(emailData: FormData): Promise<object> {
    return firstValueFrom(
      this.http.post(FirebaseConstants.SendEmailWithAttachments, emailData)
    );
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      oldPassword
    );
    await firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credential)
      .then((response) => {
        console.log('reauthenticateWithCredential - response: ', response);
        return firebase.auth().currentUser.updatePassword(newPassword);
      })
      .catch((error) => {
        // TODO: Handle errors
        console.log('reauthenticateWithCredential - error: ', error);
        throw error;
      });
  }

  public async changeEmail(
    user: User,
    newEmail: string,
    oldPassword: string
  ): Promise<void> {
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      oldPassword
    );
    firebase.auth().languageCode = user.settings.lang;
    await firebase
      .auth()
      .currentUser.reauthenticateWithCredential(credential)
      .then((response) => {
        console.log('reauthenticateWithCredential - response: ', response);
        return firebase
          .auth()
          .currentUser.updateEmail(newEmail)
          .then(() =>
            this.sendVerificationEmail(
              newEmail,
              user.displayName,
              user.settings.lang
            )
          );
      })
      .catch((error) => {
        // TODO: Handle errors
        console.log('reauthenticateWithCredential - error: ', error);
        throw error;
      });
  }

  public resetPassword(email: string, lang: string): Promise<void> {
    firebase.auth().languageCode = lang;
    return firebase.auth().sendPasswordResetEmail(email);
  }

  public isFirebaseSessionActive(): Promise<boolean> {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(() => {
        const isLogged = firebase.auth().currentUser !== null;
        return resolve(isLogged);
      });
    });
  }

  // EXPORT FUNCTIONS

  public getUserExportData(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .where(
        FirebaseConstants.CompanyIdProp,
        FirebaseConstants.EqualOperation,
        companyId
      )
      .get();
  }

  public getLevelExportData(
    userId: string,
    startDate: number
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .doc(userId)
      .collection(FirebaseConstants.LevelsCollection)
      .where(
        FirebaseConstants.StartDateProp,
        FirebaseConstants.GreaterOrEqualOperation,
        startDate
      )
      .get();
  }

  public getTrainingExportData(
    companyId: string,
    day: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TrainingsCollection)
      .doc(day)
      .get();
  }

  // TEAM CHALLENGE FUNCTIONS

  public getTeamChallengeDailyPerformance(
    companyId: string,
    teamId: string,
    day: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .collection(FirebaseConstants.DailyPerformanceCollection)
      .doc(day)
      .get();
  }

  public subscribeToTeamChallengeDailyPerformance(
    companyId: string,
    teamId: string,
    day: string,
    observer: IObserverDocument
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .collection(FirebaseConstants.DailyPerformanceCollection)
      .doc(day)
      .onSnapshot(observer);
  }

  public getTeamChallengeDailyRanking(
    companyId: string,
    teamChallengeId: string,
    day: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.WeeklyRankingCollection)
      .doc(day)
      .get();
  }

  public getTeamChallengeWeeklyRanking(
    companyId: string,
    teamChallengeId: string,
    day: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.WeeklyRankingCollection)
      .where(
        FirebaseConstants.WeekdaysProp,
        FirebaseConstants.ArrayContainsOperation,
        day
      )
      .limit(1)
      .get();
  }

  public getTeamChallenge(
    companyId: string,
    teamChallengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(teamChallengeId)
      .get();
  }

  public subscribeToTeamChallengeWeeks(
    companyId: string,
    teamChallengeId: string,
    observer: IObserverQuery
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.WeeklyRankingCollection)
      .onSnapshot(observer);
  }

  public getTeamChallengeWeeks(
    companyId: string,
    teamChallengeId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.WeeklyRankingCollection)
      .where(
        FirebaseConstants.WeekdaysProp,
        FirebaseConstants.NotEqualOperation,
        false
      )
      .get();
  }

  public getTeamChallengeFinalStandings(
    companyId: string,
    teamChallengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.WeeklyRankingCollection)
      .doc(FirebaseConstants.FinalStandingsDocument)
      .get();
  }

  // Team functions
  public getTeam(
    companyId: string,
    teamId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .get();
  }

  public subscribeToTeam(
    companyId: string,
    teamId: string,
    observer: IObserverDocument
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .onSnapshot(observer);
  }

  public getTeams(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .get();
  }

  public getTeamsByTeamChallenge(
    companyId: string,
    teamChallengeId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .where(
        FirebaseConstants.CurrentTeamChallengeIdProp,
        FirebaseConstants.EqualOperation,
        teamChallengeId
      )
      .get();
  }

  public getTeamsByLocation(
    companyId: string,
    locationId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .where(FirebaseConstants.LocationProp, FirebaseConstants.InOperation, [
        locationId,
        '',
      ])
      .get();
  }

  public subscribeToTeams(
    companyId: string,
    observer: IObserverQuery
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .onSnapshot(observer);
  }

  public createCompanyTeam(
    companyId: string,
    teamId: string,
    team: Team
  ): Promise<void> {
    const createTeamBatch = this.db.batch();
    createTeamBatch.set(
      this.db
        .collection(FirebaseConstants.CompaniesCollection)
        .doc(companyId)
        .collection(FirebaseConstants.TeamsCollection)
        .doc(teamId),
      team.toObject()
    );

    createTeamBatch.update(
      this.db.collection(FirebaseConstants.CompaniesCollection).doc(companyId),
      {
        teamIds: firebase.firestore.FieldValue.arrayUnion(teamId),
      }
    );

    return createTeamBatch.commit();
  }

  public updateTeam(
    companyId: string,
    teamId: string,
    team: Team
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .update(team.toObject());
  }

  public removeUserFromTeam(
    companyId: string,
    teamId: string,
    userId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .update({
        members: firebase.firestore.FieldValue.arrayRemove(userId),
      });
  }

  public removeUserFromTeamChallenge(
    companyId: string,
    teamChallengeId: string,
    userId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LigasCollection)
      .doc(teamChallengeId)
      .update({
        userIds: firebase.firestore.FieldValue.arrayRemove(userId),
      });
  }

  public deleteTeam(companyId: string, teamId: string): Promise<void> {
    const deleteTeamBatch = this.db.batch();
    deleteTeamBatch.delete(
      this.db
        .collection(FirebaseConstants.CompaniesCollection)
        .doc(companyId)
        .collection(FirebaseConstants.TeamsCollection)
        .doc(teamId)
    );

    deleteTeamBatch.update(
      this.db.collection(FirebaseConstants.CompaniesCollection).doc(companyId),
      {
        teamIds: firebase.firestore.FieldValue.arrayRemove(teamId),
      }
    );

    return deleteTeamBatch.commit();
  }

  public uploadImage(file: File, path: string): firebase.storage.UploadTask {
    return firebase.storage().ref(path).put(file);
  }

  // Method that uploads data to a specific path on the storage and returns its download url.
  public uploadData(
    data: File | Blob | Uint8Array | ArrayBuffer,
    path: string
  ): Promise<string> {
    return firebase
      .storage()
      .ref(path)
      .put(data)
      .then(async (snapshot) => snapshot.ref.getDownloadURL())
      .catch((error) => Promise.reject(error));
  }

  public uploadDataURL(data: string, path: string): Promise<string> {
    return firebase
      .storage()
      .ref(path)
      .putString(data as string, 'data_url')
      .then(async (snapshot) => snapshot.ref.getDownloadURL())
      .catch((error) => Promise.reject(error));
  }

  public deleteImage(path: string): Promise<void> {
    return firebase.storage().ref(path).delete();
  }

  public createUpdateCity(city: City): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .doc(city.id)
      .set(city.toObject(), {
        merge: true,
      });
  }

  public deleteCity(cityId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .doc(cityId)
      .delete();
  }

  public subscribeToCities(observer: IObserverQuery): () => void {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .onSnapshot(observer);
  }

  public getCities(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.CitiesCollection).get();
  }

  public getCity(
    cityId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .doc(cityId)
      .get();
  }

  public getTeamUsers(
    teamId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .where('teamId', '==', teamId)
      .get();
  }

  public getTeamUsersByCompany(
    companyId: string,
    teamId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.UsersCollection)
      .where('companyId', '==', companyId)
      .where('teamId', '==', teamId)
      .get();
  }

  public sendVerificationEmail(
    email: string,
    name: string,
    lang: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    const sendVerificationEmail = firebase
      .app()
      .functions(FirebaseConstants.EuropeRegion)
      .httpsCallable(FirebaseConstants.SendVerificationEmail);

    return sendVerificationEmail({
      email,
      lang,
      name,
    });
  }

  public getCompanyLocations(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LocationsCollection)
      .get();
  }

  public uploadReport(
    file: Blob,
    name: string,
    companyId: string
  ): firebase.storage.UploadTask {
    return firebase
      .storage()
      .ref('companies/' + companyId + '/reports/' + name)
      .put(file);
  }

  public getDownloadURL(filePath: string): Promise<string> {
    return firebase.storage().ref(filePath).getDownloadURL();
  }

  public uploadFile(
    file: ArrayBuffer,
    path: string
  ): firebase.storage.UploadTask {
    return firebase.storage().ref(path).put(file);
  }

  public addNewReport(
    report: Report,
    reportId: string,
    companyId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.ReportsCollection)
      .doc(reportId)
      .set(report.toObject());
  }

  public async fileExist(path: string): Promise<boolean> {
    try {
      await firebase.storage().ref(path).getDownloadURL();
      return true;
    } catch (error) {
      return false;
    }
  }

  public updateObject(
    path: string,
    docId: string,
    update: Partial<object>
  ): Promise<void> {
    return this.db.collection(path).doc(docId).update(update);
  }

  public sendAdminNotification(
    notification: Partial<Notification>,
    senderCompanyId: string
  ): Promise<Array<firebase.functions.HttpsCallableResult>> {
    notification.sender = firebase.auth().currentUser.uid;

    const sendAdminNotification = firebase
      .app()
      .functions(FirebaseConstants.EuropeRegion)
      .httpsCallable(FirebaseConstants.SendAdminNotification);

    const notificationObject = notification.toObject();
    const destinationCompaniesArray = [
      ...notificationObject.recipientCompanyIds,
    ];
    const callableResults = [];
    let arrayIndex = 0;

    do {
      notificationObject.recipientCompanyIds = destinationCompaniesArray.slice(
        arrayIndex,
        arrayIndex + Number(FirebaseConstants.InOperationLimit)
      );
      callableResults.push(
        sendAdminNotification({
          companyId: senderCompanyId,
          notification: notificationObject,
        })
      );
      arrayIndex += Number(FirebaseConstants.InOperationLimit);
    } while (arrayIndex < destinationCompaniesArray.length);

    return Promise.all(callableResults);
  }

  public sendTeamLeaderNotification(
    companyId: string,
    teamIds: Array<string>,
    notification: Partial<Notification>
  ): Promise<firebase.functions.HttpsCallableResult> {
    const sendTeamLeaderNotification = firebase
      .app()
      .functions(FirebaseConstants.EuropeRegion)
      .httpsCallable(FirebaseConstants.SendTeamLeaderNotification);

    return sendTeamLeaderNotification({
      companyId,
      teamIds,
      notification: notification.toObject(),
    });
  }

  public changeTeamMember(
    userEmail: string,
    newTeamId: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    const changeTeamMember = firebase
      .app()
      .functions(FirebaseConstants.EuropeRegion)
      .httpsCallable(FirebaseConstants.ChangeTeamMember);

    return changeTeamMember({
      userEmail,
      newTeamId,
    });
  }

  public getCompanyEvents(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.EventsCollection)
      .get();
  }

  public getCompanyEvent(
    companyId: string,
    eventId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.EventsCollection)
      .doc(eventId)
      .get();
  }

  public cleanProjectData(
    companyId: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    const cleanProjectClientData = firebase
      .app()
      .functions(FirebaseConstants.EuropeRegion)
      .httpsCallable(FirebaseConstants.CleanProjectClientData);

    return cleanProjectClientData({ companyId });
  }

  public async createDynamicLink(longUrl: string): Promise<string> {
    const dynamicLinkPost = this.http.post(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' +
        environment.firebaseConfig.apiKey,
      {
        dynamicLinkInfo: {
          domainUriPrefix: environment.firebaseConfig.dynamicLinkDomain,
          link: longUrl,
        },
        suffix: {
          option: 'UNGUESSABLE',
        },
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    const shortLink = await firstValueFrom(dynamicLinkPost);

    // tslint:disable-next-line:no-string-literal
    return shortLink['shortLink'];
  }

  public getDuelChallenge(
    companyId: string,
    duelId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.DuelChallengesCollection)
      .doc(duelId)
      .get();
  }

  // New city info model
  public createUpdateCityInfo(
    cityId: string,
    cityInfo: CityInfo
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .doc(cityId)
      .collection(FirebaseConstants.CityInfosCollection)
      .doc(cityInfo.id)
      .set(cityInfo.toObject(), { merge: true });
  }

  public getCityInfoByType(
    cityId: string,
    cityInfoType: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .doc(cityId)
      .collection(FirebaseConstants.CityInfosCollection)
      .where(
        FirebaseConstants.CityInfoTypeProp,
        FirebaseConstants.EqualOperation,
        cityInfoType
      )
      .limit(1)
      .get();
  }

  public removeCityInfo(cityId: string, cityInfo: CityInfo): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .doc(cityId)
      .collection(FirebaseConstants.CityInfosCollection)
      .doc(cityInfo.id)
      .delete();
  }

  public subscribeToCityInfos(
    cityId: string,
    observer: IObserverQuery
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CitiesCollection)
      .doc(cityId)
      .collection(FirebaseConstants.CityInfosCollection)
      .onSnapshot(observer);
  }

  public getNextCitiesChallenge(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    const todayISO = Helpers.createISODateTime();
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.CitiesChallengesCollection)
      .where(
        FirebaseConstants.StartDateProp,
        FirebaseConstants.GreaterOperation,
        todayISO
      )
      .orderBy(FirebaseConstants.StartDateProp, FirebaseConstants.AscOrder)
      .limit(1)
      .get();
  }

  public getCitiesChallenge(
    companyId: string,
    challengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.CitiesChallengesCollection)
      .doc(challengeId)
      .get();
  }

  public getCitiesChallengeDailyPerformances(
    companyId: string,
    citiesChallengeId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.CitiesChallengesCollection)
      .doc(citiesChallengeId)
      .collection(FirebaseConstants.DailyPerformanceCollection)
      .get();
  }

  public sendProjectPlan(
    challenge: TeamChallenge | CitiesChallenge | TeamChallengeV2,
    company: Company
  ): Promise<HttpsCallableResult> {
    const functions = getFunctions(
      firebase.app(),
      FirebaseConstants.EuropeRegion
    );
    const sendProjectPlan = httpsCallable(
      functions,
      FirebaseConstants.SendProjectPlanFunction
    );

    return sendProjectPlan({
      challenge,
      company: company.toObject(),
    });
  }

  // New team challenge functions
  public createTeamChallengeV2(
    companyId: string,
    teamChallenge: TeamChallengeV2,
    teams: Array<TeamV2>
  ): Promise<void> {
    const batch = this.db.batch();

    const companyRef = this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId);

    const teamChallengeRef = companyRef
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallenge.id);

    batch.set(teamChallengeRef, teamChallenge.toObject());

    for (const team of teams) {
      if (!team.id) {
        team.id = teamChallengeRef
          .collection(FirebaseConstants.TeamsCollection)
          .doc().id;
      }

      const teamRef = teamChallengeRef
        .collection(FirebaseConstants.TeamsCollection)
        .doc(team.id);
      batch.set(teamRef, team.toObject());
    }
    return batch.commit();
  }

  public getTeamChallengeV2(
    companyId: string,
    teamChallengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .get();
  }

  public subscribeToTeamChallengeV2(
    companyId: string,
    teamChallengeId: string,
    observer: IObserverDocument
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .onSnapshot(observer);
  }

  public getTeamChallengesV2(
    companyId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .orderBy(FirebaseConstants.StartDateProp, FirebaseConstants.DescOrder)
      .get();
  }

  public getTeamsFromTeamChallengeV2(
    companyId: string,
    teamChallengeId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.TeamsCollection)
      .get();
  }

  public subscribeToTeamsFromTeamChallengeV2(
    companyId: string,
    teamChallengeId: string,
    observer: IObserverQuery
  ): () => void {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.TeamsCollection)
      .onSnapshot(observer);
  }

  public updateTeamChallengeV2(
    companyId: string,
    teamChallenge: Partial<TeamChallengeV2>
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallenge.id)
      .set(teamChallenge.toObject(), { merge: true });
  }

  public updateTeamV2(
    companyId: string,
    teamChallengeId: string,
    team: Partial<TeamV2>
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(team.id)
      .set(team.toObject(), { merge: true });
  }

  public removeTeamV2(
    companyId: string,
    teamChallengeId: string,
    teamId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.TeamsCollection)
      .doc(teamId)
      .delete();
  }

  public removeTeamChallengeV2(
    companyId: string,
    teamChallengeId: string
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .delete();
  }

  public getTeamChallengeV2WeeklyHistories(
    companyId: string,
    teamChallengeId: string
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.WeeklyHistoriesCollection)
      .get();
  }

  public getTeamChallengeV2WeeklyHistory(
    companyId: string,
    teamChallengeId: string,
    weeklyRankingId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.TeamChallengesCollection)
      .doc(teamChallengeId)
      .collection(FirebaseConstants.WeeklyHistoriesCollection)
      .doc(weeklyRankingId)
      .get();
  }

  public getLocationByHierarchyLevel(
    companyId: string,
    hierarchyLevel: number
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.CompaniesCollection)
      .doc(companyId)
      .collection(FirebaseConstants.LocationsCollection)
      .where(
        FirebaseConstants.HierarchyLevelProp,
        FirebaseConstants.EqualOperation,
        hierarchyLevel
      )
      .get();
  }

  public getAvatars(): Promise<Array<string>> {
    const avatarsFolderRef = firebase
      .storage()
      .ref(FirebaseConstants.AvatarsFolder);
    return avatarsFolderRef.listAll().then((resultList) => {
      const avatarUrlPromises = resultList.items.map(
        async (item) => await item.getDownloadURL()
      );
      return Promise.all([...avatarUrlPromises]);
    });
  }

  public getGroupRunChallenges(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return this.db.collection(FirebaseConstants.GroupRunChallenges).get();
  }

  public getGroupRunChallenge(
    groupRunChallengeId: string
  ): Promise<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  > {
    return this.db
      .collection(FirebaseConstants.GroupRunChallenges)
      .doc(groupRunChallengeId)
      .get();
  }

  public updateGroupRunChallenge(
    groupRunChallenge: GroupRunChallenge
  ): Promise<void> {
    return this.db
      .collection(FirebaseConstants.GroupRunChallenges)
      .doc(groupRunChallenge.id)
      .set(groupRunChallenge.toObject(), { merge: true });
  }

  public deleteGroupRunChallenge(groupRunChallengeId: string): Promise<void> {
    return this.db
      .collection(FirebaseConstants.GroupRunChallenges)
      .doc(groupRunChallengeId)
      .delete();
  }
}
