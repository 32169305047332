import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { Company } from 'src/app/models/company.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-cities-challenge-table',
  templateUrl: './cities-challenge-table.component.html',
  styleUrls: ['./cities-challenge-table.component.scss'],
})
export class CitiesChallengeTableComponent implements OnChanges, AfterViewInit {
  @Input() challenges: Array<CitiesChallenge>;
  @Input() company: Company;
  @ViewChild(MatSort) sort: MatSort;

  public citiesChallengesDataSource: MatTableDataSource<CitiesChallenge> = new MatTableDataSource();

  public citiesChallengeColumns: Array<string> = [
    'name',
    'startDate',
    'endDate',
    'projectPlan',
    'edit',
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.challenges) {
      this.refreshDataSource();
    }
  }

  ngAfterViewInit(): void {
    this.citiesChallengesDataSource.sort = this.sort;
  }

  private refreshDataSource(): void {
    this.citiesChallengesDataSource = new MatTableDataSource(this.challenges);
  }

  public goToCitiesChallenge(routeId: string): void {
    this.router.navigate(['edit-cities-challenge', routeId], {
      relativeTo: this.route,
    });
  }

  public sendProjectPlan(challenge: CitiesChallenge, button: MatButton): void {
    button.disabled = true;
    this.firebaseService
      .sendProjectPlan(challenge, this.company)
      .finally(() => {
        button.disabled = false;
      });
  }
}
