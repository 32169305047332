import {
  Challenge,
  IChallengeInitializer,
} from '../../challenge/challenge.abstract';
import { ITeamMember } from '../team-member/team-member.model';
import { ITeamPerformance } from '../interfaces/team-performance.interface';

export interface ITeamChallengeInitializer extends IChallengeInitializer {
  maxParticipantsPerTeam: number;
  membersWaitingForTeam: { [key: string]: ITeamMember };
  durationInWeeks: number;
  locationLevel: number;
  currentWeek: number;
  teamsTotalPerformance: { [teamId: string]: ITeamPerformance };
}

export class TeamChallenge extends Challenge {
  public maxParticipantsPerTeam: number = 0;
  public membersWaitingForTeam: Map<string, ITeamMember> = new Map();
  public durationInWeeks: number = 0;
  public locationLevel: number = 0;
  public currentWeek: number = 0;
  public teamsTotalPerformance: Map<string, ITeamPerformance> = new Map();

  constructor(initializer?: Partial<ITeamChallengeInitializer>) {
    super(initializer);
    if (initializer) {
      this.maxParticipantsPerTeam =
        initializer.maxParticipantsPerTeam ?? this.maxParticipantsPerTeam;
      this.durationInWeeks =
        initializer.durationInWeeks ?? this.durationInWeeks;
      this.locationLevel = initializer.locationLevel ?? this.locationLevel;
      this.currentWeek = initializer.currentWeek ?? this.currentWeek;

      if (initializer.teamsTotalPerformance) {
        this.teamsTotalPerformance = new Map(
          Object.entries(initializer.teamsTotalPerformance)
        );
      }

      if (
        initializer.membersWaitingForTeam &&
        Object.keys(initializer.membersWaitingForTeam).length > 0
      ) {
        this.membersWaitingForTeam = new Map(
          Object.entries(initializer.membersWaitingForTeam)
        );
      }
    }
  }

  public toObject(): ITeamChallengeInitializer {
    const {
      maxParticipantsPerTeam,
      membersWaitingForTeam,
      durationInWeeks,
      locationLevel,
      currentWeek,
      teamsTotalPerformance,
    } = this;

    return {
      ...super.toObject(),
      maxParticipantsPerTeam,
      membersWaitingForTeam: Object.fromEntries(membersWaitingForTeam),
      durationInWeeks,
      locationLevel,
      currentWeek,
      teamsTotalPerformance: Object.fromEntries(teamsTotalPerformance),
    };
  }

  /**
   * Sort an array of ITeamTotalPerformance by total points.
   * @return {Array<ITeamPerformance>} - Returns the sorted ranking.
   */
  public sort(): Array<ITeamPerformance> {
    const totalRanking = Array.from(this.teamsTotalPerformance.values());

    totalRanking.sort((teamTotalA, teamTotalB) => {
      if (teamTotalB.points !== teamTotalA.points) {
        return teamTotalB.points - teamTotalA.points;
      } else {
        if (teamTotalB.average !== teamTotalA.average) {
          return teamTotalB.average - teamTotalA.average;
        } else {
          return teamTotalB.syncs - teamTotalA.syncs;
        }
      }
    });

    return totalRanking;
  }
}
