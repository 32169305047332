import { Deserializable } from './deserializable.model';
import { WhiteLabelButton } from './white-label-button.model';
import { ReportTemplates } from './reports/report-templates.model';

export interface IWhiteLabelInitializer {
  id?: string;
  name?: string;
  color?: string;
  logo?: string;
  button?: WhiteLabelButton;
  reportTemplates?: ReportTemplates;
}
export class WhiteLabel implements Deserializable {
  public id: string = null;
  public name: string = null;
  public color: string = null;
  public logo: string = null;
  public button: WhiteLabelButton = null;
  public reportTemplates: ReportTemplates = null;

  constructor(initializer?: IWhiteLabelInitializer) {
    Object.assign(this, initializer);
    this.button =
      initializer && initializer.button
        ? new WhiteLabelButton(initializer.button)
        : null;
    this.reportTemplates =
      initializer && initializer.reportTemplates
        ? new ReportTemplates(initializer.reportTemplates)
        : null;
  }

  public deserialize(input: any): this {
    this.button = input.button ? new WhiteLabelButton(input.button) : null;
    this.reportTemplates = input.reportTemplates
      ? new ReportTemplates(input.reportTemplates)
      : null;
    Object.assign(this, input);
    return this;
  }

  public toObject(): any {
    const button = this.button ? this.button.toObject() : null;
    const reportTemplates = this.reportTemplates
      ? this.reportTemplates.toObject()
      : null;
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, {
      button,
      reportTemplates,
    });
  }
}
