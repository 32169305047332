import { IReportInitializer, Report } from './report.model';

export interface IReportChallengeInitializer extends IReportInitializer {
  challengeId: string;
}

export function isReportChallenge(object: Report): object is ReportChallenge {
  return 'challengeId' in object;
}

export class ReportChallenge extends Report {
  public challengeId = '';

  constructor(initializer?: IReportChallengeInitializer) {
    super(initializer);
    if (initializer) {
      Object.assign(this, initializer);
    }
  }
}
