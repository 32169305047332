import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from 'src/app/models/company.model';
import {
  IChallengeData,
  ProjectManagerCompanyService,
} from 'src/app/services/project-manager-company/project-manager-company.service';
import { CompanyTrainingsStatistics } from 'src/app/models/company-trainings-statistics.model';

@Component({
  selector: 'app-managed-companies-table',
  templateUrl: './managed-companies-table.component.html',
  styleUrls: ['./managed-companies-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ManagedCompaniesTableComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() companies: Array<Company>;
  @Input() companiesStatistics: Map<string, CompanyTrainingsStatistics>;
  @Input() managedCompanyChallengesData: Map<string, Array<IChallengeData>>;
  @ViewChild(MatSort) sort: MatSort;

  public companiesDataSource: MatTableDataSource<
    Company & CompanyTrainingsStatistics
  > = new MatTableDataSource();
  public columnsToDisplay: Array<string> = [
    'avatar',
    'name',
    'users',
    'availableLicenses',
    'totalSteps',
  ];
  public expandedCompany: Company | null = null;
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public showSpinner = false;

  constructor(private projectManagerService: ProjectManagerCompanyService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.companies &&
      this.companiesStatistics &&
      (changes.companies || changes.companiesStatistics)
    ) {
      const companiesWithSteps = this.companies.map((company) =>
        Object.assign(
          company,
          this.companiesStatistics.has(company.id)
            ? this.companiesStatistics.get(company.id)
            : new CompanyTrainingsStatistics({ totalSteps: 0 })
        )
      );
      this.companiesDataSource = new MatTableDataSource(companiesWithSteps);
    }
  }

  ngAfterViewInit(): void {
    this.companiesDataSource.sort = this.sort;
  }

  public selectedRow(company: Company): void {
    this.expandedCompany = this.expandedCompany === company ? null : company;
    this.getCompanyChallenges(company.id);
  }

  public getCompanyChallenges(companyId: string): void {
    this.showSpinner = true;
    this.projectManagerService
      .getManagedCompanyChallenges(companyId, this.managedCompanyChallengesData)
      .finally(() => (this.showSpinner = false));
  }
}
