<div class="spinner-container" *ngIf="showSpinnerReports">
  <mat-spinner></mat-spinner>
</div>
<div class="container" *ngIf="!showSpinnerReports">
  <div class="report-list-container">
    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let reports of reportsByChallenge | keyvalue">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ reports.key }}
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon class="folder-icons">folder</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item *ngFor="let report of reportsByChallenge[reports.key]">
            <mat-icon class="left-icons">assignment</mat-icon>
            {{ report.name }} - {{ report.dateGenerated | date: 'dd.MM.yyyy'}}
            <mat-icon (click)="downloadReport(report.src)" class="download-icons">
              get_app
            </mat-icon>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
