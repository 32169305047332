import { EARTH_RADIUS_KM } from 'src/app/constants/constants';
import { Helpers } from 'src/app/helpers/helpers';
import { CityMarkerPoint as OldCityMarkerPoint } from '../../city-marker-point.model';

export interface ICityInitializer {
  id?: string;
  name?: string;
  lat?: number;
  lng?: number;
  flag?: string;
  countryAlphaCode?: string;
  cityInfoTypes?: Array<string>;
  translations?: object;
}

export class City {
  public id: string = null;
  public name: string = null;
  public lat: number = null;
  public lng: number = null;
  public flag: string = null;
  public countryAlphaCode: string = null;
  public cityInfoTypes: Set<string> = new Set();
  public translations: Map<string, string> = new Map<string, string>();

  constructor(iCityInitializer: ICityInitializer | OldCityMarkerPoint = {}) {
    Object.assign(this, iCityInitializer);

    if (iCityInitializer.translations) {
      this.translations = new Map(
        Object.entries(iCityInitializer.translations)
      );
    }

    if ('cityInfoTypes' in iCityInitializer && iCityInitializer.cityInfoTypes) {
      this.cityInfoTypes = new Set(iCityInitializer.cityInfoTypes);
    }
  }

  public toObject(): ICityInitializer {
    const { translations, cityInfoTypes, ...otherProperties } = this;
    return {
      ...otherProperties,
      cityInfoTypes: [...cityInfoTypes],
      translations: Object.fromEntries(translations),
    };
  }

  public getDistanceToCity(cityTo: City): number {
    if (this.lat == null || this.lng == null) {
      throw new Error(
        'city from does not exist or is missing latitude or longitude'
      );
    }

    if (!cityTo || cityTo.lat == null || cityTo.lng == null) {
      throw new Error(
        'city to does not exist or is missing latitude or longitude'
      );
    }

    // Haversine Formula implementation
    const dLat = Helpers.toRad(this.lat - cityTo.lat);
    const dLon = Helpers.toRad(this.lng - cityTo.lng);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(Helpers.toRad(cityTo.lat)) *
        Math.cos(Helpers.toRad(this.lat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return EARTH_RADIUS_KM * c;
  }
}
