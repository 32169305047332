import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements AfterViewInit {
  @Input() locations = [];
  @Input() companyUsers = 0;

  public locationDataSource = new MatTableDataSource();

  @ViewChild('usersSort') usersSort = new MatSort();

  constructor() {}

  public displayedColumns: Array<string> = ['name', 'userAmount', 'totalSteps'];

  ngAfterViewInit(): void {
    this.locationDataSource = new MatTableDataSource(this.locations);
    this.locationDataSource.sort = this.usersSort;
  }
}
