import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// Models
import { PointsSource } from 'src/app/models/points-source.model';
import { PointsSourceType } from 'src/app/models/points-source-type.enum';
import { PointsSourceBasis } from 'src/app/models/points-source-basis.enum';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { AdminTabs } from '../admin-tabs.enum';

@Component({
  selector: 'app-edit-points-source',
  templateUrl: './edit-points-source.component.html',
  styleUrls: ['./edit-points-source.component.scss'],
})
export class EditPointsSourceComponent implements OnInit {
  public pointsSourceDataForm: UntypedFormGroup;
  private companyId: string;
  private pointsSourceId: string;
  private pointsSource: PointsSource;

  public showSpinner = false;

  public pointsSourceTypesKeys: Array<number> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'number') as number[];

  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as string[];

  public pointsSourceBasisKeys: Array<number> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'number') as number[];

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as string[];

  public UPDATE_POINTS_SOURCE_CREATED = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.getTranslations();
    this.pointsSourceDataForm = this.formBuilder.group({
      id: [{ value: '', disabled: true }, Validators.required],
      description: ['', Validators.required],
      basis: ['0', Validators.required],
      type: ['0', Validators.required],
      expectedPerformance: [
        1,
        Validators.compose([
          Validators.min(1),
          Validators.max(1000000),
          Validators.required,
        ]),
      ],
      pointsAwarded: [
        1,
        Validators.compose([
          Validators.min(1),
          Validators.max(5),
          Validators.required,
        ]),
      ],
    });
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.pointsSourceId = this.route.snapshot.paramMap.get('pointsSourceId');

    if (this.companyId) {
      this.firebaseService
        .getCompanyPointsSource(this.companyId, this.pointsSourceId)
        .then((pointsSourceSnapshot) => {
          this.pointsSource = new PointsSource().deserialize(
            pointsSourceSnapshot.data()
          );
          Object.entries(this.pointsSourceDataForm.controls).forEach(
            (control) => {
              if (control[0] !== 'companyId') {
                this.pointsSourceDataForm
                  .get(control[0])
                  .setValue(
                    control[0] !== 'type' && control[0] !== 'basis'
                      ? this.pointsSource[control[0]]
                      : this.pointsSource[control[0]].toString()
                  );
              }
            }
          );
        })
        .catch((error) => {
          console.log(
            'firebaseService.getCompanyPointsSource - error: ',
            error
          );
        });
    } else {
      this.firebaseService
        .getPointsSource(this.pointsSourceId)
        .then((pointsSourceSnapshot) => {
          this.pointsSource = new PointsSource().deserialize(
            pointsSourceSnapshot.data()
          );
          Object.entries(this.pointsSourceDataForm.controls).forEach(
            (control) => {
              if (control[0] !== 'companyId') {
                this.pointsSourceDataForm
                  .get(control[0])
                  .setValue(
                    control[0] !== 'type' && control[0] !== 'basis'
                      ? this.pointsSource[control[0]]
                      : this.pointsSource[control[0]].toString()
                  );
              }
            }
          );
        })
        .catch((error) => {
          console.log('firebaseService.getPointsSource - error: ', error);
        });
    }
  }

  private getTranslations(): void {
    this.translateService
      .stream(['ADD_POINTS_SOURCE_CREATED'])
      .subscribe((values) => {
        this.UPDATE_POINTS_SOURCE_CREATED = values.ADD_POINTS_SOURCE_CREATED;
      });
  }

  public async updatePointsSource(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (this.pointsSourceDataForm.valid) {
      const pointsSourceId = this.pointsSourceDataForm.controls.id.value;

      const pointsSource = new PointsSource().deserialize({
        type: Number(this.pointsSourceDataForm.controls.type.value),
        description: this.pointsSourceDataForm.controls.description.value,
        basis: Number(this.pointsSourceDataForm.controls.basis.value),
        expectedPerformance: this.pointsSourceDataForm.controls
          .expectedPerformance.value,
        pointsAwarded: this.pointsSourceDataForm.controls.pointsAwarded.value,
        isAchieved: false,
        id: this.pointsSourceDataForm.controls.id.value,
      });

      if (this.companyId) {
        await this.firebaseService.updateCompanyPointsSource(
          this.companyId,
          pointsSource,
          pointsSourceId
        );
      } else {
        await this.firebaseService.updatePointsSource(pointsSource);
      }

      this.showSpinner = false;
      this.snackBar.open(this.UPDATE_POINTS_SOURCE_CREATED, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
      this.router.navigate(['../..'], {
        relativeTo: this.route,
        state: { tab: AdminTabs.PointsSources },
      });
    }
  }

  public returnPage(): void {
    this.router.navigate(['../..'], {
      relativeTo: this.route,
      state: { tab: AdminTabs.PointsSources },
    });
  }
}
