import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'src/app/services/company/company.service';
import { UserService } from 'src/app/services/user/user.service';
import {
  EmailType,
  IEmailData,
  NewEmailFormComponent,
} from 'src/app/shared/components/new-email-form/new-email-form.component';
import { environment } from 'src/environments/environment';

export interface INoCurrentChallenge {
  image: string;
  title: string;
}
@Component({
  selector: 'app-no-current-challenge',
  templateUrl: './no-current-challenge.component.html',
  styleUrls: ['./no-current-challenge.component.scss'],
})
export class NoCurrentChallengeComponent {
  @Input() noCurrentChallenge: INoCurrentChallenge;

  constructor(
    private dialog: MatDialog,
    private companyService: CompanyService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  public contact(): void {
    const emailData: IEmailData = {
      subject: this.translateService.instant(
        'NO_CURRENT_CHALLENGE.EMAIL.SUBJECT'
      ),
      body: this.translateService.instant('NO_CURRENT_CHALLENGE.EMAIL.BODY', {
        companyName: this.companyService.company.name,
        companyCode: this.companyService.company.id,
      }),
      recipients: environment.salesEmails,
      attachments: [],
      type: EmailType.NoAttachments,
      replyTo: this.userService.user.email,
    };

    this.dialog.open(NewEmailFormComponent, {
      width: '800px',
      data: emailData,
      disableClose: true,
    });
  }
}
