import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { de, enUS } from 'date-fns/locale';
import { Helpers } from 'src/app/helpers/helpers';
import { IDuelist } from 'src/app/interfaces/duelist.interface';
import { IconNames } from 'src/app/models/assets-constants';
import { Company } from 'src/app/models/company.model';
import { DuelChallenge } from 'src/app/models/duel-challenge/duel-challenge.model';

import { DuelChallengeService } from 'src/app/services/duel-challenge/duel-challenge.service';
import { IBadgeCardInfo } from 'src/app/shared/widgets/badge-card/badge-card.component';
import { ICircleProgressCard } from 'src/app/shared/widgets/circle-progress-chart/circle-progress-chart.component';
import {
  IKpiItem,
  IKpiList,
} from 'src/app/shared/widgets/kpi-list/kpi-list.component';
import {
  IUserRanking,
  IUserRankingItem,
} from 'src/app/shared/widgets/user-ranking/user-ranking.component';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

const DATEPICKER_FORMAT = {
  parse: {
    dateInput: 'dd.MM.yyyy',
  },
  display: {
    dateInput: 'MMM yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd.MM.yyyy',
    monthYearA11yLabel: 'MMM yyyy',
  },
};

@Component({
  selector: 'app-duels',
  templateUrl: './duels.component.html',
  styleUrls: ['./duels.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMAT },
  ],
})
export class DuelsComponent implements OnInit {
  @ViewChild('duelDatePicker') duelDatePicker: MatDatepicker<Date>;
  @Input() company: Company = null;
  public DUEL_CHALLENGE_ICON = IconNames.SwordCrossedHistory;
  public duelChallengeDuration = 0;
  public dateFormControl: FormControl = new FormControl(new Date());
  public duelChallenge: DuelChallenge;
  public duelChallengeRanking: Array<IDuelist> = [];
  public winnerBadge: IBadgeCardInfo;
  public mostStepsBadge: IBadgeCardInfo;
  public kpiList: IKpiList;
  public circleProgressCard: ICircleProgressCard;
  public userRanking: IUserRanking;
  public today = new Date();
  public showSpinner = true;

  constructor(
    private duelChallengeService: DuelChallengeService,
    private subscriptionService: SubscriptionService,
    private dateAdapter: DateAdapter<Date>,
    private translateService: TranslateService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.setLocaleToDateAdapter();
    });
  }

  ngOnInit(): void {
    this.setLocaleToDateAdapter();
    if (!this.company) {
      this.subscriptionService.company.subscribe((company) => {
        if (company) {
          this.company = company;
          this.getDuelChallenge();
        }
      });
    } else {
      this.getDuelChallenge();
    }
  }

  public openDatePicker(): void {
    this.duelDatePicker.open(); // Programmatically open the datepicker
  }

  public setMonthAndYear(date: Date, datepicker: MatDatepicker<Date>): void {
    this.dateFormControl.setValue(date);
    this.getDuelChallenge();
    datepicker.close();
  }

  private getDuelChallenge(): void {
    const duelId = Helpers.getISOYearMonth(
      Helpers.createISODateTime(this.dateFormControl.value)
    );
    this.duelChallengeService
      .getDuelChallenge(this.company.id, duelId)
      .then((duelChallenge) => {
        this.duelChallenge = duelChallenge;
        this.duelChallengeDuration = this.duelChallenge
          ? this.duelChallenge.getDaysDuration()
          : 0;
        this.duelChallengeRanking = this.duelChallenge
          ? this.duelChallenge.sortRanking()
          : [];
        this.createBadgeCards();
        this.createKpiList();
        this.createCircleProgress();
        this.createUserRanking();
        this.showSpinner = false;
      });
  }

  private createBadgeCards(): void {
    let mostStepsDuelist: IDuelist;

    this.winnerBadge = {
      title: 'DUEL_BADGE_CARD.WINNER_OF_THE_MONTH',
      iconName: this.duelChallenge
        ? IconNames.FirstPlaceDuel
        : IconNames.DeactivatedPlacePodiumDuel,
      userNickname: this.duelChallenge
        ? this.duelChallengeRanking[0].nickname
        : null,
      message: this.duelChallenge ? null : 'DUEL_BADGE_CARD.NO_WINNER',
      userPerformance: this.duelChallenge
        ? this.duelChallengeRanking[0].duelsWon
        : 0,
    };

    if (this.duelChallenge) {
      mostStepsDuelist = this.duelChallengeService.getUserWithMostStepsInADuel(
        this.duelChallengeRanking
      );
    }

    this.mostStepsBadge = {
      title: 'DUEL_BADGE_CARD.TOP_STEPS_DUELIST',
      iconName: this.duelChallenge
        ? IconNames.MostStepsOfTheMonthDuel
        : IconNames.MostStepsOfTheMonthDeactivatedDuel,
      userNickname: this.duelChallenge ? mostStepsDuelist.nickname : null,
      message: this.duelChallenge ? null : 'DUEL_BADGE_CARD.NO_WINNER',
      userPerformance: this.duelChallenge ? mostStepsDuelist.steps : 0,
    };
  }

  private createKpiList(): void {
    const kpiContestant: IKpiItem = {
      name: 'KPI_LIST.DUEL.DUELISTS',
      value: this.duelChallenge ? this.duelChallengeRanking.length : 0,
    };
    const kpiActiveDuels: IKpiItem = {
      name: 'KPI_LIST.DUEL.ACTIVE_DUELS',
      value: this.duelChallenge
        ? Math.ceil(this.duelChallengeRanking.length / 2)
        : 0,
    };
    const kpiPerformanceAverage: IKpiItem = {
      name: 'KPI_LIST.DUEL.PERFORMANCE_AVERAGE',
      value: this.duelChallenge
        ? this.duelChallengeRanking.reduce(
            (totalPerformance, duelist) =>
              totalPerformance + duelist.totalPerformance,
            0
          ) /
          new Date().getDate() /
          this.duelChallengeRanking.length
        : 0,
    };
    this.kpiList = {
      title: 'KPI_LIST.DUEL.TITLE',
      list: [kpiContestant, kpiActiveDuels, kpiPerformanceAverage],
    };
  }

  private createCircleProgress(): void {
    this.circleProgressCard = {
      title: 'CIRCLE_PROGRESS_CARD.DUEL.TITLE',
      totalProgress: this.duelChallenge
        ? (this.duelChallengeRanking.length / this.company.users.length) * 100
        : 0,
      progressTitle: this.duelChallenge
        ? `${this.duelChallengeRanking.length} / ${this.company.users.length}`
        : null,
      progressSubtitle: this.duelChallenge
        ? null
        : 'CIRCLE_PROGRESS_CARD.DUEL.SUBTITLE',
    };
  }

  private createUserRanking(): void {
    this.userRanking = {
      title: 'USER_RANKING.DUEL.TITLE',
      badgeIcons: [
        IconNames.FirstPlaceDuel,
        IconNames.SecondPlaceDuel,
        IconNames.ThirdPlaceDuel,
      ],
      ranking: this.duelChallengeRanking
        ? this.duelChallengeRanking.map(
            (duelist) =>
              ({
                avatar: duelist.avatar,
                nickname: duelist.nickname,
                performance: duelist.duelsWon,
              } as IUserRankingItem)
          )
        : [],
      emptyRankingMessage: 'USER_RANKING.DUEL.EMPTY_RANKING_MESSAGE',
    };
  }

  private setLocaleToDateAdapter(): void {
    if (this.translateService.currentLang === 'en') {
      this.dateAdapter.setLocale(enUS);
    } else {
      this.dateAdapter.setLocale(de);
    }
  }
}
