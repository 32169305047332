<div class="container">
  <div class="top">
    <mat-icon (click)="goBack()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'EDIT_CITIES_CHALLENGE.TITLE.NEW_CITIES_CHALLENGE' | translate }}</h1>
  </div>

  <mat-horizontal-stepper labelPosition="bottom" #stepper linear="true" (selectionChange)="stepperChanged($event)">
    <mat-step completed="false">
      <ng-container>
        <app-challenge-type-selection [challengeTypes]="challengeTypes" (selectedType)="setSelectedType($event)"></app-challenge-type-selection>
      </ng-container>
    </mat-step>

    <mat-step completed="false">
      <ng-container *ngIf="stepper.selectedIndex > 0">
        <app-routes-table (newRouteSelected)="setSelectedRoute($event)" [routes]="defaultRoutes" [selectable]="true" [showCreateRouteButton]="citiesChallengeType === challengeTypes[2]"></app-routes-table>
      </ng-container>
    </mat-step>

    <mat-step completed="false">
      <ng-container *ngIf="stepper.selectedIndex > 1">
        <app-cities-challenge-form
          [citiesChallengeDataForm]="challengeDataForm"
          [editableChallenge]="true"
          [editableRoute]="citiesChallengeType !== challengeTypes[0]"
          [createRegions]="citiesChallengeType === challengeTypes[2]"
        ></app-cities-challenge-form>

        <button mat-raised-button [disabled]="!challengeDataForm.valid || challengeDataForm.controls.totalDuration.value < 1"
          (click)="disableDataFormForOverview()">
          {{ 'EDIT_CITIES_CHALLENGE.BUTTON.CREATE_CITIES_CHALLENGE' | translate }}
        </button>
      </ng-container>
    </mat-step>

    <mat-step completed="false">
      <ng-container *ngIf="stepper.selectedIndex > 2">
        <app-cities-challenge-overview [citiesChallengeDataForm]="challengeDataForm" [citiesChallengeType]="citiesChallengeType" [showSpinner]="showCreatingCitiesChallengeSpinner" (createClicked)="createCompanyCityChallenge()"></app-cities-challenge-overview>
      </ng-container>
    </mat-step>
  </mat-horizontal-stepper>
</div>
