import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { NavigationEnd, Router } from '@angular/router';

import { CompanyService } from 'src/app/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';

import { Company } from 'src/app/models/company.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user.model';
import { REGEX_EMAIL } from 'src/app/constants/constants';

@Component({
  selector: 'app-input-steps',
  templateUrl: './input-steps.component.html',
  styleUrls: ['./input-steps.component.scss'],
})
export class InputStepsComponent implements OnInit {
  public inputDataForm: UntypedFormGroup;
  public notCompanyMember = true;
  public showInputDataForm = true;

  public maxDate = new Date();

  public INPUT_STEPS_STEPS_ADDED = '';

  public emailCrtl = new UntypedFormControl('', []);

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd && e.url === '/input-data') {
        this.companyService.getUsers().then((users) => {
          this.showInputDataForm = false;
        });
      }
    });

    this.getTranslations();
    this.inputDataForm = this.formBuilder.group({
      date: ['', Validators.required],
      steps: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
    });
    this.inputDataForm.controls.email.valueChanges.subscribe((value) => {
      const userSelected = this.users.find((user) => user.email === value);
      if (userSelected) {
        this.notCompanyMember = false;
      } else {
        this.notCompanyMember = true;
      }
    });

    this.dateAdapter.setLocale('de');
  }
  get company(): Company {
    return this.companyService.company;
  }
  get users(): Array<User> {
    return this.companyService.users;
  }
  get user(): User {
    return this.userService.user;
  }

  ngOnInit(): void {}

  getTranslations(): void {
    this.translateService
      .stream(['INPUT_STEPS_STEPS_ADDED'])
      .subscribe((values) => {
        this.INPUT_STEPS_STEPS_ADDED = values.INPUT_STEPS_STEPS_ADDED;
      });
  }

  public updateSteps(): void {
    const userSelected = this.users.find(
      (user) => user.email === this.inputDataForm.value.email
    );
    if (userSelected) {
      const date = new Date(this.inputDataForm.value.date).getTime().toString();
      this.companyService.updateTraining(
        this.inputDataForm.value.email,
        date,
        this.inputDataForm.value.steps
      );
      this.snackBar.open(this.INPUT_STEPS_STEPS_ADDED, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
    }
  }
}
