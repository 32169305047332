import { Injectable } from '@angular/core';
import { PDFDocument, PDFFont, PDFForm, PDFImage, rgb } from 'pdf-lib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as fontkit from '@btielen/pdf-lib-fontkit';

// Models
import { DistanceChallenge } from 'src/app/models/distance-challenge.model';
import { Company } from 'src/app/models/company.model';
import { Report } from 'src/app/models/reports/report.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { Location } from 'src/app/models/location.model';
import { ReportChallenge } from 'src/app/models/reports/report-challenge.model';
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';
import { Training } from 'src/app/models/training.model';
import { CitiesChallengeDayPerformance } from 'src/app/models/cities-challenge/cities-day-challenge-performance/cities-challenge-day-performance.model';
import { Document } from 'src/app/models/challenge-reports/document/document.model';
import { Report as ChallengeReport } from 'src/app/models/challenge-reports/report/report.model';
import {
  CitiesChallengeReport,
  ICitiesChallengeReport,
} from 'src/app/models/challenge-reports/cities-challenge-report/cities-challenge-report.model';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamsService } from '../teams/teams.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';

// Constants
import {
  TEAM_CHALLENGE_REPORT_TEMPLATE_PATH,
  ONE_PAGER,
  TEAM_CHALLENGE_REPORT,
} from 'src/app/constants/constants';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { ReportTypes } from 'src/app/models/reports/report-types.enum';

export class BlobFile {
  title: string;
  blob: Blob;
  status: ReportStatus;
  fileURL?: SafeResourceUrl;
}

export enum ReportStatus {
  Pending,
  Uploading,
  Saved,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  public reportTranslations = null;

  constructor(
    private firebaseService: FirebaseService,
    private companyService: CompanyService,
    private teamsService: TeamsService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  public async generateTeamReport(
    company: Company,
    teamChallenge: TeamChallenge,
    weekRanking: any,
    documentTitle: string
  ): Promise<BlobFile> {
    // Calculate week steps average and create teamsArray
    let totalWeekStepsCount = 0;
    let totalWeekSyncsCount = 0;
    const teamsArray = [];
    console.log(weekRanking);
    Object.keys(weekRanking).forEach((key) => {
      if (
        weekRanking[key].totalSteps >= 0 &&
        weekRanking[key].totalSyncs >= 0
      ) {
        totalWeekStepsCount += weekRanking[key].totalSteps;
        totalWeekSyncsCount += weekRanking[key].totalSyncs;
        teamsArray.push(weekRanking[key]);
      }
    });

    const companyAverageStepCount = Helpers.formatAndRoundNumberByLanguage(
      totalWeekStepsCount / totalWeekSyncsCount,
      0,
      this.translateService.currentLang
    );

    // Sort teamsArray by points
    teamsArray.sort((teamA, teamB) => teamB.points - teamA.points);

    const dateValue: string = Helpers.formatDateByLanguage(
      new Date(),
      this.translateService.currentLang
    );
    const startDate = new Date(teamChallenge.startDate);
    const endDate = new Date(teamChallenge.endDate);
    const challengePeriod: string =
      Helpers.formatDateByLanguage(
        startDate,
        this.translateService.currentLang
      ) +
      ' - ' +
      Helpers.formatDateByLanguage(endDate, this.translateService.currentLang);
    const participantsNumber: string = company.users.length.toString();

    // Download report template
    const templateUrl = await this.firebaseService.getDownloadURL(
      TEAM_CHALLENGE_REPORT_TEMPLATE_PATH
    );

    const templatePdfBytes = await fetch(templateUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(templatePdfBytes);
    const form = pdfDoc.getForm();

    // Download and set images
    await this.downloadAndSetImageField(
      TEAM_CHALLENGE_REPORT.IMAGE_FIELDS.COMPANY_LOGO,
      company.avatar,
      form,
      pdfDoc
    );

    if (company.whiteLabel && company.id !== 'BBRAUN') {
      await this.downloadAndSetImageField(
        TEAM_CHALLENGE_REPORT.IMAGE_FIELDS.WHITE_LABEL_LOGO,
        company.whiteLabel.logo,
        form,
        pdfDoc
      );
    }

    // I would leave this part commented until the automation is working correctly.
    // const qrCode = document.getElementById('qrcode');
    // if (qrCode) {
    //   const qrcodeImageData = this.getQrcodeAsImage(qrCode.firstChild);
    //   await this.dowloadAndSetImageField(
    //     TEAM_CHALLENGE_REPORT.IMAGE_FIELDS.QR_CODE,
    //     qrcodeImageData,
    //     form,
    //     pdfDoc
    //   );
    // }

    // Embed fonts
    pdfDoc.registerFontkit(fontkit);
    const openSansExtraBoldItalic = await this.embedCustomFont(
      FirebaseConstants.OpenSansExtraBoldItalic,
      pdfDoc
    );
    const openSansExtraBold = await this.embedCustomFont(
      FirebaseConstants.OpenSansExtraBold,
      pdfDoc
    );

    // Set mandatory Text fields
    this.setTextField(
      TEAM_CHALLENGE_REPORT.TEXT_FIELDS.COMPANY_NAME,
      company.name,
      form,
      openSansExtraBoldItalic
    );
    this.setTextField(
      TEAM_CHALLENGE_REPORT.TEXT_FIELDS.COMPANY_ID,
      company.id,
      form,
      openSansExtraBoldItalic
    );
    this.setTextField(
      TEAM_CHALLENGE_REPORT.TEXT_FIELDS.CHALLENGE_NAME,
      teamChallenge.name,
      form,
      openSansExtraBoldItalic
    );
    this.setTextField(
      TEAM_CHALLENGE_REPORT.TEXT_FIELDS.REPORT_DATE,
      dateValue,
      form,
      openSansExtraBoldItalic
    );
    this.setTextField(
      TEAM_CHALLENGE_REPORT.TEXT_FIELDS.CHALLENGE_PERIOD,
      challengePeriod,
      form,
      openSansExtraBold
    );
    this.setTextField(
      TEAM_CHALLENGE_REPORT.TEXT_FIELDS.PARTICIPANT_NUMBER,
      participantsNumber,
      form,
      openSansExtraBold
    );
    this.setTextField(
      TEAM_CHALLENGE_REPORT.TEXT_FIELDS.COMPANY_AVERAGE_STEP_COUNT,
      companyAverageStepCount.toString(),
      form,
      openSansExtraBold
    );

    // Draw ranking
    const fontColor = rgb(94 / 255, 95 / 255, 103 / 255);
    const rankingPosition = 366;
    const teamNamePosition = 450;
    const pointsPosition = 520;
    const stepsCountPosition = 620;
    const participantsPosition = 725;
    const textSize = 8;
    const minPositionY = 112;
    const lineHeight = openSansExtraBold.heightAtSize(10);

    let [tablePage] = await pdfDoc.copyPages(pdfDoc, [0]);
    let page = pdfDoc.getPage(0);
    let positionY = 455;
    let rankingNumber = 1;

    for (const team of teamsArray) {
      const teamName: string = team.name;
      const teamPoints: string = Helpers.formatAndRoundNumberByLanguage(
        team.points,
        0,
        this.translateService.currentLang
      );

      const stepsCount: string = Helpers.formatAndRoundNumberByLanguage(
        Math.floor(team.totalSyncs > 0 ? team.totalSteps / team.totalSyncs : 0),
        0,
        this.translateService.currentLang
      );
      const participants: string = Helpers.formatAndRoundNumberByLanguage(
        team.teamSize,
        0,
        this.translateService.currentLang
      );

      if (positionY - lineHeight + 40 < minPositionY) {
        page = pdfDoc.addPage(tablePage);
        [tablePage] = await pdfDoc.copyPages(pdfDoc, [
          pdfDoc.getPageCount() - 1,
        ]);
        positionY = 455;
      }

      page.drawLine({
        start: { x: rankingPosition, y: positionY - 2 },
        end: { x: participantsPosition, y: positionY - 2 },
        thickness: 1,
        color: fontColor,
        opacity: 0.5,
        dashArray: [1],
        dashPhase: 100,
      });

      // Draw department name
      page.drawText(rankingNumber.toString() + '.', {
        x: rankingPosition,
        y: positionY,
        size: textSize,
        font: openSansExtraBold,
        color: fontColor,
      });

      let widthSize = openSansExtraBold.widthOfTextAtSize(teamName, 8) / 2;
      let teamAvatarFound = false;

      try {
        const teamAvatar: string = team.avatar;
        const lastSlashIndex = teamAvatar.lastIndexOf('/');
        const lastQuestionMarkIndex = teamAvatar.lastIndexOf('?');
        const avatarPath =
          teamAvatar
            .substring(lastSlashIndex, lastQuestionMarkIndex)
            .replace(/%2F/gm, '/') + '.png';
        // Download and draw avatar
        const avatarUrl = await this.firebaseService.getDownloadURL(avatarPath);
        const avatarImageBytes = await fetch(avatarUrl).then((res) =>
          res.arrayBuffer()
        );
        const avatarEmbed = await pdfDoc.embedPng(avatarImageBytes);

        page.drawRectangle({
          x: teamNamePosition - 15,
          y: positionY - 15,
          width: 30,
          height: 30,
          color: rgb(1, 1, 1),
          opacity: 1,
        });

        page.drawImage(avatarEmbed, {
          x: teamNamePosition - 15,
          y: positionY - 15,
          width: 30,
          height: 30,
        });

        teamAvatarFound = true;
      } catch (err) {
        console.log('error: ', err);
      }

      page.drawText(teamName, {
        x: teamNamePosition - widthSize,
        y: teamAvatarFound ? positionY - 25 : positionY,
        size: textSize,
        font: openSansExtraBold,
        color: fontColor,
      });

      widthSize = openSansExtraBold.widthOfTextAtSize(teamPoints, 8);
      page.drawText(teamPoints, {
        x: pointsPosition - widthSize,
        y: positionY,
        size: textSize,
        font: openSansExtraBold,
        color: fontColor,
      });

      widthSize = openSansExtraBold.widthOfTextAtSize(stepsCount, 8);
      page.drawText(stepsCount, {
        x: stepsCountPosition - widthSize,
        y: positionY,
        size: textSize,
        font: openSansExtraBold,
        color: fontColor,
      });

      widthSize = openSansExtraBold.widthOfTextAtSize(participants, 8);
      page.drawText(participants, {
        x: participantsPosition - widthSize,
        y: positionY,
        size: textSize,
        font: openSansExtraBold,
        color: fontColor,
      });

      positionY -= lineHeight + 40;
      rankingNumber += 1;
    }

    return this.generateBlobFile(pdfDoc, form, documentTitle);
  }

  public async generateTeamOnePagerReport(
    company: Company,
    documentTitle: string,
    challenge: TeamChallenge | DistanceChallenge,
    reportTranslations: any,
    templatePath: string
  ): Promise<Array<BlobFile>> {
    const blobFiles: Array<BlobFile> = [];
    const templateUrl = await this.firebaseService.getDownloadURL(templatePath);

    const templatePdfBytes = await fetch(templateUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(templatePdfBytes);
    const form = pdfDoc.getForm();

    const teamChallenge: TeamChallenge = new TeamChallenge(challenge);
    const companyTeams = await this.teamsService.getTeams(company.id);

    if (company.id !== 'BBRAUN') {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.COMPANY_LOGO,
        company.avatar,
        form,
        pdfDoc
      );
    }

    if (company.whiteLabel) {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.WHITE_LABEL_LOGO,
        company.whiteLabel.logo,
        form,
        pdfDoc
      );
    }

    // Embed fonts
    pdfDoc.registerFontkit(fontkit);
    const openSansExtraBoldItalic = await this.embedCustomFont(
      FirebaseConstants.OpenSansExtraBoldItalic,
      pdfDoc
    );
    const openSansRegular = await this.embedCustomFont(
      FirebaseConstants.OpenSansRegular,
      pdfDoc
    );
    const openSansBold = await this.embedCustomFont(
      FirebaseConstants.OpenSansBold,
      pdfDoc
    );

    // Complete text fields
    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME,
      challenge.name,
      form,
      openSansExtraBoldItalic
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_START_DATE,
      Helpers.formatDateByLanguage(
        new Date(challenge.startDate),
        this.translateService.currentLang
      ),
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME_TEXT,
      challenge.name,
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CODE_TYPE,
      reportTranslations.ONE_PAGER.COMPANY_CODE,
      form,
      openSansBold
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.COMPANY_ID,
      company.id,
      form,
      openSansBold
    );

    for (const teamId of teamChallenge.teamIds) {
      const team = companyTeams.find(
        (companyTeam) => companyTeam.id === teamId
      );
      const teamName = team ? team.name : teamId;
      this.setTextField(
        ONE_PAGER.TEXT_FIELDS.TEAM_NAME,
        reportTranslations.ONE_PAGER.TEAM_PREFIX + teamName,
        form,
        openSansExtraBoldItalic
      );

      this.setTextField(
        ONE_PAGER.TEXT_FIELDS.CODE_TYPE_TEAM,
        reportTranslations.ONE_PAGER.TEAM_CODE,
        form,
        openSansBold
      );

      this.setTextField(
        ONE_PAGER.TEXT_FIELDS.TEAM_ID,
        teamId,
        form,
        openSansBold
      );

      // save team report
      blobFiles.push(
        await this.generateBlobFile(pdfDoc, form, teamId + '-' + documentTitle)
      );
    }

    return blobFiles;
  }

  public async generateCompanyOnePagerReport(
    company: Company,
    documentTitle: string,
    challenge: TeamChallenge | DistanceChallenge,
    reportTranslations: any
  ): Promise<Array<BlobFile>> {
    const blobFiles: Array<BlobFile> = [];
    const templateUrl = await this.firebaseService.getDownloadURL(
      ONE_PAGER.TEMPLATE_PATH
    );

    const templatePdfBytes = await fetch(templateUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(templatePdfBytes);
    const form = pdfDoc.getForm();

    if (company.id !== 'BBRAUN') {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.COMPANY_LOGO,
        company.avatar,
        form,
        pdfDoc
      );
    }

    if (company.whiteLabel) {
      await this.downloadAndSetImageField(
        ONE_PAGER.IMAGE_FIELDS.WHITE_LABEL_LOGO,
        company.whiteLabel.logo,
        form,
        pdfDoc
      );
    }

    // Embed fonts
    pdfDoc.registerFontkit(fontkit);
    const openSansExtraBoldItalic = await this.embedCustomFont(
      FirebaseConstants.OpenSansExtraBoldItalic,
      pdfDoc
    );
    const openSansRegular = await this.embedCustomFont(
      FirebaseConstants.OpenSansRegular,
      pdfDoc
    );
    const openSansBold = await this.embedCustomFont(
      FirebaseConstants.OpenSansBold,
      pdfDoc
    );

    // Complete text fields
    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME,
      challenge.name,
      form,
      openSansExtraBoldItalic
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_START_DATE,
      Helpers.formatDateByLanguage(
        new Date(challenge.startDate),
        this.translateService.currentLang
      ),
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CHALLENGE_NAME_TEXT,
      challenge.name,
      form,
      openSansRegular
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.CODE_TYPE,
      reportTranslations.ONE_PAGER.COMPANY_CODE,
      form,
      openSansBold
    );

    this.setTextField(
      ONE_PAGER.TEXT_FIELDS.COMPANY_ID,
      company.id,
      form,
      openSansBold
    );

    // save company report
    blobFiles.push(await this.generateBlobFile(pdfDoc, form, documentTitle));

    return blobFiles;
  }

  public async generateBlobFile(
    pdfDoc: PDFDocument,
    form: PDFForm,
    documentTitle: string
  ): Promise<BlobFile> {
    // Set all fields as read only
    form.getFields().map((field) => field.enableReadOnly());

    // Set document title
    pdfDoc.setTitle(documentTitle);

    const pdfToSave: PDFDocument = await pdfDoc.copy();
    // save and create a Blob
    const pdfBytes = await pdfToSave.save();
    const blob: Blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const blobFile: BlobFile = {
      title: documentTitle,
      blob,
      status: ReportStatus.Pending,
      fileURL: this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(blob)
      ),
    };

    return blobFile;
  }

  public openBlob(blob: Blob): void {
    const file = new File([blob], 'txtName.pdf', { type: 'application/pdf' });
    const reportUrl = URL.createObjectURL(file);
    window.open(reportUrl);
  }

  public async embedCustomFont(
    fontName: string,
    pdfDoc: PDFDocument
  ): Promise<PDFFont> {
    const fontUrl = await this.firebaseService.getDownloadURL(
      FirebaseConstants.TemplatesFolder +
        FirebaseConstants.FontsFolder +
        fontName
    );
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    const font = await pdfDoc.embedFont(fontBytes);
    return font;
  }

  public async downloadAndSetImageField(
    fieldName: string,
    imageUrl: string,
    form: PDFForm,
    pdfDoc: PDFDocument
  ): Promise<void> {
    const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
    let image: PDFImage;
    try {
      image = await pdfDoc.embedPng(imageBytes);
    } catch {
      image = await pdfDoc.embedJpg(imageBytes);
    }
    const imageField = form.getButton(fieldName);
    imageField.setImage(image);
  }

  public setTextField(
    fieldName: string,
    fieldValue: string,
    form: PDFForm,
    font?: PDFFont
  ): void {
    const companyNameField = form.getTextField(fieldName);
    companyNameField.setText(fieldValue);
    companyNameField.updateAppearances(font);
  }

  public getMultiLineText(text: string, lineLenght: number): Array<string> {
    const textSplited = text.split(' ');
    let lineText = '';
    const multiLineText = [];

    for (const word of textSplited) {
      if ((lineText + word).length <= lineLenght) {
        lineText += word + ' ';
      } else {
        multiLineText.push(lineText);
        lineText = '';
        lineText += word + ' ';
      }
    }
    multiLineText.push(
      lineText.length > 0 ? lineText : text.substring(0, lineLenght)
    );

    return multiLineText;
  }

  public async getLocationReportCalculations(
    companyId: string,
    distanceChallenge: DistanceChallenge
  ): Promise<any> {
    const locationsQuerySnapshot = await this.firebaseService.getCompanyLocations(
      companyId
    );
    const traninings = await this.companyService.getCompanyTrainings(companyId);
    const locationsSorted = locationsQuerySnapshot.docs
      .map((location) => new Location().deserialize(location.data()))
      .sort((locationA, locationB) =>
        locationB.name > locationA.name ? -1 : 1
      );

    if (traninings.length !== 0) {
      this.getStepsForLocations(locationsSorted, traninings, distanceChallenge);
    }

    const totalLocationsSteps = locationsSorted.reduce(
      (accumulator, location) => accumulator + location.totalSteps,
      0
    );

    const locationCalculations = locationsSorted.map((location) =>
      Object.assign(location, {
        locationContribution: (
          (location.totalSteps / totalLocationsSteps) *
          100
        ).toFixed(2),
        locationStepsPerDay:
          location.userAmount > 0
            ? (
                location.totalSteps /
                (location.userAmount *
                  Math.round(
                    (Date.now() - distanceChallenge.startDate) /
                      (1000 * 60 * 60 * 24)
                  ))
              ).toFixed(2)
            : 0,
      })
    );

    return locationCalculations;
  }

  public releaseReport(
    report: Report,
    reportId: string,
    companyId: string
  ): Promise<void> {
    return this.firebaseService.addNewReport(report, reportId, companyId);
  }

  public async uploadReport(
    fileToUpload: Blob,
    documentTitle: string,
    type: ReportTypes,
    companyId: string,
    challengeId: string
  ): Promise<Report> {
    let longReportUrl = '';
    let shortReportUrl = '';
    try {
      const snapshot = await this.firebaseService.uploadReport(
        fileToUpload,
        documentTitle,
        companyId
      );
      longReportUrl = await snapshot.ref.getDownloadURL();
      shortReportUrl = await this.firebaseService.createDynamicLink(
        longReportUrl
      );
    } catch (error) {
      console.log('uploadReport - error: ', error);
    }
    if (longReportUrl !== '' && shortReportUrl !== '') {
      // TODO: update when a new report type does not need challenge id
      return new ReportChallenge({
        name: documentTitle,
        src: longReportUrl,
        shortLink: shortReportUrl,
        type,
        dateGenerated: Date.now(),
        challengeId,
      });
    } else {
      return Promise.reject('Could not upload report');
    }
  }

  public reportExist(path: string): Promise<boolean> {
    return this.firebaseService.fileExist(path);
  }

  private getStepsForLocations(
    locations: Array<Location>,
    trainings: Array<Training>,
    currentDistanceChallenge: DistanceChallenge
  ): void {
    locations.forEach((location) => {
      location.totalSteps = -1;
      trainings.forEach((trainingsDay) => {
        const trainingsDate = Helpers.unformatDate(trainingsDay.date).getTime();
        if (
          trainingsDate >= currentDistanceChallenge.startDate &&
          trainingsDate <= currentDistanceChallenge.endDate
        ) {
          location.totalSteps += trainingsDay.trainings.reduce(
            (accumulator, trainingData) =>
              (accumulator += trainingData.locationIds.includes(location.name)
                ? trainingData.steps
                : 0),
            0
          );
        }
      });
      location.totalSteps += 1;
    });
  }

  public async generateCitiesChallengeReport(
    citiesChallenge: CitiesChallenge,
    citiesChallengeDayPerformance: CitiesChallengeDayPerformance,
    companyId: string
  ): Promise<Blob> {
    // Get company
    const company = await this.companyService.getCompany(companyId);

    // Create title
    const documentTitle = `${company.id}_${citiesChallenge.name.replace(
      ' ',
      '-'
    )}_Report_${Helpers.formatDateFileTitle(new Date())}.pdf`;

    // Get cities challenge report data
    const challengeData = this.getCitiesChallengeReportData(
      company,
      citiesChallenge,
      citiesChallengeDayPerformance
    );

    // Create report
    const citiesChallengeReport = new CitiesChallengeReport(challengeData);

    // Download report template
    await this.prepareDocument(citiesChallengeReport);

    // Set logos
    await this.setCompanyAndWhiteLabelLogos(
      citiesChallengeReport,
      company.avatar,
      company.whiteLabel?.logo
    );

    // Embed fonts
    await this.embedFontsInPDFDoc(citiesChallengeReport);

    // Set text fields
    this.setTextFields(citiesChallengeReport);

    return this.generateBlob(citiesChallengeReport, documentTitle);
  }

  /**
   * Prepare Document for Report. Download template file, and set the pdfDoc and pdfForm properties
   */
  public async prepareDocument(document: Document): Promise<void> {
    // Download report template from Firebase Storage
    const templateUrl = await this.firebaseService.getDownloadURL(
      document.templatePath
    );

    const templatePdfBytes = await fetch(templateUrl).then((res) =>
      res.arrayBuffer()
    );

    // Load the PDF with pdf-lib
    document.pdfDoc = await PDFDocument.load(templatePdfBytes);

    // Get form object to be filled
    document.pdfForm = document.pdfDoc.getForm();
  }

  /**
   * Set Company Logo and White Label Logo if exists
   * @param {Document} document - The Document where set the logos
   * @param {string} companyLogo - The Company Logo path
   * @param {string} whiteLabelLogo - The White Label Logo path
   */
  public async setCompanyAndWhiteLabelLogos(
    document: Document,
    companyLogo: string,
    whiteLabelLogo: string | null = null
  ): Promise<void> {
    if (companyLogo) {
      await this.downloadAndSetImageField(
        document.getCompanyLogoFieldName(),
        companyLogo,
        document.pdfForm,
        document.pdfDoc
      );
    }

    if (whiteLabelLogo) {
      await this.downloadAndSetImageField(
        document.getWhiteLabelLogoFieldName(),
        whiteLabelLogo,
        document.pdfForm,
        document.pdfDoc
      );
    }
  }

  /**
   * Embed fonts in PDF Document for Report
   */
  public async embedFontsInPDFDoc(report: ChallengeReport): Promise<void> {
    report.pdfDoc.registerFontkit(fontkit);
    for (const fontName of Object.keys(report.fonts)) {
      report.fonts[fontName] = await this.embedCustomFont(
        fontName,
        report.pdfDoc
      );
    }
  }

  /**
   * Set text fields in report
   * @param {Report} report - The report where text field will be set
   */
  public async setTextFields(report: ChallengeReport): Promise<void> {
    report.setTextFieldsInForm();
  }

  /**
   * Set text fields in report
   * @param {Report} report - The report that will be used to create a blob
   * @param {string} documentTitle - The title to be set on the blob
   */
  public generateBlob(
    report: ChallengeReport,
    documentTitle: string
  ): Promise<Blob> {
    return report.generateBlobFile(documentTitle);
  }

  /**
   * Get Cities Challenge Report Data
   * @param {Company} company - The company
   * @param {CitiesChallenge} citiesChallenge - The cities challenge from the company
   * @param {CitiesChallengeDayPerformance} citiesChallengeDayPerformance - Cities challenge performance data
   */
  public getCitiesChallengeReportData(
    company: Company,
    citiesChallenge: CitiesChallenge,
    citiesChallengeDayPerformance: CitiesChallengeDayPerformance
  ): ICitiesChallengeReport {
    // Create strings to fill the form up
    const startDate = new Date(citiesChallenge.startDate);
    const endDate = new Date(citiesChallenge.endDate);
    const challengePeriod: string =
      Helpers.formatDateByLanguage(
        startDate,
        this.translateService.currentLang
      ) +
      ' - ' +
      Helpers.formatDateByLanguage(endDate, this.translateService.currentLang);
    const dayProgress: string =
      citiesChallengeDayPerformance.day +
      ' / ' +
      citiesChallenge.getDaysDuration() +
      ' ' +
      this.translateService.instant('CITIES_CHALLENGE.REPORT.DAYS');
    const participantsNumber: string = citiesChallengeDayPerformance.users.toString();
    const routeProgress: string =
      Helpers.formatAndRoundNumberByLanguage(
        citiesChallengeDayPerformance.totalProgress,
        2,
        this.translateService.currentLang
      ) + ' %';
    const expectedProgress =
      '(' +
      this.translateService.instant(
        'CITIES_CHALLENGE.REPORT.EXPECTED_PROGRESS'
      ) +
      ': ' +
      Helpers.formatAndRoundNumberByLanguage(
        citiesChallengeDayPerformance.expectedTotalProgress,
        2,
        this.translateService.currentLang
      ) +
      '%)';
    const currentStage: string =
      citiesChallengeDayPerformance.totalProgress < 100
        ? citiesChallenge.regions[
            citiesChallengeDayPerformance.regionIndex
          ].route.cityMarkerPoints[
            citiesChallengeDayPerformance.stageIndex
          ].city.translations.get(this.translateService.currentLang) +
          ' (' +
          citiesChallenge.regions[
            citiesChallengeDayPerformance.regionIndex
          ].route.cityMarkerPoints[
            citiesChallengeDayPerformance.stageIndex
          ].city.countryAlphaCode.toUpperCase() +
          ') - ' +
          citiesChallenge.regions[
            citiesChallengeDayPerformance.regionIndex
          ].route.cityMarkerPoints[
            citiesChallengeDayPerformance.stageIndex + 1
          ].city.translations.get(this.translateService.currentLang) +
          ' (' +
          citiesChallenge.regions[
            citiesChallengeDayPerformance.regionIndex
          ].route.cityMarkerPoints[
            citiesChallengeDayPerformance.stageIndex + 1
          ].city.countryAlphaCode.toUpperCase() +
          ')'
        : this.translateService.instant('CITIES_CHALLENGE.REPORT.COMPLETED');
    const stageProgress: string =
      Helpers.formatAndRoundNumberByLanguage(
        citiesChallengeDayPerformance.stageProgress,
        2,
        this.translateService.currentLang
      ) + ' %';
    const dateValue: string = Helpers.formatDateByLanguage(
      new Date(citiesChallengeDayPerformance.date),
      this.translateService.currentLang
    );

    return {
      company: {
        name: company.name,
        id: company.id,
      },
      challenge: {
        name: citiesChallenge.name,
        reportDate: dateValue,
        challengePeriod,
        dayProgress,
        participantsNumber,
        routeProgress,
        targetValue: expectedProgress,
        currentStage,
        stageProgress,
      },
    };
  }
}
