<div class="container">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab [label]="'CITIES_CHALLENGE.CURRENT_CHALLENGE' | translate">
      <app-cities-challenge-starts-soon-card *ngIf="nextCitiesChallenge" [nextCitiesChallenge]="nextCitiesChallenge"></app-cities-challenge-starts-soon-card>
      <app-cities-challenge-dashboard *ngIf="currentCitiesChallengeDashboard" [citiesChallengeDashboard]="currentCitiesChallengeDashboard"></app-cities-challenge-dashboard>
      <app-no-current-challenge *ngIf="!loading && !(currentCitiesChallengeDashboard || nextCitiesChallenge)" [noCurrentChallenge]="noCitiesChallenge"></app-no-current-challenge>
      <div class="spinner-container" *ngIf="loading && !(currentCitiesChallengeDashboard || nextCitiesChallenge)">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>
    <mat-tab [label]="'CITIES_CHALLENGE.COMPLETED_CHALLENGES' | translate">
      <div class="form-field-container">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'CITIES_CHALLENGE.SELECT_CHALLENGE' | translate }}</mat-label>
          <mat-select [formControl]="selectedChallengeFormControl">
            <mat-option *ngFor="let citiesChallenge of completedCitiesChallenges" [value]="citiesChallenge">
              {{ citiesChallenge.name }}
            </mat-option>
          </mat-select>
          <mat-hint>{{ 'CITIES_CHALLENGE.HINT' | translate }}</mat-hint>
        </mat-form-field>
      </div>
      <app-cities-challenge-dashboard *ngIf="completedCitiesChallengeDashboard" [citiesChallengeDashboard]="completedCitiesChallengeDashboard"></app-cities-challenge-dashboard>
      <div class="spinner-container" *ngIf="loading && !completedCitiesChallengeDashboard">
        <mat-spinner></mat-spinner>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
