<div class="team-challenge-dashboard-container">
  <app-challenge-header [challengeName]="teamChallenge.name" [challengeDuration]="duration" [challengeIcon]="TEAM_CHALLENGE_ICON"></app-challenge-header>
  <mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedTab">
    <mat-tab [label]="'TEAM_CHALLENGE_V2.OVERVIEW' | translate">
      <app-team-challenge-starts-soon-card *ngIf="CHALLENGE_STATUS.Pending === currentChallengeStatus" [nextTeamChallenge]="teamChallenge" (editButtonClicked)="goToEdit()"></app-team-challenge-starts-soon-card>
      <app-team-challenge-overview *ngIf="CHALLENGE_STATUS.InProgress === currentChallengeStatus && teamChallengeOverview" [teamChallengeOverview]="teamChallengeOverview"></app-team-challenge-overview>
    </mat-tab>
    <mat-tab [label]="'TEAM_CHALLENGE_V2.EDITION' | translate">
      <ng-template matTabContent>
        <app-team-challenge-form [teamChallengeDataForm]="teamChallengeDataForm" [availableLocationLevels]="availableLocationLevels" [company]="company" [showSaveButton]="true"></app-team-challenge-form>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
