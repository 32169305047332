import {
  CityMarkerPoint,
  ICityMarkerPointInitializer,
} from '../city-marker-point/city-marker-point.model';
import { ICityInitializer } from '../city/city.model';

export interface IRouteInitializer {
  id?: string;
  cityMarkerPoints?: Array<ICityMarkerPointInitializer>;
  totalDistance?: number;
  name?: string;
  map?: string;

  // Retro compatibility
  citiesMarkerPoints?: Array<ICityInitializer>;
  distanceBetweenCities?: Array<number>;
}

export class Route {
  public id: string = null;
  public cityMarkerPoints: Array<CityMarkerPoint> = [
    ...Array(3).fill(new CityMarkerPoint()),
  ];
  public totalDistance: number = null;
  public name: string = null;
  public map: string = null;

  constructor(iRouteInitializer: IRouteInitializer = {}) {
    Object.assign(this, iRouteInitializer);

    if (iRouteInitializer.cityMarkerPoints?.length > 0) {
      this.cityMarkerPoints = iRouteInitializer.cityMarkerPoints.map(
        (cityMarkerPoint) => new CityMarkerPoint(cityMarkerPoint)
      );
    } else {
      // IS AN OLD ROUTE MODEL?
      if (iRouteInitializer.citiesMarkerPoints?.length > 0) {
        // ADAPT NEW ROUTE MODEL INITIALIZATION
        this.cityMarkerPoints = iRouteInitializer.citiesMarkerPoints.map(
          (cityMarkerPoint, index) =>
            new CityMarkerPoint({
              city: { ...cityMarkerPoint },
              distanceToNextCity:
                iRouteInitializer.distanceBetweenCities[index],
            })
        );
      }
    }
  }

  public toObject(): IRouteInitializer {
    const { cityMarkerPoints, ...otherProperties } = this;

    return {
      ...otherProperties,
      cityMarkerPoints: cityMarkerPoints.map((cityMarkerPoint) =>
        cityMarkerPoint.toObject()
      ),
    };
  }

  public calculateTotalDistance(): void {
    this.totalDistance = this.cityMarkerPoints.reduce(
      (accumulatedDistance, cityMarkerPoint) =>
        (accumulatedDistance += cityMarkerPoint.distanceToNextCity),
      0
    );
  }

  public updateCity(index: number, cityMarkerPoint: CityMarkerPoint): void {
    if (index < this.cityMarkerPoints.length && index >= 0) {
      if (cityMarkerPoint && cityMarkerPoint.city) {
        if (index > 0) {
          this.cityMarkerPoints[
            index - 1
          ].distanceToNextCity = this.cityMarkerPoints[
            index - 1
          ].city.getDistanceToCity(cityMarkerPoint.city);
        }

        if (index < this.cityMarkerPoints.length - 1) {
          cityMarkerPoint.distanceToNextCity = cityMarkerPoint.city.getDistanceToCity(
            this.cityMarkerPoints[index + 1].city
          );
        }

        this.cityMarkerPoints[index] = cityMarkerPoint;

        this.calculateTotalDistance();
      } else {
        throw new Error(
          'city marker point does not exist or does not have a city set'
        );
      }
    } else {
      throw new Error('incorrect index');
    }
  }

  public removeCity(index: number): void {
    if (index < this.cityMarkerPoints.length && index >= 0) {
      if (index > 0 && index < this.cityMarkerPoints.length - 1) {
        this.cityMarkerPoints[
          index - 1
        ].distanceToNextCity = this.cityMarkerPoints[
          index - 1
        ].city.getDistanceToCity(this.cityMarkerPoints[index + 1].city);
      } else {
        if (index === this.cityMarkerPoints.length - 1) {
          this.cityMarkerPoints[index - 1].distanceToNextCity = null;
        }
      }

      this.cityMarkerPoints.splice(index, 1);
      this.calculateTotalDistance();
    } else {
      throw new Error('incorrect index');
    }
  }

  public addCity(cityMarkerPoint: CityMarkerPoint): void {
    if (cityMarkerPoint && cityMarkerPoint.city) {
      if (this.cityMarkerPoints.length > 0) {
        this.cityMarkerPoints[
          this.cityMarkerPoints.length - 1
        ].distanceToNextCity = this.cityMarkerPoints[
          this.cityMarkerPoints.length - 1
        ].city.getDistanceToCity(cityMarkerPoint.city);
      }

      this.cityMarkerPoints.push(cityMarkerPoint);
      this.calculateTotalDistance();
    } else {
      throw new Error(
        'city marker point does not exist or does not have a city set'
      );
    }
  }
}
