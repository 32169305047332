<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar [svgIcon]="challengeIcon"></mat-icon>
    <mat-card-title>
      {{ challengeName | translate }}
    </mat-card-title>
    <mat-card-subtitle *ngIf="challengeDuration > 0">
      {{ challengeDuration | challengeDuration: this.lang }}
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
