<div class="container">
  <div class="title">
    <h1>{{ citiesChallengeDataForm.controls.name.value }}</h1>
  </div>

  <div class="subtitle" *ngIf="citiesChallengeType">
    <h2>{{ 'CITIES_CHALLENGE_OVERVIEW.CHALLENGE_TYPE' | translate }}</h2>
    <p>{{ citiesChallengeType.split('_')[1] }}</p>
  </div>

  <div class="subtitle">
    <h2>{{ 'CITIES_CHALLENGE_OVERVIEW.EXPECTED_STEPS_PER_DAY' | translate }}</h2>
    <p>{{ citiesChallengeDataForm.controls.expectedDailySteps.value }}</p>
  </div>

  <div class="subtitle">
    <h2>{{ 'CITIES_CHALLENGE_OVERVIEW.REGIONS' | translate }}</h2>
    <p>{{ citiesChallengeDataForm.controls.regionsNumber.value }}</p>
  </div>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let region of regions.controls; let i = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ region.controls.route.get('name').value }} {{ 'CITIES_CHALLENGE_OVERVIEW.CITIES_AMOUNT' | translate:{ citiesAmount: region.controls.route.get('cityMarkerPoints').value.length } }}
        </mat-panel-title>
        <mat-panel-description>
          ({{ region.controls.dateRange.value.start | date: 'dd.MM.yyyy' }} - {{ region.controls.dateRange.value.end | date: 'dd.MM.yyyy' }})
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-route-form [editable]="false" [routeDataForm]="getRouteFromRegionIndex(i)"></app-route-form>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="confirmation-message">
    <h1>{{ 'CITIES_CHALLENGE_OVERVIEW.CONFIRMATION_TITLE' | translate }}</h1>
    <h2>{{ 'CITIES_CHALLENGE_OVERVIEW.CONFIRMATION_TEXT' | translate }}</h2>
    <button mat-raised-button color="primary" [disabled]="showSpinner" (click)="createClick()">
      {{ 'CITIES_CHALLENGE_OVERVIEW.DONE_BUTTON' | translate }}
      <mat-spinner *ngIf="showSpinner" diameter="16"></mat-spinner>
    </button>
  </div>
</div>
