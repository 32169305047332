<mat-spinner *ngIf="showLoadingCitySpinner"></mat-spinner>
<div class="container" *ngIf="!showLoadingCitySpinner">
  <div class="header">
    <div class="title">
      <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
      <h1>{{ 'CITY_INFO_PAGE.TITLE' | translate }}</h1>
    </div>
    <h4 class="subtitle">{{ city.translations.get(currentLang) }}</h4>
  </div>
  <div class="body">
    <button color="primary" mat-raised-button (click)="addCityInfo()">
      {{ 'CITY_INFO_PAGE.BUTTON' | translate }}
    </button>

    <!-- Add city info card inside the body -->
    <div *ngIf="cityInfos && cityInfos.length > 0" class="card-container">
      <div *ngFor="let cityInfo of cityInfos" class="card">
        <app-city-info-card [cityInfo]="cityInfo" [city]="city"></app-city-info-card>
      </div>
    </div>
  </div>
</div>
  