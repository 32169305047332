import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { SELECT_ALL } from 'src/app/constants/constants';

// Models
import { Company } from 'src/app/models/company.model';
import { UserSearchOptions } from 'src/app/models/user-search-options.enum';
import { User, UserPropNames } from 'src/app/models/user.model';

// Service
import { UsersManagementService } from 'src/app/services/admin/users-management/users-management.service';

@Component({
  selector: 'app-users-tab',
  templateUrl: './users-tab.component.html',
  styleUrls: ['./users-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersTabComponent implements OnInit, AfterViewInit {
  public USER_PROP_NAMES = UserPropNames;
  public SELECT_ALL_VALUE = SELECT_ALL;

  public exportUserStepAveragePerCompanySpinner = false;

  public usersDataSource = new MatTableDataSource(this.usersToShow);
  @ViewChild(MatSort, { static: true }) usersSort: MatSort;

  constructor(
    private usersManagementService: UsersManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.filterValue.valueChanges.subscribe((value) => {
      this.usersDataSource.filter = value.trim().toLowerCase();
    });
  }

  ngOnInit(): void {
    if (this.activeSort) {
      this.usersSort.active = this.activeSort.active;
      this.usersSort.direction = this.activeSort.direction;
    } else {
      this.usersManagementService.updateSort(this.usersSort);
    }
    if (this.filterValue) {
      this.usersDataSource.filter = this.filterValue.value.trim().toLowerCase();
    }
  }

  ngAfterViewInit(): void {
    this.usersDataSource.sort = this.usersSort;
    if (this.companiesToSelect.length === 0) {
      this.usersManagementService.getCompaniesToSelect();
    }
  }

  get companiesToSelect(): Array<Company> {
    return this.usersManagementService.companiesToSelect;
  }

  get userSearchOptions(): Array<string> {
    return this.usersManagementService.userSearchOptions;
  }

  get usersToShow(): Array<User> {
    return this.usersManagementService.usersToShow;
  }

  get filterValue(): UntypedFormControl {
    return this.usersManagementService.filterValue;
  }

  get valuesBySearchOption(): any {
    return this.usersManagementService.formControlBySearchOption;
  }

  get userSearchOptionSelected(): UntypedFormControl {
    return this.usersManagementService.userSearchOptionSelected;
  }

  get USERS_SEARCH_OPTIONS(): typeof UserSearchOptions {
    return this.usersManagementService.USERS_SEARCH_OPTIONS;
  }

  get selectedCompanies(): UntypedFormControl {
    return this.usersManagementService.selectedCompanies;
  }

  get userEmailToSearch(): UntypedFormControl {
    return this.usersManagementService.userEmailToSearch;
  }

  get activeSort(): Sort {
    return this.usersManagementService.activeSort;
  }

  get showSpinnerUsers(): boolean {
    return this.usersManagementService.showSpinnerUsers;
  }

  get usersFilters(): Array<string> {
    return this.usersManagementService.usersFilters;
  }

  get filtersForm(): UntypedFormGroup {
    return this.usersManagementService.filtersForm;
  }

  get activeFilters(): Array<string> {
    return this.usersManagementService.activeFilters;
  }

  public exportUserStepAveragePerCompany(): void {
    this.exportUserStepAveragePerCompanySpinner = true;

    this.usersManagementService
      .exportUserStepAveragePerCompany()
      .then(() => {})
      .catch((error) => {
        console.log('error exporting excel: ', error);
      })
      .finally(() => {
        this.exportUserStepAveragePerCompanySpinner = false;
      });
  }

  public async getUserBySearchMethod(userSearchMethod?: string): Promise<void> {
    await this.usersManagementService.getUsersBySearchMethod(userSearchMethod);
    this.usersDataSource = new MatTableDataSource(this.usersToShow);
    this.usersDataSource.sort = this.usersSort;
  }

  public goToUser(userId: string): void {
    this.router.navigate(['user-info', userId], {
      relativeTo: this.route,
    });
  }

  public updateSort(sortChange: Sort): void {
    this.usersManagementService.updateSort(sortChange);
  }

  public exportUserTableToExcel(): void {
    const dataToExport = this.usersDataSource.filteredData.map((user) =>
      this.activeFilters.reduce(
        (
          objectWithProperties: Partial<User> | { sLastSync: string },
          column: string
        ) => {
          switch (column) {
            case 'lastSync':
              objectWithProperties['sLastSync'] = this.datePipe.transform(
                user[column],
                'yyyy-MM-dd HH:mm:ss'
              );
              break;
            case 'settings.healthProvider':
              objectWithProperties['settings.healthProvider'] =
                user.settings.healthProvider;
              break;
            case 'locationIds':
              objectWithProperties['locationIds'] = Array.isArray(
                user.locationIds
              )
                ? user.locationIds.join(', ')
                : user.locationIds;
              break;
            default:
              objectWithProperties[column] = user[column];
              break;
          }
          return objectWithProperties;
        },
        {}
      )
    );
    this.usersManagementService.exportUserTableToExcel(dataToExport);
  }

  public toggleAllSelection(): void {
    if (
      this.selectedCompanies.value &&
      !this.selectedCompanies.value.includes(SELECT_ALL)
    ) {
      this.selectedCompanies.patchValue([]);
    } else {
      this.selectedCompanies.patchValue([
        ...this.companiesToSelect.map((company) => company.id),
        SELECT_ALL,
      ]);
    }
  }

  public updateSelectAllValue(): void {
    if (
      this.selectedCompanies.value &&
      this.selectedCompanies.value.includes(SELECT_ALL) &&
      this.selectedCompanies.value.length - 1 < this.companiesToSelect.length
    ) {
      this.selectedCompanies.patchValue(
        this.selectedCompanies.value.filter((value) => value !== SELECT_ALL)
      );
    } else if (
      this.selectedCompanies.value &&
      !this.selectedCompanies.value.includes(SELECT_ALL) &&
      this.selectedCompanies.value.length === this.companiesToSelect.length
    ) {
      this.selectedCompanies.patchValue([
        ...this.companiesToSelect.map((company) => company.id),
        SELECT_ALL,
      ]);
    }
  }
}
