import { Injectable } from '@angular/core';

// Models
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { CityInfo } from 'src/app/models/cities-challenge/city-info/city-info.model';

// Services
import { FirebaseService } from '../firebase/firebase.service';
import { SubscriptionService } from '../subscription/subscription.service';
import {
  CityDetails,
  ICityDetailsInitializer,
} from 'src/app/models/cities-challenge/city-details/city-details.model';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { EditCityInfoDialogComponent } from 'src/app/shared/components/edit-city-info-dialog/edit-city-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CityInfoCardDialogComponent } from 'src/app/shared/components/city-info-card-dialog/city-info-card-dialog.component';

export interface ICityDetailsToUpload extends ICityDetailsInitializer {
  file?: File;
}
@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(
    private firebaseService: FirebaseService,
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog
  ) {
    this.subscriptionService.subscribeToCities();
  }

  public createUpdateCity(city: City): Promise<void> {
    if (!city.id) {
      city.id = this.firebaseService.getAutomaticIdInRootCollection(
        FirebaseConstants.CitiesCollection
      );
    }
    return this.firebaseService.createUpdateCity(city);
  }

  public getCityFromFirebase(cityId: string): Promise<City> {
    return this.firebaseService
      .getCity(cityId)
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          return new City(documentSnapshot.data());
        } else {
          return Promise.reject(`NO CITY WITH ID: ${cityId}`);
        }
      })
      .catch((error) => {
        console.log('getCityFromFirebase - error: ', error);
        return Promise.reject(error);
      });
  }

  // City info methods
  public removeCityInfo(cityId: string, cityInfo: CityInfo): Promise<void> {
    return this.firebaseService
      .removeCityInfo(cityId, cityInfo)
      .catch((error) => {
        console.log('removeCityInfo - error: ', error);
        return Promise.reject(error);
      });
  }

  public async uploadCityDetails(
    cityId: string,
    cityDetailsToUpload: Array<ICityDetailsToUpload>
  ): Promise<Array<CityDetails>> {
    for (const cityDetail of cityDetailsToUpload) {
      if (cityDetail.file) {
        const path =
          FirebaseConstants.CitiesFolder +
          cityId +
          '/' +
          FirebaseConstants.ImagesFolder +
          cityDetail.file.name;
        cityDetail.image = await this.firebaseService.uploadData(
          cityDetail.file,
          path
        );
      }
    }

    return cityDetailsToUpload.map(
      (cityDetailToUpload) =>
        new CityDetails({
          image: cityDetailToUpload.image ? cityDetailToUpload.image : null,
          description: cityDetailToUpload.description,
          imageRights: cityDetailToUpload.imageRights,
        })
    );
  }

  public createUpdateCityInfo(
    cityId: string,
    cityInfo: CityInfo
  ): Promise<void> {
    if (!cityInfo.id) {
      cityInfo.id = this.firebaseService.getAutomaticIdInRootCollection(
        FirebaseConstants.CitiesCollection
      );
    }
    return this.firebaseService
      .createUpdateCityInfo(cityId, cityInfo)
      .catch((error) => {
        console.log('createUpdateCityInfo - error: ', error);
        return Promise.reject(error);
      });
  }

  public getCityInfoFromFirebase(
    cityId: string,
    cityInfoType: string
  ): Promise<CityInfo> {
    return this.firebaseService
      .getCityInfoByType(cityId, cityInfoType)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return new CityInfo(querySnapshot.docs[0].data());
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log('getCityInfos - error: ', error);
        return Promise.reject(error);
      });
  }

  public getCityInfosFromFirebase(
    cityIds: Array<string>,
    cityInfoType: Array<string>
  ): Promise<Array<CityInfo>> {
    const cityInfoPromises = cityIds.map((cityId, index) =>
      cityId
        ? this.getCityInfoFromFirebase(cityId, cityInfoType[index])
        : Promise.resolve(null)
    );
    return Promise.all(cityInfoPromises);
  }

  public openEditCityInfoDialog(
    city: City,
    cityInfo?: CityInfo,
    cityInfoTypeDisabled = false,
    cityInfoTypeDefault: string = null
  ): Observable<CityInfo> {
    const editCityInfoDialog = this.dialog.open(EditCityInfoDialogComponent, {
      width: '30%',
      disableClose: true,
      data: {
        city,
        cityInfo,
        cityInfoTypeDisabled,
        cityInfoTypeDefault,
      },
    });

    return editCityInfoDialog.afterClosed();
  }

  public openCityInfoCardDialog(
    city: City,
    cityInfo: CityInfo,
    viewOnly: boolean
  ): Observable<CityInfo> {
    const editCityInfoDialog = this.dialog.open(CityInfoCardDialogComponent, {
      width: '30%',
      disableClose: true,
      data: {
        cityInfo,
        city,
        viewOnly,
      },
    });

    return editCityInfoDialog.afterClosed();
  }
}
