export interface ITeamMember {
  avatar: string;
  email: string;
  id: string;
  nickname: string;
}

export class TeamMember {
  public avatar: string = '';
  public email: string = '';
  public id: string = '';
  public nickname: string = '';

  constructor(initializer?: ITeamMember) {
    if (initializer) {
      this.avatar = initializer.avatar ?? this.avatar;
      this.email = initializer.email ?? this.email;
      this.id = initializer.id ?? this.id;
      this.nickname = initializer.nickname ?? this.nickname;
    }
  }

  public toObject(): ITeamMember {
    return {
      avatar: this.avatar,
      email: this.email,
      id: this.id,
      nickname: this.nickname,
    };
  }
}
