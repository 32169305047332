<mat-card>
  <mat-card-header>
    {{ 'TEAM_CHALLENGE.FEATURES.' + featureSelectionCard.feature + '.TITLE' | translate}}
  </mat-card-header>
  <mat-card-content>
    <div class="slide-section">
      <mat-slide-toggle 
        #slideToggle 
        (change)="featureToggledEvent($event)" 
        [disabled]="featureSelectionCard.disabled" 
        [checked]="featureSelectionCard.checked"
        color="primary" 
        labelPosition="before"
      >
        {{ (slideToggle.checked ? 'TEAM_CHALLENGE.FEATURES.ACTIVE' : 'TEAM_CHALLENGE.FEATURES.INACTIVE') | translate }}
      </mat-slide-toggle>
    </div>
    <div class="mode-section">
      <p class="mode-section-title">{{ featureSelectionCard.inactiveSection.title | translate }}</p>
      <p class="mode-section-text">{{ featureSelectionCard.inactiveSection.text | translate }}</p>
    </div>
    <div class="mode-section">
      <p class="mode-section-title">{{ featureSelectionCard.activeSection.title | translate }}</p>
      <p class="mode-section-text">{{ featureSelectionCard.activeSection.text | translate }}</p>
    </div>
  </mat-card-content>
</mat-card>
