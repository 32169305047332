import { Deserializable } from './deserializable.model';
import { PointsSourceBasis } from './points-source-basis.enum';
import { PointsSourceType } from './points-source-type.enum';

export class PointsSource implements Deserializable {
  public type: PointsSourceType;
  public description: string;
  public basis: PointsSourceBasis;
  public expectedPerformance: number;
  public pointsAwarded: number;
  public isAchieved: boolean;
  public id: string;

  constructor(pointsSourceObject?: any) {
    this.type = pointsSourceObject
      ? pointsSourceObject.type
      : PointsSourceType.Steps;
    this.description = pointsSourceObject ? pointsSourceObject.description : '';
    this.basis = pointsSourceObject
      ? pointsSourceObject.basis
      : PointsSourceBasis.Daily;
    this.expectedPerformance = pointsSourceObject
      ? pointsSourceObject.expectedPerformance
      : 0;
    this.pointsAwarded = pointsSourceObject
      ? pointsSourceObject.pointsAwarded
      : 0;
    this.isAchieved = pointsSourceObject
      ? pointsSourceObject.isAchieved
      : false;
    this.id = pointsSourceObject ? pointsSourceObject.id : '';
  }

  public deserialize(input: any): this {
    return Object.assign(this, input);
  }

  public toObject(): any {
    return Object.assign({}, this);
  }
}
