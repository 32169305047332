<app-notification-messages></app-notification-messages>
<div class="spinner-container" *ngIf="!user">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="user">
  <div class="container" *ngIf="user.role === ROLES.TeamLeader && !team">
    <div class="col-lg-12 mt-4">
      <app-widget-card  [title]="YOU_BELONG_TO_THE_TEAM"
      [showHeader]="false"
      [text]="JOIN_A_TEAM"
      [showImage]="false">
      </app-widget-card>
    </div>
  </div>
  <div class="container" *ngIf="user.role === ROLES.TeamLeader && team">
    <h1 class="title text-primary text-start" [ngStyle]="{ 'color': team.color }">{{'CREATE_COMPANY.COMPANY_FORM.TITLE' | translate}}</h1>
    <div class="row mb-4">
      <div class="col-lg-12">
        <app-widget-card [showImage]="true" [title]="YOU_BELONG_TO_THE_TEAM"
        [showHeader]="true"
        [text]="team.name"
        [image]="team.avatar">
        </app-widget-card>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-6">
        <app-widget-card [showImage]="true" [title]="TEAM_LEADER"
        [text]="user.nickname" [showHeader]="true"
        [image]="'assets/img/path.png'">
        </app-widget-card>
      </div>
      <div class="col-lg-6">
        <app-widget-card [showImage]="true"[title]="MEMBERS_AMOUNT"
         [showHeader]="true"
         [number]="team.members.size"
         [image]="'assets/img/users2.png'">
        </app-widget-card>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role !== ROLES.TeamLeader && user.role !== ROLES.ProjectManager">
    <h1 class="text-primary text-start">{{ 'OVERVIEW.LABEL' | translate }}</h1>
    <div class="mb-4">
      <app-widget-steps-chart fxLayout.sm="column space-between"></app-widget-steps-chart>
    </div>
      <h1 class="text-primary text-start">{{ 'OVERVIEW.DETAILS_LABEL' | translate }}</h1>
      <h2 class="text-primary text-start">{{ 'OVERVIEW.DAILY_STEPS_SUBTITLE' | translate }}</h2>
    <div class="row">
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true" [title]="CURRENT_STEPS" [number]="todaySteps ? todaySteps : 0" [showHeader]="false"
          [image]="'assets/img/users2.png'" unit="{{ 'STEPS' | translate }}">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true"  [title]="CURRENT_KILOMETERS"
          [number]="todaySteps ? todaySteps / STEPS_IN_ONE_KILOMETER : 0" [showHeader]="false"
          [image]="'assets/img/path.png'" unit="km">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true"  [title]="TODAY_AVERAGE" [number]="todayAverage ? todayAverage : 0" [average]="true"
          [showHeader]="false" [image]="'assets/img/movement.png'" unit="{{ 'STEPS' | translate }}">
        </app-widget-card>
      </div>


    </div>
      <h2 class="text-primary text-start">
        {{ 'OVERVIEW.HISTORIC_STEPS_SUBTITLE' | translate }}
      </h2>
    <div class="row">
      <div  class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true"  [title]="TOTAL_STEPS" [number]="totalSteps ? totalSteps : 0" [showHeader]="false"
          [image]="'assets/img/users2.png'" unit="{{ 'STEPS' | translate }}">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true"  [title]="TOTAL_KILOMETERS"
          [number]="totalSteps ? totalSteps / STEPS_IN_ONE_KILOMETER : 0" [showHeader]="false"
          [image]="'assets/img/path.png'" unit="km">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true"  [title]="CURRENT_AVERAGE_STEPS_TOTAL"
          [number]="averageTotalSteps ? averageTotalSteps : 0" [showHeader]="false" [average]="true"
          [image]="'assets/img/movement.png'" unit="{{ 'STEPS' | translate }}">
        </app-widget-card>
      </div>
    </div>
    <h2 class="text-primary text-start">{{ 'OVERVIEW.SPECIFIC_DATE_RANGE' | translate }}</h2>

    <div class="col-lg-6">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'OVERVIEW.DATE_RANGE' | translate }}</mat-label>
        <mat-date-range-input [comparisonStart]="trainingDateRange.value.start"
          [comparisonEnd]="trainingDateRange.value.end" [rangePicker]="picker" [formGroup]="trainingDateRange" (click)="picker.open()">
          <input readonly='true' matStartDate
            placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_START' | translate }}" formControlName="start" />
          <input readonly='true' matEndDate
            placeholder="{{ 'OVERVIEW.DATE_PLACEHOLDER_END' | translate }}" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>



    <!-- <button mat-raised-button color="primary" [disabled]="
        !trainingDateRange.value.start ||
        !trainingDateRange.value.end ||
        disableButton
      " (click)="getUserTrainingData()">
      <div class="download-button">
        {{ 'OVERVIEW.UPDATE' | translate }}
        <mat-spinner diameter="20" *ngIf="spinnerFlag"></mat-spinner>
      </div>
    </button> -->

    <div class="row">
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true"  [updatingValue]="updatingValue" [title]="TOTAL_STEPS"
          [number]="userDefinedSteps ? userDefinedSteps : 0" [showHeader]="false" [image]="'assets/img/users2.png'"
          unit="{{ 'STEPS' | translate }}">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true"  [updatingValue]="updatingValue" [title]="TOTAL_KILOMETERS"
          [number]="userDefinedSteps ? userDefinedSteps / STEPS_IN_ONE_KILOMETER : 0" [showHeader]="false"
          [image]="'assets/img/path.png'" unit="km">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true" [updatingValue]="updatingValue" [title]="CURRENT_AVERAGE_STEPS_TOTAL" [number]="
            userDefinedSteps && userDefinedUsers && userDefinedUsers !== 0
              ? userDefinedSteps / userDefinedUsers
              : 0
          " [showHeader]="false" [average]="true" [image]="'assets/img/movement.png'" unit="{{ 'STEPS' | translate }}">
        </app-widget-card>
      </div>
    </div>

    <h2 class="text-primary text-start">{{ 'OVERVIEW.LICENCES_SUBTITLE' | translate }}</h2>
    <div class="row">
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true" [title]="ACTIVE_USERS" [number]="company ? company.users.length : 0"
          [showHeader]="true" [image]="'assets/img/month.png'">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true" [title]="LICENCES" [number]="company ? company.availableLicenses : 50"
          [showHeader]="true" [image]="'assets/img/licence.png'">
        </app-widget-card>
      </div>
    </div>
      <h2 class="text-primary text-start">{{ 'OVERVIEW.CHALLENGES_SUBTITLE' | translate }}</h2>
    <div class="row">
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true" [title]="FULLFILLED_CHALLENGES_IN_TOTAL" [showHeader]="true"
          [number]="company ? company.completedChallenges.length : 3" [image]="'assets/img/users2.png'">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true" [title]="AMOUNT_OF_FULFILLED_CHALLENGES_MONTH" [showHeader]="true"
          [number]="currentMonthChallenges ? currentMonthChallenges : 0" [image]="'assets/img/cog.png'">
        </app-widget-card>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
        <app-widget-card [showImage]="true" [title]="EMPLOYEES_WHO_AT_LEAST_FULFILLED_ONE_CHALLENGE" [showHeader]="true"
          [number]="usersWhoCompletedAChallenge ? usersWhoCompletedAChallenge : 0" [image]="'assets/img/challenge.png'">
        </app-widget-card>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role === ROLES.ProjectManager">
    <h1 class="title">{{ 'COMPANIES_TABLE.TITLE' | translate }}</h1>
    <mat-tab-group>
      <mat-tab label="{{ 'OVERVIEW.ACTIVE' | translate}}">
        <app-managed-companies-table [companies]="activeManagedCompanies" [companiesStatistics]="managedCompaniesStatistics" [managedCompanyChallengesData]="managedCompaniesChallengesData"></app-managed-companies-table>
      </mat-tab>
      <mat-tab label="{{ 'OVERVIEW.INACTIVE' | translate}}">
        <app-managed-companies-table [companies]="inactiveManagedCompanies" [companiesStatistics]="managedCompaniesStatistics" [managedCompanyChallengesData]="managedCompaniesChallengesData"></app-managed-companies-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
