<app-header [sidebarOpened]="sidebarOpened"></app-header>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="side" [opened]="sidebarOpened" class="sidenav">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content
    class="sidenav-content"
    [ngClass]="sidebarOpened ? '' : 'sidebar-closed'"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- <app-footer></app-footer> -->
