import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { de } from 'date-fns/locale';

// Models
import { GroupChallengesTypes } from 'src/app/models/group-challenges-types.enum';
import { WhiteLabel } from 'src/app/models/white-label.model';
import { Location } from 'src/app/models/location.model';
import { Locations } from 'src/app/models/locations.model';
import { ChallengeTypes } from 'src/app/models/challenge-types.enum';
import { PointsSourceBasis } from 'src/app/models/points-source-basis.enum';
import { PointsSourceType } from 'src/app/models/points-source-type.enum';
import { TeamChallengeTypes } from 'src/app/models/team-challenge-types.enum';
import { Team } from 'src/app/models/team.model';
import { Features } from 'src/app/models/features.enum';
import { WhiteLabelDetails } from 'src/app/models/white-label-details.model';
import { Company } from 'src/app/models/company.model';
import { LicenceTypes } from 'src/app/models/licence-types.enum';
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { AdminTabs } from '../admin-tabs.enum';
import { TeamChallengeSchema } from 'src/app/models/team-challenge-schema/team-challenge-schema';
import { TeamSchema } from 'src/app/models/team-schema.model';
import { PersonalChallengeSchema } from 'src/app/models/personal-challenge-schema/personal-challenge-schema';
import { PointsSource } from 'src/app/models/points-source.model';

// Services
import { AdminService } from 'src/app/services/admin/admin.service';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { TeamsService } from 'src/app/services/teams/teams.service';

// Components
import { LinkLocationsDialogComponent } from 'src/app/shared/components/link-locations-dialog/link-locations-dialog.component';

// Constants
import {
  COMPANY_TEAM_AVATAR_IMAGE_PATH,
  REGEX_ALPHANUMERIC_EXTENDED,
  REGEX_LINK,
} from 'src/app/constants/constants';
import { MatStepper } from '@angular/material/stepper';
import { MatIconRegistry } from '@angular/material/icon';
import { FileInputComponent } from 'ngx-material-file-input';
import { WhiteLabelButton } from 'src/app/models/white-label-button.model';
import { MatSelectChange } from '@angular/material/select';

interface DataFormDictionary {
  1: UntypedFormGroup;
  2: UntypedFormGroup;
  3: UntypedFormGroup;
}

interface LevelLocationsDictionary {
  1: Array<Location>;
  2: Array<Location>;
  3: Array<Location>;
}

interface DataSourcesDictionary {
  1: MatTableDataSource<Location>;
  2: MatTableDataSource<Location>;
  3: MatTableDataSource<Location>;
}

interface PointsAward {
  position: number;
  points: number;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'dd.MM.yyyy',
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd.MM.yyyy',
    monthYearA11yLabel: 'MMM yyyy',
  },
};

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: de },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateCompanyComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  // <---------------------------------- Company Form ---------------------------------->
  public companyDataForm: UntypedFormGroup;

  public groupChallengeTypes: Array<string> = Object.values(
    GroupChallengesTypes
  );

  public NO_GROUP_CHALLENGE = GroupChallengesTypes.NoGroupChallenge;

  // Chip list
  @ViewChild('chipList') chipList: MatChipList;
  public licencesRegex: RegExp = /^\d{1,7}$/; // Limited to 9.999.999 licences -> biggest company in the world has 2.300.000 employees
  public adminEmails: Array<string> = [];
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  public avatarImgUrl = '';

  // <---------------------------------- White label Form ---------------------------------->

  public whiteLabelDataForm: UntypedFormGroup;
  public whiteLabelLogo = null;

  public NEW = 'NEW';
  public EXISTING = 'EXISTING';

  // <---------------------------------- Locations Form ---------------------------------->

  public locationDataForm: UntypedFormGroup;
  public levelsDataForms: DataFormDictionary = {
    1: null,
    2: null,
    3: null,
  };

  public levelsDataSources: DataSourcesDictionary = {
    1: null,
    2: null,
    3: null,
  };

  public levelsLocations: LevelLocationsDictionary = {
    1: [],
    2: [],
    3: [],
  };

  public locationColumns = ['id', 'name', 'edit', 'links', 'delete'];
  public isEditActive = false;
  public editIndex = -1;
  public editLevel = -1;

  public LOCATION_ALREADY_PRESENT = '';

  public allLocations: number;

  public filteredAdminPointDataSource: Array<PointsSource>;
  // <---------------------------------- Personal Challenge Form ---------------------------------->

  public personalChallengeForm: UntypedFormGroup;
  public personalChallengeDateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  public pointsSourcesDataForm: UntypedFormGroup;

  public minDate = new Date();

  public challengeTypesKeys: Array<number> = Object.keys(ChallengeTypes)
    .map((key) => ChallengeTypes[key])
    .filter((value) => typeof value === 'number') as Array<number>;

  public challengeTypesNames: Array<string> = Object.keys(ChallengeTypes)
    .map((key) => ChallengeTypes[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public pointsSourceIds: Array<string> = [];
  public selectablePointsSources: Array<PointsSource> = [];
  public pointsSourceDataSource = new MatTableDataSource();
  public pointsSourceColumns: Array<string> = [
    'id',
    'description',
    'basis',
    'expectedPerformance',
    'type',
    'pointsAwarded',
    'delete',
  ];

  public POINTS_SOURCE_STEPS = PointsSourceType.Steps;
  public POINTS_SOURCE_SYNC = PointsSourceType.Sync;
  public POINTS_SOURCE_DAILY = PointsSourceBasis.Daily;

  // <---------------------------------- Team Challenge Form ---------------------------------->
  public GROUP_CHALLENGE_TYPES_TEAM_CHALLENGE =
    GroupChallengesTypes.TeamChallenge;

  public teamChallengeDurationRegex: RegExp = /^\d{1,2}$/; // Limited to 99 weeks
  public teamChallengeForm: UntypedFormGroup;
  public teamsDataForm: UntypedFormGroup;

  public teamChallengeStartDate = new UntypedFormControl(
    {
      value: null,
      disabled: true,
    },
    [Validators.required]
  );

  public teamChallengeEndDate = new UntypedFormControl(
    {
      value: null,
      disabled: true,
    },
    [Validators.required]
  );

  public teamChallengeTypesNames: Array<string> = Object.keys(
    TeamChallengeTypes
  )
    .map((key) => TeamChallengeTypes[key])
    .filter((value) => typeof value === 'string') as Array<string>;

  public allTeams: Array<TeamSchema> = [];
  public selectableTeams: Array<TeamSchema> = [];
  public challengeTeams: Array<TeamSchema> = [];
  public userIds: Array<string> = [];
  public teamsDataSource = new MatTableDataSource(this.challengeTeams);
  public teamsColumns: Array<string> = ['avatar', 'name', 'delete'];

  public pointsAwardedDataSource = new MatTableDataSource();
  public pointsAwardedColumns: Array<string> = ['position', 'points'];

  public POSITION_ALREADY_PRESENT = '';
  public pointsAwarded: Array<PointsAward> = [];

  public teamAvatarInputIndex: number = null;

  // <---------------------------------- Creation Step ---------------------------------->

  public CREATING_WHITE_LABEL = '';
  public CREATING_COMPANY = '';
  public CREATING_PERSONAL_CHALLENGE = '';
  public CREATING_TEAM_CHALLENGE = '';
  public ERROR_CREATION_COMPANY = '';

  // <---------------------------------- Final Page ---------------------------------->

  public showSpinner = false;
  public spinnerMessage = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private firebaseService: FirebaseService,
    private router: Router,
    private teamsService: TeamsService,
    private matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry.addSvgIcon(
      'trophy-orange',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/trophy-orange.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pencil-outline',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/pencil-outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash-outline',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/trash-outline.svg'
      )
    );
    this.getTranslations();
    // <---------------------------------- Company Form ---------------------------------->
    this.companyDataForm = this.formBuilder.group({
      adminEmail: ['', [Validators.required, Validators.email]],
      availableLicenses: [
        '',
        [Validators.required, Validators.pattern(this.licencesRegex)],
      ],
      avatar: ['assets/img/move-me-new.png'],
      avatarImg: [undefined, Validators.required],
      companyId: ['', Validators.required],
      name: ['', Validators.required],
      featureTeamChallenge: [false],
      // featureMarketplace: [false],
      featureWhiteLabel: [false],
      featureLocations: [false],
      featureProjectManager: [false],
      groupChallengeType: [''],
      managerCompanyIds: [[]],
    });

    // <---------------------------------- White label Form ---------------------------------->
    this.whiteLabelDataForm = this.formBuilder.group({
      mode: [null, Validators.required],
      id: [''],
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(20),
          Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED),
        ]),
      ],
      companyColor: ['#f97a1c', Validators.required],
      whiteLabelLogo: [''],
      whiteLabelLogoFile: [null],
      whiteLabelButtonOption: [false],
      whiteLabelButtonColor: ['#f97a1c'],
      whiteLabelButtonText: [
        '',
        [
          Validators.maxLength(50),
          Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED),
          Validators.required,
        ],
      ],
      whiteLabelButtonLink: [
        '',
        [Validators.pattern(REGEX_LINK), Validators.required],
      ],
    });

    this.whiteLabelDataForm.controls.mode.valueChanges.subscribe(
      (modeValue) => {
        this.whiteLabelLogo = null;
        this.whiteLabelDataForm.patchValue({
          name: '',
          id: '',
          companyColor: '#f97a1c',
          whiteLabelLogo: '',
          whiteLabelLogoFile: null,
          whiteLabelButtonOption: false,
          whiteLabelButtonColor: '#f97a1c',
          whiteLabelButtonText: '',
          whiteLabelButtonLink: '',
        });

        if (modeValue === this.NEW) {
          this.whiteLabelDataForm.controls.whiteLabelLogoFile.setValidators(
            Validators.required
          );
          this.whiteLabelDataForm.controls.whiteLabelLogoFile.updateValueAndValidity();
        } else {
          this.whiteLabelDataForm.controls.whiteLabelLogoFile.clearValidators();
          this.whiteLabelDataForm.controls.whiteLabelLogoFile.updateValueAndValidity();
        }
      }
    );

    this.whiteLabelDataForm.controls.whiteLabelButtonOption.valueChanges.subscribe(
      (buttonOptionValue) => {
        if (buttonOptionValue) {
          this.whiteLabelDataForm.controls.whiteLabelButtonColor.setValidators(
            Validators.required
          );
          this.whiteLabelDataForm.controls.whiteLabelButtonColor.updateValueAndValidity();
          this.whiteLabelDataForm.controls.whiteLabelButtonText.setValidators([
            Validators.maxLength(50),
            Validators.pattern(REGEX_ALPHANUMERIC_EXTENDED),
            Validators.required,
          ]);
          this.whiteLabelDataForm.controls.whiteLabelButtonText.updateValueAndValidity();
          this.whiteLabelDataForm.controls.whiteLabelButtonLink.setValidators([
            Validators.pattern(REGEX_LINK),
            Validators.required,
          ]);
          this.whiteLabelDataForm.controls.whiteLabelButtonLink.updateValueAndValidity();
        } else {
          this.whiteLabelDataForm.controls.whiteLabelButtonColor.clearValidators();
          this.whiteLabelDataForm.controls.whiteLabelButtonColor.updateValueAndValidity();
          this.whiteLabelDataForm.controls.whiteLabelButtonText.clearValidators();
          this.whiteLabelDataForm.controls.whiteLabelButtonText.updateValueAndValidity();
          this.whiteLabelDataForm.controls.whiteLabelButtonLink.clearValidators();
          this.whiteLabelDataForm.controls.whiteLabelButtonLink.updateValueAndValidity();
        }
      }
    );

    this.whiteLabelDataForm.controls.whiteLabelLogoFile.valueChanges.subscribe(
      (newValue) => {
        if (newValue) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(newValue.files[0]);

          fileReader.addEventListener('load', (event) => {
            this.whiteLabelLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
              String(event.target?.result)
            );
          });
        } else {
          this.whiteLabelLogo = null;
        }
      }
    );
    // <---------------------------------- Locations Form ---------------------------------->

    this.locationDataForm = this.formBuilder.group({
      levels: [0, Validators.compose([Validators.min(1), Validators.max(3)])],
    });

    for (const level of [1, 2, 3]) {
      this.levelsDataForms[level] = this.formBuilder.group({
        titleEN: [null, Validators.required],
        titleDE: [null, Validators.required],
        selectorEN: [null, Validators.required],
        selectorDE: [null, Validators.required],
        name: [null, Validators.required],
        id: [{ value: null, disabled: true }, Validators.required],
      });
    }

    // <---------------------------------- Personal Challenge Form ---------------------------------->
    this.personalChallengeForm = this.formBuilder.group({
      schema: [null, Validators.required],
      challengeId: ['', Validators.required],
      name: ['', Validators.required],
      initialPoints: [0, Validators.required],
      type: ['0', Validators.required],
      minLevel: [1, Validators.required],
      maxLevel: [26, Validators.required],
      pointsToLevelUp: [14, Validators.required],
      pointsToHoldLevel: [10, Validators.required],
      initialLevel: [1, Validators.required],
      levelDuration: [7, Validators.required],
      canAscend: [{ value: true, disabled: true }, Validators.required],
      canDescend: [{ value: true, disabled: true }, Validators.required],
      hasFixedDate: [{ value: false, disabled: true }, Validators.required],
    });

    this.personalChallengeForm.controls.schema.valueChanges.subscribe(
      (selectedSchema: PersonalChallengeSchema) => {
        this.personalChallengeForm.patchValue({
          challengeId: selectedSchema.id,
          name: selectedSchema.name,
          initialPoints: selectedSchema.initialPoints,
          type: selectedSchema.type,
          minLevel: selectedSchema.minLevel,
          maxLevel: selectedSchema.maxLevel,
          pointsToLevelUp: selectedSchema.pointsToLevelUp,
          pointsToHoldLevel: selectedSchema.pointsToHoldLevel,
          initialLevel: selectedSchema.initialLevel,
          levelDuration: selectedSchema.levelDuration,
          canAscend: selectedSchema.canAscend,
          canDescend: selectedSchema.canDescend,
          hasFixedDate: selectedSchema.hasFixedDate,
        });

        this.pointsSourceIds = Array.from(selectedSchema.pointsSourceIds);
        this.pointsSourceDataSource = new MatTableDataSource(
          this.adminService.pointsSources.filter((pointSource) =>
            this.pointsSourceIds.includes(pointSource.id)
          )
        );
        this.filteredAdminPointDataSource = this.adminService.pointsSources.filter(
          (pointSource) => this.pointsSourceIds.includes(pointSource.id)
        );
      }
    );

    this.pointsSourcesDataForm = this.formBuilder.group({
      pointsSourceId: ['', Validators.required],
    });

    this.personalChallengeForm.get('type').valueChanges.subscribe((type) => {
      switch (Number(type)) {
        case ChallengeTypes.Walk:
          this.personalChallengeForm.get('canAscend').setValue(true);
          this.personalChallengeForm.get('canDescend').setValue(true);
          this.personalChallengeForm.get('hasFixedDate').setValue(false);
          break;

        case ChallengeTypes.WalkFixedDate:
          this.personalChallengeForm.get('canAscend').setValue(true);
          this.personalChallengeForm.get('canDescend').setValue(true);
          this.personalChallengeForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyDownwards:
          this.personalChallengeForm.get('canAscend').setValue(false);
          this.personalChallengeForm.get('canDescend').setValue(true);
          this.personalChallengeForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyUpwards:
          this.personalChallengeForm.get('canAscend').setValue(true);
          this.personalChallengeForm.get('canDescend').setValue(false);
          this.personalChallengeForm.get('hasFixedDate').setValue(true);
          break;
      }
    });

    // <---------------------------------- Team Challenge Form ---------------------------------->

    this.teamChallengeForm = this.formBuilder.group({
      schema: [null, Validators.required],
      challengeId: ['', Validators.required],
      name: ['', Validators.required],
      duration: [
        0,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern(this.teamChallengeDurationRegex),
        ],
      ],
      participantsPerTeam: [10, Validators.required],
      type: ['0', Validators.required],
    });

    this.teamChallengeForm.controls.duration.valueChanges.subscribe(
      (duration: number) => {
        if (duration > 0) {
          this.teamChallengeStartDate.enable();
        } else {
          this.teamChallengeStartDate.disable();
        }
      }
    );

    this.teamChallengeStartDate.valueChanges.subscribe(
      (dateStartValue: Date) => {
        const challengeDuration = this.teamChallengeForm.controls.duration
          .value;
        if (dateStartValue !== null && challengeDuration > 0) {
          const endDate = new Date(dateStartValue.getTime());
          endDate.setDate(endDate.getDate() + 7 * challengeDuration - 1);
          this.teamChallengeEndDate.setValue(endDate);
        }
      }
    );

    this.teamsDataForm = this.formBuilder.group({
      teamsAmount: [
        0,
        [Validators.required, Validators.min(1), Validators.max(50)],
      ],
      avatarFileInput: [''],
      teamsFormsArray: this.formBuilder.array([]),
    });

    this.teamChallengeForm.controls.schema.valueChanges.subscribe(
      (selectedSchema: TeamChallengeSchema) => {
        this.teamChallengeForm.patchValue({
          challengeId: selectedSchema.id,
          name: selectedSchema.name,
          participantsPerTeam: selectedSchema.participantsPerTeam,
          type: selectedSchema.type,
        });
        this.challengeTeams = this.adminService.teams.filter((team) =>
          selectedSchema.teamIds.has(team.id)
        );
        this.teamsDataForm.controls.teamsAmount.setValue(
          this.challengeTeams.length
        );
        this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
        this.pointsAwarded = Array.from(
          selectedSchema.pointsAwarded,
          ([position, points]) => ({ position, points })
        ).map((element) => {
          const pointAward: PointsAward = {
            points: element.points,
            position: Number(element.position),
          };
          return pointAward;
        });
        this.pointsAwardedDataSource = new MatTableDataSource(
          this.pointsAwarded
        );
        this.refreshTeamsAmount();
      }
    );

    this.teamsDataForm.controls.avatarFileInput.valueChanges.subscribe(
      (newValue) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(newValue.files[0]);

        fileReader.addEventListener('load', (event) => {
          const avatarImg = this.sanitizer.bypassSecurityTrustResourceUrl(
            String(event.target?.result)
          );
          this.teamsFormsArray.at(this.teamAvatarInputIndex).patchValue({
            avatarImg,
            fileInput: newValue,
          });
        });
      }
    );
  }

  get personalChallengeSchemas(): Array<PersonalChallengeSchema> {
    return this.adminService.personalChallenges;
  }
  get teamChallengeSchemas(): Array<TeamChallengeSchema> {
    return this.adminService.teamChallenges;
  }
  get teamsFormsArray(): UntypedFormArray {
    return this.teamsDataForm.get('teamsFormsArray') as UntypedFormArray;
  }
  get managerCompanies(): Array<Company> {
    return this.adminService.managerCompanies;
  }
  ngOnInit(): void {
    for (const level of [1, 2, 3]) {
      this.levelsDataForms[level].controls.name.valueChanges.subscribe(
        (newValue) => {
          if (newValue) {
            this.levelsDataForms[level].controls.id.setValue(
              newValue.toUpperCase().replace(/\//, '')
            );
          }
        }
      );
    }

    this.adminService
      .getPointsSources()
      .then((pointSources) => {
        this.selectablePointsSources = [...pointSources];
      })
      .catch((error) => {
        console.log('adminService.getPointsSources - error: ', error);
      });

    this.allTeams = [...this.adminService.teams];
    this.selectableTeams = [...this.adminService.teams].filter(
      (team) =>
        this.challengeTeams.findIndex(
          (challengeTeam) => challengeTeam.id === team.id
        ) === -1
    );
  }

  public returnPage(): void {
    this.router.navigate(['admin'], { state: { tab: AdminTabs.Companies } });
  }

  public getTranslations(): void {
    this.translateService
      .stream([
        'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.LOCATION_ALREADY_PRESENT',
        'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.POSITION_ALREADY_PRESENT',
        'CREATE_COMPANY.CREATION_STEP.CREATING_WHITE_LABEL',
        'CREATE_COMPANY.CREATION_STEP.CREATING_COMPANY',
        'CREATE_COMPANY.CREATION_STEP.CREATING_PERSONAL_CHALLENGE',
        'CREATE_COMPANY.CREATION_STEP.CREATING_TEAM_CHALLENGE',
        'CREATE_COMPANY.CREATION_STEP.ERROR_CREATION_COMPANY',
      ])
      .subscribe((values) => {
        this.LOCATION_ALREADY_PRESENT =
          values[
            'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.LOCATION_ALREADY_PRESENT'
          ];
        this.POSITION_ALREADY_PRESENT =
          values[
            'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.POSITION_ALREADY_PRESENT'
          ];
        this.CREATING_WHITE_LABEL =
          values['CREATE_COMPANY.CREATION_STEP.CREATING_WHITE_LABEL'];
        this.CREATING_COMPANY =
          values['CREATE_COMPANY.CREATION_STEP.CREATING_COMPANY'];
        this.CREATING_PERSONAL_CHALLENGE =
          values['CREATE_COMPANY.CREATION_STEP.CREATING_PERSONAL_CHALLENGE'];
        this.CREATING_TEAM_CHALLENGE =
          values['CREATE_COMPANY.CREATION_STEP.CREATING_TEAM_CHALLENGE'];
        this.ERROR_CREATION_COMPANY =
          values['CREATE_COMPANY.CREATION_STEP.ERROR_CREATION_COMPANY'];
      });
  }

  // <---------------------------------- Company Form ---------------------------------->

  get adminEmail(): AbstractControl {
    return this.companyDataForm.get('adminEmail');
  }

  get availableLicenses(): AbstractControl {
    return this.companyDataForm.get('availableLicenses');
  }

  public removeAdminEmail(email: string): void {
    const index = this.adminEmails.indexOf(email);

    if (index >= 0) {
      this.adminEmails.splice(index, 1);
    }

    if (index === 0) {
      this.adminEmail.setValue('');
    }
  }

  get name(): string {
    return this.companyDataForm.get('name').value;
  }

  get companyLogo(): string {
    return this.companyDataForm.get('avatarImg').value;
  }
  get companyLicenses(): number {
    return this.companyDataForm.get('availableLicenses').value;
  }
  get adminEmailAmount(): string {
    return this.companyDataForm.get('adminEmail').value;
  }

  get managerCompanyIds(): string {
    return this.companyDataForm.get('managerCompanyIds').value;
  }

  get levelsAmount(): number {
    return this.locationDataForm.get('levels').value;
  }

  get personalChallengeName(): string {
    return this.personalChallengeForm.get('name').value;
  }

  get pointsToLevelUpPersonalChallenge(): number {
    return this.personalChallengeForm.get('pointsToLevelUp').value;
  }

  get pointsToHoldLevelPersonalChallenge(): number {
    return this.personalChallengeForm.get('pointsToHoldLevel').value;
  }

  get personalChallengeMinLevel(): AbstractControl {
    return this.personalChallengeForm.get('minLevel');
  }

  get personalChallengeMaxLevel(): AbstractControl {
    return this.personalChallengeForm.get('maxLevel');
  }

  get personalChallengeInitialPoints(): number {
    return this.personalChallengeForm.get('initialPoints').value;
  }

  get personalChallengeType(): string {
    return this.personalChallengeForm.get('type').value;
  }

  get teamlChallengeName(): string {
    return this.teamChallengeForm.get('name').value;
  }

  get teamChallengeTotalDuration(): number {
    return this.teamChallengeForm.get('duration').value;
  }

  public getImgLogoUrl(): void {
    const avatarFile = this.companyDataForm.controls.avatarImg.value.files[0];
    const reader = new FileReader();
    reader.onload = (): void => {
      this.avatarImgUrl = reader.result as string;
    };
    reader.readAsDataURL(avatarFile);
  }

  public addAdminEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const input = event.input;
    const isValid = this.adminEmail.valid;

    // Add admin email
    if (value && isValid) {
      const email: string = event.value;
      this.adminEmails.push(email.toLowerCase().trim());
      this.adminEmails.slice(1);
      if (input) {
        input.value = '';
      }
    }

    if (this.adminEmail.invalid && this.adminEmail.dirty) {
      this.chipList.errorState = true;
    } else {
      this.chipList.errorState = false;
    }
  }

  public onFeatureProjectManagerChange(): void {
    if (this.companyDataForm.controls.featureProjectManager.value) {
      this.companyDataForm.controls.featureWhiteLabel.setValue(false);
      this.companyDataForm.controls.featureWhiteLabel.disable();
      this.companyDataForm.controls.featureLocations.setValue(false);
      this.companyDataForm.controls.featureLocations.disable();
      this.companyDataForm.controls.groupChallengeType.setValue(
        this.NO_GROUP_CHALLENGE
      );
      this.companyDataForm.controls.groupChallengeType.disable();
      this.companyDataForm.controls.managerCompanyIds.disable();
      this.companyDataForm.controls.managerCompanyIds.setValue([]);
    } else {
      this.companyDataForm.controls.managerCompanyIds.enable();
      this.companyDataForm.controls.groupChallengeType.enable();
      this.companyDataForm.controls.featureWhiteLabel.enable();
      this.companyDataForm.controls.featureLocations.enable();
    }
  }

  // <---------------------------------- White label Form ---------------------------------->

  get whiteLabels(): Array<WhiteLabel> {
    return this.adminService.whiteLabels;
  }

  public openColorPicker(colorPickerId: string): void {
    const colorPicker = document.getElementById(colorPickerId);
    colorPicker.click();
  }

  public updateWhiteLabelValues(event: MatSelectChange): void {
    const selectedWhiteLabel = event.value;
    this.whiteLabelDataForm.controls.id.setValue(selectedWhiteLabel.id);
    this.whiteLabelDataForm.controls.name.setValue(selectedWhiteLabel.name);
    this.whiteLabelDataForm.controls.whiteLabelLogo.setValue(
      selectedWhiteLabel.logo
    );
    this.whiteLabelDataForm.controls.companyColor.setValue(
      selectedWhiteLabel.color
    );

    this.whiteLabelDataForm.controls.whiteLabelButtonOption.setValue(
      Boolean(selectedWhiteLabel.button)
    );

    if (this.whiteLabelDataForm.controls.whiteLabelButtonOption.value) {
      this.whiteLabelDataForm.controls.whiteLabelButtonColor.setValue(
        selectedWhiteLabel.button.color
      );
      this.whiteLabelDataForm.controls.whiteLabelButtonText.setValue(
        selectedWhiteLabel.button.text
      );
      this.whiteLabelDataForm.controls.whiteLabelButtonLink.setValue(
        selectedWhiteLabel.button.link
      );
    } else {
      this.whiteLabelDataForm.controls.whiteLabelButtonColor.setValue(null);
      this.whiteLabelDataForm.controls.whiteLabelButtonText.setValue(null);
      this.whiteLabelDataForm.controls.whiteLabelButtonLink.setValue(null);
    }
  }

  public onChangeCompanyColor(companyColor: string): void {
    this.whiteLabelDataForm.patchValue({ companyColor });
  }

  public onChangeWhiteLabelButtonColor(whiteLabelButtonColor: string): void {
    this.whiteLabelDataForm.patchValue({ whiteLabelButtonColor });
  }

  // <---------------------------------- Location Form ---------------------------------->

  public addLocationToLevel(level: number): void {
    const dataForm = this.levelsDataForms[level];

    const location = new Location({
      id: dataForm.controls.id.value,
      name: dataForm.controls.name.value,
      hierarchyLevel: level,
    });

    const allLocations = [].concat(...Object.values(this.levelsLocations));
    const sameNameIndex = allLocations.findIndex(
      (element) => element.name === location.name
    );

    if (sameNameIndex === -1) {
      if (!this.isEditActive) {
        this.levelsLocations[level].push(location);
      } else {
        this.levelsLocations[level].splice(this.editIndex, 1, location);
        this.isEditActive = false;
      }
      dataForm.controls.id.setValue(null);
      dataForm.controls.name.setValue(null);
      dataForm.controls.id.markAsUntouched();
      dataForm.controls.name.markAsUntouched();
      dataForm.controls.id.markAsPristine();
      dataForm.controls.name.markAsPristine();
      dataForm.controls.id.setErrors(null);
      dataForm.controls.name.setErrors(null);
      console.log('dataForm.controls: ', dataForm.controls);
      this.levelsDataSources[level] = new MatTableDataSource(
        this.levelsLocations[level]
      );
    } else {
      this.snackBar.open(this.LOCATION_ALREADY_PRESENT, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
    }
  }

  public editLocation(locationId: string, level: number): void {
    if (this.isEditActive) {
      this.cancelEdit(this.editLevel);
    }
    this.isEditActive = true;
    const editElement = this.levelsLocations[level].find(
      (element) => element.id === locationId
    );
    const editIndex = this.levelsLocations[level].findIndex(
      (element) => element.id === locationId
    );
    this.levelsDataForms[level].controls.id.setValue(editElement.id);
    this.levelsDataForms[level].controls.name.setValue(editElement.name);
    this.editIndex = editIndex;
    this.editLevel = level;
  }

  public removeLocation(locationId: string, level: number): void {
    const removeIndex = this.levelsLocations[level].findIndex(
      (element) => element.id === locationId
    );
    this.levelsLocations[level].splice(removeIndex, 1);
    this.levelsDataSources[level] = new MatTableDataSource(
      this.levelsLocations[level]
    );
  }

  public cancelEdit(level: number): void {
    this.isEditActive = false;
    this.levelsDataForms[level].controls.id.setValue(null);
    this.levelsDataForms[level].controls.id.markAsUntouched();
    this.levelsDataForms[level].controls.id.markAsPristine();
    this.levelsDataForms[level].controls.id.setErrors(null);
    this.levelsDataForms[level].controls.name.setValue(null);
    this.levelsDataForms[level].controls.name.markAsUntouched();
    this.levelsDataForms[level].controls.name.markAsPristine();
    this.levelsDataForms[level].controls.name.setErrors(null);
  }

  public linkLocation(location: Location, level: number): void {
    const locationList = this.levelsLocations[level + 1];

    if (level !== this.locationDataForm.value.levels) {
      this.dialog.open(LinkLocationsDialogComponent, {
        width: '400px',
        data: {
          location,
          locationList,
        },
      });
    }
  }

  public mapFormToLocations(
    locationDataForm: UntypedFormGroup,
    locationsOptions: Array<Location>
  ): Locations {
    const title = new Map<string, string>();
    title.set('en', locationDataForm.value.titleEN);
    title.set('de', locationDataForm.value.titleDE);

    const selector = new Map<string, string>();
    selector.set('en', locationDataForm.value.selectorEN);
    selector.set('de', locationDataForm.value.selectorDE);

    const optionNames = locationsOptions.map((location) => location.name);

    return new Locations({
      title,
      selector,
      optionNames,
    });
  }

  public saveLocationsAmount(): void {
    this.allLocations =
      this.levelsLocations[1].length +
      this.levelsLocations[2].length +
      this.levelsLocations[3].length;
  }

  // <---------------------------------- Personal Challenge Form ---------------------------------->
  public addPointsSource(): void {
    this.pointsSourceIds.push(
      this.pointsSourcesDataForm.controls.pointsSourceId.value
    );

    this.selectablePointsSources.splice(
      this.selectablePointsSources.findIndex(
        (pointsSources) =>
          pointsSources.id ===
          this.pointsSourcesDataForm.controls.pointsSourceId.value
      ),
      1
    );
    this.pointsSourcesDataForm.controls.pointsSourceId.setValue('');
    this.pointsSourcesDataForm.controls.pointsSourceId.markAsUntouched();
  }

  public removePointsSource(pointsSourceId: string): void {
    this.pointsSourceIds.splice(
      this.pointsSourceIds.findIndex(
        (pointsSources) => pointsSources === pointsSourceId
      ),
      1
    );
  }

  // <---------------------------------- Team Challenge Form ---------------------------------->

  public allowOnlyTuesdaysAndThursdays(d: Date | null): boolean {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 1 && day !== 3 && day !== 5 && day !== 6;
  }

  public refreshTeamsAmount(): void {
    this.challengeTeams = [];
    this.teamsFormsArray.clear();
    const randomInitialTeamIndex = Math.floor(
      Math.random() *
        (this.allTeams.length - this.teamsDataForm.controls.teamsAmount.value)
    );
    this.challengeTeams.push(
      ...this.allTeams.slice(
        randomInitialTeamIndex,
        randomInitialTeamIndex + this.teamsDataForm.controls.teamsAmount.value
      )
    );
    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
    for (const teamSchema of this.challengeTeams) {
      this.teamsFormsArray.push(
        this.formBuilder.group({
          avatar: [teamSchema.avatar, Validators.required],
          avatarImg: [teamSchema.avatar],
          fileInput: [null],
          name: [teamSchema.name, Validators.required],
        })
      );
    }
    this.recalculatePointsAwarded();
  }

  public editTeamAvatar(
    index: number,
    teamAvatarInput: FileInputComponent
  ): void {
    this.teamAvatarInputIndex = index;
    teamAvatarInput.open();
  }

  public removeTeam(index: number): void {
    const removedTeam = this.challengeTeams.splice(index, 1)[0];
    this.teamsDataForm.patchValue({
      teamsAmount: this.challengeTeams.length,
    });
    this.teamsFormsArray.removeAt(index);
    this.selectableTeams.push(
      this.allTeams.find((team) => team.id === removedTeam.id)
    );
    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
    this.recalculatePointsAwarded();
  }

  private recalculatePointsAwarded(): void {
    this.pointsAwarded = this.challengeTeams.map((team, teamIndex, teams) => {
      const pointAward: PointsAward = {
        position: teamIndex + 1,
        points: teams.length - teamIndex,
      };
      return pointAward;
    });
    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
  }

  public addTeam(): void {
    const randomInitialTeamIndex = Math.floor(
      Math.random() * this.allTeams.length
    );
    const teamSchema = this.allTeams.slice(
      randomInitialTeamIndex,
      randomInitialTeamIndex + 1
    )[0];
    this.challengeTeams.push(teamSchema);
    this.teamsDataForm.patchValue({
      teamsAmount: this.challengeTeams.length,
    });
    this.teamsFormsArray.push(
      this.formBuilder.group({
        avatar: [teamSchema.avatar, Validators.required],
        avatarImg: [teamSchema.avatar],
        fileInput: [null],
        name: [teamSchema.name, Validators.required],
      })
    );
    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
    this.recalculatePointsAwarded();
  }

  // <---------------------------------- Create Company ---------------------------------->

  public async createCompany(): Promise<void> {
    this.showSpinner = true;

    try {
      this.stepper.steps.forEach((step) => {
        step.editable = false;
      });
      if (this.whiteLabelDataForm.controls.mode.value === this.NEW) {
        this.spinnerMessage = this.CREATING_WHITE_LABEL;
        await this.addWhiteLabel();
      }

      this.spinnerMessage = this.CREATING_COMPANY;
      await this.addCompany();

      if (!this.companyDataForm.controls.featureProjectManager.value) {
        this.spinnerMessage = this.CREATING_PERSONAL_CHALLENGE;
        await this.addPersonalChallenge();
      }

      switch (this.companyDataForm.controls.groupChallengeType.value) {
        case GroupChallengesTypes.TeamChallenge:
          this.spinnerMessage = this.CREATING_TEAM_CHALLENGE;
          await this.addTeamChallenge();
          break;
      }
      this.spinnerMessage = null;
    } catch (error) {
      console.log('error during creation of company: ', error);
      this.stepper.steps.forEach((step) => {
        step.editable = true;
      });
      this.spinnerMessage = this.ERROR_CREATION_COMPANY + error;
    }

    this.showSpinner = false;
  }

  public async addWhiteLabel(): Promise<void> {
    if (this.whiteLabelDataForm.valid) {
      let button: WhiteLabelButton = null;
      if (this.whiteLabelDataForm.controls.whiteLabelButtonOption.value) {
        button = new WhiteLabelButton({
          color: this.whiteLabelDataForm.controls.whiteLabelButtonColor.value,
          link: this.whiteLabelDataForm.controls.whiteLabelButtonLink.value,
          text: this.whiteLabelDataForm.controls.whiteLabelButtonText.value,
        });
      }
      const whiteLabel = new WhiteLabel({
        name: this.whiteLabelDataForm.controls.name.value,
        color: this.whiteLabelDataForm.controls.companyColor.value,
        button,
      });

      const whiteLabelLogoFile = this.whiteLabelDataForm.controls
        .whiteLabelLogoFile.value.files[0];

      try {
        await this.firebaseService
          .addWhiteLabel(whiteLabel)
          .then(async (whiteLabelId) => {
            await this.firebaseService
              .uploadImage(
                whiteLabelLogoFile,
                `whiteLabels/${whiteLabelId}/logo`
              )
              .then(async (snapshot) => {
                const url = await snapshot.ref.getDownloadURL();
                whiteLabel.id = whiteLabelId;
                whiteLabel.logo = url;
                this.whiteLabelDataForm.controls.whiteLabelLogo.setValue(url);
                this.whiteLabelDataForm.controls.id.setValue(whiteLabelId);
                await this.firebaseService.updateWhiteLabel(whiteLabel);
              });
          });
      } catch (error) {
        console.log('error while creating white label - error: ', error);
        return Promise.reject(error);
      }
    }
  }

  public async addCompany(): Promise<void> {
    if (this.companyDataForm.valid) {
      const companyId = this.companyDataForm.controls.companyId.value;

      if (this.companyDataForm.controls.avatarImg.value) {
        const avatarFile = this.companyDataForm.controls.avatarImg.value
          .files[0];

        await this.firebaseService
          .uploadImage(avatarFile, 'companies/' + companyId + '/avatar')
          .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            this.companyDataForm.controls.avatar.setValue(url);
          });
      }

      const features = new Set<Features>();
      const locationsInfoArray = [];
      const locationsArray = [];
      let whiteLabel = null;

      if (this.companyDataForm.controls.featureWhiteLabel.value) {
        features.add(Features.WhiteLabel);
        whiteLabel = new WhiteLabelDetails({
          id: this.whiteLabelDataForm.controls.id.value,
          logo: this.whiteLabelDataForm.controls.whiteLabelLogo.value,
          buttonColor: this.whiteLabelDataForm.controls.whiteLabelButtonColor
            .value,
          buttonText: this.whiteLabelDataForm.controls.whiteLabelButtonText
            .value,
          buttonLink: this.whiteLabelDataForm.controls.whiteLabelButtonLink
            .value,
          color: this.whiteLabelDataForm.controls.companyColor.value,
        });
      }

      if (this.companyDataForm.controls.featureTeamChallenge.value) {
        features.add(Features.TeamChallenge);
      }

      // if (this.companyDataForm.controls.featureMarketplace.value) {
      //   features.add(Features.Marketplace);
      // }

      if (this.companyDataForm.controls.featureLocations.value) {
        features.add(Features.Locations);

        for (
          let levelIndex = 1;
          levelIndex <= this.locationDataForm.value.levels;
          levelIndex++
        ) {
          const locationsInfo = this.mapFormToLocations(
            this.levelsDataForms[levelIndex],
            this.levelsLocations[levelIndex]
          );
          locationsInfoArray.push(locationsInfo);
        }

        for (
          let levelIndex = 1;
          levelIndex <= this.locationDataForm.value.levels;
          levelIndex++
        ) {
          for (const location of this.levelsLocations[levelIndex]) {
            if (
              location.childLocations.optionNames.length > 0 &&
              locationsInfoArray[levelIndex]
            ) {
              location.childLocations.title =
                locationsInfoArray[levelIndex].title;
              location.childLocations.selector =
                locationsInfoArray[levelIndex].selector;
            } else {
              location.childLocations = null;
            }
            locationsArray.push(location);
          }
        }
      }

      if (this.companyDataForm.controls.featureProjectManager.value) {
        features.add(Features.ProjectManager);
      }

      const managerCompanyIds = this.companyDataForm.get('managerCompanyIds')
        .value;
      const company = new Company({
        avatar: this.companyDataForm.controls.avatar.value,
        id: this.companyDataForm.controls.companyId.value,
        name: this.companyDataForm.controls.name.value,
        color: this.whiteLabelDataForm.controls.companyColor.value,
        users: [],
        completedChallenges: [],
        availableLicenses: this.companyDataForm.controls.availableLicenses
          .value,
        licence: LicenceTypes.Complete,
        teamIds: [],
        currentTeamChallenges: [],
        features: Array.from(features),
        adminEmail: this.adminEmails,
        whiteLabel,
        locationsInfo: locationsInfoArray[0],
        locationLevels: this.locationDataForm.value.levels,
        managerCompanyIds,
      });

      await this.firebaseService.createCompany(
        this.companyDataForm.controls.companyId.value,
        company
      );

      if (company.features.has(Features.Locations)) {
        this.firebaseService.createLocations(company.id, locationsArray);
      }
    }
  }

  public async addPersonalChallenge(): Promise<void> {
    if (this.personalChallengeForm.valid) {
      const challengeId = this.personalChallengeForm.controls.challengeId.value;
      const companyId = this.companyDataForm.controls.companyId.value;

      const pointsSourceIds = new Set(this.pointsSourceIds);
      const pointSources = this.selectablePointsSources.filter((pointSource) =>
        this.pointsSourceIds.includes(pointSource.id)
      );

      // TODO: add try catch structures when connecting to firebase
      await this.adminService.createCompanyPointSources(
        companyId,
        pointSources
      );

      const endDate = this.personalChallengeForm.controls.hasFixedDate.value
        ? new Date(this.personalChallengeDateRange.value.end)
        : null;

      if (endDate !== null) {
        endDate.setHours(23, 59, 59);
      }

      const challengeSchema = new ChallengeSchema().deserialize({
        id: challengeId,
        initialPoints: this.personalChallengeForm.controls.initialPoints.value,
        name: this.personalChallengeForm.controls.name.value,
        type: Number(this.personalChallengeForm.controls.type.value),
        maxLevel: this.personalChallengeForm.controls.maxLevel.value,
        minLevel: this.personalChallengeForm.controls.minLevel.value,
        pointsToLevelUp: this.personalChallengeForm.controls.pointsToLevelUp
          .value,
        pointsToHoldLevel: this.personalChallengeForm.controls.pointsToHoldLevel
          .value,
        initialLevel: this.personalChallengeForm.controls.initialLevel.value,
        pointsSourceIds,
        // rewards,
        levelDuration: this.personalChallengeForm.controls.levelDuration.value,
        canAscend: this.personalChallengeForm.controls.canAscend.value,
        canDescend: this.personalChallengeForm.controls.canDescend.value,
        hasFixedDate: this.personalChallengeForm.controls.hasFixedDate.value,
        startDate: this.personalChallengeForm.controls.hasFixedDate.value
          ? new Date(this.personalChallengeDateRange.value.start).getTime()
          : null,
        endDate: endDate !== null ? endDate.getTime() : null,
      });

      await this.firebaseService.createPersonalChallenge(
        companyId,
        challengeSchema,
        challengeId
      );
    }
  }

  public async addTeamChallenge(): Promise<void> {
    if (this.teamChallengeForm.valid) {
      const challengeId = this.teamChallengeForm.controls.challengeId.value;
      const companyId = this.companyDataForm.controls.companyId.value;

      const pointsAwarded = {};
      this.pointsAwarded.forEach((pointsAward) => {
        pointsAwarded[pointsAward.position] = pointsAward.points;
      });

      const teamIds = new Set(
        this.challengeTeams.map(
          (team, index) => companyId + String(index + 1).padStart(2, '0')
        )
      );
      const userIds = new Set(this.userIds);

      const teamChallenge = new TeamChallenge({
        id: challengeId,
        name: this.teamChallengeForm.controls.name.value,
        type: this.teamChallengeForm.controls.type.value,
        participantsPerTeam: this.teamChallengeForm.controls.participantsPerTeam
          .value,
        duration: this.teamChallengeForm.controls.duration.value,
        pointsAwarded,
        teamIds,
        userIds,
        currentWeek: 1,
        startDate: new Date(this.teamChallengeStartDate.value).getTime(),
        endDate: new Date(this.teamChallengeEndDate.value).setHours(23, 59, 59),
      });

      const teams = this.challengeTeams.map(
        (teamSchema) => new Team(teamSchema)
      );

      for (const [index, team] of teams.entries()) {
        const fileInput = this.teamsFormsArray.controls[index].value.fileInput;
        team.id = companyId + String(index + 1).padStart(2, '0');
        team.name = this.teamsFormsArray.value[index].name;
        if (fileInput) {
          const teamAvatarImagePath = COMPANY_TEAM_AVATAR_IMAGE_PATH.replace(
            'COMPANY_ID',
            companyId
          ).replace('TEAM_ID', team.id);

          const file = fileInput.files[0];

          await this.firebaseService
            .uploadImage(file, teamAvatarImagePath)
            .then(async (snapshot) => {
              const url = await snapshot.ref.getDownloadURL();
              team.avatar = url;
            })
            .catch((error) => {
              console.log('error uploading team avatar: ', error);

              this.snackBar.open(
                'Error uploading team avatar' + ': ' + error,
                'Ok',
                {
                  duration: 4000,
                  panelClass: 'snack-bar-color',
                }
              );
            });
        }
      }

      // TODO: add try catch structures when connecting to firebase
      await this.teamsService.createCompanyTeams(companyId, teams);

      await this.firebaseService.createTeamChallenge(
        companyId,
        teamChallenge,
        challengeId
      );
    }
  }

  // <---------------------------------- Congrats Page ---------------------------------->

  public returnToAdminPage(): void {
    this.router.navigate(['admin']);
  }
}
