import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Helpers } from 'src/app/helpers/helpers';

export interface IUserRankingItem {
  avatar: string;
  nickname: string;
  performance: number;
}

export interface IUserRanking {
  title: string;
  subtitle?: string;
  ranking: Array<IUserRankingItem>;
  badgeIcons: Array<string>;
  emptyRankingMessage: string;
}
@Component({
  selector: 'app-user-ranking',
  templateUrl: './user-ranking.component.html',
  styleUrls: ['./user-ranking.component.scss'],
})
export class UserRankingComponent implements AfterViewInit {
  @Input() userRanking: IUserRanking;
  @ViewChild('rankingCardContent', { static: true }) contentElement: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    Helpers.calculateCardContentHeight(this.contentElement, 16);
  }

  public setDefaultImagePath(userAvatar: HTMLImageElement): void {
    Helpers.setDefaultImagePath(userAvatar);
  }
}
