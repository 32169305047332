export class ConfirmationDialogData {
  public title: string;
  public message: string;
  public confirmLabel: string;
  public cancelLabel: string;
  public action: () => Promise<void>;
  public showSpinner?: boolean;

  constructor({
    title = null,
    message = null,
    action = null,
    confirmLabel = null,
    cancelLabel = null,
    showSpinner = false,
  }) {
    this.title = title;
    this.message = message;
    this.action = action;
    this.confirmLabel = confirmLabel;
    this.cancelLabel = cancelLabel;
    this.showSpinner = showSpinner;
  }
}
