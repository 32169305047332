import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';

// Models
import { Company } from 'src/app/models/company.model';
import { Training } from 'src/app/models/training.model';
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { TeamFinalStandings } from 'src/app/models/team-final-standings.model';
import { User } from 'src/app/models/user.model';
import { PointsSource } from 'src/app/models/points-source.model';
import { TrainingData } from 'src/app/models/training-data.model';
import { Location } from 'src/app/models/location.model';
import { Features } from 'src/app/models/features.enum';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LocalstorageService } from '../localstorage/localstorage.service';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';
import { TeamsService } from '../teams/teams.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { StatisticsBasis, StatisticsType } from 'src/app/constants/constants';
import { Team } from 'src/app/models/team.model';
import {
  CitiesChallenge,
  ICitiesChallengeInitializer,
} from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';

export interface DistanceChallengeData {
  realCompanyKilometers: number;
  adjustedCompanyKilometers: number;
  accumulatedDistance: Array<number>;
  segmentIndex: number;
  totalProgress: number;
  stageProgress: number;
  expectedProgress: number;
  averageSteps: number;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public company: Company;

  public trainings: Array<Training> = [];

  public todayAverage = 0;
  public todaySteps = 0;
  public totalSteps = 0;
  public averageTotalSteps = 0;
  public users: Array<User> = [];
  public locations: Array<Location> = [];
  private isCompanyDataReady = false;

  public usersWhoCompletedAChallenge = 0;
  public currentMonthChallenges = 0;

  private unsubscribeFromCompanyDailyRanking: () => void;
  private unsubscribeFromCompanyHistoricalData: () => void;

  constructor(
    private firebaseService: FirebaseService,
    private localstorageService: LocalstorageService,
    private subscriptionService: SubscriptionService,
    private teamsService: TeamsService
  ) {
    this.subscriptionService.company.subscribe((company) => {
      if (company) {
        this.company = company;
        if (!this.isCompanyDataReady) {
          this.prepareCompanyData(this.company.id);
        }
      } else {
        this.isCompanyDataReady = false;
      }
    });
  }

  public async getStepsByDateRange(
    startDate: string,
    endDate: string
  ): Promise<Array<Training>> {
    const daysArray = Helpers.generateDaysArray(startDate, endDate);
    return this.firebaseService
      .getStatisticsArray(
        this.company.id,
        StatisticsType.Trainings,
        StatisticsBasis.Daily,
        daysArray
      )
      .then(
        (
          trainingDocs: Array<
            firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
          >
        ) => {
          const trainings = trainingDocs
            .map((trainingSnapshot) => {
              if (trainingSnapshot.exists) {
                return new Training(
                  trainingSnapshot.id,
                  Object.values(trainingSnapshot.data())
                );
              }
              return null;
            })
            .filter((element) => Boolean(element));
          return trainings;
        }
      )
      .catch((error) => {
        console.log('getStatisticsArray - error: ', error);
        return Promise.reject(error);
      });
  }

  public removeCompany(): void {
    this.company = null;
    this.unsubscribeFromCompanyDailyRanking();
    this.unsubscribeFromCompanyHistoricalData();
    this.subscriptionService.unsubscribeFromCompany();
    this.subscriptionService.teamsUnsubscribe();
    this.localstorageService.setCompanyId(null);
    this.isCompanyDataReady = false;
  }

  public async prepareCompanyData(companyId: string): Promise<void> {
    if (this.company.features.has(Features.Locations)) {
      this.firebaseService
        .getCompanyLocations(companyId)
        .then((companyLocationsQuery) => {
          this.locations = companyLocationsQuery.docs.map(
            (locationDocSnapshot) => new Location(locationDocSnapshot.data())
          );
        });
    }
    if (this.company.features.has(Features.TeamChallenge)) {
      this.subscriptionService.subscribeToTeams(this.company.id);
    }

    this.usersWhoCompletedAChallenge = new Set(
      this.company.completedChallenges.map(
        (completedChallenge) => completedChallenge.userId
      )
    ).size;
    this.currentMonthChallenges = this.company.completedChallenges.filter(
      (completedChallenge) =>
        new Date(completedChallenge.date).getMonth() === new Date().getMonth()
    ).length;

    // TODAY STEPS - SUBSCRIPTION
    this.subscribeToCompanyDailyRanking(
      companyId,
      Helpers.formatDateYYYYMMDD(new Date())
    );

    // TOTAL STEPS - SUBSCRIPTION
    this.subscribeToHistoricalData(companyId);

    this.isCompanyDataReady = true;
  }

  public subscribeToCompanyDailyRanking(companyId: string, day: string): void {
    if (this.unsubscribeFromCompanyDailyRanking !== undefined) {
      this.unsubscribeFromCompanyDailyRanking();
    }
    this.unsubscribeFromCompanyDailyRanking = this.subscriptionToCompanyDailyRanking(
      companyId,
      day
    );
  }

  private subscriptionToCompanyDailyRanking(
    companyId: string,
    day: string
  ): () => void {
    return this.firebaseService.subscribeToStatistics(
      companyId,
      StatisticsType.Trainings,
      StatisticsBasis.Daily,
      day,
      {
        next: (
          snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
        ) => {
          if (day !== Helpers.formatDateYYYYMMDD(new Date())) {
            this.subscribeToCompanyDailyRanking(
              companyId,
              Helpers.formatDateYYYYMMDD(new Date())
            );
            return;
          }

          if (snapshot.exists) {
            const todayTrainings = Object.values(snapshot.data())
              .map((element) => new TrainingData(element))
              .sort((a, b) => b.steps - a.steps);

            this.todaySteps = todayTrainings.reduce(
              (accumulator, currentTraining) =>
                accumulator + currentTraining.steps,
              0
            );

            this.todayAverage = Math.round(
              this.todaySteps / todayTrainings.length
            );
          } else {
            this.todaySteps = 0;
          }
        },
        error: (error: firebase.firestore.FirestoreError) => {
          console.log('subscriptionToCompanyDailyRanking - error: ', error);
          throw new Error(
            error + ' > company.service - subscriptionToCompanyDailyRanking'
          );
        },
      }
    );
  }

  public subscribeToHistoricalData(companyId: string): void {
    if (this.unsubscribeFromCompanyHistoricalData !== undefined) {
      this.unsubscribeFromCompanyHistoricalData();
    }
    this.unsubscribeFromCompanyHistoricalData = this.subscriptionToCompanyHistoricalData(
      companyId
    );
  }

  private subscriptionToCompanyHistoricalData(companyId: string): () => void {
    return this.firebaseService.subscribeToStatisticsCollection(
      companyId,
      StatisticsType.Trainings,
      StatisticsBasis.Daily,
      {
        next: (
          querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
        ) => {
          if (!querySnapshot.empty) {
            // TODO: Update with historical statistics when existing
            this.totalSteps = querySnapshot.docs
              .map((dailyTrainingDoc) => dailyTrainingDoc.data())
              .reduce(
                (dailyAccumulator, trainings) =>
                  (dailyAccumulator += Object.values(trainings).reduce(
                    (usersAccumulator, userDailySteps) =>
                      (usersAccumulator += Math.floor(userDailySteps.steps)),
                    0
                  )),
                0
              );
            const totalTrainings = querySnapshot.docs
              .map((trainingDoc) => Object.values(trainingDoc.data()).length)
              .reduce(
                (accumulator, totalUsersByDay) =>
                  (accumulator += totalUsersByDay),
                0
              );
            this.averageTotalSteps = this.totalSteps / totalTrainings;
          } else {
            this.totalSteps = 0;
          }
        },
        error: (error: firebase.firestore.FirestoreError) => {
          console.log('subscriptionToCompanyDailyRanking - error: ', error);
          throw new Error(
            error + ' > company.service - subscriptionToCompanyDailyRanking'
          );
        },
      }
    );
  }

  public updateCompanyData(): Promise<void> {
    return this.prepareCompanyData(this.company.id);
  }

  public updateCompanyName(name: string): Promise<void> {
    return this.firebaseService.updateCompany(this.company.id, { name });
  }

  public getCompany(companyId?: string): Promise<Company> {
    if (!companyId || (this.company && companyId === this.company.id)) {
      return Promise.resolve(this.company);
    } else {
      return this.firebaseService
        .getCompany(companyId)
        .then((companySnapshot) => {
          if (companySnapshot.exists) {
            return new Company(companySnapshot.data());
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log('getCompany - error: ', error);
          return Promise.reject(error);
        });
    }
  }

  public getCompanyLocations(companyId: string): Promise<Array<Location>> {
    return this.firebaseService
      .getCompanyLocations(companyId)
      .then((companyLocationsQuery) => {
        if (!companyLocationsQuery.empty) {
          return companyLocationsQuery.docs.map(
            (locationSnapshot) => new Location(locationSnapshot.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('getCompanyLocations - error: ', error);
        return Promise.reject(error);
      });
  }

  public getAvailableChallenges(
    companyId: string
  ): Promise<Array<ChallengeSchema>> {
    return this.firebaseService
      .getCompanyAvailableChallenges(companyId)
      .then((companyAvailableChallengesQuery) => {
        if (!companyAvailableChallengesQuery.empty) {
          return companyAvailableChallengesQuery.docs.map(
            (availableChallengeDoc) =>
              new ChallengeSchema().deserialize(availableChallengeDoc.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('getAvailableChallenges - error: ', error);
        return Promise.reject(error);
      });
  }

  public getTeamChallenges(companyId: string): Promise<Array<TeamChallenge>> {
    return this.firebaseService
      .getCompanyTeamChallenges(companyId)
      .then((companyTeamChallengesQuery) => {
        if (!companyTeamChallengesQuery.empty) {
          return companyTeamChallengesQuery.docs.map(
            (teamChallengeDoc) => new TeamChallenge(teamChallengeDoc.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('getTeamChallenges - error: ', error);
        return Promise.reject(error);
      });
  }

  public getTeamChallengesWeeksOnRange(
    companyId: string,
    teamChallengeId: string,
    startingWeek: any,
    endingWeek: any
  ): Promise<Array<any>> {
    return this.teamsService
      .getTeamChallengeWeeks(companyId, teamChallengeId)
      .then((teamChallengeWeeks) => {
        const weeksArray = teamChallengeWeeks.filter(
          (week) =>
            Number(week.id.split(' ')[1]) >=
              Number(startingWeek.id.split(' ')[1]) &&
            Number(week.id.split(' ')[1]) <= Number(endingWeek.id.split(' ')[1])
        );

        let standingsArray = Object.entries(weeksArray[0]).map(
          (weekEntry: any) => {
            if (weekEntry[1].name) {
              return {
                name: weekEntry[1].name,
                totalSteps: weekEntry[1].totalSteps,
                teamSize: weekEntry[1].teamSize,
                avatar: weekEntry[1].avatar,
                location: weekEntry[1].location,
                totalSyncs: weekEntry[1].totalSyncs,
              };
            } else {
              return null;
            }
          }
        );

        standingsArray = standingsArray.filter(Boolean);

        if (weeksArray.length > 1) {
          weeksArray.slice(1).forEach((week) => {
            Object.values(week).forEach((team: any) => {
              const teamIndex = standingsArray.findIndex(
                (element) => element.name === team.name
              );
              if (teamIndex >= 0) {
                standingsArray[teamIndex].totalSteps += team.totalSteps;
                standingsArray[teamIndex].totalSyncs += team.totalSyncs;
              }
            });
          });
        }

        standingsArray.sort(
          (teamA, teamB) => teamB.totalSteps - teamA.totalSteps
        );
        standingsArray = standingsArray.map((team, index) =>
          Object.assign(team, {
            position: index + 1,
            averageSteps:
              team.teamSize > 0 ? team.totalSteps / team.teamSize : 0,
          })
        );

        return standingsArray;
      })
      .catch((error) => {
        console.log('getTeamChallengesWeeksOnRange error: ', error);
        return Promise.reject(error);
      });
  }

  public getPointsSources(companyId: string): Promise<Array<PointsSource>> {
    return this.firebaseService
      .getCompanyPointsSources(companyId)
      .then((companyPointsSourcesQuery) => {
        if (!companyPointsSourcesQuery.empty) {
          return companyPointsSourcesQuery.docs.map((pointsSourceSnapshot) =>
            new PointsSource().deserialize(pointsSourceSnapshot.data())
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log('getPointsSources - error: ', error);
        return Promise.reject(error);
      });
  }

  public updateTraining(
    userEmail: string,
    date: string,
    steps: number
  ): Promise<void> {
    const userSelected = this.users.find(
      (user) => user.email === userEmail.toLowerCase().trim()
    );
    return this.firebaseService.updateTraining(userSelected.id, date, {
      count: steps,
    });
  }

  public async getUsers(): Promise<void> {
    if (this.company && this.company.id) {
      this.users = await this.getAndReturnUsers(this.company.id);
    }
  }

  public async getAndReturnUsers(companyId: string): Promise<Array<User>> {
    return this.firebaseService
      .getUsersFromCompany(companyId)
      .then((querySnapshot) =>
        querySnapshot.docs.map((documentSnapshot) =>
          new User().deserialize(documentSnapshot.data())
        )
      )
      .catch((error) => {
        console.log('error - getUsers: ', error);
        return Promise.reject(error);
      });
  }

  private setTotalSteps(): void {
    let totalSteps = 0;
    this.trainings.forEach((trainings) => {
      trainings.trainings.forEach((training) => {
        totalSteps = totalSteps + training.steps;
      });
    });
    this.totalSteps = totalSteps;
  }

  public getDistanceChallenges(
    companyId: string
  ): Promise<Array<CitiesChallenge>> {
    return this.firebaseService
      .getCompanyCitiesChallenges(companyId)
      .then((companyDistanceChallengesQuery) => {
        if (!companyDistanceChallengesQuery.empty) {
          return companyDistanceChallengesQuery.docs.map(
            (doc) =>
              new CitiesChallenge(doc.data() as ICitiesChallengeInitializer)
          );
        }
        return [];
      })
      .catch((error) => {
        console.log('getDistanceChallenges - error: ', error);
        return Promise.reject(error);
      });
  }

  public getTeamChallengeFinalStandings(
    teamChallengeId: string,
    companyId: string = this.company.id
  ): Promise<TeamFinalStandings> {
    return this.firebaseService
      .getTeamChallengeFinalStandings(companyId, teamChallengeId)
      .then((finalStandingsDocument) => {
        if (finalStandingsDocument.exists) {
          return new TeamFinalStandings().deserialize(
            finalStandingsDocument.data()
          );
        }
        return null;
      })
      .catch((error) => {
        console.log('getTeamChallengeFinalStandings - error: ', error);
        return Promise.reject(error);
      });
  }

  public getCompanyTrainings(companyId: string): Promise<Array<Training>> {
    const trainings: Array<Training> = [];
    return this.firebaseService
      .getTrainings(companyId)
      .then((trainingsQuerySnapshot) => {
        trainingsQuerySnapshot.docs.forEach((trainingSnapshot) => {
          const training = new Training();
          training.date = trainingSnapshot.id;
          training.trainings = Object.values(trainingSnapshot.data()).map(
            (element) => {
              if (typeof element === 'number') {
                return new TrainingData({ steps: element });
              }
              return new TrainingData({
                steps: element.steps,
                nickname: element.nickname,
                country: element.country,
                avatar: element.avatar,
                location: element.location,
                locationIds: element.locationIds ? element.locationIds : null,
              });
            }
          );
          trainings.push(training);
        });
        return trainings;
      })
      .catch((error) => {
        console.log('getCompanyTrainings - error: ', error);
        return Promise.reject(error);
      });
  }

  public getCurrentTeamChallenge(
    companyId: string,
    currentTeamChallenge: string
  ): Promise<TeamChallenge> {
    return this.firebaseService
      .getTeamChallenge(companyId, currentTeamChallenge)
      .then((teamChallengeSnapshot) => {
        if (teamChallengeSnapshot.exists) {
          return new TeamChallenge(teamChallengeSnapshot.data());
        }
        return null;
      })
      .catch((error) => {
        console.log('getCurrentTeamChallenge - error: ', error);
        return Promise.reject(error);
      });
  }

  public setLocationSteps(locationId: string, steps: number): void {
    const locationIndex = this.locations.findIndex(
      (location) => location.id === locationId
    );
    if (locationIndex !== -1) {
      this.locations[locationIndex].totalSteps = steps;
    } else {
      console.log('LOCATION NOT FOUND!!!');
      // TODO: SHOW LOCATION NOT FOUND ERROR
    }
  }

  public updateTeamChallenge(
    companyId: string,
    teamChallenge: TeamChallenge,
    teamChallengeId: string
  ): Promise<void> {
    return this.firebaseService.updateTeamChallenge(
      companyId,
      teamChallenge,
      teamChallengeId
    );
  }

  public async getTeamsByLocation(
    companyId: string,
    locationId: string,
    teamChallenge: TeamChallenge
  ): Promise<Array<Team>> {
    return this.firebaseService
      .getTeamsByLocation(companyId, locationId)
      .then((querySnapshot) =>
        querySnapshot.docs
          .map((docSnapshot) => new Team(docSnapshot.data()))
          .filter((team) => teamChallenge.teamIds.has(team.id))
      );
  }

  public async getTeamsByTeamChallenge(
    companyId: string,
    teamChallengeId: string
  ): Promise<Array<Team>> {
    return this.firebaseService
      .getTeamsByTeamChallenge(companyId, teamChallengeId)
      .then((querySnapshot) =>
        querySnapshot.docs.map((docSnapshot) => new Team(docSnapshot.data()))
      );
  }

  public changeTeamMember(
    userEmail: string,
    newTeamId: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    return this.firebaseService.changeTeamMember(userEmail, newTeamId);
  }

  public removeUserFromTeamChallenge(
    companyId: string,
    teamChallengeId: string,
    userId: string
  ): Promise<void> {
    return this.firebaseService.removeUserFromTeamChallenge(
      companyId,
      teamChallengeId,
      userId
    );
  }
}
