import {
  AfterContentInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription, map, merge, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

// Models
import {
  City,
  ICityInitializer,
} from 'src/app/models/cities-challenge/city/city.model';
import { CityMarkerPoint } from 'src/app/models/cities-challenge/city-marker-point/city-marker-point.model';
import { CityInfo } from 'src/app/models/cities-challenge/city-info/city-info.model';

// Services
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { RoutesService } from 'src/app/services/routes/routes.service';
import { CitiesService } from 'src/app/services/cities/cities.service';

// Constants
import { IconNames } from 'src/app/models/assets-constants';

// Helpers
import { Helpers } from 'src/app/helpers/helpers';

@Component({
  selector: 'app-route-form',
  templateUrl: './route-form.component.html',
  styleUrls: ['./route-form.component.scss'],
})
export class RouteFormComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() routeDataForm: UntypedFormGroup;
  @Input() editable: boolean = true;

  private distanceCalculationSubscription: Subscription;
  private cityInfoTypeSubscription: Subscription;
  private cityUpdateSubscription: Subscription;
  private citiesFilterSubscription: Subscription;

  public cityInfoTypesAvailable: Set<string> = new Set();

  public cities: Array<City> = [];
  public filteredCities: Observable<Array<City>>;
  public cityInfos: Array<CityInfo> = [];

  public trashIcon = IconNames.TrashOutline;

  public showCityName = (city?: ICityInitializer): string | undefined => {
    if (city) {
      const cityObject = new City(city);
      return cityObject.translations.get(this.currentLang)
        ? cityObject.translations.get(this.currentLang)
        : city.name;
    } else {
      return undefined;
    }
  };

  constructor(
    private translateService: TranslateService,
    private subscriptionService: SubscriptionService,
    private sanitizer: DomSanitizer,
    private routesService: RoutesService,
    private citiesService: CitiesService
  ) {
    this.subscriptionService.subscribeToCities();

    this.subscriptionService.cities.subscribe((cities) => {
      if (cities) {
        this.cities = cities.sort((cityA, cityB) =>
          cityA.name > cityB.name ? 1 : -1
        );
        this.filteredCities = of(this.cities);
      }
    });
  }

  ngOnInit(): void {
    if (!this.editable) {
      this.routeDataForm.controls.mapFile.disable({ emitEvent: false });
    }
    this.getCityInfos();
  }

  ngAfterContentInit(): void {
    if (this.editable) {
      this.routeDataForm.controls.mapFile.valueChanges.subscribe((newValue) => {
        if (newValue && newValue.files && newValue.files[0]) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(newValue.files[0]);

          fileReader.addEventListener('load', (event) => {
            this.routeDataForm.patchValue({
              mapUrl: this.sanitizer.bypassSecurityTrustResourceUrl(
                String(event.target?.result)
              ),
            });
          });
        }
      });
      this.subscribeToFormUpdates();
      this.subscribeToRouteCityInfoTypeUpdate();
    } else {
      this.routeDataForm.disable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeFromCities();
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  get cityMarkerPoints(): FormArray<FormGroup> {
    return this.routeDataForm.controls.cityMarkerPoints as UntypedFormArray;
  }

  private subscribeToCityUpdate(): void {
    this.subscribeToDistanceCalculation();

    if (this.cityUpdateSubscription) {
      this.cityUpdateSubscription.unsubscribe();
    }

    this.cityUpdateSubscription = merge(
      ...this.cityMarkerPoints.controls.map(
        (cityFormGroup: UntypedFormGroup, index: number) =>
          cityFormGroup.controls.city.valueChanges.pipe(
            map((newCity: ICityInitializer | string) => ({ newCity, index }))
          )
      )
    ).subscribe((changes) => {
      if (typeof changes.newCity !== 'string') {
        this.cityInfos[changes.index] = null;
        const newCity = new City(changes.newCity);

        let previousCity = null;
        let nextCity = null;
        if (changes.index > 0) {
          previousCity = (this.cityMarkerPoints.at(changes.index - 1)
            .value as CityMarkerPoint).city;
        }
        if (changes.index < this.cityMarkerPoints.controls.length - 1) {
          nextCity = (this.cityMarkerPoints.at(changes.index + 1)
            .value as CityMarkerPoint).city;
        }

        // TODO: update it to work with changes in between a route change using the city search
        if (previousCity && newCity instanceof City) {
          this.updateDistanceAt(changes.index - 1, newCity);
        }

        if (nextCity) {
          this.updateDistanceAt(changes.index, nextCity);
        }

        if (changes.index === this.cityMarkerPoints.controls.length - 1) {
          this.cityMarkerPoints.at(changes.index).patchValue(
            {
              distanceToNextCity: null,
            },
            { emitEvent: false }
          );
        }

        this.getCommonCityInfoTypes();
      }
    });
  }

  private subscribeToRouteCityInfoTypeUpdate(): void {
    this.routeDataForm.controls.cityInfoType.valueChanges.subscribe(
      (newCityInfoType) => {
        if (newCityInfoType === 'CLEAR') {
          this.cityInfos.fill(null);
          this.cityMarkerPoints.controls.forEach((cityMarketPoint) => {
            if (cityMarketPoint.controls.city.value) {
              cityMarketPoint.controls.cityInfoType.reset(null, {
                emitEvent: false,
              });
            }
          });
          this.routeDataForm.controls.cityInfoType.reset(null, {
            emitEvent: false,
          });
        } else if (newCityInfoType) {
          this.cityMarkerPoints.controls.forEach((cityMarketPoint) => {
            if (cityMarketPoint.controls.city.value) {
              cityMarketPoint.controls.cityInfoType.setValue(newCityInfoType, {
                emitEvent: false,
              });
            }
          });
        }
      }
    );
  }

  private getCityInfos(): void {
    const citiesWithInfoTypes = this.cityMarkerPoints.getRawValue().reduce(
      (accumulator, cityMarkerPoint: CityMarkerPoint) => {
        if (cityMarkerPoint.city) {
          accumulator.cities.push(cityMarkerPoint.city.id);
          if (cityMarkerPoint.cityInfoType) {
            accumulator.cityInfoTypes.push(cityMarkerPoint.cityInfoType);
          } else {
            accumulator.cityInfoTypes.push(null);
          }
        } else {
          accumulator.cities.push(null);
          accumulator.cityInfoTypes.push(null);
        }
        return accumulator;
      },
      { cities: [], cityInfoTypes: [] }
    );
    this.citiesService
      .getCityInfosFromFirebase(
        citiesWithInfoTypes.cities,
        citiesWithInfoTypes.cityInfoTypes
      )
      .then((cityInfos) => {
        this.cityInfos = cityInfos;
      })
      .catch((error) => {
        console.log('error while getting city infos from firebase: ', error);
      });
  }

  public editCityInfo(city: City, cityInfo: CityInfo, index: number): void {
    const cityInfoTypeDisabled = Boolean(
      this.cityMarkerPoints.controls[index].controls.cityInfoType.value
    );
    this.citiesService
      .openEditCityInfoDialog(
        city,
        cityInfo,
        cityInfoTypeDisabled,
        this.cityMarkerPoints.controls[index].controls.cityInfoType.value
      )
      .subscribe((newCityInfo: CityInfo) => {
        if (newCityInfo) {
          this.cityInfos[index] = newCityInfo;
          if (
            this.cityMarkerPoints.controls[index].controls.cityInfoType
              .value !== newCityInfo.cityInfoType
          ) {
            this.cityMarkerPoints.controls[
              index
            ].controls.cityInfoType.setValue(newCityInfo.cityInfoType, {
              emitEvent: false,
            });
            this.cityMarkerPoints.controls[
              index
            ].controls.city.value.cityInfoTypes.push(newCityInfo.cityInfoType);
            this.getCommonCityInfoTypes();
          }
        }
      });
  }

  private updateDistanceAt(index: number, newCity?: City): void {
    this.cityMarkerPoints
      .at(index)
      .controls.city.updateValueAndValidity({ emitEvent: false });
    this.cityMarkerPoints
      .at(index + 1)
      .controls.city.updateValueAndValidity({ emitEvent: false });

    const cityMarkerPointAtIndex = new CityMarkerPoint(
      this.cityMarkerPoints.at(index).value
    );
    const cityMarkerPointAtNextIndex = new CityMarkerPoint(
      this.cityMarkerPoints.at(index + 1).value
    );

    const cityAtNextIndex = newCity ? newCity : cityMarkerPointAtNextIndex.city;

    try {
      const distance = cityMarkerPointAtIndex.city.getDistanceToCity(
        cityAtNextIndex
      );

      this.cityMarkerPoints.at(index).patchValue({
        distanceToNextCity: Number(distance.toFixed(2)),
      });
    } catch (error) {
      console.log('Error - updateDistanceAt: ', error);
      if (!(cityMarkerPointAtIndex instanceof City)) {
        this.cityMarkerPoints
          .at(index)
          .controls.city.setErrors({ invalidCity: true });
      }
      if (!(cityMarkerPointAtNextIndex.city instanceof City)) {
        this.cityMarkerPoints
          .at(index + 1)
          .controls.city.setErrors({ invalidCity: true });
      }
    }
  }

  private subscribeToDistanceCalculation(): void {
    if (this.distanceCalculationSubscription) {
      this.distanceCalculationSubscription.unsubscribe();
    }

    this.distanceCalculationSubscription = merge(
      ...this.cityMarkerPoints.controls.map(
        (cityFormGroup: UntypedFormGroup, index: number) =>
          cityFormGroup.controls.distanceToNextCity.valueChanges.pipe(
            map((changes: number) => ({ changes, index }))
          )
      )
    ).subscribe(() => {
      this.calculateTotalDistance();
    });
  }

  private calculateTotalDistance(): void {
    const totalDistance = this.cityMarkerPoints.controls.reduce(
      (accumulatedDistance: number, cityMarkerPoint: FormGroup) =>
        (accumulatedDistance += Number(
          cityMarkerPoint.controls.distanceToNextCity.value
        )),
      0
    );

    this.routeDataForm.patchValue({
      totalDistance: Number(totalDistance.toFixed(2)),
    });
  }

  private getCommonCityInfoTypes(): void {
    if (
      this.cityMarkerPoints.controls[0].controls.city.value &&
      typeof this.cityMarkerPoints.controls[0].controls.city.value !== 'string'
    ) {
      let commonTypes: Array<string> = [];

      commonTypes = this.cityMarkerPoints.controls[0].controls.city.value.cityInfoTypes.slice();

      for (let i = 1; i < this.cityMarkerPoints.controls.length; i++) {
        if (
          this.cityMarkerPoints.controls[i].controls.city.value &&
          typeof this.cityMarkerPoints.controls[i].controls.city.value !==
            'string'
        ) {
          const currentCityInfoTypes: Array<string> = this.cityMarkerPoints
            .controls[i].controls.city.value.cityInfoTypes;
          commonTypes = commonTypes.filter((type) =>
            currentCityInfoTypes.includes(type)
          );
        }

        if (commonTypes.length === 0) {
          break;
        }
      }

      this.cityInfoTypesAvailable = new Set(commonTypes);
    }

    if (this.cityInfoTypesAvailable.size === 0) {
      this.routeDataForm.controls.cityInfoType.reset(null, {
        emitEvent: false,
      });
    } else {
      this.routeDataForm.controls.cityInfoType.enable({ emitEvent: false });
    }
  }

  private subscribeToCityInfoTypeUpdate(): void {
    if (this.cityInfoTypeSubscription) {
      this.cityInfoTypeSubscription.unsubscribe();
    }

    this.cityInfoTypeSubscription = merge(
      ...this.cityMarkerPoints.controls.map(
        (cityFormGroup: UntypedFormGroup, index: number) =>
          cityFormGroup.controls.cityInfoType.valueChanges.pipe(
            map((newCityInfoType: string) => ({ newCityInfoType, index }))
          )
      )
    ).subscribe((changes) => {
      if (changes.newCityInfoType) {
        this.citiesService
          .getCityInfoFromFirebase(
            this.cityMarkerPoints.controls[changes.index].controls.city.value
              .id,
            changes.newCityInfoType
          )
          .then((cityInfo) => {
            this.cityInfos[changes.index] = cityInfo;
          })
          .catch((error) => {
            this.cityInfos[changes.index] = null;
            console.log(
              'error while getting city infos from firebase: ',
              error
            );
          });
      } else {
        this.cityInfos[changes.index] = null;
      }
    });
  }

  private subscribeToCitiesFilter(): void {
    if (this.citiesFilterSubscription) {
      this.citiesFilterSubscription.unsubscribe();
    }

    this.citiesFilterSubscription = merge(
      ...this.cityMarkerPoints.controls.map(
        (cityFormGroup: UntypedFormGroup) =>
          cityFormGroup.controls.city.valueChanges
      )
    ).subscribe((changes) => {
      if (typeof changes === 'object') {
        this.filteredCities = of(this.cities);
      } else {
        this.filteredCities = of(this.filterCities(changes));
      }
    });
  }

  private filterCities(val: string): Array<City> {
    return this.cities.filter(
      (option) =>
        Array.from(option.translations.values()).filter((cityTranslation) =>
          cityTranslation.toLowerCase().includes(val.toLowerCase())
        ).length > 0
    );
  }

  public addCity(): void {
    this.cityMarkerPoints
      .at(this.cityMarkerPoints.controls.length - 1)
      .controls.distanceToNextCity.addValidators([
        Validators.required,
        Validators.min(1),
      ]);
    this.cityMarkerPoints
      .at(this.cityMarkerPoints.controls.length - 1)
      .updateValueAndValidity({ emitEvent: false });
    this.routesService.addControlToCityMarkerPoints(this.cityMarkerPoints);

    this.subscribeToFormUpdates();
  }

  public removeCity(cityMarkerPointIndex: number): void {
    this.cityMarkerPoints.removeAt(cityMarkerPointIndex, { emitEvent: false });

    if (
      cityMarkerPointIndex !== 0 &&
      cityMarkerPointIndex < this.cityMarkerPoints.length
    ) {
      this.updateDistanceAt(cityMarkerPointIndex - 1);
    }

    this.cityMarkerPoints
      .at(this.cityMarkerPoints.controls.length - 1)
      .controls.distanceToNextCity.removeValidators([
        Validators.required,
        Validators.min(1),
      ]);
    this.cityMarkerPoints
      .at(this.cityMarkerPoints.controls.length - 1)
      .patchValue(
        {
          distanceToNextCity: null,
        },
        { emitEvent: false }
      );
    this.cityMarkerPoints
      .at(this.cityMarkerPoints.controls.length - 1)
      .updateValueAndValidity();

    this.cityInfos.splice(cityMarkerPointIndex, 1);

    this.calculateTotalDistance();
    this.subscribeToFormUpdates();
  }

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }

  public getFlagPath(city: City | string): string {
    const countryCode =
      city && typeof city === 'object' ? city.countryAlphaCode : 'xx';
    return Helpers.getFlagPath(countryCode);
  }

  public subscribeToFormUpdates(): void {
    this.subscribeToCityUpdate();
    this.subscribeToCitiesFilter();
    this.getCommonCityInfoTypes();
    this.subscribeToCityInfoTypeUpdate();
  }
}
