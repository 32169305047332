import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { Route } from 'src/app/models/cities-challenge/route/route.model';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import { ROUTE_MAP_PATH } from 'src/app/constants/constants';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CityMarkerPoint } from 'src/app/models/cities-challenge/city-marker-point/city-marker-point.model';
import { ICityInitializer } from 'src/app/models/cities-challenge/city/city.model';

export interface ICityMarkerPointControlData {
  city?: ICityInitializer;
  distanceToNextCity?: number;
  cityInfoType?: string;
  isDistanceRequired?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  constructor(
    private firebaseService: FirebaseService,
    private formBuilder: UntypedFormBuilder
  ) {}

  public getRouteFromFirebase(routeId: string): Promise<Route> {
    return this.firebaseService
      .getRoute(routeId)
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          return new Route(documentSnapshot.data());
        } else {
          return Promise.reject(`NO ROUTE WITH ID: ${routeId}`);
        }
      })
      .catch((error) => {
        console.log('getRouteFromFirebase - error: ', error);
        return Promise.reject(error);
      });
  }

  public async createRoute(route: Route, routeImage?: File): Promise<void> {
    if (!route.id) {
      route.id = this.firebaseService.getAutomaticIdInRootCollection(
        FirebaseConstants.RoutesCollection
      );
    }
    if (routeImage) {
      const routeImagePath = ROUTE_MAP_PATH.replace('ROUTE_ID', route.id);
      route.map = await this.uploadRouteImage(routeImage, routeImagePath);
    }
    return this.firebaseService.createRoute(route);
  }

  public setRouteDataFormValues(
    routeDataForm: UntypedFormGroup,
    route: Route,
    editable = true
  ): void {
    routeDataForm.patchValue({
      name: route.name,
      totalDistance: route.totalDistance,
      mapUrl: route.map,
    });

    if (!editable) {
      routeDataForm.controls.name.disable({ emitEvent: false });
      routeDataForm.controls.totalDistance.disable({ emitEvent: false });
      routeDataForm.controls.cityInfoType.disable({ emitEvent: false });
      routeDataForm.controls.mapFile.disable({ emitEvent: false });
    }

    route.cityMarkerPoints.forEach(
      (cityMarkerPoint: CityMarkerPoint, index: number) => {
        this.addControlToCityMarkerPoints(
          routeDataForm.controls.cityMarkerPoints as UntypedFormArray,
          {
            city: cityMarkerPoint.city ? cityMarkerPoint.city.toObject() : null,
            distanceToNextCity: cityMarkerPoint.distanceToNextCity,
            isDistanceRequired: index !== route.cityMarkerPoints.length - 1,
            cityInfoType: cityMarkerPoint.cityInfoType,
          },
          editable
        );
      }
    );
  }

  public addControlToCityMarkerPoints(
    cityMarkerPointDataFormArray: UntypedFormArray,
    cityMarkerPointData: ICityMarkerPointControlData = {},
    editable = true
  ): void {
    const {
      city = null,
      distanceToNextCity = null,
      cityInfoType = null,
      isDistanceRequired = false,
    } = cityMarkerPointData;
    const distanceValidators = isDistanceRequired
      ? [Validators.required, Validators.min(1)]
      : [];
    cityMarkerPointDataFormArray.push(
      this.formBuilder.group({
        city: [
          editable ? city : { value: city, disabled: true },
          [Validators.required],
        ],
        distanceToNextCity: [
          editable
            ? distanceToNextCity
            : { value: distanceToNextCity, disabled: true },
          distanceValidators,
        ],
        cityInfoType: [
          editable ? cityInfoType : { value: cityInfoType, disabled: true },
        ],
      }),
      { emitEvent: false }
    );
  }

  public getStandardRouteDataForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      totalDistance: [
        { value: 0, disabled: true },
        [Validators.required, Validators.min(0)],
      ],
      mapFile: [null],
      mapUrl: [null],
      cityInfoType: [null],
      cityMarkerPoints: this.formBuilder.array([]),
    });
  }

  public createRouteObject(
    routeId: string,
    routeDataForm: UntypedFormGroup,
    cityMarkerPointsForm: UntypedFormArray
  ): Route {
    const cityMarkerPoints = cityMarkerPointsForm.value.map(
      (cityMarkerPoint: CityMarkerPoint) => cityMarkerPoint
    );

    return new Route({
      id: routeId ? routeId : null,
      name: routeDataForm.controls.name.value,
      totalDistance: Number(routeDataForm.controls.totalDistance.value),
      map: routeDataForm.controls.mapUrl.value,
      cityMarkerPoints,
    });
  }

  public uploadRouteImage(imageFile: File, path: string): Promise<string> {
    return this.firebaseService.uploadData(imageFile, path);
  }
}
