import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Helpers } from 'src/app/helpers/helpers';

export interface ICircleProgressCard {
  totalProgress: number;
  title: string;
  progressTitle?: string;
  progressSubtitle?: string;
}
@Component({
  selector: 'app-circle-progress-chart',
  templateUrl: './circle-progress-chart.component.html',
  styleUrls: ['./circle-progress-chart.component.scss'],
})
export class CircleProgressChartComponent implements OnChanges, AfterViewInit {
  @Input() circleProgressCard: ICircleProgressCard;
  @ViewChild('circleProgressCardContent', { static: true })
  contentElement: ElementRef;
  public totalProgress: number = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.circleProgressCard) {
      this.totalProgress =
        this.circleProgressCard.totalProgress > 100
          ? 100
          : this.circleProgressCard.totalProgress;
    }
  }

  ngAfterViewInit(): void {
    Helpers.calculateCardContentHeight(this.contentElement, 32);
  }
}
