import { Injectable } from '@angular/core';
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ITeamChallengeInitializer,
  TeamChallenge as TeamChallengeV2,
} from 'src/app/models/team-challenge/team-challenge/team-challenge.model';
import {
  ITeam,
  Team as TeamV2,
  TeamVisibility,
} from 'src/app/models/team-challenge/team/team.model';
import {
  IWeeklyHistory,
  WeeklyHistory,
} from 'src/app/models/team-challenge/weekly-history/weekly-history.model';
import { FirebaseService } from '../firebase/firebase.service';
import { FirebaseConstants } from 'src/app/models/firebase-constants.enum';
import {
  CHALLENGE_TYPE_NAMES,
  PRIMARY_COLOR,
  STEPS_IN_ONE_KILOMETER,
} from 'src/app/constants/constants';
import { REGEX_NUMERIC } from 'src/app/constants/constants';
import { Features as TeamChallengeFeatures } from 'src/app/models/team-challenge/features';
import { Helpers } from 'src/app/helpers/helpers';
import { UserMessageService } from '../user-message/user-message.service';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';
// eslint-disable-next-line max-len
import { ITeamChallengeDashboard } from 'src/app/shared/components/team-challenge-v2/team-challenge-dashboard/team-challenge-dashboard.component';

export interface ITeamChallengeKpis {
  participants: number;
  teams: number;
  totalPerformance: number;
  totalSyncs: number;
  totalAverage: number;
  totalKms: number;
}

@Injectable({
  providedIn: 'root',
})
export class TeamChallengeService {
  public teamAvatars: Array<string> = [];
  public teamChallenges: Array<TeamChallengeV2> = [];

  private teamChallengeUnsubscribe: () => void;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private userMessageService: UserMessageService
  ) {}

  public getStandardTeamChallengeDataForm(): UntypedFormGroup {
    return this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      features: [[]],
      durationInWeeks: [
        4,
        [
          Validators.required,
          Validators.min(4),
          Validators.max(12),
          Validators.pattern(REGEX_NUMERIC),
        ],
      ],
      startDate: [],
      endDate: [{ value: null, disabled: true }],
      maxParticipantsPerTeam: [
        0,
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(/^(?!1$).*$/),
        ],
      ],
      locationLevel: [null, [Validators.min(1), Validators.max(3)]],
      membersWaitingForTeam: [{}],
      currentWeek: [0],
      teamsAmount: [0, [Validators.min(0)]],
      projectPlanSent: [false],
      teamsTotalPerformance: [{}],
      teamForms: this.formBuilder.array([]),
    });
  }

  public createTeamChallengeV2(
    companyId: string,
    teamChallenge: TeamChallengeV2,
    teams: Array<TeamV2>
  ): Promise<void> {
    if (!teamChallenge.id) {
      teamChallenge.id = this.firebaseService.getAutomaticIdInCompanySubCollection(
        companyId,
        FirebaseConstants.TeamChallengesCollection
      );
    }
    return this.firebaseService
      .createTeamChallengeV2(companyId, teamChallenge, teams)
      .catch((error) =>
        console.log(`Create team challenge V2 error: ${error}`)
      );
  }

  public async saveTeamAvatarsInStorage(
    teamChallengeForm: UntypedFormGroup,
    companyId: string
  ): Promise<void> {
    if (!teamChallengeForm.controls.id.value) {
      teamChallengeForm.controls.id.setValue(
        this.firebaseService.getAutomaticIdInCompanySubCollection(
          companyId,
          FirebaseConstants.TeamChallengesCollection
        )
      );
    }
    const teamsPathFolder =
      FirebaseConstants.CompaniesFolder +
      companyId +
      '/' +
      FirebaseConstants.TeamChallengesFolder +
      teamChallengeForm.controls.id.value +
      '/' +
      FirebaseConstants.TeamsFolder;

    const teamForms = teamChallengeForm.controls.teamForms as FormArray;

    for (const key of Object.keys(teamForms.controls)) {
      const teamForm = teamForms.controls[key] as UntypedFormGroup;
      if (teamForm.controls.avatar.value) {
        if (!teamForm.controls.id.value) {
          teamForm.controls.id.setValue(
            this.firebaseService.getAutomaticIdInCompanySubCollection(
              companyId,
              FirebaseConstants.TeamsCollection
            )
          );
        }

        if (teamForm.controls.avatar.value.indexOf('firebasestorage') < 0) {
          // save avatar only when it is necessary
          const downloadUrl = await this.firebaseService.uploadDataURL(
            teamForm.controls.avatar.value,
            `${teamsPathFolder}/${teamForm.controls.id.value}/avatar.png`
          );

          teamForm.controls.avatar.setValue(downloadUrl);
        }
      }
    }
  }

  public getTeamChallengeV2(
    companyId: string,
    teamChallengeId: string
  ): Promise<TeamChallengeV2 | null> {
    const challengeSaved = this.teamChallenges.find(
      (challenge) => challenge.id === teamChallengeId
    );
    if (challengeSaved) {
      return Promise.resolve(challengeSaved);
    } else {
      return this.firebaseService
        .getChallenge(companyId, teamChallengeId)
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            return new TeamChallengeV2(
              docSnapshot.data() as ITeamChallengeInitializer
            );
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log(`Get team challenge V2 error: ${error}`);
          return null;
        });
    }
  }

  public subscribeToTeamChallengeV2(
    companyId: string,
    teamChallengeId: string,
    teamChallengeDashboard: ITeamChallengeDashboard
  ): void {
    this.unsubscribeFromTeamChallengeV2();
    this.teamChallengeUnsubscribe = this.subscriptionToTeamChallengeV2(
      companyId,
      teamChallengeId,
      teamChallengeDashboard
    );
  }

  public unsubscribeFromTeamChallengeV2(): void {
    if (this.teamChallengeUnsubscribe !== undefined) {
      this.teamChallengeUnsubscribe();
    }
  }

  // eslint-disable-next-line max-len
  private subscriptionToTeamChallengeV2(
    companyId: string,
    teamChallengeId: string,
    teamChallengeDashboard: ITeamChallengeDashboard
  ): () => void {
    return this.firebaseService.subscribeToChallenge(
      companyId,
      teamChallengeId,
      {
        next: (
          teamChallengeSnapshot: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
        ) => {
          if (teamChallengeSnapshot.exists) {
            teamChallengeDashboard.teamChallenge = new TeamChallengeV2(
              teamChallengeSnapshot.data()
            );

            // subscribe to teams
            this.subscriptionToTeamsFromTeamChallengeV2(
              companyId,
              teamChallengeId,
              teamChallengeDashboard
            );
          }
        },
        error: (error) => {
          // TODO: show snack bar?
          console.log(
            'team-challenge.service.ts - subscriptionToTeamChallengeV2: ',
            error
          );
        },
      }
    );
  }

  // eslint-disable-next-line max-len
  private subscriptionToTeamsFromTeamChallengeV2(
    companyId: string,
    teamChallengeId: string,
    teamChallengeDashboard: ITeamChallengeDashboard
  ): () => void {
    return this.firebaseService.subscribeToTeamsFromTeamChallengeV2(
      companyId,
      teamChallengeId,
      {
        next: (
          querySnapshot: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
        ) => {
          if (!querySnapshot.empty) {
            teamChallengeDashboard.teams = querySnapshot.docs.map(
              (docSnapshot) => new TeamV2(docSnapshot.data() as ITeam)
            );
          } else {
            teamChallengeDashboard.teams = [];
          }
        },
        error: (error) => {
          // TODO: show snack bar?
          console.log(
            'team-challenge.service.ts - subscriptionToTeamChallengeV2: ',
            error
          );
        },
      }
    );
  }

  public getTeamChallengesV2(
    companyId: string
  ): Promise<Array<TeamChallengeV2>> {
    return this.firebaseService
      .getChallengesByType(companyId, CHALLENGE_TYPE_NAMES.TeamChallengeV2)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          this.teamChallenges = querySnapshot.docs.map(
            (docSnapshot) =>
              new TeamChallengeV2(
                docSnapshot.data() as ITeamChallengeInitializer
              )
          );
          return [...this.teamChallenges];
        } else {
          this.teamChallenges = [];
          return [];
        }
      })
      .catch((error) => {
        console.log(`Get team challenges V2 error: ${error}`);
        this.teamChallenges = [];
        return [];
      });
  }

  public getTeamsFromTeamChallengeV2(
    companyId: string,
    teamChallengeId: string
  ): Promise<Array<TeamV2>> {
    return this.firebaseService
      .getTeamsFromTeamChallengeV2(companyId, teamChallengeId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs.map(
            (docSnapshot) => new TeamV2(docSnapshot.data() as ITeam)
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log(`Get teams from team challenge V2 error: ${error}`);
        this.userMessageService.snackBarMessage(
          'TEAM_CHALLENGE_V2.ERRORS.GETTING_TEAMS'
        );
        return [];
      });
  }

  public updateTeamChallengeV2(
    companyId: string,
    teamChallenge: Partial<TeamChallengeV2>
  ): Promise<void> {
    return this.firebaseService
      .updateChallenge(companyId, teamChallenge)
      .catch((error) => {
        console.log(`Update team challenge V2 error: ${error}`);
      });
  }

  public updateTeamV2(
    companyId: string,
    teamChallengeId: string,
    team: Partial<TeamV2>
  ): Promise<void> {
    return this.firebaseService
      .updateTeamV2(companyId, teamChallengeId, team)
      .catch((error) => {
        console.log(`Update team V2 error: ${error}`);
      });
  }

  public removeTeamV2(
    companyId: string,
    teamChallengeId: string,
    teamId: string
  ): Promise<void> {
    return this.firebaseService
      .removeTeamV2(companyId, teamChallengeId, teamId)
      .catch((error) => {
        console.log(`Remove team V2 error: ${error}`);
      });
  }

  public removeTeamChallengeV2(
    companyId: string,
    teamChallengeId: string
  ): Promise<void> {
    return this.firebaseService
      .removeChallenge(companyId, teamChallengeId)
      .catch((error) => {
        console.log(`Remove team challenge V2 error: ${error}`);
      });
  }

  public getTeamChallengeV2WeeklyHistories(
    companyId: string,
    teamChallengeId: string
  ): Promise<Array<WeeklyHistory>> {
    return this.firebaseService
      .getTeamChallengeV2WeeklyHistories(companyId, teamChallengeId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs.map(
            (docSnapshot) =>
              new WeeklyHistory(docSnapshot.data() as IWeeklyHistory)
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log(`Get team challenge V2 weekly histories error: ${error}`);
        return [];
      });
  }

  public getTeamChallengeV2WeeklyHistory(
    companyId: string,
    teamChallengeId: string,
    weeklyRankingId: string
  ): Promise<WeeklyHistory | null> {
    return this.firebaseService
      .getTeamChallengeV2WeeklyHistory(
        companyId,
        teamChallengeId,
        weeklyRankingId
      )
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          return new WeeklyHistory(docSnapshot.data() as IWeeklyHistory);
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log(`Get team challenge V2 weekly history error: ${error}`);
        return null;
      });
  }

  public getStandardTeamDataForm(): UntypedFormGroup {
    return this.formBuilder.group({
      avatar: ['', Validators.required],
      name: ['', Validators.required],
      color: [PRIMARY_COLOR, Validators.required],
      id: [''],
      joinRequests: [[]],
      location: [''],
      members: [[]],
      memberIds: [[]],
      teamCaptain: [''],
      visibility: [TeamVisibility.PUBLIC, Validators.required],
    });
  }

  public async getRandomTeams(
    numberOfTeams: number,
    startingIndex: number = 0
  ): Promise<Array<TeamV2>> {
    if (this.teamAvatars.length === 0) {
      await this.getTeamAvatars();
    }

    if (this.teamAvatars.length > 0) {
      return Array.from(
        { length: numberOfTeams },
        (_, arrayIndex) =>
          new TeamV2({
            name: `Team_${arrayIndex + startingIndex + 1}`,
            avatar: this.teamAvatars[
              (arrayIndex + startingIndex + 1) % this.teamAvatars.length
            ],
            color: PRIMARY_COLOR,
          })
      );
    } else {
      return [];
    }
  }

  public getTeamAvatars(): Promise<Array<string>> {
    if (this.teamAvatars.length === 0) {
      return this.firebaseService
        .getAvatars()
        .then((avatars) => (this.teamAvatars = avatars))
        .catch((error) => {
          console.log('Error getting team avatars: ', error);
          return (this.teamAvatars = []);
        });
    } else {
      return Promise.resolve(this.teamAvatars);
    }
  }

  // Function to set the form according to the 8 types of challenge
  public setFeaturesToForm(
    teamChallengeDataForm: UntypedFormGroup,
    features: Array<string>,
    emitEvent = false
  ): void {
    const customTeams = features.includes(TeamChallengeFeatures.CUSTOM_TEAMS);
    const locations = features.includes(TeamChallengeFeatures.LOCATIONS);
    const teamSelection = features.includes(
      TeamChallengeFeatures.TEAM_SELECTION
    );
    const teamForms = teamChallengeDataForm.controls.teamForms as FormArray;

    const tomorrow = Helpers.createISODateTime(Helpers.addDays(new Date(), 1));
    const startDateValue = teamChallengeDataForm.controls.startDate.value;

    if (teamSelection) {
      teamChallengeDataForm.controls.teamsAmount.enable({ emitEvent });
    } else {
      teamChallengeDataForm.controls.teamsAmount.reset();
      teamChallengeDataForm.controls.teamsAmount.disable({ emitEvent });
    }

    if (customTeams) {
      teamForms.enable({ emitEvent });
    } else {
      teamForms.disable({ emitEvent });
    }

    if (locations) {
      teamChallengeDataForm.controls.locationLevel.enable({ emitEvent });
      teamChallengeDataForm.controls.locationLevel.addValidators(
        Validators.required
      );
      teamForms.controls.forEach((form: UntypedFormGroup) => {
        form.controls.location.enable({ emitEvent });
        form.controls.location.addValidators(Validators.required);
      });
    } else {
      teamChallengeDataForm.controls.locationLevel.reset();
      teamChallengeDataForm.controls.locationLevel.disable({ emitEvent });
      teamChallengeDataForm.controls.locationLevel.removeValidators(
        Validators.required
      );
      teamForms.controls.forEach((form: UntypedFormGroup) => {
        form.controls.location.disable({ emitEvent });
        form.controls.location.removeValidators(Validators.required);
      });
    }

    if (
      startDateValue &&
      Helpers.createISODateTime(startDateValue) <= tomorrow
    ) {
      teamChallengeDataForm.controls.name.disable({ emitEvent });
      teamChallengeDataForm.controls.durationInWeeks.disable({ emitEvent });
      teamChallengeDataForm.controls.startDate.disable({ emitEvent });
      teamChallengeDataForm.controls.maxParticipantsPerTeam.disable({
        emitEvent,
      });
      teamChallengeDataForm.controls.locationLevel.disable({ emitEvent });

      teamForms.controls.forEach((form: UntypedFormGroup) => {
        if (locations && form.controls.location.value) {
          form.controls.location.disable({ emitEvent });
          form.controls.location.removeValidators(Validators.required);
        }
      });
    }
  }

  public getTeamChallengeDataFromForm(
    teamChallengeDataForm: UntypedFormGroup
  ): ITeamChallengeInitializer {
    return {
      id: teamChallengeDataForm.controls.id.value,
      name: teamChallengeDataForm.controls.name.value,
      features: teamChallengeDataForm.controls.features.value,
      durationInWeeks: teamChallengeDataForm.controls.durationInWeeks.value,
      startDate: Helpers.createISOStartDateTime(
        teamChallengeDataForm.controls.startDate.value
      ),
      endDate: Helpers.createISOEndDateTime(
        teamChallengeDataForm.controls.endDate.value
      ),
      maxParticipantsPerTeam:
        teamChallengeDataForm.controls.maxParticipantsPerTeam.value,
      membersWaitingForTeam:
        teamChallengeDataForm.controls.membersWaitingForTeam.value,
      locationLevel: teamChallengeDataForm.controls.locationLevel.value,
      currentWeek: teamChallengeDataForm.controls.currentWeek.value,
      projectPlanSent: teamChallengeDataForm.controls.projectPlanSent.value,
      teamsTotalPerformance:
        teamChallengeDataForm.controls.teamsTotalPerformance.value,
    };
  }

  public getTeamInterfaceFromForm(teamDataForm: UntypedFormGroup): ITeam {
    return {
      id: teamDataForm.controls.id.value,
      avatar: teamDataForm.controls.avatar.value,
      name: teamDataForm.controls.name.value,
      color: teamDataForm.controls.color.value,
      joinRequests: teamDataForm.controls.joinRequests.value,
      location: teamDataForm.controls.location.value,
      members: teamDataForm.controls.members.value,
      memberIds: teamDataForm.controls.memberIds.value,
      teamCaptain: teamDataForm.controls.teamCaptain.value,
      visibility: teamDataForm.controls.visibility.value,
    };
  }

  public setTeamChallengeToForm(
    teamChallengeDataForm: UntypedFormGroup,
    teamChallenge: TeamChallengeV2,
    teams: Array<TeamV2>
  ): void {
    teamChallengeDataForm.controls.id.setValue(teamChallenge.id);
    teamChallengeDataForm.controls.name.setValue(teamChallenge.name);
    teamChallengeDataForm.controls.features.setValue(
      Array.from(teamChallenge.features)
    );
    teamChallengeDataForm.controls.durationInWeeks.setValue(
      teamChallenge.durationInWeeks
    );
    teamChallengeDataForm.controls.startDate.setValue(
      new Date(teamChallenge.startDate)
    );
    teamChallengeDataForm.controls.endDate.setValue(
      new Date(teamChallenge.endDate)
    );
    teamChallengeDataForm.controls.maxParticipantsPerTeam.setValue(
      teamChallenge.maxParticipantsPerTeam
    );
    teamChallengeDataForm.controls.membersWaitingForTeam.setValue(
      Object.fromEntries(teamChallenge.membersWaitingForTeam)
    );
    teamChallengeDataForm.controls.locationLevel.setValue(
      teamChallenge.locationLevel
    );
    teamChallengeDataForm.controls.currentWeek.setValue(
      teamChallenge.currentWeek
    );
    teamChallengeDataForm.controls.projectPlanSent.setValue(
      teamChallenge.projectPlanSent
    );
    teamChallengeDataForm.controls.teamsTotalPerformance.setValue(
      Object.fromEntries(teamChallenge.teamsTotalPerformance)
    );

    for (const team of teams) {
      const teamDataForm = this.getStandardTeamDataForm();
      teamDataForm.patchValue(team.toObject());
      (teamChallengeDataForm.controls.teamForms as FormArray).push(
        teamDataForm
      );
    }
  }

  public removeTeamWithConfirmation(
    companyId: string,
    teamChallengeId: string,
    teamId: string
  ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MatDialogRef<ConfirmationDialogComponent, any> {
    const dialogData: ConfirmationDialogData = new ConfirmationDialogData({
      title: 'TEAM_CHALLENGE.REMOVE_TEAM.TITLE',
      message: 'TEAM_CHALLENGE.REMOVE_TEAM.MESSAGE',
      action: () => this.removeTeamV2(companyId, teamChallengeId, teamId),
    });

    const dialogRef = this.userMessageService.openConfirmationDialog(
      dialogData
    );

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.userMessageService.snackBarMessage(
            'TEAM_CHALLENGE.REMOVE_TEAM.SUCCESS_MESSAGE'
          );
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.userMessageService.snackBarMessage(
            'TEAM_CHALLENGE.REMOVE_TEAM.ERROR_MESSAGE'
          );
          console.log('error: ', result.error);
          break;
      }
    });

    return dialogRef;
  }

  public async saveTeamChallengeFromForm(
    companyId: string,
    challengeDataForm: UntypedFormGroup
  ): Promise<void> {
    // Save avatars if it is necessary
    await this.saveTeamAvatarsInStorage(challengeDataForm, companyId);
    const teamInitializer = this.getTeamChallengeDataFromForm(
      challengeDataForm
    );
    const teamChallenge = new TeamChallengeV2(teamInitializer);
    const teams = (challengeDataForm.controls
      .teamForms as FormArray).controls.map(
      (teamForm: UntypedFormGroup) =>
        new TeamV2(this.getTeamInterfaceFromForm(teamForm))
    );

    return this.createTeamChallengeV2(companyId, teamChallenge, teams);
  }

  public calculateTeamChallengeKpis(
    teamChallenge: TeamChallengeV2,
    teams: Array<TeamV2>,
    weeklyHistory: WeeklyHistory
  ): ITeamChallengeKpis {
    const teamsTotalPerformance = Array.from(
      teamChallenge.teamsTotalPerformance.values()
    );
    const teamsWeeklyPerformance = Array.from(
      weeklyHistory.teamsWeeklyPerformance.values()
    );

    const kpis: ITeamChallengeKpis = {
      totalAverage: 0,
      totalKms: 0,
      totalPerformance: 0,
      totalSyncs: 0,
      participants: teams.reduce((acc, team) => acc + team.memberIds.length, 0),
      teams: teams.length,
    };

    teamsTotalPerformance.reduce((kpiAcc, teamTotalPerformance) => {
      kpiAcc.totalPerformance += teamTotalPerformance.performance;
      kpiAcc.totalSyncs += teamTotalPerformance.syncs;
      return kpiAcc;
    }, kpis);

    teamsWeeklyPerformance.reduce((kpiAcc, teamWeeklyPerformance) => {
      kpiAcc.totalPerformance += teamWeeklyPerformance.performance;
      kpiAcc.totalSyncs += teamWeeklyPerformance.syncs;
      return kpiAcc;
    }, kpis);

    kpis.totalAverage = kpis.totalPerformance / (kpis.totalSyncs || 1);

    kpis.totalKms = kpis.totalPerformance / STEPS_IN_ONE_KILOMETER;

    return kpis;
  }
}
