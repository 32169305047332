import {
  Challenge,
  IChallengeInitializer,
} from '../../challenge/challenge.abstract';
import { ParticipantPerformance } from '../../participant-performance/participant-performance';

export interface IGroupRunChallengeInitializer extends IChallengeInitializer {
  participants: Array<string>;
  firstLevelTarget: number;
  levelIncrementalFactor: number;
  totalPerformance: number;
  currentPerformance: number;
  currentLevel: number;
  currentTarget: number;
  totalTarget: number;
}

export class GroupRunChallenge extends Challenge {
  public participants: Array<string> = [];
  public firstLevelTarget: number = 0;
  public levelIncrementalFactor: number = 0;
  public totalPerformance: number = 0;
  public currentPerformance: number = 0;
  public currentLevel: number = 0;
  public currentTarget: number = 0;
  public totalTarget: number = 0;

  constructor(initializer?: Partial<IGroupRunChallengeInitializer>) {
    super(initializer);
    if (initializer) {
      this.participants = initializer.participants ?? this.participants;
      this.firstLevelTarget =
        initializer.firstLevelTarget ?? this.firstLevelTarget;
      this.levelIncrementalFactor =
        initializer.levelIncrementalFactor ?? this.levelIncrementalFactor;
      this.totalPerformance =
        initializer.totalPerformance ?? this.totalPerformance;
      this.currentPerformance =
        initializer.currentPerformance ?? this.currentPerformance;
      this.currentLevel = initializer.currentLevel ?? this.currentLevel;
      this.currentTarget = initializer.currentTarget ?? this.currentTarget;
      this.totalTarget = initializer.totalTarget ?? this.totalTarget;
    }
  }

  public toObject(): IGroupRunChallengeInitializer {
    const {
      participants,
      firstLevelTarget,
      levelIncrementalFactor,
      totalPerformance,
      currentPerformance,
      currentLevel,
      currentTarget,
      totalTarget,
    } = this;

    return {
      ...super.toObject(),
      participants,
      firstLevelTarget,
      levelIncrementalFactor,
      totalPerformance,
      currentPerformance,
      currentLevel,
      currentTarget,
      totalTarget,
    };
  }

  public sortRanking(
    ranking: Array<ParticipantPerformance>
  ): Array<ParticipantPerformance> {
    const sortedRanking = [...ranking];
    sortedRanking.sort(
      (
        participantA: ParticipantPerformance,
        participantB: ParticipantPerformance
      ) => participantB.performance - participantA.performance
    );
    return sortedRanking;
  }
}
