import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login/login.service';
import { UserService } from 'src/app/services/user/user.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { Settings } from 'src/app/models/settings.model';
import { Roles } from 'src/app/models/roles.enum';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { REGEX_EMAIL } from 'src/app/constants/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: UntypedFormGroup;
  public errorMessage = '';
  public isMobile = false;
  public colSize = 2;

  public emailNotVerified = false;

  public loggingIn = false;

  private INCORRECT_USER_OR_PASSWORD = '';
  private NO_RESPONSE_FROM_SERVER = '';
  private NOT_AUTHORIZED = '';
  private VERIFICATION_EMAIL_SENT_SUCCESSFULLY = '';
  private VERIFICATION_EMAIL_ERROR = '';

  private observer: ResizeObserver;
  private observedElement: Element;

  public isProdEnvironment = environment.production;

  constructor(
    private settingsService: SettingsService,
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
    private zone: NgZone,
    private snackBar: MatSnackBar
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
      password: [
        '',
        Validators.compose([Validators.minLength(6), Validators.required]),
      ],
      persistence: [false, Validators.required],
    });

    this.getTranslations();

    this.loginForm.controls.email.statusChanges.subscribe(() => {
      this.emailNotVerified = false;
    });
  }

  get email(): AbstractControl {
    return this.loginForm.get('email');
  }
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }
  get settings(): Settings {
    return this.settingsService.settings;
  }

  get persistence(): AbstractControl {
    return this.loginForm.get('persistence');
  }

  ngOnInit(): void {
    const width = window.outerWidth;
    if (width < 1200) {
      this.colSize = 1;
    } else {
      this.colSize = 2;
    }

    this.observedElement = document.querySelector('.container-card-login');

    this.observer = new ResizeObserver((entries) => {
      let observerWidth = entries[0].contentRect.width;
      if (observerWidth === 0) {
        observerWidth = window.outerWidth;
      }
      if (observerWidth < 1200) {
        this.zone.run(() => {
          this.colSize = 1;
        });
      } else {
        this.zone.run(() => {
          this.colSize = 2;
        });
      }
    });

    this.observer.observe(this.observedElement);
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.observedElement);
  }

  public changeLanguage(lang: string): void {
    this.settingsService.changeLanguage(lang);
  }

  private getTranslations(): void {
    this.translateService
      .stream([
        'login.errors.INCORRECT_USER_OR_PASSWORD',
        'login.errors.NO_RESPONSE_FROM_SERVER',
        'login.errors.NOT_AUTHORIZED',
        'login.VERIFICATION_EMAIL_SENT_SUCCESSFULLY',
        'login.VERIFICATION_EMAIL_ERROR',
      ])
      .subscribe((values) => {
        this.INCORRECT_USER_OR_PASSWORD =
          values['login.errors.INCORRECT_USER_OR_PASSWORD'];
        this.NO_RESPONSE_FROM_SERVER =
          values['login.errors.NO_RESPONSE_FROM_SERVER'];
        this.NOT_AUTHORIZED = values['login.errors.NOT_AUTHORIZED'];
        this.VERIFICATION_EMAIL_SENT_SUCCESSFULLY =
          values['login.VERIFICATION_EMAIL_SENT_SUCCESSFULLY'];
        this.VERIFICATION_EMAIL_ERROR =
          values['login.VERIFICATION_EMAIL_ERROR'];
      });
  }

  public goToSignup(): void {
    this.router.navigate(['signup']);
  }

  public async loginUser(): Promise<void> {
    this.emailNotVerified = false;
    this.loggingIn = true;
    this.loginService
      .login(this.email.value, this.password.value, this.persistence.value)
      .then((userId) => {
        this.loginService
          .getUser(userId)
          .then(async (userSnapshot) => {
            if (userSnapshot.exists) {
              const user = new User(userSnapshot.data());
              //TODO: access the dashboard in a feature basis
              if (
                user.role <= Roles.TeamLeader ||
                user.role === Roles.ProjectManager
              ) {
                this.loginService.setUserLoggedIn(user.id);
                await this.userService.setUser(user);
                this.userService.prepareData(user.id);
                this.router.navigate(['']);
              } else {
                this.errorMessage = this.NOT_AUTHORIZED;
              }
            } else {
              this.errorMessage = this.INCORRECT_USER_OR_PASSWORD;
            }
          })
          .catch((error) => {
            this.errorMessage = this.NO_RESPONSE_FROM_SERVER;
            console.log('error: ', error);
          })
          .finally(() => {
            this.loggingIn = false;
          });
      })
      .catch((error) => {
        console.log('error: ', error);
        // Handle Errors here.
        if (error.code === 'auth/email-not-verified') {
          this.emailNotVerified = true;
        } else {
          if (
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/wrong-password'
          ) {
            this.errorMessage = this.INCORRECT_USER_OR_PASSWORD;
          } else {
            this.errorMessage = this.NO_RESPONSE_FROM_SERVER;
          }
        }
        this.loggingIn = false;
      });
  }

  public sendVerificationEmail(): void {
    this.emailNotVerified = false;
    this.loginService
      .sendVerificationEmail(
        this.loginForm.controls.email.value,
        this.settingsService.settings.lang
      )
      .then(() => {
        this.snackBar.open(this.VERIFICATION_EMAIL_SENT_SUCCESSFULLY, 'Ok', {
          duration: 10000,
          panelClass: 'snack-bar-color',
        });
      })
      .catch((error) => {
        console.log('error while sending verification email - error: ', error);
        this.snackBar.open(this.VERIFICATION_EMAIL_ERROR + error, 'Ok', {
          duration: 10000,
          panelClass: 'snack-bar-color',
        });
      });
  }

  public scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
