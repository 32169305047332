import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'move-me-dashboard';

  constructor(private translate: TranslateService) {
    this.initTranslate();
  }

  initTranslate(): void {
    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang('de');
    const browserLang = this.translate.getBrowserLang();

    if (browserLang !== 'de' && browserLang !== 'en') {
      this.translate.use('de'); // Set your language here
    } else {
      this.translate.use(browserLang);
    }
  }
}
