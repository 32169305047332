import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { IUserInitializer, User } from 'src/app/models/user.model';
import { Company } from 'src/app/models/company.model';
import { Team } from 'src/app/models/team.model';
import { Location } from 'src/app/models/location.model';
import { TeamsService } from '../teams/teams.service';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  public account: User;
  public password = '';

  constructor(
    private firebaseService: FirebaseService,
    private teamsService: TeamsService
  ) {}

  public newAccount(data: IUserInitializer): void {
    this.account = new User(data);
  }

  public updateAccount(data): void {
    Object.assign(this.account, data);
  }

  public updatePassword(newPassword: string): void {
    this.password = newPassword;
  }

  public createAccount(): Promise<void> {
    return this.firebaseService.createUserAccount(this.account, this.password);
  }

  public checkForUsedEmail(valueToSearch: string): Promise<boolean> {
    return this.firebaseService
      .checkForUsedEmail(valueToSearch.toLowerCase().trim())
      .then((signInMethods) => {
        return signInMethods.length > 0;
      })
      .catch((error) => {
        console.log('error while checking for user email - error: ', error);
        return Promise.reject(error);
      });
  }

  public validateCompanyCode(companyCode: string): Promise<Company> {
    return this.firebaseService
      .getCompany(companyCode)
      .then((companyDoc) => {
        if (companyDoc.exists) {
          return Promise.resolve(new Company(companyDoc.data()));
        } else {
          return Promise.reject('forms.companyCode.NOT_FOUND');
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public getCompanyLocations(companyId: string): Promise<Array<Location>> {
    return this.firebaseService
      .getCompanyLocations(companyId)
      .then((querySnapshot) => {
        return Promise.resolve(
          querySnapshot.docs.map(
            (docSnapshot) => new Location(docSnapshot.data())
          )
        );
      })
      .catch((error) => {
        console.log('error getting company locations: ', error);
        return Promise.reject(error);
      });
  }

  public setTeamOnUser(companyId: string, teamId: string): Promise<void> {
    return this.teamsService
      .getTeam(companyId, teamId)
      .then((team: Team) => {
        if (team) {
          this.updateAccount({
            teamId: team.id,
            currentTeamChallengeId: team.currentTeamChallengeId,
          });
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
