import { Deserializable } from './deserializable.model';
import { TrainingData } from './training-data.model';

export class Training implements Deserializable {
  public date: string;
  public trainings: Array<TrainingData>;

  constructor(date?: string, trainings?: Array<TrainingData>) {
    this.date = date ? date : null;
    this.trainings = trainings ? trainings : [];
  }

  public toObject(): Training {
    return Object.assign({}, this);
  }

  public deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
