import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { CompanyService } from 'src/app/services/company/company.service';
import { LoginService } from 'src/app/services/login/login.service';
import { UserService } from 'src/app/services/user/user.service';
import { TeamsService } from 'src/app/services/teams/teams.service';

import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { Team } from 'src/app/models/team.model';
import { Roles } from 'src/app/models/roles.enum';
import { Features } from 'src/app/models/features.enum';

import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  public FEATURE_TEAM_CHALLENGES = Features.TeamChallenge;
  public FEATURE_LOCATIONS = Features.Locations;

  public MOVE_ME_ADMIN_ROLE = Roles.MoveMeAdmin;
  public ADMIN_ROLE = Roles.Admin;
  public TEAM_LEADER_ROLE = Roles.TeamLeader;
  public PROJECT_MANAGER = Roles.ProjectManager;

  public isUpdating: boolean;

  public appVersion = environment.appVersion;

  public openChallengesSons = false;
  public openPaymentSons = false;

  constructor(
    private companyService: CompanyService,
    private loginService: LoginService,
    private userService: UserService,
    private teamsService: TeamsService,
    private router: Router,
    private layoutService: LayoutService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/dashboard.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'single-person',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/person-alone.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'arrow-send',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/arrow-send.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'shield-person',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/shield-person.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'clipboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/clipboard.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'info-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/info-circle.svg'
      )
    );
  }

  get company(): Company {
    return this.companyService.company;
  }
  get user(): User {
    return this.userService.user;
  }
  get team(): Team {
    return this.teamsService.team;
  }

  public logout(): void {
    this.loginService.logout();
    this.userService.removeUser();
    this.companyService.removeCompany();
    this.router.navigate(['login']);
  }

  get sidebarOpened(): boolean {
    return this.layoutService.sidebarOpened;
  }

  public updateChart(): void {
    this.isUpdating = true;
    this.companyService
      .updateCompanyData()
      .then(() => {
        this.isUpdating = false;
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }

  public goToSubscriptionPage(): void {
    window.open(
      'https://billing.stripe.com/p/login/test_14kdSs2Vrf884sUdQQ',
      '_blank'
    );
  }

  public challengesToggleSons(): void {
    this.openChallengesSons = !this.openChallengesSons;
    this.openPaymentSons = false;
  }

  public paymentToggleSons(): void {
    this.openPaymentSons = !this.openPaymentSons;
    this.openChallengesSons = false;
  }

  public closeCollapsibleItems(): void {
    this.openPaymentSons = false;
    this.openChallengesSons = false;
  }
}
