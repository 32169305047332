<mat-card class="cities-list-container">
  <mat-card-header>
    <mat-card-title>
      {{ citiesList.title | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content #citiesListCardContent>
    <div *ngFor="let region of citiesList.citiesChallenge.regions; index as regionIndex">
      <p class="region-name">{{ region.route.name }}</p>
      <mat-divider></mat-divider>
      <div *ngFor="let cityMarkerPoint of region.route.cityMarkerPoints; index as cityMarkerPointIndex">
        <div class="city-item" [ngClass]="{ 'current-stage': citiesList.cityChallengePerformance.isCurrentCity(regionIndex, cityMarkerPointIndex), 'lock-stage': !citiesList.cityChallengePerformance.isCityUnlock(regionIndex, cityMarkerPointIndex)}">
          <p class="position"> {{ cityMarkerPointIndex + 1 }} </p>
          <img class="flag" [src]="getFlagPath(cityMarkerPoint.city)">
          <p class="city-name">{{ cityMarkerPoint.city.translations.get(lang) ? cityMarkerPoint.city.translations.get(lang) : cityMarkerPoint.city.name }}</p>
          <mat-icon class="icon info-icon" *ngIf="citiesList.cityChallengePerformance.isCityUnlock(regionIndex, cityMarkerPointIndex) && cityMarkerPoint.cityInfoType && gettingCityInfoIndex !== cityMarkerPointIndex" (click)="openCityInfo(cityMarkerPoint, cityMarkerPointIndex)">info_outline</mat-icon>
          <mat-icon class="icon" *ngIf="!citiesList.cityChallengePerformance.isCityUnlock(regionIndex, cityMarkerPointIndex)">lock_outline</mat-icon>
          <mat-spinner [diameter]="24" *ngIf="gettingCityInfoIndex === cityMarkerPointIndex"></mat-spinner>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </mat-card-content>
</mat-card>