import { AfterContentInit, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MSECS_IN_A_DAY } from 'src/app/constants/constants';

@Component({
  selector: 'app-region-form',
  templateUrl: './region-form.component.html',
  styleUrls: ['./region-form.component.scss'],
})
export class RegionFormComponent implements AfterContentInit {
  @Input() regionDataForm: UntypedFormGroup;
  @Input() editableRoute = true;
  @Input() minDate: Date = new Date();

  constructor() {}

  ngAfterContentInit(): void {
    this.dateRange.controls.end.valueChanges.subscribe((dateEndValue) => {
      if (dateEndValue !== null) {
        const daysDifference = Math.round(
          (this.dateRange.controls.end.value.getTime() -
            this.dateRange.controls.start.value.getTime()) /
            MSECS_IN_A_DAY
        );

        this.regionDataForm.patchValue({
          duration: daysDifference + 1,
        });
      }
    });
  }

  get dateRange(): UntypedFormGroup {
    return this.regionDataForm.controls.dateRange as UntypedFormGroup;
  }

  get route(): UntypedFormGroup {
    return this.regionDataForm.controls.route as UntypedFormGroup;
  }
}
