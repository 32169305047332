import { Deserializable } from './deserializable.model';

export interface IAwardInitializer {
  name?: string;
  date?: Date;
}
export class Award implements Deserializable {
  public name: string;
  public date: Date;

  constructor(initializer?: IAwardInitializer) {
    Object.assign(this, initializer);
  }

  public deserialize(input: any): this {
    return Object.assign(this, input);
  }

  public toObject(): any {
    return Object.assign({}, this);
  }
}
