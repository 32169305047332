import { Injectable } from '@angular/core';

// Models
import { User } from 'src/app/models/user.model';
import { Company } from 'src/app/models/company.model';

// Services
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../settings/settings.service';

export interface Error {
  msg: string;
  code: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: User;

  // store the URL so we can redirect after logging in
  public redirectUrl: string;

  public today = new Date();
  public date =
    this.today.getDate().toString().padStart(2, '0') +
    '.' +
    (this.today.getMonth() + 1).toString().padStart(2, '0') +
    '.' +
    this.today.getFullYear();

  public errorMessages = {
    'auth/user-not-found': '',
    'auth/wrong-password': '',
    'auth/email-not-verified': '',
    default: '',
  };

  constructor(
    private localstorageService: LocalstorageService,
    private companyService: CompanyService,
    private firebaseService: FirebaseService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService,
    private settingsService: SettingsService
  ) {
    this.getTranslations();
    this.redirectUrl = '';

    this.subscriptionService.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.settingsService.changeLanguage(this.user.settings.lang);
      }
    });
  }

  get company(): Company {
    return this.companyService.company;
  }

  private getTranslations(): void {
    this.translateService
      .stream([
        'login.errors.INCORRECT_USER_OR_PASSWORD',
        'login.errors.NO_RESPONSE_FROM_SERVER',
        'login.errors.EMAIL_NOT_VERIFIED_TEXT',
      ])
      .subscribe((values) => {
        this.errorMessages['auth/user-not-found'] =
          values['login.errors.INCORRECT_USER_OR_PASSWORD'];
        this.errorMessages['auth/wrong-password'] =
          values['login.errors.INCORRECT_USER_OR_PASSWORD'];
        this.errorMessages['auth/email-not-verified'] =
          values['login.errors.EMAIL_NOT_VERIFIED_TEXT'];
        this.errorMessages.default =
          values['login.errors.NO_RESPONSE_FROM_SERVER'];
      });
  }

  public getUserById(userId: string): Promise<User> {
    return this.firebaseService
      .getUser(userId)
      .then((userSnapshot) => {
        return new User(userSnapshot.data());
      })
      .catch((error) => {
        console.log('error: ', error);
        return Promise.reject();
      });
  }

  public async setUser(user: User): Promise<void> {
    this.subscriptionService.setUser(user);
    this.localstorageService.setUserId(this.user.id);
  }

  public removeUser(): void {
    this.user = null;
    this.subscriptionService.unsubscribeFromUser();
    this.localstorageService.setUserId(null);
  }

  public deleteUser(email: string, password: string): Promise<void> {
    return this.firebaseService.deleteUser(email, password);
  }

  public async prepareData(userId: string): Promise<void> {
    try {
      const user = await this.getUserById(userId);
      this.subscriptionService.setUser(user);
      const company = await this.companyService.getCompany(user.companyId);
      this.subscriptionService.setCompany(company);

      this.subscribeToFirebaseData(user.id, company.id);

      // TODO: add rest of the data to prepare experience
    } catch (error) {
      console.log('error preparing data: ', error);
    }
  }

  public subscribeToFirebaseData(userId: string, companyId: string): void {
    this.subscriptionService.subscribeToUser(userId);
    this.subscriptionService.subscribeToCompany(companyId);
  }

  public updateUser(
    update: Partial<User>,
    userId: string = this.user.id
  ): Promise<void> {
    return this.firebaseService.updateUser(userId, update);
  }

  public updateLang(lang: string): void {
    const settings = this.user.settings;
    settings.lang = lang;
    this.updateUser({ settings: settings.toObject() })
      .then(() => {
        this.settingsService.changeLanguage(lang);
      })
      .catch((error) => {
        console.log('error while updating user ', error);
      });
  }

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    return this.firebaseService.changePassword(oldPassword, newPassword);
  }

  public changeEmail(newEmail: string, oldPassword: string): Promise<void> {
    return this.firebaseService.changeEmail(this.user, newEmail, oldPassword);
  }

  public resetPassword(email: string): Promise<void> {
    const lang = this.user
      ? this.user.settings.lang
      : this.translateService.currentLang;
    return this.firebaseService
      .resetPassword(email, lang)
      .catch((firebaseError) => {
        const error = {
          code: firebaseError.code,
          // if error code is known, use the translation
          msg: this.errorMessages[firebaseError.code]
            ? this.errorMessages[firebaseError.code]
            : // if not, show the default
              this.errorMessages.default,
        };
        return Promise.reject(error);
      });
  }
}
