import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Error, UserService } from 'src/app/services/user/user.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { REGEX_EMAIL } from 'src/app/constants/constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: UntypedFormGroup;
  public isMobile = false;
  public colSize = 2;

  private RESET_PASSWORD_EMAIL_SENT_CORRECTLY = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.getTranslations();
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
    });
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
        if (this.isMobile) {
          this.colSize = 1;
        } else {
          this.colSize = 2;
        }
      });
  }

  get email(): AbstractControl {
    return this.resetPasswordForm.get('email');
  }

  ngOnInit(): void {}

  private getTranslations(): void {
    this.translateService
      .stream(['RESET_PASSWORD_PAGE.RESET_PASSWORD_EMAIL_SENT_CORRECTLY'])
      .subscribe((values) => {
        this.RESET_PASSWORD_EMAIL_SENT_CORRECTLY =
          values['RESET_PASSWORD_PAGE.RESET_PASSWORD_EMAIL_SENT_CORRECTLY'];
      });
  }

  public resetPassword(): void {
    this.userService
      .resetPassword(this.email.value)
      .then(() => {
        this.snackBar.open(this.RESET_PASSWORD_EMAIL_SENT_CORRECTLY, 'Ok', {
          duration: 4000,
          panelClass: 'snack-bar-color',
        });
        this.router.navigate(['/login']);
      })
      .catch((error: Error) => {
        console.log('error while resetting password: ', error);
        this.snackBar.open(error.msg, 'Ok', {
          duration: 4000,
          panelClass: 'snack-bar-color',
        });
      });
  }

  public scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
