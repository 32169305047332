import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { REGEX_EMAIL, REGEX_NAME } from 'src/app/constants/constants';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  public helpForm: UntypedFormGroup;

  public sendingMail = false;

  public SEND_BUTTON_LABEL = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.getTranslations();
    const messageRegex: RegExp = /^[^<>]+$/;
    const phoneRegex: RegExp = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/;
    this.helpForm = this.formBuilder.group({
      firstName: ['', [Validators.pattern(REGEX_NAME)]],
      lastName: ['', [Validators.pattern(REGEX_NAME)]],
      email: ['', [Validators.pattern(REGEX_EMAIL)]],
      phone: ['', [Validators.pattern(phoneRegex)]],
      privacyPolicy: [false],
      message: ['', [Validators.required, Validators.pattern(messageRegex)]],
    });
  }

  get firstName(): AbstractControl {
    return this.helpForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.helpForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.helpForm.get('email');
  }

  get phone(): AbstractControl {
    return this.helpForm.get('phone');
  }

  get message(): AbstractControl {
    return this.helpForm.get('message');
  }

  get user(): User {
    return this.userService.user;
  }

  getTranslations(): void {
    this.translateService
      .stream(['HELP_SEND_BUTTON_LABEL'])
      .subscribe((values) => {
        this.SEND_BUTTON_LABEL = values.HELP_SEND_BUTTON_LABEL;
      });
  }

  ngOnInit(): void {}

  sendMessage(): void {
    this.sendingMail = true;

    let email: string =
      this.email.value !== '' ? this.email.value : this.user.email;
    email = email.toLowerCase().trim();
    const name =
      this.firstName.value !== '' && this.lastName.value !== ''
        ? this.firstName.value + ' ' + this.lastName.value
        : this.user.displayName;

    this.firebaseService
      .sendSupportEmail(email, name, this.message.value, this.phone.value)
      .then((result) => {
        this.sendingMail = false;
        if (result.data.accepted.length > 0) {
          this.snackBar.open(
            'We have received your email. We will contact you soon.',
            'Ok',
            {
              duration: 4000,
              panelClass: 'snack-bar-color',
            }
          );
        } else if (result.data.rejected.length > 0) {
          this.snackBar.open('An error has happened, please try again.', 'Ok', {
            duration: 4000,
            panelClass: 'snack-bar-color',
          });
        }
      })
      .catch((error) => {
        console.log('sendSupportEmail - error: ', error);
      });
  }
}
