<table mat-table [dataSource]="citiesChallengesDataSource" matSort>
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMPANY_INFO.CITIES_CHALLENGE.TABLE.NAME' | translate }}</th>
    <td mat-cell *matCellDef="let citiesChallenge">{{ citiesChallenge.name }}</td>
  </ng-container>

  <!-- Start Date Column -->
  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMPANY_INFO.CITIES_CHALLENGE.TABLE.START_DATE' | translate }}</th>
    <td mat-cell *matCellDef="let citiesChallenge">{{ citiesChallenge.startDate | date: 'dd.MM.yyyy' }}</td>
  </ng-container>

  <!-- End Date Column -->
  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'COMPANY_INFO.CITIES_CHALLENGE.TABLE.END_DATE' | translate }}</th>
    <td mat-cell *matCellDef="let citiesChallenge">{{ citiesChallenge.endDate | date: 'dd.MM.yyyy' }}</td>
  </ng-container>

  <!-- Project Plan Column -->
  <ng-container matColumnDef="projectPlan">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Project Plan
    </th>
    <td mat-cell *matCellDef="let citiesChallenge">
      <button mat-raised-button #projectPlanButton (click)="sendProjectPlan(citiesChallenge, projectPlanButton)">
        <mat-spinner diameter="20" *ngIf="projectPlanButton.disabled"></mat-spinner>
        <mat-icon *ngIf="!projectPlanButton.disabled">outgoing_mail</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let citiesChallenge">
      <button mat-raised-button (click)="goToCitiesChallenge(citiesChallenge.id)">
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="citiesChallengeColumns"></tr>
  <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: citiesChallengeColumns"></tr>
</table>
