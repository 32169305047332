import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { User } from 'src/app/models/user.model';
import { Company } from 'src/app/models/company.model';
import { UserService } from 'src/app/services/user/user.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { SettingsValidators } from 'src/app/validators/settings-validators';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { Settings } from 'src/app/models/settings.model';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { LoginService } from 'src/app/services/login/login.service';
import { REGEX_EMAIL, REGEX_NAME } from 'src/app/constants/constants';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public user: User;
  public company: Company;
  public settingsForm: UntypedFormGroup;
  public changeEmailForm: UntypedFormGroup;
  public changePasswordForm: UntypedFormGroup;

  public companyName: AbstractControl;
  public displayName: AbstractControl;

  public savingSettings = false;
  public changingEmail = false;
  public changingPassword = false;

  public SAVE_SETTINGS_BUTTON_LABEL = '';
  public SAVE_EMAIL_BUTTON_LABEL = '';
  public SAVE_PASSWORD_BUTTON_LABEL = '';
  public EMAIL_CHANGED_SUCCESSFULLY = '';
  public PASSWORD_CHANGED_SUCCESSFULLY = '';
  public SETTINGS_SUBTITLE_CHANGE_LANGUAGE = '';

  private CHANGE_EMAIL_CONFIRMATION_DIALOG = {
    TITLE: '',
    MESSAGE: '',
  };

  private CHANGE_PASSWORD_CONFIRMATION_DIALOG = {
    TITLE: '',
    MESSAGE: '',
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,
    private loginService: LoginService
  ) {
    this.getTranslations();
    const companyNameRegex: RegExp = /^[A-Za-zÀ-ú0-9 ]*$/;

    this.settingsForm = this.formBuilder.group({
      companyId: [{ value: '', disabled: true }],
      companyName: ['', [Validators.pattern(companyNameRegex)]],
      displayName: ['', [Validators.pattern(REGEX_NAME)]],
      email: [{ value: '', disabled: true }, [Validators.pattern(REGEX_EMAIL)]],
    });

    this.subscriptionService.user.subscribe((user) => {
      if (user) {
        this.user = user;

        this.settingsForm.patchValue({
          companyId: this.user.companyId,
          displayName: this.user.displayName,
          email: this.user.email,
        });

        this.displayName = this.settingsForm.get('displayName');
      }
    });

    this.subscriptionService.company.subscribe((company) => {
      if (company) {
        this.company = company;

        this.settingsForm.patchValue({
          companyName: this.company.name,
        });

        this.companyName = this.settingsForm.get('companyName');
      }
    });

    this.changeEmailForm = this.formBuilder.group(
      {
        newEmail: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
        confirmEmail: [
          '',
          [Validators.required, Validators.pattern(REGEX_EMAIL)],
        ],
        newEmailPassword: [
          '',
          Validators.compose([Validators.minLength(6), Validators.required]),
        ],
      },
      {
        validator: SettingsValidators.MatchEmail,
      }
    );

    this.changePasswordForm = this.formBuilder.group(
      {
        newPassword: [
          '',
          Validators.compose([Validators.minLength(6), Validators.required]),
        ],
        confirmPassword: [
          '',
          Validators.compose([Validators.minLength(6), Validators.required]),
        ],
        oldPassword: [
          '',
          Validators.compose([Validators.minLength(6), Validators.required]),
        ],
      },
      {
        validator: SettingsValidators.MatchPassword,
      }
    );
  }

  get settings(): Settings {
    return this.settingsService.settings;
  }

  get newEmail(): AbstractControl {
    return this.changeEmailForm.get('newEmail');
  }

  get confirmEmail(): AbstractControl {
    return this.changeEmailForm.get('confirmEmail');
  }

  get newEmailPassword(): AbstractControl {
    return this.changeEmailForm.get('newEmailPassword');
  }

  get newPassword(): AbstractControl {
    return this.changePasswordForm.get('newPassword');
  }

  get confirmPassword(): AbstractControl {
    return this.changePasswordForm.get('confirmPassword');
  }

  get oldPassword(): AbstractControl {
    return this.changePasswordForm.get('oldPassword');
  }

  getTranslations(): void {
    this.translateService
      .stream([
        'SETTINGS.SAVE_SETTINGS_BUTTON_LABEL',
        'SETTINGS.SAVE_EMAIL_BUTTON_LABEL',
        'SETTINGS.SAVE_PASSWORD_BUTTON_LABEL',
        'SETTINGS.EMAIL_CHANGED_SUCCESSFULLY',
        'SETTINGS.PASSWORD_CHANGED_SUCCESSFULLY',
        'SETTINGS.SUBTITLE_CHANGE_LANGUAGE',
      ])
      .subscribe((values) => {
        this.SAVE_SETTINGS_BUTTON_LABEL =
          values['SETTINGS.SAVE_SETTINGS_BUTTON_LABEL'];
        this.SAVE_EMAIL_BUTTON_LABEL =
          values['SETTINGS.SAVE_EMAIL_BUTTON_LABEL'];
        this.SAVE_PASSWORD_BUTTON_LABEL =
          values['SETTINGS.SAVE_PASSWORD_BUTTON_LABEL'];
        this.EMAIL_CHANGED_SUCCESSFULLY =
          values['SETTINGS.EMAIL_CHANGED_SUCCESSFULLY'];
        this.PASSWORD_CHANGED_SUCCESSFULLY =
          values['SETTINGS.PASSWORD_CHANGED_SUCCESSFULLY'];
        this.SETTINGS_SUBTITLE_CHANGE_LANGUAGE =
          values['SETTINGS.SUBTITLE_CHANGE_LANGUAGE'];
      });
  }

  ngOnInit(): void {}

  public saveSettings(): void {
    this.savingSettings = true;

    let companyPromise: Promise<void> = Promise.resolve();
    let userPromise: Promise<void> = Promise.resolve();

    if (
      this.companyName.dirty &&
      this.companyName.value !== this.company.name
    ) {
      companyPromise = this.companyService.updateCompanyName(
        this.companyName.value
      );
    }

    if (
      this.displayName.dirty &&
      this.displayName.value !== this.user.displayName
    ) {
      userPromise = this.userService.updateUser({
        displayName: this.displayName.value,
      });
    }

    Promise.all([companyPromise, userPromise])
      .then(() => {
        this.savingSettings = false;
        this.snackBar.open('Settings saved successfully.', 'Ok', {
          duration: 4000,
          panelClass: 'snack-bar-color',
        });
      })
      .catch((error) => {
        this.savingSettings = false;
        console.log('error: ', error);
      });
  }

  public changeEmail(): void {
    const translations = this.translateService.instant(
      [
        'SETTINGS.CHANGE_EMAIL_CONFIRMATION_DIALOG.TITLE',
        'SETTINGS.CHANGE_EMAIL_CONFIRMATION_DIALOG.MESSAGE',
      ],
      {
        newEmail: this.newEmail.value,
      }
    );
    this.CHANGE_EMAIL_CONFIRMATION_DIALOG.TITLE =
      translations['SETTINGS.CHANGE_EMAIL_CONFIRMATION_DIALOG.TITLE'];
    this.CHANGE_EMAIL_CONFIRMATION_DIALOG.MESSAGE =
      translations['SETTINGS.CHANGE_EMAIL_CONFIRMATION_DIALOG.MESSAGE'];
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: new ConfirmationDialogData({
        title: this.CHANGE_EMAIL_CONFIRMATION_DIALOG.TITLE,
        message: this.CHANGE_EMAIL_CONFIRMATION_DIALOG.MESSAGE,
        action: () => {
          this.changingEmail = true;
          const email = this.newEmail.value;
          this.userService
            .changeEmail(
              email.toLowerCase().trim(),
              this.newEmailPassword.value
            )
            .then(() => {
              this.changingEmail = false;
              this.settingsForm.get('email').setValue(this.newEmail.value);
              this.userService.updateUser({ email: this.newEmail.value });
              this.snackBar.open(this.EMAIL_CHANGED_SUCCESSFULLY, 'Ok', {
                duration: 4000,
                panelClass: 'snack-bar-color',
              });
              this.loginService.logout();
            })
            .catch((error) => {
              this.changingEmail = false;
              console.log('error: ', error);
            });
        },
      }),
    });
  }

  public changePassword(): void {
    const translations = this.translateService.instant(
      [
        'SETTINGS.CHANGE_PASSWORD_CONFIRMATION_DIALOG.TITLE',
        'SETTINGS.CHANGE_PASSWORD_CONFIRMATION_DIALOG.MESSAGE',
      ],
      {
        newEmail: this.newEmail.value,
      }
    );
    this.CHANGE_PASSWORD_CONFIRMATION_DIALOG.TITLE =
      translations['SETTINGS.CHANGE_PASSWORD_CONFIRMATION_DIALOG.TITLE'];
    this.CHANGE_PASSWORD_CONFIRMATION_DIALOG.MESSAGE =
      translations['SETTINGS.CHANGE_PASSWORD_CONFIRMATION_DIALOG.MESSAGE'];

    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: new ConfirmationDialogData({
        title: this.CHANGE_PASSWORD_CONFIRMATION_DIALOG.TITLE,
        message: this.CHANGE_PASSWORD_CONFIRMATION_DIALOG.MESSAGE,
        action: () => {
          this.changingPassword = true;
          this.userService
            .changePassword(this.oldPassword.value, this.newPassword.value)
            .then(() => {
              this.changingPassword = false;
              this.snackBar.open(this.PASSWORD_CHANGED_SUCCESSFULLY, 'Ok', {
                duration: 4000,
                panelClass: 'snack-bar-color',
              });
              this.loginService.logout();
            })
            .catch((error) => {
              this.changingPassword = false;
              console.log('error: ', error);
            });
        },
      }),
    });
  }

  public changeLanguage(lang: string): void {
    this.settingsService.changeLanguage(lang);
  }
}
