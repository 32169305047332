<h1 mat-dialog-title>{{ 'Add Random Teams' }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>How many teams would you like to add?</mat-label>
    <mat-select required [(value)]="teamsAmount">
      <mat-option
        *ngFor="let team of availableTeams; let i = index"
        value="{{ i + 1 }}"
      >
        {{ i + 1 }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button [mat-dialog-close]="teamsAmount" cdkFocusInitial>Continue</button>
</div>
