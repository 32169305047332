<mat-accordion class="table-view">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'TEAM_CHALLENGE_INFO.TEAMS' | translate }} {{ ' (' + teamForms.length + ') '}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [ngStyle]="{'display': panelOpenState ? '' : 'none'}">
      <mat-form-field>
        <mat-label>{{ 'TEAMS_TABLE.FILTER.LABEL' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [placeholder]="'TEAMS_TABLE.FILTER.PLACEHOLDER' | translate " #input>
      </mat-form-field>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <!-- Avatar Column -->
          <ng-container matColumnDef="avatar">
            <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS_TABLE.AVATAR' | translate }} </th>
            <td mat-cell *matCellDef="let teamForm; let i = index">
              <app-avatar [formControl]="teamForm.get('avatar')" [index]="i"></app-avatar>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE.NAME' | translate }} </th>
            <td mat-cell *matCellDef="let teamForm">
              <mat-form-field [hideRequiredMarker]="true">
                <mat-label></mat-label>
                <input matInput type="text" [formControl]="teamForm.get('name')" placeholder="Name" />
                <mat-error>{{ getFormErrors(teamForm.controls.name, 'TEAMS_TABLE.TEAM_FORM', 'name') | translate }}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS_TABLE.COLOR' | translate }} </th>
            <td mat-cell *matCellDef="let teamForm, let i = index">
              <label class="color-input-label" [for]="'color-input-' + i">
                <div class="color-input" [style.background]="teamForm.controls.color.value">
                  <input [id]="'color-input-' + i" matInput
                    [cpPosition]="'top-right'"
                    [formControl]="teamForm.get('color')"
                    [(colorPicker)]="teamForm.controls.color.value"
                    [cpOKButton]="true"
                    [cpOKButtonText]="'CONFIRM' | translate"
                    (colorPickerChange)="onChangeColor($event, teamForm)"
                  />
                </div>
                <p>{{ teamForm.controls.color.value }}</p>
              </label>
            </td>
          </ng-container>

          <!-- Location Column -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'TEAMS_TABLE.LOCATION' | translate }} </th>
            <td mat-cell *matCellDef="let teamForm, let i = index">
              <mat-form-field>
                <mat-select [formControl]="teamForm.get('location')">
                  <mat-option *ngFor="let location of teamsTableElement.locations" [value]="location.id">
                    {{location.name}}
                  </mat-option>
                </mat-select>
                <mat-error>{{ getFormErrors(teamForm.controls.location, 'TEAMS_TABLE.TEAM_FORM', 'location') | translate }}</mat-error>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Delete Column -->
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let teamForm">
              <mat-icon class="action-icon" (click)="removeTeam(teamForm)">delete_outline</mat-icon>
            </td>
          </ng-container>

          <!-- Save Column -->
          <ng-container matColumnDef="save">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let teamForm">
              <mat-icon
                [ngClass]="teamForm.dirty && teamForm.valid ? 'action-icon' : ''"
                [ngStyle]="{ 'color': teamForm.dirty && teamForm.valid ? 'var(--color-primary)' : '' }"
                (click)="teamForm.dirty && teamForm.valid ? saveTeam(teamForm) : ''"
              >
                save_outline
              </mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">{{ 'TEAMS_TABLE.FILTER.MESSAGE' | translate}} "{{input.value}}"</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of teams"></mat-paginator>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>


