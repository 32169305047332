import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsolutePipe } from './absolute/absolute.pipe';
import { ChallengeDurationPipe } from './challenge-duration/challenge-duration.pipe';
import { BigNumbersFormatPipe } from 'src/app/pipes/big-numbers-format/big-numbers-format.pipe';

@NgModule({
  declarations: [AbsolutePipe, ChallengeDurationPipe, BigNumbersFormatPipe],
  imports: [CommonModule],
  exports: [AbsolutePipe, ChallengeDurationPipe, BigNumbersFormatPipe],
})
export class PipesModule {}
