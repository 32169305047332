import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ChallengeSchema } from 'src/app/models/challenge-schema.model';
import { ChallengeTypes } from 'src/app/models/challenge-types.enum';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { PersonalChallengeSchema } from 'src/app/models/personal-challenge-schema/personal-challenge-schema';
import { PointsSourceBasis } from 'src/app/models/points-source-basis.enum';
import { PointsSourceType } from 'src/app/models/points-source-type.enum';
import { PointsSource } from 'src/app/models/points-source.model';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-new-personal-challenge-dialog',
  templateUrl: './new-personal-challenge-dialog.component.html',
  styleUrls: ['./new-personal-challenge-dialog.component.scss'],
})
export class NewPersonalChallengeDialogComponent implements OnInit {
  public challengeDataForm: UntypedFormGroup;
  public pointsSourcesDataForm: UntypedFormGroup;

  public pointsSourceDataSource = new MatTableDataSource();
  public pointsSourceColumns: string[] = [
    'id',
    'description',
    'basis',
    'expectedPerformance',
    'type',
    'pointsAwarded',
    'delete',
  ];

  public challengeTypesKeys: Array<number> = Object.keys(ChallengeTypes)
    .map((key) => ChallengeTypes[key])
    .filter((value) => typeof value === 'number') as number[];

  public challengeTypesNames: Array<string> = Object.keys(ChallengeTypes)
    .map((key) => ChallengeTypes[key])
    .filter((value) => typeof value === 'string') as string[];

  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as string[];

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as string[];

  public allPointsSources: Array<PointsSource> = [];
  public selectablePointsSources: Array<PointsSource> = [];
  public pointsSourceIds: Array<string> = [];

  public showSpinner = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<NewPersonalChallengeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonalChallengeSchema
  ) {
    if (data) {
      this.challengeDataForm = this.formBuilder.group({
        challengeId: [{ value: data.id, disabled: true }, Validators.required],
        initialPoints: [data.initialPoints, Validators.required],
        name: [data.name, Validators.required],
        type: [String(data.type), Validators.required],
        minLevel: [data.minLevel, Validators.required],
        maxLevel: [data.maxLevel, Validators.required],
        pointsToLevelUp: [data.pointsToLevelUp, Validators.required],
        pointsToHoldLevel: [data.pointsToHoldLevel, Validators.required],
        initialLevel: [data.initialLevel, Validators.required],
        levelDuration: [data.levelDuration, Validators.required],
        canAscend: [
          { value: data.canAscend, disabled: true },
          Validators.required,
        ],
        canDescend: [
          { value: data.canDescend, disabled: true },
          Validators.required,
        ],
        hasFixedDate: [
          { value: data.hasFixedDate, disabled: true },
          Validators.required,
        ],
      });
      this.pointsSourceIds = Array.from(data.pointsSourceIds);
    } else {
      this.challengeDataForm = this.formBuilder.group({
        challengeId: ['', Validators.required],
        initialPoints: [0, Validators.required],
        name: ['', Validators.required],
        companyId: [{ value: '', disabled: true }, Validators.required],
        type: ['0', Validators.required],
        minLevel: [1, Validators.required],
        maxLevel: [26, Validators.required],
        pointsToLevelUp: [14, Validators.required],
        pointsToHoldLevel: [10, Validators.required],
        initialLevel: [1, Validators.required],
        levelDuration: [7, Validators.required],
        canAscend: [{ value: true, disabled: true }, Validators.required],
        canDescend: [{ value: true, disabled: true }, Validators.required],
        hasFixedDate: [{ value: false, disabled: true }, Validators.required],
      });
    }

    this.pointsSourcesDataForm = this.formBuilder.group({
      pointsSourceId: ['', Validators.required],
    });

    this.challengeDataForm.get('type').valueChanges.subscribe((type) => {
      switch (Number(type)) {
        case ChallengeTypes.Walk:
          this.challengeDataForm.get('canAscend').setValue(true);
          this.challengeDataForm.get('canDescend').setValue(true);
          this.challengeDataForm.get('hasFixedDate').setValue(false);
          break;

        case ChallengeTypes.WalkFixedDate:
          this.challengeDataForm.get('canAscend').setValue(true);
          this.challengeDataForm.get('canDescend').setValue(true);
          this.challengeDataForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyDownwards:
          this.challengeDataForm.get('canAscend').setValue(false);
          this.challengeDataForm.get('canDescend').setValue(true);
          this.challengeDataForm.get('hasFixedDate').setValue(true);
          break;

        case ChallengeTypes.WalkFixedDateOnlyUpwards:
          this.challengeDataForm.get('canAscend').setValue(true);
          this.challengeDataForm.get('canDescend').setValue(false);
          this.challengeDataForm.get('hasFixedDate').setValue(true);
          break;
      }
    });
  }

  ngOnInit(): void {
    this.adminService
      .getPointsSources()
      .then((pointSources) => {
        this.allPointsSources = pointSources;
        this.selectablePointsSources = [...this.allPointsSources];

        if (this.pointsSourceIds.length !== 0) {
          this.selectablePointsSources = this.selectablePointsSources.filter(
            (pointSource) => !this.pointsSourceIds.includes(pointSource.id)
          );

          this.pointsSourceDataSource = new MatTableDataSource(
            this.allPointsSources.filter((pointSource) =>
              this.pointsSourceIds.includes(pointSource.id)
            )
          );
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        this.selectablePointsSources = [];
      });
  }

  public addPointsSource(): void {
    this.pointsSourceIds.push(
      this.pointsSourcesDataForm.controls.pointsSourceId.value
    );
    this.pointsSourceDataSource = new MatTableDataSource(
      this.allPointsSources.filter((pointSource) =>
        this.pointsSourceIds.includes(pointSource.id)
      )
    );
    this.selectablePointsSources.splice(
      this.selectablePointsSources.findIndex(
        (pointsSources) =>
          pointsSources.id ===
          this.pointsSourcesDataForm.controls.pointsSourceId.value
      ),
      1
    );
    this.pointsSourcesDataForm.controls.pointsSourceId.setValue('');
    this.pointsSourcesDataForm.controls.pointsSourceId.markAsUntouched();
  }

  public removePointsSource(pointsSourceId: string): void {
    this.pointsSourceIds.splice(
      this.pointsSourceIds.findIndex(
        (pointsSources) => pointsSources === pointsSourceId
      ),
      1
    );
    this.selectablePointsSources.push(
      this.allPointsSources.find(
        (pointsSource) => pointsSource.id === pointsSourceId
      )
    );
    this.pointsSourceDataSource = new MatTableDataSource(
      this.allPointsSources.filter((companyPointSource) =>
        this.pointsSourceIds.includes(companyPointSource.id)
      )
    );
  }

  public close(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.showSpinner = true;
    if (this.challengeDataForm.valid) {
      const challengeId = this.challengeDataForm.controls.challengeId.value;
      const pointsSourceIds = this.pointsSourceIds;

      const challengeSchema = new PersonalChallengeSchema({
        id: challengeId,
        initialPoints: this.challengeDataForm.controls.initialPoints.value,
        name: this.challengeDataForm.controls.name.value,
        type: Number(this.challengeDataForm.controls.type.value),
        maxLevel: this.challengeDataForm.controls.maxLevel.value,
        minLevel: this.challengeDataForm.controls.minLevel.value,
        pointsToLevelUp: this.challengeDataForm.controls.pointsToLevelUp.value,
        pointsToHoldLevel: this.challengeDataForm.controls.pointsToHoldLevel
          .value,
        initialLevel: this.challengeDataForm.controls.initialLevel.value,
        pointsSourceIds,
        levelDuration: this.challengeDataForm.controls.levelDuration.value,
        canAscend: this.challengeDataForm.controls.canAscend.value,
        canDescend: this.challengeDataForm.controls.canDescend.value,
        hasFixedDate: this.challengeDataForm.controls.hasFixedDate.value,
      });

      this.adminService
        .createPersonalChallenge(challengeSchema)
        .then(() => {
          this.dialogRef.close();
        })
        .catch((error) => {
          // TODO: show error toast
          console.log('error: ', error);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    }
  }
}
