import { CompletedChallenge } from './completed-challege.model';
import { Features } from './features.enum';
import { CompanyStatus } from './company-status.enum';
import { LicenceTypes } from './licence-types.enum';
import { WhiteLabelDetails } from 'src/app/models/white-label-details.model';
import { Locations } from './locations.model';

export interface ICompanyInitializer {
  id?: string;
  name?: string;
  adminEmail?: Array<string>;
  users?: Array<string>;
  inactiveUsers?: Array<string>;
  completedChallenges?: Array<CompletedChallenge>;
  avatar?: string;
  availableLicenses?: number;
  licence?: LicenceTypes;
  color?: string;
  features?: Array<Features>;
  whiteLabel?: WhiteLabelDetails;
  teamIds?: Array<string>;
  currentTeamChallenges?: Array<string>;
  nextTeamChallenges?: Array<string>;
  currentDistanceChallenge?: string;
  useDistanceChallengeFactor?: boolean;
  locationsInfo?: Locations;
  locationLevels?: number;
  status?: CompanyStatus;
  isTestCompany?: boolean;
  eventIds?: Array<string>;
  managerCompanyIds?: Array<string>;
  currentGroupRunChallenge?: string;
}

export class Company {
  public id: string = null;
  public name: string = null;
  public adminEmail: Array<string> = [];
  public users: Array<string> = [];
  public inactiveUsers: Array<string> = [];
  public completedChallenges: Array<CompletedChallenge> = [];
  public avatar: string = null;
  public availableLicenses: number = null;
  public licence: LicenceTypes = null;
  public color: string = null;
  public features: Set<Features> = new Set<Features>();
  public whiteLabel: WhiteLabelDetails = null;

  // teams
  public teamIds: Array<string> = [];
  public currentTeamChallenges: Array<string> = [];
  public nextTeamChallenges: Array<string> = [];

  // distance challenge
  public currentDistanceChallenge: string = null;

  public useDistanceChallengeFactor = false;

  // Locations
  public locationsInfo: Locations = null;
  public locationLevels = 0;

  public status: CompanyStatus = CompanyStatus.InPreparation;

  public isTestCompany = false;

  // events

  public eventIds: Array<string> = [];

  // Project Manager
  public managerCompanyIds: Set<string> = new Set<string>();

  // Group Run
  public currentGroupRunChallenge: string = null;

  constructor(initializer?: ICompanyInitializer) {
    Object.assign(this, initializer);
    if (initializer) {
      this.features = initializer.features
        ? new Set<Features>(initializer.features)
        : new Set<Features>();

      this.locationsInfo =
        this.features.has(Features.Locations) && initializer.locationsInfo
          ? new Locations(initializer.locationsInfo)
          : null;

      this.whiteLabel =
        this.features.has(Features.WhiteLabel) && initializer.whiteLabel
          ? new WhiteLabelDetails(initializer.whiteLabel)
          : null;

      this.managerCompanyIds =
        !this.features.has(Features.ProjectManager) &&
        initializer.managerCompanyIds
          ? new Set<string>(initializer.managerCompanyIds)
          : new Set<string>();

      if (initializer.completedChallenges) {
        this.completedChallenges = initializer.completedChallenges.map(
          (completedChallenge) =>
            new CompletedChallenge().deserialize(completedChallenge)
        );
      }

      if (initializer.users) {
        this.users = initializer.users.map((user: string) => user);
      }

      if (initializer.teamIds) {
        this.teamIds = initializer.teamIds.map((teamId: string) => teamId);
      }

      if (initializer.eventIds) {
        this.eventIds = initializer.eventIds.map((eventId: string) => eventId);
      }

      if (initializer.currentTeamChallenges) {
        this.currentTeamChallenges = initializer.currentTeamChallenges.map(
          (currentTeamChallenge: string) => currentTeamChallenge
        );
      }

      if (initializer.nextTeamChallenges) {
        this.nextTeamChallenges = initializer.nextTeamChallenges.map(
          (nextTeamChallenge: string) => nextTeamChallenge
        );
      }

      if (initializer.inactiveUsers) {
        this.inactiveUsers = initializer.inactiveUsers.map(
          (inactiveUser: string) => inactiveUser
        );
      }
    }

    if (typeof this.licence === 'string') {
      this.licence = Number(this.licence);
    }

    if (typeof this.availableLicenses === 'string') {
      this.availableLicenses = Number(this.availableLicenses);
    }

    if (this.features.has(Features.Locations) && this.locationLevels === 0) {
      this.locationLevels = 1;
    }
  }

  public toObject(): any {
    const completedChallenges = this.completedChallenges.map(
      (completedChallenge) => completedChallenge.toObject()
    );
    const features = Array.from(this.features);
    const managerCompanyIds = Array.from(this.managerCompanyIds);
    let locationsInfo = null;
    if (this.features.has(Features.Locations)) {
      locationsInfo = this.locationsInfo.toObject();
    }
    let whiteLabel = null;
    if (this.features.has(Features.WhiteLabel)) {
      whiteLabel = this.whiteLabel.toObject();
    }
    const returnObject = Object.assign({}, this);
    return Object.assign(returnObject, {
      completedChallenges,
      features,
      locationsInfo,
      whiteLabel,
      managerCompanyIds,
    });
  }
}
