import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company.model';
import { Features } from 'src/app/models/features.enum';
import { Notification } from 'src/app/models/notification.model';

// Models
import { Roles } from 'src/app/models/roles.enum';
import { SendNotificationType } from 'src/app/models/send-notification-type';
import { Team } from 'src/app/models/team.model';
import { User } from 'src/app/models/user.model';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ProjectManagerCompanyService } from 'src/app/services/project-manager-company/project-manager-company.service';

// Services
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
import { TeamsService } from 'src/app/services/teams/teams.service';

interface INotificationTypeByRole {
  [prop: number]: SendNotificationType;
}

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss'],
})
export class SendNotificationComponent implements OnInit {
  @Input() adminPage: boolean = false;
  public user: User;
  public company: Company;
  public possibleRecipients: Array<Company | Team> = [];

  public ROLES_USER = Roles.User;
  public FEATURES = Features;

  public SEND_NOTIFICATION_TYPE = SendNotificationType;

  public sendNotificationTypeByRole: INotificationTypeByRole = {
    [Roles.MoveMeAdmin]: SendNotificationType.Company,
    [Roles.Admin]: SendNotificationType.Company,
    [Roles.TeamLeader]: SendNotificationType.Team,
    [Roles.ProjectManager]: SendNotificationType.ProjectManager,
  };

  constructor(
    private subscriptionService: SubscriptionService,
    private projectManagerCompanyService: ProjectManagerCompanyService,
    private teamsService: TeamsService,
    private adminService: AdminService
  ) {
    this.subscriptionService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });

    this.subscriptionService.company.subscribe((company) => {
      if (company) {
        this.company = company;

        if (this.user.role === Roles.TeamLeader) {
          this.teamsService
            .getTeam(this.user.companyId, this.user.teamId)
            .then((team) => {
              this.possibleRecipients = [team];
            });
        } else {
          this.possibleRecipients = [this.company];
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.adminPage) {
      this.sendNotificationTypeByRole[Roles.MoveMeAdmin] =
        SendNotificationType.MoveMeAdmin;
    }
  }

  get pushNotificationsSent(): Array<Notification> {
    return this.projectManagerCompanyService.pushNotificationsSent;
  }

  get managedCompanies(): Array<Company> {
    return this.projectManagerCompanyService.managedCompanies;
  }

  get adminCompanies(): Array<Company> {
    return this.adminService.companies;
  }
}
