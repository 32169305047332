<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'ADD_POINTS_SOURCE_TITLE' | translate }}</h1>
  </div>

  <form [formGroup]="pointsSourceDataForm" (ngSubmit)="addPointsSource($event)">
    <div *ngIf="companyId !== null">
      <h2>Select a default points source</h2>
      <mat-form-field class="default-points-sources">
        <mat-label>{{ 'Default Points Sources' | translate }}</mat-label>
        <mat-select formControlName="defaultPointsSource">
          <mat-option
            *ngFor="let pointsSource of defaultPointSources"
            value="{{ pointsSource.id }}"
          >
            {{ pointsSource.description }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <h2>Or create your own!</h2>
    </div>
    <mat-form-field>
      <mat-label>{{ 'ADD_POINTS_SOURCE_ID' | translate }}</mat-label>
      <input matInput formControlName="id" />
      <mat-error>{{ 'ADD_POINTS_SOURCE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_POINTS_SOURCE_DESCRIPTION' | translate }}</mat-label>
      <input matInput formControlName="description" />
      <mat-error>{{ 'ADD_POINTS_SOURCE_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <div class="points-source-details">
      <mat-form-field>
        <mat-label>{{ 'Type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let type of pointsSourceTypesNames; let i = index"
            value="{{ pointsSourceTypesKeys[i] }}"
          >
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field>
        <mat-label>{{ 'Type' | translate }}</mat-label>
        <input type="number" matInput formControlName="type" />
        <mat-error>{{ 'ADD_POINTS_SOURCE_ERROR' | translate }}</mat-error>
      </mat-form-field> -->
      <mat-form-field>
        <mat-label>{{ 'Basis' | translate }}</mat-label>
        <mat-select formControlName="basis">
          <mat-option
            *ngFor="let basis of pointsSourceBasisNames; let i = index"
            value="{{ pointsSourceBasisKeys[i] }}"
          >
            {{ basis }}
          </mat-option>
        </mat-select>
        <mat-error>{{ 'ADD_POINTS_SOURCE_ERROR' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'Expected Performance' | translate }}</mat-label>
        <input type="number" matInput formControlName="expectedPerformance" />
        <mat-error>{{
          pointsSourceDataForm.get('expectedPerformance').errors &&
          pointsSourceDataForm.get('expectedPerformance').errors.min
            ? 'Should be at least 1'
            : pointsSourceDataForm.get('expectedPerformance').errors &&
              pointsSourceDataForm.get('expectedPerformance').errors.max
            ? 'Should be at most 1.000.000'
            : ('ADD_POINTS_SOURCE_ERROR' | translate)
        }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Points Awarded' | translate }}</mat-label>
        <input type="number" matInput formControlName="pointsAwarded" />
        <mat-error>{{
          pointsSourceDataForm.get('pointsAwarded').errors &&
          pointsSourceDataForm.get('pointsAwarded').errors.min
            ? 'Should be at least 1'
            : pointsSourceDataForm.get('pointsAwarded').errors &&
              pointsSourceDataForm.get('pointsAwarded').errors.max
            ? 'Should be at most 5'
            : ('ADD_POINTS_SOURCE_ERROR' | translate)
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="buttons">
      <button mat-raised-button [disabled]="!pointsSourceDataForm.valid">
        {{ 'ADD_POINTS_SOURCE_TITLE' | translate }}
      </button>
    </div>
  </form>
  <div class="creatingTeam" *ngIf="showSpinner">
    <p>{{ 'ADD_POINTS_SOURCE_SPINNER' | translate }}</p>
    <mat-spinner></mat-spinner>
  </div>
</div>
