<div class="card">
  <div class="row" [formGroup]="formGroup">
    <div class="col-2 image-container-col">
      <div class="image-size">
        <img [src]="image" />
      </div>
    </div>
    <div class="col-8 name-form-container">
      <mat-form-field class="name-form-field" [floatLabel]="'never'">
        <input matInput formControlName="name" [placeholder]="'EDIT_IMAGE_AND_NAME_CARD.FORM.name.LABEL' | translate" />
        <mat-error>{{ getFormErrors(formGroup.controls.name, 'EDIT_IMAGE_AND_NAME_CARD.FORM', 'name') | translate }} </mat-error>
      </mat-form-field>
    </div>
    <div class="col-2 buttons-container">
      <button mat-icon-button type="button" (click)="remove(formGroup)">
        <mat-icon [svgIcon]="trashIcon"></mat-icon>
      </button>
    </div>
  </div>
</div>
