import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { animate, style, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

// Component
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

// Models
import { CityDetails } from 'src/app/models/cities-challenge/city-details/city-details.model';
import { CityInfo } from 'src/app/models/cities-challenge/city-info/city-info.model';
import { City } from 'src/app/models/cities-challenge/city/city.model';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';

// Constants
import { IconNames } from 'src/app/models/assets-constants';
import {
  REGEX_ALPHANUMERIC_WITHOUT_GERMAN_CHARS,
  REGEX_LINK,
} from 'src/app/constants/constants';

// Enums
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';

// Services
import { CitiesService } from 'src/app/services/cities/cities.service';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from 'src/app/helpers/helpers';

@Component({
  selector: 'app-edit-city-info-dialog',
  templateUrl: './edit-city-info-dialog.component.html',
  styleUrls: ['./edit-city-info-dialog.component.scss'],
  animations: [
    trigger('animSlider', [
      transition(
        ':increment, :decrement',
        [
          style({ transform: 'translateX({{ direction }}%)' }),
          animate('.5s ease-out', style({ transform: 'translateX(0%)' })),
        ],
        { params: { direction: 0 } }
      ),
    ]),
  ],
})
export class EditCityInfoDialogComponent implements OnInit {
  public PENCIL_ICON = IconNames.PencilOutline;
  public TRASH_ICON = IconNames.TrashOutline;
  public cityInfoDataForm: FormGroup;
  public cityDetailsDataForm: FormGroup;

  public cityInfoDataSavingSpinner = false;
  public filesToDelete = [];
  public isButtonDisabled: boolean = false;
  public detailsIndex = 0;
  public animationDirection = 0;
  public cityInfoTypes: Array<string> = [];
  private existingCityInfoTypes: Set<string> = new Set();

  constructor(
    private citiesService: CitiesService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<EditCityInfoDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public params: {
      cityInfo: CityInfo;
      city: City;
      cityInfoTypeDisabled: boolean;
      cityInfoTypeDefault: string;
    }
  ) {
    this.cityInfoDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      subtitle: ['', Validators.required],
      link: ['', Validators.pattern(REGEX_LINK)],
      cityInfoType: [
        null,
        [
          Validators.required,
          Validators.pattern(REGEX_ALPHANUMERIC_WITHOUT_GERMAN_CHARS),
          this.uniqueValueValidator,
        ],
      ],
      details: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    if (this.params.city) {
      this.existingCityInfoTypes = new Set(this.params.city.cityInfoTypes);

      if (this.params.cityInfo) {
        this.existingCityInfoTypes.delete(this.params.cityInfo.cityInfoType);
      }
    }

    if (this.params.cityInfo) {
      // Have to edit an existing city info.
      this.loadCityInfoDataForm(this.params.cityInfo);
    } else {
      // Create new city info.
      (this.cityInfoDataForm.get('details') as UntypedFormArray).push(
        this.createImageFormGroup()
      );
    }

    if (this.params.cityInfoTypeDisabled) {
      this.cityInfoDataForm.controls.cityInfoType.disable();
      this.cityInfoDataForm.controls.cityInfoType.setValue(
        this.params.cityInfoTypeDefault
      );
    }
  }

  public get detailsControlsArray(): Array<AbstractControl> {
    return (this.cityInfoDataForm.controls.details as UntypedFormArray)
      .controls;
  }

  private createImageFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      image: ['', Validators.required],
      file: [],
      description: ['', Validators.required],
      imageRights: [''],
    });
  }

  public onFileChange(event: Event, index: number): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0]; // Get the selected file
    if (file) {
      const formArrayControl = this.detailsControlsArray[index]; // Access the FormArray control at the given index

      // Set the file value of the FormArray control
      formArrayControl.get('file').setValue(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', (readerEvent) => {
        formArrayControl
          .get('image')
          .setValue(
            this.sanitizer.bypassSecurityTrustResourceUrl(
              String(readerEvent.target?.result)
            )
          );
      });
    }
  }

  private createCityInfo(details: Array<CityDetails>): CityInfo {
    return new CityInfo({
      name: this.cityInfoDataForm.controls.name.value,
      subtitle: this.cityInfoDataForm.controls.subtitle.value,
      link: this.cityInfoDataForm.controls.link.value,
      cityInfoType: this.cityInfoDataForm.controls.cityInfoType.value
        ? this.cityInfoDataForm.controls.cityInfoType.value.toUpperCase().trim()
        : null,
      id: this.params.cityInfo ? this.params.cityInfo.id : null,
      details,
    });
  }

  private loadCityInfoDataForm(cityInfo: CityInfo): void {
    if (cityInfo) {
      this.cityInfoDataForm.get('name').setValue(cityInfo.name);
      this.cityInfoDataForm.get('subtitle').setValue(cityInfo.subtitle);
      this.cityInfoDataForm.get('link').setValue(cityInfo.link);
      this.cityInfoDataForm.get('cityInfoType').setValue(cityInfo.cityInfoType);

      cityInfo.details.forEach((detail) => {
        const imageFormGroup = this.createImageFormGroup();
        imageFormGroup.get('image').setValue(detail.image);
        imageFormGroup.get('description').setValue(detail.description);
        imageFormGroup.get('imageRights').setValue(detail.imageRights);
        (this.cityInfoDataForm.get('details') as UntypedFormArray).push(
          imageFormGroup
        );
      });
    }
  }

  public async saveCityInfo(): Promise<void> {
    this.cityInfoDataSavingSpinner = true;

    try {
      const cityDetails = await this.citiesService.uploadCityDetails(
        this.params.city.id,
        this.detailsControlsArray.map((detailControl) => detailControl.value)
      );

      const cityInfo = this.createCityInfo(cityDetails);

      await this.citiesService.createUpdateCityInfo(
        this.params.city.id,
        this.createCityInfo(cityDetails)
      );

      this.dialogRef.close(cityInfo);
    } catch (error) {
      console.log('edit city info - save error: ', error);
      this.showSnackBar('CITY_INFO.ERROR_UPDATING_CITY_INFO', error);
    } finally {
      this.cityInfoDataSavingSpinner = false;
    }
  }

  public changeImage(index: number): void {
    this.detailsIndex += index;
    this.animationDirection = index;

    if (this.detailsIndex < 0) {
      this.detailsIndex = 0;
      return;
    } else if (this.detailsIndex >= this.detailsControlsArray.length) {
      (this.cityInfoDataForm.get('details') as UntypedFormArray).push(
        this.createImageFormGroup()
      );
    }
  }

  public removeImage(index: number): void {
    const dialogTranslations = this.translateService.instant(
      'CITY_INFO.DELETE.DIALOG'
    );

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: new ConfirmationDialogData({
        title: dialogTranslations.TITLE,
        message: dialogTranslations.MESSAGE,
        confirmLabel: dialogTranslations.CONFIRM,
        cancelLabel: dialogTranslations.CANCEL,
        action: (): void => {
          // Reduce the index by one to display the previous image
          this.detailsIndex -= this.detailsIndex === 0 ? 0 : 1;
          this.detailsControlsArray.splice(index, 1);
        },
      }),
    });

    dialogRef.afterClosed().subscribe((result: ConfirmationDialogResponse) => {
      switch (result.code) {
        case ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly:
          this.showSnackBar('CITY_INFO.DELETE.SNACK_BAR.SUCCESSFUL');
          break;

        case ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors:
          this.showSnackBar('CITY_INFO.DELETE.SNACK_BAR.ERROR', result.error);
          break;
      }
    });
  }

  private showSnackBar(snackBarType: string, error?: string): void {
    this.snackBar.open(
      this.translateService.instant(snackBarType, error ? { error } : null),
      this.translateService.instant('Ok'),
      {
        duration: 4000,
        panelClass: 'snack-bar-color',
      }
    );
  }

  public uniqueValueValidator = (
    control: AbstractControl
  ): ValidationErrors => {
    if (control.value) {
      const value = control.value.toUpperCase().trim();
      return this.existingCityInfoTypes.has(value)
        ? { uniqueValue: true }
        : null;
    }
  };

  public getFormErrors(
    control: AbstractControl,
    translationFormToken: string,
    controlName: string
  ): string {
    return Helpers.getFormErrors(control, translationFormToken, controlName);
  }
}
