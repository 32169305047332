<div class="container">
  <h1 class="title" id="team-chalenge-title">
    {{ 'TEAM_CHALLENGE.TITLE' | translate }}
  </h1>

  <mat-tab-group [selectedIndex]="company.currentTeamChallenges.length !== 0 ? 0 : 1">
    <mat-tab [label]="'TEAM_CHALLENGE.CURRENT_CHALLENGE' | translate" [disabled]="company.currentTeamChallenges.length === 0">
      <div>
        <div>
          <form [formGroup]="teamChallengeRankingForm">
            <mat-form-field>
              <mat-label>{{ 'Week' | translate }}</mat-label>
              <mat-select formControlName="week">
                <mat-option *ngFor="let week of currentTeamChallengeWeeks" [value]="week">
                  {{ (week.id.split(' ')[0] | translate) + ' ' + week.id.split(' ')[1] }}
                  ({{ week.weekdays[0] + ' - ' + week.weekdays[week.weekdays.length - 1] }})
                </mat-option>
              </mat-select>
              <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
            </mat-form-field>
          </form>
        </div>
        <mat-tab-group>
          <mat-tab [label]="TEAM_CHALLENGE_TAB_TEAM_CHALLENGE_RANKING">
            <table mat-table [dataSource]="dataSourceLiga" matSort #ligaSort="matSort">
                <!-- Avatar Column -->
                <ng-container matColumnDef="avatar">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let team" class="avatar">
                      <img src="{{ team.avatar }}" alt="avatar" />
                  </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="positionSorted">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                  <td mat-cell *matCellDef="let team">
                    {{ team.positionSorted | number: '1.0-0':'de' }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'TEAM_CHALLENGE.TABLE_TEAM' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let team">{{ team.name }}</td>
                </ng-container>

                <!-- Team Member Column -->
                <ng-container matColumnDef="teamSize">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'TEAM_CHALLENGE.TABLE_NUMBER_TEAM_MEMBERS' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let team">
                    {{ team.teamSize | number: '1.0-0':'de' }}
                  </td>
                </ng-container>

                <!-- Points Column -->
                <ng-container matColumnDef="points">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'TEAM_CHALLENGE.TABLE_POINTS' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let team">
                    {{ team.points | number: '1.0-0':'de' }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsLiga"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsLiga"></tr>
            </table>
            <mat-card *ngIf="dataSourceLiga.data.length === 0">
              <p>{{ 'TEAM_CHALLENGE.NO_DATA_AVAILABLE' | translate }}</p>
            </mat-card>
          </mat-tab>

          <mat-tab [label]="TEAM_CHALLENGE_TAB_WEEKLY_RANKING">
            <table mat-table [dataSource]="dataSourceWeekly" matSort #weeklySort="matSort">
                <!-- Avatar Column -->
                <ng-container matColumnDef="avatar">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let team" class="avatar">
                    <img src="{{ team.avatar }}" alt="avatar" />
                  </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                  <td mat-cell *matCellDef="let team">
                    {{ team.position | number: '1.0-0':'de' }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'TEAM_CHALLENGE.TABLE_TEAM' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let team">{{ team.name }}</td>
                </ng-container>

                <!-- Acumulated Steps Column -->
                <ng-container>
                  <ng-container matColumnDef="totalSteps">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ 'TEAM_CHALLENGE.TABLE_CUMULATED_STEPS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let team">
                      {{ team.totalSteps | number: '1.0-0':'de' }}
                    </td>
                  </ng-container>
                </ng-container>

                <!-- Team Size Column -->
                <ng-container>
                    <ng-container matColumnDef="teamSize">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'TEAM_CHALLENGE.TABLE_NUMBER_TEAM_MEMBERS' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let team">
                            {{ team.teamSize | number: '1.0-0':'de' }}
                        </td>
                    </ng-container>
                </ng-container>

                <!-- Team Size Column -->
                <ng-container>
                  <ng-container matColumnDef="totalSyncs">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          {{ 'TEAM_CHALLENGE.TABLE_NUMBER_TEAM_SYNCS' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let team">
                          {{ team.totalSyncs | number: '1.0-0':'de' }}
                      </td>
                  </ng-container>
                </ng-container>

                <!-- Average Steps Column -->
                <ng-container>
                  <ng-container matColumnDef="averageSteps">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ 'TEAM_CHALLENGE.AVERAGE_STEPS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let team">
                      {{
                        team.totalSyncs !== 0
                        ? (team.totalSteps / team.totalSyncs | number: '1.0-0':'de')
                        : '-'
                      }}
                    </td>
                  </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsWeekly"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsWeekly"></tr>
            </table>
            <mat-card *ngIf="dataSourceWeekly.data.length === 0">
              <p>{{ 'TEAM_CHALLENGE.NO_DATA_AVAILABLE' | translate }}</p>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-tab>
    <mat-tab [label]="'TEAM_CHALLENGE.PAST_CHALLENGES' | translate" [disabled]="pastTeamChallenges.length === 0">
      <div *ngFor="let pastTeamChallenge of pastTeamChallenges; let i = index">
        <p class="past-challenge-name">{{ pastTeamChallenge.name }}</p>
        <div *ngIf="finalStandingsReady">
          <table mat-table [dataSource]="dataSourceFinalStandings[i]">
            <!-- Avatar Column -->
            <ng-container matColumnDef="avatar">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let team" class="avatar">
                <img src="{{ team.teamAvatar }}" alt="avatar" />
              </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="positionSorted">
              <th mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let team">
                {{ team.finalPosition | number: '1.0-0':'de' }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'TEAM_CHALLENGE.TABLE_TEAM' | translate }}
              </th>
              <td mat-cell *matCellDef="let team">{{ team.teamName }}</td>
            </ng-container>


            <!-- Points Column -->
            <ng-container matColumnDef="points">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'TEAM_CHALLENGE.TABLE_POINTS' | translate }}
              </th>
              <td mat-cell *matCellDef="let team">
                {{ team.finalPoints | number: '1.0-0':'de' }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalStandings"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsFinalStandings"></tr>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="'TEAM_CHALLENGE.RANKING_BY_DATE' | translate" [disabled]="company.currentTeamChallenges.length === 0 && pastTeamChallenges.length === 0">
      <div class="tab-container">
        <form [formGroup]="dateRange" class="ranking-by-date-form">
          <mat-form-field>
            <mat-label>{{ 'TEAM_CHALLENGE.TITLE' | translate }}</mat-label>
            <mat-select formControlName="teamChallenge" (selectionChange)="onTeamChallengeRankingSelected()">
              <mat-option *ngFor="let teamChallenge of pastTeamChallenges" [value]="teamChallenge.id">
                {{ teamChallenge.name }}
              </mat-option>
            </mat-select>
            <mat-error>{{ 'Error' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'TEAM_CHALLENGE.RANKING_BY_DATE_STARTING_WEEK' | translate }}</mat-label>
            <mat-select formControlName="startingWeek" (selectionChange)="onStartingWeekSelected()">
              <mat-option *ngFor="let week of selectedTeamChallengeWeeks" [value]="week">
                {{ (week.id.split(' ')[0] | translate) + ' ' + week.id.split(' ')[1] }}
                ({{ week.weekdays[0] + ' - ' + week.weekdays[week.weekdays.length - 1] }})
              </mat-option>
            </mat-select>
            <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'TEAM_CHALLENGE.RANKING_BY_DATE_ENDING_WEEK' | translate }}</mat-label>
            <mat-select formControlName="endingWeek">
              <mat-option *ngFor="let week of selectedTeamChallengePossibleEndingWeeks" [value]="week">
                {{ (week.id.split(' ')[0] | translate) + ' ' + week.id.split(' ')[1] }}
                ({{ week.weekdays[0] + ' - ' + week.weekdays[week.weekdays.length - 1] }})
              </mat-option>
            </mat-select>
            <mat-error>{{ 'ADD_PERSONAL_CHALLENGE_ERROR' | translate }}</mat-error>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="getTeamStepsOnRange()" [disabled]="!dateRange.value.teamChallenge || !dateRange.value.startingWeek || !dateRange.value.endingWeek">
            {{ 'TEAM_CHALLENGE.SHOW_RANKING' | translate}}
          </button>
        </form>

        <div>

          <h2 *ngIf="rankingByDateDataSource.data.length > 0 && dateRange.value.startingWeek && dateRange.value.endingWeek">
            {{ 'TEAM_CHALLENGE.RANKING_BY_DATE' | translate }} ({{ dateRange.value.startingWeek.weekdays[0] }} - {{ dateRange.value.endingWeek.weekdays[dateRange.value.endingWeek.weekdays.length - 1] }})
          </h2>

          <table mat-table [dataSource]="rankingByDateDataSource" matSort #rankingByDateSort="matSort">
            <!-- Avatar Column -->
            <ng-container matColumnDef="avatar">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let team" class="avatar">
                <img src="{{ team.avatar }}" alt="avatar" />
              </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
              <td mat-cell *matCellDef="let team">
                {{ team.position | number: '1.0-0':'de' }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'TEAM_CHALLENGE.TABLE_TEAM' | translate }}
              </th>
              <td mat-cell *matCellDef="let team">{{ team.name }}</td>
            </ng-container>

            <!-- Acumulated Steps Column -->
            <ng-container>
              <ng-container matColumnDef="totalSteps">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-right-aligned">
                  {{ 'TEAM_CHALLENGE.TABLE_CUMULATED_STEPS' | translate }}
                </th>
                <td mat-cell *matCellDef="let team" class="align-right">
                  {{ team.totalSteps | number: '1.0-0':'de' }}
                </td>
              </ng-container>
            </ng-container>

            <!-- Team Size Column -->
            <ng-container>
                <ng-container matColumnDef="teamSize">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-right-aligned">
                        {{ 'TEAM_CHALLENGE.TABLE_NUMBER_TEAM_MEMBERS' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let team" class="align-right">
                        {{ team.teamSize | number: '1.0-0':'de' }}
                    </td>
                </ng-container>
            </ng-container>

            <!-- Team Size Column -->
            <ng-container>
              <ng-container matColumnDef="totalSyncs">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-right-aligned">
                      {{ 'TEAM_CHALLENGE.TABLE_NUMBER_TEAM_SYNCS' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let team" class="align-right">
                      {{ team.totalSyncs | number: '1.0-0':'de' }}
                  </td>
              </ng-container>
            </ng-container>

            <!-- Average Steps Column -->
            <ng-container>
              <ng-container matColumnDef="averageSteps">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-right-aligned">
                  {{ 'TEAM_CHALLENGE.AVERAGE_STEPS' | translate }}
                </th>
                <td mat-cell *matCellDef="let team" class="align-right">
                  {{ team.averageSteps | number: '1.0-0':'de' }}
                </td>
              </ng-container>
            </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsWeekly"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsWeekly"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
