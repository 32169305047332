import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IconNames } from 'src/app/models/assets-constants';
import { Company } from 'src/app/models/company.model';
import { Features as TeamChallengeFeatures } from 'src/app/models/team-challenge/features';
import { TeamChallenge as TeamChallengeV2 } from 'src/app/models/team-challenge/team-challenge/team-challenge.model';
import { Team as TeamV2 } from 'src/app/models/team-challenge/team/team.model';
import { CompanyService } from 'src/app/services/company/company.service';
import {
  IHierarchyLevelName,
  LocationService,
} from 'src/app/services/location/location.service';
import { TeamChallengeService } from 'src/app/services/team-challenge/team-challenge.service';
import { UserMessageService } from 'src/app/services/user-message/user-message.service';
import { ITeamChallengeOverview } from '../team-challenge-overview/team-challenge-overview.component';
import { WeeklyHistory } from 'src/app/models/team-challenge/weekly-history/weekly-history.model';
import { ChallengeStatus } from 'src/app/models/challenge/challenge-status.enum';

export const TABS = {
  OVERVIEW: 0,
  EDITION: 1,
};

export interface ITeamChallengeDashboard {
  teamChallenge: TeamChallengeV2;
  teams: Array<TeamV2>;
  companyId: string;
}
@Component({
  selector: 'app-team-challenge-dashboard',
  templateUrl: './team-challenge-dashboard.component.html',
  styleUrls: ['./team-challenge-dashboard.component.scss'],
})
export class TeamChallengeDashboardComponent implements OnChanges {
  @Input() teamChallengeDashboard: ITeamChallengeDashboard;

  public CHALLENGE_STATUS = ChallengeStatus;
  public currentChallengeStatus: ChallengeStatus;
  public duration = 0;
  public TEAM_CHALLENGE_ICON = IconNames.GroupPeople;
  public selectedTab = TABS.OVERVIEW;

  public teamChallengeDataForm: UntypedFormGroup;
  public company: Company;
  public availableLocationLevels: Array<IHierarchyLevelName> = [];
  public teamChallengeOverview: ITeamChallengeOverview = null;
  public weeklyHistories: Array<WeeklyHistory> = null;

  constructor(
    private companyService: CompanyService,
    private teamChallengeService: TeamChallengeService,
    private locationService: LocationService,
    private userMessageService: UserMessageService
  ) {}

  get teamChallenge(): TeamChallengeV2 {
    return this.teamChallengeDashboard.teamChallenge;
  }

  ngOnChanges(): void {
    this.companyService
      .getCompany(this.teamChallengeDashboard.companyId)
      .then((company) => {
        this.company = company;

        if (this.teamChallenge.features.has(TeamChallengeFeatures.LOCATIONS)) {
          this.locationService
            .getTopHierarchyLocationsNames(
              this.teamChallengeDashboard.companyId,
              this.company.locationsInfo
            )
            .then((availableLocationLevels) => {
              this.availableLocationLevels = availableLocationLevels;
            })
            .catch((error) => {
              console.log('getTopHierarchyLocationsNames - error: ', error);
              this.userMessageService.snackBarMessage(
                'USER_MESSAGE_SERVICE.GENERIC_ERROR'
              );
            });
        }
      })
      .catch((error) => {
        console.log('getCompany - error: ', error);
        this.userMessageService.snackBarMessage(
          'USER_MESSAGE_SERVICE.GENERIC_ERROR'
        );
      });

    this.teamChallengeDataForm = this.teamChallengeService.getStandardTeamChallengeDataForm();

    this.teamChallengeService.setTeamChallengeToForm(
      this.teamChallengeDataForm,
      this.teamChallenge,
      this.teamChallengeDashboard.teams
    );

    this.duration = this.teamChallenge.getDaysDuration();
    this.currentChallengeStatus = this.teamChallenge.getStatus();

    if (!this.teamChallenge.isPending()) {
      this.getWeeklyHistories();
    }
  }

  public goToEdit(): void {
    this.selectedTab = TABS.EDITION;
  }

  private setTeamChallengeOverview(): void {
    this.teamChallengeOverview = {
      teamChallenge: this.teamChallenge,
      teamsMap: new Map<string, TeamV2>(
        this.teamChallengeDashboard.teams.map((team) => [team.id, team])
      ),
      company: this.company,
      teamChallengeKpi: this.teamChallengeService.calculateTeamChallengeKpis(
        this.teamChallengeDashboard.teamChallenge,
        this.teamChallengeDashboard.teams,
        this.weeklyHistories[0]
      ),
      weeklyHistories: this.weeklyHistories,
    };
  }

  public getWeeklyHistories(): Promise<void> {
    return this.teamChallengeService
      .getTeamChallengeV2WeeklyHistories(
        this.teamChallengeDashboard.companyId,
        this.teamChallenge.id
      )
      .then((weeklyHistories) => {
        weeklyHistories.sort((week1, week2) =>
          week1.weekdays[0] > week2.weekdays[0] ? -1 : 1
        );
        this.weeklyHistories = weeklyHistories;
        this.setTeamChallengeOverview();
      });
  }
}
