<h1 mat-dialog-title>{{ company.name }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Licences</mat-label>
    <input matInput required type="number" [(ngModel)]="company.availableLicenses" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button [mat-dialog-close]="company" (click)="updateCompanyAvailableLicences()" cdkFocusInitial>Update Value</button>
</div>
