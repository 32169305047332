<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'ADD_COMPANY_TITLE' | translate }}</h1>
  </div>

  <mat-horizontal-stepper #stepper linear="true" labelPosition="bottom">
    <!-- COMPANY DATA -->
    <mat-step  [stepControl]="companyDataForm">
      <ng-template matStepLabel><mat-icon>domain</mat-icon></ng-template>
      <h1 class="title">{{'COMPANY_PROCESS.COMPANY_DATA_TITLE'|translate}}</h1>
      <p class="paragraph">{{'COMPANY_PROCESS.COMPANY_DATA_TEXT' | translate}}</p>
        <form [formGroup]="companyDataForm" (ngSubmit)="addCompany($event)">
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_ID' | translate }}</mat-label>
            <input matInput required formControlName="companyId" id="id" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_NAME' | translate }}</mat-label>
            <input matInput required formControlName="name" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_ADMIN_EMAIL' | translate }}</mat-label>
            <mat-chip-list #chipList required>
              <mat-chip *ngFor="let email of adminEmails" [selectable]="selectable"
              [removable]="removable" (removed)="remove(email)">
              {{ email }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input type="email" formControlName="adminEmail"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" />
              </mat-chip-list>
              <mat-error *ngIf="adminEmail.hasError('required') && adminEmail.touched">{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
              <mat-error *ngIf="adminEmail.hasError('email')">{{ 'ADD_COMPANY_ERROR_EMAIL' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_AVAILABLE_LICENCES' | translate }}</mat-label>
            <input matInput required type="number" formControlName="availableLicenses" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <ngx-mat-file-input
              formControlName="avatarImg"
              [placeholder]="'ADD_COMPANY_AVATAR_PLACEHOLDER' | translate"
            ></ngx-mat-file-input>
            <mat-icon matSuffix>attach_file</mat-icon>
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Select a type of Challenge</mat-label>
            <mat-select>
              <mat-option>
                Team Challenge
              </mat-option>
              <mat-option>
                Distance Challenge
              </mat-option>
            </mat-select>
          </mat-form-field>
          <h2>{{ 'ADD_COMPANY_EXTRA_FEATURES' | translate }}</h2>
          <mat-checkbox color="primary" formControlName="featureWhiteLabel">{{
            'ADD_COMPANY_WHITE_LABEL' | translate
          }}</mat-checkbox>

          <mat-checkbox color="primary" formControlName="featureLocations">{{
            'ADD_COMPANY_LOCATIONS' | translate
          }}</mat-checkbox>

          <!-- <mat-checkbox color="primary" formControlName="featureTeamChallenge">{{
            'ADD_COMPANY_TEAM_CHALLENGES' | translate
          }}</mat-checkbox> -->
        </form>
        <div class="button-container">
          <button [disabled]="!companyDataForm.valid" class="round-button register-button" color="primary" mat-raised-button matStepperNext>
            <span class="material-icons next-icon">
              done
            </span>
          </button>
        </div>
    </mat-step>

    <!-- WHITE LABEL -->
    <mat-step [stepControl]="whiteLabelDataForm"  *ngIf="this.companyDataForm.controls['featureWhiteLabel'].value">
      <ng-template matStepLabel><mat-icon>color_lens</mat-icon></ng-template>
      <h1 class="title">Add a White Label</h1>
      <p class="paragraph"></p>
      <form [formGroup]="whiteLabelDataForm" >
      <div class="white-label-container" *ngIf="this.companyDataForm.controls['featureWhiteLabel'].value">
        <mat-form-field>
          <mat-label>{{ 'Select a White Label' | translate }}</mat-label>
          <mat-select [(value)]="whiteLabelSelected" (selectionChange)="updateWhiteLabelValues()">
            <mat-option
              *ngFor="let whiteLabel of whiteLabels; let i = index"
              [value]="whiteLabel">
              {{ whiteLabel.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="white-label-logo-container" fxLayout.xs="center" *ngIf="whiteLabelSelected">
          <img src="{{ whiteLabelSelected?.logo }}" alt="Select a white label to see its logo" />
        </div>
          <mat-form-field>
            <ngx-mat-file-input
              formControlName="whiteLabelLogo"
              [placeholder]="'ADD_COMPANY_AVATAR_WHITE_LABEL' | translate"
            ></ngx-mat-file-input>
            <mat-icon matSuffix>attach_file</mat-icon>
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field class="color-input">
            <mat-label>{{ 'ADD_COMPANY_WHITE_LABEL_BUTTON_COLOR' | translate }}</mat-label>
            <input matInput formControlName="whiteLabelButtonColor" (colorPickerChange)="onChangeWhiteLabelButtonColor($event)" [(colorPicker)]="this.companyDataForm.value.whiteLabelButtonColor" [style.background]="this.companyDataForm.value.whiteLabelButtonColor"/>
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field class="color-input">
            <mat-label>{{ 'ADD_COMPANY_COLOR' | translate }}</mat-label>
            <input matInput formControlName="color" (colorPickerChange)="onChangeCompanyColor($event)" [(colorPicker)]="this.companyDataForm.value.color" [style.background]="this.companyDataForm.value.color"/>
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_WHITE_LABEL_BUTTON_TEXT' | translate }}</mat-label>
            <input matInput formControlName="whiteLabelButtonText" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ADD_COMPANY_WHITE_LABEL_BUTTON_LINK' | translate }}</mat-label>
            <input matInput formControlName="whiteLabelButtonLink" />
            <mat-error>{{ 'ADD_COMPANY_ERROR' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </form>

      <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button [disabled]="!whiteLabelDataForm.valid" class="round-button register-button" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            done
          </span>
        </button>
        <!-- <button [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
          <mat-spinner diameter="24"></mat-spinner>
        </button> -->
      </div>
    </mat-step>

    <!-- LOCATIONS -->
    <mat-step [stepControl]="locationDataForm" *ngIf="this.companyDataForm.controls['featureLocations'].value">
      <ng-template matStepLabel><mat-icon>add_location</mat-icon></ng-template>
      <h1 class="title">Add your company departments!</h1>
      <p class="paragraph"></p>
     <div *ngIf="this.companyDataForm.controls['featureLocations'].value">
      <form class="new-location-form" [formGroup]="locationDataForm">
        <div class="new-location-form-title">
          <h2 class="subtitle-locations">
            {{ 'How many locations levels do you need?' | translate }}
          </h2>
        </div>
        <div class="new-location-form-levels-selection">
          <mat-radio-group color="primary" formControlName="levels" class="example-radio-group">
            <mat-radio-button class="example-radio-button" [value]="1">1</mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="2">2</mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="3">3</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngFor="let level of [1, 2, 3]">
          <form class="new-location-form" [formGroup]="levelsDataForms[level]" *ngIf="locationDataForm.value.levels > level - 1">
            <div class="new-location-form-location-level-title">
              <h2 class="location-level-title">
                Location Level {{ level }}
              </h2>
            </div>
            <mat-card>
              <div>
            <div class="new-location-form-location-level-subtitle">
              <span class="location-level-subtitle">
                Title
              </span>
            </div>
            <mat-form-field>
              <mat-label>{{ 'languages.en' | translate }}</mat-label>
              <input type="text" matInput formControlName="titleEN" />
              <mat-error>{{ 'Error' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'languages.de' | translate }}</mat-label>
              <input type="text" matInput formControlName="titleDE" />
              <mat-error>{{ 'Error' | translate }}</mat-error>
            </mat-form-field>

            <div class="new-location-form-location-level-subtitle">
              <span class="location-level-subtitle">
                Selector Label
              </span>
            </div>
            <mat-form-field>
              <mat-label>{{ 'languages.en' | translate }}</mat-label>
              <input type="text" matInput formControlName="selectorEN" />
              <mat-error>{{ 'Error' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'languages.de' | translate }}</mat-label>
              <input type="text" matInput formControlName="selectorDE" />
              <mat-error>{{ 'Error' | translate }}</mat-error>
            </mat-form-field>
            <div class="new-location-form-location-level-subtitle">
              <span class="location-level-subtitle">
                Locations
              </span>
            </div>
            <mat-form-field>
              <mat-label>{{ 'Name' | translate }}</mat-label>
              <input type="text" matInput formControlName="name" />
              <mat-error>{{ 'Error' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'ID' | translate }}</mat-label>
              <input type="text" matInput formControlName="id" />
              <mat-error>{{ 'Error' | translate }}</mat-error>
            </mat-form-field>
          </div>
          </mat-card>
            <button class="add-location-button"
              mat-raised-button
              (click)="addLocationToLevel(level)"
            >
              {{ isEditActive ? 'Update Location' : 'Add Location' | translate }}
            </button>
            <button
              *ngIf="isEditActive"
              mat-raised-button
              (click)="cancelEdit(level)"
            >
              {{ 'Cancel' | translate }}
            </button>
            <table
              mat-table
              [dataSource]="levelsDataSources[level]"
              matSort
              *ngIf="levelsLocations[level].length !== 0"
            >
              <!-- Id Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let location">
                  {{ location.id }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let location">
                  {{ location.name }}
                </td>
              </ng-container>

              <!-- Edit Column -->
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td
                  mat-cell
                  *matCellDef="let location; let i = index"
                  (click)="editLocation(location.id, level)"
                  class="clickable-column"
                >
                  <mat-icon>edit</mat-icon>
                </td>
              </ng-container>

              <!-- Links Column -->
              <ng-container matColumnDef="links">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td
                  mat-cell
                  *matCellDef="let location; let i = index"
                  (click)="linkLocation(location, level)"
                  class="clickable-column"
                >
                  <mat-icon [ngClass]="locationDataForm.value.levels !== level ? 'enabled' : 'disabled'">share</mat-icon>
                </td>
              </ng-container>

              <!-- Delete Column -->
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let location; let i = index"
                  (click)="removeLocation(location.id, level)"
                  class="clickable-column"
                >
                  <mat-icon>delete_outline</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="locationColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: locationColumns"></tr>
            </table>

          </form>
        </div>
      </form>
    </div>

    <div class="button-container">
      <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
        <span class="material-icons next-icon">
          chevron_left
        </span>
      </button>
      <button [disabled]="!locationDataForm.valid" class="round-button register-button" color="primary" mat-raised-button matStepperNext>
        <span class="material-icons next-icon">
          done
        </span>
      </button>
      <button [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
        <mat-spinner diameter="24"></mat-spinner>
      </button>
    </div>
    </mat-step>

<!-- PERSONAL CHALLENGE -->
    <!-- <mat-step [completed]="personalChallengeFormCompleted">
      <ng-template matStepLabel><mat-icon>person_add</mat-icon></ng-template>
      <h1 class="title">Add a Personal Challenge!</h1>
      <p class="paragraph"></p>

      <app-add-personal-challenge (completedEvent)="setCompleted($event, 'PERSONAL')"></app-add-personal-challenge>

      <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button class="round-button register-button" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            done
          </span>
        </button>
      </div>
    </mat-step> -->


    <!-- TEAM CHALLENGE -->
    <!-- <mat-step [completed]="teamChallengeFormCompleted">
      <ng-template matStepLabel><mat-icon>people_outline</mat-icon></ng-template>
      <h1 class="title">Add a Team Challenge!</h1>
      <p class="paragraph">What is a Team Challenge? Team members can be chosen by a team captain, or randomly (Configured by your company). Every Sunday, teams will be rewarded with points based on the step average accomplished the past week. The team with the higher steps average gets the most points.The team with most points at the end of the challenge wins.Participation in Team Challenges is optional and according to your company subscription.</p>

       <app-add-team-challenge (completedEvent)="setCompleted($event, 'TEAM_CHALLENGE')"></app-add-team-challenge>
       <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button class="round-button register-button" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            done
          </span>
        </button>
        <button [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
          <mat-spinner diameter="24"></mat-spinner>
        </button>
      </div>
    </mat-step> -->


    <!-- DISTANCE CHALLENGE -->

    <!-- <mat-step>
      <ng-template matStepLabel><mat-icon>place</mat-icon></ng-template>
      <h1 class="title">Add an Around the world challenge!</h1>
      <p class="paragraph"></p>
       <app-add-around-the-world-challenge (completedEvent)="setCompleted($event, 'DISTANCE_CHALLENGE')"></app-add-around-the-world-challenge>
       <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button class="round-button register-button" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            done
          </span>
        </button>
        <button [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
          <mat-spinner diameter="24"></mat-spinner>
        </button>
      </div>
    </mat-step> -->

    <!-- PREVIEW -->
    <!-- <mat-step>
      <ng-template matStepLabel><mat-icon>place</mat-icon></ng-template>
      <h1 class="title">{{'COMPANY_PROCESS.FINAL_STEP_TITLE'|translate}} </h1>
      <p class="paragraph">{{'COMPANY_PROCESS.FINAL_STEP_TEXT'}}</p>
      <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <div class="buttons">
          <button color="primary" mat-raised-button  [disabled]="!companyDataForm.valid || adminEmails.length === 0">
            {{ 'ADD_COMPANY_TITLE' | translate }}
          </button>
        </div>
        <button [disabled]="true" class="round-button spinner-button" color="primary" mat-raised-button>
          <mat-spinner diameter="24"></mat-spinner>
        </button>
      </div>
    <div class="creating-company-spinner-container" *ngIf="showSpinner">
      <p>{{ 'ADD_COMPANY_CREATING_SPINNER' | translate }}</p>
      <mat-spinner></mat-spinner>
    </div>
    </mat-step> -->



  </mat-horizontal-stepper>










