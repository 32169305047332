export interface ICitiesChallengePerformance {
  value: number;
  users: number;
  average: number;
  day: number;
  totalProgress: number;
  stageIndex: number;
  stageProgress: number;
  expectedTotalProgress: number;
  regionIndex: number;
  cityIndex: number;
  syncs: number;
}

export class CitiesChallengePerformance {
  public value: number = 0;
  public users: number = 0;
  public average: number = 0;
  public day: number = 1;
  public totalProgress: number = 0;
  public stageIndex: number = 0;
  public stageProgress: number = 0;
  public expectedTotalProgress: number = 0;
  public regionIndex: number = 0;
  public cityIndex: number = 0;
  public syncs: number = 0;

  constructor(iCitiesChallengePerformance?: ICitiesChallengePerformance) {
    if (iCitiesChallengePerformance) {
      Object.assign(this, iCitiesChallengePerformance);
      this.totalProgress = Math.min(
        100,
        iCitiesChallengePerformance.totalProgress
      );
    }
  }

  public toObject(): ICitiesChallengePerformance {
    return { ...this };
  }

  public isCurrentRegion(regionIndex: number): boolean {
    return regionIndex === this.regionIndex;
  }

  public isCurrentStage(regionIndex: number, stageIndex: number): boolean {
    return this.isCurrentRegion(regionIndex) && stageIndex === this.stageIndex;
  }

  public isCityUnlock(regionIndex: number, cityIndex: number): boolean {
    return (
      regionIndex < this.regionIndex ||
      (this.isCurrentRegion(regionIndex) && cityIndex <= this.cityIndex)
    );
  }

  public isCurrentCity(regionIndex: number, cityIndex: number): boolean {
    return this.isCurrentRegion(regionIndex) && cityIndex === this.cityIndex;
  }
}
