<mat-card class="circle-card-container">
  <mat-card-header>
    <mat-card-title>
      {{ stageProgressCard.title | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content #circleProgressCardContent>
    <div class="stage-container">
      <div class="cities">
        <h2>{{ 'STAGE_PROGRESS_CARD.ACTUAL_CITY' | translate }}</h2>
        <img [src]="getFlagPath(stageProgressCard.cityFrom)" alt="City from flag">
        <p>{{ stageProgressCard.cityFrom.translations.get(currentLang) ? stageProgressCard.cityFrom.translations.get(currentLang) : stageProgressCard.cityFrom.name }}</p>
      </div>
      <div class="circle-progress-container">
        <svg viewBox="-2 -2 40 40" class="circular-chart" [ngClass]="{ 'no-progress': totalProgress === 0 }">
          <circle class="circle-bg" cx="18" cy="18" r="16"/>
          <path *ngIf="totalProgress > 0" class="circle"
            [attr.stroke-dasharray]="totalProgress + ' 100'"
            d="M18 2
              a 16 16 0 1 1 0 32
              a 16 16 0 1 1 0 -32"
          />
          <text x="47%" y="50%" class="percentage" [ngClass]="{ 'no-progress': totalProgress === 0 }">
            {{ totalProgress | number: '1.0-2':'de' }}%
          </text>
        </svg>
      </div>
      <div class="cities">
        <h2>{{ 'STAGE_PROGRESS_CARD.NEXT_CITY' | translate }}</h2>
        <img [src]="getFlagPath(stageProgressCard.cityTo)" alt="City from flag">
        <p> {{ stageProgressCard.cityTo.translations.get(currentLang) ? stageProgressCard.cityTo.translations.get(currentLang) : stageProgressCard.cityTo.name }}</p>
      </div>
    </div>
    <div class="info-container">
      <h1 *ngIf="stageProgressCard.progressTitle" class="progress-title">{{ stageProgressCard.progressTitle | translate }}</h1>
      <p *ngIf="stageProgressCard.progressSubtitle" class="progress-subtitle" [ngClass]="{ 'no-progress': totalProgress === 0 }">{{ stageProgressCard.progressSubtitle | translate }}</p>
    </div>
  </mat-card-content>
</mat-card>