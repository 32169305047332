import { City, ICityInitializer } from '../city/city.model';

export interface ICityMarkerPointInitializer {
  city?: ICityInitializer;
  distanceToNextCity?: number;
  cityInfoType?: string;
}

export class CityMarkerPoint {
  public city: City = null;
  public distanceToNextCity: number = null;
  public cityInfoType: string = null;

  constructor(iCityMarkerPointInitializer: ICityMarkerPointInitializer = {}) {
    Object.assign(this, iCityMarkerPointInitializer);

    if (iCityMarkerPointInitializer.city) {
      this.city = new City(iCityMarkerPointInitializer.city);
    }
  }

  public toObject(): ICityMarkerPointInitializer {
    const { city, ...otherProperties } = this;

    return {
      ...otherProperties,
      city: city ? city.toObject() : null,
    };
  }
}
