<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="title">{{ 'ADD_TEAM_TITLE' | translate }}</h1>
  </div>

  <form [formGroup]="teamDataForm" (ngSubmit)="addTeam($event)">
    <mat-form-field *ngIf="companyId !== null">
      <mat-label>{{ 'ADD_TEAM_COMPANY' | translate }}</mat-label>
      <mat-select formControlName="companyId">
        <mat-option *ngFor="let company of companies" value="{{ company.id }}">
          {{ company.name }}
        </mat-option>
      </mat-select>
      <mat-error>{{ 'ADD_TEAM_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_TEAM_ID' | translate }}</mat-label>
      <input matInput formControlName="teamId" />
      <mat-error *ngIf="teamDataForm.get('teamId').hasError('required')">{{ 'ADD_TEAM_ERROR' | translate }}</mat-error>
      <mat-error *ngIf="teamDataForm.get('teamId').hasError('pattern')">{{ 'ADD_TEAM_PATTERN_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_TEAM_NAME' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error>{{ 'ADD_TEAM_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <ngx-mat-file-input
        formControlName="avatarImg"
        [placeholder]="'ADD_TEAM_AVATAR_PLACEHOLDER' | translate"
      ></ngx-mat-file-input>
      <mat-icon matSuffix>folder</mat-icon>
      <mat-error>{{ 'ADD_TEAM_ERROR' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ADD_TEAM_COLOR' | translate }}</mat-label>
      <input matInput formControlName="color" />
      <mat-error>{{ 'ADD_TEAM_ERROR' | translate }}</mat-error>
      <mat-hint>{{ 'ADD_TEAM_COLOR_HINT' | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="company && company.features.has(FEATURE_LOCATIONS)">
      <mat-label>{{ 'ADD_TEAM_LOCATION' | translate }}</mat-label>
      <mat-select formControlName="location">
        <mat-option *ngFor="let locationName of company.locationsInfo.optionNames" value="{{ locationName }}">
          {{ locationName }}
        </mat-option>
      </mat-select>
      <mat-error>{{ 'ADD_TEAM_ERROR' | translate }}</mat-error>
    </mat-form-field>

    <div class="buttons">
      <button mat-raised-button [disabled]="!teamDataForm.valid || showSpinner">
        {{ 'ADD_TEAM_TITLE' | translate }}
      </button>
    </div>
  </form>
  <div class="creatingTeam" *ngIf="showSpinner">
    <p>{{ 'ADD_TEAM_SPINNER' | translate }}</p>
    <mat-spinner></mat-spinner>
  </div>
</div>
