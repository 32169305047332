<table mat-table [dataSource]="companiesDataSource" multiTemplateDataRows matSort class="mat-elevation-z8">
  <!-- Avatar Column -->
  <ng-container matColumnDef="avatar">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let company" class="avatar">
      <img [src]="company.avatar" alt="avatar" onerror="this.onerror=null;this.src='assets/img/default-placeholder.png';"/>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header id="user-header">
      {{ 'MANAGED_COMPANIES_TABLE.NAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let company">{{ company.name }}</td>
  </ng-container>

  <!-- Users Column -->
  <ng-container matColumnDef="users">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">
      {{ 'MANAGED_COMPANIES_TABLE.USERS' | translate }}
    </th>
    <td mat-cell *matCellDef="let company" class="center-align-column">
      {{ company.users.length | number: '1.0-0':'de' }}
    </td>
  </ng-container>

  <!-- Available Licenses Column -->
  <ng-container matColumnDef="availableLicenses">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">
      {{ 'MANAGED_COMPANIES_TABLE.AVAILABLE_LICENSES' | translate }}
    </th>
    <td mat-cell *matCellDef="let company" class="center-align-column">
      {{ company.availableLicenses | number: '1.0-0':'de' }}
    </td>
  </ng-container>

  <!-- Total Steps Column -->
  <ng-container matColumnDef="totalSteps">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">
      {{ 'MANAGED_COMPANIES_TABLE.TOTAL_STEPS' | translate }}
    </th>
    <td mat-cell *matCellDef="let company" class="center-align-column">
      {{ company.totalSteps | number: '1.0-0':'de' }}
    </td>
  </ng-container>

  <!-- Expanded Row -->
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let company">
      <mat-icon *ngIf="expandedCompany !== company">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="expandedCompany === company && !showSpinner">keyboard_arrow_up</mat-icon>
      <mat-spinner [diameter]="16" *ngIf="expandedCompany === company && showSpinner"></mat-spinner>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let company" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="company-challenges-list" *ngIf="company == expandedCompany" [@detailExpand]="company === expandedCompany ? 'expanded' : 'collapsed'">
        <section class="no-challenge-data" *ngIf="!showSpinner && managedCompanyChallengesData.get(company.id).length === 0">
          {{ 'MANAGED_COMPANIES_TABLE.NO_DATA_AVAILABLE' | translate }}
        </section>
        <section class="company-challenge" *ngIf="!showSpinner && managedCompanyChallengesData.get(company.id).length > 0">
          <table class="challenge-data">
            <thead>
              <th>{{ 'MANAGED_COMPANIES_TABLE.CHALLENGE_NAME' | translate }}</th>
              <th>{{ 'MANAGED_COMPANIES_TABLE.START_DATE' | translate }}</th>
              <th>{{ 'MANAGED_COMPANIES_TABLE.END_DATE' | translate }}</th>
            </thead>
            <tbody>
              <tr *ngFor="let challenge of managedCompanyChallengesData.get(company.id)">
                <td> {{ challenge.challengeName }} </td>
                <td> {{ challenge.startDate | date: 'dd.MM.yyyy' }} </td>
                <td> {{ challenge.endDate | date: 'dd.MM.yyyy' }} </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let company; columns: columnsToDisplayWithExpand;"
      class="company-row"
      [class.example-expanded-row]="expandedCompany === company"
      (click)="selectedRow(company)">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="company-challenge-row"></tr>
</table>

