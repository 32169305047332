import { Component, Inject, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-company-update-available-licences',
  templateUrl: './company-update-available-licences.component.html',
  styleUrls: ['./company-update-available-licences.component.scss'],
})
export class CompanyUpdateAvailableLicencesComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CompanyUpdateAvailableLicencesComponent>,
    private firebaseService: FirebaseService,
    @Inject(MAT_DIALOG_DATA) public company: Company
  ) {}

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }

  public async updateCompanyAvailableLicences(): Promise<void> {
    return this.firebaseService.updateCompany(this.company.id, {
      availableLicenses: this.company.availableLicenses,
    });
  }
}
