import { Component, Input } from '@angular/core';
import { Location } from 'src/app/models/location.model';

@Component({
  selector: 'app-team-challenge-info-locations',
  templateUrl: './team-challenge-info-locations.component.html',
  styleUrls: ['./team-challenge-info-locations.component.scss'],
})
export class TeamChallengeInfoLocationsComponent {
  @Input() locationSelectedName: string = '';
  @Input() locationsArray: Array<Location> = [];

  constructor() {}
}
