import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyStatus } from 'src/app/models/company-status.enum';
import { Company } from 'src/app/models/company.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-company-status-dialog',
  templateUrl: './company-status-dialog.component.html',
  styleUrls: ['./company-status-dialog.component.scss'],
})
export class CompanyStatusDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CompanyStatusDialogComponent>,
    private firebaseService: FirebaseService,
    @Inject(MAT_DIALOG_DATA) public company: Company
  ) {}

  get statusEnumKeys(): Array<number> {
    return Object.keys(CompanyStatus)
      .map((key) => CompanyStatus[key])
      .filter((value) => typeof value === 'number') as number[];
  }

  get statusEnumNames(): Array<string> {
    return Object.keys(CompanyStatus)
      .map((key) => CompanyStatus[key])
      .filter((value) => typeof value === 'string') as string[];
  }

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }

  public async updateCompanyStatus(): Promise<void> {
    return this.firebaseService.updateCompany(this.company.id, {
      status: Number(this.company.status),
    });
  }
}
