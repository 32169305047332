import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { FirebaseService } from '../services/firebase/firebase.service';
import { LoginService } from '../services/login/login.service';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private firebaseService: FirebaseService,
    private router: Router
  ) {}

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const url: string = state.url;
    const isLoggedIn = await this.checkLogin(url);
    return isLoggedIn;
  }

  private async checkLogin(url: string): Promise<boolean> {
    const isLoggedIn = this.loginService.isUserLoggedIn();
    const isFirebaseSessionActive = await this.firebaseService.isFirebaseSessionActive();

    if (isLoggedIn && isFirebaseSessionActive) {
      return Promise.resolve(true);
    }

    // Store the attempted URL for redirecting
    this.userService.redirectUrl = url;

    // Navigate to the login page
    this.router.navigate(['/login']);
    return Promise.resolve(false);
  }
}
