import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from 'src/app/models/location.model';

export interface LocationLinkData {
  location: Location;
  locationList: Array<Location>;
}

@Component({
  selector: 'app-link-locations-dialog',
  templateUrl: './link-locations-dialog.component.html',
  styleUrls: ['./link-locations-dialog.component.scss'],
})
export class LinkLocationsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LinkLocationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LocationLinkData
  ) {}

  ngOnInit(): void {}

  public updateChildLocations(location: Location): void {
    if (this.data.location.childLocations.optionNames.includes(location.name)) {
      this.data.location.childLocations.optionNames = this.data.location.childLocations.optionNames.filter(
        (locationName) => locationName !== location.name
      );
    } else {
      this.data.location.childLocations.optionNames.push(location.name);
    }
  }

  public locationsSelected(): void {
    this.dialogRef.close();
  }
}
