import { AbstractControl } from '@angular/forms';
export class SignupValidators {
  static MatchEmail(control: AbstractControl): void {
    const email = control.get('email').value;
    const checkEmail = control.get('checkEmail').value;
    if (checkEmail && email !== checkEmail) {
      control.get('checkEmail').setErrors({ ConfirmEmail: true });
    }
  }

  static MatchPassword(control: AbstractControl): void {
    const password = control.get('password').value;
    const checkPassword = control.get('checkPassword').value;
    if (password !== checkPassword) {
      control.get('checkPassword').setErrors({ ConfirmPassword: true });
    }
  }
}
