import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UserMessageService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  public snackBarMessage(message: string): void {
    this.snackBar.open(
      this.translateService.instant(message),
      this.translateService.instant('USER_MESSAGE_SERVICE.OK'),
      {
        duration: 3000,
        panelClass: ['snack-bar-color'],
      }
    );
  }

  public openConfirmationDialog(
    data: ConfirmationDialogData
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): MatDialogRef<ConfirmationDialogComponent, any> {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data,
    });
  }
}
