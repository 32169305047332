import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private localstorageService: LocalstorageService,
    private firebaseService: FirebaseService,
    private router: Router
  ) {}

  public async login(
    email: string,
    password: string,
    persistence: boolean
  ): Promise<string> {
    let loginFunction: (
      email: string,
      password: string
    ) => Promise<firebase.default.auth.UserCredential>;
    if (persistence) {
      loginFunction = this.firebaseService
        .persistenceSignInWithEmailAndPassword;
    } else {
      loginFunction = this.firebaseService.signInWithEmailAndPassword;
    }
    return loginFunction(email, password)
      .then((firebaseUserCredential) => {
        if (!firebaseUserCredential.user.emailVerified) {
          return Promise.reject({
            code: 'auth/email-not-verified',
          });
        }
        return Promise.resolve(firebaseUserCredential.user.uid);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  public sendVerificationEmail(
    email: string,
    lang: string
  ): Promise<firebase.default.functions.HttpsCallableResult> {
    return this.firebaseService.sendVerificationEmail(email, '', lang);
  }

  public getUser(
    userId: string
  ): Promise<
    firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
  > {
    return this.firebaseService.getUser(userId);
  }

  public async checkIfUserIsAdmin(
    userId: string,
    companyId: string
  ): Promise<
    firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>
  > {
    return this.firebaseService.checkIfUserIsAdmin(userId, companyId);
  }

  public logout(): void {
    this.localstorageService.setUserId(null);
    this.localstorageService.setCompanyId(null);
    this.localstorageService.setTeam(null);
    this.router.navigate(['/login']);
  }

  public setUserLoggedIn(userId: string): void {
    this.localstorageService.setUserId(userId);
  }

  public isUserLoggedIn(): boolean {
    const userId = this.localstorageService.getUserId();
    return userId !== null && userId !== undefined;
  }
}
