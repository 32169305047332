import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { CompanyService } from '../company/company.service';
import { Company } from 'src/app/models/company.model';
import { Notification } from 'src/app/models/notification.model';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { Event } from 'src/app/models/event/event';
import { EventsService } from '../events/events.service';
import { CompanyTrainingsStatistics } from 'src/app/models/company-trainings-statistics.model';
import { Helpers } from 'src/app/helpers/helpers';
import { SubscriptionService } from '../subscription/subscription.service';
import { Features } from 'src/app/models/features.enum';
import { CompanyStatus } from 'src/app/models/company-status.enum';
import { CitiesChallenge } from 'src/app/models/cities-challenge/cities-challenge/cities-challenge.model';

export interface IChallengeData {
  challengeName: string;
  startDate: Date;
  endDate: Date;
}
@Injectable({
  providedIn: 'root',
})
export class ProjectManagerCompanyService {
  public company: Company;

  public managedCompanies: Array<Company> = [];
  public activeManagedCompanies: Array<Company> = [];
  public inactiveManagedCompanies: Array<Company> = [];
  public pushNotificationsSent: Array<Notification> = [];
  public managedCompaniesChallengesData: Map<
    string,
    Array<IChallengeData>
  > = new Map();
  public managedCompaniesStatistics: Map<
    string,
    CompanyTrainingsStatistics
  > = new Map();

  constructor(
    private subscriptionService: SubscriptionService,
    private firebaseService: FirebaseService,
    private companyService: CompanyService,
    private eventsService: EventsService
  ) {
    this.subscriptionService.company.subscribe((company) => {
      // Executes every time company changes
      if (company && company.features.has(Features.ProjectManager)) {
        this.company = company;
        this.getManagedCompaniesAndStatistics();
        this.getPushNotifications();
      }
    });
  }

  public getManagedCompanies(
    companyId: string = this.company.id
  ): Promise<Array<Company>> {
    let managedCompanies: Array<Company> = [];
    return this.firebaseService
      .getManagedCompanies(companyId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          managedCompanies = querySnapshot.docs.map(
            (documentSnapshot) => new Company(documentSnapshot.data())
          );
        }
        // Save values in local variables.
        if (this.company && companyId === this.company.id) {
          this.managedCompanies = managedCompanies;
          this.activeManagedCompanies = this.managedCompanies.filter(
            (company) => company.status === CompanyStatus.Active
          );
          this.inactiveManagedCompanies = this.managedCompanies.filter(
            (company) => company.status !== CompanyStatus.Active
          );
        }
        return managedCompanies;
      })
      .catch((error) => {
        console.log('Project manager service - getManagedCompanies - ', error);
        return managedCompanies;
      });
  }

  public getPushNotifications(
    companyId: string = this.company.id
  ): Promise<Array<Notification>> {
    let pushNotificationsSent: Array<Notification> = [];
    return this.firebaseService
      .getPushNotifications(companyId)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          pushNotificationsSent = querySnapshot.docs.map(
            (documentSnapshot) => new Notification(documentSnapshot.data())
          );
        }
        if (this.company && companyId === this.company.id) {
          this.pushNotificationsSent = pushNotificationsSent;
        }
        return pushNotificationsSent;
      })
      .catch((error) => {
        console.log('Project manager service - getPushNotifications - ', error);
        return pushNotificationsSent;
      });
  }

  public async getManagedCompanyChallenges(
    companyId: string,
    managedCompaniesChallengesData: Map<string, Array<IChallengeData>> = this
      .managedCompaniesChallengesData
  ): Promise<Map<string, Array<IChallengeData>>> {
    if (!managedCompaniesChallengesData.has(companyId)) {
      try {
        const challenges: Array<CitiesChallenge | TeamChallenge | Event> = [];
        const challengesData: Array<IChallengeData> = [];

        const distanceChallenges = await this.companyService.getDistanceChallenges(
          companyId
        );
        const teamChallenges = await this.companyService.getTeamChallenges(
          companyId
        );
        const eventChallenges = await this.eventsService.getCompanyArrayEvents(
          companyId
        );

        challenges.push(
          ...distanceChallenges,
          ...teamChallenges,
          ...eventChallenges
        );

        challengesData.push(
          ...distanceChallenges.map((distanceChallenge) => ({
            challengeName: distanceChallenge.name,
            startDate: Helpers.unformatDate(distanceChallenge.startDate),
            endDate: Helpers.unformatDate(distanceChallenge.endDate),
          }))
        );

        challengesData.push(
          ...teamChallenges.map((teamChallenge) => ({
            challengeName: teamChallenge.name,
            startDate: new Date(teamChallenge.startDate),
            endDate: new Date(teamChallenge.endDate),
          }))
        );

        challengesData.push(
          ...eventChallenges.map((eventChallenge) => ({
            challengeName: eventChallenge.name,
            startDate: Helpers.unformatDateYYYYMMDD(eventChallenge.days[0]),
            endDate: Helpers.unformatDateYYYYMMDD(
              eventChallenge.days[eventChallenge.days.length - 1]
            ),
          }))
        );
        managedCompaniesChallengesData.set(companyId, challengesData);
      } catch (error) {
        console.log(
          'Project manager service - getManagedCompanyChallenges - ',
          error
        );
      }
    }
    return managedCompaniesChallengesData;
  }

  public getManagedCompanyStatistics(
    managedCompanies: Array<Company> = this.managedCompanies
  ): Promise<Map<string, CompanyTrainingsStatistics>> {
    const managedCompaniesStatistics: Map<
      string,
      CompanyTrainingsStatistics
    > = new Map();
    return this.firebaseService
      .getCompaniesStatistics(managedCompanies.map((company) => company.id))
      .then((documentsResult) => {
        documentsResult.forEach((documentSnapshot) => {
          if (documentSnapshot.exists) {
            const companyId = documentSnapshot.ref.path.split('/')[1];
            managedCompaniesStatistics.set(
              companyId,
              new CompanyTrainingsStatistics(documentSnapshot.data())
            );
          }
        });
        if (
          this.managedCompanies &&
          this.managedCompanies === managedCompanies
        ) {
          this.managedCompaniesStatistics = managedCompaniesStatistics;
        }
        return managedCompaniesStatistics;
      })
      .catch((error) => {
        console.log(
          'Project manager service - getManagedCompanyStatistics - ',
          error
        );
        return managedCompaniesStatistics;
      });
  }

  public getManagedCompaniesAndStatistics(
    companyId: string = this.company.id
  ): Promise<Map<string, CompanyTrainingsStatistics>> {
    return this.getManagedCompanies(companyId).then((managedCompanies) =>
      this.getManagedCompanyStatistics(managedCompanies)
    );
  }
}
