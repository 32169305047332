<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <h1 class="title">{{ 'HELP_TITLE' | translate }}</h1>
  <div>
    <p class="email-paragraph">
      {{ 'HELP_SEND_US' | translate }}
      <a href="mailto: support@move-me-service.com"
        >support@move-me-service.com</a
      >
      {{ 'HELP_USE_FORM' | translate }}
    </p>
  </div>
  <form
    fxLayout.xs="column space-between"
    [formGroup]="helpForm"
    (ngSubmit)="sendMessage()"
  >
    <h3 class="subtitle">{{ 'HELP_CONTACT_FORM_TITLE' | translate }}</h3>
    <mat-label>
      {{ 'HELP_CONTACT_FORM_TEXT' | translate }}
    </mat-label>

    <div class="form-position">
      <mat-form-field class="name-field">
        <mat-label>{{ 'forms.firstName.LABEL' | translate }}</mat-label>
        <input
          matInput
          type="text"
          class="form-control"
          formControlName="firstName"
          id="firstName"
        />
        <mat-error *ngIf="firstName.hasError('pattern')">
          {{ 'forms.firstName.PATTERN_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="name-field">
        <mat-label>{{ 'forms.lastName.LABEL' | translate }}</mat-label>
        <input
          matInput
          type="text"
          class="form-control"
          formControlName="lastName"
          id="lastName"
        />
        <mat-error *ngIf="lastName.hasError('pattern')">
          {{ 'forms.lastName.PATTERN_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'forms.email.LABEL' | translate }}</mat-label>
        <input
          matInput
          type="email"
          class="form-control"
          formControlName="email"
          id="email"
        />
        <mat-error *ngIf="email.hasError('pattern')">
          {{ 'forms.email.PATTERN_ERROR' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'forms.phone.LABEL' | translate }}</mat-label>
        <input
          matInput
          type="email"
          class="form-control"
          formControlName="phone"
          id="phone"
        />
        <mat-error *ngIf="phone.hasError('pattern')">
          {{ 'forms.phone.PATTERN_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
      <div fxLayoutAlign="row">
        <mat-checkbox
          class="privacy-policy-button"
          color="primary"
          formControlName="privacyPolicy"
          [required]="
            email.value !== '' ||
            phone.value !== '' ||
            firstName.value !== '' ||
            lastName.value !== ''
          "
        >
          {{
            email.value !== '' ||
            phone.value !== '' ||
            firstName.value !== '' ||
            lastName.value !== ''
              ? '*'
              : ''
          }}
        </mat-checkbox>
        <p class="privacy-policy-text">
          {{ 'HELP_PRIVACY_POLICY_CHECKBOX_TEXT' | translate
          }}{{ 'PRIVACY_POLICY' | translate }}
        </p>
      </div>
      <mat-form-field class="message-field">
        <mat-label>{{ 'forms.contactMessage.LABEL' | translate }}</mat-label>
        <textarea
          matInput
          type="text"
          class="form-control"
          formControlName="message"
          id="message"
          cdkTextareaAutosize
          cdkAutosizeMinRows="10"
          cdkAutosizeMaxRows="15"
          #autosize="cdkTextareaAutosize"
          required
        >
        </textarea>
        <mat-error *ngIf="message.hasError('required')">
          {{ 'forms.contactMessage.REQUIRED_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="message.hasError('pattern')">
          {{ 'forms.contactMessage.PATTERN_ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <br />
    <div class="send-button">
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!helpForm.valid || sendingMail"
      >
        {{ sendingMail ? '' : SEND_BUTTON_LABEL }}
        <mat-spinner [diameter]="16" *ngIf="sendingMail"></mat-spinner>
      </button>
    </div>
  </form>
</div>
