import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { TranslateService } from '@ngx-translate/core';

import { LicenceTypes } from 'src/app/models/licence-types.enum';
import { Team } from 'src/app/models/team.model';
import { TeamSchema } from 'src/app/models/team-schema.model';
import { Company } from 'src/app/models/company.model';
import { AdminTabs } from '../admin-tabs.enum';
import { Features } from 'src/app/models/features.enum';
import { AdminService } from 'src/app/services/admin/admin.service';
import { REGEX_ALPHANUMERIC_WITHOUT_GERMAN_CHARS } from 'src/app/constants/constants';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss'],
})
export class AddTeamComponent implements OnInit {
  public teamDataForm: UntypedFormGroup;
  public licences: LicenceTypes;
  public showSpinner = false;
  private isAdmin: boolean;
  public FEATURE_LOCATIONS: Features = Features.Locations;

  public companyId: string;
  public company: Company = null;

  public ADD_TEAM_CREATED = '';
  private REGEX_FOR_ID = REGEX_ALPHANUMERIC_WITHOUT_GERMAN_CHARS;

  constructor(
    private firebaseService: FirebaseService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) {
    this.getTranslations();
    this.teamDataForm = this.formBuilder.group({
      avatar: ['assets/img/move-me-new.png'],
      avatarImg: [undefined, Validators.required],
      color: ['#f97a1c', Validators.required],
      teamId: [
        '',
        [Validators.required, Validators.pattern(this.REGEX_FOR_ID)],
      ],
      name: ['', Validators.required],
      companyId: [{ value: '', disabled: true }, Validators.required],
      location: [''],
    });
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.isAdmin = this.companyId === null;
    this.teamDataForm.controls.companyId.setValue(this.companyId);
    if (this.companyId) {
      this.getSelectedCompany(this.companyId);
    }
  }

  get companies(): Array<Company> {
    return this.adminService.companies;
  }

  public getSelectedCompany(companyId: string): void {
    this.company = this.companies.find((company) => company.id === companyId);
  }

  public async addTeam(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (!this.isAdmin) {
      if (this.teamDataForm.valid) {
        const companyId = this.teamDataForm.controls.companyId.value;
        const teamId = this.teamDataForm.controls.teamId.value;

        if (this.teamDataForm.controls.avatarImg.value) {
          const avatarFile = this.teamDataForm.controls.avatarImg.value
            .files[0];

          await this.firebaseService
            .uploadImage(
              avatarFile,
              'companies/' + companyId + '/' + teamId + '/avatar'
            )
            .then(async (snapshot) => {
              const url = await snapshot.ref.getDownloadURL();
              this.teamDataForm.controls.avatar.setValue(url);
            });
        }
        const team = new Team({
          avatar: this.teamDataForm.controls.avatar.value,
          color: this.teamDataForm.controls.color.value,
          name: this.teamDataForm.controls.name.value,
          id: this.teamDataForm.controls.teamId.value,
          location: this.teamDataForm.controls.location.value,
          totalSteps: 0,
          totalPoints: 0,
          teamCaptain: '',
          records: [],
          members: [],
        });

        await this.firebaseService.createCompanyTeam(
          this.teamDataForm.controls.companyId.value,
          this.teamDataForm.controls.teamId.value,
          team
        );

        this.teamCreationFinished();
      }
    } else {
      if (
        this.teamDataForm.controls.avatar.valid &&
        this.teamDataForm.controls.avatarImg.valid &&
        this.teamDataForm.controls.color.valid &&
        this.teamDataForm.controls.teamId.valid &&
        this.teamDataForm.controls.name.valid
      ) {
        const teamId = this.teamDataForm.controls.teamId.value;

        if (this.teamDataForm.controls.avatarImg.value) {
          const avatarFile = this.teamDataForm.controls.avatarImg.value
            .files[0];

          await this.firebaseService
            .uploadImage(avatarFile, 'teams/' + teamId + '/avatar')
            .then(async (snapshot) => {
              console.log('image uploaded');
              const url = await snapshot.ref.getDownloadURL();
              this.teamDataForm.controls.avatar.setValue(url);
            });
        }
        const team = new TeamSchema().deserialize({
          avatar: this.teamDataForm.controls.avatar.value,
          color: this.teamDataForm.controls.color.value,
          name: this.teamDataForm.controls.name.value,
          id: this.teamDataForm.controls.teamId.value,
        });

        try {
          await this.firebaseService.createTeamSchema(teamId, team);
          this.teamCreationFinished();
        } catch (error) {
          console.log('error: ', error);
          this.showErrorSnackBar(error);
        } finally {
          this.showSpinner = false;
        }
      }
    }
  }

  public teamCreationFinished(): void {
    this.snackBar.open(this.ADD_TEAM_CREATED, 'Ok', {
      duration: 4000,
      panelClass: 'snack-bar-color',
    });
    this.router.navigate(['..'], {
      relativeTo: this.route,
      state: { tab: AdminTabs.Teams },
    });
  }

  public showErrorSnackBar(error): void {
    this.snackBar.open(error, 'Ok', {
      duration: 4000,
      panelClass: 'snack-bar-color',
    });
  }

  public returnPage(): void {
    this.router.navigate(['..'], {
      relativeTo: this.route,
      state: { tab: AdminTabs.Teams },
    });
  }

  public getTranslations(): void {
    this.translateService.stream(['ADD_TEAM_CREATED']).subscribe((values) => {
      this.ADD_TEAM_CREATED = values.ADD_TEAM_CREATED;
    });
  }
}
