import { Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import {
  BlobFile,
  ReportsService,
} from 'src/app/services/reports/reports.service';
import { MethodNotImplementedError } from 'pdf-lib';

interface IFunctionsMap {
  [prop: number]: () => Promise<void>;
}

export enum EmailType {
  NoAttachments,
  OneAttachmentPerRecipient,
  AllAttachmentsPerRecipient,
}

export enum EmailSendResult {
  Sent,
  NotSent,
}

export interface IEmailData {
  recipients: Array<string>;
  subject: string;
  body: string;
  attachments: Array<BlobFile>;
  type: EmailType;
  replyTo?: string;
}

@Component({
  selector: 'app-new-email-form',
  templateUrl: './new-email-form.component.html',
  styleUrls: ['./new-email-form.component.scss'],
})
export class NewEmailFormComponent {
  public emailFormGroup: UntypedFormGroup;

  public showSpinner = false;

  private addAttachmentsFunctionsMap: IFunctionsMap = {
    [EmailType.NoAttachments]: this.sendEmailWithoutAttachment.bind(this),
    [EmailType.OneAttachmentPerRecipient]: this.sendOneAttachmentPerRecipient.bind(
      this
    ),
    [EmailType.AllAttachmentsPerRecipient]: this.sendAllAttachmentsPerRecipient.bind(
      this
    ),
  };

  constructor(
    public dialogRef: MatDialogRef<NewEmailFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEmailData,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private firebaseService: FirebaseService,
    private reportsService: ReportsService
  ) {
    this.emailFormGroup = this.formBuilder.group({
      subject: [this.data.subject, Validators.required],
      body: [this.data.body, Validators.required],
    });
  }

  public closeDialog(): void {
    const dialogTranslation = this.translateService.instant(
      'CONFIRM_CLOSE_WINDOW_DIALOG'
    );
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      panelClass: 'confirmation-close-window-dialog',
      data: new ConfirmationDialogData({
        title: dialogTranslation.TITLE,
        message: dialogTranslation.MESSAGE,
        confirmLabel: dialogTranslation.CONFIRM,
        cancelLabel: dialogTranslation.CANCEL,
        action: () => this.dialogRef.close(EmailSendResult.NotSent),
      }),
    });
  }

  public async sendEmail(): Promise<void> {
    this.showSpinner = true;
    return this.addAttachmentsFunctionsMap[this.data.type]()
      .catch((error) => {
        console.log('error: ', error);
        this.dialog.open(InfoDialogComponent, {
          width: '350px',
          data: {
            message: this.translateService.instant(
              'EMAILS.ERROR_WHEN_SENDING_EMAIL'
            ),
          },
        });
      })
      .finally(() => {
        this.showSpinner = false;
        this.dialogRef.close(EmailSendResult.Sent);
        this.dialog.open(InfoDialogComponent, {
          width: '350px',
          data: {
            message: this.translateService.instant(
              'EMAILS.EMAIL_SENT_SUCCESFULLY'
            ),
          },
        });
      });
  }

  public async sendEmailWithoutAttachment(): Promise<void> {
    const emailPromises = [];
    for (const recipient of this.data.recipients) {
      // Create Emails and send them
      const formData = new FormData();

      formData.append('to', recipient);
      formData.append('subject', this.emailFormGroup.controls.subject.value);
      formData.append('text', this.emailFormGroup.controls.body.value);

      if (this.data.replyTo) {
        formData.append('replyTo', this.data.replyTo);
      }

      emailPromises.push(this.firebaseService.sendEmail(formData));
    }

    return Promise.all(emailPromises)
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  }

  public async sendOneAttachmentPerRecipient(): Promise<void> {
    // Run a firebaseService.sendEmail for every team captain selected, with their corresponding report
    const emailPromises = [];
    for (const [index, recipient] of this.data.recipients.entries()) {
      // Create Emails and send them
      const formData = new FormData();

      formData.append('to', recipient);
      formData.append('subject', this.emailFormGroup.controls.subject.value);
      formData.append('text', this.emailFormGroup.controls.body.value);

      // ATTACHMENTS AND RECIPIENTS MUST BE IN ORDER !!!
      formData.append(
        this.data.attachments[index].title,
        this.data.attachments[index].blob,
        this.data.attachments[index].title
      );

      emailPromises.push(this.firebaseService.sendEmail(formData));
    }

    return Promise.all(emailPromises)
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  }

  public async sendAllAttachmentsPerRecipient(): Promise<void> {
    return Promise.reject(
      new MethodNotImplementedError(
        'NewEmailFormComponent',
        'sendAllAttachmentsPerRecipient'
      )
    );
  }

  public openBlob(blob: Blob): void {
    this.reportsService.openBlob(blob);
  }

  public discardFile(index: number): void {
    const dialogTranslation = this.translateService.instant(
      'CONFIRM_REMOVE_TEAM_CAPTAIN_REPORT_FROM_EMAIL_DIALOG'
    );
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      panelClass: 'confirmation-close-window-dialog',
      data: new ConfirmationDialogData({
        title: dialogTranslation.TITLE,
        message: dialogTranslation.MESSAGE,
        confirmLabel: dialogTranslation.CONFIRM,
        cancelLabel: dialogTranslation.CANCEL,
        action: (): void => {
          this.data.recipients.splice(index, 1);
          this.data.attachments.splice(index, 1);
        },
      }),
    });
  }
}
