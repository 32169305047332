<h1 mat-dialog-title>Import Route</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="data.route" matSort>

    <!-- Start City Column -->
    <ng-container matColumnDef="startingCity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTANCE_CHALLENGE_ROUTE_IMPORT.STARTING_CITY' | translate }}</th>
      <td mat-cell *matCellDef="let city">{{ city.startingCity }}</td>
    </ng-container>

    <!-- End City Column -->
    <ng-container matColumnDef="endingCity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTANCE_CHALLENGE_ROUTE_IMPORT.ENDING_CITY' | translate }}</th>
      <td mat-cell *matCellDef="let city">{{ city.endingCity }}</td>
    </ng-container>

    <!-- Distance Column -->
    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTANCE_CHALLENGE_ROUTE_IMPORT.DISTANCE' | translate }}</th>
      <td mat-cell *matCellDef="let city">
        {{ city.distance }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="routesColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: routesColumns"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial>{{ 'Cancel' }}</button>
  <button mat-button (click)="onYesClick()">{{ 'Import' }}</button>
</div>
