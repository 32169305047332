import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// Models
import { PointsSource } from 'src/app/models/points-source.model';
import { PointsSourceType } from 'src/app/models/points-source-type.enum';
import { PointsSourceBasis } from 'src/app/models/points-source-basis.enum';

// Services
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AdminTabs } from '../admin-tabs.enum';

@Component({
  selector: 'app-add-points-source',
  templateUrl: './add-points-source.component.html',
  styleUrls: ['./add-points-source.component.scss'],
})
export class AddPointsSourceComponent implements OnInit {
  public pointsSourceDataForm: UntypedFormGroup;
  public companyId: string;

  public showSpinner = false;

  public pointsSourceTypesKeys: Array<number> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'number') as number[];

  public pointsSourceTypesNames: Array<string> = Object.keys(PointsSourceType)
    .map((key) => PointsSourceType[key])
    .filter((value) => typeof value === 'string') as string[];

  public pointsSourceBasisKeys: Array<number> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'number') as number[];

  public pointsSourceBasisNames: Array<string> = Object.keys(PointsSourceBasis)
    .map((key) => PointsSourceBasis[key])
    .filter((value) => typeof value === 'string') as string[];

  public ADD_POINTS_SOURCE_CREATED = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private firebaseService: FirebaseService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private adminService: AdminService
  ) {
    this.getTranslations();
    this.pointsSourceDataForm = this.formBuilder.group({
      defaultPointsSource: [null],
      id: ['', Validators.required],
      description: ['', Validators.required],
      basis: ['0', Validators.required],
      type: ['0', Validators.required],
      expectedPerformance: [
        1,
        Validators.compose([
          Validators.min(1),
          Validators.max(1000000),
          Validators.required,
        ]),
      ],
      pointsAwarded: [
        1,
        Validators.compose([
          Validators.min(1),
          Validators.max(5),
          Validators.required,
        ]),
      ],
    });

    this.pointsSourceDataForm.controls.defaultPointsSource.valueChanges.subscribe(
      (value) => {
        const pointSource = this.defaultPointSources.find(
          (defaultPointsSource) => defaultPointsSource.id === value
        );
        this.pointsSourceDataForm.controls.id.setValue(pointSource.id);
        this.pointsSourceDataForm.controls.description.setValue(
          pointSource.description
        );
        this.pointsSourceDataForm.controls.basis.setValue(
          String(this.pointsSourceBasisKeys[pointSource.basis])
        );
        this.pointsSourceDataForm.controls.type.setValue(
          String(this.pointsSourceTypesKeys[pointSource.type])
        );
        this.pointsSourceDataForm.controls.expectedPerformance.setValue(
          pointSource.expectedPerformance
        );
        this.pointsSourceDataForm.controls.pointsAwarded.setValue(
          pointSource.pointsAwarded
        );
      }
    );
  }

  get defaultPointSources(): Array<PointsSource> {
    return this.adminService.pointsSources;
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
  }

  private getTranslations(): void {
    this.translateService
      .stream(['ADD_POINTS_SOURCE_CREATED'])
      .subscribe((values) => {
        this.ADD_POINTS_SOURCE_CREATED = values.ADD_POINTS_SOURCE_CREATED;
      });
  }

  public returnPage(): void {
    this.router.navigate(['..'], {
      relativeTo: this.route,
      state: { tab: AdminTabs.PointsSources },
    });
  }

  public async addPointsSource(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (this.pointsSourceDataForm.valid) {
      const pointsSourceId = this.pointsSourceDataForm.controls.id.value;

      const pointsSource = new PointsSource().deserialize({
        type: Number(this.pointsSourceDataForm.controls.type.value),
        description: this.pointsSourceDataForm.controls.description.value,
        basis: Number(this.pointsSourceDataForm.controls.basis.value),
        expectedPerformance: this.pointsSourceDataForm.controls
          .expectedPerformance.value,
        pointsAwarded: this.pointsSourceDataForm.controls.pointsAwarded.value,
        isAchieved: false,
        id: this.pointsSourceDataForm.controls.id.value,
      });

      if (this.companyId) {
        await this.firebaseService.createPointsSourceInCompany(
          this.companyId,
          pointsSource
        );
      } else {
        await this.firebaseService.createPointsSource(pointsSource);
      }

      this.showSpinner = false;
      this.snackBar.open(this.ADD_POINTS_SOURCE_CREATED, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
      this.router.navigate(['..'], {
        relativeTo: this.route,
        state: { tab: AdminTabs.PointsSources },
      });
    }
  }
}
