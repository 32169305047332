<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>{{ data.propertyToUpdate }}</mat-label>
    <mat-select required [(value)]="selectedOption">
      <mat-option
        *ngFor="let option of data.options; let i = index"
        value="{{ option.value }}"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
  <button mat-button [mat-dialog-close]="data.object" (click)="selectedValue()" cdkFocusInitial>Select</button>
</div>