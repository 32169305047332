import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export interface IFeatureSection {
  title: string;
  text: string;
}

export interface IFeatureSelectionCard {
  feature: string;
  disabled: boolean;
  inactiveSection: IFeatureSection;
  activeSection: IFeatureSection;
  checked?: boolean;
}

export interface IFeatureChange {
  feature: string;
  value: boolean;
}

@Component({
  selector: 'app-feature-selection-card',
  templateUrl: './feature-selection-card.component.html',
  styleUrls: ['./feature-selection-card.component.scss'],
})
export class FeatureSelectionCardComponent {
  @Input() featureSelectionCard: IFeatureSelectionCard;
  @Output()
  featureToggled: EventEmitter<IFeatureChange> = new EventEmitter<IFeatureChange>();

  constructor() {}

  public featureToggledEvent(event: MatSlideToggleChange): void {
    this.featureToggled.emit({
      feature: this.featureSelectionCard.feature,
      value: event.checked,
    });
  }
}
