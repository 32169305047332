<div
  class="container"
  fxLayout.xs="column space-between"
  fxLayout.sm="column space-between"
>
  <div class="top">
    <mat-icon (click)="returnPage()">keyboard_backspace</mat-icon>
    <h1 class="new-company-title">{{ 'CREATE_COMPANY.TITLE'| translate }}</h1>
  </div>

  <mat-horizontal-stepper #stepper linear="true" labelPosition="bottom">
    <!-- COMPANY DATA -->
    <mat-step [stepControl]="companyDataForm" #companyStep>
      <ng-template matStepLabel><mat-icon [ngStyle]="{ 'color': companyStep.completed ? 'var(--color-primary)' : '' }">domain</mat-icon></ng-template>
      <h1 class="title">{{ 'CREATE_COMPANY.COMPANY_FORM.TITLE'| translate }}</h1>
      <p class="paragraph">{{ 'CREATE_COMPANY.COMPANY_FORM.SUBTITLE' | translate }}</p>
        <form [formGroup]="companyDataForm">
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.COMPANY_FORM.companyId.LABEL' | translate }}</mat-label>
            <input matInput required formControlName="companyId" id="id" />
            <mat-error>{{ 'CREATE_COMPANY.COMPANY_FORM.companyId.REQUIRED_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.COMPANY_FORM.companyName.LABEL' | translate }}</mat-label>
            <input matInput required formControlName="name" />
            <mat-error>{{ 'CREATE_COMPANY.COMPANY_FORM.companyName.REQUIRED_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.COMPANY_FORM.adminEmails.LABEL' | translate }}</mat-label>
            <mat-chip-list #chipList required>
              <mat-chip *ngFor="let email of adminEmails" [selectable]="true"
              [removable]="true" (removed)="removeAdminEmail(email)">
              {{ email }}
              <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input type="email" formControlName="adminEmail"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addAdminEmail($event)" />
              </mat-chip-list>
              <mat-error *ngIf="adminEmail.hasError('required')">{{ 'CREATE_COMPANY.COMPANY_FORM.adminEmails.REQUIRED_ERROR' | translate }}</mat-error>
              <mat-error *ngIf="adminEmail.hasError('email')">{{ 'CREATE_COMPANY.COMPANY_FORM.adminEmails.INVALID_EMAIL_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.COMPANY_FORM.availableLicences.LABEL' | translate }}</mat-label>
            <input matInput required type="number" formControlName="availableLicenses" />
            <mat-error *ngIf="availableLicenses.hasError('required')">{{ 'CREATE_COMPANY.COMPANY_FORM.availableLicences.REQUIRED_ERROR' | translate }}</mat-error>
            <mat-error *ngIf="availableLicenses.hasError('pattern')">{{ 'CREATE_COMPANY.COMPANY_FORM.availableLicences.PATTERN_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.PROJECT_MANAGER_FORM.MANAGER_COMPANY' | translate }} ({{'forms.OPTIONAL' | translate}}) </mat-label>
            <mat-select [disabled]="companyDataForm.controls.featureProjectManager.value" multiple formControlName="managerCompanyIds">
              <mat-option *ngFor="let company of managerCompanies" [value]="company.id">{{ company.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.COMPANY_FORM.challengeType.LABEL' | translate }}</mat-label>
            <mat-select required formControlName="groupChallengeType">
              <mat-option *ngFor="let challengeType of groupChallengeTypes; let i = index" value="{{ challengeType }}">
                {{ ('CREATE_COMPANY.COMPANY_FORM.challengeType.OPTIONS.' + challengeType) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <ngx-mat-file-input (ngModelChange)="getImgLogoUrl()" matInput required
              accept="image/jpeg, image/jpg, image/png, image/svg+xml"
              formControlName="avatarImg"
              [placeholder]="'CREATE_COMPANY.COMPANY_FORM.logo.PLACEHOLDER' | translate"
            ></ngx-mat-file-input>
            <mat-icon matSuffix>attach_file</mat-icon>
            <mat-error>{{ 'CREATE_COMPANY.COMPANY_FORM.logo.REQUIRED_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <div class="col-lg-12">
            <img class="logo-size" src="{{avatarImgUrl}}" />
          </div>
          <h2 class="subtitles">{{ 'CREATE_COMPANY.COMPANY_FORM.EXTRA_FEATURES.TITLE' | translate }}</h2>
          <div class="checkbox-container">
            <mat-checkbox class="extra-feature-checkbox" color="primary" formControlName="featureProjectManager" (change)="onFeatureProjectManagerChange()">{{
              'CREATE_COMPANY.PROJECT_MANAGER_FORM.PROJECT_MANAGER' | translate
            }}</mat-checkbox>

            <mat-checkbox class="extra-feature-checkbox" color="primary" formControlName="featureWhiteLabel">{{
              'CREATE_COMPANY.COMPANY_FORM.EXTRA_FEATURES.WHITE_LABEL' | translate
            }}</mat-checkbox>

            <mat-checkbox class="extra-feature-checkbox" color="primary" formControlName="featureLocations">{{
              'CREATE_COMPANY.COMPANY_FORM.EXTRA_FEATURES.LOCATIONS' | translate
            }}</mat-checkbox>
          </div>
        </form>
        <div class="button-container">
          <button (click)="getImgLogoUrl()" [disabled]="!companyDataForm.valid" class="round-button register-button" color="primary" mat-raised-button matStepperNext>
            <span class="material-icons next-icon">
              chevron_right
            </span>
          </button>
        </div>
    </mat-step>
    <!-- WHITE LABEL -->
    <mat-step [stepControl]="whiteLabelDataForm"  *ngIf="companyDataForm.controls.featureWhiteLabel.value" #whiteLabelStep>
      <ng-template matStepLabel><mat-icon [ngStyle]="{ 'color': whiteLabelStep.completed ? 'var(--color-primary)' : '' }">color_lens</mat-icon></ng-template>
      <h1 class="title">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.TITLE'| translate }}</h1>
      <p class="paragraph"></p>
      <form [formGroup]="whiteLabelDataForm">
        <div class="white-label-container">
          <div class="white-label-creation-selector-container">
            <mat-radio-group formControlName="mode">
              <mat-radio-button color="primary" value="NEW">
                {{ 'CREATE_COMPANY.WHITE_LABEL_FORM.modeSelector.NEW'| translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" value="EXISTING">
                {{ 'CREATE_COMPANY.WHITE_LABEL_FORM.modeSelector.EXISTING'| translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="whiteLabelDataForm.controls.mode.value">
            <mat-form-field *ngIf="whiteLabelDataForm.controls.mode.value === NEW">
              <mat-label>{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.name.LABEL' | translate }}</mat-label>
              <input matInput formControlName="name" />
              <mat-error *ngIf="whiteLabelDataForm.controls.name.errors?.maxlength">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.name.MAX_LENGTH_ERROR' | translate }}</mat-error>
              <mat-error *ngIf="whiteLabelDataForm.controls.name.errors?.pattern">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.name.PATTERN_ERROR' | translate }}</mat-error>
              <mat-error *ngIf="whiteLabelDataForm.controls.name.errors?.required">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.name.REQUIRED_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="whiteLabelDataForm.controls.mode.value === EXISTING">
              <mat-label>{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelSelector.LABEL' | translate }}</mat-label>
              <mat-select (selectionChange)="updateWhiteLabelValues($event)">
                <mat-option
                  *ngFor="let whiteLabel of whiteLabels; let i = index"
                  [value]="whiteLabel">
                  {{ whiteLabel.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="white-label-logo-and-color-section">
              <div class="white-label-logo-container">
                <h2 class="subtitles">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.LOGO_TITLE' | translate }}</h2>
                <mat-form-field class="white-label-logo-input-button">
                  <img *ngIf="(whiteLabelDataForm.controls.mode.value === EXISTING && whiteLabelDataForm.controls.whiteLabelLogo.value) || (whiteLabelDataForm.controls.mode.value === NEW && whiteLabelLogo)" [src]="whiteLabelDataForm.controls.mode.value === EXISTING ? whiteLabelDataForm.controls.whiteLabelLogo.value : whiteLabelLogo" [alt]="'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelSelector.IMG_ALT' | translate" />
                  <button type="button" color="primary" class="custom-file-input add-white-label-logo-button" (click)="whiteLabelFileInput" *ngIf="!(whiteLabelDataForm.controls.mode.value === EXISTING && whiteLabelDataForm.controls.whiteLabelLogo.value) && !(whiteLabelDataForm.controls.mode.value === NEW && whiteLabelLogo)">
                    {{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelLogo.LABEL' | translate }}
                  </button>
                  <button type="button" color="primary" mat-mini-fab (click)="whiteLabelFileInput" *ngIf="(whiteLabelDataForm.controls.mode.value === EXISTING && whiteLabelDataForm.controls.whiteLabelLogo.value) || (whiteLabelDataForm.controls.mode.value === NEW && whiteLabelLogo)">
                    <mat-icon>edit_outline</mat-icon>
                  </button>
                  <ngx-mat-file-input
                    style="visibility: hidden;"
                    accept="image/jpeg, image/jpg, image/png"
                    formControlName="whiteLabelLogoFile"
                    #whiteLabelFileInput
                  ></ngx-mat-file-input>
                </mat-form-field>

              </div>
              <div class="white-label-color-container">
                <h2 class="subtitles">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.COLOR_TITLE' | translate }}</h2>
                <div class="white-label-color-selector">
                  <div class="color-square" [style.background]="this.whiteLabelDataForm.value.companyColor">
                    <input [style.color]="this.whiteLabelDataForm.value.companyColor" matInput id="companyColorPicker" #companyColorPicker formControlName="companyColor" (colorPickerChange)="onChangeCompanyColor($event)" [(colorPicker)]="this.whiteLabelDataForm.value.companyColor" [cpOutputFormat]="'hex'" [cpAlphaChannel]="'disabled'" [cpPosition]="'right'"/>
                  </div>
                  <div class="color-code" (click)="openColorPicker('companyColorPicker')">
                    <p>
                      {{ whiteLabelDataForm.controls.companyColor.value }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h2 class="subtitles">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.BUTTON_TITLE' | translate }}</h2>
            <div class="white-label-button-section">
              <mat-checkbox class="white-label-button-checkbox" color="primary" formControlName="whiteLabelButtonOption">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonOption.LABEL' | translate }} </mat-checkbox>

              <div *ngIf="whiteLabelDataForm.controls.whiteLabelButtonOption.value" class="white-label-button-container">
                <mat-form-field>
                  <mat-label>{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.LABEL' | translate }}</mat-label>
                  <input matInput formControlName="whiteLabelButtonText" />
                  <mat-error *ngIf="whiteLabelDataForm.controls.whiteLabelButtonText.errors?.maxlength">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.MAXLENGTH_ERROR' | translate }}</mat-error>
                  <mat-error *ngIf="whiteLabelDataForm.controls.whiteLabelButtonText.errors?.pattern">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.PATTERN_ERROR' | translate }}</mat-error>
                  <mat-error *ngIf="whiteLabelDataForm.controls.whiteLabelButtonText.errors?.required">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonText.REQUIRED_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonLink.LABEL' | translate }}</mat-label>
                  <input [placeholder]="'https://www.website.com'" matInput formControlName="whiteLabelButtonLink" />
                  <mat-error *ngIf="whiteLabelDataForm.controls.whiteLabelButtonLink.errors?.pattern">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonLink.PATTERN_ERROR' | translate }}</mat-error>
                  <mat-error *ngIf="whiteLabelDataForm.controls.whiteLabelButtonLink.errors?.required">{{ 'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelButtonLink.REQUIRED_ERROR' | translate }}</mat-error>
                </mat-form-field>
                <div class="white-label-color-container">
                  <div class="white-label-color-selector">
                    <div class="color-square" (click)="whiteLabelButtonColorPicker" [style.background]="this.whiteLabelDataForm.value.whiteLabelButtonColor">
                      <input [style.color]="this.whiteLabelDataForm.value.whiteLabelButtonColor" matInput id="whiteLabelButtonColorPicker" #whiteLabelButtonColorPicker formControlName="whiteLabelButtonColor" (colorPickerChange)="onChangeWhiteLabelButtonColor($event)" [(colorPicker)]="this.whiteLabelDataForm.value.whiteLabelButtonColor" [cpOutputFormat]="'hex'" [cpAlphaChannel]="'disabled'" [cpPosition]="'right'"/>
                    </div>
                    <div class="color-code" (click)="openColorPicker('whiteLabelButtonColorPicker')">
                      <p>
                        {{ whiteLabelDataForm.controls.whiteLabelButtonColor.value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button [disabled]="!whiteLabelDataForm.valid" class="round-button register-button" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            chevron_right
          </span>
        </button>
      </div>
    </mat-step>

    <!-- LOCATIONS -->
    <mat-step [stepControl]="locationDataForm" *ngIf="companyDataForm.controls.featureLocations.value" #locationsStep>
      <ng-template matStepLabel><mat-icon [ngStyle]="{ 'color': locationsStep.completed ? 'var(--color-primary)' : '' }">add_location</mat-icon></ng-template>
      <h1 class="title">{{ 'CREATE_COMPANY.LOCATIONS_FORM.TITLE'| translate }}</h1>
      <p class="paragraph"></p>
     <div *ngIf="this.companyDataForm.controls['featureLocations'].value">
      <form class="new-location-form" [formGroup]="locationDataForm">
        <div class="new-location-form-title">
          <h2 class="subtitle-locations">
            {{ 'CREATE_COMPANY.LOCATIONS_FORM.SUBTITLE' | translate }}
          </h2>
        </div>
        <div class="new-location-form-levels-selection">
          <mat-radio-group color="primary" formControlName="levels" class="example-radio-group">
            <mat-radio-button class="example-radio-button" [value]="1">1</mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="2">2</mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="3">3</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngFor="let level of [1, 2, 3]">
          <form class="new-location-form" [formGroup]="levelsDataForms[level]" *ngIf="locationDataForm.value.levels > level - 1">
            <div class="new-location-form-location-level-title">
              <h2 class="location-level-title">
                {{ 'CREATE_COMPANY.LOCATIONS_FORM.LOCATION_LEVEL' | translate }} {{ level }}
              </h2>
            </div>
            <mat-card>
              <div>
            <div class="new-location-form-location-level-subtitle">
              <span class="location-level-subtitle">
                {{ 'CREATE_COMPANY.LOCATIONS_FORM.levelTitle.LABEL' | translate }}
              </span>
            </div>
            <mat-form-field>
              <mat-label>{{ 'languages.en' | translate }}</mat-label>
              <input type="text" matInput formControlName="titleEN" />
              <mat-error>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelTitle.REQUIRED_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'languages.de' | translate }}</mat-label>
              <input type="text" matInput formControlName="titleDE" />
              <mat-error>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelTitle.REQUIRED_ERROR' | translate }}</mat-error>
            </mat-form-field>

            <div class="new-location-form-location-level-subtitle">
              <span class="location-level-subtitle">
                {{ 'CREATE_COMPANY.LOCATIONS_FORM.levelSelectorLabel.LABEL' | translate }}
              </span>
            </div>
            <mat-form-field>
              <mat-label>{{ 'languages.en' | translate }}</mat-label>
              <input type="text" matInput formControlName="selectorEN" />
              <mat-error>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelSelectorLabel.REQUIRED_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'languages.de' | translate }}</mat-label>
              <input type="text" matInput formControlName="selectorDE" />
              <mat-error>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelSelectorLabel.REQUIRED_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <div class="new-location-form-location-level-subtitle">
              <span class="location-level-subtitle">
                {{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.LABEL' | translate }}
              </span>
            </div>
            <mat-form-field>
              <mat-label>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.NAME' | translate }}</mat-label>
              <input type="text" matInput formControlName="name" />
              <mat-error>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.REQUIRED_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.ID' | translate }}</mat-label>
              <input type="text" matInput formControlName="id" />
              <mat-error>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.REQUIRED_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <button class="add-location-button"
              mat-raised-button
              (click)="addLocationToLevel(level)"
            >
              {{ (isEditActive && editLevel === level ? 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.UPDATE_BUTTON' : 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.ADD_BUTTON') | translate }}
            </button>
            <button
              *ngIf="isEditActive && editLevel === level"
              mat-raised-button
              (click)="cancelEdit(level)"
            >
              {{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.CANCEL_BUTTON' | translate }}
            </button>
          </div>
          </mat-card>
            <table
              mat-table
              [dataSource]="levelsDataSources[level]"
              matSort
              *ngIf="levelsLocations[level].length !== 0"
            >
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.ID' | translate }}</th>
                <td mat-cell *matCellDef="let location">
                  {{ location.id }}
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.LOCATIONS_FORM.levelLocations.NAME' | translate }}</th>
                <td mat-cell *matCellDef="let location">
                  {{ location.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td
                  mat-cell
                  *matCellDef="let location; let i = index"
                  (click)="editLocation(location.id, level)"
                  class="clickable-column"
                >
                  <mat-icon>edit</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="links">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td
                  mat-cell
                  *matCellDef="let location; let i = index"
                  (click)="linkLocation(location, level)"
                  class="clickable-column"
                >
                  <mat-icon [ngClass]="locationDataForm.value.levels !== level ? 'enabled' : 'disabled'">share</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let location; let i = index"
                  (click)="removeLocation(location.id, level)"
                  class="clickable-column"
                >
                  <mat-icon>delete_outline</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="locationColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: locationColumns"></tr>
            </table>
          </form>
        </div>
      </form>
    </div>

    <div class="button-container">
      <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
        <span class="material-icons next-icon">
          chevron_left
        </span>
      </button>
      <button (click)="saveLocationsAmount()" [disabled]="!locationDataForm.valid" class="round-button register-button" color="primary" mat-raised-button matStepperNext>
        <span class="material-icons next-icon">
          chevron_right
        </span>
      </button>
    </div>
    </mat-step>

    <!-- PERSONAL CHALLENGE -->
    <mat-step [stepControl]="personalChallengeForm" [completed]="personalChallengeForm.valid && pointsSourceIds.length !== 0" #personalChallengeStep *ngIf="!companyDataForm.controls.featureProjectManager.value">
      <ng-template matStepLabel><mat-icon [ngStyle]="{ 'color': personalChallengeStep.completed ? 'var(--color-primary)' : '' }">person_add</mat-icon></ng-template>
      <h1 class="title">{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.TITLE'| translate }}</h1>
      <p class="paragraph"></p>
      <form [formGroup]="personalChallengeForm">
        <mat-form-field>
          <mat-label>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.schema.LABEL' | translate }}</mat-label>
          <mat-select formControlName="schema">
            <mat-option *ngFor="let personalChallengeSchema of personalChallengeSchemas" [value]="personalChallengeSchema">
              {{ personalChallengeSchema.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.name.LABEL' | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.name.REQUIRED_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="personalChallengeForm.controls.hasFixedDate.value">
          <mat-label>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.personalChallengeDateRange.LABEL' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="personalChallengeDateRangePicker" [formGroup]="personalChallengeDateRange" [min]="minDate" disabled (click)="personalChallengeDateRangePicker.open()">
            <input
              matStartDate
              placeholder="{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.personalChallengeDateRange.START_LABEL' | translate }}"
              formControlName="start"
            />
            <input
              matEndDate
              placeholder="{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.personalChallengeDateRange.END_LABEL' | translate }}"
              formControlName="end"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="personalChallengeDateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #personalChallengeDateRangePicker [disabled]="!personalChallengeForm.controls.hasFixedDate.value"></mat-date-range-picker>
        </mat-form-field>
      </form>
      <form [formGroup]="pointsSourcesDataForm">
        <div class="points-sources">
          <h2 class="subtitles">{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TITLE' | translate }}</h2>

          <table
            mat-table
            [dataSource]="pointsSourceDataSource"
            matSort
            *ngIf="pointsSourceIds.length !== 0"
          >
            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.ID' | translate }}</th>
              <td mat-cell *matCellDef="let pointsSource">
                {{ pointsSource.id }}
              </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.DESCRIPTION' | translate }}</th>
              <td mat-cell *matCellDef="let pointsSource">
                {{ pointsSource.description }}
              </td>
            </ng-container>

            <!-- Basis Column -->
            <ng-container matColumnDef="basis">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.BASIS' | translate }}</th>
              <td mat-cell *matCellDef="let pointsSource">
                {{ pointsSourceBasisNames[pointsSource.basis] }}
              </td>
            </ng-container>

            <!-- Expected Performance Column -->
            <ng-container matColumnDef="expectedPerformance">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.EXPECTED_PERFORMANCE' | translate }}
              </th>
              <td mat-cell *matCellDef="let pointsSource">
                {{ pointsSource.expectedPerformance }}
              </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.TYPE' | translate }}</th>
              <td mat-cell *matCellDef="let pointsSource">
                {{ pointsSourceTypesNames[pointsSource.type] }}
              </td>
            </ng-container>

            <!-- Points Awarded Column -->
            <ng-container matColumnDef="pointsAwarded">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.POINTS_AWARDED' | translate }}
              </th>
              <td mat-cell *matCellDef="let pointsSource">
                {{ pointsSource.pointsAwarded }}
              </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td
                mat-cell
                *matCellDef="let pointsSource"
                (click)="removePointsSource(pointsSource.id)"
              >
                <mat-icon>delete_outline</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="pointsSourceColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: pointsSourceColumns"></tr>
          </table>
          <div *ngIf="pointsSourceIds.length === 0">
            <span>{{ 'CREATE_COMPANY.PERSONAL_CHALLENGE_FORM.pointsSources.TABLE.EMPTY_TABLE' | translate }}</span>
          </div>
        </div>
      </form>

      <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button class="round-button register-button" [disabled]="!personalChallengeForm.valid || pointsSourceIds.length === 0 || (personalChallengeForm.controls.hasFixedDate.value && !personalChallengeDateRange.controls.end.value)" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            chevron_right
          </span>
        </button>
      </div>
    </mat-step>

    <!-- TEAM CHALLENGE -->
    <mat-step [completed]="teamChallengeForm.valid && challengeTeams.length > 0" *ngIf="companyDataForm.controls.groupChallengeType.value === GROUP_CHALLENGE_TYPES_TEAM_CHALLENGE" #teamChallengeStep>
      <ng-template matStepLabel><mat-icon [ngStyle]="{ 'color': teamChallengeStep.completed ? 'var(--color-primary)' : '' }">people_outline</mat-icon></ng-template>
      <h1 class="title">{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.TITLE'| translate }}</h1>
      <h1 class="subtitle">{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.SECOND_TITLE'| translate }}</h1>
      <p class="paragraph">{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.SUBTITLE'| translate }}</p>
      <p class="second_paragraph">{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.SECOND_SUBTITLE'| translate }}</p>
      <p class="third_paragraph">{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.THIRD_SUBTITLE'| translate }}</p>

      <form [formGroup]="teamChallengeForm">
        <mat-form-field>
          <mat-label>{{ 'Select Team Challenge' | translate }}</mat-label>
          <mat-select formControlName="schema">
            <mat-option *ngFor="let teamChallengeSchema of teamChallengeSchemas" [value]="teamChallengeSchema">
              {{ teamChallengeSchema.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.name.LABEL' | translate }}</mat-label>
          <input matInput formControlName="name" />
          <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.name.REQUIRED_ERROR' | translate }}</mat-error>
        </mat-form-field>

        <div class="challenge-details">
          <mat-form-field class="duration-field">
            <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.duration.LABEL' | translate }}</mat-label>
            <input required matInput type="number" matInput formControlName="duration" />
            <mat-hint>Weeks</mat-hint>
            <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.duration.REQUIRED_ERROR' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamChallengeDateRange.START_LABEL' | translate}}</mat-label>
            <input required  matInput [matDatepicker]="teamChallengeStartDatePicker" [matDatepickerFilter]="allowOnlyTuesdaysAndThursdays" [disabled]="teamChallengeForm.controls.duration.value <= 0" [min]="minDate" [formControl]="teamChallengeStartDate" (click)="teamChallengeStartDatePicker.open()">
            <mat-hint>Team Challenges start on Tuesdays by default</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="teamChallengeStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #teamChallengeStartDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamChallengeDateRange.END_LABEL' | translate}}</mat-label>
            <input matInput [matDatepicker]="teamChallengeEndDatePicker" [formControl]="teamChallengeEndDate">
            <mat-datepicker-toggle matSuffix [for]="teamChallengeEndDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #teamChallengeEndDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </form>
      <form [formGroup]="teamsDataForm">
        <div class="team-selector">
          <mat-form-field>
            <mat-label>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamsAmount.LABEL' | translate }}</mat-label>
            <input required matInput type="number" matInput formControlName="teamsAmount" />
            <mat-hint>{{ 'Enter a team amount between 1 and 50' | translate }}</mat-hint>
            <mat-error>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamsAmount.REQUIRED_ERROR' | translate }}</mat-error>
          </mat-form-field>
          <button
            mat-flat-button
            color="primary"
            [disabled]="!teamsDataForm.controls.teamsAmount.valid"
            (click)="refreshTeamsAmount()"
          >
            {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teamsAmount.BUTTON_LABEL' | translate }}
          </button>
        </div>

      <div *ngIf="challengeTeams.length !== 0" class="subtitle-aligment">
        <h2 class="subtitles">{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TITLE' | translate }}</h2>
      </div>
      <mat-form-field style="visibility: hidden;">
        <ngx-mat-file-input
          accept="image/png"
          formControlName="avatarFileInput"
          #teamAvatarInput
        ></ngx-mat-file-input>
        <mat-error style="visibility: hidden;">{{ 'Error' | translate }}</mat-error>
      </mat-form-field>
      <div class="team-list" formArrayName="teamsFormsArray">
        <div class="team-card" *ngFor="let teamData of teamsFormsArray.controls; let i = index">
          <div class="row" [formGroupName]="i">
            <div class="col-4 avatar-container-col">
              <div class="avatar-size">
                <img [src]="teamsFormsArray.at(i).value.avatarImg" [alt]="'Team Avatar' | translate"/>
                <mat-form-field style="display: none;">
                  <input matInput formControlName="avatar" />
                  <mat-error style="display: none;">{{ 'Error' | translate }}</mat-error>
                </mat-form-field>
              </div>
              <button mat-icon-button type="button" (click)="editTeamAvatar(i, teamAvatarInput)">
                <mat-icon svgIcon="pencil-outline"></mat-icon>
              </button>
            </div>
            <div class="col-7 name-form-container">
              <mat-form-field class="team-name-form-field" [floatLabel]="'never'">
                <input matInput formControlName="name" [placeholder]="'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.TEAM_NAME_FIELD.PLACEHOLDER' | translate" />
              </mat-form-field>
            </div>
            <div class="col-1 team-buttons-container">
              <button mat-icon-button type="button" (click)="removeTeam(i)">
                <mat-icon svgIcon="trash-outline"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="add-team-button-container">
          <button
              mat-flat-button
              color="primary"
              (click)="addTeam()"
            >
              {{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.teams.ADD_BUTTON' | translate }}
          </button>

        </div>
      </div>
      </form>

      <div *ngIf="challengeTeams.length !== 0">
        <div *ngIf="challengeTeams.length !== 0" class="subtitle-aligment">
          <h2 class="subtitles">{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.TITLE' | translate }}</h2>
        </div>
        <div class="rewards">
          <mat-table
            [dataSource]="pointsAwardedDataSource"
            matSort
            *ngIf="pointsAwarded.length !== 0"
          >
            <ng-container matColumnDef="position">
              <mat-header-cell class="reward-title-list" *matHeaderCellDef>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.TABLE.POSITION' | translate }}</mat-header-cell>
              <mat-cell class="reward-title-list" *matCellDef="let pointsAward">
                <div class="position-number-container">
                  <span class="position-number">
                    {{ pointsAward.position }}
                  </span>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="points">
              <mat-header-cell class="reward-title-list" *matHeaderCellDef>{{ 'CREATE_COMPANY.TEAM_CHALLENGE_FORM.pointsAwarded.TABLE.POINTS' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let pointsAward" class="points-number-container">
                {{ pointsAward.points }}
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="pointsAwardedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: pointsAwardedColumns"></mat-row>
          </mat-table>
        </div>
      </div>

      <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button class="round-button register-button" [disabled]="!teamChallengeForm.valid || challengeTeams.length === 0 || teamChallengeForm.controls.duration.value <= 0 || !teamChallengeStartDate.value || !teamChallengeEndDate.value" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            chevron_right
          </span>
        </button>
      </div>
    </mat-step>

    <!-- PREVIEW -->
    <mat-step #previewStep>
      <div class="border-bottom">
        <ng-template matStepLabel><mat-icon [ngStyle]="{ 'color': previewStep.completed ? 'var(--color-primary)' : '' }">tag_faces</mat-icon></ng-template>
      <!-- COMPANY DATA -->
        <h1 class="text-center text-primary">{{'CREATE_COMPANY.PREVIEW.PREVIEW_TITLE' | translate}} </h1>
        <p class="text-secondary text-center">{{'CREATE_COMPANY.PREVIEW.PREVIEW_TEXT'|translate}}</p>
        <h1 class="text-center text-primary  text-sizing"> {{name}}</h1>
        <div class="img-container">
          <img src="{{avatarImgUrl}}" />
        </div>
        <div>
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <h1 class="text-secondary text-center text-sizing">{{'CREATE_COMPANY.PREVIEW.LICENCES'|translate}}</h1>
                <p class="text-secondary text-center">{{companyLicenses | number: '1.0-0':'de'}}</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <h1 class="text-secondary text-center text-sizing">{{'CREATE_COMPANY.PREVIEW.ADMINS'|translate}}</h1>
                <p class="text-secondary text-center">{{adminEmails.length | number: '1.0-0':'de'}}</p>
                <div *ngFor="let admin of adminEmails">
                  <p class="text-secondary text-center">{{admin}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
      <!-- PROJECT MANAGER DATA -->
      <div class="border-bottom" *ngIf="managerCompanyIds && managerCompanyIds.length > 0">
        <div class="my-4">
        <div class="alignment-title">
          <img  class="icon-margin" src="../../../../assets/img/icon-team-users.svg" alt="">
          <h1 class="text-center text-primary my-2">{{'CREATE_COMPANY.PROJECT_MANAGER_FORM.PROJECT_MANAGER' | translate}}</h1>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <h1 class="text-secondary text-center text-sizing">{{'CREATE_COMPANY.PROJECT_MANAGER_FORM.MANAGER_COMPANY'|translate}}</h1>
            <div *ngFor="let companies of managerCompanyIds">
              <p class="text-secondary text-center">{{companies}}</p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <!-- WHITE LABEL -->
      <div *ngIf="companyDataForm.controls.featureWhiteLabel.value" class="border-bottom">
        <div class="my-4">
          <div class="alignment-title">
            <span class="material-icons icon-margin">label_outline</span>
            <h1 class="text-center text-primary my-2">{{'CREATE_COMPANY.PREVIEW.WHITE_LABEL'|translate}}</h1>
          </div>
          <h1 class="text-center text-primary text-sizing"> {{ whiteLabelDataForm.value.name }}</h1>
          <div>
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <h1 class="text-secondary text-center text-sizing">{{'CREATE_COMPANY.PREVIEW.LOGO'|translate}}</h1>
                  <div class="img-container">
                    <img *ngIf="(whiteLabelDataForm.controls.mode.value === EXISTING && whiteLabelDataForm.controls.whiteLabelLogo.value) || (whiteLabelDataForm.controls.mode.value === NEW && whiteLabelLogo)" [src]="whiteLabelDataForm.controls.mode.value === EXISTING ? whiteLabelDataForm.controls.whiteLabelLogo.value : whiteLabelLogo" [alt]="'CREATE_COMPANY.WHITE_LABEL_FORM.whiteLabelSelector.IMG_ALT' | translate" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <h1 class="text-secondary text-center text-sizing">{{'CREATE_COMPANY.PREVIEW.COLOR'|translate}}</h1>
                  <div [style.background]="whiteLabelDataForm.value.companyColor" class="white-label-color-square row"></div>
                  <p class="text-secondary text-center">{{ whiteLabelDataForm.value.companyColor }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- LOCATION -->
      <div *ngIf="companyDataForm.controls.featureLocations.value" class="border-bottom">
        <div class="my-4">
          <div class="alignment-title">
            <!-- <img  class="icon-margin" src="../../../../assets/img/Icon material-location-on.svg" alt=""> -->
            <span class="material-icons icon-margin">location_on</span>
            <h1 class="text-center text-primary my-2"> {{'CREATE_COMPANY.PREVIEW.LOCATION'|translate}} </h1>
          </div>
        <div class="row">
          <div class="col-lg-6">
            <h1 class="text-center text-secondary text-sizing"> {{'CREATE_COMPANY.PREVIEW.LEVELS'|translate}}</h1>
            <p class="text-center text-secondary">{{ levelsAmount | number: '1.0-0':'de' }}</p>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <h1 class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.LOCATIONS'|translate}}</h1>
              <p class="text-center text-secondary">
              {{allLocations | number: '1.0-0':'de'}}
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!-- PERSONAL CHALLENGE -->
      <div  class="border-bottom" *ngIf="!companyDataForm.controls.featureProjectManager.value">
        <div class="my-4">
          <div class="alignment-title">
            <img class="icon-margin" src="../../../../assets/img/icon-user-standing.svg" alt="">
            <h1 class="text-center text-primary my-2">{{'CREATE_COMPANY.PREVIEW.PERSONAL_CHALLENGE'|translate}}</h1>
          </div>
          <h1 class="text-center text-primary text-sizing"> {{ personalChallengeName }}</h1>
          <div class="personal-challenge-card card mb-4 mt-4">
            <div class="card-body">
              <div class="row">
                <div class="col-5">
                  <h5 class="card-title text-center text-secondary">{{'CREATE_COMPANY.PREVIEW.EXPECTATION' | translate}}</h5>
                  <div class="alignment" *ngFor="let pointSource of filteredAdminPointDataSource">
                    <mat-icon class="type-icons">
                      {{ pointSource.type === POINTS_SOURCE_STEPS ? 'directions_run' : 'refresh' }} </mat-icon>
                    <p class="card-text text-secondary">
                      {{ pointSource.expectedPerformance | number: '1.0-0':'de' }} {{ (pointSource.basis === POINTS_SOURCE_DAILY ? 'CREATE_COMPANY.PREVIEW.DAILY' : 'CREATE_COMPANY.PREVIEW.WEEKLY') | translate }}
                    </p>
                  </div>
                </div>
                <div class="col-2">
                  <mat-icon style="display: block; margin: auto; color: var(--color-medium);"> directions_run</mat-icon>
                </div>
                <div class="col-5">
                  <h5 class="card-title text-center text-secondary">{{'CREATE_COMPANY.PREVIEW.POINTS'|translate}}</h5>
                  <div class="starts-col-alignment" *ngFor="let pointSource of filteredAdminPointDataSource">
                      <mat-icon class="star-icon">star_border</mat-icon>
                      <p class="card-text text-secondary">
                        {{ pointSource.pointsAwarded | number: '1.0-0':'de' }}
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row mt-3">
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.POINTS_TO_LEVEL_UP'|translate}}</p>
                  <p class="text-center text-secondary">{{ pointsToLevelUpPersonalChallenge | number: '1.0-0':'de' }}</p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.POINTS_TO_HOLD_LEVEL'|translate}}</p>
                  <p class="text-center text-secondary">{{ pointsToHoldLevelPersonalChallenge | number: '1.0-0':'de' }}</p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.MIN_LEVEL'|translate}}</p>
                  <p class="text-center text-secondary">{{ personalChallengeMinLevel.value | number: '1.0-0':'de' }}</p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.MAX_LEVEL'|translate}}</p>
                  <p class="text-center text-secondary">{{ personalChallengeMaxLevel.value | number: '1.0-0':'de' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TEAM CHALLENGE -->
      <div  *ngIf="companyDataForm.controls.groupChallengeType.value === GROUP_CHALLENGE_TYPES_TEAM_CHALLENGE" class="border-bottom">
        <div class="my-4">
          <div class="alignment-title">
            <img  class="icon-margin" src="../../../../assets/img/icon-team-users.svg" alt="">
            <h1 class="text-center text-primary my-2">{{'CREATE_COMPANY.PREVIEW.TEAM_CHALLENGE'|translate}} </h1>
          </div>
          <h1 class="text-center text-primary text-sizing"> {{ teamlChallengeName }}</h1>
          <div>
            <div class="row">
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.NUMBER_TEAMS'|translate}}</p>
                  <p class="text-center text-secondary">{{  challengeTeams.length | number: '1.0-0':'de' }}</p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.START_DATE'|translate}}</p>
                  <p class="text-center text-secondary">{{ teamChallengeStartDate.value | date: 'dd.MM.yyyy' }}</p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.END_DATE'|translate}}</p>
                  <p class="text-center text-secondary">{{ teamChallengeEndDate.value | date: 'dd.MM.yyyy' }}</p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="row">
                  <p class="text-center text-secondary text-sizing">{{'CREATE_COMPANY.PREVIEW.DURATION'|translate}}</p>
                  <p class="text-center text-secondary">{{ teamChallengeTotalDuration | number: '1.0-0':'de' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 class="text-center font-weight-bold text-primary my-3">
          {{'CREATE_COMPANY.PREVIEW.CONFIRMATION_TITLE'|translate}}
        </h1>
        <p class="text-center text-bolder text-secondary">
          {{'CREATE_COMPANY.PREVIEW.CONFIRMATION_TEXT'|translate}}</p>

      </div>
      <div class="button-container">
        <button class="round-button" color="medium" mat-raised-button matStepperPrevious>
          <span class="material-icons next-icon">
            chevron_left
          </span>
        </button>
        <button class="round-button register-button" (click)="createCompany()" color="primary" mat-raised-button matStepperNext>
          <span class="material-icons next-icon">
            done
          </span>
        </button>
      </div>
    </mat-step>

    <!-- CONGRATS -->
    <mat-step #finalStep>
      <ng-template matStepLabel><mat-icon [ngStyle]="{ 'color': finalStep.completed ? 'var(--color-primary)' : '' }">check_circle_outline</mat-icon></ng-template>
      <div class="creating-company-spinner-container" *ngIf="showSpinner || spinnerMessage">
        <p>{{ spinnerMessage }}</p>
        <mat-spinner *ngIf="showSpinner"></mat-spinner>
      </div>
      <div class="container" *ngIf="!showSpinner && !spinnerMessage">
        <h1 class="congrats-title text-center text-primary">{{'CREATE_COMPANY.PREVIEW.CONGRATULATIONS_TITLE' | translate}}</h1>
        <div class="row">
          <div class="font-size col-lg-6 center-vertically">
            <p class="text-secondary mb-4">{{'CREATE_COMPANY.PREVIEW.CONGRATULATIONS_FIST_TEXT'|translate}}</p>
            <p class="text-secondary mb-4">{{'CREATE_COMPANY.PREVIEW.CONGRATULATIONS_SECOND_TEXT'|translate}}</p>
            <p class="text-secondary" [innerHTML]="('CREATE_COMPANY.PREVIEW.CONGRATULATIONS_THIRD_TEXT' | translate)"></p>
          </div>
          <div class="col-lg-6 center-vertically">
            <img class="img-size" src="../../../../assets/img/img-celebration.svg" alt="">
          </div>
        </div>
        <button (click)="returnToAdminPage()" class="return-button" mat-flat-button color="primary" mat-button>
          {{'CREATE_COMPANY.PREVIEW.RETURN_BUTTON'|translate}}
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
