import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  private fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private fileExtension = '.xlsx';
  private date = new Date().getTime();
  private fileDate = formatDate(this.date, 'yyyy-MM-dd', 'en-US');

  private COLUMN_USER_STEP_AVERAGE_PER_DAY = '';
  private COLUMN_COMPANY_ID = '';
  private COLUMN_TOTAL_AVERAGE_STEPS = '';
  private FILENAME_USER_STEP_AVERAGE_PER_COMPANY_PER_DAY = '';

  constructor(private translateService: TranslateService) {
    this.getTranslations();
  }

  public exportExcel(
    jsonData: any[],
    fileName: string,
    headers: Array<string>
  ): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData, {
      header: headers,
    });
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    ws['!cols'] = [
      { wch: 35 },
      { wch: 8 },
      { wch: 8 },
      { wch: 8 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + '-' + this.fileDate + this.fileExtension);
  }

  public exportUserStepsAverageExcel(
    userStepsAveragePerCompany: Array<any>
  ): void {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    for (const company of userStepsAveragePerCompany) {
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
        [this.COLUMN_USER_STEP_AVERAGE_PER_DAY],
      ]);
      XLSX.utils.sheet_add_aoa(
        ws,
        [[this.COLUMN_COMPANY_ID, company.companyId]],
        { origin: -1 }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [[this.COLUMN_TOTAL_AVERAGE_STEPS, company.totalAverage]],
        { origin: -1 }
      );
      XLSX.utils.sheet_add_aoa(ws, [['']], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, company.trainings, { origin: -1 });
      this.autofitColumns(userStepsAveragePerCompany, ws, [
        this.COLUMN_USER_STEP_AVERAGE_PER_DAY,
        ...Object.keys(company.trainings[0]).splice(1, 3),
      ]);
      XLSX.utils.book_append_sheet(wb, ws, company.companyId);
    }

    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveExcelFile(
      excelBuffer,
      this.FILENAME_USER_STEP_AVERAGE_PER_COMPANY_PER_DAY
    );
  }

  private autofitColumns(
    json: any[],
    worksheet: XLSX.WorkSheet,
    header?: string[]
  ): void {
    const jsonKeys = header ? header : Object.keys(json[0]);

    const objectMaxLength = [];

    for (const value of json) {
      for (let j = 0; j < jsonKeys.length; j++) {
        if (typeof value[jsonKeys[j]] === 'number') {
          objectMaxLength[j] = 10;
        } else {
          const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

          objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
        }
      }

      const key = jsonKeys;
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] =
          objectMaxLength[j] >= key[j].length
            ? objectMaxLength[j]
            : key[j].length;
      }
    }

    const wscols = objectMaxLength.map((w) => ({ width: w }));

    worksheet['!cols'] = wscols;
  }

  exportUserTableToExcel(
    usersDataSource: (Partial<User> | { sLastSync: string })[]
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(usersDataSource);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveExcelFile(excelBuffer, 'user-table-export');
  }

  private getTranslations(): void {
    this.translateService
      .stream([
        'COLUMN_USER_STEP_AVERAGE_PER_DAY',
        'COLUMN_COMPANY_ID',
        'COLUMN_TOTAL_AVERAGE_STEPS',
        'FILENAME_USER_STEP_AVERAGE_PER_COMPANY_PER_DAY',
      ])
      .subscribe((values) => {
        this.COLUMN_USER_STEP_AVERAGE_PER_DAY =
          values.COLUMN_USER_STEP_AVERAGE_PER_DAY;
        this.COLUMN_COMPANY_ID = values.COLUMN_COMPANY_ID;
        this.COLUMN_TOTAL_AVERAGE_STEPS = values.COLUMN_TOTAL_AVERAGE_STEPS;
        this.FILENAME_USER_STEP_AVERAGE_PER_COMPANY_PER_DAY =
          values.FILENAME_USER_STEP_AVERAGE_PER_COMPANY_PER_DAY;
      });
  }
}
