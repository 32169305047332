<div class="container-card">
  <app-notification-messages *ngIf="!isProdEnvironment" [defaultNotification]="{ showMessage: true, message: { en: 'Testing Environment', de: 'Testing Umgebung' }}"></app-notification-messages>
  <div class="language-button-container">
    <button class="language-button-position" mat-button [matMenuTriggerFor]="langMenu">
      <span class="menu-label">{{ 'languages.' + settings.lang | translate }}
      </span>
      <mat-icon> language </mat-icon>
    </button>
    <mat-menu #langMenu="matMenu">
      <button mat-menu-item (click)="changeLanguage('en')">
        {{ 'languages.en' | translate }}
      </button>
      <button mat-menu-item (click)="changeLanguage('de')">
        {{ 'languages.de' | translate }}
      </button>
    </mat-menu>
  </div>
  <mat-grid-list [cols]="colSize" rowHeight="1:1">
    <mat-grid-tile class="logo-col" colspan="1" rowspan="1">
      <div class="img-container">
        <img src="../../../assets/logo_login.png" />
      </div>
      <div *ngIf="colSize === 1" (click)="scroll(scrollTarget)" class="expand-icon-container">
        <mat-icon>expand_more</mat-icon>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="recover-col" rowspan="1" colspan="1">
      <div #scrollTarget>
        <div class="welcome-box">
          <p class="move-me-title">Move Me</p>
          <p class="move-me-title">{{ 'ACCOUNT_DELETION_PAGE.TITLE' | translate }}</p>
          <p class="enter-email-label">
            {{ 'ACCOUNT_DELETION_PAGE.PARAGRAPH_1' | translate }}
          </p>
          <p class="enter-email-label">
            {{ 'ACCOUNT_DELETION_PAGE.PARAGRAPH_2' | translate }}
          </p>
        </div>
        <form [formGroup]="dataDeletionRequest" (ngSubmit)="requestDataDelete()">
          <mat-form-field>
            <mat-label>{{ 'forms.email.LABEL' | translate }}</mat-label>
            <input
              matInput
              type="email"
              class="form-control"
              formControlName="email"
              id="email"
            />
            <mat-error *ngIf="email.hasError('required')">
              {{ 'forms.email.REQUIRED_ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="email.hasError('pattern')">
              {{ 'forms.email.PATTERN_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'forms.password.LABEL' | translate }}</mat-label>
            <input matInput type="password" class="form-control" formControlName="password" id="password" />
            <mat-error *ngIf="password.hasError('required')">
              {{ 'forms.password.REQUIRED_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <div class="button-container">
            <button mat-raised-button color="warn" type="submit" [disabled]="!dataDeletionRequest.valid">
              {{ 'ACCOUNT_DELETION_PAGE.BUTTON' | translate }}
            </button>
          </div>
          <div class="links-container">
            <p
              class="ion-text-center"
              routerLink="/login"
              routerDirection="forward"
            >
              {{ 'RESET_PASSWORD_BACK' | translate }}
            </p>
          </div>
        </form>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
