import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';

import { LoginComponent } from './pages/login/login.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SignupComponent } from './pages/signup/signup.component';
import { HelpComponent } from './pages/help/help.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DefaultComponent } from './layouts/default/default.component';
import { MyTeamComponent } from './pages/my-team/my-team.component';
import { TeamChallengeComponent } from './pages/team-challenge/team-challenge.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AddTeamComponent } from './pages/admin/add-team/add-team.component';
import { CompanyInfoComponent } from './pages/admin/company-info/company-info.component';
import { AddPersonalChallengeComponent } from './pages/admin/add-personal-challenge/add-personal-challenge.component';
import { AddTeamChallengeComponent } from './pages/admin/add-team-challenge/add-team-challenge.component';
import { AddPointsSourceComponent } from './pages/admin/add-points-source/add-points-source.component';
import { AdminTeamsComponent } from 'src/app/pages/admin-teams/admin-teams.component';
import { CitiesChallengeContainerComponent } from './pages/cities-challenge-container/cities-challenge-container.component';
import { EditPersonalChallengeComponent } from './pages/admin/edit-personal-challenge/edit-personal-challenge.component';
import { EditPointsSourceComponent } from './pages/admin/edit-points-source/edit-points-source.component';
import { EditTeamComponent } from './pages/admin/edit-team/edit-team.component';
import { EditTeamChallengeComponent } from './pages/admin/edit-team-challenge/edit-team-challenge.component';
import { InputStepsComponent } from './pages/input-steps/input-steps.component';
import { EditCityComponent } from './pages/admin/edit-city/edit-city.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { UsersComponent } from './pages/users/users.component';
import { ImportCitiesComponent } from './pages/admin/import-cities/import-cities.component';
import { EditRouteComponent } from './pages/admin/edit-route/edit-route.component';
import { UserInfoComponent } from './pages/admin/user-info/user-info.component';
import { AddWhiteLabelComponent } from './pages/admin/add-white-label/add-white-label.component';
import { EditWhiteLabelComponent } from './pages/admin/edit-white-label/edit-white-label.component';
import { DuelsComponent } from './pages/duels/duels.component';
import { SendNotificationComponent } from './pages/send-notification/send-notification.component';
import { CreateCompanyComponent } from 'src/app/pages/admin/create-company/create-company.component';
import { PricingTableComponent } from './shared/components/pricing-table/pricing-table.component';
import { EventsComponent } from './pages/events/events.component';
import { AddEventComponentComponent } from './pages/admin/add-event-component/add-event-component.component';
import { CityInfoComponent } from './pages/admin/city-info/city-info.component';
import { EditCitiesChallengeComponent } from './pages/admin/edit-cities-challenge/edit-cities-challenge.component';
import { AddCitiesChallengeComponent } from './pages/admin/add-cities-challenge/add-cities-challenge.component';
import { DataDeletionRequestComponent } from './pages/data-deletion-request/data-deletion-request.component';
import { NewTeamChallengeComponent } from './pages/admin/team-challenge/new-team-challenge/new-team-challenge.component';
import { TeamChallengeContainerComponent } from './pages/team-challenge-container/team-challenge-container.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OverviewComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'team-challenge',
        component: TeamChallengeComponent,
      },
      {
        path: 'my-team',
        component: MyTeamComponent,
      },
      {
        path: 'input-data',
        component: InputStepsComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'duels',
        component: DuelsComponent,
      },
      {
        path: 'send-notification',
        component: SendNotificationComponent,
      },
      {
        path: 'pricing-table',
        component: PricingTableComponent,
      },
      {
        path: 'events',
        component: EventsComponent,
      },
      {
        path: 'admin',
        component: AdminComponent,
      },
      {
        path: 'admin/add-company',
        component: CreateCompanyComponent,
      },
      {
        path: 'admin/add-points-source',
        component: AddPointsSourceComponent,
      },
      {
        path: 'admin/edit-points-source/:pointsSourceId',
        component: EditPointsSourceComponent,
      },
      {
        path: 'admin/add-team',
        component: AddTeamComponent,
      },
      {
        path: 'admin/edit-team/:teamId',
        component: EditTeamComponent,
      },
      {
        path: 'admin/company-info/:id',
        component: CompanyInfoComponent,
      },
      {
        path: 'admin/user-info/:id',
        component: UserInfoComponent,
      },
      {
        path: 'admin/company-info/:companyId/add-team',
        component: AddTeamComponent,
      },
      {
        path: 'admin/company-info/:companyId/edit-team/:teamId',
        component: EditTeamComponent,
      },
      {
        path: 'admin/company-info/:companyId/add-personal-challenge',
        component: AddPersonalChallengeComponent,
      },
      {
        path:
          'admin/company-info/:companyId/edit-personal-challenge/:personalChallengeId',
        component: EditPersonalChallengeComponent,
      },
      {
        path: 'admin/company-info/:companyId/add-point-source',
        component: AddPointsSourceComponent,
      },
      {
        path:
          'admin/company-info/:companyId/edit-points-source/:pointsSourceId',
        component: EditPointsSourceComponent,
      },
      {
        path: 'admin/company-info/:companyId/add-team-challenge',
        component: AddTeamChallengeComponent,
      },
      {
        path: 'admin/company-info/:companyId/new-team-challenge',
        component: NewTeamChallengeComponent,
      },
      {
        path:
          'admin/company-info/:companyId/edit-team-challenge-v2/:teamChallengeV2Id',
        component: NewTeamChallengeComponent,
      },
      {
        path: 'admin/company-info/:companyId/app-add-event-component',
        component: AddEventComponentComponent,
      },
      {
        path: 'admin/company-info/:companyId/app-add-event-component/:eventId',
        component: AddEventComponentComponent,
      },
      {
        path:
          'admin/company-info/:companyId/edit-cities-challenge/:citiesChallengeId',
        component: EditCitiesChallengeComponent,
      },
      {
        path: 'admin/company-info/:companyId/add-cities-challenge',
        component: AddCitiesChallengeComponent,
      },
      {
        path:
          'admin/company-info/:companyId/edit-team-challenge/:teamChallengeId',
        component: EditTeamChallengeComponent,
      },
      {
        path: 'admin/add-city',
        component: EditCityComponent,
      },
      {
        path: 'admin/city-info/:cityId',
        component: CityInfoComponent,
      },
      {
        path: 'admin/import-cities',
        component: ImportCitiesComponent,
      },
      {
        path: 'admin/edit-city/:cityId',
        component: EditCityComponent,
      },
      {
        path: 'admin/add-route',
        component: EditRouteComponent,
      },
      {
        path: 'admin/edit-route/:routeId',
        component: EditRouteComponent,
      },
      {
        path: 'admin-teams',
        component: AdminTeamsComponent,
      },
      {
        path: 'cities-challenges/:companyId/:challengeId',
        component: CitiesChallengeContainerComponent,
      },
      {
        path: 'cities-challenges/:companyId',
        component: CitiesChallengeContainerComponent,
      },
      {
        path: 'team-challenges-v2/:companyId/:challengeId',
        component: TeamChallengeContainerComponent,
      },
      {
        path: 'team-challenges-v2/:companyId',
        component: TeamChallengeContainerComponent,
      },
      {
        path: 'admin/add-white-label',
        component: AddWhiteLabelComponent,
      },
      {
        path: 'admin/edit-white-label/:whiteLabelId',
        component: EditWhiteLabelComponent,
      },
    ],
    // TODO: make guard here
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'data-deletion-request',
    component: DataDeletionRequestComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
