import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Models
import { ConfirmationDialogData } from 'src/app/models/confirmation-dialog-data.model';
import { ConfirmationDialogResponse } from 'src/app/models/confirmation-dialog-response.model';
import { ConfirmationDialogDataReturnCodes } from 'src/app/models/confirmation-dialog-data-return-codes.enum';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  public showYesSpinner = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

  public onNoClick(): void {
    this.dialogRef.close(
      new ConfirmationDialogResponse({
        code: ConfirmationDialogDataReturnCodes.UserCancelledAction,
      })
    );
  }

  public async onYesClick(): Promise<void> {
    this.showYesSpinner = true;
    const confirmationDialogResponse = new ConfirmationDialogResponse({});
    try {
      await this.data.action();
      confirmationDialogResponse.code =
        ConfirmationDialogDataReturnCodes.ActionPerformedCorrectly;
    } catch (error) {
      console.log('onYesClick - error: ', error);
      confirmationDialogResponse.code =
        ConfirmationDialogDataReturnCodes.ActionPerformedWithErrors;
      confirmationDialogResponse.error = error;
    }
    this.dialogRef.close(confirmationDialogResponse);
  }
}
