import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

// Models
import { TeamChallengeTypes } from 'src/app/models/team-challenge-types.enum';
import { TeamChallenge } from 'src/app/models/team-challenge.model';
import { Company } from 'src/app/models/company.model';
import { Team } from 'src/app/models/team.model';

// Services
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-add-team-challenge',
  templateUrl: './add-team-challenge.component.html',
  styleUrls: ['./add-team-challenge.component.scss'],
})
export class AddTeamChallengeComponent implements OnInit {
  public challengeDataForm: UntypedFormGroup;
  public teamsDataForm: UntypedFormGroup;
  public pointsAwardedDataForm: UntypedFormGroup;

  public showSpinner = false;

  public companyId: string;
  public company: Company;

  public allTeams: Array<Team> = [];
  public companyTeams: Array<Team> = [];
  public selectableTeams: Array<Team> = [];
  public challengeTeams: Array<Team> = [];
  public userIds: Array<string> = [];

  public teamsDataSource = new MatTableDataSource(this.challengeTeams);
  public teamsColumns: string[] = [
    'avatar',
    'name',
    'totalPoints',
    'totalSteps',
  ];

  public pointsAwarded = []; // [{level, reward}] => convert to map
  public pointsAwardedDataSource = new MatTableDataSource();
  public pointsAwardedColumns: string[] = ['position', 'points', 'delete'];

  public dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  public challengeTypesNames: Array<string> = Object.keys(TeamChallengeTypes)
    .map((key) => TeamChallengeTypes[key])
    .filter((value) => typeof value === 'string') as string[];

  public ADD_TEAM_CHALLENGE_CREATED = '';
  public ADD_TEAM_CHALLENGE_NEW_TEAM = '';
  public ADD_TEAM_CHALLENGE_COMPANY_TEAMS = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private firebaseService: FirebaseService,
    private teamsService: TeamsService,
    private adminService: AdminService
  ) {
    this.getTranslations();
    this.challengeDataForm = this.formBuilder.group({
      challengeId: ['', Validators.required],
      name: ['', Validators.required],
      companyId: [{ value: '', disabled: true }, Validators.required],
      duration: [4, Validators.required],
      participantsPerTeam: [10, Validators.required],
      type: ['0', Validators.required],
    });

    this.teamsDataForm = this.formBuilder.group({
      teamId: ['', Validators.required],
    });

    this.pointsAwardedDataForm = this.formBuilder.group({
      position: [1, [Validators.required, Validators.min(1)]],
      points: [3, [Validators.required, Validators.min(1)]],
    });
  }

  get companies(): Array<Company> {
    return this.adminService.companies;
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.challengeDataForm.controls.companyId.setValue(this.companyId);

    Promise.all([
      this.firebaseService.getTeamSchemas(),
      this.teamsService.getTeams(this.companyId),
      this.companyService.getCompany(this.companyId),
    ])
      .then(([teamSchemas, companyTeams, company]) => {
        this.allTeams = teamSchemas.docs.map(
          (teamSchema) => new Team(teamSchema.data())
        );
        this.companyTeams = companyTeams;
        this.company = company;
        this.selectableTeams = this.allTeams.filter(
          (team) => !this.company.teamIds.includes(team.id)
        );
      })
      .catch((error) => {
        console.log('error getting data from firebase: ', error);
      });
  }

  public getTranslations(): void {
    this.translateService
      .stream([
        'ADD_PERSONAL_CHALLENGE_CREATED',
        'ADMIN_NEW_TEAM_BUTTON',
        'ADD_TEAM_CHALLENGE_COMPANY_TEAMS',
      ])
      .subscribe((values) => {
        this.ADD_TEAM_CHALLENGE_CREATED = values.ADD_PERSONAL_CHALLENGE_CREATED;
        this.ADD_TEAM_CHALLENGE_NEW_TEAM = values.ADMIN_NEW_TEAM_BUTTON;
        this.ADD_TEAM_CHALLENGE_COMPANY_TEAMS =
          values.ADD_TEAM_CHALLENGE_COMPANY_TEAMS;
      });
  }

  public addTeam(): void {
    if (
      this.company.teamIds.includes(this.teamsDataForm.controls.teamId.value)
    ) {
      this.challengeTeams.push(
        ...this.companyTeams.filter(
          (team) => team.id === this.teamsDataForm.controls.teamId.value
        )
      );
    } else {
      this.challengeTeams.push(
        ...this.allTeams.filter(
          (team) => team.id === this.teamsDataForm.controls.teamId.value
        )
      );
    }

    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);

    this.selectableTeams.splice(
      this.selectableTeams.findIndex(
        (team) => team.id === this.teamsDataForm.controls.teamId.value
      ),
      1
    );
    this.teamsDataForm.controls.teamId.setValue('');
  }

  public removeTeam(teamId: string): void {
    this.challengeTeams.splice(
      this.challengeTeams.findIndex((team) => team.id === teamId),
      1
    );
    this.selectableTeams.push(this.allTeams.find((team) => team.id === teamId));
    this.teamsDataSource = new MatTableDataSource(this.challengeTeams);
  }

  public addPointsAwarded(): void {
    // TODO: prevent adding more points for one position
    this.pointsAwarded.push({
      position: this.pointsAwardedDataForm.controls.position.value,
      points: this.pointsAwardedDataForm.controls.points.value,
    });
    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
  }

  public removePointsAward(pointsAward): void {
    this.pointsAwarded.splice(
      this.pointsAwarded.findIndex(
        (element) => element.position === pointsAward.position
      ),
      1
    );
    this.pointsAwardedDataSource = new MatTableDataSource(this.pointsAwarded);
  }

  public async addTeamChallenge(event: Event): Promise<void> {
    event.preventDefault();
    this.showSpinner = true;
    if (this.challengeDataForm.valid) {
      const challengeId = this.challengeDataForm.controls.challengeId.value;
      const companyId = this.challengeDataForm.controls.companyId.value;

      const pointsAwarded = {};
      this.pointsAwarded.forEach((pointsAward) => {
        pointsAwarded[pointsAward.position] = pointsAward.points;
      });

      const teamIds = new Set(this.challengeTeams.map((team) => team.id));
      const userIds = new Set(this.userIds);

      const teamChallenge = new TeamChallenge({
        id: challengeId,
        name: this.challengeDataForm.controls.name.value,
        type: this.challengeDataForm.controls.type.value,
        participantsPerTeam: this.challengeDataForm.controls.participantsPerTeam
          .value,
        duration: this.challengeDataForm.controls.duration.value,
        pointsAwarded,
        teamIds,
        userIds,
        currentWeek: 1,
        startDate: new Date(this.dateRange.value.start).getTime(),
        endDate: new Date(this.dateRange.value.end).setHours(23, 59, 59),
      });

      // CREATE TEAMS FROM TEAM SCHEMAS IF THEY ARE NEW TEAMS

      const teamsToCreate = this.challengeTeams.filter(
        (team) => !this.company.teamIds.includes(team.id)
      );

      if (teamsToCreate.length > 0) {
        try {
          await this.teamsService.createCompanyTeams(companyId, teamsToCreate);
        } catch (error) {
          console.log(
            'error on addTeamChallenge, while creating teams: ',
            error
          );
          this.showSpinner = false;
          this.snackBar.open(
            'error on addTeamChallenge, while creating teams: ' + error,
            'Ok',
            {
              duration: 4000,
              panelClass: 'snack-bar-color',
            }
          );
          return Promise.reject(error);
        }
      }

      try {
        await this.firebaseService.createTeamChallenge(
          companyId,
          teamChallenge,
          challengeId
        );
      } catch (error) {
        console.log(
          'error on addTeamChallenge, while creating the challenge: ',
          error
        );
        this.showSpinner = false;
        this.snackBar.open(
          'error on addTeamChallenge, while creating the challenge: ' + error,
          'Ok',
          {
            duration: 4000,
            panelClass: 'snack-bar-color',
          }
        );
        return Promise.reject(error);
      }

      this.showSpinner = false;
      this.snackBar.open(this.ADD_TEAM_CHALLENGE_CREATED, 'Ok', {
        duration: 4000,
        panelClass: 'snack-bar-color',
      });
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  public returnPage(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
