<div class="container" fxLayout.xs="column space-between" fxLayout.sm="column space-between">
  <h1 class="title">Events</h1>

  <div class="spinner-container" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!showSpinner">
    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let event of companyEvents" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ event.name }}
          </mat-panel-title>
          <mat-panel-description>
            <mat-chip-list>
              <mat-chip>
                {{ event.status === EVENT_STATUS_NOT_STARTED ? ("ADMIN_PAGE.EVENTS_TAB.STATUS.NOT_STARTED" | translate) 
                    : event.status === EVENT_STATUS_IN_PROGRESS ? 
                      ("ADMIN_PAGE.EVENTS_TAB.STATUS.IN_PROGRESS" | translate) : 
                      ("ADMIN_PAGE.EVENTS_TAB.STATUS.COMPLETED" | translate) }}
              </mat-chip>
            </mat-chip-list>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <p>{{('COMPANY_INFO.EVENT.START_DATE' | translate) + ": " + event.days[0]}}</p>
          <p>{{('COMPANY_INFO.EVENT.END_DATE' | translate) + ": " + event.days[event.days.length - 1]}}</p>
          <p>Distance: {{ event.distance }}</p>
          <p>Progress: {{ event.progress }}</p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</div>
