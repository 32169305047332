import { Component, OnInit } from '@angular/core';
import { _isNumberValue } from '@angular/cdk/coercion';

import { CompanyService } from 'src/app/services/company/company.service';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/user.model';
import { Company } from 'src/app/models/company.model';

@Component({
  selector: 'app-team-challenge',
  templateUrl: './team-challenge.component.html',
  styleUrls: ['./team-challenge.component.scss'],
})
export class TeamChallengeComponent implements OnInit {
  public currentTeamChallengeData: any = {
    weeklyRanking: [],
    weeklyRankingPointsSorted: [],
    weeklyRankingStats: [],
  };

  constructor(
    private companyService: CompanyService,
    private teamsService: TeamsService,
    private userService: UserService
  ) {}

  public ngOnInit(): void {
    this.currentTeamChallengeData.weeklyRanking = this.weeklyRanking;
    this.currentTeamChallengeData.weeklyRankingPointsSorted = this.weeklyRankingPointsSorted;
    this.currentTeamChallengeData.weeklyRankingStats = this.weeklyRankingStats;
  }

  get user(): User {
    return this.userService.user;
  }
  get company(): Company {
    return this.companyService.company;
  }
  get teamChallengeWeeks(): Array<any> {
    return this.teamsService.currentTeamChallengeWeeks;
  }
  get weeklyRanking(): Array<any> {
    return this.teamsService.weeklyRanking;
  }
  get weeklyRankingPointsSorted(): Array<any> {
    return this.teamsService.weeklyRankingPointsSorted;
  }
  get weeklyRankingStats(): Array<string> {
    return this.teamsService.weeklyRankingStats;
  }
}
